import React from 'react';
import { TValveOpening } from '../types';
import redUpTriangle from '!svg-url-loader!../../../assets/icons/triangle-red.svg';
import greenUpTriangle from '!svg-url-loader!../../../assets/icons/triangle-green.svg';
import greyDownTriangle from '!svg-url-loader!../../../assets/icons/triangle-grey.svg';

export interface ValveOpeningIndicatorProps {
  x: number;
  y: number;
  state: TValveOpening;
}

const TRIANGLE_HEIGHT = 10;

export const ValveOpeningIndicator: React.FC<ValveOpeningIndicatorProps> = React.memo(({ x, y, state }) => {
  const upTriangle = state === 'opening' ? greenUpTriangle : greyDownTriangle;
  const downTriangle = state === 'closing' ? redUpTriangle : greyDownTriangle;
  const mirrorUpTriangle = state !== 'opening';
  const mirrorDownTriangle = state === 'closing';

  const upImage = mirrorUpTriangle ? (
    <g transform={`translate(0, ${TRIANGLE_HEIGHT}) scale(1, -1)`}>
      <image href={upTriangle} />
    </g>
  ) : (
    <image href={upTriangle} />
  );

  const downImage = mirrorDownTriangle ? (
    <g transform={`translate(0, ${TRIANGLE_HEIGHT}) scale(1, -1)`}>
      <image href={downTriangle} />
    </g>
  ) : (
    <image href={downTriangle} />
  );

  return (
    <g transform={`translate(${x}, ${y})`}>
      <g transform={`translate(0, ${-TRIANGLE_HEIGHT - 1})`}>{upImage}</g>
      <g transform={`translate(0, ${1})`}>{downImage}</g>
    </g>
  );
});
