import { Component, OnInit, Inject } from '@angular/core';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { UntypedFormGroup, UntypedFormControl, ValidatorFn, AbstractControl, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { BehaviorSubject } from 'rxjs';
import { MODAL_CONTROLLER, MODAL_DATA, ModalController, INotificationSettings } from 'shared';
import { TranslateService } from '@ngx-translate/core';


export function phoneNumberValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const validator = parsePhoneNumberFromString(control.value);
    const valid = validator && validator.isValid();
    if (valid) {
      return null;
    } else {
      return {
        phone: false
      };
    }
  };
}


@Component({
  selector: 'app-two-factor-verification-flow-modal',
  templateUrl: './two-factor-verification-flow-modal.component.html',
  styleUrls: ['./two-factor-verification-flow-modal.component.scss']
})
export class TwoFactorVerificationFlowModalComponent {

  public notificationsettings: INotificationSettings;

  public seconds = 30;
  public showPhoneNumberError = false;

  public error$ = new BehaviorSubject('');

  public loading = false;
  public step: 'phone' | 'code' | 'success' = 'phone';

  public form = new UntypedFormGroup({
    mobile: new UntypedFormControl('', [Validators.required, phoneNumberValidator(), Validators.pattern('[0-9+]+')]),
    verification: new UntypedFormControl('', [Validators.required, Validators.maxLength(6), Validators.minLength(6), Validators.pattern('[0-9]+')])
  });

  constructor(
    @Inject(MODAL_CONTROLLER)  private controller: ModalController<void>,
    @Inject(MODAL_DATA) private modalData: INotificationSettings,
    private userService: UserService,
    private translate: TranslateService
  ) {
    this.notificationsettings = modalData;

    if (this.modalData.mobile) {
      this.form.controls.mobile.setValue(modalData.mobile);
    }
  }

  startVerification() {
    this.loading = true;
    if (this.modalData.mobile === this.form.value.mobile && this.modalData.mobileValidatedState === 'Validated') {
      this.controller.dismiss();
    } else {
      this.userService.setNotificationPreferences({
        ...this.notificationsettings,
        mobile: this.form.value.mobile,
      })
          .subscribe(() => {
            this.step = 'code';
            this.loading = false;
            this.startCountdown();
          }, err => {

          });
    }
  }

  verifyCode() {
    this.loading = true;

    this.userService.sendConfirmationCode(this.form.controls.verification.value)
      .subscribe(() => {
        this.step = 'success';
        this.loading = false;
      }, (err) => {
        this.loading = false;
        this.error$.next(this.translate.instant('two-factor-verification-flow-modal.incorrect-verification-code'));
      });
  }

  done() {
    this.controller.complete();
  }

  startCountdown() {
    this.seconds = 60;
    const interval = setInterval(() => {
      this.seconds -= 1;

      if (this.seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);
  }

  resendCode() {
    this.userService.resendConfirmationCode().subscribe(() => {
      this.startCountdown();
    }, err => {
      this.error$.next(this.translate.instant('two-factor-verification-flow-modal.code-resent-too-often'));
    });
  }

  dismiss() {
    this.controller.dismiss();
  }
}
