<div class="dropdown-text">
  {{placeholder}}
</div>
<div class="dropdown-arrow">
  &#9662;
</div>

<div class="dropdown-items" *ngIf="dropdownVisible">
  <ng-content></ng-content>
  <div class="dropdown-arrow top"></div>
</div>
