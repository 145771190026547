<gbc-page-container>
  <gbc-tile>
    <div style="padding: 2em">
      <h2>{{ 'account-page.subscription-differences' | translate }}</h2>
      <button gbc-button [routerLink]="['/account', 'mixit-subscriptions-overview']">
        {{ 'account-page.learn-more' | translate }}
      </button>
    </div>
  </gbc-tile>

  <gbc-tile [title]="'app-account-page.licensing' | translate">
    <gbc-table>
      <th gbc-table-header>{{ 'account-page.device-table.facility' | translate }}</th>
      <th gbc-table-header>{{ 'account-page.device-table.device' | translate }}</th>
      <th gbc-table-header>{{ 'account-page.device-table.active-upgrade' | translate }}</th>
      <th gbc-table-header>{{ 'account-page.device-table.serial-number' | translate }}</th>
      <th gbc-table-header>{{ 'account-page.device-table.license-type' | translate }}</th>
      <th gbc-table-header>{{ 'account-page.device-table.purchase-date' | translate }}</th>
      <th gbc-table-header>{{ 'account-page.device-table.renewal-date' | translate }}</th>
      <ng-container *ngFor="let facility of mixitFacilities$ | async">
        <ng-container *ngFor="let installation of facility.installations">
          <ng-container *ngIf="installation">
            <tr gbc-table-row *ngIf="getLatest(installation.licenseInformation) as licenses">
              <td gbc-table-cell>{{ facility.name }}</td>
              <td gbc-table-cell>
                <span class="installation-name">
                  <app-mixit-connection-status [installation]="installation"></app-mixit-connection-status>
                  <span class="name" title="{{ installation.name }}">{{ installation.name }}</span>
                </span>
                <span *ngIf="installation.parentInstallation">({{ installation.parentInstallation.name }})</span>
              </td>
              <td gbc-table-cell>
                <ng-container *ngIf="getSomeActivePaid(licenses); else freemium">
                  <ng-container *ngFor="let license of licenses">
                    <span class="block">
                      <ng-container *ngIf="license.isConnect">
                        {{ 'schematic.connect' | translate }}
                      </ng-container>
                      <ng-container *ngIf="license.isDynamic">
                        {{ 'schematic.dynamic' | translate }}
                      </ng-container>
                    </span>
                  </ng-container>
                </ng-container>
              </td>
              <td gbc-table-cell>{{ installation.serialNumber }}</td>
              <td gbc-table-cell>
                <ng-container *ngIf="getSomeActivePaid(licenses); else noActiveLicenseBlock">
                  <ng-container *ngFor="let license of licenses">
                    <span class="block" *ngIf="license.isActive">{{ 'license.type.' + getLicenseType(license) | translate }}</span>
                  </ng-container>
                </ng-container>
              </td>
              <td gbc-table-cell>
                <ng-container *ngIf="getSomeActivePaid(licenses); else dash">
                  <ng-container *ngFor="let license of licenses">
                    <span class="block" *ngIf="license.isActive">{{ license?.activationTimeEpoch | date }}</span>
                  </ng-container>
                </ng-container>
              </td>
              <td gbc-table-cell>
                <ng-container *ngIf="getSomeActivePaid(licenses); else dash">
                  <ng-container *ngFor="let license of licenses">
                    <span class="block" *ngIf="license.isActive && (license.isSubscription || license.isTrial)">{{
                      license?.expirationTimeEpoch | date
                    }}</span>
                    <span class="block" *ngIf="license.isActive && license.isOneTimeFee">&#8212;</span>
                  </ng-container>
                </ng-container>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
    </gbc-table>
    <div gbc-tile-right>
      <button gbc-button small [routerLink]="'/upgrade/subscribe/'">{{ 'account-page.upgrade-button' | translate }}</button>
    </div>
  </gbc-tile>
</gbc-page-container>

<ng-template #dash>
  <span class="block">&#8212;</span>
</ng-template>

<ng-template #freemium>
  <span class="block">{{ 'schematic.freemium' | translate }}</span>
</ng-template>

<ng-template #noActiveLicenseBlock>
  <span class="block">{{ 'license.type.free' | translate }}</span>
</ng-template>
