<div class="alarm-bar"
  *ngIf="alarm | async as alarm"
  [ngClass]="alarmClass | async"
  (click)="navigate()"
  angulartics2On="click"
  angularticsAction="alarmOrWarningClicked"
  angularticsCategory="installation">
  <img [src]="alarmStateToIconLink(alarm.type)">
  <div class="text-container">
    <div class="title">
      {{alarmTitle | async}}
    </div>
    <div>
      <div *ngIf="showDetailedInfo | async">{{alarm.metadata | formatAlarmMetadata }}: {{alarm.metadata.title}}</div>
    </div>
  </div>
  <div class="right">
    <div *ngIf="showDetailedInfo | async">{{alarm.currentActivation.activatedTimestampEpoch | timeWithAgo}}</div>
  </div>
</div>
