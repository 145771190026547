<gbc-tile [title]="'installation-activity-tile.title' | translate">

  <div *ngIf="viewMode === 'display' && activity; else empty" class="gbc-content-wrapper" data-test-id="gbc-activity-comments">
    <div class="gbc-content">
      <pre class="gbc-comment">{{ activity.text }}</pre>
      <div class="metadata">
        <span data-test-id="gbc-activity-metadata-last-edited-by">{{ 'installation-activity-tile.last-edited-by' | translate }} {{ activity.lastEditedBy }}</span>
        <span data-test-id="gbc-activity-metadata-last-edited-at" [attr.data-timestamp]="activity.lastEditedAt">, {{ activity.lastEditedAt | date:' dd.MM.y, HH:mm' }}</span>
      </div>
    </div>
    <button gbc-button ghost small icon-left (click)="onEdit()" data-test-id="gbc-activity-edit-button">
      <img src="./assets/svgs/edit.svg" />
      {{ 'app-edit' | translate }}</button>
  </div>

  <ng-template #empty>
    <gbc-message-tile
      *ngIf="viewMode === 'display'"
      icon='./assets/svgs/info-circle_outline.svg'
      [title]="'installation-activity-tile.no-activity-comments' | translate">
      <button gbc-button ghost small icon-left (click)="changeViewMode(ViewModeOptions.Edit)" data-test-id="gbc-activity-add-button">
        <img src="./assets/svgs/plus_outline.svg" />
        {{ 'installation-activity-tile.button-add-title' | translate }}
      </button>
    </gbc-message-tile>
  </ng-template>

  <div *ngIf="viewMode === 'edit'" class="gbc-content-wrapper">
    <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
      <div class="content">
        <textarea formControlName="comment" name="comment" rows="15" maxlength="3200" data-test-id="gbc-activity-comments-input"></textarea>
      </div>
      <div class="buttons">
        <button gbc-button ghost [disabled]="isLoading" (click)="changeViewMode(ViewModeOptions.Display)" data-test-id="gbc-activity-cancel-button">{{ 'app-cancel' | translate }}</button>
        <button *ngIf="isLoading === false" gbc-button type="submit" data-test-id="gbc-activity-save-button">{{ 'app-save' | translate }}</button>
        <button *ngIf="isLoading === true" gbc-button icon-center type="button" data-test-id="gbc-activity-spinner-button"><img class="spinner" src="./assets/svgs/loading.svg"></button>
      </div>
    </form>
  </div>

</gbc-tile>
