import classnames from 'classnames';
// @ts-ignore
import ChooseBumps from 'choosebumps';
import styled from 'styled-components';
import { QuestionTitle } from './QuestionTitle';
import React, { useCallback, useRef, useState } from 'react';
import { IDropdownQuestion } from '../../models/form.interface';
import { formService } from '../../services/formService';

export interface DropdownQuestionProps {
  question: IDropdownQuestion;
  data: any;
  onChange: (newData: any, oldData?: any) => void;
}

const borderActive = 'solid 2px #0066b0';
export const StyledDropdownQuestion = styled.div`
  //  Choosebumps styling
  .choosebumps {
    font-size: 18px;
    line-height: 1.58;
    border: 1px solid #eaeaea;
    width: 100%;
    outline: none;
    position: relative;
    box-sizing: border-box;
  }
  .choosebumps.cb--active,
  .choosebumps:focus {
    border-color: #ccc;
    outline: none;
  }
  .choosebumps *,
  .choosebumps :after,
  .choosebumps :before {
    box-sizing: border-box;
  }
  .choosebumps.disabled {
    background-color: #ddd !important;
    color: #aaa;
    cursor: not-allowed;
    pointer-events: all !important;
  }

  .choosebumps.disabled .cb-main-item {
    cursor: not-allowed;
    pointer-events: all !important;
  }

  .choosebumps.disabled .cb-selected-item {
    color: #aaa;
    cursor: not-allowed;
    pointer-events: all !important;
  }

  .cb-main-item {
    padding: 1em 3em 1em 1em;
    cursor: default;
  }
  .cb-main-item.cb-placeholder:before {
    content: attr(placeholder);
    opacity: 0.4;
  }
  .cb-caret {
    height: 1em;
    width: 1em;
    position: absolute;
    right: 1em;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  .cb-caret path {
    stroke: #333;
    stroke-width: 20px;
  }
  .cb-items {
    display: none;
    position: absolute;
    width: calc(100% + 2px);
    max-height: 300px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    top: calc(100% + 1px);
    left: -1px;
    z-index: 2;
    background: #fff;
    box-shadow: 0 15px 35px -10px rgba(0, 0, 0, 0.3);
    border: 1px solid #eaeaea;
    border-top: none;
  }
  .cb-items:empty:not([no-results-text]) {
    display: none !important;
  }
  .cb-items[no-results-text]:after {
    content: attr(no-results-text);
    padding: 1em;
    display: block;
  }
  .cb-items > div {
    padding: 1em;
    cursor: default;
  }
  .cb-items > div:not(:first-child) {
    border-top: 1px solid #fff;
  }
  .cb-items > div[category] {
    border-top: none;
  }
  .cb-items > div[category]:before {
    content: attr(category);
    display: block;
    margin: -1em -1em 1em;
    padding: 0.5em 1em;
    background: #eaeaea;
    color: #919191;
  }
  .cb-items > div:hover {
    background-color: #30323f;
    color: #fff;
  }
  .cb-selected-item {
    display: inline;
  }
  .cb-selected {
    background-color: #30323f;
    color: #fff;
  }
  .cb-tag {
    display: inline-block;
    background-color: #eaeaea;
    padding: 0 0.5em;
    border-radius: 0.25em;
    margin: 0.25em;
  }
  .cb-tag svg {
    cursor: pointer;
    width: 10px;
    height: 10px;
    margin-left: 10px;
    stroke-width: 50px;
    stroke: #000;
    position: relative;
    top: -2px;
  }
  .cb-active.cb-search-enabled .cb-placeholder:before {
    display: none;
  }
  .cb-active.cb-search-enabled .cb-selected-item {
    position: absolute;
    opacity: 0.1;
  }
  .cb-active .cb-items {
    display: block;
  }
  .cb-active .cb-search {
    display: inline;
  }
  .cb-search {
    display: none;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    z-index: 1;
    outline: none;
    border: none;
    background-color: transparent;
  }
  .cb-loader {
    position: absolute;
    top: 50%;
    margin-top: -0.5em;
    right: 1em;
    width: 1em;
    height: 1em;
    border: 2px solid;
    border-top-color: transparent;
    border-radius: 50%;
    animation: a 0.5s infinite linear;
    display: none;
  }
  @keyframes a {
    to {
      transform: rotate(1turn);
    }
  }

  .title {
    @include question-title;

    &.error {
      color: #ff0000;
    }
  }

  // Choose bumps styling

  .choosebumps {
    background-color: white;
    border: solid 2px #d3d9dd;
    height: 36px;

    &:focus {
      border-color: #0066b0;
    }

    &.error {
      border: solid 2px #ff0000;
    }
  }

  .cb-active {
    border-top: ${borderActive} !important;
    border-right: ${borderActive} !important;
    border-left: ${borderActive} !important;
    border-color-bottom: #d3d9dd !important;

    .cb-items {
      width: calc(100% + 4px);
      left: -2px;
      top: 100%;

      border-bottom: ${borderActive};
      border-left: ${borderActive};
      border-right: ${borderActive};
    }
  }

  .cb-active.disabled,
  .choosebumps:focus.disabled {
    border: solid 2px #d3d9dd !important;
  }

  .cb-caret {
    visibility: hidden;
    display: none;
  }

  .cb-main-item:after {
    content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgMTIgOCI+CiAgICA8cGF0aCBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLXdpZHRoPSIxLjU4NyIgZD0iTTEwLjUgMS41TDYgNi41bC00LjUtNSIvPgo8L3N2Zz4K');

    height: 1em;
    width: 1em;
    position: absolute;
    right: 6px;
    z-index: 1;

    // Magic numbers
    top: calc(50% - 18px);
    transform: translateY(calc(-50% + 12px));
  }

  .choosebumps.disabled .cb-main-item:after {
    filter: invert(76%) sepia(0%) saturate(5583%) hue-rotate(15deg) brightness(84%) contrast(100%);
  }

  .cb-main-item {
    padding: 0 !important;
  }

  .cb-placeholder::before {
    font-family: 'GrundfosTheSansV2', sans-serif;
    padding: 8px 12px !important;
    font-size: 14px;
  }

  .cb-items > *,
  .cb-selected-item {
    height: 36px;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 8px 12px !important;

    font-family: 'GrundfosTheSansV2', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.35px;
    color: #000000;
  }

  .choosebumps.disabled .cb-items {
    display: none;
  }

  .cb-items > div:hover {
    color: black;
    background-color: #edf0f2;
  }
`;

export const DropdownQuestion: React.FC<DropdownQuestionProps> = ({ question, data, onChange }) => {
  const [submitted, setSubmitted] = useState(false);

  const choosebumps = useRef<ChooseBumps>(null);
  const selectCallback = useRef<HTMLSelectElement>(null);

  // @ts-ignore
  selectCallback.current = (e: any) => {
    setSubmitted(true);
    onChange({ ...data, [question.name]: e.value }, { ...data });
  };

  const setValue = (val: any) => {
    if (!choosebumps.current) {
      return;
    }
    const item = choosebumps.current.items.find((i: any) => {
      return i.value === val;
    });
    choosebumps.current.select(item);
  };

  const dropdownContainer = useCallback((element) => {
    if (!element && choosebumps.current) {
      choosebumps.current = null;
    }
    if (element && !choosebumps.current) {
      const values = question.choices.map((c) => {
        if (typeof c === 'string') {
          return {
            value: c,
            text: c,
          };
        }
        return c;
      });
      choosebumps.current = ChooseBumps(element, {
        placeholder: 'Choose',
        items: values,
        minlength: 0,
        search: false,
        searchfields: '',
        noresults: '',
        multiple: false,
        template: '{{data.text}}',
        tagtemplate: null, // inherits from template if null
        selectedtemplate: null,
        // @ts-ignore
        onselect: (e) => selectCallback.current(e),
        onremove: null,
        onadd: null,
        categorize: null
      });
      setValue(data[question.name]);
    }
  }, []);

  setValue(data[question.name]);
  const isRequired = question.isRequired;
  const isDisabled = formService.isElementDisabled(question);
  const isVisible = formService.isElementVisible(question, data);
  const disabledTooltip = (isDisabled && question.disabledTooltip !== undefined && question.disabledTooltip !== "") ? question.disabledTooltip : undefined;

  const value = (isVisible && isDisabled && typeof question.disabledValue === 'string') ? question.disabledValue : data[question.name];

  const isInErrorState = (value === null || value === undefined) && isRequired && submitted;

  return (
    <StyledDropdownQuestion>
      <QuestionTitle text={question.title} hasError={isInErrorState} isRequired={isRequired} />
      <div ref={ dropdownContainer } className={ classnames({ choosebumps: true, error: isInErrorState, disabled: isDisabled }) } title={ disabledTooltip } />
    </StyledDropdownQuestion>
  );
};
