import React from 'react';
import { colors } from '../../util/colors';
import { EConnectionType } from '../state';
import { Direction, getRotation } from './Common';

interface SupplyTriangleProps {
  warm: boolean;
  x: number;
  y: number;
  direction: Direction;
}

export const SUPPLY_TRIANGLE_WIDTH = 10;
export const SUPPLY_TRIANGLE_HEIGHT = 13;

export const SupplyTriangle: React.FC<SupplyTriangleProps> = React.memo(({ warm, x, y, direction }) => {
  const color = warm ? colors.connections[EConnectionType.supply].regular : colors.connections[EConnectionType.return].regular;
  return (
    <polygon
      stroke="none"
      transform={`translate(${x}, ${y}) rotate(${getRotation(direction) + 180})`}
      points={`0,0 ${-Math.floor(SUPPLY_TRIANGLE_WIDTH / 2)}, ${-SUPPLY_TRIANGLE_HEIGHT} ${Math.floor(
        SUPPLY_TRIANGLE_HEIGHT / 2
      )},${-SUPPLY_TRIANGLE_HEIGHT}`}
      fill={color}
    />
  );
});
