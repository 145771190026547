import { Component, OnInit } from '@angular/core';
import { PageInfo, AppError, AppErrorService, ModalService, Result } from 'shared';
import { Observable, of, EMPTY, BehaviorSubject } from 'rxjs';
import { PageInfoService } from '../../services/page-info.service';
import { Facility, Installation } from '../../interfaces/facilty';
import { FacilityService } from '../../services/facility.service';
import { Router, ActivatedRoute } from '@angular/router';
import { flatMap, map, filter, first, catchError } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { EFacilityType } from 'projects/serviceportal/src/app/interfaces/facility';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IBuildingConnectUser, UserType } from '../../interfaces/user';
import { LicenseType } from '../../interfaces/mixit';
import { MIXIT_TRIAL_PERIOD, filterFacilityInstallationsBySingleLicense } from '../../utils/mixit-utils';
import { UpgradeLicenseService } from '../../services/upgrade-license.service';
import { IInstallationUpgradeRequest, EUpgradeResultStatusCodes, EUpgradeResultErrorCodes } from '../../interfaces/premium';
import { MixitUpgradeStatusModalComponent, IInstallationUpgradeStatus } from '../../components/mixit-upgrade-status-modal/mixit-upgrade-status-modal.component';
import { Location } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { TERMS_AND_CONDITIONS_LINK, TERMS_OF_USE_LINK } from '../../utils/links';
import { formValues } from '../../../../../shared/src/lib/constants/form-values';

@Component({
  selector: 'app-trial-flow-page',
  templateUrl: './trial-flow-page.component.html',
  styleUrls: ['./trial-flow-page.component.scss'],
})
export class TrialFlowPageComponent implements OnInit {
  public currentUser$: Observable<IBuildingConnectUser | null>;
  public pageInfo: PageInfo;
  public facilities$: Observable<Facility[]>;
  public pageError$: Observable<AppError>;
  public deviceType = 'MIXIT';
  public canContinue: boolean;
  public requests: IInstallationUpgradeRequest[] = [];
  public selectedInstallationsByFacility$: BehaviorSubject<Installation[]> = new BehaviorSubject<Installation[]>([]);
  public canContinue$: Observable<boolean>;
  public preSelectedInstallation: string;

  public TERMS_AND_CONDITIONS_LINK = TERMS_AND_CONDITIONS_LINK;
  public TERMS_OF_USE_LINK = TERMS_OF_USE_LINK;
  public MixitTrialPeriod = MIXIT_TRIAL_PERIOD;

  public licenseInfo = new UntypedFormGroup({
    companyName: new UntypedFormControl('', [Validators.required,  Validators.maxLength(formValues.max_length.name)]),
    street: new UntypedFormControl('', [Validators.required,  Validators.maxLength(formValues.max_length.address)]),
    postalCode: new UntypedFormControl('', [Validators.required,  Validators.maxLength(formValues.max_length.postalCode)]),
    city: new UntypedFormControl('', [Validators.required,  Validators.maxLength(formValues.max_length.address)]),
    country: new UntypedFormControl('', [Validators.required,  Validators.maxLength(formValues.max_length.address)]),
    region: new UntypedFormControl('',  Validators.maxLength(formValues.max_length.name)),
    fullName: new UntypedFormControl('',  Validators.maxLength(formValues.max_length.address)),
    phoneNumber: new UntypedFormControl('',  Validators.maxLength(formValues.max_length.phone)),
    email: new UntypedFormControl('',  Validators.maxLength(formValues.max_length.email)),
    termsOfUse: new UntypedFormControl(false, [Validators.required]),
    termsAndConditions: new UntypedFormControl(false, [Validators.required]),
    other: new UntypedFormControl(false),
  });

  constructor(
    private pageInfoService: PageInfoService,
    private errorService: AppErrorService,
    private facilityService: FacilityService,
    private userService: UserService,
    private upgradeService: UpgradeLicenseService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.facilities$ = this.facilityService.facilities$.pipe(
      filter((f) => !!f),
      // first(),
      map((facilities) => {
        const filteredFacilities = (facilities as Facility[])
          .filter((f) => f.facilityType === EFacilityType.MIXIT)
          .sort((a, b) => a.name.localeCompare(b.name));
        return filterFacilityInstallationsBySingleLicense(filteredFacilities, LicenseType.Freemium);
      })
    );
    this.pageError$ = this.errorService.createPageErrorObservable([this.userService.initialUser$, this.facilities$]);
    this.pageInfo = this.pageInfoService.trialFlow();
    this.currentUser$ = this.userService.currentUser$.pipe(
      flatMap((user) => {
        // OIDC users do not have a profile page, so we filter them
        if (user?.type === UserType.OIDC) {
          return EMPTY;
        }
        return of(user);
      })
    );

    this.preSelectedInstallation = this.route.snapshot.queryParamMap.get('installationId') as string;

    this.canContinue$ = this.selectedInstallationsByFacility$.pipe(map((installations) => !!installations.length));
  }

  onSelectInstallations(facilities: Facility[]) {
    this.selectedInstallationsByFacility$.next(facilities.flatMap((facility) => facility.installations));
  }

  done(): void {
    // Todo: Refactor the this.requests list, so a request is added to the list upon installation selection
    // to allow us to catch potential errors before the user reaches "done".
    this.selectedInstallationsByFacility$.pipe(first()).subscribe((installations) => {
      this.requests = installations.map((installation) => {
        return {
          request: this.upgradeService
            .upgradeLicense(installation.id, {
              licenseType: LicenseType.CodeConnectTrial,
              licensePurchaseInformation: this.licenseInfo.value,
            })
            .pipe(
              map(() => {
                return {
                  status: EUpgradeResultStatusCodes.SUCCESS,
                  message: installation.name,
                };
              }),
              catchError((e: HttpErrorResponse) => {
                return of({
                  status: EUpgradeResultStatusCodes.ERROR,
                  code: e.headers?.get('ErrorCode') as EUpgradeResultErrorCodes,
                  message: e.message,
                });
              })
            ),
          installationId: installation.id,
          installationName: installation.name as string,
        };
      });
    });

    this.modalService
      .openDialog<Result<IInstallationUpgradeStatus[]>>(MixitUpgradeStatusModalComponent, {
        data: { status: EUpgradeResultStatusCodes.PENDING, requests: this.requests },
      })
      .subscribe((result) => {
        if (!result.dismissed) {
          // @ts-ignore
          const upgradeResults = result.result as IInstallationUpgradeStatus[];
          if (
            this.preSelectedInstallation &&
            upgradeResults.length === 1 &&
            !upgradeResults.some((r) => r.status === EUpgradeResultStatusCodes.ERROR)
          ) {
            this.location.back();
          } else {
            this.router.navigate([`/account`, { tab: 'devices' }]);
          }
        }
      });
  }
}
