// Default deltas used for temperature sensors when placing label at end of sensor pin
export const LargeLabelDeltas = {
  NORTH_X: -80,
  SOUTH_X: 40,
  WEST_Y: 30,
  EAST_Y: -30,
};

// Label deltas used for pumps and flowmeters and temperature sensors when placing label next to sensor pin
export const SmallLabelDeltas = {
  NORTH_X: -72,
  SOUTH_X: 30,
  WEST_Y: 30,
  EAST_Y: -30,
  TEMP_SENSOR_HORIZONTAL_DX: 3,
};
