import { Component, OnInit, Inject } from '@angular/core';
import { MODAL_CONTROLLER, ModalController } from 'shared';

@Component({
  selector: 'app-user-role-explainer-modal',
  templateUrl: './user-role-explainer-modal.component.html',
  styleUrls: ['./user-role-explainer-modal.component.scss'],
})
export class UserRoleExplainerModalComponent {
  constructor(@Inject(MODAL_CONTROLLER) private controller: ModalController<void>) {
  }

  dismiss() {
    this.controller.dismiss();
  }
}
