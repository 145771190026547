import { AlarmActivation, AlarmComment, AlarmType } from './system-status';
import { DeviceDataPoint } from './data-points';
import { Observable } from 'rxjs';
import { SystemType } from '../../../../dumbledore/src/schematic/state';

export type MixingLoopType = 'RadiatorOneStringHeating' | 'RadiatorTwoStringHeating' | 'UnderfloorHeating' | 'FanCoilSystem';
export type HeatExchangerType = 'RadiatorOneStringHeating' | 'RadiatorTwoStringHeating' | 'UnderfloorHeating' | 'Supply';

export type ApplicationSubType = MixingLoopType | HeatExchangerType;

export interface Application {
  id: string;
  title: Observable<string>;
  type: SystemType;
  subtype?: ApplicationSubType;
  subSystems?: Application[];
}

export interface RemedyStep {
  command: string;
}

export interface AlarmCause {
  description: string;
  remedySteps: RemedyStep[];
}

export interface AlarmMetadata {
  productName: string;
  mainErrorCode: string;
  subErrorCode: string;
  title: string;
  conditions: string[];
  causes: AlarmCause[];
}

export interface AlarmOverview {
  id: number;
  type: AlarmType;
  application?: Application;
  currentActivation: AlarmActivation;
  metadata: AlarmMetadata;
  installationName: string;
  showDetailedInfo: boolean;
}

export interface AlarmExtended extends AlarmOverview {
  comments: AlarmComment[];
  history: AlarmActivation[];
}

// Note that the enum values are taken from the GENI spec, do not change.
export enum PumpControlMode {
  ConstantPressure = 'ConstantPressure',
  ProportionalPressure = 'ProportionalPressure',
  ConstantFrequency = 'ConstantFrequency',
  AutoAdapt = 'AutoAdapt',
  ConstantTemperature = 'ConstantTemperature',
  ClosedLoopSensorControl = 'ClosedLoopSensorControl',
  ConstantFlow = 'ConstantFlow',
  ConstantLevel = 'ConstantLevel',
  FlowAdapt = 'FlowAdapt',
  ConstantDifferentialPressure = 'ConstantDifferentialPressure',
  ConstantDifferentialTemperature = 'ConstantDifferentialTemperature',
  Other = 'Other',
}

// Note that the enum values are taken from the GENI spec, do not change.
export enum OperatingMode {
  Other = 'Other',
  Normal = 'Normal',
  Stop = 'Stop',
  Min = 'Min',
  Max = 'Max',
  Hand = 'Hand'
}

export interface PumpData {
  controlMode: DeviceDataPoint | undefined;
  operatingMode: DeviceDataPoint | undefined;
  power?: DeviceDataPoint | undefined;
  estimatedFlow?: DeviceDataPoint | undefined;
  impellerSpeed?: DeviceDataPoint | undefined;
  // impellerSpeedRelative: number;
  differentialPressure?: DeviceDataPoint | undefined;
  setPoint: DeviceDataPoint | undefined;
}
