import { Injectable, Injector } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ISnackBarRef, SNACK_BAR_DATA, SNACK_BAR_REF } from '../interfaces/snack-bar';
import { ComponentPortal } from '@angular/cdk/portal';
import { SnackBarComponent } from '../components/snack-bar/snack-bar.component';

export interface ISnackBarOptions {
  duration?: number;
}

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  DEFAULT_DURATION = 7000;

  constructor(private overlay: Overlay) {
  }

  currentSnackBar: ISnackBarRef | null = null;

  public show(message: string, {duration}: ISnackBarOptions = {}): ISnackBarRef {
    // there can only be one snackbar
    if (this.currentSnackBar) {
      this.currentSnackBar.dismiss();
    }
    const positionStrategy = this.overlay.position()
        .global()
        .centerHorizontally()
        .bottom('50px');
    const overlayRef = this.overlay.create({positionStrategy});
    const snackBarRef = {
      dismiss: () => {
        // this snackbar may already be disposed or replaced by a new one
        if (this.currentSnackBar && this.currentSnackBar === snackBarRef) {
          overlayRef.dispose();
          this.currentSnackBar = null;
        }
      }
    };
    this.currentSnackBar = snackBarRef;
    const snackBarInjector = Injector.create({
      providers: [{provide: SNACK_BAR_DATA, useValue: {message}}, {provide: SNACK_BAR_REF, useValue: snackBarRef}]
    });

    const snackBarPortal = new ComponentPortal(SnackBarComponent, null, snackBarInjector);
    overlayRef.attach(snackBarPortal);
    setTimeout(() => snackBarRef.dismiss(), duration || this.DEFAULT_DURATION);
    return snackBarRef;
  }
}
