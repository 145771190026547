import { Component, OnInit } from '@angular/core';
import { IBackendUser} from '../../interfaces/user';
import { AccessClaim, EAccessLevel } from 'projects/shared/src/lib/interfaces/access'
import { Observable, throwError } from 'rxjs';
import { UserService } from '../../services/user.service';
import { PageInfoService } from '../../services/page-info.service';
import { AppError, AppErrorService, FORBIDDEN_ERROR_CODE, PageInfo } from 'shared';
import { catchError, filter, map } from 'rxjs/operators';
import { SocketService } from '../../services/socket.service';

@Component({
  selector: 'app-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss']
})
export class UsersPageComponent implements OnInit {

  public users$: Observable<IBackendUser[]>;
  public pageError$: Observable<AppError>;
  public pageInfo: PageInfo;

  constructor(
    private userService: UserService,
    private pageInfoService: PageInfoService,
    private errorService: AppErrorService,
  ) { }

  ngOnInit(): void {
    this.users$ = this.userService.users$.pipe(
      filter(u => !!u),
      map(users => [...(users || [])].sort((a, b) => a.name.localeCompare(b.name)))
    );

    const hasUserManagementClaim$ = this.userService.hasClaim(AccessClaim.CustomerPortalUserManagement).pipe(
      map(hasClaim => {
        if (!hasClaim) {
          throw Error();
        }
        return true;
      }),
      catchError((e) => {
        return throwError(this.errorService.createError({
          ...FORBIDDEN_ERROR_CODE[403],
          report: false
        }));
      })
    );

    this.pageError$ = this.errorService.createPageErrorObservable([this.users$, hasUserManagementClaim$]);

    this.pageInfo = this.pageInfoService.viewUsers();
  }

  public translateRole(role: EAccessLevel) {
    return this.userService.translateRole(role);
  }

}
