<app-pasteurization-status-tile
  [dataPoints$]="dataPoints$"
  [installationId]="(installation$ | async)?.id"
  [systemId]="application.id">
</app-pasteurization-status-tile>

<app-mixit-x509-banner/>
<app-alarm-bar *ngIf="alarm$ | async" [alarm]="alarm$"> </app-alarm-bar>

<app-schematics-tile
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [showShadow]="false"
  [dataPoints$]="dataPoints$">
</app-schematics-tile>

<app-setpoint-tile
  [application]="application"
  [installation$]="installation$"
  [dataPoints$]="dataPoints$"
  [setpointClientId]="'Application_TemperatureSetpoint'"
  [headerTitle]="'commercial-hot-water-dashboard-standalone.setpoint-tile-title' | translate">
</app-setpoint-tile>

<app-pump-status-tile
  *ngIf="pumpDevice$ | async"
  [installation$]="installation$"
  [application]="application"
  [pumpType$]="pumpType$"
  [pumpDevice$]="pumpDevice$"
  [pumpOptions]="pumpOptions$ | async">
</app-pump-status-tile>
