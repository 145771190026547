import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { DataPointsResult, DeviceDataPoint, EBackendUnit } from '../../../interfaces/data-points';
import { Installation } from '../../../interfaces/facilty';
import { AppModel } from '@ams/dumbledore';
import { Application } from '../../../interfaces/alarm';
import { getDatapoint } from '../../../utils/mixit-utils';
import { MixitDataPointName } from '../../../interfaces/mixit';
import { first, flatMap, map } from 'rxjs/operators';
import { parseBoolean } from '../../../utils/data-point-utils';
import { MixitHeatCoilProtectionInput, MixitHeatCoilProtectionModalComponent, MixitHeatCoilProtectionResponse } from '../mixit-heat-coil-protection-modal/mixit-heat-coil-protection-modal.component';
import { ICommand, MixitService } from '../../../services/mixit-service.service';
import { ModalService } from 'shared';
import { SchematicsService } from '../../../services/schematics.service';
import { TranslateService } from '@ngx-translate/core';
import { SystemDeviceType } from '../../../interfaces/systemDeviceType';

@Component({
  selector: 'app-heat-coil-protection-tile',
  templateUrl: './heat-coil-protection-tile.component.html',
  styleUrls: ['./heat-coil-protection-tile.component.scss'],
})
export class HeatCoilProtectionTileComponent implements OnInit {
  @Input() installation$: Observable<Installation>;
  @Input() schematic$: Observable<AppModel>;
  @Input() dataPoints$: Observable<DataPointsResult>;
  @Input() application$: Observable<Application>;

  @Input() public coilPreHeatEnabled$: Observable<boolean>;
  @Input() public coilPreheatReturnTemperature$: Observable<DeviceDataPoint | null>;

  @Input() public frostProtectionEnabled$: Observable<boolean>;
  @Input() public frostProtectionAirTemperature$: Observable<DeviceDataPoint | null>;
  @Input() public frostProtectionReturnFlowTemperature$: Observable<DeviceDataPoint | null>;

  private isAdjustmentInProgress = false;

  constructor(
    private translateService: TranslateService,
    private schematicService: SchematicsService,
    private modalService: ModalService,
    private mixitService: MixitService
  ) {}

  ngOnInit(): void {
    this.coilPreHeatEnabled$ = getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.CoilPreheatEnabled).pipe(
      map((d) => parseBoolean(d?.value as string))
    );
    this.coilPreheatReturnTemperature$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.CoilPreheatReturnTemp
    );

    this.frostProtectionEnabled$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.FrostProtectInternal
    ).pipe(map((d) => parseBoolean(d?.value as string)));

    this.frostProtectionReturnFlowTemperature$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.FrostReturnTempLimit
    );
    this.frostProtectionAirTemperature$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.FrostZoneAirSupplyTempLimit
    );
  }

  public adjustCoilHeatProtection() {
    if (this.isAdjustmentInProgress) { return; }
    this.isAdjustmentInProgress = true;

    const heatCoilMeta$ = this.application$.pipe(
      map((application) => application.id),
      flatMap((id) => this.schematicService.getEquipmentMetaInfo(id)),
      map((metas) => {
        return metas.find((m) => m.term === MixitDataPointName.CoilPreheatReturnTemp);
      })
    );

    const frostAirSupplyMeta$ = this.application$.pipe(
      map((application) => application.id),
      flatMap((id) => this.schematicService.getEquipmentMetaInfo(id)),
      map((metas) => {
        return metas.find((m) => m.term === MixitDataPointName.FrostZoneAirSupplyTempLimit);
      })
    );

    const frostReturnTempMeta$ = this.application$.pipe(
      map((application) => application.id),
      flatMap((id) => this.schematicService.getEquipmentMetaInfo(id)),
      map((metas) => {
        return metas.find((m) => m.term === MixitDataPointName.FrostReturnTempLimit);
      })
    );

    combineLatest([
      this.installation$,
      this.application$,
      this.coilPreHeatEnabled$,
      this.coilPreheatReturnTemperature$,
      this.frostProtectionEnabled$,
      this.frostProtectionReturnFlowTemperature$,
      this.frostProtectionAirTemperature$,
      heatCoilMeta$,
      frostAirSupplyMeta$,
      frostReturnTempMeta$,
    ])
      .pipe(first())
      .subscribe(
        ([
          installation,
          application,
          heatCoilProtectionEnabled,
          coilProtectionTemperature,
          frostProtectionEnabled,
          frostProtectionTemperature,
          frostProtectionAirTemperature,
          heatCoilMeta,
          frostAirSupplyMeta,
          frostReturnTempMeta,
        ]) => {
          const data: MixitHeatCoilProtectionInput = {
            // @ts-ignore
            enableCoilPreheatReturnTemperature: heatCoilProtectionEnabled,
            // @ts-ignore
            coilPreheatReturnTemperature: parseFloat(coilProtectionTemperature?.value) || null,
            // @ts-ignore
            coilPreHeatReturnTemperatureMeta: heatCoilMeta,
            // @ts-ignore
            enableFrostProtection: frostProtectionEnabled,
            // @ts-ignore
            frostProtectionReturnFlowTemperature: parseFloat(frostProtectionTemperature?.value) || null,
            // @ts-ignore
            frostProtectionReturnFlowTemperatureMeta: frostReturnTempMeta,
            // @ts-ignore
            frostProtectionAirTemperature: parseFloat(frostProtectionAirTemperature?.value) || null,
            // @ts-ignore
            frostProtectionAirTemperatureMeta: frostAirSupplyMeta,
          };

          this.modalService
            .openDialog<MixitHeatCoilProtectionResponse>(MixitHeatCoilProtectionModalComponent, {
              data,
            })
            .subscribe((response) => {
              if (response.dismissed) {
                this.isAdjustmentInProgress = false;
                return;
              }
              const res = response.result;

              const commands: ICommand[] = [
                {
                  term: MixitDataPointName.CoilPreheatEnabled,
                  value: res.enableCoilPreheatReturnTemperature?.toString() || null,
                  unit: EBackendUnit.None,
                },
                {
                  term: MixitDataPointName.CoilPreheatReturnTemp,
                  value: res.coilPreheatReturnTemperature?.toString() || null,
                  unit: EBackendUnit.DegreeCelsius,
                },

                {
                  term: MixitDataPointName.FrostProtectInternal,
                  value: res.enableFrostProtection?.toString() || null,
                  unit: EBackendUnit.None,
                },
                {
                  term: MixitDataPointName.FrostReturnTempLimit,
                  value: res.frostProtectionReturnFlowTemperature?.toString() || null,
                  unit: EBackendUnit.DegreeCelsius,
                },
                {
                  term: MixitDataPointName.FrostZoneAirSupplyTempLimit,
                  value: res.frostProtectionAirTemperature?.toString() || null,
                  unit: EBackendUnit.DegreeCelsius,
                },
              ];

              this.mixitService.sendCommand(installation as Installation, (application as Application).id, commands);

              this.isAdjustmentInProgress = false;
            });
        }
      );
  }
}
