import {Pipe, PipeTransform} from '@angular/core';
import {AlarmType} from '../interfaces/system-status';

@Pipe({
  name: 'alarmIconLink'
})
export class AlarmIconLinkPipe implements PipeTransform {

  transform(type: AlarmType): string {
    switch (type) {
      case AlarmType.Alarm:
        return './assets/svgs/alarm.svg';
      case AlarmType.Warning:
        return './assets/svgs/warning.svg';
      default:
        return '';
    }
  }

}
