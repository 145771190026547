import classnames from 'classnames';
import styled from 'styled-components';
import React, { useState } from 'react';
import { QuestionTitle } from './QuestionTitle';
import { IBooleanQuestion } from '../../models/form.interface';
import { formService } from '../../services/formService';

export interface BooleanQuestionProps {
  question: IBooleanQuestion;
  data: any;
  onChange: (newData: any, oldData?: any) => void;
}

const StyledBooleanQuestion = styled.div`
  button {
    cursor: pointer;
    appearance: none;
    border: none;

    width: 42px;
    height: 27px;
    padding: 0px 7px;
    margin-right: 3px;
    background-color: #ccd5da;
    color: #000;

    font-family: 'GrundfosTheSansV2';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.35px;
    text-align: center;
  }

  button[disabled] {
    background-color: #ddd !important;
    color: #aaa;
    cursor: not-allowed;
    pointer-events: all !important;
  }

  button[disabled].selected {
    background-color: #aaa !important;
    color: #ccc;
  }

  .selected {
    background-color: #0069d0;
    color: #fff;
  }
`;

export const BooleanQuestion: React.FC<BooleanQuestionProps> = ({ question, data, onChange }) => {

  const [submitted, setSubmitted] = useState(false);
  const isRequired = question.isRequired;
  const isDisabled = formService.isElementDisabled(question);
  const isVisible = formService.isElementVisible(question, data);
  const disabledTooltip = (isDisabled && question.disabledTooltip !== undefined && question.disabledTooltip !== "") ? question.disabledTooltip : undefined;

  const value = (isVisible && isDisabled && typeof question.disabledValue === 'boolean') ? question.disabledValue : data[question.name];

  const isInErrorState = (value === null || value === undefined) && isRequired && submitted;

  const valueIsFalse = (typeof value === 'boolean' && !value);

  const setValue = (newValue: boolean) => {
    setSubmitted(true);
    onChange({ ...data, [question.name]: newValue }, { ...data });
  };

  return (
    <StyledBooleanQuestion>
      <QuestionTitle text={question.title} hasError={isInErrorState} isRequired={isRequired} />
      <div>
        <button type="button" className={classnames({ selected: value })} disabled={ isDisabled } title={ disabledTooltip } onClick={() => setValue(true)}>
          YES
        </button>
        <button type="button" className={classnames({ selected: valueIsFalse })} disabled={ isDisabled } title={ disabledTooltip } onClick={() => setValue(false)}>
          NO
        </button>
      </div>
    </StyledBooleanQuestion>
  );
};
