import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, shareReplay, tap } from 'rxjs/operators';
import { AuthService, LanguageService } from 'shared';
import { HealthCheckMetadataDto } from '../interfaces/installation';

@Injectable({
  providedIn: 'root',
})
export class HealthCheckService {
  private healthCheckMetadatasSource: BehaviorSubject<HealthCheckMetadataDto[]> = new BehaviorSubject<HealthCheckMetadataDto[]>([]);
  public healthCheckMetadatas$: Observable<HealthCheckMetadataDto[]> = this.healthCheckMetadatasSource.asObservable();

  constructor(
    private httpClient: HttpClient,
    private languageService: LanguageService,
    private authService: AuthService,
  ) {
    combineLatest([this.languageService.currentLanguage, this.authService.authenticated]).pipe(
      mergeMap(([language, authenticated]) => {
        if (!authenticated) {
          return of([]);
        }
        return this.httpClient.get<HealthCheckMetadataDto[]>(`${environment.serverUrl}/api/installations/healthCheck/metadata/${language}`).pipe(
          catchError((e) => {
            return of([]);
          })
        );
      }),
      tap(metadata => this.healthCheckMetadatasSource.next(metadata)),
      shareReplay(1)
    ).subscribe();
  }

  getMetadataForHealthCheck(healthCheckTargetKey: string, healthCheckKey: string): Observable<HealthCheckMetadataDto | null | undefined> {
    return this.healthCheckMetadatas$.pipe(
      map(metadata => {
        const result = metadata.find(m => m.healthCheckTargetKey === healthCheckTargetKey && m.healthCheckKey === healthCheckKey)
        return result;
      }
    ));
  }
}
