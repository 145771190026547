export enum BoosterDataPoints {
  NoOfZonePumps = "gfdm:NO_OF_ZONE_PUMPS",
  SystemAlarmCode = "gfdm:SYSTEM_ALARM_CODE",
  SystemWarningCode = "gfdm:SYSTEM_WARNING_CODE",
  SystemActMode1 = "gfdm:SYSTEM_ACT_MODE1",
  SystemActMode3 = "gfdm:SYSTEM_ACT_MODE3",
  SystemPower = "gfdm:SYSTEM_POWER_AVG",
  SystemEnergy = "gfdm:SYSTEM_ENERGY_AVG",
  SystemPrepress = "gfdm:SYSTEM_PREPRESS_AVG",
  Flow = "gfdm:FLOW_AVG",
  Volume = "gfdm:VOLUME_AVG",
  SystemDiffPress = "gfdm:SYSTEM_DIFF_PRESS_AVG",
  SysRef = 'gfdm:SYS_REF_AVG',
  SystemPress = 'gfdm:SYSTEM_PRESS_AVG',
  SystemSpecEnergy = 'gfdm:SYSTEM_SPEC_ENERGY_AVG',
  SystemInDiffPress = 'gfdm:SYSTEM_IN_DIFF_PRESS_AVG',
  SystemOutDiffPress = 'gfdm:SYSTEM_OUT_DIFF_PRESS_AVG',
  P1OperatingTime = "gfdm:P1_OPERATING_TIME_AVG",
  P2OperatingTime = "gfdm:P2_OPERATING_TIME_AVG",
  P3OperatingTime = "gfdm:P3_OPERATING_TIME_AVG",
  P4OperatingTime = "gfdm:P4_OPERATING_TIME_AVG",
  P5OperatingTime = "gfdm:P5_OPERATING_TIME_AVG",
  P6OperatingTime = "gfdm:P6_OPERATING_TIME_AVG",
  P1Energy = "gfdm:P1_ENERGY_AVG",
  P2Energy = "gfdm:P2_ENERGY_AVG",
  P3Energy = "gfdm:P3_ENERGY_AVG",
  P4Energy = "gfdm:P4_ENERGY_AVG",
  P5Energy = "gfdm:P5_ENERGY_AVG",
  P6Energy = "gfdm:P6_ENERGY_AVG",
  P1Power = "gfdm:P1_POWER_AVG",
  P2Power = "gfdm:P2_POWER_AVG",
  P3Power = "gfdm:P3_POWER_AVG",
  P4Power = "gfdm:P4_POWER_AVG",
  P5Power = "gfdm:P5_POWER_AVG",
  P6Power = "gfdm:P6_POWER_AVG",
  P1FAct = 'gfdm:P1_F_ACT_AVG',
  P2FAct = 'gfdm:P2_F_ACT_AVG',
  P3FAct = 'gfdm:P3_F_ACT_AVG',
  P4FAct = 'gfdm:P4_F_ACT_AVG',
  P5FAct = 'gfdm:P5_F_ACT_AVG',
  P6FAct = 'gfdm:P6_F_ACT_AVG',
  P1AlarmCode = "gfdm:P1_ALARM_CODE",
  P2AlarmCode = "gfdm:P2_ALARM_CODE",
  P3AlarmCode = "gfdm:P3_ALARM_CODE",
  P4AlarmCode = "gfdm:P4_ALARM_CODE",
  P5AlarmCode = "gfdm:P5_ALARM_CODE",
  P6AlarmCode = "gfdm:P6_ALARM_CODE",
  P1ActMode1 = "gfdm:P1_ACT_MODE1",
  P2ActMode1 = "gfdm:P2_ACT_MODE1",
  P3ActMode1 = "gfdm:P3_ACT_MODE1",
  P4ActMode1 = "gfdm:P4_ACT_MODE1",
  P5ActMode1 = "gfdm:P5_ACT_MODE1",
  P6ActMode1 = "gfdm:P6_ACT_MODE1",
}
