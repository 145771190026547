import React, { useCallback } from 'react';

export interface HtmlPanelContentProps {
  htmlText: string;
}

export const HtmlPanelContent: React.FC<HtmlPanelContentProps> = React.memo(({ htmlText }) => {
  const containerElm = useCallback((elm) => {
    if (htmlText) {
      elm.innerHTML = htmlText;
    }
  }, []);
  return <div ref={containerElm} />;
});
