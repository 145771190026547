import { LabelPosition, SystemMeta } from '../../types';
import { LargeLabelDeltas } from '../../../util/constants';
import { EConnectionType, SystemType } from '../../../state';
import { EConnectionPointPlacement, EEquipment } from '../../../types';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/pressure-sensor-system.svg';
import pressureSensorSystemForm from '../../../../models/systems/pressureSensorSystem/pressureSensorSystemForm.json';
import { IPressureSensorSystemFormInterface } from '../../../../models/systems/pressureSensorSystem/pressureSensorSystemFormInterface';

export const HEIGHT = 200;
export const WIDTH = 200;
export const PIPE_Y = 140;

export const pressureSensorMetadata: SystemMeta = {
  systemType: SystemType.PressureSensorSystem,
  name: 'Pressure Sensor System',
  thumbnail,
  form: pressureSensorSystemForm,
  width: WIDTH,
  height: HEIGHT,
  calculateConnectionPoints: (systemInfo: IPressureSensorSystemFormInterface) =>
    systemInfo.Location === 'Forward'
      ? [
          {
            key: EConnectionPointPlacement.connectionPrimaryForward,
            x: 0,
            y: PIPE_Y,
            type: EConnectionType.supply,
            dotted: false,
          },
          {
            key: EConnectionPointPlacement.connectionSecondaryForward,
            x: WIDTH,
            y: PIPE_Y,
            type: EConnectionType.supply,
            dotted: false,
          },
        ]
      : [
          {
            key: EConnectionPointPlacement.connectionPrimaryReturn,
            x: 0,
            y: PIPE_Y,
            type: EConnectionType.return,
            dotted: true,
          },
          {
            key: EConnectionPointPlacement.connectionSecondaryReturn,
            x: WIDTH,
            y: PIPE_Y,
            type: EConnectionType.return,
            dotted: true,
          },
        ],
};

export const getLabels = (systemInfo: IPressureSensorSystemFormInterface): LabelPosition[] => {
  if (systemInfo.hasDirtCollector) {
    return [
      {
        id: EEquipment.H_DIFF_P,
        x: WIDTH / 2 + 15,
        y: PIPE_Y - 60,
      },
    ];
  }

  if (systemInfo.Location === 'Forward') {
    return [
      {
        id: EEquipment.H_F,
        x: WIDTH / 2,
        y: PIPE_Y - 15 + LargeLabelDeltas.EAST_Y,
        horizontalAlignment: 'middle',
      },
    ];
  }

  return [
    {
      id: EEquipment.H_R,
      x: WIDTH / 2,
      y: PIPE_Y - 15 + LargeLabelDeltas.EAST_Y,
      horizontalAlignment: 'middle',
    },
  ];
};
