import { Router } from '@angular/router';
import { ECommissionStatus } from 'shared';
import { Facility, Installation } from '../interfaces/facilty';
import { EFacilityType } from '../../../../serviceportal/src/app/interfaces/facility';

const firstInstallationIsLive = (installations: Installation[]) => {
  return installations[0].commissionStatus && installations[0].commissionStatus === ECommissionStatus.Live;
};

export const navigateToFacility = (router: Router, facility: Facility) => {
  const topLevelInstallations  =  facility.installations.filter(installation => !installation.parentInstallationId);  // subinstallations won't be shown in the installation list view, so don't include them in the count
  const hasOneInstallation = topLevelInstallations.length === 1;
  const isMixit = facility.facilityType === EFacilityType.MIXIT;

  // We don't want to navigate to the installation if it is buildingConnect and not live
  // But if is not live and a mixit, we can navigate safely to it
  const navigateDirectly = isMixit ? hasOneInstallation : hasOneInstallation && firstInstallationIsLive(topLevelInstallations);

  if (navigateDirectly) {
    router.navigate(['/facility', facility.id, 'installation', topLevelInstallations[0].id], { queryParams: {} });
  } else {
    router.navigate(['/facility', facility.id], { queryParams: {} });
  }
};

export const navigateToInstallation = (router: Router, facilityId: number, installationId: string) => {
  router.navigate(['/facility', facilityId, 'installation', installationId]);
};
