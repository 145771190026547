import React from 'react';
import { colors } from '../../util/colors';
import { pointsToSvgPathD } from './Common';

export const FLOWMETER_SIZE = 30;

export interface FlowMeterProps {
  x: number;
  y: number;
}

const flowMeterDiamondPoints = [
  [-FLOWMETER_SIZE / 2, 0],
  [0, -FLOWMETER_SIZE / 2],
  [FLOWMETER_SIZE / 2, 0],
  [0, FLOWMETER_SIZE / 2],
  [-FLOWMETER_SIZE / 2, 0],
];

export const FlowMeter: React.FC<FlowMeterProps> = React.memo(({ x, y }) => {
  return (
    <path
      key="diamond"
      transform={`translate(${x}, ${y})`}
      stroke="#313131"
      strokeWidth="1.5"
      fill={colors.background}
      d={pointsToSvgPathD(flowMeterDiamondPoints)}
    />
  );
});
