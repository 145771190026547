import meta from '../assets/version.json';
const version = meta.version;

import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { InstallationPageComponent } from './pages/installation-page/installation-page.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { FacilityPageComponent } from './pages/facility-page/facility-page.component';
import { FacilityMapPageComponent } from './pages/facility-map-page/facility-map-page.component';
import { RootPageComponent } from './pages/root-page/root-page.component';
import { BuildingconnectFacilityPageComponent } from './pages/buildingconnect-facility-page/buildingconnect-facility-page.component';
import { BuildingConnectInstallationPageComponent } from './pages/building-connect-installation-page/building-connect-installation-page.component';
import { SchematicsTileComponent } from './components/schematics-tile/schematics-tile.component';
import { AlarmTableComponent } from './components/alarms/alarm-table/alarm-table.component';
import { InstallationActivityTileComponent } from './components/installation-activity-tile/installation-activity-tile.component';
import { InstallationSystemStatusComponent } from './components/installation-system-status/installation-system-status.component';
import { ApplicationPageComponent } from './pages/application-page/application-page.component';
import { MixingLoopDashboardComponent } from './components/dashboards/mixing-loop-dashboard/mixing-loop-dashboard.component';
import { HotWaterDashboardComponent } from './components/dashboards/hot-water-dashboard/hot-water-dashboard.component';
import { OutdoorTempDashboardComponent } from './components/dashboards/outdoor-temp-dashboard/outdoor-temp-dashboard.component';
import { DistrictHeatingDashboardComponent } from './components/dashboards/district-heating-dashboard/district-heating-dashboard.component';
import { ChartComponent } from './components/charts/chart/chart.component';
import { ValveChartComponent } from './components/charts/valve-chart/valve-chart.component';
import { CommercialHotWaterDashboardComponent } from './components/dashboards/commercial-hot-water-dashboard/commercial-hot-water-dashboard.component';
import { WeatherCurveChartComponent } from './components/charts/weather-curve-chart/weather-curve-chart.component';
import { SystemStatusViewPageComponent } from './pages/system-status-view-page/system-status-view-page.component';
import { TabTileComponent } from './components/tab-tile/tab-tile.component';
import { TabComponent } from './components/tab/tab.component';
import { MapComponent } from './components/map/map.component';
import { TemperatureChartTileComponent } from './components/charts/temperature-chart-tile/temperature-chart-tile.component';
import { ForwardReturnChartTileComponent } from './components/charts/forward-return-chart-tile/forward-return-chart-tile.component';
import { AlarmBarComponent } from './components/alarms/alarm-bar/alarm-bar.component';
import { AlarmPageComponent } from './pages/alarm-page/alarm-page.component';
import { AlarmIconLinkPipe } from './pipes/alarm-icon-link.pipe';
import { AlarmCommentsTableComponent } from './components/alarms/alarm-comments-table/alarm-comments-table.component';
import { AddAlarmCommentTileComponent } from './components/alarms/add-alarm-comment-tile/add-alarm-comment-tile.component';
import { ManualOverrideBoxComponent } from './components/manual-override-box/manual-override-box.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { AlarmOverrideModalComponent } from './components/alarms/alarm-override-modal/alarm-override-modal.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { TwoFactorVerificationFlowModalComponent } from './components/two-factor-verification-flow-modal/two-factor-verification-flow-modal.component';
import localeEnDk from '@angular/common/locales/en-DK';
import { registerLocaleData } from '@angular/common';
import { PathInterceptor } from './interceptors/path.interceptor';
import { environment } from '../environments/environment';
import { HeatExchangerDashboardComponent } from './components/dashboards/heat-exchanger-dashboard/heat-exchanger-dashboard.component';
import { PumpStatusTileComponent } from './components/pump-status-tile/pump-status-tile.component';
import { sentryConfig, SentryErrorHandler, SharedModule, TokenInterceptor, versionToken, AuthService } from 'shared';
import { AlarmMetadataComponent } from './pages/alarm-metadata/alarm-metadata.component';
import { serverUrlProvider } from './providers/server-url.provider';
import { MixitFacilityPageComponent } from './pages/mixit-facility-page/mixit-facility-page.component';
import { SchematicComponent } from './components/schematic/schematic.component';
import { MixitOnboardingComponent } from './pages/mixit-onboarding/mixit-onboarding.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { EditInstallationPageComponent } from './pages/edit-installation-page/edit-installation-page.component';
import { BuildingConnectWeatherCurveTileComponent } from './components/charts/building-connect-weather-curve-tile/building-connect-weather-curve-tile.component';
import { WeatherCurveFormComponent } from './components/charts/weather-curve-form/weather-curve-form.component';
import { EditFacilityPageComponent } from './pages/edit-facility-page/edit-facility-page.component';
import { TestAuthService, TestInterceptor } from 'shared';
import { PumpSystemDashboardComponent } from './components/dashboards/pump-system-dashboard/pump-system-dashboard.component';
import { WelcomePageComponent } from './pages/welcome-page/welcome-page.component';
import { ChartTileComponent } from './components/charts/chart-tile/chart-tile.component';
import { DifferentialPressureChartComponent } from './components/charts/differential-pressure-chart/differential-pressure-chart.component';
import { BoilerDashboardComponent } from './components/dashboards/boiler-dashboard/boiler-dashboard.component';
import { UnspecifiedLoadDashboardComponent } from './components/dashboards/unspecified-load-dashboard/unspecified-load-dashboard.component';
import { TwoStrokeHeatExchangerDashboardComponent } from './components/dashboards/two-stroke-heat-exchanger-dashboard/two-stroke-heat-exchanger-dashboard.component';
import { ConnectingcoreFacilityPageComponent } from './pages/connectingcore-facility-page/connectingcore-facility-page.component';
import { ConnectingcoreInstallationPageComponent } from './pages/connectingcore-installation-page/connectingcore-installation-page.component';
import { MixitFreemiumDashboardComponent } from './components/dashboards/mixit-freemium-dashboard/mixit-freemium-dashboard.component';
import { MixitPremiumDashboardComponent } from './components/dashboards/mixit-premium-dashboard/mixit-premium-dashboard.component';
import { HeatMeterStatusTileComponent } from './components/heat-meter-status-tile/heat-meter-status-tile.component';
import { MultiValueTileComponent } from './components/multi-value-tile/multi-value-tile.component';
import { MixitTileComponent } from './components/mixit-premium/mixit-tile/mixit-tile.component';
import { PumpTileComponent } from './components/mixit-premium/pump-tile/pump-tile.component';
import { LimitersTileComponent } from './components/mixit-premium/limiters-tile/limiters-tile.component';
import { EnergyMonitoringTileComponent } from './components/mixit-premium/energy-monitoring-tile/energy-monitoring-tile.component';
import { MixitWarmWeatherShutdownTileComponent } from './components/mixit-premium/mixit-warm-weather-shutdown-tile/mixit-warm-weather-shutdown-tile.component';
import { UnderfloorProtectionTileComponent } from './components/mixit-premium/underfloor-protection-tile/underfloor-protection-tile.component';
import { HeatCoilProtectionTileComponent } from './components/mixit-premium/heat-coil-protection-tile/heat-coil-protection-tile.component';
import { MixitWeatherCurveTileComponent } from './components/mixit-premium/mixit-weather-curve-tile/mixit-weather-curve-tile.component';
import { PumpModalComponent } from './components/pump-modal/pump-modal.component';
import { MixitDeviceModalComponent } from './components/mixit-premium/mixit-device-modal/mixit-device-modal.component';
import { MixitBalancingLimitersModalComponent } from './components/mixit-premium/mixit-balancing-limiters-modal/mixit-balancing-limiters-modal.component';
import { MixitWarmWeatherShutdownModalComponent } from './components/mixit-premium/mixit-warm-weather-shutdown-modal/mixit-warm-weather-shutdown-modal.component';
import { MixitUnderfloorProtectionModalComponent } from './components/mixit-premium/mixit-underfloor-protection-modal/mixit-underfloor-protection-modal.component';
import { MixitHeatCoilProtectionModalComponent } from './components/mixit-premium/mixit-heat-coil-protection-modal/mixit-heat-coil-protection-modal.component';
import { MixitInstallationPageComponent } from './pages/mixit-installation-page/mixit-installation-page.component';
import { EditUserPageComponent } from './pages/edit-user-page/edit-user-page.component';
import { ViewUserPageComponent } from './pages/view-user-page/view-user-page.component';
import { UsersPageComponent } from './pages/users-page/users-page.component';
import { FacilityListComponent } from './components/facility-list/facility-list.component';
import { AccountPageComponent } from './pages/account-page/account-page.component';
import { AcquireLicensePageComponent } from './pages/acquire-license-page/acquire-license-page.component';
import { MixitThermalPowerChartComponent } from './components/charts/mixit-thermal-power-chart/mixit-thermal-power-chart.component';
import { ValueComponent } from './components/key-value/value/value.component';
import { KeyComponent } from './components/key-value/key/key.component';
import { KeyValueComponent } from './components/key-value/key-value.component';
import { TrialFlowPageComponent } from './pages/trial-flow-page/trial-flow-page.component';
import { SubscribeFlowPageComponent } from './pages/subscribe-flow-page/subscribe-flow-page.component';
import { ContentTileComponent } from './components/content-tile/content-tile.component';
import { MixitConnectionStatusComponent } from './components/mixit-connection-status/mixit-connection-status.component';
import { BufferTankDashboardComponent } from './components/dashboards/buffer-tank-dashboard/buffer-tank-dashboard.component';
import { MixitUpgradeStatusModalComponent } from './components/mixit-upgrade-status-modal/mixit-upgrade-status-modal.component';
import { FormatDataPointPipe } from './pipes/format-data-point.pipe';
import { UsersContainerPageComponent } from './pages/users-container-page/users-container-page.component';
import { UserRoleExplainerModalComponent } from './components/user-role-explainer-modal/user-role-explainer-modal.component';
import { UserRoleExplainerToggleLinkComponent } from './components/user-role-explainer-toggle-link/user-role-explainer-toggle-link.component';
import { MixitTemperaturesChartComponent } from './components/charts/mixit-temperatures-chart/mixit-temperatures-chart.component';
import { MixitFlowValveChartComponent } from './components/charts/mixit-flow-valve-chart/mixit-flow-valve-chart.component';
import { MixitPremiumBillingDetailsComponent } from './components/mixit-premium-billing-details/mixit-premium-billing-details.component';
import { MixitInstallationSelectorComponent } from './components/mixit-installation-selector/mixit-installation-selector.component';
import { CommercialHotWaterDashboardStandaloneComponent } from './components/dashboards/commercial-hot-water-dashboard-standalone/commercial-hot-water-dashboard-standalone.component';
import { PumpStandaloneDashboardComponent } from './components/dashboards/pump-standalone-dashboard/pump-standalone-dashboard.component';
import { LastUpdatedComponent } from './components/last-updated/last-updated.component';
import { FormatAlarmMetadataPipe } from './pipes/format-alarm-metadata.pipe';
import { DeveloperDataPageComponent } from './pages/developer-data-page/developer-data-page.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MixitSetpointModalComponent } from './components/mixit-premium/mixit-setpoint-modal/mixit-setpoint-modal.component';
import { EnergyManagementDashboardComponent } from './components/energy-management-dashboard/energy-management-dashboard.component';
import { EnergyManagementDashboardSectionComponent } from './components/energy-management-dashboard-section/energy-management-dashboard-section.component';
import { isUserLoggedInProvider } from './providers/is-user-logged-in.provider';
import { MixitPumpChartComponent } from './components/charts/mixit-pump-chart/mixit-pump-chart.component';
import { MixitSubscriptionOverviewComponent } from './pages/mixit-subscription-overview/mixit-subscription-overview.component';
import { Angulartics2Module } from 'angulartics2';
import { Angulartics2LaunchByAdobe } from 'angulartics2';
import { UserTrackingService } from './services/user-tracking.service';
import { BoosterTileComponent } from './components/booster-tile/booster-tile.component';
import { BoosterPumpTileComponent } from './components/booster-pump-tile/booster-pump-tile.component';
import { BoosterInputSourceTileComponent } from './components/booster-input-source-tile/booster-input-source-tile.component';
import { BoosterFlowPressureChartComponent } from './components/charts/booster-flow-pressure-chart/booster-flow-pressure-chart.component';
import { PressureSystemDashboardComponent } from './components/dashboards/pressure-system-dashboard/pressure-system-dashboard.component';
import { PressureSystemChartComponent } from './components/charts/pressure-system-chart/pressure-system-chart.component';
import { MixitTemperatureTileComponent } from './components/mixit-premium/mixit-temperature-tile/mixit-temperature-tile.component';
import { EnergyManagementDashboardSettingsModalComponent } from './components/energy-management-dashboard/energy-management-dashboard-settings-modal/energy-management-dashboard-settings-modal.component';
import { HealthCheckPageComponent } from './pages/health-check-page/health-check-page.component';
import { FlowHeatMeterChartTileComponent } from './components/charts/flow-heat-meter-chart-tile/flow-heat-meter-chart-tile.component';
import { IndirectDistrictHeatingSupplyDashboardComponent } from './components/dashboards/indirect-district-heating-supply-dashboard/indirect-district-heating-supply-dashboard.component';
import { SchedulingTileComponent } from './components/scheduling-tile/scheduling-tile.component';
import { FormatHoursMinutesPipe } from './pipes/format-hours-minutes.pipe';
import { FormatHoursMinutesWithDurationPipe } from './pipes/format-hours-minutes-with-duration.pipe';
import { SchedulingModalComponent } from './components/scheduling-modal/scheduling-modal.component';
import { CascadeTankDashboardComponent } from './components/dashboards/cascade-tank-dashboard/cascade-tank-dashboard.component';
import { WaterMeterChartTileComponent } from './components/charts/water-meter-chart-tile/water-meter-chart-tile.component';
import { AlarmSettingsTableComponent } from './components/alarm-settings-table/alarm-settings-table.component';
import { AlarmSettingsModalComponent } from './components/alarm-settings-modal/alarm-settings-modal.component';
import { AlarmSettingsViewComponent } from './components/alarm-settings-view/alarm-settings-view.component';
import { SetpointTileComponent } from './components/setpoint-tile/setpoint-tile.component';
import { SetpointModalComponent } from './components/setpoint-modal/setpoint-modal.component';
import { EnergyDataExportModalComponent } from './components/energy-data-export-modal/energy-data-export-modal.component';
import { MixitPumpInfoModalComponent } from './components/mixit-pump-info-modal/mixit-pump-info-modal.component';
import { MixitProductInfoModalComponent } from './components/mixit-product-info-modal/mixit-product-info-modal.component';
import { PasteurizationStatusTileComponent } from './components/pasteurization-status-tile/pasteurization-status-tile.component';
import { CascadeBufferTankChargingSystemDashboardComponent } from './components/dashboards/cascade-buffer-tank-charging-system-dashboard/cascade-buffer-tank-charging-system-dashboard.component';
import { ValveStatusTileComponent } from './components/valve-status-tile/valve-status-tile.component';
import { DevicesAndUpgradesComponent } from './pages/devices-and-upgrades/devices-and-upgrades.component';
import { ValveModalComponent } from './components/valve-modal/valve-modal.component';
import { ControlStateToggleComponent } from './components/control-state-toggle/control-state-toggle.component';
import { FilterFacilityListPipe } from './pipes/filter-facility-list.pipe';
import { EnergyValveHeatAndFlowChartComponent } from './components/charts/energy-valve-heat-and-flow-chart/energy-valve-heat-and-flow-chart.component';
import { EnergyValveTemperatureChartComponent } from './components/charts/energy-valve-temperature-chart/energy-valve-temperature-chart.component';
import { ColdWaterSupplyDashboardComponent } from './components/dashboards/cold-water-supply-dashboard/cold-water-supply-dashboard.component';
import { WarmWeatherShutdownSettingsModalComponent } from './components/warm-weather-shutdown-settings-modal/warm-weather-shutdown-settings-modal.component';
import { WarmWeatherShutdownTileComponent } from './components/warm-weather-shutdown-tile/warm-weather-shutdown-tile.component';
import { MixitX509BannerComponent } from './components/mixit-x509-banner/mixit-x509-banner.component';

registerLocaleData(localeEnDk, 'en-DK');

const testModeProviders = [
  {
    provide: AuthService,
    useClass: TestAuthService,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TestInterceptor,
    multi: true,
  },
];

@NgModule({
  declarations: [
    AppComponent,
    InstallationPageComponent,
    FacilityPageComponent,
    FacilityMapPageComponent,
    RootPageComponent,
    BuildingconnectFacilityPageComponent,
    BuildingConnectInstallationPageComponent,
    SchematicsTileComponent,
    AlarmTableComponent,
    InstallationActivityTileComponent,
    InstallationSystemStatusComponent,
    ApplicationPageComponent,
    MixingLoopDashboardComponent,
    HeatExchangerDashboardComponent,
    HotWaterDashboardComponent,
    OutdoorTempDashboardComponent,
    DistrictHeatingDashboardComponent,
    ChartComponent,
    ValveChartComponent,
    CommercialHotWaterDashboardComponent,
    WeatherCurveChartComponent,
    SystemStatusViewPageComponent,
    TabTileComponent,
    TabComponent,
    MapComponent,
    TemperatureChartTileComponent,
    ForwardReturnChartTileComponent,
    AlarmBarComponent,
    AlarmPageComponent,
    AlarmIconLinkPipe,
    AlarmCommentsTableComponent,
    AddAlarmCommentTileComponent,
    ManualOverrideBoxComponent,
    AlarmOverrideModalComponent,
    ProfilePageComponent,
    AccountPageComponent,
    TwoFactorVerificationFlowModalComponent,
    PumpStatusTileComponent,
    AlarmMetadataComponent,
    MixitFacilityPageComponent,
    SchematicComponent,
    MixitOnboardingComponent,
    EditInstallationPageComponent,
    BuildingConnectWeatherCurveTileComponent,
    WeatherCurveFormComponent,
    EditFacilityPageComponent,
    PumpSystemDashboardComponent,
    WelcomePageComponent,
    ChartTileComponent,
    DifferentialPressureChartComponent,
    BoilerDashboardComponent,
    UnspecifiedLoadDashboardComponent,
    TwoStrokeHeatExchangerDashboardComponent,
    ConnectingcoreFacilityPageComponent,
    ConnectingcoreInstallationPageComponent,
    MixitFreemiumDashboardComponent,
    MixitPremiumDashboardComponent,
    HeatMeterStatusTileComponent,
    MultiValueTileComponent,
    MixitTileComponent,
    PumpTileComponent,
    LimitersTileComponent,
    EnergyMonitoringTileComponent,
    MixitWarmWeatherShutdownTileComponent,
    UnderfloorProtectionTileComponent,
    HeatCoilProtectionTileComponent,
    MixitWeatherCurveTileComponent,
    PumpModalComponent,
    MixitDeviceModalComponent,
    MixitBalancingLimitersModalComponent,
    MixitWarmWeatherShutdownModalComponent,
    MixitUnderfloorProtectionModalComponent,
    MixitHeatCoilProtectionModalComponent,
    MixitInstallationPageComponent,
    EditUserPageComponent,
    ViewUserPageComponent,
    UsersPageComponent,
    FacilityListComponent,
    AcquireLicensePageComponent,
    MixitThermalPowerChartComponent,
    KeyValueComponent,
    KeyComponent,
    ValueComponent,
    TrialFlowPageComponent,
    ContentTileComponent,
    MixitConnectionStatusComponent,
    BufferTankDashboardComponent,
    MixitUpgradeStatusModalComponent,
    SubscribeFlowPageComponent,
    FormatDataPointPipe,
    UsersContainerPageComponent,
    UserRoleExplainerModalComponent,
    UserRoleExplainerToggleLinkComponent,
    MixitTemperaturesChartComponent,
    MixitFlowValveChartComponent,
    MixitPremiumBillingDetailsComponent,
    MixitInstallationSelectorComponent,
    CommercialHotWaterDashboardStandaloneComponent,
    PumpStandaloneDashboardComponent,
    ColdWaterSupplyDashboardComponent,
    LastUpdatedComponent,
    FormatAlarmMetadataPipe,
    DeveloperDataPageComponent,
    MixitSetpointModalComponent,
    EnergyManagementDashboardComponent,
    EnergyManagementDashboardSectionComponent,
    MixitPumpChartComponent,
    MixitSubscriptionOverviewComponent,
    BoosterTileComponent,
    BoosterPumpTileComponent,
    BoosterInputSourceTileComponent,
    BoosterFlowPressureChartComponent,
    FlowHeatMeterChartTileComponent,
    MixitTemperatureTileComponent,
    SchedulingTileComponent,
    FormatHoursMinutesPipe,
    FormatHoursMinutesWithDurationPipe,
    SchedulingModalComponent,
    PressureSystemDashboardComponent,
    PressureSystemChartComponent,
    MixitTemperatureTileComponent,
    EnergyManagementDashboardSettingsModalComponent,
    HealthCheckPageComponent,
    IndirectDistrictHeatingSupplyDashboardComponent,
    CascadeTankDashboardComponent,
    AlarmSettingsTableComponent,
    AlarmSettingsModalComponent,
    AlarmSettingsViewComponent,
    WaterMeterChartTileComponent,
    SetpointTileComponent,
    SetpointModalComponent,
    EnergyDataExportModalComponent,
    MixitPumpInfoModalComponent,
    MixitProductInfoModalComponent,
    CascadeBufferTankChargingSystemDashboardComponent,
    PasteurizationStatusTileComponent,
    ValveStatusTileComponent,
    DevicesAndUpgradesComponent,
    ValveModalComponent,
    ControlStateToggleComponent,
    FilterFacilityListPipe,
    EnergyValveHeatAndFlowChartComponent,
    EnergyValveTemperatureChartComponent,
    WarmWeatherShutdownSettingsModalComponent,
    WarmWeatherShutdownTileComponent,
    MixitX509BannerComponent
  ],
  imports: [
    CdkStepperModule,
    SharedModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    HighchartsChartModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    OverlayModule,
    NgxDatatableModule,
    Angulartics2Module.forRoot({
      //developerMode: !environment.production, // Enable this if you want to load the tracking script in dev but disable actual tracking
      pageTracking: { autoTrackVirtualPages: true, excludedRoutes: [/callback#state/g, /silent-renew.html/g] },
    }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    serverUrlProvider,
    isUserLoggedInProvider,
    AlarmIconLinkPipe,
    {
      provide: versionToken,
      useValue: version,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PathInterceptor,
      multi: true,
    },
    {
      provide: sentryConfig,
      useValue: {
        dsn: 'https://0416bf35d1ed4c6fbc641cce21752012@sentry.io/2851033',
        release: 'customer-portal@' + version,
        enabled: environment.production,
      },
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-DK',
    },
    {
      provide: Angulartics2LaunchByAdobe,
      useClass: UserTrackingService,
    },

    // Test environment stuff
    ...(environment.testMode ? testModeProviders : []),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

