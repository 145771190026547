import { Injectable } from '@angular/core';
import { Facility, Installation } from '../interfaces/facilty';
import { AlarmType } from '../interfaces/system-status';
import { Router, UrlSerializer } from '@angular/router';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Application } from '../interfaces/alarm';
import { PageInfo } from 'shared';
import { EFacilityType } from 'projects/serviceportal/src/app/interfaces/facility';
import { IBackendUser, IBuildingConnectUser } from '../interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class PageInfoService {
  constructor(private router: Router, private urlSerializer: UrlSerializer, private translateService: TranslateService) {}

  mapQueryParams: { lat: number; lng: number; zoom: number };

  public setMapCoordinates(lat: number, lng: number, zoom: number) {
    this.mapQueryParams = { lat, lng, zoom };
  }

  public fixInstallationPageForMixit(facility: Facility, installation: Installation, pageInfo: PageInfo): PageInfo {
    const onlyOneInstallation = facility.installations.length === 1;
    if (onlyOneInstallation) {
      return {
        ...pageInfo,
        parent: this.facilityMap(),
      };
    }
    return pageInfo;
  }

  public facilityMap(): PageInfo {
    const queryParams = this.mapQueryParams ? this.mapQueryParams : undefined;
    return {
      parent: null,
      title: this.translateService.get('page-info.facility-map-title'),
      subtitles: [of('')],
      link: ['/facilities'],
      queryParams,
    };
  }

  public facility(facility: Facility): PageInfo {
    return {
      parent: this.facilityMap(),
      title: of(facility.name),
      subtitles: [of(facility.address)],
      link: ['/', 'facility', facility.id],
    };
  }

  public installation(facility: Facility, installation: Installation, firmwareVersion?: string): PageInfo {
    const isMixit = facility.facilityType === EFacilityType.MIXIT;
    const parent = this.facility(facility);

    return {
      parent,
      title: of(installation.name || installation.id),
      subtitles: [
        of(facility.address),
        // Only show these if they are there
        ...(installation.serialNumber && !isMixit
          ? [this.translateService.get('page-info.serial', { serial: installation.serialNumber })]
          : []),
        ...(firmwareVersion ? [this.translateService.get('page-info.firmware-version', { version: firmwareVersion })] : []),
      ],
      link: [...parent.link, 'installation', installation.id],
    };
  }

  public energyDashboard(facility: Facility, installation: Installation, firmwareVersion?: string): PageInfo {
    const isMixit = facility.facilityType === EFacilityType.MIXIT;
    const parent = this.installation(facility, installation, firmwareVersion);

    return {
      parent: {
        title: parent.title,
        link: [...parent.link, { tab: 'energy_dashboard' }],
        subtitles: parent.subtitles,
        parent: parent.parent,
      },
      title: of(installation.name || installation.id),
      subtitles: [
        of(facility.address),
        // Only show these if they are there
        ...(installation.serialNumber && !isMixit
          ? [this.translateService.get('page-info.serial', { serial: installation.serialNumber })]
          : []),
        ...(firmwareVersion ? [this.translateService.get('page-info.firmware-version', { version: firmwareVersion })] : []),
      ],
      link: [...parent.link, 'health-check'],
    };
  }

  public application(facility: Facility, installation: Installation, application: Application, firmwareVersion: string): PageInfo {
    const parent = this.installation(facility, installation, firmwareVersion);
    return {
      parent,
      title: application.title,
      subtitles: parent.subtitles,
      link: [...parent.link, 'application', application.id],
    };
  }

  public systemStatus(facility: Facility, installation: Installation, firmwareVersion: string): PageInfo {
    const parent = this.installation(facility, installation, firmwareVersion);
    return {
      parent,
      title: this.translateService.get('page-info.system-status-title'),
      subtitles: parent.subtitles,
      link: [...parent.link, 'system-status'],
    };
  }

  public alarm(
    facility: Facility,
    installation: Installation,
    alarm: { type: AlarmType; id: number | string },
    firmwareVersion: string
  ): PageInfo {
    const parent = this.systemStatus(facility, installation, firmwareVersion);
    return {
      parent,
      title: this.translateService.get(`page-info.${alarm.type}`),
      subtitles: parent.subtitles,
      link: [...parent.link, 'alarm', alarm.id],
    };
  }

  public mixitOnboardingComponent(): PageInfo {
    return {
      parent: this.facilityMap(),
      title: this.translateService.get('page-info.claim-device'),
      subtitles: [of('')],
      link: [`/mixit/onboarding`],
    };
  }

  public editInstallation(facility: Facility, installation: Installation, firmwareVersion: string): PageInfo {
    const parent = this.installation(facility, installation, firmwareVersion);
    return {
      parent,
      title: this.translateService.get('page-info.edit-installation'),
      subtitles: [of('')],
      link: [],
    };
  }

  public editFacility(facility: Facility): PageInfo {
    const parent = this.facility(facility);
    return {
      parent,
      title: this.translateService.get('page-info.edit-facility'),
      subtitles: [of('')],
      link: [],
    };
  }

  public profile(): PageInfo {
    return {
      parent: null,
      title: this.translateService.get('app-profile.title'),
      subtitles: [of('')],
      link: ['/'],
    };
  }

  public account(): PageInfo {
    return {
      parent: null,
      title: this.translateService.get('app-account-page.title'),
      subtitles: [of('')],
      link: ['/'],
    };
  }

  public createUser(): PageInfo {
    return {
      parent: this.viewUsers(),
      title: this.translateService.get('page-info-service.create-user'),
      subtitles: [of('')],
      link: ['/users/create'],
    };
  }

  public editUser(user: IBackendUser): PageInfo {
    return {
      parent: this.viewUser(user),
      title: this.translateService.get('page-info-service.edit-user'),
      subtitles: [of('')],
      link: ['/users', user.id, 'edit'],
    };
  }

  public viewUsers(): PageInfo {
    return {
      parent: null,
      title: this.translateService.get('users-page.title'),
      subtitles: [of('')],
      link: ['/users'],
    };
  }

  public viewUser(user: IBackendUser): PageInfo {
    const parent = this.viewUsers();
    return {
      parent: this.viewUsers(),
      title: of(user.name),
      subtitles: [of('')],
      link: [...parent.link, user.id, 'view'],
    };
  }

  public trialFlow(): PageInfo {
    return {
      parent: null,
      title: this.translateService.get('trial-flow-page.title'),
      subtitles: [of('')],
      link: ['/upgrade/trial'],
    };
  }

  public subscriptionFlow(): PageInfo {
    return {
      parent: null,
      title: this.translateService.get('subscribe-flow-page.title'),
      subtitles: [of('')],
      link: ['/upgrade/subscribe'],
    };
  }

  public dataPointsPage(facility: Facility, installation: Installation, firmwareVersion: string): PageInfo {
    const parent = this.installation(facility, installation, firmwareVersion);
    return {
      parent,
      title: of(installation.name || installation.id), // Is developer page, doesn't need translation
      subtitles: parent.subtitles,
      link: [...parent.link, 'data-table'],
    };
  }

  public mixitSubscriptionOverviewPage(): PageInfo {
    const parent = this.account();
    return {
      parent: {
        title: parent.title,
        link: ['/account', { tab: 'devices' }],
        subtitles: parent.subtitles,
        parent: parent.parent,
      },
      title: this.translateService.get('mixit-subscription-overview.title'),
      subtitles: [],
      link: [...parent.link, 'mixit-subscriptions-overview'],
    };
  }
}
