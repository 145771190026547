import { v4 } from 'uuid';
import { snap } from './components/Common';
import { systemMetadataMap } from './components/system-metadata';
import { getConnectionPointViewModel } from './util/rendering-util';
import { connectionPointIdentifierEquals } from './util/model-util';
import { CaseReducer, createReducer, PayloadAction } from '@reduxjs/toolkit';
import { ConnectionModel, ConnectionPointModel, SchematicMode, SystemConnectionPointModel } from './types';
import {
  ConnectionPointIdentifier,
  ConnectionState,
  FreeConnectionPointIdentifier,
  FreeConnectionPointState,
  SchematicState,
  SystemConnectionPointIdentifier,
  SystemState,
} from './state';
import {
  cancelCreatingConnection,
  deleteConnection,
  DeleteConnection,
  deleteConnectionPoint,
  DeleteConnectionPoint,
  finishCreatingConnection,
  FinishCreatingConnection,
  loadSchematic,
  LoadSchematic,
  removeSystem,
  RemoveSystem,
  setConnectionPointPosition,
  SetConnectionPointPosition,
  setMode,
  SetMode,
  setSystemInfo,
  SetSystemInfo,
  setSystemPosition,
  SetSystemPosition,
  setValues,
  SetValues,
  splitConnection,
  SplitConnection,
  startCreatingConnection,
  StartCreatingConnection,
  startDraggingConnectionPoint,
  StartDraggingConnectionPoint,
  startDraggingSystem,
  StartDraggingSystem,
  stopDraggingConnectionPoint,
  stopDraggingSystem,
  updateConnectionBeingCreatedPosition,
  UpdateConnectionBeingCreatedPosition,
  AlarmsWarnings,
  setSystemAlarmsWarnings,
  SystemSelect,
  systemSelect,
  systemUnselect,
  CloneSystem,
  cloneSystem,
  InfoState,
  setSystemInfoState,
  AddSystem,
  addSystem,
  SystemControlModePayload,
  setSystemControlMode,
  setWarmWeatherShutDown,
  WarmWeatherShutDownPayload,
  setShowOutdoorTemperatureSensor,
  ShowOutdoorTemperatureSensorPayload,
} from './actions';

export const initialState = (): SchematicState => {
  return {
    alarmsWarnings: [],
    systems: [],
    points: [],
    connections: [],
    values: [],
    inputStrategyName: 'Default',
    connectionBeingCreated: null,
    connectionPointBeingDragged: null,
    draggingSystemState: null,
    mode: SchematicMode.Edit,
    selectedSystemId: null,
  };
};

export type SchematicCaseReducer<Payload> = CaseReducer<SchematicState, PayloadAction<Payload>>;

const setModeReducer: SchematicCaseReducer<SetMode> = (state, { payload }) => {
  state.mode = payload.mode;
  state.inputStrategyName = payload.mode === SchematicMode.Edit ? 'Default' : 'Viewer';
};

const addSystemReducer: SchematicCaseReducer<AddSystem> = (state, { payload }) => {
  state.systems.push(payload.system);
  return state;
};

const systemSelectReducer: SchematicCaseReducer<SystemSelect> = (state, { payload }) => {
  state.selectedSystemId = payload.systemId;
  return state;
};

const systemUnselectReducer: SchematicCaseReducer<void> = (state) => {
  state.selectedSystemId = null;
  return state;
};

const setConnectionPointReducer: SchematicCaseReducer<SetConnectionPointPosition> = (state, { payload }) => {
  const point = state.points.find((p) => p.hasId === payload.id) as FreeConnectionPointState;
  point.x = snap(payload.x);
  point.y = snap(payload.y);
  return state;
};

const startCreatingConnectionReducer: SchematicCaseReducer<StartCreatingConnection> = (state, { payload }) => {
  state.inputStrategyName = 'CreatingConnection';
  state.connectionBeingCreated = {
    from: payload.from,
    toX: payload.toX,
    toY: payload.toY,
  };
  return state;
};

const finishCreatingConnectionReducer: SchematicCaseReducer<FinishCreatingConnection> = (state, { payload }) => {
  if (!state.connectionBeingCreated) {
    console.error('Attempted to finish creating connection, but no connection is being created!');
    return state;
  }
  const newConnection = {
    id: v4(),
    from: state.connectionBeingCreated.from,
    to: payload.to,
    dotted: state.connectionBeingCreated.from?.dotted,
  };
  state.connections.push(newConnection);
  state.inputStrategyName = 'Default';
  state.connectionBeingCreated = null;
};

const deleteConnectionReducer: SchematicCaseReducer<DeleteConnection> = (state, { payload }) => {
  state.connections = state.connections.filter((connection) => connection.id !== payload.targetId);
  return state;
};

const deleteConnectionPointReducer: SchematicCaseReducer<DeleteConnectionPoint> = (state, { payload }) => {
  const newConnections = state.connections.filter(
    (conn) =>
      !connectionPointIdentifierEquals(conn.from, payload.connectionPointId) &&
      !connectionPointIdentifierEquals(conn.to, payload.connectionPointId)
  );
  const newPoints = state.points.filter((point) => point.hasId !== (payload.connectionPointId as FreeConnectionPointIdentifier).id);
  state.connections = newConnections;
  state.points = newPoints;
  return state;
};

const cancelCreatingConnectionReducer: SchematicCaseReducer<void> = (state) => {
  state.connectionBeingCreated = null;
  state.inputStrategyName = 'Default';
  return state;
};

const updateConnectionBeingCreatedPositionReducer: SchematicCaseReducer<UpdateConnectionBeingCreatedPosition> = (state, { payload }) => {
  if (!state.connectionBeingCreated) {
    console.error('Attempted to update connection being created, but no connection is being created!');
    return state;
  }
  Object.assign(state.connectionBeingCreated, { toX: payload.newX, toY: payload.newY });
  return state;
};

const startDraggingConnectionPointReducer: SchematicCaseReducer<StartDraggingConnectionPoint> = (state, { payload }) => {
  state.connectionPointBeingDragged = { id: payload.targetId };
  state.inputStrategyName = 'DraggingConnectionPoint';
  return state;
};

const stopDraggingConnectionPointReducer: SchematicCaseReducer<void> = (state) => {
  state.connectionPointBeingDragged = null;
  state.inputStrategyName = 'Default';
  return state;
};

const removeSystemReducer: SchematicCaseReducer<RemoveSystem> = (state, { payload }) => {
  state.systems = state.systems.filter((system) => system.hasId !== payload.systemId);
  state.connections = state.connections.filter((conn) => {
    if (conn.from.type === 'SYSTEM' && conn.from.systemId === payload.systemId) {
      return false;
    }
    return !(conn.to.type === 'SYSTEM' && conn.to.systemId === payload.systemId);
  });
  if (payload.systemId === state.selectedSystemId) {
    state.selectedSystemId = null;
  }
  return state;
};

const cloneSystemReducer: SchematicCaseReducer<CloneSystem> = (state, { payload }) => {
  const system = state.systems.find((s) => s.hasId === payload.systemId) as SystemState;
  const meta = systemMetadataMap[system.type];
  const newSystem = JSON.parse(JSON.stringify(system));
  newSystem.hasId = v4();
  newSystem.left = system.left + meta.width + 20;
  addSystemReducer(state, addSystem({ system: newSystem }));
  systemSelectReducer(state, systemSelect({ systemId: newSystem.hasId }));
  return state;
};

const setSystemInfoReducer: SchematicCaseReducer<SetSystemInfo> = (state, { payload }) => {
  const system = state.systems.find((s) => s.hasId === payload.systemId) as SystemState;
  system.systemInfo = payload.systemInfo;
  system.configured = payload.configured;

  // Some properties in SystemInfo cause a change in the connections points on a system
  // We might add an extra outtake, or change the color of a point.
  // That can cause a valid connection (red to red) to become invalid (red to blue)
  // We want to remove those connections, so the application doesn't crash

  // The current connectionPoints for the system, based on the newly updated systemInfo
  const systemConnectionPointsForSystem = systemMetadataMap[system.type].calculateConnectionPoints(system.systemInfo);

  const connectionsThatAreAttachedToThisSystem = state.connections.filter((connection) => {
    return (
      (connection.to.type === 'SYSTEM' && connection.to.systemId === system.hasId) ||
      (connection.from.type === 'SYSTEM' && connection.from.systemId === system.hasId)
    );
  });

  const connectionsToDelete: string[] = [];

  for (const connection of connectionsThatAreAttachedToThisSystem) {
    for (const connectionPoint of [connection.to, connection.from] as SystemConnectionPointIdentifier[]) {
      // We don't know if it's the to or from that is connected to this system, so we still need to check on the next line
      if (connectionPoint.type === 'SYSTEM' && connectionPoint.systemId === system.hasId) {
        if (!systemConnectionPointsForSystem.find((s) => s.key === connectionPoint.key && s.type === connectionPoint.connectionPointType)) {
          // Remove this connection
          connectionsToDelete.push(connection.id);
        }
      }
    }
  }

  state.connections = state.connections.filter((c) => !connectionsToDelete.includes(c.id));
  return state;
};

const startDraggingSystemReducer: SchematicCaseReducer<StartDraggingSystem> = (state, { payload }) => {
  state.draggingSystemState = { systemId: payload.systemId, offsetX: payload.offsetX, offsetY: payload.offsetY };
  state.inputStrategyName = 'DraggingSystem';
  return state;
};

const stopDraggingSystemReducer: SchematicCaseReducer<void> = (state) => {
  state.draggingSystemState = null;
  state.inputStrategyName = 'Default';
  return state;
};

const setSystemPositionReducer: SchematicCaseReducer<SetSystemPosition> = (state, { payload }) => {
  const system = state.systems.find((s) => s.hasId === payload.systemId) as SystemState;
  system.left = snap(payload.x);
  system.top = snap(payload.y);
  return state;
};

const splitConnectionReducer: SchematicCaseReducer<SplitConnection> = (state, { payload }) => {
  const connection = state.connections.find((conn) => conn.id === payload.connectionId) as ConnectionState;
  const fromId = connection.from;
  const toId = connection.to;
  const fromVm = getConnectionPointViewModel(state, fromId);
  const connectionType = fromVm.type;
  const newPoint: FreeConnectionPointState = {
    hasId: v4(),
    x: snap(payload.x),
    y: snap(payload.y),
    type: connectionType,
    dotted: connection.dotted,
  };

  const newPointId: FreeConnectionPointIdentifier = {
    id: newPoint.hasId,
    connectionPointType: fromVm.type,
    type: 'FREE',
    dotted: connection.dotted,
  };
  state.points.push(newPoint);
  state.connections = state.connections.filter((c) => c.id !== payload.connectionId);
  state.connections.push({
    id: v4(),
    from: fromId,
    to: newPointId,
    dotted: connection.dotted,
  });
  state.connections.push({
    id: v4(),
    from: newPointId,
    to: toId,
    dotted: connection.dotted,
  });
  return state;
};

// converts external connection point to internal identifier
const getConnectionPointIdentifier = (point: ConnectionPointModel): ConnectionPointIdentifier => {
  if ((point as SystemConnectionPointModel).systemId) {
    const systemPoint = point as SystemConnectionPointModel;
    // tslint:disable-next-line:no-shadowed-variable
    const id: ConnectionPointIdentifier = {
      type: 'SYSTEM',
      systemId: systemPoint.systemId,
      key: systemPoint.connectionPointPlacement as string,
      connectionPointType: systemPoint.type,
      dotted: systemPoint.dotted,
    };
    return id;
  }
  const id: ConnectionPointIdentifier = {
    type: 'FREE',
    connectionPointType: point.type,
    id: point.hasId,
    dotted: point.dotted,
  };
  return id;
};

// Loading schematics will create a new schematic state from scratch, not including data, alarms, etc.
export const loadSchematicReducer: SchematicCaseReducer<LoadSchematic> = (state, { payload }) => {
  const schematic = payload.schematic;
  const systems: SystemState[] = schematic.systems.map((system) => {
    return { ...system, left: snap(system.left), top: snap(system.top) };
  });

  if (payload.systemId) {
    Object.assign(state, {
      systems: [systems.find((system) => system.hasId === payload.systemId)],
      connections: [],
      points: [],
      inputStrategyName: state.mode === SchematicMode.Edit ? 'Default' : 'Viewer',
      connectionBeingCreated: null,
      connectionPointBeingDragged: null,
      draggingSystemState: null,
    });
    return state;
  }

  const freeConnectionPoints = schematic.connectionPoints
    .filter((point) => {
      return !(point as SystemConnectionPointModel).systemId;
    })
    .map((p) => {
      const point: FreeConnectionPointState = {
        x: snap(p.left),
        y: snap(p.top),
        hasId: p.hasId,
        type: p.type,
        dotted: p.dotted,
      };
      return point;
    });

  const idToPointMap = schematic.connectionPoints.reduce(
    (acc: { [k: string]: ConnectionPointModel | SystemConnectionPointModel }, point) => {
      acc[point.hasId] = point;
      return acc;
    },
    {}
  );

  const connections = ((schematic.connections || []) as ConnectionModel[])
    .map((conn) => {
      const fromId = idToPointMap[conn.connectionPointIds[0]];
      if (!fromId) {
        console.error('fromId not found', fromId);
        return null;
      }
      const toId = idToPointMap[conn.connectionPointIds[1]];
      if (!toId) {
        console.error('toId not found', toId);
        return null;
      }
      const from = getConnectionPointIdentifier(fromId);
      const to = getConnectionPointIdentifier(toId);
      if (!from || !to) {
        console.error('could not load connection', conn);
        return null;
      }
      const connection: ConnectionState = {
        id: v4(),
        from,
        to,
        dotted: from.dotted,
      };
      return connection;
    })
    .filter((conn) => conn !== null);
  Object.assign(state, {
    systems,
    connections,
    points: freeConnectionPoints as unknown as FreeConnectionPointState[],
    inputStrategyName: state.mode === SchematicMode.Edit ? 'Default' : 'Viewer',
    connectionBeingCreated: null,
    connectionPointBeingDragged: null,
    draggingSystemState: null,
  });
  return state;
};

const setValuesReducer: SchematicCaseReducer<SetValues> = (state, { payload }) => {
  state.values = payload.values;
  return state;
};

const setSystemAlarmWarningsReducer: SchematicCaseReducer<AlarmsWarnings[]> = (state, { payload }) => {
  state.alarmsWarnings = payload;
  return state;
};

const setSystemInfoStateReducer: SchematicCaseReducer<InfoState[]> = (state, { payload }) => {
  payload.forEach((data) => {
    const system = state.systems.find((s) => s.hasId === data.systemId) as SystemState;
    system.pasteurizationState = data.pasteurization;
  });
  return state;
};

const setSystemControlModeReducer: SchematicCaseReducer<SystemControlModePayload[]> = (state, { payload }) => {
  if (!payload) {
    return;
  }
  if (!payload.length) {
    return;
  }

  payload.forEach((data) => {
    const system = state.systems.find((s) => s.hasId === data.systemId) as SystemState;
    if (system) {
      system.systemControlMode = data.systemControlMode;
    }
  });
  return state;
};

const setWarmWeatherShutDownReducer: SchematicCaseReducer<WarmWeatherShutDownPayload[]> = (state, { payload }) => {
  if (!payload) {
    return;
  }
  if (!payload.length) {
    return;
  }

  payload.forEach((data: WarmWeatherShutDownPayload) => {
    const system: SystemState = state.systems.find((s) => s.hasId === data.systemId) as SystemState;
    if (system) {
      system.warmWeatherShutdown = data.warmWeatherShutdown;
    }
  });
  return state;
};

const setShowOutdoorTemperatureSensorReducer: SchematicCaseReducer<ShowOutdoorTemperatureSensorPayload[]> = (state, { payload }) => {
  if (!payload?.length) {
    return;
  }

  payload.forEach((data: ShowOutdoorTemperatureSensorPayload) => {
    const system: SystemState = state.systems.find((s) => s.hasId === data.systemId) as SystemState;
    if (system) {
      system.showOutdoorTemperatureSensor = data.showOutdoorTemperatureSensor;
    }
  });
  return state;
};

export const schematicReducer = createReducer<SchematicState>(initialState(), {
  [setMode.type]: setModeReducer,
  [setConnectionPointPosition.type]: setConnectionPointReducer,
  [startCreatingConnection.type]: startCreatingConnectionReducer,
  [finishCreatingConnection.type]: finishCreatingConnectionReducer,
  [cancelCreatingConnection.type]: cancelCreatingConnectionReducer,
  [updateConnectionBeingCreatedPosition.type]: updateConnectionBeingCreatedPositionReducer,
  [deleteConnection.type]: deleteConnectionReducer,
  [startDraggingConnectionPoint.type]: startDraggingConnectionPointReducer,
  [stopDraggingConnectionPoint.type]: stopDraggingConnectionPointReducer,
  [deleteConnectionPoint.type]: deleteConnectionPointReducer,
  [addSystem.type]: addSystemReducer,
  [removeSystem.type]: removeSystemReducer,
  [cloneSystem.type]: cloneSystemReducer,
  [systemSelect.type]: systemSelectReducer,
  [systemUnselect.type]: systemUnselectReducer,
  [setSystemInfo.type]: setSystemInfoReducer,
  [startDraggingSystem.type]: startDraggingSystemReducer,
  [stopDraggingSystem.type]: stopDraggingSystemReducer,
  [setSystemPosition.type]: setSystemPositionReducer,
  [splitConnection.type]: splitConnectionReducer,
  [loadSchematic.type]: loadSchematicReducer,
  [setValues.type]: setValuesReducer,
  [setSystemAlarmsWarnings.type]: setSystemAlarmWarningsReducer,
  [setSystemInfoState.type]: setSystemInfoStateReducer,
  [setSystemControlMode.type]: setSystemControlModeReducer,
  [setWarmWeatherShutDown.type]: setWarmWeatherShutDownReducer,
  [setShowOutdoorTemperatureSensor.type]: setShowOutdoorTemperatureSensorReducer,
});

export const reducersToTriggerConnectionPointUpdate: string[] = [setSystemInfo.type];

export type AppDispatch = (action: PayloadAction<any>) => void;
