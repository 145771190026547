<div [ngStyle]="{ 'width': fixedWidthStyle }">
  <div [ngClass]="['base', matchRelativeParentDimensions ? 'expanded' : ''].join(' ')">
    <div class="control-button decrementButton" (click)="decrementStep()">
      <img class="icon" src="./assets/svgs/remove_outline.svg">
    </div>
    <div class="input-wrapper">
      <div class="input-container" [ngClass]="{ invalid: !valid && ngControl.control?.touched, hasUnit: !!(unit), disabled: ngControl.control?.disabled }">
        <div class="input-control">
          <input
            [min]="min"
            [max]="max"
            [step]="step"
            [disabled]="ngControl.control?.disabled"
            [type]="inputType"
            [(ngModel)]="value"
            #filterInputField
            class="input"
            [placeholder]="placeholderTranslationString ? (placeholderTranslationString | translate) : ''"
            (keyup)="onKey(filterInputField.value)"
            (blur)="onBlur()"
            [maxlength]="maxlength"
            [attr.name]="formControlName || ngControl.name" />
        </div>
        <div class="unit" *ngIf="unit">{{unit}}</div>
      </div>
    </div>
    <div class="control-button increment" (click)="incrementStep()">
      <img class="icon" src="./assets/svgs/add_outline.svg">
    </div>
  </div>
  <!-- We show it as a description, rather than an error, when the user has not touched the input -->
  <div class="label error" [ngClass]="{error: ngControl.control?.touched}" *ngIf="!valid">
    {{ errorDescriptionTranslationString | translate: errorDescriptionData }}
  </div>
</div>
