import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EMPTY } from 'rxjs';
import { first, switchMap, withLatestFrom } from 'rxjs/operators';
import { ModalService, Result } from 'shared';
import { IAlarmSettingsInfoDto } from '../../interfaces/alarm-settings-info';
import { Installation } from '../../interfaces/facilty';
import { FacilityService } from '../../services/facility.service';
import { InstallationConfigurationService } from '../../services/installation-configuration.service';
import { AlarmSettingsModalComponent } from '../alarm-settings-modal/alarm-settings-modal.component';

@Component({
  selector: 'app-alarm-settings-view',
  templateUrl: './alarm-settings-view.component.html',
  styleUrls: ['./alarm-settings-view.component.scss']
})
export class AlarmSettingsViewComponent {
  public isShowingSettingsDialog: boolean;

  constructor(
    private route: ActivatedRoute,
    private facilityService: FacilityService,
    private installationConfigurationService: InstallationConfigurationService,
    private modalService: ModalService
  ) { }

  showAlarmSettingsDialog(alarmInfo: IAlarmSettingsInfoDto) {
    if (this.isShowingSettingsDialog) { return };
    this.isShowingSettingsDialog = true;

    const installation$ = this.facilityService.getCurrentInstallation(this.route).pipe(
      first()
    );

    this.modalService.openDialog<IAlarmSettingsInfoDto>(AlarmSettingsModalComponent, { data: alarmInfo }).pipe(
      withLatestFrom(installation$),
      switchMap(([response, installation]: [Result<IAlarmSettingsInfoDto>, Installation]) => {
        if (response.dismissed) return EMPTY;
        const alarmSettings = response.result.alarmSettings;
        return this.installationConfigurationService.setAlarmSettings(installation.id, alarmInfo.applicationSubSystemId ?? alarmInfo.applicationId, alarmSettings);
      })
    ).subscribe({
      next: () => { },
      complete: () => { this.isShowingSettingsDialog = false; }
    });
  }
}
