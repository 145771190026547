import React from 'react';
import { Modal } from './Modal';
import { hideHelp } from '../actions';
import { AppDispatch } from '../../schematic/reducer';
import { translationService } from '../../services/translationService';
import styled from "styled-components";

export interface HelpModalProps {
  dispatch: AppDispatch;
}

const FormattedInstruction = styled.div`
  dd {
    margin-left: 8px;
  }
`;

/**
 * The text body was originally a hardcoded markdown file using a 3rd party plugin to create html.
 * As this was never translated the 3rd party plugin is removed and the text is hardcoded here instead.
 * Future improvement is to actually use ctf for these texts.
 *
 */

export const HelpModal: React.FC<HelpModalProps> = ({ dispatch }) => {
  return (
    <Modal
      header={<div>{translationService.getTranslation('help')}</div>}
      body={
        <FormattedInstruction>
          <h2>Systems</h2>
          <dl>
            <dt>Create a system</dt>
            <dd>Drag a system from the left hand menu on to the blank canvas</dd>
            <dt>Edit a system</dt>
            <dd>Click on a system, that is on the canvas. Edit it in the right hand sidebar</dd>
            <dt>Delete a system</dt>
            <dd>Select the system by clicking on it, so an outline appears. Press backspace to delete it</dd>
            <dt>Deselect a system</dt>
            <dd>Click anywhere outside of a system, this deselects the system and hides the form for it</dd>
          </dl>
          <h2>Connections</h2>
          <dl>
            <dt>Create a connection</dt>
            <dd>
              Double click on a connection point (the blue and red dots) and move the mouse to a second connection point. Then double click
              again to complete the connection.
           </dd>
            <dt>Select a connection</dt>
            <dd>Click on it once to select it</dd>
            <dt>Delete a connection</dt>
            <dd>Click on it so an outline appears. Press backspace to delete it</dd>
            <dt>Stop drawing a connection</dt>
            <dd>Right click or double click on background to cancel</dd>
          </dl>

          <h2>Connection points</h2>
          <dl>
            <dt>Select a connection point</dt>
            <dd>Click on it</dd>
            <dt>Create a connection point</dt>
            <dd>Double click anywhere on an existing connection</dd>
            <dt>Delete a connection point</dt>
            <dd>Click on a connection point so an outline appears. Press backspace to delete it</dd>
          </dl>
        </FormattedInstruction>
      }
      actions={<button onClick={() => dispatch(hideHelp())}>{translationService.getTranslation('close')}</button>}
      onBackdropClick={() => dispatch(hideHelp())}
    />
  );
};
