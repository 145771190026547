import { groupBy } from 'lodash';
import { useMode } from './mode-provider';
import { Connection } from './Connection';
import { colors } from '../../util/colors';
import { DraggingAid } from './DraggingAid';
import { ConnectionPoint } from './ConnectionPoint';
import { getAbsoluteCoordinates } from './Common';
import { systemMetadataMap } from './system-metadata';
import { ResizeObserver } from '@juggle/resize-observer';
import { getComponent } from './system-type-to-component';
import { Rect, SchematicMode, TEquipmentValues } from '../types';
import { AlarmsWarnings } from 'projects/dumbledore/src/schematic/actions';
import React, { MouseEventHandler, ReactElement, useEffect, useMemo, useRef } from 'react';
import { EConnectionType, FreeConnectionPointState, SchematicState, SystemState, VisualState } from '../state';
import { connectionPointKey, getConnectionPointViewModel, getConnectionPointVms } from '../util/rendering-util';
import {
  BackgroundClickEvent,
  BackgroundDoubleClickEvent,
  BackspaceEvent,
  DropSystemEvent,
  InputEventType,
  MouseMoveEvent,
  MouseRightClickEvent,
  MouseUpEvent,
  useDispatchInputEvent,
  useInputStrategy,
} from '../input-strategies/input-strategy-service';

const getAlarmForSystem = (systemId: string, alarms: AlarmsWarnings[] = []) => {
  return alarms
    .filter((a) => a.systemId === systemId)
    .reduce((old: AlarmsWarnings | null, current) => {
      if (!old) {
        return current;
      }

      if (old.type === 'Warning' && current.type === 'Alarm') {
        return current;
      }

      return old;
    }, null);
};

const renderSystems = (
  systems: SystemState[],
  stateToValuesMap: Record<string, TEquipmentValues[]>,
  alarms: AlarmsWarnings[],
  selectedSystemId: string | null
): ReactElement[] => {
  return systems.map((system) => {
    const SystemComponent: any = getComponent(system.type);
    const values = stateToValuesMap[system.hasId];
    const alarm = getAlarmForSystem(system.hasId, alarms);

    return <SystemComponent key={system.hasId} state={system} values={values} alarm={alarm} selected={selectedSystemId === system.hasId} />;
  });
};

const renderConnections = (state: SchematicState): ReactElement[] => {
  return state.connections
    .map((connectionModel) => {
      try {
        const from = getConnectionPointViewModel(state, connectionModel.from);
        const to = getConnectionPointViewModel(state, connectionModel.to);
        if (!from || !to) {
          throw new Error('Connection specified points that does not exist in state');
        }
        return (
          <Connection
            key={connectionModel.id}
            id={connectionModel.id}
            from={from}
            to={to}
            dotted={connectionModel.dotted}
            type={from.type}
          />
        );
      } catch (e) {
        console.log(e);
        return null;
      }
    })
    .filter((c): c is ReactElement => c !== null);
};

function getMinMaxPointsForXandY(allPoints: number[][]) {
  const allX = allPoints.map((point) => point[0]);
  const allY = allPoints.map((point) => point[1]);

  const xmin = Math.min(...allX);
  const xmax = Math.max(...allX);
  const ymin = Math.min(...allY);
  const ymax = Math.max(...allY);
  return { xmin, xmax, ymin, ymax };
}

const getViewBox = (state: SchematicState): Rect => {
  const allPoints = [
    ...state.systems.flatMap((system) => {
      const meta = systemMetadataMap[system.type];
      return [
        [system.left, system.top],
        [system.left + meta.width, system.top + meta.height],
      ];
    }),
    ...state.points.map((point) => [point.x, point.y]),
  ];
  if (allPoints.length === 0) {
    return { x: -20, y: -20, width: 40, height: 40 };
  }
  const { xmin, xmax, ymin, ymax } = getMinMaxPointsForXandY(allPoints);
  const x = xmin - 20;
  const width = xmax - xmin + 40;
  const y = ymin - 20;
  const height = ymax - ymin + 40;
  return { x, y, width, height };
};

export const ConnectionPoints: React.FC<{ points: FreeConnectionPointState[]; systems: SystemState[] }> = React.memo(
  ({ points, systems }) => {
    const pointVms = getConnectionPointVms(points, systems);
    return (
      <React.Fragment>
        {pointVms.map((pointModel) => (
          <ConnectionPoint key={connectionPointKey(pointModel.id)} model={pointModel} />
        ))}
      </React.Fragment>
    );
  }
);

interface ViewProps {
  state: SchematicState;
}

export const View: React.FC<ViewProps> = ({ state }) => {
  const svgElement = useRef<SVGSVGElement>(null);
  const inputStrategy = useInputStrategy();
  const dispatchEvent = useDispatchInputEvent();
  const mode = useMode();

  // performance: We use useMemo in order to avoid rerendering systems
  const systemToValuesMap = useMemo(() => {
    return groupBy(state.values, (value) => value.systemId);
  }, [state.values]);

  const onClick: MouseEventHandler = () => {
    const inputEvent: BackgroundClickEvent = {
      type: InputEventType.BackgroundClick,
    };
    dispatchEvent(inputEvent);
  };

  const onDblClick: MouseEventHandler = (event) => {
    if ((event.target as any).tagName === 'svg') {
      const { x, y } = getAbsoluteCoordinates(event);
      const inputEvent: BackgroundDoubleClickEvent = {
        type: InputEventType.BackgroundDoubleClick,
        x,
        y,
      };
      dispatchEvent(inputEvent);
    }
  };

  const onMouseMove: MouseEventHandler = (event) => {
    const { x, y } = getAbsoluteCoordinates(event);
    const inputEvent: MouseMoveEvent = {
      type: InputEventType.MouseMove,
      x,
      y,
    };
    dispatchEvent(inputEvent);
  };

  const onMouseUp: React.MouseEventHandler = () => {
    const inputEvent: MouseUpEvent = {
      type: InputEventType.MouseUp,
    };
    dispatchEvent(inputEvent);
  };

  const onRightClick: React.MouseEventHandler = (event) => {
    event.preventDefault();
    const { x, y } = getAbsoluteCoordinates(event);
    const inputEvent: MouseRightClickEvent = {
      type: InputEventType.MouseRightClick,
      x,
      y,
    };
    dispatchEvent(inputEvent);
  };

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Backspace' || event.key === 'Delete') {
        const inputEvent: BackspaceEvent = {
          type: InputEventType.Backspace,
        };
        dispatchEvent(inputEvent);
      }
    };
    window.document.addEventListener('keydown', onKeyDown);
    return () => {
      window.document.removeEventListener('keydown', onKeyDown);
    };
  }, [dispatchEvent]);

  const viewBox = getViewBox(state);
  const svgViewBox = mode !== SchematicMode.Edit ? `${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}` : undefined;

  // In edit mode, we want the svg to take up at least the entire space, adding scrollbars if the schematic does not fit
  // We do this programmatically
  useEffect(() => {
    if (!svgElement.current) {
      return;
    }

    const parentElement = svgElement.current.parentElement;
    if (!parentElement) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      // Use fix from here: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        if (!svgElement.current) {
          return;
        }

        // we make it a bit smaller than client area, because otherwise scrollbars will appear even when not necessary
        const containerWidth = Math.max(parentElement.clientWidth - 5, 0);
        const containerHeight = Math.max(parentElement.clientHeight - 5, 0);
        let width;
        let height;
        if (mode === SchematicMode.Edit) {
          width = Math.max(containerWidth, viewBox.x + viewBox.width);
          height = Math.max(containerHeight, viewBox.y + viewBox.height);
        } else {
          // scale to fit, but do not make it too large.
          width = Math.min(containerWidth, viewBox.width);
          height = Math.ceil((width * viewBox.height) / viewBox.width);
        }
        svgElement.current.setAttribute('width', `${width}px`);
        svgElement.current.setAttribute('height', `${height}px`);
      });
    });
    observer.observe(parentElement);
    return () => observer.unobserve(parentElement);
  }, [state]);

  const element = svgElement.current;

  const draggingAidViewbox: Rect = {
    x: Math.min(5, viewBox.x),
    y: Math.min(5, viewBox.y),
    width: element !== null ? Number.parseFloat(element.getAttribute('width') ?? '0') : 0,
    height: element !== null ? Number.parseFloat(element.getAttribute('height') ?? '0') : 0,
  };

  return (
    <svg
      ref={svgElement}
      // this class is used for selectors, not styling.
      className="dumbledore-schematic-svg"
      version="2.0"
      viewBox={svgViewBox}
      overflow="scroll"
      baseProfile="full"
      xmlns="http://www.w3.org/2000/svg"
      onDoubleClick={onDblClick}
      onClick={onClick}
      onMouseMove={onMouseMove}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDragEnter={(e) => {
        e.preventDefault();
      }}
      onContextMenu={onRightClick}
      onDrop={(event) => {
        event.preventDefault();
        const type = event.dataTransfer.getData('text/plain');
        if (type) {
          const { x, y } = getAbsoluteCoordinates(event);
          const inputEvent: DropSystemEvent = {
            type: InputEventType.DropSystem,
            x,
            y,
            systemType: type,
          };
          dispatchEvent(inputEvent);
        }
      }}
      onMouseUp={onMouseUp}
    >
      <defs>
        <linearGradient id="warmToCold">
          <stop offset="0%" stopColor={colors.connections[EConnectionType.supply].regular} />
          <stop offset="100%" stopColor={colors.connections[EConnectionType.return].regular} />
        </linearGradient>
        <linearGradient id="coldToWarm">
          <stop offset="0%" stopColor={colors.connections[EConnectionType.return].regular} />
          <stop offset="100%" stopColor={colors.connections[EConnectionType.supply].regular} />
        </linearGradient>
      </defs>
      <DraggingAid state={state} viewBox={draggingAidViewbox} />

      {renderSystems(state.systems, systemToValuesMap, state.alarmsWarnings, state.selectedSystemId)}

      {renderConnections(state)}
      {mode === SchematicMode.Edit ? <ConnectionPoints points={state.points} systems={state.systems} /> : null}
      {inputStrategy.render(state)}
    </svg>
  );
};
