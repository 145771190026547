import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Facility, Installation } from '../interfaces/facilty';
import { catchError, distinctUntilChanged, filter, flatMap, map } from 'rxjs/operators';
import { AppErrorService, CommandService, ECommissionStatus } from 'shared';
import { ActivatedRoute } from '@angular/router';
import fastDeepEqual from 'fast-deep-equal';
import { EFacilityType } from '../../../../serviceportal/src/app/interfaces/facility';

export interface IUpdateFacility {
  id: number;
  name: string;
  addressRoad: string;
  addressCity: string;
  addressPostal: string;
  location: {
    latitude: number;
    longitude: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class FacilityService {
  public facilities$: BehaviorSubject<Facility[] | null> = new BehaviorSubject<Facility[] | null>(null);
  private readonly baseUserUrl = `${environment.serverUrl}/api/facilities`;

  constructor(
    private httpClient: HttpClient,
    private appErrorService: AppErrorService,
    private commandService: CommandService
  ) {}

  public pushOrUpdateFacilityToFacilityList(facility: Facility) {
    const allFacilities = this.facilities$.value || [];

    const facilityIndex = allFacilities.findIndex(f => f.id === facility.id);
    if (facilityIndex === -1) {
      allFacilities.push(facility);
    } else {
      allFacilities[facilityIndex] = facility;
    }
    this.facilities$.next(allFacilities);
  }

  public removeFacilityFromList(facilityId: number) {
    const allFacilities = this.facilities$.value || [];
    const facilityListWithoutFacilityId = allFacilities.filter(f => f.id !== facilityId);
    this.facilities$.next(facilityListWithoutFacilityId);
  }

  facilityNotFoundError() {
    return this.appErrorService.createError({
      titleKey: 'page-error-service.not-found',
      messageKey: 'page-error-service.facility-not-found',
      report: false,
    });
  }

  installationNotFoundError() {
    return this.appErrorService.createError({
      titleKey: 'page-error-service.not-found',
      messageKey: 'page-error-service.installation-not-found',
      // technicalMessage: 'Facility did not contain installation specified in route params',
      report: false,
    });
  }

  installationNotCommissionedError() {
    return this.appErrorService.createError({
      titleKey: 'installation-view.under-commissioning-title',
      messageKey: 'installation-view.under-commissioning-message',
      report: false,
    });
  }

  public getCurrentFacility(route: ActivatedRoute): Observable<Facility> {
    const param = route.snapshot.paramMap.get('facilityId') as string;
    const facilityId = parseInt(param, 10);
    return this.facilities$.pipe(
      filter((f) => !!f),
      map((facilities) => {
        if (!facilities) {
          throw Error();
        }
        const facility = facilities.find((f) => f.id === facilityId);
        if (!facility) {
          throw Error();
        }
        return facility;
      }),
      // Only emit a change, when the facility is different
      distinctUntilChanged(fastDeepEqual),
      catchError(() => {
        return throwError(this.facilityNotFoundError());
      })
    );
  }

  public getCurrentInstallation(route: ActivatedRoute): Observable<Installation> {
    const facility$ = this.getCurrentFacility(route);
    const installationId = route.snapshot.paramMap.get('installationId');
    return facility$.pipe(
      map((facility) => {
        const installation = facility.installations.find((i) => i.id === installationId);
        if (!installation) {
          throw this.installationNotFoundError();
        }

        // For BuildingConnect installations, we do not allow navigation until the device is sending data.
        // For Mixit, we allow the user to navigate directly to the installation after commissioning, before any data is sent up.
        if (
          facility.facilityType === EFacilityType.BuildingConnect &&
          installation.commissionStatus &&
          ![ECommissionStatus.ReadyForTest, ECommissionStatus.BeingTested, ECommissionStatus.TestSuccess, ECommissionStatus.Live].includes(
            installation.commissionStatus
          )
        ) {
          throw this.installationNotCommissionedError();
        }

        return installation;
      }),
      distinctUntilChanged(fastDeepEqual),
    );
  }

  public getInstallation(installationId: string) {
    return this.facilities$.pipe(
      map(facilities => {
        const installations = facilities?.flatMap(f => f.installations);
        return installations?.find(i => i.id === installationId);
      }),
      distinctUntilChanged(fastDeepEqual),
    );
  }

  public updateFacility(facility: IUpdateFacility) {
    return this.commandService.execute(
      () => {
        return this.httpClient.patch(`${this.baseUserUrl}/${facility.id}`, facility).pipe(
          this.appErrorService.catchApiError({
            errorCodes: {
              404: {
                messageKey: 'facility-service.failed-to-update-no-access',
              },
            },
            fallbackMessageKey: 'facility-service.failed-to-update-facility',
          })
        );
      },
      { successMessageKey: 'facility-service.successfully-updated-facility' }
    );
  }

  public deleteEmptyFacility(facility: Facility) {
    return this.commandService.execute(
      () => {
        return this.httpClient.delete(`${this.baseUserUrl}/${facility.id}`).pipe(
          this.appErrorService.catchApiError({
            fallbackMessageKey: 'facility-service.failed-to-delete-facility',
          })
        );
      },
      { successMessageKey: 'facility-service.successfully-deleted-facility' }
    );
  }
}
