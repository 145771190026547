import styled from "styled-components";

export const StyledFooter = styled.footer`
  min-height: 60px;
  border-top: 1px solid lightgrey;
  background-color: white;

  .footer-buttons {
    padding: 4px 0;
    display: flex;
    justify-content: flex-end;
  }

  .button {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    padding: 10px 15px;
    margin: 5px 10px;
    font-size: 16px;
    font-weight: 400;
    font-family: 'GrundfosTheSansV2', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
      outline: 0;
    }

    &-icon {
      height: 16px;
      margin-top: -3px;
    }

    &-text {
      margin-left: 10px;
    }

    &:hover {
      background-color: #f3f5f8;
      cursor: pointer;
    }
  }

  .section {
    display: inline-block;
    margin-left: 20px;

    &.left {
      position: absolute;
      left: 15px;
      margin-left: 0;

      label,
      button {
        display: inline-block;
      }
    }

    label::after {
      content: ':';
    }

    select {
      display: inline-block;
    }
  }

  .toggle-container {
    display: inline-block;

    .button {
      display: inline-block;
      margin-left: 0;
      margin-right: 0;

      &:first-child {
        border-right: none;
        margin-left: 15px;
      }

      &:last-child {
        border-left: none;
        margin-right: 15px;
      }

      &.selected {
        background-color: #0068cb;
        color: #fff;
      }
    }
  }

`;
