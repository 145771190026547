<div [ngClass]="['base', matchRelativeParentDimensions ? 'expanded' : ''].join(' ')">
  <div
    class="input-container"
    [ngClass]="{ invalid: !valid && ngControl?.control?.touched, hasUnit: !!unit, disabled: ngControl?.control?.disabled || disabled }"
    pTooltip="{{ tooltipTextToShow }}"
    [tooltipPosition]="tooltipPosition">
    <input
      [min]="min"
      [max]="max"
      [step]="step"
      [disabled]="ngControl?.control?.disabled || disabled"
      [type]="inputType"
      [(ngModel)]="value"
      #filterInputField
      class="input"
      [placeholder]="placeholderTranslationString ? (placeholderTranslationString | translate) : ''"
      (keyup)="onKey(filterInputField.value)"
      (blur)="onBlur()"
      [maxlength]="maxlength"
      [attr.name]="formControlName || ngControl?.name" />
    <div class="unit" *ngIf="unit">
      {{ unit }}
    </div>
  </div>
  <!-- We show it as a description, rather than an error, when the user has not touched the input -->
  <div class="label error" [ngClass]="{ error: ngControl?.control?.touched }" *ngIf="!valid">
    {{ errorDescriptionTranslationString | translate: errorDescriptionData }}
  </div>
</div>
