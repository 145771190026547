import { SystemType, DistrictHeatingSystemState, BoilerSystemState, BoosterSystemState } from '../state';
import { EEquipment, EValueType, IEquipmentValue, ISetPointValue, IValveOpenValue } from '../types';
import { SampleEquipmentValue } from './sample-equipment-values';
import { v4 } from 'uuid';

// WIP

export const SAMPLE_BOOSTER_SYSTEM: BoosterSystemState = {
  hasId: v4(),
  left: 10,
  top: 2180,
  configured: false,
  type: SystemType.BoosterSystem,
  systemInfo: {
    title: '',
    NumberOfPumps: 6
  }
};

export const SAMPLE_VALUES_BOOSTER: SampleEquipmentValue[] = [
  {
    systemType: SystemType.BoosterSystem,
    type: EValueType.value,
    equipment: EEquipment.H_PF,
    value: '1,7 Bar',
  },
  {
    systemType: SystemType.BoosterSystem,
    type: EValueType.value,
    equipment: EEquipment.H_SF,
    value: '3,0 Bar\n(3,0 Bar)',
  },
  {
    systemType: SystemType.BoosterSystem,
    type: EValueType.value,
    equipment: EEquipment.Q_SF,
    value: '20 m3/h\n 184 W/m3',
  },
  {
    systemType: SystemType.BoosterSystem,
    type: EValueType.value,
    equipment: EEquipment.P_1,
    value: '75 %\n 1840 W',
  },
  {
    systemType: SystemType.BoosterSystem,
    type: EValueType.value,
    equipment: EEquipment.P_2,
    value: '75 %\n 1840 W',
  },
  {
    systemType: SystemType.BoosterSystem,
    type: EValueType.value,
    equipment: EEquipment.P_3,
    value: '75 %\n 1840 W',
  },
  {
    systemType: SystemType.BoosterSystem,
    type: EValueType.value,
    equipment: EEquipment.P_4,
    value: '75 %\n 1840 W',
  },
  {
    systemType: SystemType.BoosterSystem,
    type: EValueType.value,
    equipment: EEquipment.P_5,
    value: '75 %\n 1840 W',
  },
  {
    systemType: SystemType.BoosterSystem,
    type: EValueType.value,
    equipment: EEquipment.P_6,
    value: '75 %\n 1840 W',
  }
];
