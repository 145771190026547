import '@angular/compiler';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import translations from './assets/i18n/en.json';

import { detect } from 'detect-browser';

if (environment.production) {
  enableProdMode();
}

if (!sessionStorage.getItem('customerportal.browser-warning-shown')) {
  sessionStorage.setItem('customerportal.browser-warning-shown', 'true');
  const detectedBrowser = detect();
  if (detectedBrowser) {
    const isBrowserSupported = ['chrome', 'firefox'].includes(detectedBrowser.name);

    const isDeviceSupported = screen.width > 1024;

    if (!isDeviceSupported || !isBrowserSupported) {
      alert(translations['unsupported-device-or-browser']);
    }
  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

