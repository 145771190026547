import React from 'react';
import { Direction, getRotation } from './Common';

export interface TemperatureSensorProps {
  direction: Direction;
  x?: number;
  y?: number;
}

const CIRCLE_RADIUS = 4;
const SENSOR_LINE_LENGTH = 25;

const getShift = (direction: Direction): [number, number] => {
  switch (direction) {
    case Direction.NORTH:
      return [-CIRCLE_RADIUS - 1, 0];
    case Direction.EAST:
      return [0, -CIRCLE_RADIUS - 1];
    case Direction.SOUTH:
      return [CIRCLE_RADIUS + 1, 0];
    case Direction.WEST:
      return [0, CIRCLE_RADIUS + 1];
  }
};

export const TemperatureSensor: React.FC<TemperatureSensorProps> = React.memo(({ direction, x, y }) => {
  const rotation = getRotation(direction);
  const [shiftX, shiftY] = getShift(direction);
  return (
    <g transform={`translate(${(x || 0) + shiftX}, ${(y || 0) + shiftY}) rotate(${rotation})`}>
      <circle r={CIRCLE_RADIUS} fill="#FFF" stroke="#313131" strokeWidth="1.5" />
      <line x1={-CIRCLE_RADIUS} y1={0} x2={-CIRCLE_RADIUS - SENSOR_LINE_LENGTH} y2={0} stroke="#313131" strokeWidth="1.5" />
    </g>
  );
});
