import { Component, Input, OnInit } from '@angular/core';
import { DataPointsResult, DeviceDataPoint } from '../../../interfaces/data-points';
import { getDataPoint } from '../../../utils/data-point-utils';
import { combineLatest, Observable, of } from 'rxjs';
import { SeriesLineOptions, SeriesOptionsType } from 'highcharts';
import { filter, first, switchMap, map, shareReplay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { EEquipment } from '@ams/dumbledore';
import { DataPointsService, ReportPeriod, TimeSerie, TimeSeriesDetails } from '../../../services/data-points.service';
import { UserTrackingPeriodOptions } from '../../../services/user-tracking-helper.service';
import { ChartInterface } from '../chart.interface';

@Component({
  selector: 'app-differential-pressure-chart[installationId][applicationId]',
  templateUrl: './differential-pressure-chart.component.html',
  styleUrls: ['./differential-pressure-chart.component.scss'],
})
export class DifferentialPressureChartComponent implements OnInit, ChartInterface {
  @Input() public dataPoints$: Observable<DataPointsResult>;
  @Input() applicationId: string;
  @Input() installationId: string;

  @Input() userTrackingPeriodOptions: UserTrackingPeriodOptions;

  public options = {
    yAxis: {
      title: {
        text: 'Bar',
      },
      min: 0,
      softMax: 1,
    }
  };
  public visible$: Observable<boolean>;
  public legends$ = combineLatest([
    this.translateService.get('differential-pressure-chart.primary-legend'),
    this.translateService.get('differential-pressure-chart.secondary-legend'),
  ]);
  public dataPointsForChart$: Observable<(DeviceDataPoint | null)[]>;
  public getSeries: (period: ReportPeriod) => Observable<SeriesOptionsType[] | null[]>;

  constructor(private translateService: TranslateService, private dataPointsService: DataPointsService) {}

  ngOnInit(): void {
    this.dataPointsForChart$ = this.dataPoints$.pipe(first()).pipe(
      filter((dataPoints) => !!dataPoints?.data?.length),
      map((dataPoints) => [
        getDataPoint({
          applicationId: this.applicationId,
          systems: dataPoints?.data || [],
          dumbledoreId: EEquipment.H_P,
          humanReadableId: '',
        }),
        getDataPoint({
          applicationId: this.applicationId,
          systems: dataPoints?.data || [],
          dumbledoreId: EEquipment.H_S,
          humanReadableId: '',
        }),
      ]),
      first()
    );
    this.visible$ = this.dataPointsForChart$.pipe(map((dataPoints) => dataPoints.some((dataPoint) => dataPoint)));


    this.getSeries = (period: ReportPeriod) => {
      return combineLatest([this.legends$, this.dataPointsForChart$]).pipe(
        switchMap(([legends, dataPoints]) => {
          const timeSeriesDetails: TimeSeriesDetails = {
            dataPoints,
            period,
            installationId: this.installationId,
            systemId: this.applicationId,
            requestId: 'differential_pressure',
          };
          return this.dataPointsService.getTimeSeries(timeSeriesDetails).pipe(
            map((seriesData: TimeSerie[]) => {
              return [
                { name: legends[0], data: seriesData[0], type: 'line' } as SeriesLineOptions,
                { name: legends[1], data: seriesData[1], type: 'line' } as SeriesLineOptions,
              ].filter((series) => series.data);
            }),
            shareReplay(1)
          );
        })
      );
    };
  }
}
