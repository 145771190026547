import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription, first, map, switchMap } from 'rxjs';
import { ActivityComment } from '../../interfaces/installation';
import { InstallationService } from '../../services/installation.service';

export enum ViewMode {
  Display = "display",
  Edit = "edit"
}

@Component({
  selector: 'app-installation-activity-tile',
  templateUrl: './installation-activity-tile.component.html',
  styleUrls: ['./installation-activity-tile.component.scss']
})
export class InstallationActivityTileComponent implements OnInit, OnDestroy {
  public ViewModeOptions = ViewMode;
  public viewMode: ViewMode = ViewMode.Display;
  public isLoading: boolean = false;
  public form: UntypedFormGroup;
  public activityComment: ActivityComment;
  public activity: ActivityComment | undefined;
  public activity$: Observable<ActivityComment>;

  private activitiesSubscription: Subscription;
  private awatingActivityUpdate: boolean = false;

  @Input() installationId: string;
  @Input() activities$: Observable<ActivityComment[]>;

  constructor(
    private installationService: InstallationService) { }

  ngOnInit(): void {

    this.form = new UntypedFormGroup({
      comment: new UntypedFormControl("")
    });

    this.activitiesSubscription = this.activities$.subscribe((activities: ActivityComment[]) => {
      this.activity = (activities && activities.length) ? activities[0] : undefined;
      if (this.awatingActivityUpdate) {
        this.awatingActivityUpdate = false;
        this.viewMode = ViewMode.Display;
        this.isLoading = false;
        this.form.controls.comment.enable();
        this.form.controls.comment.setValue("");
      }
    });
  }

  ngOnDestroy(): void {
    this.activitiesSubscription.unsubscribe();
  }

  public changeViewMode(viewMode: ViewMode) {
    this.viewMode = viewMode;
  }

  public onSubmit(formData: any ) {
    this.isLoading = true;
    this.form.controls.comment.disable();

    const dto: ActivityComment = { installationId: this.installationId, text: formData.comment };

    this.installationService.updateActivityComment(dto).subscribe((activityComment: ActivityComment) => {
      this.awatingActivityUpdate = true;
      this.installationService.pushActivityUpdate([activityComment]);
    });
  }

  public onEdit() {
    // Populate edit field with existing comments.
    const currentText = this.activity ? this.activity.text : "";
    this.form.controls.comment.setValue(currentText);

    // Change to edit mode.
    this.viewMode = ViewMode.Edit;
  }
}
