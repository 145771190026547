import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
// @ts-ignore
import Leaflet from 'leaflet';
import { Facility } from '../../interfaces/facilty';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { PageInfoService } from '../../services/page-info.service';
import { interval, Observable, Subscription } from 'rxjs';
import { debounce, tap } from 'rxjs/operators';
import 'leaflet-bing-layer';
import 'leaflet.markercluster';
import { MapService, MarkerState } from '../../services/map.service';

declare global {
  interface Window { L: typeof Leaflet; }
}

// Required for bing map
window.L = Leaflet;

export interface FacilityData {
  data: Facility[];
  trigger?: string;
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public facilityData$: Observable<FacilityData>;
  @Input() hideZoom = false;
  @Input() interactive = true;
  @Input() saveCoordinatesInQueryParams = false;
  @Input() zoomOutFromSingleFacility = false;
  @Input() showZeroForFacilities = true;
  @Output() facilityClick: EventEmitter<Facility> = new EventEmitter();

  private facilities: Facility[] = [];
  private facilitiesSubscription = new Subscription();
  private map: any;
  private clusterGroup: Leaflet.ClusterGroup;
  private mapLoaded = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private pageInfo: PageInfoService,
    private elementRef: ElementRef,
    private mapService: MapService
  ) {}

  ngOnInit(): void {
    this.facilitiesSubscription = this.facilityData$
      .pipe(
        tap((facilityData) => (this.facilities = facilityData.data)),
        debounce(() => interval(100)),
        tap((facilityData) => {
          if (this.mapLoaded) {
            setTimeout(() => {
              // Update cluster and refresh bounds when search or filters are updated.
              const triggerUpdate = (facilityData.trigger !== undefined && facilityData.trigger !== 'service');
              this.updateCluster(triggerUpdate);
            }, 100);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.facilitiesSubscription.unsubscribe();
    if (this.map) {
      this.map.off('moveend');
    }
  }

  ngAfterViewInit() {
    // Angular initializes the component even when it is not attached to DOM.
    // We wrap in a setTimeout to prevent this issue.
    setTimeout(() => {
      // Bounds of the world!
      const southWest = Leaflet.latLng(-89.98155760646617, -180);
      const northEast = Leaflet.latLng(89.99346179538875, 180);
      const bounds = Leaflet.latLngBounds(southWest, northEast);

      // Initialize map and attach to html element.
      this.map = Leaflet.map(this.elementRef.nativeElement, {
        zoomControl: !this.hideZoom,
        maxBounds: bounds, // Limit the map to the bounds of a world map
        minZoom: 3,
        maxBoundsViscosity: 1.0,
      });

      // Add OpenStreetMap tile layer to map.
      Leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 18,
        attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      }).addTo(this.map);

      this.map.on('load', () => {
        this.mapLoaded = true;
        let updateBounds = true;

        // Update map coordinates if query params are specified.
        const params = this.route.snapshot.queryParams;
        if (params.lat && params.lng && params.zoom && this.saveCoordinatesInQueryParams) {
          this.map.setView([params.lat, params.lng], params.zoom);
          updateBounds = false;
        }

        // Create initial facility clusters.
        this.updateCluster(updateBounds);

        // Initialize 'Move' event handler.
        this.listenToMapMove();

        if (!this.interactive) {
          this.disable();
        }
      });

      // Center map around Europe as a default.
      this.map.setView([55.7797581, 10.9970365], 6);
    });
  }

  private listenToMapMove() {
    if (!this.saveCoordinatesInQueryParams) {
      return;
    }

    this.map.on('moveend', (e: any) => {
      const center = e.target.getCenter();
      const zoom = e.target.getZoom();
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          lat: center.lat,
          lng: center.lng,
          zoom,
        },
        replaceUrl: true,
        queryParamsHandling: 'merge',
      });
      this.pageInfo.setMapCoordinates(center.lat, center.lng, zoom);
    });
  }

  public updateCluster(updateBounds = true) {
    // Initialize cluster group if it does not exist.
    if (!this.clusterGroup) {
      this.clusterGroup = Leaflet.markerClusterGroup({
        // Disable the bounds being shown
        showCoverageOnHover: false,
        animate: true,
        spiderfyDistanceMultiplier: 2.5,
        spiderLegPolylineOptions: { opacity: 0 }, // Don't show lines from spiderify effect
        iconCreateFunction: (cluster: any) => {
          const facilities = cluster.getAllChildMarkers().map((marker: any) => marker.facility as Facility);
          const markerState = this.mapService.getMarkerState(facilities);
          return this.createClusterMarker(markerState, cluster.getChildCount());
        },
      });

      // Do not show the cluster marker after we click on it.
      this.clusterGroup.on('spiderfied', (e: any) => {
        e.cluster.setOpacity(0);
      });

      // Add the clusters to the map
      this.map.addLayer(this.clusterGroup);
    }

    // Update markers.
    const markers = [];
    if (this.facilities) {
      for (const facility of this.facilities) {
        // Ignore facilities without location.
        if (!facility.location) {
          continue;
        }

        const marker = this.createBuildingMarker(facility);

        marker.on('click', () => {
          this.facilityClick.emit(facility);
        });

        marker.facility = facility;

        markers.push(marker);
      }
    }

    // Bulk update markers in cluster group.
    this.clusterGroup.clearLayers();
    this.clusterGroup.addLayers(markers);


    // Ignore update from service trigger (facilityUpdated event).
    if (updateBounds) {
      this.fitToBounds();
    }
  }

  @HostListener('window:resize', ['$event'])
  resize() {
    setTimeout(() => {
      this.map.invalidateSize();
    }, 400);
  }

  public fitToBounds() {
    // If there is no facilities on the map (due to query or filters).
    if (this.facilities === undefined || this.facilities.length === 0) {
      this.map.setView([25.0, 0.0], 3);
      return;
    }

    // Ensure we can see all the facilities on the map
    this.map.fitBounds(this.clusterGroup.getBounds(), { padding: [30, 30] });

    // If there is only one facility, we zoom in too far, so we zoom out
    if (this.facilities.length === 1 && this.zoomOutFromSingleFacility) {
      this.map.setZoom(13);
    }
  }

  private disable() {
    this.map.dragging.disable();
    this.map.touchZoom.disable();
    this.map.doubleClickZoom.disable();
    this.map.scrollWheelZoom.disable();
    this.map.boxZoom.disable();
    this.map.keyboard.disable();
    if (this.map.tap) {
      this.map.tap.disable();
    }
  }

  private createClusterMarker(markerState: MarkerState, childCount: number): Leaflet.divIcon {
    const marker = Leaflet.divIcon({
      className: `facility-icon cluster ${this.mapService.getIconClass(markerState)}`,
      html: `
        <button class="background">
          ${this.mapService.getMarkerIcon(markerState)}
          <div class="foreground">
              ${childCount}
          </div>
        </button>
      `,
    });
    return marker;
  }

  private createBuildingMarker(facility: Facility): Leaflet.marker {
    const markerState = this.mapService.getMarkerState([facility]);
    const showZero = this.showZeroForFacilities && facility.installations.length === 0;
    return Leaflet.marker([facility.location.latitude, facility.location.longitude], {
      icon: Leaflet.divIcon({
        className: `facility-icon marker  ${this.mapService.getIconClass(markerState)}`,
        html: `
          <div class="facility-tooltip">
            ${ facility.name }
          </div>
          <button class="background">
            ${this.mapService.getMarkerIcon(markerState)}
            <div class="foreground">
              ${showZero ? '0' : '<img class="building-icon" src="./assets/svgs/building_outline.svg">'}
            </div>
          </button>
        `,
      }),
    });
  }
}
