import { Component, OnInit } from '@angular/core';
import { AlarmService } from '../../services/alarm.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-alarm-metadata',
  templateUrl: './alarm-metadata.component.html',
  styleUrls: ['./alarm-metadata.component.scss']
})
export class AlarmMetadataComponent implements OnInit {

  public metadatas$: Observable<any[]>;

  constructor(private alarmService: AlarmService) {
  }

  ngOnInit(): void {
    this.metadatas$ = this.alarmService.alarmMetadatas$;
  }
}
