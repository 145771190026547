<gbc-modal (dismiss)='dismiss()' [title]="'mixit-underfloor-protection-modal.underfloor-protection' | translate" class="gbc-with-dividers" data-test-id='mixit-underfloor-protection-modal'>
  <gbc-modal-content>
    <h2 class='modal-header'>
      {{ 'mixit-underfloor-protection-modal.overheat-protection' | translate }}
    </h2>
    <p class='modal-text'>
      {{ 'mixit-underfloor-protection-modal.underfloor-protection-description' | translate }}
    </p>
    <form [formGroup]='form'>
      <gbc-modal-section>
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'app-enable' | translate }}</gbc-input-label>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-toggle formControlName='enabled'></gbc-toggle>
        </gbc-modal-section-column>
      </gbc-modal-section>
      <gbc-modal-section>
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'mixit-underfloor-protection-modal.max-flow-temperature' | translate }}</gbc-input-label>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-field
            [errorDescriptionData]='maxTemperatureMinMax'
            [max]='maxTemperatureMinMax.max'
            [min]='maxTemperatureMinMax.min'
            [showErrorTranslationString]='true'
            errorDescriptionTranslationString='app-enter-value-between'
            formControlName='maxTemperature'
            inputType='number'
            unit='°C'>
          </gbc-input-field>
        </gbc-modal-section-column>
      </gbc-modal-section>
    </form>
  </gbc-modal-content>
  <gbc-modal-buttons>
    <button gbc-modal-button (click)='close()' angulartics2On='click' angularticsAction='cancelClicked' angularticsCategory='adjustUnderfloorProtectionShown'>
      {{ 'app-cancel' | translate }}
    </button>
    <button gbc-modal-button class='primary' (click)='save()' [disabled]='(valid$ | async) === false' angulartics2On='click' angularticsAction='saveClicked' angularticsCategory='adjustUnderfloorProtectionShown'>
      {{ 'app-save' | translate }}
    </button>
  </gbc-modal-buttons>
</gbc-modal>
