<ng-container>
  <gbc-page-container *ngIf="currentUser$ | async as user">
    <gbc-tile [title]="'app-profile.title' | translate">
      <gbc-table>
        <tr gbc-table-row>
          <td gbc-table-cell>
            {{ 'app-profile.name' | translate }}
          </td>
          <td gbc-table-cell>
            <gbc-input-container>
              <gbc-input-field disabled="true" name="name" [(ngModel)]="user.name"></gbc-input-field>
            </gbc-input-container>
          </td>
        </tr>
        <tr gbc-table-row>
          <td gbc-table-cell>
            {{ 'app-profile.email' | translate }}
          </td>
          <td gbc-table-cell>
            <gbc-input-container>
              <gbc-input-field disabled="true" name="email" [(ngModel)]="user.email"></gbc-input-field>
            </gbc-input-container>
          </td>
        </tr>
        <tr gbc-table-row>
          <td gbc-table-cell>
            {{ 'app-profile.phone' | translate }}
          </td>
          <td gbc-table-cell>
            <gbc-input-container>
              <gbc-input-field
                disabled="true"
                name="phone"
                [placeholderTranslationString]="'app-profile.no-phone-number'"
                [(ngModel)]="user.notificationSettings.mobile"
              ></gbc-input-field>

              <div class="verified-edit-thing">
                <div *ngIf="user.notificationSettings.mobileValidatedState === 'Validated'" class="verified">
                  <img class="verified-icon" src="./assets/svgs/check-mark.svg" alt="" />
                  <span>{{ 'app-profile.verified' | translate }}</span>
                </div>

                <div
                  class="not-verified"
                  *ngIf="user.notificationSettings.mobile && user.notificationSettings.mobileValidatedState === 'ValidationSent'"
                >
                  {{ 'app-profile.not-verified' | translate }}
                </div>

                <button
                  *ngIf="user.notificationSettings.mobileValidatedState !== 'Validated'"
                  gbc-text-button
                  (click)="editPhoneNumber(user.notificationSettings)"
                >
                  {{ 'app-profile.edit-phone-number' | translate }}
                </button>
                <button
                  gbc-text-button
                  *ngIf="user.notificationSettings.mobile"
                  type="danger"
                  (click)="removePhoneNumber(user.notificationSettings)"
                >
                  {{ 'app-profile.remove-phone-number' | translate }}
                </button>
              </div>
            </gbc-input-container>
          </td>
        </tr>
      </gbc-table>
    </gbc-tile>

    <gbc-tile [title]="'app-profile.notifications' | translate">
      <form [formGroup]="notificationForm">
        <gbc-table>
          <tr gbc-table-row>
            <td gbc-table-cell>{{ 'app-profile.sms' | translate }}</td>
            <td gbc-table-cell>
              <div class="p-field-checkbox">
                <p-checkbox
                  [formControl]="$any(notificationForm).controls['mobileActive']"
                  id="mobile-active"
                  [binary]="true"
                  data-test-id="mobile-active"
                ></p-checkbox>
                <label for="mobile-active">
                  {{
                    (notificationForm.controls.mobileActive.disabled
                      ? 'app-profile.sms-send-message-disabled'
                      : notificationForm.controls.mobileActive.value
                      ? 'app-profile.sms-send-message-true'
                      : 'app-profile.sms-send-message-false'
                    ) | translate: { phone: user.notificationSettings.mobile }
                  }}</label
                >
              </div>
            </td>
          </tr>

          <tr gbc-table-row>
            <td gbc-table-cell>{{ 'app-profile.email' | translate }}</td>
            <td gbc-table-cell>
              <div class="p-field-checkbox">
                <p-checkbox
                  [formControl]="$any(notificationForm).controls['emailActive']"
                  id="email-active"
                  [binary]="true"
                  data-test-id="email-active"
                ></p-checkbox>
                <label for="email-active">
                  {{
                    (notificationForm.controls.emailActive.value
                      ? 'app-profile.email-send-message-true'
                      : 'app-profile.email-send-message-false'
                    ) | translate: { email: user.email }
                  }}</label
                >
              </div>
            </td>
          </tr>
        </gbc-table>
      </form>
    </gbc-tile>

    <gbc-tile [title]="'app-profile.terms-title' | translate" data-test-id="profile-page-terms">
      <gbc-table id="app-profile-terms">
        <tr gbc-table-row>
          <td gbc-table-cell>{{ 'app-profile.terms-of-use-title' | translate }}</td>
          <td gbc-table-cell>
            <div class="p-field-checkbox">
              <p-checkbox
                [(ngModel)]="checked"
                [disabled]="true"
                binary="true"
                id="terms-of-use"
                pTooltip="{{ 'app-profile.terms-of-use-tooltip' | translate }}"
                tooltipPosition="top"
              ></p-checkbox>
              <label for="terms-of-use">
                {{ 'app-profile.terms-text' | translate }}
                <a [href]="TERMS_OF_USE_LINK" target="_blank">{{ 'app-profile.terms-of-use-title' | translate }}</a></label
              >
            </div>
          </td>
        </tr>
        <tr gbc-table-row>
          <td gbc-table-cell>{{ 'app-profile.terms-and-conditions-title' | translate }}</td>
          <td gbc-table-cell>
            <div class="p-field-checkbox">
              <p-checkbox
                [(ngModel)]="checked"
                [disabled]="true"
                binary="true"
                id="terms-and-conditions"
                pTooltip="{{ 'app-profile.terms-and-conditions-tooltip' | translate }}"
                tooltipPosition="top"
              ></p-checkbox>
              <label for="terms-of-use">
                {{ 'app-profile.terms-text' | translate }}
                <a [href]="TERMS_AND_CONDITIONS_LINK" target="_blank">{{ 'app-profile.terms-and-conditions-title' | translate }}</a>
              </label>
            </div>
          </td>
        </tr>
      </gbc-table>
    </gbc-tile>

    <gbc-tile [title]="'account-page.company-details.company' | translate" *ngIf="company$ | async as company">
      <gbc-table>
        <tr gbc-table-row>
          <td gbc-table-cell>
            {{ 'account-page.company-details.company-name' | translate }}
          </td>
          <td gbc-table-cell>
            {{ company.name }}
          </td>
        </tr>
        <tr gbc-table-row>
          <td gbc-table-cell>
            {{ 'account-page.company-details.address' | translate }}
          </td>
          <td gbc-table-cell>
            {{ company.companyLocation?.address || '-' }}
          </td>
        </tr>
        <tr gbc-table-row>
          <td gbc-table-cell>
            {{ 'account-page.company-details.postal-code' | translate }}
          </td>
          <td gbc-table-cell>
            {{ company.companyLocation?.postal || '-' }}
          </td>
        </tr>
        <tr gbc-table-row>
          <td gbc-table-cell>
            {{ 'account-page.company-details.city' | translate }}
          </td>
          <td gbc-table-cell>
            {{ company.companyLocation?.city || '-' }}
          </td>
        </tr>
        <tr gbc-table-row>
          <td gbc-table-cell>
            {{ 'account-page.company-details.country' | translate }}
          </td>
          <td gbc-table-cell>
            {{ company.companyLocation?.country || '-' }}
          </td>
        </tr>
        <tr gbc-table-row>
          <td gbc-table-cell>
            {{ 'account-page.company-details.region' | translate }}
          </td>
          <td gbc-table-cell>
            {{ company.companyLocation?.region || '-' }}
          </td>
        </tr>
      </gbc-table>
    </gbc-tile>
  </gbc-page-container>
</ng-container>
