import { Pipe, PipeTransform } from '@angular/core';
import { AlarmMetadata } from '../interfaces/alarm';

@Pipe({
  name: 'formatAlarmMetadata'
})
export class FormatAlarmMetadataPipe implements PipeTransform {

  transform(alarMetaData: AlarmMetadata, ...args: unknown[]): string {
    if (alarMetaData !== null) {
      return alarMetaData.mainErrorCode + (alarMetaData.subErrorCode && alarMetaData.subErrorCode !== '0' ? ('-' + alarMetaData.subErrorCode) : '')
    }

    return '';
  }

}
