import { LabelPosition, SystemMeta } from '../../types';
import { SmallLabelDeltas } from '../../../util/constants';
import { EConnectionType, SystemType } from '../../../state';
import { EConnectionPointPlacement, EEquipment } from '../../../types';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/cold-water-supply.svg';
import coldWaterPumpSystemForm from '../../../../models/systems/coldWaterSupplySystem/coldWaterSupplySystemForm.json';
import { IColdWaterSupplySystemFormInterface } from '../../../../models/systems/coldWaterSupplySystem/coldWaterSupplySystemFormInterface';

export const HEIGHT = 140;
export const WIDTH = 140;
export const PIPE_Y = 80;

export const coldWaterSupplyMeta: SystemMeta = {
  systemType: SystemType.ColdWaterSupplySystem,
  name: 'Cold Water Supply',
  thumbnail,
  form: coldWaterPumpSystemForm,
  width: WIDTH,
  height: HEIGHT,
  calculateConnectionPoints: (systemInfo: IColdWaterSupplySystemFormInterface) => [
    ...(systemInfo.LeftToRight
      ? [
          {
            key: EConnectionPointPlacement.connectionSecondaryForward,
            x: WIDTH,
            y: PIPE_Y,
            type: EConnectionType.return,
            dotted: false,
          },
        ]
      : [
          {
            key: EConnectionPointPlacement.connectionPrimaryForward,
            x: 0,
            y: PIPE_Y,
            type: EConnectionType.return,
            dotted: false,
          },
        ]),
  ],
};

export const getColdWaterSupplyLabels = (systemInfo: IColdWaterSupplySystemFormInterface): LabelPosition[] => {
  const coldWaterSupplyFlowLabel: LabelPosition[] = systemInfo.Q_SC_present
    ? [
        {
          id: EEquipment.Q_SC,
          x: WIDTH / 2,
          y: PIPE_Y + SmallLabelDeltas.SOUTH_X,
          horizontalAlignment: 'middle',
        },
      ]
    : [];

  return [...coldWaterSupplyFlowLabel];
};
