<app-multi-value-tile
  [applicationId]="application.id"
  [installationId]="(installation$ | async)?.id"
  [tileTitle]="('pump-status-tile.title' | translate) + (title ? ( ' - ' + title) : '')"
  [lastUpdated]="lastUpdated$ | async"
  [values]="formattedPumpData$| async"
  [showAdjust]="!!(pumpOptions && pumpOptions.length)"
  [disableAdjust]="systemControlMode === SystemControlModeOptions.Off"
  (adjust)="adjustPump()"
  [attr.data-system-control-mode]="systemControlMode">
</app-multi-value-tile>
