import { ICascadeBufferTankChargingSystemFormInterface } from '../../models/systems/cascadeBufferTankChargingSystem/cascadeBufferTankChargingSystemFormInterface';
import { ICascadeTankSystemFormInterface } from '../../models/systems/cascadeTankSystem/cascadeTankSystemFormInterface';
import { ICommercialHotWaterSystemFormInterface } from '../../models/systems/commercialHotWaterSystem/commercialHotWaterSystemFormInterface';
import { IHeatExchangingSystemFormInterface } from '../../models/systems/heatExchangingSystem/heatExchangingSystemFormInterface';
import { IHotWaterTankStandAloneSystemFormInterface } from '../../models/systems/hotWaterTankStandAloneSystem/hotWaterTankStandAloneSystemFormInterface';
import { IHotWaterTankSystemFormInterface } from '../../models/systems/hotWaterTankSystem/hotWaterTankSystemFormInterface';
import { IIndirectDistrictHeatingSupplySystemFormInterface } from '../../models/systems/indirectDistrictHeatingSupplySystem/indirectDistrictHeatingSupplySystemFormInterface';
import { IMixingloopSystemFormInterface } from '../../models/systems/mixingloopSystem/mixingloopSystemFormInterface';
import { IMixingloopUnspecifiedLoadSystemFormInterface } from '../../models/systems/mixingloopUnspecifiedLoadSystem/mixingloopUnspecifiedLoadSystemFormInterface';
import { ITwoStrokeHeatExchangerHotWaterSystemFormInterface } from '../../models/systems/twoStrokeHeatExchangerHotWaterSystem/twoStrokeHeatExchangerHotWaterSystemFormInterface';

export const systemHasEnergyValve = (
    systemInfo: ICascadeBufferTankChargingSystemFormInterface
    | ICascadeTankSystemFormInterface
    | ICommercialHotWaterSystemFormInterface
    | IHeatExchangingSystemFormInterface
    | IHotWaterTankStandAloneSystemFormInterface
    | IHotWaterTankSystemFormInterface
    | IIndirectDistrictHeatingSupplySystemFormInterface
    | IMixingloopSystemFormInterface
    | IMixingloopUnspecifiedLoadSystemFormInterface
    | ITwoStrokeHeatExchangerHotWaterSystemFormInterface): boolean => {
  return systemInfo.MV_positionControl === "ModbusRtuControl" && 
    !!systemInfo.MV_ModbusRtuProfile?.startsWith("BelimoEnergyValveProfile");
}
