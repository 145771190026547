import { SystemType, DistrictHeatingSystemState, BoilerSystemState, UnspecifiedLoadSystem } from '../state';
import { EEquipment, EValueType, IEquipmentValue, ISetPointValue, IValveOpenValue } from '../types';
import { SampleEquipmentValue } from './sample-equipment-values';
import { v4 } from 'uuid';

export const SAMPLE_UNSPECIFIED_LOAD: UnspecifiedLoadSystem = {
  hasId: v4(),
  left: 10,
  top: 1180,
  configured: false,
  type: SystemType.UnspecifiedLoad,
  systemInfo: {
    HM_present: true,
    HM_location: 'PrimaryReturn',
    Application: 'RadiatorOneStringHeating'
  }
};

export const SAMPLE_VALUES_UNSPECIFIED_LOAD: SampleEquipmentValue[] = [
  {
    systemType: SystemType.UnspecifiedLoad,
    type: EValueType.value,
    equipment: EEquipment.T_F,
    value: '72.9\u2103',
  },
  {
    systemType: SystemType.UnspecifiedLoad,
    type: EValueType.value,
    equipment: EEquipment.T_R,
    value: '33.7\u2103',
  },
  {
    systemType: SystemType.UnspecifiedLoad,
    type: EValueType.value,
    equipment: EEquipment.Q_PF,
    value: '10.3\u33a5/s',
  },
  {
    systemType: SystemType.UnspecifiedLoad,
    type: EValueType.value,
    equipment: EEquipment.Q_PR,
    value: '10.3\u33a5/s',
  },
];
