import { SystemMeta } from './types';
import styled from 'styled-components';
import { useMode } from './mode-provider';
import { colors } from '../../util/colors';
import { SystemBaseState, VisualState } from '../state';
import { getRelativeCoordinates } from './Common';
import React, { useCallback, useRef } from 'react';
import { formService } from '../../services/formService';
import { SchematicMode, SystemControlMode, TEquipmentValues } from '../types';
import { AlarmsWarnings } from '../../schematic/actions';
import arrowUpSvg from '!svg-url-loader!../../../assets/icons/arrow-up.svg';
import alarmIconFilledSvg from '!svg-url-loader!../../../assets/icons/alarm-round-filled.svg';
import alarmTriangleOutlineSvg from '!svg-url-loader!../../../assets/icons/alarm-triangle-outline.svg';
import warningTriangleFilledSvg from '!svg-url-loader!../../../assets/icons/warning-triangle-filled.svg';
import warningTriangleOutlineSvg from '!svg-url-loader!../../../assets/icons/warning-triangle-outline.svg';
import pasteurizationSvg from '!svg-url-loader!../../../assets/icons/pasteurization.svg';
import warmWeatherShutdownSvg from '!svg-url-loader!../../../assets/icons/warm-weather-shutdown.svg';
import controlModeManualSvg from '!svg-url-loader!../../../assets/icons/control-mode-manual.svg';
import { InputEventType, SystemClickEvent, SystemMouseDownEvent, useDispatchInputEvent } from '../input-strategies/input-strategy-service';
import { IconPositionHandler } from '../util/rendering-util';

// These props are shared by all systems, and passed into the individual system component.
export interface BaseSystemProps<T> {
  state: SystemBaseState<T>;
  values: TEquipmentValues[];
  alarm?: AlarmsWarnings;
  selected: boolean;
}

// These props are are passed to the 'Component' below...
export interface SystemProps {
  state: SystemBaseState<any>;
  meta: SystemMeta;
  selected: boolean;
  alarm?: AlarmsWarnings;
  hideName?: boolean;
  titleX?: number;
  titleY?: number;
}

// Visual properties used for styling support...
// The use of $-prefix is to ensure, that the styled-compoent will filter out
// the properties and not pass them on to the DOM (unknown props will cause warnings).
export interface StyledSystemProps {
  $hoverEffect: boolean;
  $alarm: boolean;
  $warning: boolean;
  $selected: boolean;
  $editMode: boolean;
  $invalidForm: boolean;
  $manualMode: boolean;
  $pasteurization: boolean;
  $warmWeatherShutdown: boolean;
}

enum ErrorState {
  Unknown = 0,
  Alarm = 1, // red
  Warning = 2, // orange
  Information = 3, // blue
  Highlight = 4, // grey
}

const DEBUG = false;
const HEADER_TOP = 20;
const HEADER_AREA_HEIGHT: number = 40;
const CONTAINER_PADDING: number = 10;

// Using styled components for styling system.
const StyledSystem = styled.g<StyledSystemProps>`
  // Default border.
  & > rect.gbc-system-rect {
    stroke-width: 2px;
    stroke-opacity: 0.6;
  }

  //-- Secondary Severity ------------------------------------------------------

  // Manual Mode (Warning)
  ${(props) => props.$manualMode && `
  & > rect.gbc-system-rect { stroke: rgb(245,166,35); stroke-dasharray: 2,2; }`}

  // Pastauration (Warning)
  ${(props) => props.$pasteurization && `
  & > rect.gbc-system-rect { stroke: rgb(245,166,35); stroke-dasharray: 2,2; }`}


  // Form Validation Error (Alarm)
  ${(props) => props.$editMode && props.$invalidForm && `
  & > rect.gbc-system-rect { stroke: rgb(255, 5, 0); stroke-dasharray: 2,2; }`}

  //-- Primary Severity --------------------------------------------------------

  // Warning
  ${(props) => props.$warning && `
  & > rect.gbc-system-rect { stroke: rgb(245,166,35) !important; stroke-dasharray: none; }`}

  // Alarm
  ${(props) => props.$alarm && `
  & > rect.gbc-system-rect { stroke: rgb(255, 5, 0) !important; stroke-dasharray: none; }`}

  //-- States ------------------------------------------------------------------

  // Hover state.
  ${(props) => props.$hoverEffect && `
  &:hover { cursor: pointer; }
  &:hover > rect.gbc-system-rect { stroke: #d9dddf; stroke-opacity: 1 !important; stroke-dasharray: none !important; }
  &:hover > .gbc-arrow-up { opacity: 1; }`}

  // Selection state.
  ${(props) => props.$selected && `
  & > .gbc-arrow-up { opacity: 1; }
  & > rect.gbc-system-rect { stroke: #d9dddf; stroke-opacity: 1 !important; stroke-dasharray: none !important; }
  &:hover > rect.gbc-system-rect { stroke: #d9dddf; }`}

  //-- Debug -------------------------------------------------------------------

  ${DEBUG && `
  & > .gbc-hover-box { stroke: #ccc; stroke-width: 1px; stroke-dasharray: 2,2; stroke-opacity: 1; }`}
`;

export const System: React.FC<SystemProps> = React.memo(({ state, meta, alarm, children, selected, hideName = false, titleX, titleY }) => {
  const title = state.systemInfo.title || meta.name;
  const systemId = state.hasId;
  const dispatchEvent = useDispatchInputEvent();
  const mode = useMode();
  const formValid = formService.formValid(meta.form, state.systemInfo);
  const showName = !hideName;

  // Internal state.
  const showAlarm: boolean = alarm?.type === 'Alarm';
  const showWarning: boolean = alarm?.type === 'Warning';
  const showPasteurization: boolean = !!state.pasteurizationState;
  const showWarmWeatherShutdown: boolean = !!state.warmWeatherShutdown;
  const showManualMode: boolean = state.systemControlMode === SystemControlMode.Manual;
  const showInvalidSchematics = mode === SchematicMode.Edit && !formValid && !(showAlarm || showWarning);
  const showIcons = showAlarm || showWarning || showPasteurization || showManualMode || showWarmWeatherShutdown || showInvalidSchematics;

  // We need element references for dynamically dynamically position elements such as icons and header text.
  const applicationElement = useRef<SVGGElement>(null);
  const systemRect = useRef<SVGRectElement>(null);
  const contentElement = useRef<SVGGElement>(null);
  const headerTextElement = useRef<SVGTextElement>(null);
  const errorStateIconElement = useRef<SVGImageElement>(null);
  const arrowUpElement = useRef<SVGImageElement>(null);

  const onMouseDown = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (applicationElement && applicationElement.current) {
        const relativeCoords = getRelativeCoordinates(e, applicationElement.current);
        const bbox = applicationElement.current?.getBBox() || { x: 0, y: 0 };
        const x = relativeCoords.x + bbox?.x;
        const y = relativeCoords.y + bbox?.y;
        const inputEvent: SystemMouseDownEvent = {
          type: InputEventType.SystemMouseDown,
          systemId: state.hasId,
          offsetX: x,
          offsetY: y,
        };
        dispatchEvent(inputEvent);
      }
    },
    [dispatchEvent, state.hasId]
  );

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      const inputEvent: SystemClickEvent = {
        type: InputEventType.SystemClick,
        systemId: state.hasId,
      };
      dispatchEvent(inputEvent);
    },
    [dispatchEvent, state.hasId]
  );

  // Primary severity state.
  const getErrorState = (): ErrorState => {
    if (showAlarm) {
      return ErrorState.Alarm;
    }
    if (showWarning) {
      return ErrorState.Warning;
    }
    return ErrorState.Unknown;
  };

  const getTitleCoordinates = (): { x: number; y: number } => {
    return {
      x: titleX ? titleX : meta.width / 2,
      y: titleY ? titleY : HEADER_TOP,
    };
  };

  // Get primary severity state and coorsponding icon.
  const errorState = getErrorState();

  // Position header text based on container and header width.
  const titleWidth = headerTextElement.current ? headerTextElement.current.getBBox().width : 0;
  const containerWidth = Math.max(meta.width, titleWidth);
  const containerOffset = (containerWidth - meta.width) / 2;
  const titlePosition: { x: number; y: number } = getTitleCoordinates();

  // Prepare icons for position calculations.
  const iconWidth = 24;
  const iconPadding = 4;
  const iconHandler = new IconPositionHandler(
    [
      { key: 'alarm', visible: showAlarm || showWarning },
      { key: 'invalid', visible: showInvalidSchematics },
      { key: 'manualMode', visible: showManualMode },
      { key: 'pasteurization', visible: showPasteurization },
      { key: 'warmWeatherShutdown', visible: showWarmWeatherShutdown },
    ],
    iconWidth,
    iconPadding
  );

  // Calculate size and position of icon background.
  const iconBackgroundPadding = 2;
  const iconBackgroundWidth = iconHandler.getTotalWidth() + iconBackgroundPadding * 2;
  const iconBackgroundOffset = meta.width / 2 - iconBackgroundWidth / 2;

  // Calculate icon offsets.
  const iconAlarmPositionX = meta.width / 2 + (iconHandler.getIcon('alarm')?.offset || 0);
  const iconInvalidPositionX = meta.width / 2 + (iconHandler.getIcon('invalid')?.offset || 0);
  const iconManualModePositionX = meta.width / 2 + (iconHandler.getIcon('manualMode')?.offset || 0);
  const iconPasteurizationPositionX = meta.width / 2 + (iconHandler.getIcon('pasteurization')?.offset || 0);
  const iconWarmWeatherShutdownX = meta.width / 2 + (iconHandler.getIcon('warmWeatherShutdown')?.offset || 0);

  return (
    <StyledSystem
      ref={applicationElement}
      data-systemname={title}
      data-systemid={systemId}
      transform={`translate(${state.left},${state.top})`}
      onClick={onClick}
      onMouseDown={onMouseDown}
      $hoverEffect={ mode !== SchematicMode.Present }
      $alarm={showAlarm}
      $warning={showWarning}
      $pasteurization={showPasteurization}
      $warmWeatherShutdown={showWarmWeatherShutdown}
      $manualMode={showManualMode}
      $selected={selected}
      $editMode={mode === SchematicMode.Edit}
      $invalidForm={!formValid}>

      <rect
        className="gbc-system-rect"
        ref={systemRect}
        width={meta.width}
        height={meta.height - HEADER_AREA_HEIGHT}
        x={0}
        y={HEADER_AREA_HEIGHT}
        fillOpacity="0"
        data-test-id="gbc-schematics-system-border"
      />

      { showName &&
        <text
          ref={headerTextElement}
          x={titlePosition.x}
          y={titlePosition.y}
          textAnchor="middle"
          style={{ fontFamily: 'GrundfosTheSansV2', fontSize: '16px', fontWeight: 300, fill: colors.textColor }}>
          {title}
        </text>
      }
      { hideName && <text ref={headerTextElement}></text>}

      { showIcons && <rect key="iconBackground" y={28} x={iconBackgroundOffset} width={iconBackgroundWidth} height={24} fill="white" />}
      { errorState === ErrorState.Alarm &&
        <image key="errorStateAlarm" y={30} x={iconAlarmPositionX + 2} width="20px" href={alarmIconFilledSvg} data-test-id="gbc-schematics-primary-status-icon" />
      }
      { errorState === ErrorState.Warning &&
        <image key="errorStateWarning" y={28} x={iconAlarmPositionX} width="22px" href={warningTriangleFilledSvg} data-test-id="gbc-schematics-primary-status-icon" />
      }
      { showInvalidSchematics &&
        <image key="invalidFormIcon" y={28} x={iconInvalidPositionX} width="22px" href={alarmTriangleOutlineSvg} data-test-id="gbc-schematics-invalid-form-icon" />
      }
      { showManualMode &&
        <image key="manualModeIcon" y={28} x={iconManualModePositionX} width="24px" href={controlModeManualSvg} data-test-id="gbc-schematics-manual-mode-icon" />
      }
      { showPasteurization &&
        <image key="pasteurizationIcon" x={iconPasteurizationPositionX + 2} y={30} width="20px" href={pasteurizationSvg} />
      }
      { showWarmWeatherShutdown &&
        <image key="warmWeatherShutdownIcon" x={iconWarmWeatherShutdownX + 2} y={30} width="20px" href={warmWeatherShutdownSvg} data-test-id="gbc-schematics-warm-weather-icon" />
      }
      { !showIcons && showName &&
        <image className="gbc-arrow-up" key="arrowUp" ref={arrowUpElement} y={30} x={titlePosition.x - 16 / 2} width="16px" href={arrowUpSvg} opacity={0} />
      }

      <g ref={contentElement}>{children}</g>

      { DEBUG &&
        <rect
          className="gbc-hover-box"
          width={containerWidth + CONTAINER_PADDING * 2}
          height={meta.height + CONTAINER_PADDING * 2}
          x={-CONTAINER_PADDING - containerOffset}
          y={-CONTAINER_PADDING}
          fillOpacity="0"
        />
      }
      { DEBUG &&
        <circle className="gbc-origin" cx={0} cy={0} r={5} fill="#ccc" fillOpacity="1" />
      }
    </StyledSystem>
  );
});
