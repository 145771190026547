import styled from 'styled-components';
import { getAbsoluteCoordinates } from './Common';
import { getPipeConnectionType, Pipe } from './Pipe';
import { EConnectionType, SchematicPosition } from '../state';
import React, { KeyboardEventHandler, MouseEventHandler } from 'react';
import {
  ConnectionBackspaceEvent,
  ConnectionDoubleClickEvent,
  InputEventType,
  useDispatchInputEvent,
} from '../input-strategies/input-strategy-service';

export interface ConnectionProps {
  id: string;
  from: SchematicPosition;
  to: SchematicPosition;
  type: EConnectionType;
  dotted: boolean;
  className?: string;
}

export const Connection: React.FC<ConnectionProps> = styled(({ id, from, to, type, dotted, className }: ConnectionProps) => {
  const dispatchInput = useDispatchInputEvent();
  const onKeyDown: KeyboardEventHandler = (event) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      const inputEvent: ConnectionBackspaceEvent = {
        type: InputEventType.ConnectionBackspace,
        targetId: id,
      };
      dispatchInput(inputEvent);
    }
  };
  const onDoubleClick: MouseEventHandler = (e) => {
    e.stopPropagation();
    const { x, y } = getAbsoluteCoordinates(e);
    const inputEvent: ConnectionDoubleClickEvent = {
      type: InputEventType.ConnectionDoubleClick,
      targetId: id,
      x,
      y,
    };
    dispatchInput(inputEvent);
  };

  return (
    // TabIndex needs to be set in order for the outline to show up properly
    <g className={className} tabIndex={0} onKeyDown={onKeyDown} onDoubleClick={onDoubleClick}>
      {/* The purpose of the transparent line is to extend the hit zone for clicks */}
      <line key="transparent" x1={from.x} y1={from.y} x2={to.x} y2={to.y} stroke="transparent" strokeWidth="10px" />
      <Pipe key="actual" x1={from.x} y1={from.y} x2={to.x} y2={to.y} type={getPipeConnectionType(type, dotted)} />
    </g>
  );
})`
  g:focus {
    outline: solid blue 1px !important;
  }
`;
