import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Options, SeriesOptionsType } from 'highcharts';
import { EEquipment } from 'projects/dumbledore/src/schematic/types';
import { combineLatest, Observable } from 'rxjs';
import { filter, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { Application } from '../../../interfaces/alarm';
import { DataPointsResult, DeviceDataPoint } from '../../../interfaces/data-points';
import { DataPointsService, ReportPeriod, TimeSerie, TimeSeriesDetails } from '../../../services/data-points.service';
import { UserTrackingPeriodOptions } from '../../../services/user-tracking-helper.service';
import { convertWtoKW, getDataPoint } from '../../../utils/data-point-utils';
import { ChartInterface } from '../chart.interface';

@Component({
  selector: 'app-flow-heat-meter-chart-tile[installationId][applicationId]',
  templateUrl: './flow-heat-meter-chart-tile.component.html',
  styleUrls: ['./flow-heat-meter-chart-tile.component.scss'],
})
export class FlowHeatMeterChartTileComponent implements OnInit, ChartInterface {
  @Input() dataPoints$: Observable<DataPointsResult>;
  @Input() applicationId: string;
  @Input() installationId: string;
  @Input() userTrackingPeriodOptions: UserTrackingPeriodOptions;

  public getSeries: (period: ReportPeriod) => Observable<SeriesOptionsType[]>;
  public visible$: Observable<boolean>;
  public legends$ = combineLatest([
    this.translateService.get('flow-heat-meter-chart-tile.power'),
    this.translateService.get('flow-heat-meter-chart-tile.flow-meter-flow'),
  ]);
  public dataPointsForChart$: Observable<(DeviceDataPoint | null)[]>;
  public options: Options = {
    yAxis: [
      {
        title: {
          text: null,
        },
        labels: {
          format: '{value} kW',
        },
        min: 0,
      },
      {
        title: {
          text: null,
        },
        labels: {
          format: '{value} m3/h',
        },
        min: 0,
        softMax: 1.0,
        opposite: true,
      },
    ],
    plotOptions: {
      series: {
        tooltip: {
          valueDecimals: 1,
        },
      },
    },
  };

  constructor(private translateService: TranslateService, private dataPointsService: DataPointsService) {}

  ngOnInit() {
    this.dataPointsForChart$ = this.dataPoints$.pipe(
      filter((dataPoints) => !!dataPoints?.data?.length),
      map((dataPoints) => {
        return [
          getDataPoint({
            applicationId: this.applicationId,
            systems: dataPoints?.data || [],
            dumbledoreId: EEquipment.POW,
            humanReadableId: '',
          }),
          getDataPoint({
            applicationId: this.applicationId,
            systems: dataPoints?.data || [],
            dumbledoreId: EEquipment.Q_PF,
            humanReadableId: '',
          }) ||
            getDataPoint({
              applicationId: this.applicationId,
              systems: dataPoints?.data || [],
              dumbledoreId: EEquipment.Q_PR,
              humanReadableId: '',
            }),
        ];
      }),
      first()
    );
    this.visible$ = this.dataPointsForChart$.pipe(map((dataPoints) => dataPoints.some((dataPoint) => dataPoint)));

    this.getSeries = (period: ReportPeriod) => {
      return combineLatest([this.legends$, this.dataPointsForChart$]).pipe(
        switchMap(([legends, dataPoints]) => {
          const timeSeriesDetails: TimeSeriesDetails = {
            dataPoints,
            period,
            installationId: this.installationId,
            systemId: this.applicationId,
            requestId: 'flow_and_heat_meter',
          };
          return this.dataPointsService
            .getTimeSeries(timeSeriesDetails)
            .pipe(
              map((seriesData: TimeSerie[]) => [
                { name: legends[0], data: convertWtoKW(seriesData[0]), type: 'line' } as SeriesOptionsType,
                { name: legends[1], data: seriesData[1], type: 'line', yAxis: 1 } as SeriesOptionsType,
              ])
            );
        }),
        shareReplay(1)
      );
    };
  }
}
