import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { AlarmOverview, Application } from '../../../interfaces/alarm';
import { Installation } from '../../../interfaces/facilty';
import { AppModel, EEquipment, IndirectDistrictHeatingSupplySystemState } from '@ams/dumbledore';
import { DataPointsResult, SystemDevice } from '../../../interfaces/data-points';
import { PumpOption } from '../../../services/installation-configuration.service';
import { getPumpByDataPoint, getPumpOptions, getPumpType, systemsToValveDevice } from '../../../utils/data-point-utils';
import { switchMap, map } from 'rxjs/operators';
import { SchematicsService } from '../../../services/schematics.service';
import { systemHasEnergyValve } from 'projects/dumbledore/src/schematic/util/system-properties-util';

interface PumpInfo {
  pumpName: EEquipment;
  pumpType: string;
  slavePumpName: EEquipment;
}

@Component({
  selector: 'app-indirect-district-heating-supply-dashboard',
  templateUrl: './indirect-district-heating-supply-dashboard.component.html',
  styleUrls: ['./indirect-district-heating-supply-dashboard.component.scss'],
})
export class IndirectDistrictHeatingSupplyDashboardComponent implements OnInit {
  @Input() public alarm$: Observable<AlarmOverview>;
  @Input() application: Application;
  @Input() public installation$: Observable<Installation>;
  @Input() public schematic$: Observable<AppModel>;
  @Input() public dataPoints$: Observable<DataPointsResult>;

  public EEquipment = EEquipment;
  public primaryPumpType$: Observable<string>;
  public primaryPumpDevice$: Observable<SystemDevice | undefined>;
  public primaryPumpOptions$: Observable<PumpOption[] | undefined>;
  public slavePumpType$: Observable<string>;
  public slavePumpDevice$: Observable<SystemDevice | undefined>;
  public slavePumpOptions$: Observable<PumpOption[] | undefined>;
  public valveDevice$: Observable<SystemDevice | undefined>;
  public hasEnergyValve$: Observable<boolean | undefined>;
  public hasPrimaryTemperatureSensors$: Observable<boolean | undefined>;
  private system$: Observable<IndirectDistrictHeatingSupplySystemState>;

  constructor(private schematicsService: SchematicsService) {}

  ngOnInit(): void {
    this.system$ = this.schematic$.pipe(
      map((schematic) => {
        return schematic.systems.find((s) => s.hasId === this.application.id) as IndirectDistrictHeatingSupplySystemState;
      })
    );

    const pumpInfos$: Observable<PumpInfo> = this.system$.pipe(
      map((system) => {
        if (system.systemInfo.P_location === 'SecondaryForward') {
          return {
            pumpName: EEquipment.P_SF,
            pumpType: 'P_SF_type',
            slavePumpName: EEquipment.P_SF_SLAVE,
          };
        } else {
          return {
            pumpName: EEquipment.P_SR,
            pumpType: 'P_SR_type',
            slavePumpName: EEquipment.P_SR_SLAVE,
          };
        }
      })
    );

    const systemData$ = combineLatest([this.dataPoints$, this.system$]).pipe(
      map(([dataPoints, system]) => {
        return dataPoints.data.find((d) => d.systemId === system.hasId);
      })
    );

    this.primaryPumpDevice$ = combineLatest([systemData$, pumpInfos$]).pipe(
      map(([system, pumpInfo]) => getPumpByDataPoint(system, pumpInfo.pumpName))
    );
    this.primaryPumpType$ = pumpInfos$.pipe(switchMap((pumpInfo) => getPumpType(this.schematic$, this.application.id, pumpInfo.pumpType)));
    this.primaryPumpOptions$ = getPumpOptions(this.primaryPumpDevice$, this.schematicsService, this.application.id);

    this.slavePumpDevice$ = combineLatest([systemData$, pumpInfos$]).pipe(
      map(([system, pumpInfo]) => getPumpByDataPoint(system, pumpInfo.slavePumpName))
    );
    this.slavePumpType$ = pumpInfos$.pipe(switchMap((pumpInfo) => getPumpType(this.schematic$, this.application.id, pumpInfo.pumpType)));
    this.slavePumpOptions$ = getPumpOptions(this.slavePumpDevice$, this.schematicsService, this.application.id);

    this.valveDevice$ = this.dataPoints$.pipe(systemsToValveDevice(this.application.id));

    this.hasEnergyValve$ = this.system$.pipe(
      map((system) => {
        return systemHasEnergyValve(system?.systemInfo);
      })
    );

    this.hasPrimaryTemperatureSensors$ = this.system$.pipe(
      map((system) => {
        // This system has separate graphs for heat meter temperatures and primary temperatures.
        return (system?.systemInfo.T_PF_present || system?.systemInfo.T_PR_present);
      })
    );
  }
}
