<article class="content-wrapper">
  <div class="illustration">
    <ng-content select="figure"></ng-content>
  </div>
  <div class="content">
    <p class="pre-header"><strong>{{preTitle}}</strong></p>
    <h2 class="font-bold">{{title}}</h2>
    <ng-content></ng-content>
  </div>
</article>
<ng-content select="footer"></ng-content>
