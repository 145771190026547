<div class="topbar">
  <div class="topbar-content">
    <div class="topbar-logo-title" (click)="headerClick.emit()">
      <img src="./assets/svgs/grundfos-logo.svg" alt="Grundfos logo" class="topbar-logo">
      <div class="separator"></div>
      <div class="topbar-title"> {{title}}</div>
      <div class="version" *ngIf="version">{{version}}</div>
    </div>

    <div class="topbar-right">
      <ng-content select="gbc-topbar-dropdown"></ng-content>
      <button class="current-language" data-test-id="support-button" (click)="openSupport()">
        {{'topbar.support' | translate}}
      </button>
      <button class="current-language" (click)="openLanguagePicker()">
        {{currentLanguage$ | async}}
      </button>
    </div>
  </div>
</div>
