import React from 'react';
import { Labels } from '../../Labels';
import { Direction } from '../../Common';
import { Pipe, PipeType } from '../../Pipe';
import { System, BaseSystemProps } from '../../System';
import { HeatMeterOrSensors } from '../../heat-meter-or-sensors/HeatMeterOrSensors';
import { heatmeterStandaloneMeta, getHeatmeterSystemLabels, HEAT_METER_X, RETURN_Y, SUPPLY_Y } from './heat-meter-standalone-metadata';
import { IHeatmeterStandaloneSystemFormInterface } from '../../../../models/systems/heatmeterStandaloneSystem/heatmeterStandaloneSystemFormInterface';

const meta = heatmeterStandaloneMeta;
let previousState;
let previousValues;
export const HeatMeterStandaloneSystem: React.FC<BaseSystemProps<IHeatmeterStandaloneSystemFormInterface>> = React.memo(
  ({ state, values, alarm, selected }) => {
    previousState = state;
    previousValues = values;
    const systemInfo = state.systemInfo;
    const showHeatMeter = !!systemInfo.HM_present;
    const showForwardHeatMeter = systemInfo.HM_location === 'PrimaryForward';
    const showReturnHeatMeter = systemInfo.HM_location === 'PrimaryReturn';
    const showForwardSensor = !!(systemInfo.HM_present || systemInfo.T_F_present);
    const showReturnSensor = !!(systemInfo.HM_present || systemInfo.T_R_present);
    return (
      <System state={state} meta={meta} alarm={alarm} selected={selected}>
        <Pipe key="supplyPipe" x1={0} y1={SUPPLY_Y} x2={meta.width} y2={SUPPLY_Y} type={PipeType.WarmSupply} />
        <Pipe key="returnPipe" x1={0} y1={RETURN_Y} x2={meta.width} y2={RETURN_Y} type={PipeType.ColdReturn} />
        <g transform={`translate(${HEAT_METER_X}, ${SUPPLY_Y})`}>
          <HeatMeterOrSensors
            direction={Direction.EAST}
            showHeatMeter={showHeatMeter}
            showForwardHeatMeter={showForwardHeatMeter}
            showReturnHeatMeter={showReturnHeatMeter}
            showForwardSensor={showForwardSensor}
            showReturnSensor={showReturnSensor}
          />
        </g>
        <Labels positions={getHeatmeterSystemLabels(systemInfo)} values={values} />
      </System>
    );
  }
);
