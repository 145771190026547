<gbc-modal (dismiss)="dismiss()" [title]="title" [attr.data-system-control-mode]="currentSystemControlMode" class="gbc-large gbc-with-dividers" data-test-id="pump-modal">
  <app-control-state-toggle
    *ngIf="!mixit && currentSystemControlMode !== SystemControlMode.NotSupported"
    [currentApplicationControlMode]="currentSystemControlMode"
    (selectedApplicationControlMode)="selectApplicationControlMode($event)"
    data-test-id="system-control-mode-toggle">
  </app-control-state-toggle>
  <gbc-modal-content>
    <p>
      <b>{{ 'pump-modal.control-modes' | translate }}</b>
    </p>
    <p>
      {{ 'pump-modal.control-mode-description' | translate }}
    </p>
    <p *ngIf="isUnsupportedControlMode">
      {{ 'pump-modal.unsupported-mode' | translate }}
    </p>
    <form [formGroup]="form">
      <div class="control-mode-container">
        <gbc-modal-section>
          <gbc-modal-section-column>
            <gbc-input-label>{{ 'pump-modal.control-mode' | translate }}</gbc-input-label>
          </gbc-modal-section-column>
          <gbc-modal-section-column>
            <gbc-dropdown
              [dropDownItems]="controlModes"
              class="modal-dropdown"
              formControlName="controlMode"
              placeholderTranslationString="app-select">
            </gbc-dropdown>
          </gbc-modal-section-column>
        </gbc-modal-section>

        <div *ngIf="isAutoAdapt$ | async" class="control-mode-descriptor">
          <div class="img-container">
            <img alt="" src="./assets/svgs/control-mode-autoadapt.svg" />
          </div>
          <p [innerHTML]="'pump-modal.auto-adapt-description' | translate" class="modal-text"></p>
        </div>

        <div *ngIf="isProportionalPressure$ | async" class="control-mode-descriptor">
          <div class="img-container">
            <img alt="" src="./assets/svgs/control-mode-proportional-pressure.svg" />
          </div>
          <p [innerHTML]="'pump-modal.proportional-pressure-description' | translate" class="modal-text"></p>
        </div>

        <div *ngIf="isConstantPressure$ | async" class="control-mode-descriptor">
          <div class="img-container">
            <img alt="" src="./assets/svgs/control-mode-constant-pressure.svg" />
          </div>
          <p [innerHTML]="'pump-modal.constant-pressure-description' | translate" class="modal-text"></p>
        </div>

        <div *ngIf="isConstantFrequency$ | async" class="control-mode-descriptor">
          <div class="img-container">
            <img alt="" src="./assets/svgs/control-mode-constant-speed.svg" />
          </div>
          <p [innerHTML]="'pump-modal.constant-speed-description' | translate" class="modal-text"></p>
        </div>

        <div *ngIf="isConstantFlow$ | async" class="control-mode-descriptor">
          <div class="img-container">
            <img alt="" src="./assets/svgs/control-mode-constant-flow.svg" />
          </div>
          <p [innerHTML]="'pump-modal.constant-flow-description' | translate" class="modal-text"></p>
        </div>

        <div *ngIf="isConstantTemperature$ | async" class="control-mode-descriptor">
          <div class="img-container">
            <img alt="" src="./assets/svgs/control-mode-constant-temperature.svg" />
          </div>
          <p [innerHTML]="'pump-modal.constant-temperature-description' | translate" class="modal-text"></p>
        </div>
      </div>

      <ng-container *ngIf="isConstantPressure$ | async" [formGroup]="form.controls.constantPressureForm">
        <gbc-modal-section>
          <gbc-modal-section-column>
            <gbc-input-label> {{ 'pump-modal.head' | translate }}</gbc-input-label>
          </gbc-modal-section-column>
          <gbc-modal-section-column>
            <gbc-input-field
              [errorDescriptionData]="constantPressureHeadMinMax"
              [errorDescriptionTranslationString]="'pump-modal.enter-value-between'"
              [max]="constantPressureHeadMinMax.max"
              [min]="constantPressureHeadMinMax.min"
              [showErrorTranslationString]="true"
              formControlName="head"
              inputType="number"
              unit="m">
            </gbc-input-field>
          </gbc-modal-section-column>
        </gbc-modal-section>
      </ng-container>

      <ng-container *ngIf="isConstantFrequency$ | async" [formGroup]="form.controls.constantFrequencyForm">
        <gbc-modal-section>
          <gbc-modal-section-column>
            <gbc-input-label> {{ 'pump-modal.speed' | translate }}</gbc-input-label>
          </gbc-modal-section-column>
          <gbc-modal-section-column>
            <gbc-input-field
              [errorDescriptionData]="constantFrequencySpeedMinMax"
              [errorDescriptionTranslationString]="'pump-modal.enter-value-between'"
              [max]="constantFrequencySpeedMinMax.max"
              [min]="constantFrequencySpeedMinMax.min"
              [showErrorTranslationString]="true"
              formControlName="speed"
              inputType="number"
              unit="%">
            </gbc-input-field>
          </gbc-modal-section-column>
        </gbc-modal-section>
      </ng-container>

      <ng-container *ngIf="isConstantFlow$ | async" [formGroup]="form.controls.constantFlowForm">
        <gbc-modal-section>
          <gbc-modal-section-column>
            <gbc-input-label> {{ 'pump-modal.flow' | translate }}</gbc-input-label>
          </gbc-modal-section-column>
          <gbc-modal-section-column>
            <gbc-input-field
              [errorDescriptionData]="constantFlowFlowMinMax"
              [errorDescriptionTranslationString]="'pump-modal.enter-value-between'"
              [max]="constantFlowFlowMinMax.max"
              [min]="constantFlowFlowMinMax.min"
              [showErrorTranslationString]="true"
              formControlName="flow"
              inputType="number"
              unit="m3/h">
            </gbc-input-field>
          </gbc-modal-section-column>
        </gbc-modal-section>
      </ng-container>

      <ng-container *ngIf="isProportionalPressure$ | async" [formGroup]="form.controls.proportionalPressureForm">
        <gbc-modal-section>
          <gbc-modal-section-column>
            <gbc-input-label> {{ 'pump-modal.head' | translate }}</gbc-input-label>
          </gbc-modal-section-column>
          <gbc-modal-section-column>
            <gbc-input-field
              [errorDescriptionData]="proportionalPressureHeadMinMax"
              [errorDescriptionTranslationString]="'pump-modal.enter-value-between'"
              [max]="proportionalPressureHeadMinMax.max"
              [min]="proportionalPressureHeadMinMax.min"
              [showErrorTranslationString]="true"
              formControlName="head"
              inputType="number"
              unit="m">
            </gbc-input-field>
          </gbc-modal-section-column>
        </gbc-modal-section>
        <gbc-modal-section *ngIf="mixit">
          <gbc-modal-section-column>
            <gbc-input-label> {{ 'pump-modal.flow' | translate }}</gbc-input-label>
          </gbc-modal-section-column>
          <gbc-modal-section-column>
            <gbc-input-field
              [errorDescriptionData]="proportionalPressureFlowMinMax"
              [errorDescriptionTranslationString]="'pump-modal.enter-value-between'"
              [max]="proportionalPressureFlowMinMax.max"
              [min]="proportionalPressureFlowMinMax.min"
              [showErrorTranslationString]="true"
              formControlName="flow"
              inputType="number"
              unit="m3/h">
            </gbc-input-field>
          </gbc-modal-section-column>
        </gbc-modal-section>
      </ng-container>

      <ng-container *ngIf="isConstantTemperature$ | async" [formGroup]="form.controls.constantTemperatureForm">
        <gbc-modal-section>
          <gbc-modal-section-column>
            <gbc-input-label> {{ 'pump-modal.temperature' | translate }}</gbc-input-label>
          </gbc-modal-section-column>
          <gbc-modal-section-column>
            <gbc-input-field
              [errorDescriptionData]="constantTemperatureMinMax"
              [errorDescriptionTranslationString]="'pump-modal.enter-value-between'"
              [max]="constantTemperatureMinMax.max"
              [min]="constantTemperatureMinMax.min"
              [showErrorTranslationString]="true"
              formControlName="temperature"
              inputType="number"
              unit="°C">
            </gbc-input-field>
          </gbc-modal-section-column>
        </gbc-modal-section>
      </ng-container>

      <ng-container *ngIf="!mixit" data-test-id="start-stop-pump-container">
        <gbc-modal-section class="start-stop-pump-container">
          <gbc-modal-section-column>
            <div>
              <gbc-input-label>{{ 'pump-modal.start-stop' | translate }}</gbc-input-label>
              <div *ngIf="!isPumpStopped" class="modal-text">{{ 'pump-modal.start-description' | translate }}</div>
              <div *ngIf="isPumpStopped" class="modal-text">{{ 'pump-modal.stopped-description' | translate }}</div>
            </div>
          </gbc-modal-section-column>
          <gbc-modal-section-column class="start-stop-buttons">
            <p-selectButton
              class="start-stop-pump-buttons"
              [options]="pumpStateOptions"
              formControlName="pumpMode"
              pTooltip="{{ 'pump-modal.start-stop-disabled-tooltip' | translate }}"
              tooltipPosition="top"
              [tooltipDisabled]="selectedSystemControlMode === SystemControlMode.Manual">
              <ng-template let-item>
                <span>{{ item.name }}<i *ngIf="item.value === form.controls.pumpMode.value" class="pi pi-check"></i></span>
              </ng-template>
            </p-selectButton>
          </gbc-modal-section-column>
        </gbc-modal-section>
      </ng-container>
    </form>
  </gbc-modal-content>

  <gbc-modal-buttons>
    <button gbc-modal-button (click)="close()" angulartics2On="click" angularticsAction="cancelClicked" angularticsCategory="adjustPumpStatusShown">
      {{ 'app-cancel' | translate }}
    </button>
    <button
      (click)="save()"
      [disabled]="(isValid$ | async) === false || (!mixit && selectedSystemControlMode === SystemControlMode.Unknown && currentSystemControlMode !== SystemControlMode.NotSupported)"
      angulartics2On="click"
      angularticsAction="saveClicked"
      angularticsCategory="adjustPumpStatusShown"
      class="primary"
      gbc-modal-button>
      {{ 'app-save' | translate }}
    </button>
  </gbc-modal-buttons>
</gbc-modal>
