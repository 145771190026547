import { Component, OnInit } from '@angular/core';
import { AppError, AppErrorService, ModalService, PageInfo } from 'shared';
import { combineLatest, Observable } from 'rxjs';
import { filter, flatMap, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { IBackendUser, UserType } from '../../interfaces/user';
import { PageInfoService } from '../../services/page-info.service';
import { Facility } from '../../interfaces/facilty';
import { UserService } from '../../services/user.service';
import { FacilityService } from '../../services/facility.service';
import { EAccessLevel } from 'projects/shared/src/lib/interfaces/access';

@Component({
  selector: 'app-view-user-page',
  templateUrl: './view-user-page.component.html',
  styleUrls: ['./view-user-page.component.scss'],
})
export class ViewUserPageComponent implements OnInit {
  public user$: Observable<IBackendUser>;
  public pageInfo$: Observable<PageInfo>;
  public pageError$: Observable<AppError>;

  public isFacilityManager$: Observable<boolean>;
  private allFacilities$: Observable<Facility[]>;
  public facilitiesForUser$: Observable<Facility[]>;
  public menuPresent$: Observable<boolean>;
  public translatedRole$: Observable<string>;

  constructor(
    private pageInfoService: PageInfoService,
    private userService: UserService,
    private route: ActivatedRoute,
    private facilityService: FacilityService,
    private translate: TranslateService,
    private router: Router,
    private errorService: AppErrorService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    const userId = this.route.snapshot.params.userId;

    this.user$ = this.userService.getUser(userId);

    this.translatedRole$ = this.user$.pipe(flatMap((user) => this.userService.translateRole(user.accessLevel)));

    // you cannot delete yourself
    this.menuPresent$ = combineLatest([this.user$, this.userService.initialUser$]).pipe(
      map(([user, self]) => self?.type === UserType.BuildingConnect && self.id !== user.id)
    );

    this.allFacilities$ = this.facilityService.facilities$.pipe(filter((f) => !!f)) as Observable<Facility[]>;

    this.pageError$ = this.errorService.createPageErrorObservable([this.user$]);

    this.pageInfo$ = this.user$.pipe(
      map((user) => {
        return this.pageInfoService.viewUser(user);
      })
    );
    this.isFacilityManager$ = this.user$.pipe(map((user) => user.accessLevel === EAccessLevel.GrundfosClaimAccessFacility));
    this.facilitiesForUser$ = combineLatest([this.user$, this.allFacilities$]).pipe(
      filter(([_, facilities]) => !!facilities),
      map(([user, facilities]) => {
        if (user.accessLevel === EAccessLevel.GrundfosClaimAccessCompany) {
          return facilities;
        }
        return facilities.filter((facility) => user.facilityIDs?.includes(facility.id));
      })
    );
  }

  public editUser(userId: string) {
    this.router.navigate(['/users', userId, 'edit']);
  }

  public deleteUser(userId: string) {
    this.modalService
      .showConfirmModal(
        this.translate.instant('view-user-page.confirm-delete-user-title'),
        this.translate.instant('view-user-page.confirm-delete-user-text')
      )
      .subscribe((confirmed) => {
        if (confirmed) {
          this.userService.deleteUser(userId).subscribe(() => {
            this.router.navigate(['/users']);
          });
        }
      });
  }
}
