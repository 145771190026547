import {Environment} from './type';

export const environment: Environment = {
  production: true,
  serverUrl: '',
  deploymentPath: '/portal',
  testMode: false,
  userTrackingDefaults: {
    data: {
      pageInfo: {
        pageName: '',                      // e.g. 'gbc:homepage',
        toolName: 'gbc',                   // e.g. 'gbc'
        rsid: '',                          // read from backend config. ('gfdigitalofferings' or 'gfdigitalofferingstest')
        platform: 'digital offering',      // hardcoded value,
        server: location.hostname ?? '',   // e.g. 'buildingconnect.grundfos.com',
        companyCode: 'GMA',                // hardcoded value,
        siteCountry: 'Global',             // hardcoded value,
        channelTitle: '',                  // e.g. 'Homepage',
        language: '',                      // e.g. 'en',
        hier1: '',                         // e.g. 'gbc/homepage/en',
      },
      user: {
        puid: '',                          // public user id
      },
    },
  },
};
