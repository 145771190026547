import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { isUserLoggedInToken, serverUrlToken } from '../injection-tokens';
import { tap, first, flatMap } from 'rxjs/operators';
import * as moment from 'moment';
import {momentLanguageMap} from '../../../../../../../translations/sources';
import { ConfigurationService } from 'shared';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  public languages$: Observable<string[]>;
  public currentLanguage: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private defaultLanguage = 'en';

  constructor(
    private translate: TranslateService,
    private httpClient: HttpClient,
    private configurationService: ConfigurationService,
    @Inject(serverUrlToken) private serverUrl: string,
    @Inject(isUserLoggedInToken) private isUserLoggedIn$: Observable<boolean>) {

      this.translate.setDefaultLang(this.defaultLanguage);
      this.setLanguageLocally(localStorage.getItem('locale') || this.defaultLanguage);

      this.configurationService.configuration.pipe(
        first(),
        tap(configuration => {
          this.languages$ = of(configuration.languages);
          this.languages$.pipe(first(), tap(languages => {
            this.translate.addLangs(languages);
          })).subscribe();
        })
      ).subscribe();
  }

  public async setupLanguages(userLanguage: string) {
    this.languages$.pipe(
      first(),
      tap(languages => {
        // Always use the language that the user has set on their profile first
        if (userLanguage && languages.includes(userLanguage)) {
          this.setLanguageLocally(userLanguage);
          return;
        }

        // Get the users browserlanguage and set if there is a match
        const userBrowserLanguage = this.translate.getBrowserCultureLang();
        if (userBrowserLanguage && languages.includes(userBrowserLanguage)) {
          this.setLanguageLocallyAndRemotely(userBrowserLanguage);
          return;
        }

        // The user has a language with a culture (en-GB)
        // but we don't have that in our supported languages
        // but we do have one without culture (en)
        if (userBrowserLanguage && userBrowserLanguage.includes('-')) {
          const withoutCulture = this.translate.getBrowserLang(); // this returns browserLang without culture
          for (const language of languages) {
            if (language === withoutCulture) {
              this.setLanguageLocallyAndRemotely(language);
              return;
            }
          }
        } else {
          // The user can have a language without a culture: ie. 'de' instead of 'de-DE'
          // We will attempt to find a language that matches and select the first one
          for (const language of languages) {
            const indexOfDash = language.indexOf('-');
            const languageWithoutCulture = language.slice(0, indexOfDash);
            if (languageWithoutCulture === userBrowserLanguage) {
              this.setLanguageLocallyAndRemotely(language);
              return;
            }
          }
        }

        this.setLanguageLocallyAndRemotely(this.defaultLanguage);
      })
    ).subscribe();
  }

  public setLanguageLocallyAndRemotely(language: string) {
    this.setLanguageLocally(language);
    this.setLanguageOnBackend(language);
    this.setMomentLocale(language);
  }

  public setLanguageLocally(language: string) {
    if (language === this.currentLanguage.value) return;

    this.translate.use(language);
    this.currentLanguage.next(language);
    this.setMomentLocale(language);
    localStorage.setItem('locale', language);
  }

  private setMomentLocale(locale: string) {
    moment.locale(momentLanguageMap[locale]);
  }

  private setLanguageOnBackend(language: string) {
    this.isUserLoggedIn$.pipe(
      first(),
      flatMap((isLoggedIn: boolean, index: number) => {
        if (isLoggedIn) {
          return this.httpClient.put(`${this.serverUrl}/api/users/language/${language}`, null);
        }
        return of();
      })
    ).subscribe();
  }
}
