export enum ECommissionStatus {
  Created = 'Created',
  DeviceIdRegistered = 'DeviceIdRegistered',
  DeviceRegisteredInIotHub = 'DeviceRegisteredInIotHub',
  ReadyForTest = 'ReadyForTest',
  BeingTested = 'BeingTested',
  TestSuccess = 'TestSuccess',
  Live = 'Live',
  Closed = 'Closed',
  Error = 'Error',
}
