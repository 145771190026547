import { Component, OnInit } from '@angular/core';
import { AppError, AppErrorService, ModalService, PageInfo } from 'shared';
import { BehaviorSubject, combineLatest, EMPTY, Observable, of } from 'rxjs';
import { PageInfoService } from '../../services/page-info.service';
import { Facility, Installation } from '../../interfaces/facilty';
import { FacilityService } from '../../services/facility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, filter, flatMap, map, shareReplay, take } from 'rxjs/operators';
import { UserService } from '../../services/user.service';
import { IBuildingConnectUser, UserType } from '../../interfaces/user';
import { LicenseType } from '../../interfaces/mixit';
import { filterFacilityInstallationsByLicense } from '../../utils/mixit-utils';
import { UpgradeLicenseService } from '../../services/upgrade-license.service';
import {
  CategoryType,
  EUpgradeResultErrorCodes,
  EUpgradeResultStatusCodes,
  IInstallationUpgradeRequest,
  ILicenseCategory,
  ILicenseChoice,
  ILicenseOption,
  ILicensePurchaseInformation
} from '../../interfaces/premium';
import {
  IInstallationUpgradeStatus,
  MixitUpgradeStatusModalComponent
} from '../../components/mixit-upgrade-status-modal/mixit-upgrade-status-modal.component';
import { Location } from '@angular/common';

import { HttpErrorResponse } from '@angular/common/http';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { EControllerType } from '../../../../../shared/src/lib/interfaces/controllerType';

interface ISummary {
  price: number;
  qty: number;
  selectedLicense: ILicenseChoice;
}

@Component({
  selector: 'app-subscribe-flow-page',
  templateUrl: './subscribe-flow-page.component.html',
  styleUrls: ['./subscribe-flow-page.component.scss'],
})
export class SubscribeFlowPageComponent implements OnInit {
  public currentUser$: Observable<IBuildingConnectUser | null>;
  public pageInfo: PageInfo;
  public facilities$: Observable<Facility[]>;
  public pageError$: Observable<AppError>;
  public deviceType = 'MIXIT';
  public canContinue$: Observable<boolean>;
  public requests: IInstallationUpgradeRequest[] = [];
  public selectedCategory: CategoryType = 'subscription';
  public oneTimeFeeLicenseCategory$: Observable<ILicenseCategory>;
  public subscriptionLicenseCategory$: Observable<ILicenseCategory>;
  public selectedInstallationsByFacility$: BehaviorSubject<Installation[]> = new BehaviorSubject<Installation[]>([]);
  public summary$: Observable<ISummary>;
  public selectedLicense$: BehaviorSubject<ILicenseChoice | null> = new BehaviorSubject<ILicenseChoice | null>(null);
  public selectedLicenseType$: BehaviorSubject<LicenseType | null> = new BehaviorSubject<LicenseType | null>(null);
  public formValidity$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public currentStep$: BehaviorSubject<number> = new BehaviorSubject(0);
  public licenseInfo$: BehaviorSubject<ILicensePurchaseInformation | null> = new BehaviorSubject<ILicensePurchaseInformation | null>(null);
  public preSelectedInstallation: string;

  constructor(
    private pageInfoService: PageInfoService,
    private errorService: AppErrorService,
    private facilityService: FacilityService,
    private userService: UserService,
    private upgradeService: UpgradeLicenseService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit(): void {
    this.pageError$ = this.errorService.createPageErrorObservable([this.userService.initialUser$, this.facilities$]);
    this.pageInfo = this.pageInfoService.subscriptionFlow();
    this.currentUser$ = this.userService.currentUser$.pipe(
      flatMap((user) => {
        // OIDC users do not have a profile page, so we filter them
        if (user?.type === UserType.OIDC) {
          return EMPTY;
        }
        return of(user);
      })
    );

    const priceReq$ = this.upgradeService.getLicenseInformation().pipe(shareReplay());

    this.oneTimeFeeLicenseCategory$ = priceReq$.pipe(
      map((data) => {
        const connect = data.find((option) => option.licenseType === LicenseType.CodeConnectUnlimited) as ILicenseOption;
        const dynamic = data.find((option) => option.licenseType === LicenseType.CodeDynamicUnlimited) as ILicenseOption;
        return {
          key: 'one-time-fee',
          licenses: [
            {
              translationKey: 'building-connect',
              licenseType: LicenseType.CodeConnectUnlimited,
              priceEuro: connect.priceEuro,
              bannedLicenseTypes: [
                LicenseType.CodeConnectUnlimited,
                LicenseType.CodeConnectUnlimitedPhysical,
                LicenseType.CodeConnectSubscription,
              ],
            },
            {
              translationKey: 'mixit-dynamic',
              licenseType: LicenseType.CodeDynamicUnlimited,
              priceEuro: dynamic.priceEuro,
              bannedLicenseTypes: [
                LicenseType.CodeDynamicUnlimited,
                LicenseType.CodeDynamicUnlimitedPhysical,
                LicenseType.CodeDynamicSubscription,
              ],
            },
          ],
        };
      })
    );

    this.subscriptionLicenseCategory$ = priceReq$.pipe(
      map((data) => {
        const connectPrice = data.find((option) => option.licenseType === LicenseType.CodeConnectSubscription);
        const dynamicPrice = data.find((option) => option.licenseType === LicenseType.CodeDynamicSubscription);
        return {
          key: 'subscription',
          licenses: [
            {
              translationKey: 'building-connect',
              licenseType: LicenseType.CodeConnectSubscription,
              priceEuro: connectPrice?.priceEuro,
              bannedLicenseTypes: [
                LicenseType.CodeConnectUnlimited,
                LicenseType.CodeConnectUnlimitedPhysical,
                LicenseType.CodeConnectSubscription,
              ],
            },
            {
              translationKey: 'mixit-dynamic',
              licenseType: LicenseType.CodeDynamicSubscription,
              priceEuro: dynamicPrice?.priceEuro,
              bannedLicenseTypes: [
                LicenseType.CodeDynamicUnlimited,
                LicenseType.CodeDynamicUnlimitedPhysical,
                LicenseType.CodeDynamicSubscription,
              ],
            },
          ],
        };
      })
    );

    this.canContinue$ = combineLatest([
      this.currentStep$,
      this.selectedLicense$,
      this.selectedLicenseType$,
      this.selectedInstallationsByFacility$,
      this.licenseInfo$,
      this.formValidity$,
    ]).pipe(
      map(([step, license, licenseType, selectedInstallationsByFacility, licenseInfo, formValidity]) => {
        switch (step) {
          case 0: // Select license type
            return !!licenseType && !!license;
          case 1: // Select installations
            return selectedInstallationsByFacility && !!selectedInstallationsByFacility.length;
          case 2: // Enter information
            return formValidity;
          case 3: // Summary
            return true;
          default:
            return false;
        }
      })
    );

    this.facilities$ = combineLatest([this.facilityService.facilities$, this.selectedLicense$]).pipe(
      filter(([f, l]) => !!f && !!l),
      map(([facilities, license]) => {
        const filteredFacilities = (facilities as Facility[])
          .map(f => {
            return {
              ...f,
              // We don't want to show GBC installations in the list
              installations: f.installations.filter(i => i.controllerType !== EControllerType.MOXA)
            };
          })
          .sort((a, b) => a.name.localeCompare(b.name));
        return filterFacilityInstallationsByLicense(filteredFacilities, (license as ILicenseChoice).bannedLicenseTypes);
      })
    );

    this.preSelectedInstallation = this.route.snapshot.queryParamMap.get('installationId') as string;
  }

  onSelectInstallations(facilities: Facility[]) {
    this.selectedInstallationsByFacility$.next(facilities.flatMap(facility => facility.installations.map(installation => ({ ...installation, location: facility.name }))));
  }

  onSelectLicense(license: ILicenseChoice) {
    this.selectedLicense$.next(license);
    this.selectedLicenseType$.next(license.licenseType);
  }

  onSelectCategory(categoryKey: CategoryType) {
    this.selectedCategory = categoryKey;
    this.selectedLicense$.next(null);
    this.selectedLicenseType$.next(null);
  }

  setLicenseInfo(event: ILicensePurchaseInformation) {
    this.licenseInfo$.next(event);
  }

  setFormValidity(event: boolean) {
    this.formValidity$.next(event);
  }

  stepperChange(step: StepperSelectionEvent): void {
    if (step.selectedIndex === 3) {
      this.summary$ = combineLatest([this.selectedLicense$, this.selectedInstallationsByFacility$]).pipe(
        take(1),
        map(([l, selectedInstallationsByFacility]) => {
          const license = l as ILicenseChoice;
          const qty = selectedInstallationsByFacility.length;
          const price = license?.priceEuro ? license.priceEuro * qty : 0;
          return {
            qty,
            price,
            selectedLicense: license,
          };
        })
      );
    }
    this.currentStep$.next(step.selectedIndex);
  }

  done(): void {
    // Todo: Refactor the this.requests list, so a request is added to the list upon installation selection
    // to allow us to catch potential errors before the user reaches "done".

    combineLatest([this.licenseInfo$, this.selectedLicenseType$, this.selectedInstallationsByFacility$])
      .subscribe(([lI, lT, installations]) => {
        const licenseInfo = lI as ILicensePurchaseInformation;
        const licenseType = lT as LicenseType;
        this.requests = installations
          .map((installation) => {
            return {
              request: this.upgradeService
                .upgradeLicense(installation.id, {
                  licenseType,
                  licensePurchaseInformation: licenseInfo,
                })
                .pipe(
                  map(() => {
                    return {
                      status: EUpgradeResultStatusCodes.SUCCESS,
                      message: installation.name,
                    };
                  }),
                  catchError((e: HttpErrorResponse) => {
                    return of({
                      status: EUpgradeResultStatusCodes.ERROR,
                      code: e.headers?.get('ErrorCode') as EUpgradeResultErrorCodes,
                      message: e.message,
                    });
                  })
                ),
              installationId: installation.id,
              installationName: installation?.name || '',
            };
          });
      });

    this.modalService
      .openDialog(MixitUpgradeStatusModalComponent, {
        data: { status: EUpgradeResultStatusCodes.PENDING, requests: this.requests },
      })
      .subscribe((result) => {
        if (!result.dismissed) {
          // @ts-ignore
          const upgradeResults = result.result as IInstallationUpgradeStatus[];
          if (
            this.preSelectedInstallation &&
            upgradeResults.length === 1 &&
            !upgradeResults.some((r) => r.status === EUpgradeResultStatusCodes.ERROR)
          ) {
            this.location.back();
          } else {
            this.router.navigate([`/account`, {tab: 'devices'}]);
          }
        }
      });
  }
}
