import { SystemType, DistrictHeatingSystemState, BoilerSystemState, PressureSensorSystemState } from '../state';
import { EEquipment, EValueType, IEquipmentValue, ISetPointValue, IValveOpenValue } from '../types';
import { SampleEquipmentValue } from './sample-equipment-values';
import { v4 } from 'uuid';

export const SAMPLE_PRESSURE_SENSOR_SYSTEM: PressureSensorSystemState = {
  hasId: v4(),
  left: 220,
  top: 1580,
  configured: false,
  type: SystemType.PressureSensorSystem,
  systemInfo: {
    title: '',
    Location: 'Forward',
    hasDirtCollector: true,
  }
};

export const SAMPLE_VALUES_PRESSURE_SENSOR: SampleEquipmentValue[] = [
  {
    systemType: SystemType.PressureSensorSystem,
    type: EValueType.value,
    equipment: EEquipment.H_DIFF_P,
    value: '2.1 Bar',
  },
  {
    systemType: SystemType.PressureSensorSystem,
    type: EValueType.value,
    equipment: EEquipment.H_F,
    value: '2.2 Bar',
  },
  {
    systemType: SystemType.PressureSensorSystem,
    type: EValueType.value,
    equipment: EEquipment.H_R,
    value: '2.3 Bar',
  }
];
