<gbc-page-header *ngIf="pageInfo$ | async as pageInfo" [pageInfo]="pageInfo"></gbc-page-header>
<gbc-tabs>
  <gbc-tab name="overview" [title]="'system-status-view.page-tab-overview' | translate" class="app-tab-list">
    <gbc-page-container>
      <app-tab-tile [title]="'system-status-view.alarms-title' | translate">
        <app-tab [title]="'system-status-view.active-tab' | translate" (tabSelected)="tabSelected($event, 'active')">
          <app-alarm-table
            (alarmClick)="alarmClicked($event, 'active')"
            [clickable]="true"
            [filter]="notClearedFilter"
            [noAlarmsMessage]="'system-status-view.no-active-alarms-message' | translate"
            minHeight="180px">
          </app-alarm-table>
        </app-tab>
        <app-tab [title]="'system-status-view.cleared-tab' | translate" (tabSelected)="tabSelected($event, 'cleared')">
          <app-alarm-table
            (alarmClick)="alarmClicked($event, 'cleared')"
            [clickable]="true"
            [filter]="clearedFilter"
            [noAlarmsMessage]="'system-status-view.no-cleared-alarms-message' | translate"
            minHeight="180px">
          </app-alarm-table>
        </app-tab>
      </app-tab-tile>
    </gbc-page-container>
  </gbc-tab>
  <gbc-tab name="settings" [title]="'system-status-view.page-tab-settings' | translate" class="app-tab-list">
    <app-alarm-settings-view></app-alarm-settings-view>
  </gbc-tab>
</gbc-tabs>
