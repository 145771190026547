import { Component, OnInit, Input } from '@angular/core';
import { AlarmOverview, Application } from '../../../interfaces/alarm';
import { Observable, combineLatest, of } from 'rxjs';
import { Installation } from '../../../interfaces/facilty';
import { AppModel, EEquipment, PumpStandaloneSystemState } from '@ams/dumbledore';
import { DataPointsResult, SystemDevice } from '../../../interfaces/data-points';
import { PumpOption } from '../../../services/installation-configuration.service';
import { Serie, SerieType } from '../../charts/temperature-chart-tile/temperature-chart-tile.component';
import { SchematicsService } from '../../../services/schematics.service';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { getPumpOptions, getPumpType, systemsToPumpDevice } from '../../../utils/data-point-utils';
import { IPumpStandaloneSystemFormInterface } from 'projects/dumbledore/src/models/systems/pumpStandaloneSystem/pumpStandaloneSystemFormInterface';

@Component({
  selector: 'app-pump-standalone-dashboard',
  templateUrl: './pump-standalone-dashboard.component.html',
  styleUrls: ['./pump-standalone-dashboard.component.scss'],
})
export class PumpStandaloneDashboardComponent implements OnInit {
  @Input() public alarm$: Observable<AlarmOverview>;
  @Input() public application: Application;
  @Input() public installation$: Observable<Installation>;
  @Input() public schematic$: Observable<AppModel>;
  @Input() public dataPoints$: Observable<DataPointsResult>;

  public pumpType$: Observable<string>;
  public pumpDevice$: Observable<SystemDevice | undefined>;
  public pumpOptions$: Observable<PumpOption[] | undefined>;
  public temperatureSeries$: Observable<Serie[]>;
  public temperatureColors: string[] = ['#DD0028', '#0068B4', '#F5B2BF', '#B2D2E8'];

  private system$: Observable<PumpStandaloneSystemState>;

  constructor(private schematicService: SchematicsService, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.system$ = (this.schematicService.schematic$ as Observable<AppModel>).pipe(
      map((schematic) => {
        return schematic.systems.find((s) => s.hasId === this.application.id) as PumpStandaloneSystemState;
      })
    );

    this.pumpDevice$ = this.dataPoints$.pipe(systemsToPumpDevice(this.application.id));

    this.pumpType$ = getPumpType(this.schematic$, this.application.id);

    this.pumpOptions$ = this.pumpOptions$ = getPumpOptions(this.pumpDevice$, this.schematicService, this.application.id);

    const showTF = (systemInfo: IPumpStandaloneSystemFormInterface) => {
      return systemInfo.PumpTemperatureSensor && systemInfo.P_location === 'Return';
    };

    const showTR = (systemInfo: IPumpStandaloneSystemFormInterface) => {
      return systemInfo.PumpTemperatureSensor && systemInfo.P_location === 'Forward';
    };

    const forwardLegend$ = this.translateService.get('pump-standalone-dashboard.forward');
    const returnLegend$ = this.translateService.get('pump-standalone-dashboard.return');
    this.temperatureSeries$ = combineLatest([this.system$, forwardLegend$, returnLegend$]).pipe(
      map(([system, forwardLegend, returnLegend]) => {
        if (!system?.systemInfo) {
          return [];
        }

        const series: Serie[] = [];

        if (showTF(system.systemInfo)) {
          series.push({
            type: SerieType.DataPoint,
            title: forwardLegend,
            dumbledoreId: EEquipment.T_F,
          });
        }

        if (showTR(system.systemInfo)) {
          series.push({
            type: SerieType.DataPoint,
            title: returnLegend,
            dumbledoreId: EEquipment.T_R,
          });
        }

        return series;
      })
    );
  }
}
