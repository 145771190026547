import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController, MODAL_CONTROLLER, MODAL_DATA } from 'shared';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { toFixed } from '../../../utils/data-point-utils';
import { UserTrackingHelperService } from '../../../services/user-tracking-helper.service';

export interface MixitDeviceSettingsResponse {
  kvsEnabled: boolean;
  kvs: number;
  manualOpeningEnabled: boolean;
  valveOpenness: number;
}

export interface MixitDeviceSettingsInput {
  hideKvs: boolean;
  kvsEnabled: boolean;
  kvs: number | null;
  manualOpeningEnabled: boolean;
  kvsMin: number;
  kvsMax: number;
  valveMin: number;
  valveMax: number;
  valveOpenness: number | null;
  title: string;
}

@Component({
  selector: 'app-mixit-device-modal',
  templateUrl: './mixit-device-modal.component.html',
  styleUrls: ['./mixit-device-modal.component.scss'],
})
export class MixitDeviceModalComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  public valid$: Observable<boolean>;

  public title: string;
  public manualOpeningEnabled: boolean;
  public ecoScheduling$: Observable<boolean>;
  public form: UntypedFormGroup;

  public kvsErrorData: { [k: string]: string };
  public valveErrorData: { [k: string]: string };

  constructor(
    @Inject(MODAL_DATA) public data: MixitDeviceSettingsInput,
    @Inject(MODAL_CONTROLLER) private controller: ModalController<MixitDeviceSettingsResponse>,
    private userTrackingHelperService: UserTrackingHelperService
  ) {}

  ngOnInit(): void {
    this.title = this.data.title;
    this.ecoScheduling$ = of(false);

    this.kvsErrorData = {
      min: toFixed(this.data.kvsMin),
      max: toFixed(this.data.kvsMax),
    };

    this.valveErrorData = {
      min: toFixed(this.data.valveMin),
      max: toFixed(this.data.valveMax),
    };

    this.form = new UntypedFormGroup({
      kvsEnabled: new UntypedFormControl(''),
      kvs: new UntypedFormControl('', [Validators.required, Validators.min(this.data.kvsMin), Validators.max(this.data.kvsMax)]),
      manualOpeningEnabled: new UntypedFormControl(''),
      manualOpening: new UntypedFormControl('', [Validators.required, Validators.min(this.data.valveMin), Validators.max(this.data.valveMax)]),
    });

    this.valid$ = this.form.valueChanges.pipe(
      map((form) => {
        let [manualOpeningValid, kvsValid] = [true, true];
        if (form.manualOpeningEnabled) {
          manualOpeningValid = this.form.controls.manualOpening.valid;
        }
        if (form.kvsEnabled) {
          kvsValid = this.form.controls.kvs.valid;
        }
        return manualOpeningValid && kvsValid;
      })
    );

    this.subscription.add(
      combineLatest([this.form.controls.manualOpeningEnabled.valueChanges, this.form.controls.kvsEnabled.valueChanges])
        .pipe(
          tap(([manualOpeningEnabled, kvsEnabled]: [boolean, boolean]) => {
            this.toggleEnabled(manualOpeningEnabled, kvsEnabled);
          })
        )
        .subscribe()
    );

    this.form.setValue({
      kvsEnabled: this.data.kvsEnabled,
      kvs: this.data.kvs,
      manualOpeningEnabled: this.data.manualOpeningEnabled,
      manualOpening: this.data.valveOpenness,
    });

    this.subscription.add(this.userTrackingHelperService.startFormValueChangeTracking('adjustMixitConfigurationsShown', this.form));

    this.toggleEnabled(this.data.manualOpeningEnabled, this.data.kvsEnabled);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  toggleEnabled(manualOpeningEnabled: boolean, kvsEnabled: boolean) {
    if (manualOpeningEnabled) {
      this.form.controls.manualOpening.enable({ emitEvent: false });
    } else {
      this.form.controls.manualOpening.disable({ emitEvent: false });
    }

    if (kvsEnabled) {
      this.form.controls.kvs.enable({ emitEvent: false });
    } else {
      this.form.controls.kvs.disable({ emitEvent: false });
    }

    // Force validation since we used emitEvent=false above, which means validation is not automatically triggered
    this.form.controls.manualOpening.updateValueAndValidity();
    this.form.controls.kvs.updateValueAndValidity();
  }

  close() {
    this.controller.dismiss();
  }

  save() {
    this.controller.complete({
      kvs: this.form.controls.kvs.value,
      kvsEnabled: this.form.controls.kvsEnabled.value,
      manualOpeningEnabled: this.form.controls.manualOpeningEnabled.value,
      valveOpenness: this.form.controls.manualOpening.value,
    });
  }

  dismiss() {
    this.userTrackingHelperService.trackUserAction('adjustMixitConfigurationsShown', 'exitClicked');
  }
}
