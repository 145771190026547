<gbc-modal
  class="gbc-large gbc-with-dividers"
  (dismiss)="dismiss()"
  [title]="'mixit-balancing-limiters-modal.balancing-limiters' | translate"
  data-test-id="mixit-balancing-limiters-modal">
  <gbc-modal-content>
    <p class="modal-text">
      {{ 'mixit-balancing-limiters-modal.limiters-description' | translate }}
    </p>
    <form [formGroup]="form">
      <gbc-modal-section>
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'mixit-balancing-limiters-modal.supply-flow' | translate }}</gbc-input-label>
          <img pTooltip="{{ 'mixit-balancing-limiters-modal.supply-flow-tooltip' | translate }}" tooltipPosition="right" escape class="question-mark" src="./assets/svgs/question-mark.svg" />
          <gbc-toggle class="align-right" formControlName='supplyFlowLimiterEnabled'></gbc-toggle>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-field
            [errorDescriptionData]="supplyFlowMinMax"
            [max]="supplyFlowMinMax.max"
            [min]="supplyFlowMinMax.min"
            [showErrorTranslationString]="true"
            errorDescriptionTranslationString="app-enter-value-between"
            formControlName="supplyFlow"
            inputType="number"
            unit="m3/h">
          </gbc-input-field>
        </gbc-modal-section-column>
      </gbc-modal-section>

      <gbc-modal-section>
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'mixit-balancing-limiters-modal.thermal-power' | translate }}</gbc-input-label>
          <img class="question-mark" src="./assets/svgs/question-mark.svg" pTooltip="{{ 'mixit-balancing-limiters-modal.thermal-power-tooltip' | translate }}" tooltipPosition="right" escape />
          <gbc-toggle class="align-right" formControlName='thermalPowerLimiterEnabled'></gbc-toggle>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-field
            [errorDescriptionData]="thermalPowerMinMax"
            [max]="thermalPowerMinMax.max"
            [min]="thermalPowerMinMax.min"
            [showErrorTranslationString]="true"
            errorDescriptionTranslationString="app-enter-value-between"
            formControlName="thermalPower"
            inputType="number"
            unit="kW">
          </gbc-input-field>
        </gbc-modal-section-column>
      </gbc-modal-section>

      <gbc-modal-section>
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'mixit-balancing-limiters-modal.differentiated-pressure' | translate }}</gbc-input-label>
          <img class="question-mark" src="./assets/svgs/question-mark.svg" pTooltip="{{ 'mixit-balancing-limiters-modal.differentiated-pressure-tooltip' | translate }}" tooltipPosition="right" escape />
          <gbc-toggle class="align-right" formControlName="differentiatedPressureLimiterEnabled"></gbc-toggle>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-field
            [errorDescriptionData]="differentiatedPressureMinMax"
            [max]="differentiatedPressureMinMax.max"
            [min]="differentiatedPressureMinMax.min"
            [showErrorTranslationString]="true"
            errorDescriptionTranslationString="app-enter-value-between"
            formControlName="differentiatedPressure"
            inputType="number"
            unit="°C">
          </gbc-input-field>
        </gbc-modal-section-column>
      </gbc-modal-section>
      <gbc-modal-section>
        <gbc-modal-section-column>
          <gbc-input-label>
            {{ 'mixit-balancing-limiters-modal.return-temperature' | translate }}
          </gbc-input-label>
          <img class="question-mark" src="./assets/svgs/question-mark.svg" pTooltip="{{ 'mixit-balancing-limiters-modal.return-temperature-tooltip' | translate }}" tooltipPosition="right" escape />
          <gbc-toggle class="align-right" formControlName="returnTemperatureLimiterEnabled"></gbc-toggle>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-field
            [errorDescriptionData]="returnTemperatureMinMax"
            [max]="returnTemperatureMinMax.max"
            [min]="returnTemperatureMinMax.min"
            [showErrorTranslationString]="true"
            errorDescriptionTranslationString="app-enter-value-between"
            formControlName="returnTemperature"
            inputType="number"
            unit="°C">
          </gbc-input-field>
        </gbc-modal-section-column>
      </gbc-modal-section>
    </form>
  </gbc-modal-content>
  <gbc-modal-buttons>
    <button gbc-modal-button (click)="close()" angulartics2On="click" angularticsAction="cancelClicked" angularticsCategory="adjustBalancingLimitersShown">
      {{ 'app-cancel' | translate }}
    </button>
    <button gbc-modal-button class="primary" (click)="save()" [disabled]="(valid$ | async) === false" angulartics2On="click" angularticsAction="saveClicked" angularticsCategory="adjustBalancingLimitersShown">
      {{ 'app-save' | translate }}
    </button>
  </gbc-modal-buttons>
</gbc-modal>
