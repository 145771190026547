import React from 'react';
import { Labels } from '../Labels';
import { Direction } from '../Common';
import { Pipe, PipeType } from '../Pipe';
import { BaseSystemProps, System } from '../System';
import { LabelPosition, SystemMeta } from '../types';
import { LargeLabelDeltas } from '../../util/constants';
import { TemperatureSensor } from '../TemperatureSensor';
import { EConnectionType, SystemType } from '../../state';
import { EConnectionPointPlacement, EEquipment } from '../../types';
import thumbnail from '!svg-url-loader!../../../../assets/system_thumbnails/cold-temperature.svg';
import coldWaterTemperatureSystemForm from '../../../models/systems/coldTemperatureSystem/coldTemperatureSystemForm.json';
import { IColdTemperatureSystemFormInterface } from '../../../models/systems/coldTemperatureSystem/coldTemperatureSystemFormInterface';

export const HEIGHT = 140;
export const WIDTH = 100;
export const PIPE_Y = 100;
export const TEMP_X = WIDTH / 2;

export const coldTemperatureMeta: SystemMeta = {
  systemType: SystemType.ColdTemperature,
  name: 'Temperature Sensor',
  thumbnail,
  form: coldWaterTemperatureSystemForm,
  width: WIDTH,
  height: HEIGHT,
  calculateConnectionPoints: () => [
    {
      key: EConnectionPointPlacement.connectionPrimaryReturn,
      x: 0,
      y: PIPE_Y,
      type: EConnectionType.return,
      dotted: true,
    },
    {
      key: EConnectionPointPlacement.connectionSecondaryReturn,
      x: WIDTH,
      y: PIPE_Y,
      type: EConnectionType.return,
      dotted: true,
    },
  ],
};

export const getLabels = (): LabelPosition[] => {
  return [
    {
      id: EEquipment.T_R,
      x: TEMP_X,
      y: PIPE_Y - 45,
      horizontalAlignment: 'middle',
    },
  ];
};

export const ColdTemperature: React.FC<BaseSystemProps<IColdTemperatureSystemFormInterface>> = React.memo(
  ({ state, values, alarm, selected }) => {
    return (
      <System state={state} meta={coldTemperatureMeta} alarm={alarm} selected={selected}>
        <Pipe x1={0} x2={WIDTH} y1={PIPE_Y} y2={PIPE_Y} type={PipeType.ColdReturn} />
        <TemperatureSensor x={WIDTH / 2} y={PIPE_Y} direction={Direction.EAST} />
        <Labels positions={getLabels()} values={values} />
      </System>
    );
  }
);
