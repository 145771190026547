<gbc-tile [title]="'dumbledore-schematics-tile.title' | translate" data-test-id="schematics-tile">
  <div gbc-tile-left *ngIf="isMixit$ | async" class="license-label">
    <ng-container *ngIf="(isConnect$ | async) && ((isDynamic$ | async) === false)">{{'schematic.connect' | translate}}</ng-container>
    <ng-container *ngIf="(isDynamic$ | async) && ((isConnect$ | async) === false)">{{'schematic.dynamic' | translate}}</ng-container>
    <ng-container *ngIf="((isDynamic$ | async) === false) && ((isConnect$ | async) === false)">{{'schematic.freemium' | translate}}</ng-container>
    <ng-container *ngIf="(isDynamic$ | async) && (isConnect$ | async)">{{'schematic.connect' | translate}} + {{'schematic.dynamic' | translate}}</ng-container>
  </div>
  <div class="title-right" gbc-tile-right>
    <app-last-updated [installationId]="installationId" [applicationId]="applicationId"></app-last-updated>
    <button gbc-icon-button gbc-tile-right
      class="fullscreen-button"
      (click)="fullscreen()"
      angulartics2On="click"
      angularticsAction="expandViewClicked"
      angularticsCategory="schematic">
      <img src="./assets/svgs/fullscreen.svg" alt="full screen">
    </button>
  </div>
  <div #dumbledore class="dumbledore" [ngStyle]="{height: height + 'px'}"></div>
</gbc-tile>
