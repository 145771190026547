<gbc-breadcrumb [pageInfo]="pageInfo"></gbc-breadcrumb>
<gbc-page-error-handler [content]="content" [pageError$]="pageError$"></gbc-page-error-handler>
<ng-template #content>
  <gbc-tabs>
    <gbc-tab name="map" [title]="'facility-map-page.map-tab' | translate" (selectTab)="tabSelected($event)" data-test-id="gbc-map-tab">
      <div class="main-container">
        <div class="map-container">
          <button gbc-button class="mixit-button" [routerLink]="['/mixit', 'onboarding']" data-test-id="claim-device" angulartics2On="click" angularticsAction="claimDeviceClicked" angularticsCategory="facilityMap">
            {{ 'facility-map-page.claim-device' | translate }}
          </button>
          <app-map
            *ngIf="(currentTab$ | async) === 'map'"
            (facilityClick)="navigateToFacility($event)"
            [facilityData$]="filteredFacilities$"
            [saveCoordinatesInQueryParams]="true"
            [zoomOutFromSingleFacility]="true">
          </app-map>
        </div>
        <div class="list-container">
          <form class="search-container" [formGroup]="searchForm" autocomplete="off">
            <div class="form-bar search-bar">
              <gbc-search-input #mapSearch class="search-box" formControlName="searchQuery" [placeholder]="mapSearch.value ? mapSearch.value : ('facility-map-page.search-facility' | translate)"></gbc-search-input>
            </div>
            <div class="form-bar filter-bar">
              <p-selectButton class="search-filters" [options]="filterOptions" formControlName="searchFilters" [multiple]="true" optionLabel="name" optionValue="value">
                <ng-template let-item pTemplate>
                  <span class="status" [ngClass]="item.value" [innerHTML]="getFacilityStatusIcon(item.value)"></span>
                </ng-template>
              </p-selectButton>
            </div>
          </form>
          <gbc-tile [title]="'facility-map-page.title' | translate" data-test-id="gbc-facility-list">
            <gbc-table class="facility-table" clickableRows="true">
              <tr *ngIf="!(filteredFacilities$ | async)?.data.length" class="empty-search-list">
                <td gbc-table-cell class="facility-name">
                  <span class="no-results-message">
                    {{ 'facility-map-page.no-facilities-found' | translate }}
                  </span>
                </td>
              </tr>
              <tr gbc-table-row *ngFor="let facility of (filteredFacilities$ | async)?.data" (rowClicked)="navigateToFacility(facility)">
                <td gbc-table-cell [ngClass]="facility.status" class="facility-name">
                  <span class="status" [innerHTML]="getFacilityStatusIcon(facility.status)"></span>
                  <span>{{ facility.name }}</span>
                </td>
              </tr>
            </gbc-table>
          </gbc-tile>
        </div>
      </div>
    </gbc-tab>
    <gbc-tab
      *ngIf="(user$ | async)?.hasInstallationsWithEnergyData"
      name="energyData"
      [title]="'facility-map-page.energy-data-tab' | translate"
      class="app-tab-list"
      (selectTab)="tabSelected($event)"
      data-test-id="gbc-energy-data-tab">
    </gbc-tab>
  </gbc-tabs>
</ng-template>
