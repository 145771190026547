import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { WeatherCompensationCurve, WeatherCurveDTO } from '../interfaces/weather-compensation';
import { SetpointDTO } from '../interfaces/setpoint';
import { CommandService } from 'shared';
import { PumpControlMode, OperatingMode } from 'projects/customerportal/src/app/interfaces/alarm';
import { EBackendUnit } from '../interfaces/data-points';
import { MixitDataPointName, UnitType } from '../interfaces/mixit';
import { IAlarmSettingsDto } from '../interfaces/alarm-settings';
import { ValvePositionDTO } from '../interfaces/valve';
import { SystemControlMode } from '../interfaces/system-control-mode';
import { WarmWeatherShutdownSettingsDTO } from '../interfaces/warm-weather-shutdown';

export interface PumpOption {
  controlMode: PumpControlMode;
  max: number;
  min: number;
  unitType: UnitType;
}

export interface EquipmentMetaInfo {
  id: string;
  enabled: boolean;
  term: MixitDataPointName;
  min: number;
  max: number;
  unit: UnitType;
  pumpControlOptions?: PumpOption[];
}

export interface MixitEquipmentMetaInfo extends EquipmentMetaInfo {
  pumpName: string;
  pumpLocation: string;
  pumpCount: number;
}

export interface SystemMetaInfo {
  id: string;
  subSystemId: string;
  equipmentMetaInfos: EquipmentMetaInfo[];
  weatherCompensationDto: WeatherCurveDTO;
  warmWeatherShutdownAvailable?: boolean;
  alarmSettings: IAlarmSettingsDto[];
}

export interface InstallationConfiguration {
  firmWareVersion: string;
  bleVersion: string;
  systemMetaInfos: SystemMetaInfo[];
  showMixitX509Warning?: boolean;
}

export interface UpdatePumpControlModeDTO {
  controlMode: string;
  value: string | null | undefined;
  unitType: EBackendUnit;
}


export interface UpdateSystemModeAndPumpModeDTO {
  systemControlMode: SystemControlMode,
  pumpControlMode: UpdatePumpControlModeDTO,
  pumpId: string,
  operationMode: OperatingMode
}

export interface UpdateSystemModeAndValvePositionDTO {
  systemControlMode: SystemControlMode,
  valveId: string,
  valvePosition?: ValvePositionDTO
}

@Injectable({
  providedIn: 'root'
})
export class InstallationConfigurationService {

  constructor(
    private httpClient: HttpClient,
    private commandService: CommandService
  ) {}

  public setWeathercompensationCurve(installationId: string, applicationId: string, curve: WeatherCompensationCurve): Observable<unknown> {
    return this.commandService.executeAsync(() => this.httpClient.patch<WeatherCurveDTO>(`${environment.serverUrl}/api/system/weatherCompensation/${installationId}/${applicationId}`, {points: curve, unit: EBackendUnit.DegreeCelsius}),
        {successMessageKey: 'weather-compensation-service.curve-updated'});
  }

  public setSystemModeAndPumpMode(installationId: string, systemId: string, data: UpdateSystemModeAndPumpModeDTO) {
    return this.commandService.executeAsync(() => this.httpClient.patch(`${environment.serverUrl}/api/system/setSystemModeAndPumpMode/${installationId}/${systemId}`, data),
        {successMessageKey: 'installation-configuration-service.control-mode-updated'});
  }

  public setControlModeBuildingConnect(installationId: string, pumpId: string, data: UpdatePumpControlModeDTO) {
    return this.commandService.executeAsync(() => this.httpClient.patch(`${environment.serverUrl}/api/system/pumpControlMode/${installationId}/${pumpId}`, data),
        {successMessageKey: 'installation-configuration-service.control-mode-updated'});
  }

  public startStopPump(installationId: string, pumpId: string, operationMode: OperatingMode) {
    return this.commandService.executeAsync(() => this.httpClient.patch(`${environment.serverUrl}/api/system/startStopPump/${installationId}/${pumpId}/${operationMode}`, null),
      {successMessageKey: 'installation-configuration-service.control-mode-updated'});
  }

  public setAlarmSettings(installationId: string, applicationId: string, data: IAlarmSettingsDto): Observable<unknown> {
    return this.commandService.executeAsync(() => this.httpClient.patch<IAlarmSettingsDto>(`${environment.serverUrl}/api/system/alarmSettings/${installationId}/${applicationId}`, data),
      { successMessageKey: 'installation-configuration-service.alarm-settings-updated' });
  }

  public cancelPasteurizationJob(installationId: string): Observable<unknown> {
    return this.commandService.executeAsync(() => this.httpClient.patch(`${environment.serverUrl}/api/system/pasteurization/cancel/${installationId}`, null),
      { successMessageKey: 'installation-configuration-service.pasteurization-job-canceled' });
  }

  public updateSetPoint(installationId: string, applicationId: string, setpoint: string): Observable<unknown> {
    return this.commandService.executeAsync(() => this.httpClient.patch<SetpointDTO>(`${environment.serverUrl}/api/system/setpoint/${installationId}/${applicationId}`, {setpoint: setpoint, unit: EBackendUnit.DegreeCelsius}),
        {successMessageKey: 'system-service.setpoint-updated'});
  }

  public setSystemModeAndValvePosition(installationId: string, systemId: string, data: UpdateSystemModeAndValvePositionDTO): Observable<unknown> {
    return this.commandService.executeAsync(() => this.httpClient.patch(`${environment.serverUrl}/api/system/setSystemModeAndValvePosition/${installationId}/${systemId}`, data),
        { successMessageKey: 'installation-configuration-service.valve-setpoint-updated' });
  }

  public updateWarmWeatherShutdown(installationId: string, systemId: string, dto: WarmWeatherShutdownSettingsDTO): Observable<unknown> {
    return this.commandService.executeAsync(() => this.httpClient.patch<WarmWeatherShutdownSettingsDTO>(`${environment.serverUrl}/api/system/warmWeatherShutdown/${installationId}/${systemId}`, dto),
      { successMessageKey: 'installation-configuration-service.update-warm-weather-shutdown-success' });
  }
}
