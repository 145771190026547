import React from 'react';
import { colors } from '../../util/colors';
import { pointsToSvgPathD } from './Common';

interface ConnectorProps {
  points: number[][];
}

export const Connector: React.FC<ConnectorProps> = React.memo(({ points }) => {
  return <path d={pointsToSvgPathD(points)} stroke={colors.lineColor} strokeDasharray="7 3" strokeWidth="2" fill="none" />;
});
