import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {DataPointState, DataPointType, EBackendUnit} from '../../interfaces/data-points';
import {EEquipment, EValueType} from "@ams/dumbledore";


export interface Value {
  titleKey: string;
  value: string | undefined;
  state?: DataPointState;
  visualEnabled?: "enabled" | "disabled";
  visualState?: "active" | "inactive";
}

interface TileModel {
  titleKey: string;
  value: string | undefined;
  state: DataPointState | undefined;
  showVisualState?: "enabled" | "disabled";
  visualState?: "active" | "inactive";
  isRight: boolean;
  isBottom: boolean;
}

@Component({
  selector: 'app-multi-value-tile',
  templateUrl: './multi-value-tile.component.html',
  styleUrls: ['./multi-value-tile.component.scss']
})
export class MultiValueTileComponent implements OnChanges {
  @Input() public installationId: string;
  @Input() public applicationId: string;
  @Input() public tileTitle: string;
  @Input() header: string;
  @Input() lastUpdated: number;
  @Input() values: Value[];
  @Input() columns: number = 4;
  @Input() showAdjust: boolean = false;
  @Input() disableAdjust: boolean = false;
  @Output() adjust = new EventEmitter<void>();

  public models: TileModel[];

  public state = DataPointState;

  ngOnChanges() {
    this.models = this.values?.map((value, index) => {
      const rows = this.values.length / this.columns;
      const isRight = index % this.columns === this.columns - 1;
      const isBottom = index >= rows * this.columns;
      return {
        titleKey: value.titleKey,
        value: value.value,
        isRight,
        isBottom,
        state: value.state,
        showVisualState: value.visualEnabled,
        visualState: value.visualState,
      };
    });
  }
}
