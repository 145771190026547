import React from 'react';
import { Labels } from '../../Labels';
import { Direction } from '../../Common';
import { PumpIcon } from '../../PumpIcon';
import { Connector } from '../../Connector';
import { Pipe, PipeType } from '../../Pipe';
import * as metaData from './pump-standalone-metadata';
import { System, BaseSystemProps } from '../../System';
import { TemperatureSensor } from '../../TemperatureSensor';
import { IPumpStandaloneSystemFormInterface } from '../../../../models/systems/pumpStandaloneSystem/pumpStandaloneSystemFormInterface';

export const PumpStandaloneSystem: React.FC<BaseSystemProps<IPumpStandaloneSystemFormInterface>> = React.memo(
  ({ state, values, alarm, selected }) => {
    const systemInfo: IPumpStandaloneSystemFormInterface = state.systemInfo;

    const pump = () => {
      if (systemInfo.P_location === 'Forward') {
        return <PumpIcon direction={Direction.EAST} x={metaData.PUMP_X} y={metaData.FORWARD_Y} />;
      }
      if (systemInfo.P_location === 'Return') {
        return <PumpIcon direction={Direction.WEST} x={metaData.PUMP_X} y={metaData.RETURN_Y} />;
      }
    };

    const forwardTempSensor = () => {
      const tempSensor = <TemperatureSensor x={metaData.FORWARD_TEMP_X} y={metaData.FORWARD_Y} direction={Direction.EAST} />;

      if (systemInfo.PumpTemperatureSensor && systemInfo.P_location === 'Return') {
        return tempSensor;
      }

      return null;
    };

    const returnTempSensor = () => {
      const tempSensor = <TemperatureSensor x={metaData.FORWARD_TEMP_X} y={metaData.RETURN_Y} direction={Direction.WEST} />;

      if (systemInfo.PumpTemperatureSensor && systemInfo.P_location === 'Forward') {
        return tempSensor;
      }

      return null;
    };

    const pumpToTemperatureSensorConnections = () => {
      const horizontalLine = (
        <Connector
          key="horizontalPumpLine"
          points={[
            [metaData.PUMP_X, metaData.CENTER],
            [metaData.FORWARD_TEMP_X, metaData.CENTER],
          ]}
        />
      );
      if (systemInfo.PumpTemperatureSensor) {
        if (systemInfo.P_location === 'Return') {
          return (
            <g>
              <Connector
                key="verticalPumpLine"
                points={[
                  [metaData.PUMP_X, metaData.RETURN_Y],
                  [metaData.PUMP_X, metaData.CENTER],
                ]}
              />
              {horizontalLine}
              <Connector
                key="verticalLineToTemp"
                points={[
                  [metaData.FORWARD_TEMP_X, metaData.CENTER],
                  [metaData.FORWARD_TEMP_X, metaData.FORWARD_Y],
                ]}
              />
            </g>
          );
        }

        if (systemInfo.P_location === 'Forward') {
          return (
            <g>
              <Connector
                key="verticalPumpLine"
                points={[
                  [metaData.PUMP_X, metaData.FORWARD_Y],
                  [metaData.PUMP_X, metaData.CENTER],
                ]}
              />
              {horizontalLine}
              <Connector
                key="verticalLineToTemp"
                points={[
                  [metaData.FORWARD_TEMP_X, metaData.CENTER],
                  [metaData.FORWARD_TEMP_X, metaData.RETURN_Y],
                ]}
              />
            </g>
          );
        }
      }

      return null;
    };

    return (
      <System state={state} meta={metaData.pumpStandaloneMeta} alarm={alarm} selected={selected}>
        <Pipe key="supply" type={PipeType.WarmSupply} x1={0} y1={metaData.FORWARD_Y} x2={metaData.WIDTH} y2={metaData.FORWARD_Y} />
        <Pipe key="return" type={PipeType.ColdReturn} x1={0} y1={metaData.RETURN_Y} x2={metaData.WIDTH} y2={metaData.RETURN_Y} />

        {pumpToTemperatureSensorConnections()}
        {pump()}

        {/* Temp sensors on pipes */}
        {forwardTempSensor()}
        {returnTempSensor()}

        <Labels positions={metaData.getPumpStandaloneLabels(systemInfo)} values={values} />
      </System>
    );
  }
);
