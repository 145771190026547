import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map, shareReplay, tap, withLatestFrom } from 'rxjs/operators';
import { Facility, Installation } from '../../interfaces/facilty';
import { PageInfoService } from '../../services/page-info.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlarmMetadata, AlarmOverview, Application } from '../../interfaces/alarm';
import { FacilityService } from '../../services/facility.service';
import { SchematicsService } from '../../services/schematics.service';
import { AlarmService } from '../../services/alarm.service';
import { IAlarmSettingsInfoDto } from '../../interfaces/alarm-settings-info';


@Component({
  selector: 'app-alarm-settings-table',
  templateUrl: './alarm-settings-table.component.html',
  styleUrls: ['./alarm-settings-table.component.scss']
})
export class AlarmSettingsTableComponent implements OnInit {
  alarms$: Observable<AlarmOverview[]>;
  visible$: Observable<boolean>;

  @Input() minHeight: string;
  @Input() noAlarmSettingsMessage?: string;
  @Input() clickable = false;

  @Output() settingsClick = new EventEmitter<AlarmOverview>();

  public installation$: Observable<Installation>;
  public facility$: Observable<Facility>;
  public alarmSettingsInfo$: Observable<IAlarmSettingsInfoDto[]>;

  constructor(
    private facilityService: FacilityService,
    private pageInfoService: PageInfoService,
    private alarmService: AlarmService,
    private schematicService: SchematicsService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.facility$ = this.facilityService.getCurrentFacility(this.route);
    this.installation$ = this.facilityService.getCurrentInstallation(this.route);

    this.alarmSettingsInfo$ = this.schematicService.getInstallationAlarmSettings().pipe(
      withLatestFrom(this.alarmService.alarmMetadatas$),
      map(([alarmSettings, alarmMetadata]: [IAlarmSettingsInfoDto[], AlarmMetadata[]] ) => {
        const buildingConnectAlarmMeta = alarmMetadata.filter(meta => meta.productName == "buildingconnect");
        alarmSettings.forEach((alarmInfo: IAlarmSettingsInfoDto, index) => {
          const alarmMetaTitle = buildingConnectAlarmMeta.find(meta => meta.mainErrorCode == alarmInfo.alarmSettings.errorCode.toString())?.title;
          alarmInfo.alarmTitle = alarmMetaTitle ? alarmMetaTitle : "";
        });
        return alarmSettings;
      }),
      shareReplay({refCount: true})
    );

    this.visible$ = this.alarmSettingsInfo$.pipe(
      map(info => info.length > 0)
    );
  }
}
