<gbc-page-error-handler [content]="content" [pageError$]="pageError$">
</gbc-page-error-handler>
<ng-template #content>

  <router-outlet *ngIf="route.children.length !== 0"></router-outlet>

  <ng-container *ngIf="route.children.length === 0">
    <app-mixit-facility-page *ngIf="isMixit$ | async"></app-mixit-facility-page>
    <app-buildingconnect-facility-page *ngIf="isBuildingConnect$ | async"></app-buildingconnect-facility-page>
    <app-connectingcore-facility-page *ngIf="isConnectingCore$ | async"></app-connectingcore-facility-page>
  </ng-container>
</ng-template>
