import React from 'react';
import { systemMetadataMap } from './system-metadata';
import { FreeConnectionPointState, Rect, SchematicState, SystemState } from '@ams/dumbledore';

const HEADER_AREA_HEIGHT: number = 40;

const AidLine: React.FC<{ x1: number; y1: number; x2: number; y2: number }> = ({ x1, y1, x2, y2 }) => {
  return <line x1={x1} x2={x2} y1={y1} y2={y2} stroke="#bbb" strokeDasharray="2 2" />;
};

const HorizontalAid: React.FC<{ viewBox: Rect; y: number }> = ({ viewBox, y }) => {
  return <AidLine x1={viewBox.x} x2={viewBox.x + viewBox.width} y1={y} y2={y} />;
};

const VerticalAid: React.FC<{ viewBox: Rect; x: number }> = ({ viewBox, x }) => {
  return <AidLine x1={x} x2={x} y1={viewBox.y} y2={viewBox.y + viewBox.height} />;
};

const ConnectionPointDraggingAid: React.FC<{ viewBox: Rect; state: SchematicState }> = ({ viewBox, state }) => {
  if (state.connectionPointBeingDragged) {
    const point = state.points.find(
      (p) => p.hasId === (state.connectionPointBeingDragged as { id: string }).id
    ) as FreeConnectionPointState;
    return (
      <React.Fragment>
        <VerticalAid key="vertical" x={point?.x || 0} viewBox={viewBox} />;
        <HorizontalAid key="horizontal" y={point?.y || 0} viewBox={viewBox} />;
      </React.Fragment>
    );
  }
  return null;
};

const SystemDraggingAid: React.FC<{ viewBox: Rect; state: SchematicState }> = ({ viewBox, state }) => {
  if (state.draggingSystemState) {
    const systemId = state.draggingSystemState.systemId;
    const system = state.systems.find((s) => s.hasId === systemId) as SystemState;
    const meta = systemMetadataMap[system.type];
    const connectionPointAids = meta.calculateConnectionPoints(system.systemInfo).map((point) => {
      if (point.x === 0) {
        return <AidLine key={point.key} x1={viewBox.x} x2={system.left} y1={system.top + point.y} y2={system.top + point.y} />;
      }
      if (point.x === meta.width) {
        return (
          <AidLine
            key={point.key}
            x1={system.left + meta.width}
            x2={viewBox.x + viewBox.width}
            y1={system.top + point.y}
            y2={system.top + point.y}
          />
        );
      }
      return (
        <React.Fragment key={point.key}>
          <HorizontalAid viewBox={viewBox} y={system.top + point.y} />;
          <AidLine x1={system.left + point.x} x2={system.left + point.x} y1={system.top + point.y} y2={viewBox.y + viewBox.height} />
        </React.Fragment>
      );
    });
    return (
      <g data-systemdraggingaid="">
        <VerticalAid key="left" x={system.left} viewBox={viewBox} />;
        <VerticalAid key="right" x={system.left + meta.width} viewBox={viewBox} />
        <HorizontalAid key="top" y={system.top + HEADER_AREA_HEIGHT} viewBox={viewBox} />
        <HorizontalAid key="bottom" y={system.top + meta.height} viewBox={viewBox} />
        {connectionPointAids}
      </g>
    );
  }
  return null;
};
const SystemBoxesAid: React.FC<{ state: SchematicState }> = ({ state }) => {
  if (state.connectionPointBeingDragged || state.draggingSystemState) {
    const rects = state.systems.map((system) => {
      if (system.hasId === state.selectedSystemId) {
        return null;
      }
      const meta = systemMetadataMap[system.type];
      return (
        <rect
          data-systemboxaid=""
          key={system.hasId}
          x={system.left}
          y={system.top + HEADER_AREA_HEIGHT}
          width={meta.width}
          height={meta.height - HEADER_AREA_HEIGHT}
          stroke="#bbb"
          strokeDasharray="2 2"
          fill="none"
        />
      );
    });
    return <React.Fragment>{rects}</React.Fragment>;
  }
  return null;
};

export const DraggingAid: React.FC<{ state: SchematicState; viewBox: Rect }> = ({ state, viewBox }) => {
  return (
    <React.Fragment>
      <SystemBoxesAid state={state} />
      <ConnectionPointDraggingAid state={state} viewBox={viewBox} />
      <SystemDraggingAid state={state} viewBox={viewBox} />
    </React.Fragment>
  );
};
