import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gbc-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  @Input() type: 'info' | 'alarm' | 'warning' | 'success';
  @Input() class: string;
}
