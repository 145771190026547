<gbc-page-error-handler [content]="content" [pageError$]="pageError$"></gbc-page-error-handler>
<ng-template #content>
  <gbc-page-header *ngIf="pageInfo$ | async as pageInfo" [pageInfo]="pageInfo"></gbc-page-header>
  <gbc-page-container *ngIf="application$ | async as application">
    <app-unspecified-load-dashboard
        *ngIf="application.type === ApplicationType.UnspecifiedLoad"
        [schematic$]="schematic$"
        [dataPoints$]="dataPoints$"
        [application]="application"
        [installation$]="installation$"
        [alarm$]="alarm$">
    </app-unspecified-load-dashboard>
    <app-mixing-loop-dashboard
        *ngIf="application.type === ApplicationType.MixingLoop || application.type === ApplicationType.MixingloopUnspecifiedLoadSystem"
        [schematic$]="schematic$"
        [dataPoints$]="dataPoints$"
        [application]="application"
        [installation$]="installation$"
        [alarm$]="alarm$">
    </app-mixing-loop-dashboard>
    <app-heat-exchanger-dashboard
        *ngIf="application.type === ApplicationType.HeatExchanger"
        [schematic$]="schematic$"
        [dataPoints$]="dataPoints$"
        [application]="application"
        [installation$]="installation$"
        [alarm$]="alarm$">
    </app-heat-exchanger-dashboard>
    <app-hot-water-dashboard
        *ngIf="application.type === ApplicationType.HotWaterTank || application.type === ApplicationType.HotWaterTankStandAloneSystem"
        [schematic$]="schematic$"
        [dataPoints$]="dataPoints$"
        [application]="application"
        [installation$]="installation$"
        [alarm$]="alarm$">
    </app-hot-water-dashboard>
    <app-district-heating-dashboard
        *ngIf="application.type === ApplicationType.DistrictHeatingSupply"
        [schematic$]="schematic$"
        [dataPoints$]="dataPoints$"
        [application]="application"
        [installation$]="installation$"
        [alarm$]="alarm$">
    </app-district-heating-dashboard>
    <app-outdoor-temp-dashboard
        *ngIf="application.type === ApplicationType.OutdoorTemperature"
        [schematic$]="schematic$"
        [dataPoints$]="dataPoints$"
        [application]="application"
        [installation$]="installation$"
        [alarm$]="alarm$">
    </app-outdoor-temp-dashboard>

    <app-cold-water-supply-dashboard
        *ngIf="application.type === ApplicationType.ColdWaterSupplySystem"
        [schematic$]="schematic$"
        [dataPoints$]="dataPoints$"
        [application]="application"
        [installation$]="installation$"
        [alarm$]="alarm$">
    </app-cold-water-supply-dashboard>

    <app-commercial-hot-water-dashboard
        *ngIf="application.type === ApplicationType.CommercialHotWater"
        [schematic$]="schematic$"
        [dataPoints$]="dataPoints$"
        [application]="application"
        [installation$]="installation$"
        [alarm$]="alarm$">
    </app-commercial-hot-water-dashboard>
    <app-commercial-hot-water-dashboard-standalone
      *ngIf="application.type === ApplicationType.CommercialHotWaterStandalone"
      [schematic$]="schematic$"
      [dataPoints$]="dataPoints$"
      [application]="application"
      [installation$]="installation$"
      [alarm$]="alarm$">
    </app-commercial-hot-water-dashboard-standalone>
    <app-pump-system-dashboard
        *ngIf="application.type === ApplicationType.HotWaterPump || application.type === ApplicationType.ColdWaterPump"
        [schematic$]="schematic$"
        [dataPoints$]="dataPoints$"
        [application]="application"
        [installation$]="installation$"
        [alarm$]="alarm$">
    </app-pump-system-dashboard>
    <app-boiler-dashboard
        *ngIf="application.type === ApplicationType.Boiler"
        [schematic$]="schematic$"
        [dataPoints$]="dataPoints$"
        [application]="application"
        [installation$]="installation$"
        [alarm$]="alarm$">
    </app-boiler-dashboard>
    <app-two-stroke-heat-exchanger-dashboard
      *ngIf="application.type === ApplicationType.TwoStrokeHeatExchangerHotWaterSystem"
      [schematic$]="schematic$"
      [dataPoints$]="dataPoints$"
      [application]="application"
      [installation$]="installation$"
      [alarm$]="alarm$">
    </app-two-stroke-heat-exchanger-dashboard>
    <app-buffer-tank-dashboard
      *ngIf="application.type === ApplicationType.BufferTank"
      [schematic$]="schematic$"
      [dataPoints$]="dataPoints$"
      [application]="application"
      [installation$]="installation$"
      [alarm$]="alarm$">
    </app-buffer-tank-dashboard>
    <app-pump-standalone-dashboard
      *ngIf="application.type === ApplicationType.PumpStandalone"
      [schematic$]="schematic$"
      [dataPoints$]="dataPoints$"
      [application]="application"
      [installation$]="installation$"
      [alarm$]="alarm$">
    </app-pump-standalone-dashboard>
    <app-pressure-system-dashboard
      *ngIf="application.type === ApplicationType.PressureSensorSystem"
      [schematic$]="schematic$"
      [dataPoints$]="dataPoints$"
      [application]="application"
      [installation$]="installation$"
      [alarm$]="alarm$">
    </app-pressure-system-dashboard>
    <app-indirect-district-heating-supply-dashboard
      *ngIf="application.type === ApplicationType.IndirectDistrictHeatingSupplySystem"
      [schematic$]="schematic$"
      [dataPoints$]="dataPoints$"
      [application]="application"
      [installation$]="installation$"
      [alarm$]="alarm$">
    </app-indirect-district-heating-supply-dashboard>
    <app-cascade-tank-dashboard
      *ngIf="application.type === ApplicationType.CascadeTankSystem"
      [schematic$]="schematic$"
      [dataPoints$]="dataPoints$"
      [application]="application"
      [installation$]="installation$"
      [alarm$]="alarm$">
    </app-cascade-tank-dashboard>

    <app-cascade-buffer-tank-charging-system-dashboard
      *ngIf="application.type === ApplicationType.CascadeBufferTankChargingSystem"
      [schematic$]="schematic$"
      [dataPoints$]="dataPoints$"
      [application]="application"
      [installation$]="installation$"
      [alarm$]="alarm$">
    </app-cascade-buffer-tank-charging-system-dashboard>

    <app-mixit-premium-dashboard
      *ngIf="application.type === ApplicationType.Mixit && (isMixitPremium$ | async)"
      [installation$]="installation$"
      [schematic$]="schematic$"
      [application$]="application$"
      [dataPoints$]="dataPoints$"
      [alarm$]="alarm$"
    ></app-mixit-premium-dashboard>

    <app-mixit-freemium-dashboard
      *ngIf="application.type === ApplicationType.Mixit && ((isMixitPremium$ | async) === false)"
      [installation$]="installation$"
      [schematic$]="schematic$"
      [application$]="application$"
      [dataPoints$]="dataPoints$"
      [alarm$]="alarm$"
    ></app-mixit-freemium-dashboard>

  </gbc-page-container>
</ng-template>
