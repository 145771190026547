<gbc-banner type="alarm" *ngIf="showAlarmBar$ | async" class="mt-8">
  <img src="./assets/svgs/alarm.svg" />
  <div>{{ 'mixit-installation-page.alarm' | translate }}</div>
</gbc-banner>

<gbc-banner type="warning" *ngIf="showWarningBar$ | async" class="mt-8">
  <img src="./assets/svgs/warning.svg" />
  <div>{{ 'mixit-installation-page.warning' | translate }}</div>
</gbc-banner>

<gbc-banner type="warning" *ngIf="(installationCommissionState$ | async) === 'Error'" class="mt-8">
  <img src="./assets/svgs/warning.svg" />
  <div [innerHtml]="'mixit-device-states.error-for-device-to-connect' | translate"></div>
</gbc-banner>

<gbc-banner type="info" *ngIf="isLessThanHalfHourSinceClaim$ | async" class="mt-8">
  <div [innerHtml]="'mixit-device-states.waiting-for-device-to-connect' | translate"></div>
</gbc-banner>

<ng-container *ngIf="installation$ | async as installation">
  <gbc-banner type="info" *ngIf="showUpgradeBar$ | async" class="mt-8">
    <img src="./assets/svgs/list-commissioning.svg" />
    <div>{{ 'trial-upgrade-banner.text' | translate: { trialPeriod: MixitTrialPeriod } }}</div>
    <button
      gbc-button
      [routerLink]="'/upgrade/trial/'"
      [queryParams]="{ installationId: installation.id }"
      angulartics2On="click"
      angularticsAction="upgradeTrialClicked"
      angularticsCategory="mixitFreemium"
    >
      {{ 'trial-upgrade-banner.button' | translate }}
    </button>
  </gbc-banner>
</ng-container>

<app-schematics-tile
  [applicationId]="(application$ | async)?.id"
  [installationId]="(installation$ | async)?.id"
  [dataPoints$]="dataPoints$"
  [disableAlarms]="true"
  height="500"
  [commissionState]="installationCommissionState$ | async"
  [showShadow]="false"
>
</app-schematics-tile>

<div class="tile-grid mb-8">
  <gbc-tile [title]="productType$ | async">
    <img
      gbc-tile-left
      (click)="showProductInfo()"
      class="info-img"
      [alt]="'mixit-installation-page.product-information' | translate"
      src="./assets/svgs/info-circle_outline.svg"
    />

    <div class="tile-content">
      <div class="tile-row">
        <div class="tile-row-name">{{ 'mixit-installation-page.operation-mode' | translate }}</div>
        <div class="tile-row-value">{{ operationMode$ | async }}</div>
      </div>
    </div>
  </gbc-tile>

  <gbc-tile [title]="'mixit-installation-page.temperature' | translate">
    <div class="tile-content">
      <div class="tile-row">
        <div class="tile-row-name">{{ 'mixit-installation-page.setpoint' | translate }}</div>
        <div *ngIf="(showLocalSetpoint$ | async) === false" class="tile-row-value">
          {{ setpoint$ | async | formatDataPoint }}
        </div>
        <div *ngIf="showLocalSetpoint$ | async" class="tile-row-value">
          {{ localSetpoint$ | async | formatDataPoint }}
        </div>
      </div>

      <div class="tile-row">
        <div class="tile-row-name">{{ 'mixit-installation-page.setpoint-source' | translate }}</div>
        <div class="tile-row-value" *ngIf="setpointSource$ | async as setpointSource">
          {{ 'mixit-setpoint-source.' + (setpointSource | formatDataPoint) | translate }}
        </div>
      </div>
    </div>
  </gbc-tile>

  <gbc-tile *ngIf="showPumpTile$ | async" [title]="pumpType$ | async">
    <img
      gbc-tile-left
      (click)="showPumpInfo()"
      class="info-img"
      src="./assets/svgs/info-circle_outline.svg"
      angulartics2On="click"
      angularticsAction="mixitProductInfoIconClicked"
      angularticsCategory="mixitSchematicClicked"
      data-test-id="pump-tile-info-icon"
    />
    <div class="tile-content">
      <div class="tile-row">
        <div class="tile-row-name">{{ 'mixit-installation-page.control-mode' | translate }}</div>
        <div class="tile-row-value">{{ controlMode$ | async }}</div>
      </div>
    </div>
  </gbc-tile>

  <app-energy-monitoring-tile
    *ngIf="isDynamic$ | async"
    [installation$]="installation$"
    [schematic$]="schematic$"
    [dataPoints$]="dataPoints$"
    [application$]="application$"
  ></app-energy-monitoring-tile>
</div>

<gbc-tile [title]="'mixit-installation-page.heat-curve-chart-title' | translate" *ngIf="showWeatherCurve$ | async">
  <app-weather-curve-chart [data$]="weatherCurve$" [outdoorTemp$]="outdoorTemp$"></app-weather-curve-chart>
</gbc-tile>
