import { Component, Input, OnInit } from '@angular/core';
import { Installation } from '../../interfaces/facilty';
import { ConnectionState } from '../../interfaces/connectionState';

@Component({
  selector: 'app-mixit-connection-status',
  templateUrl: './mixit-connection-status.component.html',
  styleUrls: ['./mixit-connection-status.component.scss'],
})
export class MixitConnectionStatusComponent implements OnInit {
  @Input()
  public installation: Installation;

  public state: ConnectionState;
  public text: string;

  public classMap: {[key in ConnectionState]: string} = {
    [ConnectionState.Disconnected]: 'disconnected',
    [ConnectionState.Connected]: 'connected',
    [ConnectionState.Unknown]: '',
    [ConnectionState.Connecting]: ''
  };

  ngOnInit(): void {
    this.text = this.installation.connectionState === ConnectionState.Connected ? 'mixit-connection-status.can-upgrade' : 'mixit-connection-status.unable-to-upgrade';
    this.state = this.installation.connectionState === ConnectionState.Connected ? ConnectionState.Connected : ConnectionState.Disconnected;
  }
}
