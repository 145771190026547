<gbc-tile [title]="title">
  <gbc-tile-filter-tab
    *ngIf="currentPeriod$ | async"
    [options]="filterOptions"
    (selectionChange)="selectPeriod($event)"
    [initialValue]="currentPeriod$ | async"
  ></gbc-tile-filter-tab>
  <div class="spinner-container" *ngIf="error">
    {{ errorMessage | translate }}
  </div>
  <div *ngIf="spinner && !error" class="spinner-container">
    <gbc-loading-spinner></gbc-loading-spinner>
  </div>
  <app-chart *ngIf="!spinner && !error" [options]="options" [series]="series$"></app-chart>
</gbc-tile>
