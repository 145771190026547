import React from 'react';
import { colors } from '../../util/colors';
import { Direction, getRotation, pointsToSvgPathD } from './Common';

export type ValveType = 'ThreeWayValve' | 'TwoWayValve';

export interface ValveProps {
  type: ValveType;
  direction: Direction;
  x: number;
  y: number;
}

const TRIANGLE_WIDTH = 18;
const TRIANGLE_HEIGHT = 15;
const CIRCLE_RADIUS = 9;
const pathPoints = (type: string) => [
  // top triangle
  [0, 0],
  [-TRIANGLE_WIDTH / 2, -TRIANGLE_HEIGHT],
  [TRIANGLE_WIDTH / 2, -TRIANGLE_HEIGHT],
  [0, 0],
  // right triangle
  ...(type === 'ThreeWayValve'
    ? [
        [TRIANGLE_HEIGHT, -TRIANGLE_WIDTH / 2],
        [TRIANGLE_HEIGHT, TRIANGLE_WIDTH / 2],
        [0, 0],
      ]
    : []),
  // bottom triangle
  [-TRIANGLE_WIDTH / 2, TRIANGLE_HEIGHT],
  [TRIANGLE_WIDTH / 2, TRIANGLE_HEIGHT],
  [0, 0],
  // line to circle
  [-TRIANGLE_WIDTH / 2, 0],
];

export const Valve: React.FC<ValveProps> = React.memo(({ type, direction, x, y }) => {
  const scale = [Direction.EAST, Direction.WEST].includes(direction) ? 'scale(-1 1)' : '';
  return (
    <g transform={`translate(${x},${y}) rotate(${getRotation(direction)}) ${scale}`}>
      <path stroke="#313131" strokeWidth="1.35" d={pointsToSvgPathD(pathPoints(type))} fill={colors.background} />
      <circle cx={-TRIANGLE_WIDTH / 2 - CIRCLE_RADIUS} cy="0" r={CIRCLE_RADIUS} stroke="#313131" fill="none" strokeWidth="1.35" />
    </g>
  );
});
