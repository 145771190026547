import { EditorState, TabState } from './state';
import { SystemState } from '../schematic/state';
import { formService } from '../services/formService';
import buildingForm from '../models/building/buildingForm.json';
import { IForm } from '../models/form.interface';
import { systemMetadataMap } from '../schematic/components/system-metadata';
import { IBuildingFormInterface } from '../models/building/buildingFormInterface';

export const getSystemById = (state: EditorState, systemId: string): SystemState => {
  return state.schematic.systems.find((system) => system.hasId === systemId) as SystemState;
};

export const buildingFormValid = (buildingInfo: IBuildingFormInterface): boolean => {
  return formService.formValid(buildingForm as IForm, buildingInfo);
};

export const systemValid = (system: SystemState): boolean => {
  const systemMeta = systemMetadataMap[system.type];
  return formService.formValid(systemMeta.form as IForm, system.systemInfo);
};
