import {Component, Input, OnInit} from '@angular/core';
import {combineLatest, Observable} from "rxjs";
import {DataPointsResult, DeviceDataPoint} from "../../../interfaces/data-points";
import {UserTrackingPeriodOptions} from "../../../services/user-tracking-helper.service";
import {DataPointsService, ReportPeriod, TimeSerie, TimeSeriesDetails} from "../../../services/data-points.service";
import {Options, SeriesOptionsType} from "highcharts";
import {ChartInterface} from "../chart.interface";
import {TranslateService} from "@ngx-translate/core";
import {filter, first, map, shareReplay, switchMap} from "rxjs/operators";
import {convertWtoKW, getDataPoint} from "../../../utils/data-point-utils";

@Component({
  selector: 'app-energy-valve-heat-and-flow-chart[installationId][applicationId]',
  templateUrl: './energy-valve-heat-and-flow-chart.component.html',
  styleUrls: ['./energy-valve-heat-and-flow-chart.component.scss']
})
export class EnergyValveHeatAndFlowChartComponent implements OnInit, ChartInterface {

  @Input() dataPoints$: Observable<DataPointsResult>;
  @Input() applicationId: string;
  @Input() installationId: string;
  @Input() userTrackingPeriodOptions: UserTrackingPeriodOptions;
  public getSeries: (period: ReportPeriod) => Observable<SeriesOptionsType[]>;
  public visible$: Observable<boolean>;
  public legends$ = combineLatest([
    this.translateService.get('energy-valve-heat-and-flow-chart.heat'),
    this.translateService.get('energy-valve-heat-and-flow-chart.flow'),
  ]);
  public dataPointsForChart$: Observable<(DeviceDataPoint | null)[]>;

  public options: Options = {
    yAxis: [
      {
        title: {
          text: null,
        },
        labels: {
          format: '{value} kW',
        },
        min: 0,
      },
      {
        title: {
          text: null,
        },
        labels: {
          format: '{value} m3/h',
        },
        min: 0,
        softMax: 1.0,
        opposite: true,
      },
    ],
    plotOptions: {
      series: {
        tooltip: {
          valueDecimals: 1,
        },
      },
    },
  };

  constructor(private translateService: TranslateService, private dataPointsService: DataPointsService) {}
  ngOnInit() {
    this.dataPointsForChart$ = this.dataPoints$.pipe(
      filter((dataPoints) => !!dataPoints?.data?.length),
      map((dataPoints) => {
        return [
          getDataPoint({
            applicationId: this.applicationId,
            systems: dataPoints?.data || [],
            clientId: 'Valve_HeatOutput',
          }),
          getDataPoint({
            applicationId: this.applicationId,
            systems: dataPoints?.data || [],
            clientId: 'Valve_Flow',
          })
        ];
      }),
      first()
    );

    this.visible$ = this.dataPointsForChart$.pipe(map((dataPoints) => dataPoints.some((dataPoint) => dataPoint)));

    this.getSeries = (period: ReportPeriod) => {
      return combineLatest([this.legends$, this.dataPointsForChart$]).pipe(
        switchMap(([legends, dataPoints]) => {
          const timeSeriesDetails: TimeSeriesDetails = {
            dataPoints,
            period,
            installationId: this.installationId,
            systemId: this.applicationId,
            requestId: 'energy_valve_heat_and_flow',
          };
          return this.dataPointsService
            .getTimeSeries(timeSeriesDetails)
            .pipe(
              map((seriesData: TimeSerie[]) => [
                { name: legends[0], data: convertWtoKW(seriesData[0]), type: 'line' } as SeriesOptionsType,
                { name: legends[1], data: seriesData[1], type: 'line', yAxis: 1 } as SeriesOptionsType,
              ])
            );
        }),
        shareReplay(1)
      );
    };
  }
}
