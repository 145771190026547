<div *ngIf="currentApplicationControlMode !== SystemControlMode.NotSupported" class="control-state-container" [ngClass]="'control-mode-' + SystemControlMode[currentApplicationControlMode]" data-test-id="system-control-mode-toggle">
  <div class="controls">
    <div *ngIf="currentApplicationControlMode === SystemControlMode.Unknown" class="icon-message">
      <gbc-icon iconName="auto-mode"></gbc-icon>
      <span class='icon-text'>{{ 'control-state-toggle.text.unknown' | translate }}</span>
    </div>
    <div *ngIf="currentApplicationControlMode === SystemControlMode.Auto" class="icon-message">
      <gbc-icon iconName="auto-mode"></gbc-icon>
      <span class='icon-text'>{{ 'control-state-toggle.text.auto' | translate }}</span>
    </div>
    <div *ngIf="currentApplicationControlMode === SystemControlMode.Manual" class="icon-message">
      <gbc-icon iconName="manual-mode"></gbc-icon>
      <span class='icon-text'>{{ 'control-state-toggle.text.manual' | translate }}</span>
    </div>
    <div class="choose-control-mode">
      <p-selectButton class='choose-control-mode-buttons' [options]="controlOptions" [(ngModel)]="controlMode" (onChange)="selectedApplicationControlMode.emit(controlMode)">
        <ng-template let-item>
          <span style="margin-right: 15px">{{ item.name }}</span>
          <i *ngIf="item.value === controlMode" class="pi pi-check"></i>
        </ng-template>
      </p-selectButton>
    </div>
  </div>
  <div *ngIf="controlMode !== currentApplicationControlMode">
    <p *ngIf="controlMode === SystemControlMode.Auto" class="detailed-message auto-mode">
      {{ 'control-state-toggle.message.auto' | translate }}
    </p>
    <p *ngIf="controlMode === SystemControlMode.Manual" class="detailed-message manual-mode">
      {{ 'control-state-toggle.message.manual' | translate }}
    </p>
  </div>
</div>
