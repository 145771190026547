import React from 'react';
import { Labels } from '../../Labels';
import { Direction } from '../../Common';
import { SvgImage } from '../../SvgImage';
import { Pipe, PipeType } from '../../Pipe';
import * as metaData from './buffer-tank-metadata';
import { System, BaseSystemProps } from '../../System';
import { TemperatureSensor } from '../../TemperatureSensor';
import bufferTankSvg from '!raw-loader!../../../../../assets/equipment/bufferTank.svg';
import { IBufferTankSystemFormInterface } from '../../../../models/systems/bufferTankSystem/bufferTankSystemFormInterface';

export const BufferTank: React.FC<BaseSystemProps<IBufferTankSystemFormInterface>> = React.memo(({ state, values, alarm, selected }) => {
  const systemInfo: IBufferTankSystemFormInterface = state.systemInfo;

  const primaryForwardTempSensor = () => {
    const tempSensor = <TemperatureSensor x={metaData.FORWARD_TEMP_X} y={metaData.FORWARD_Y} direction={Direction.EAST} />;

    if (systemInfo.T_PF_present) {
      return tempSensor;
    }

    return null;
  };

  const primaryReturnTempSensor = () => {
    const tempSensor = <TemperatureSensor x={metaData.FORWARD_TEMP_X} y={metaData.RETURN_Y} direction={Direction.WEST} />;

    if (systemInfo.T_PR_present) {
      return tempSensor;
    }

    return null;
  };

  return (
    <System state={state} meta={metaData.bufferTankMeta} alarm={alarm} selected={selected}>
      <Pipe
        key="supply_left"
        type={PipeType.WarmSupply}
        x1={0}
        y1={metaData.FORWARD_Y}
        x2={metaData.TANK_LEFT + 1}
        y2={metaData.FORWARD_Y}
      />
      <Pipe key="return_left" type={PipeType.ColdReturn} x1={0} y1={metaData.RETURN_Y} x2={metaData.TANK_LEFT + 1} y2={metaData.RETURN_Y} />

      <SvgImage svgText={bufferTankSvg} x={metaData.TANK_LEFT} y={metaData.TANK_TOP} />

      <Pipe
        key="supply_right"
        type={PipeType.WarmSupply}
        x1={metaData.TANK_RIGHT}
        y1={metaData.FORWARD_Y}
        x2={metaData.WIDTH}
        y2={metaData.FORWARD_Y}
      />
      <Pipe
        key="return_right"
        type={PipeType.ColdReturn}
        x1={metaData.TANK_RIGHT}
        y1={metaData.RETURN_Y}
        x2={metaData.WIDTH}
        y2={metaData.RETURN_Y}
      />

      {/* Temp sensors on pipes */}
      {primaryForwardTempSensor()}
      {primaryReturnTempSensor()}

      {systemInfo.T_SF_present && <TemperatureSensor x={metaData.RETURN_TEMP_X} y={metaData.FORWARD_Y} direction={Direction.EAST} />}
      {systemInfo.T_SR_present && <TemperatureSensor x={metaData.RETURN_TEMP_X} y={metaData.RETURN_Y} direction={Direction.WEST} />}

      {/* Tank sensors */}
      {systemInfo.T_T_present ||
        ([systemInfo.T_START, systemInfo.T_STOP].some((x) => x === 'TOP') && (
          <g key="topSensor" transform={`translate(${metaData.TANK_LEFT + 1} ${metaData.TANK_TOP_TEMP_Y})`}>
            <TemperatureSensor direction={Direction.NORTH} />
          </g>
        ))}

      {systemInfo.T_CENTERTOP_present ||
        ([systemInfo.T_START, systemInfo.T_STOP].some((x) => x === 'CENTERTOP') && (
          <g key="centerTopSensor" transform={`translate(${metaData.TANK_LEFT + 1} ${metaData.TANK_CENTER_TOP_TEMP_Y})`}>
            <TemperatureSensor direction={Direction.NORTH} />
          </g>
        ))}

      {systemInfo.T_CENTERBOTTOM_present ||
        ([systemInfo.T_START, systemInfo.T_STOP].some((x) => x === 'CENTERBOTTOM') && (
          <g key="centerBottomSensor" transform={`translate(${metaData.TANK_LEFT + 1} ${metaData.TANK_CENTER_BOTTOM_TEMP_Y})`}>
            <TemperatureSensor direction={Direction.NORTH} />
          </g>
        ))}

      {systemInfo.T_BOTTOM_present ||
        ([systemInfo.T_START, systemInfo.T_STOP].some((x) => x === 'BOTTOM') && (
          <g key="bottomSensor" transform={`translate(${metaData.TANK_LEFT + 1} ${metaData.TANK_BOTTOM_TEMP_Y})`}>
            <TemperatureSensor direction={Direction.NORTH} />
          </g>
        ))}

      <Labels positions={metaData.getBufferTankLabels(systemInfo)} values={values} />
    </System>
  );
});
