<gbc-table>
  <th gbc-table-header>Product Name</th>
  <th gbc-table-header>Error Codes</th>
  <th gbc-table-header>Title</th>
  <th gbc-table-header>Conditions</th>
  <th gbc-table-header>Cause</th>
  <th gbc-table-header>Remedy Steps</th>


  <tr gbc-table-row *ngFor="let metadata of metadatas$ | async">
    <td class="errorCode">{{metadata.productName}}</td>
    <td class="errorCode">{{metadata | formatAlarmMetadata }}</td>
    <td class="title">{{metadata.title}}</td>
    <td class="conditions">
      <ol *ngIf="metadata.conditions && metadata.conditions.length > 0">
        <li *ngFor="let condition of metadata.conditions">{{condition.value}}</li>
      </ol>
    </td>
    <td class="cause">
      <ol *ngIf="metadata.causes && metadata.causes.length > 0">
        <li *ngFor="let cause of metadata.causes">{{cause.description}}</li>
      </ol>
    </td>
    <td class="remedySteps">
      <ol *ngIf="metadata.causes?.length > 0 && metadata.causes[0]?.remedySteps?.length > 0">
        <li *ngFor="let remedyStep of metadata.causes[0].remedySteps">{{remedyStep.command}}</li>
      </ol>
    </td>
  </tr>
</gbc-table>
