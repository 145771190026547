
import {Component, Input, OnInit, OnChanges} from '@angular/core';
import {Params} from '@angular/router';
import {Observable} from 'rxjs';
import { PageInfo } from '../../interfaces/pageInfo';

interface Entry {
  title: Observable<string>;
  link: any[];
  queryParams?: Params;
  last: boolean;
}

@Component({
  selector: 'gbc-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnChanges, OnInit {

  @Input()
  public pageInfo: PageInfo;

  public entries: Entry[] = [];

  ngOnChanges() {
    this.updateEntries();
  }

  ngOnInit() {
    this.updateEntries();
  }

  updateEntries() {
    if (this.pageInfo) {
      this.entries = this.pageToEntries(this.pageInfo);
    }
  }

  entriesTrackByFn(index: number, item: PageInfo) {
    return item.link;
  }

  pageToEntries(page: PageInfo): Entry[] {
    const entries: Entry[] = [];
    let p: PageInfo | null = page;
    while (p) {
      entries.unshift({
        title: p.breadcrumb ? p.breadcrumb : p.title,
        last: false,
        link: p.link,
        queryParams: p.queryParams
      });
      p = p.parent;
    }
    entries[entries.length - 1].last = true;
    return entries;
  }
}
