import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MODAL_CONTROLLER, MODAL_DATA, ModalController } from 'shared';
import { map, tap } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { EquipmentMetaInfo } from '../../../services/installation-configuration.service';
import { UserTrackingHelperService } from '../../../services/user-tracking-helper.service';

export interface MixitWarmWeatherShutdownResponse {
  enabled: boolean;
  threshold: number;
  averagePeriod: number;
}

export interface MixitWarmWeatherShutdownSettings {
  enabled: boolean;
  threshold: number | null;
  averagePeriod: number | null;
  thresholdMeta: EquipmentMetaInfo;
}

@Component({
  selector: 'app-mixit-warm-weather-shutdown-modal',
  templateUrl: './mixit-warm-weather-shutdown-modal.component.html',
  styleUrls: ['./mixit-warm-weather-shutdown-modal.component.scss'],
})
export class MixitWarmWeatherShutdownModalComponent implements OnInit, OnDestroy {
  public valid$: Observable<boolean>;
  public form: UntypedFormGroup;
  public thresholdMinMax: { min: number; max: number };
  public periods = [1, 2, 3];
  private subscription = new Subscription();

  constructor(
    @Inject(MODAL_DATA) public data: MixitWarmWeatherShutdownSettings,
    @Inject(MODAL_CONTROLLER) private controller: ModalController<MixitWarmWeatherShutdownResponse>,
    private userTrackingHelperService: UserTrackingHelperService
  ) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      enabled: new UntypedFormControl(),
      threshold: new UntypedFormControl('', [
        Validators.required,
        Validators.min(this.data.thresholdMeta.min),
        Validators.max(this.data.thresholdMeta.max),
      ]),
      averagePeriod: new UntypedFormControl('', [Validators.required]),
    });

    this.thresholdMinMax = {
      min: this.data.thresholdMeta.min,
      max: this.data.thresholdMeta.max,
    };

    this.subscription.add(
      this.form.controls.enabled.valueChanges
        .pipe(
          tap((enabled) => {
            this.toggleEnabled(enabled);
          })
        )
        .subscribe()
    );

    this.valid$ = this.form.valueChanges.pipe(
      map((form) => {
        if (form.enabled) {
          return this.form.controls.threshold.valid && this.form.controls.averagePeriod.valid;
        }
        return true;
      })
    );

    this.toggleEnabled(this.data.enabled);

    setTimeout(() => {
      this.form.setValue({
        enabled: this.data.enabled,
        threshold: this.data.threshold,
        averagePeriod: this.data.averagePeriod,
      });

      this.subscription.add(this.userTrackingHelperService.startFormValueChangeTracking('adjustWarmWeatherShutdownShown', this.form));
    });
  }

  toggleEnabled(enabled: boolean) {
    if (enabled) {
      this.form.controls.threshold.enable();
      this.form.controls.averagePeriod.enable();
    } else {
      this.form.controls.threshold.disable();
      this.form.controls.averagePeriod.disable();
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  close() {
    this.controller.dismiss();
  }

  save() {
    this.controller.complete({
      enabled: this.form.controls.enabled.value,
      threshold: this.form.controls.threshold.value,
      averagePeriod: this.form.controls.averagePeriod.value,
    });
  }

  dismiss() {
    this.userTrackingHelperService.trackUserAction('adjustWarmWeatherShutdownShown', 'exitClicked');
  }
}
