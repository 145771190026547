import React from 'react';
import { Valve } from '../../Valve';
import { Labels } from '../../Labels';
import { Direction } from '../../Common';
import { PumpIcon } from '../../PumpIcon';
import { SvgImage } from '../../SvgImage';
import { Pipe, PipeType } from '../../Pipe';
import { SupplyTriangle } from '../../SupplyTriangle';
import { BaseSystemProps, System } from '../../System';
import { TemperatureSensor } from '../../TemperatureSensor';
import { BathAndFaucet } from '../../heat-exchanger-common/BathAndFaucet';
import * as metaData from './two-stroke-heat-exchanger-hot-water-metadata';
import { FORWARD_Y } from '../../heat-exchanger-common/heat-exchanger-common-metadata';
import heatExchangerSvg from '!raw-loader!../../../../../assets/systems/twostrokeheatexchanger.svg';
import { ITwoStrokeHeatExchangerHotWaterSystemFormInterface } from '../../../../models/systems/twoStrokeHeatExchangerHotWaterSystem/twoStrokeHeatExchangerHotWaterSystemFormInterface';
import { ValveEnergyIcon } from '../../ValveEnergyIcon';
import { systemHasEnergyValve } from '../../../util/system-properties-util';

export const TwoStrokeHeatExchangerHotWater: React.FC<BaseSystemProps<ITwoStrokeHeatExchangerHotWaterSystemFormInterface>> = React.memo(
  ({ state, values, selected }) => {
    const systemInfo = state.systemInfo;
    const hasEnergyValve = systemHasEnergyValve(systemInfo);

    const circulationTempSensor = () => {
      if (systemInfo.T_CR_present) {
        return <TemperatureSensor key="T_CR" x={metaData.SECONDARY_TEMP_SENSOR_X} y={metaData.FORWARD2_Y} direction={Direction.WEST} />;
      }
    };

    const tpfSensor = () => {
      if (systemInfo.T_PF_present) {
        return <TemperatureSensor key="T_PF" x={metaData.PRIMARY_TEMP_SENSOR_X} y={FORWARD_Y} direction={Direction.EAST} />;
      }
    };

    const tprSensor = () => {
      if (systemInfo.T_PR_present) {
        return <TemperatureSensor key="T_PR" x={metaData.PRIMARY_TEMP_SENSOR_X} y={metaData.RETURN_Y} direction={Direction.WEST} />;
      }
    };

    return (
      <System state={state} meta={metaData.twoStrokeHeatExchangerHotWaterSystemMeta} selected={selected}>
        <Pipe key="primaryForward" x1={0} y1={FORWARD_Y} x2={metaData.EXCHANGER_ICON_LEFT} y2={FORWARD_Y} type={PipeType.WarmSupply} />
        <Pipe
          key="primaryForward2"
          x1={0}
          y1={metaData.FORWARD2_Y}
          x2={metaData.EXCHANGER_ICON_LEFT}
          y2={metaData.FORWARD2_Y}
          type={PipeType.ColdReturn}
        />
        <Pipe
          key="primaryReturn"
          x1={0}
          y1={metaData.RETURN_Y}
          x2={metaData.EXCHANGER_ICON_LEFT}
          y2={metaData.RETURN_Y}
          type={PipeType.ColdReturn}
        />
        <Pipe
          key="secondaryForward"
          x1={metaData.EXCHANGER_ICON_RIGHT}
          y1={FORWARD_Y}
          x2={metaData.SECONDARY_LOOP_RIGHT}
          y2={FORWARD_Y}
          type={PipeType.WarmSupply}
        />
        <Pipe
          key="secondaryVertical"
          x1={metaData.SECONDARY_LOOP_RIGHT}
          y1={FORWARD_Y}
          x2={metaData.SECONDARY_LOOP_RIGHT}
          y2={metaData.FORWARD2_Y}
          type={PipeType.ColdReturn}
        />
        <Pipe
          key="secondaryReturn"
          x1={metaData.SECONDARY_LOOP_RIGHT}
          y1={metaData.FORWARD2_Y}
          x2={metaData.EXCHANGER_ICON_RIGHT}
          y2={metaData.FORWARD2_Y}
          type={PipeType.ColdReturn}
        />
        <Pipe
          key="coldSupply"
          x1={metaData.EXCHANGER_ICON_RIGHT}
          y1={metaData.RETURN_Y}
          x2={metaData.COLD_SUPPLY_RIGHT}
          y2={metaData.RETURN_Y}
          type={PipeType.ColdSupply}
        />
        <SupplyTriangle x={metaData.COLD_SUPPLY_RIGHT - 3} y={metaData.RETURN_Y} direction={Direction.WEST} warm={false} />
        <BathAndFaucet x={metaData.SECONDARY_LOOP_RIGHT} y={FORWARD_Y} />
        <SvgImage key="heatExIcon" svgText={heatExchangerSvg} x={metaData.EXCHANGER_ICON_LEFT} y={metaData.EXCHANGER_ICON_TOP} />
        <g key="T_SF" transform={`translate(${metaData.SECONDARY_TEMP_SENSOR_X},${FORWARD_Y})`}>
          <TemperatureSensor direction={Direction.EAST} />
        </g>
        {hasEnergyValve && <ValveEnergyIcon x={metaData.VALVE_X - 25} y={FORWARD_Y - 25} />}
        <Valve key="valve" type="TwoWayValve" direction={Direction.EAST} x={metaData.VALVE_X} y={FORWARD_Y} />
        <PumpIcon direction={Direction.WEST} x={metaData.PUMP_X} y={metaData.FORWARD2_Y} />
        {tpfSensor()}
        {tprSensor()}
        {circulationTempSensor()}
        <Labels positions={metaData.getTwoStrokeHeatExchangerHotWaterSystemLabels(systemInfo)} values={values} />
      </System>
    );
  }
);
