import { Component, Input } from '@angular/core';

@Component({
  selector: 'gbc-message-tile',
  templateUrl: './message-tile.component.html',
  styleUrls: ['./message-tile.component.scss']
})
export class MessageTileComponent {

  @Input() icon: string;
  @Input() title: string;
  @Input() text: string;

}
