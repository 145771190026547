import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { PageInfoService } from '../../services/page-info.service';
import { INotificationSettings, ModalService, PageInfo, ICompany } from 'shared';
import { TwoFactorVerificationFlowModalComponent } from '../../components/two-factor-verification-flow-modal/two-factor-verification-flow-modal.component';
import { UserService } from '../../services/user.service';
import { EMPTY, Observable, of, Subscription, combineLatest } from 'rxjs';
import { FormBuilder, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { first, flatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { IBuildingConnectUser, UserType } from 'projects/customerportal/src/app/interfaces/user';
import { TranslateService } from '@ngx-translate/core';
import { CompanyService } from '../../services/company.service';
import { TERMS_AND_CONDITIONS_LINK, TERMS_OF_USE_LINK } from '../../utils/links';

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss'],
})
export class ProfilePageComponent implements OnInit, OnDestroy {
  public currentUser$: Observable<IBuildingConnectUser | null>;
  public pageInfo: PageInfo;

  public subscription: Subscription = new Subscription();

  public company$: Observable<ICompany | null>;

  public notificationForm: FormGroup;

  TERMS_OF_USE_LINK = TERMS_OF_USE_LINK;
  TERMS_AND_CONDITIONS_LINK = TERMS_AND_CONDITIONS_LINK;
  checked: boolean = true;

  constructor(
    private pageInfoService: PageInfoService,
    private modalService: ModalService,
    private userService: UserService,
    private companyService: CompanyService,
    private translate: TranslateService,
    private formBuilder: FormBuilder
  ) {

    this.notificationForm = this.formBuilder.group({
      mobileActive: new FormControl(false),
      emailActive: new FormControl(false)
    });

    this.currentUser$ = this.userService.currentUser$.pipe(
      flatMap((user) => {
        // OIDC users do not have a profile page, so we filter them
        if (user?.type === UserType.OIDC) {
          return EMPTY;
        }

        if (user && !user?.notificationSettings) {
          const notificationSettings: INotificationSettings = {
            mobile: '',
            mobileActive: false,
            emailActive: false,
          };
          return of({
            ...user,
            notificationSettings,
          });
        }
        return of(user);
      })
    );

    this.company$ = this.currentUser$.pipe(
      first(),
      mergeMap((u) => {
        if (!u?.companyId) {
          return of(null);
        }

        return this.companyService.getCompany(u.companyId);
      })
    );

    const userSub = this.currentUser$.subscribe((currentUser) => {
      if (currentUser?.notificationSettings.mobile && currentUser.notificationSettings.mobileValidatedState === 'Validated') {
        this.notificationForm.controls.mobileActive.enable({ emitEvent: false });
      } else {
        this.notificationForm.controls.mobileActive.disable({ emitEvent: false });
      }

      this.notificationForm.setValue(
        {
          mobileActive: !!currentUser?.notificationSettings.mobileActive,
          emailActive: !!currentUser?.notificationSettings.emailActive,
        },
        { emitEvent: false }
      );
    });
    this.subscription.add(userSub);

    const formChangeSub = this.notificationForm.valueChanges
      .pipe(
        withLatestFrom(this.currentUser$),
        map(([notificationForm, user]) => {
          this.userService
            .setNotificationPreferences({
              mobileActive: !!notificationForm.mobileActive,
              emailActive: !!notificationForm.emailActive,
              mobile: user?.notificationSettings.mobile,
            })
            .subscribe(
              () => {
                // Don't do anything special here
              },
              (err) => {
                // Reset the value, to what it was before
                this.notificationForm.setValue(
                  {
                    mobileActive: !!user?.notificationSettings.mobileActive,
                    emailActive: !!user?.notificationSettings.emailActive,
                  },
                  { emitEvent: false }
                );
              }
            );
        })
      )
      .subscribe();

    this.subscription.add(formChangeSub);
  }

  ngOnInit(): void {
    this.pageInfo = this.pageInfoService.profile();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  editPhoneNumber(notificationSettings: INotificationSettings) {
    this.modalService.openDialog<{ phone: string }>(TwoFactorVerificationFlowModalComponent, { data: notificationSettings }).subscribe();
  }

  removePhoneNumber(notificationSettings: INotificationSettings) {
    this.modalService.showTextModal({
      title: this.translate.instant('app-profile.remove-phone-number'),
      content: this.translate.instant('app-profile.confirm-remove-phone-number'),
      actions: [
        {
          text: this.translate.instant('app-cancel'),
          cancel: true,
        },
        {
          text: this.translate.instant('app-profile.remove-phone-number'),
          type: 'danger',
          handler: () => {
            this.userService
              .setNotificationPreferences({
                ...notificationSettings,
                mobile: '',
                mobileActive: false,
              })
              .subscribe();
          },
        },
      ],
    });
  }
}
