<app-mixit-x509-banner/>
<app-alarm-bar *ngIf="alarm$ | async" [alarm]="alarm$"> </app-alarm-bar>

<app-schematics-tile
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [showShadow]="false"
  [dataPoints$]="dataPoints$"
>
</app-schematics-tile>

<ng-container *ngIf="installation$ | async as installation">
  <app-building-connect-weather-curve-tile
    *ngIf="hasWeatherCurve$ | async"
    [installation]="installation"
    [application]="application"
    [outdoorTemp$]="outdoorTemp$"
  >
  </app-building-connect-weather-curve-tile>
</ng-container>

<app-warm-weather-shutdown-tile
  *ngIf="isWarmWeatherShutdownAvailable$ | async"
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [dataPoints$]="dataPoints$"
></app-warm-weather-shutdown-tile>

<app-heat-meter-status-tile
  [installation$]="installation$"
  [application]="application"
  [dataPoints$]="dataPoints$"
  [schematic$]="schematic$"
>
</app-heat-meter-status-tile>

<app-forward-return-chart-tile
  [title]="'district-heating-dashboard.temperature-title' | translate"
  [applicationId]="application.id"
  forwardDumbledoreId="T_PF"
  returnDumbledoreId="T_PR"
  [dataPoints$]="dataPoints$"
  [userTrackingPeriodOptions]="{ category: 'application/unspecifiedLoad', intervalPrefix: 'temperature' }"
>
</app-forward-return-chart-tile>

<app-forward-return-chart-tile
  [title]="'district-heating-dashboard.temperature-title' | translate"
  [applicationId]="application.id"
  forwardDumbledoreId="T_F"
  returnDumbledoreId="T_R"
  [dataPoints$]="dataPoints$"
  [userTrackingPeriodOptions]="{ category: 'application/unspecifiedLoad', intervalPrefix: 'temperature' }"
>
</app-forward-return-chart-tile>
