import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DataPointsResult, DeviceDataPoint } from '../../interfaces/data-points';
import { getDatapoint } from '../../utils/mixit-utils';
import { BoosterDataPoints } from '../../interfaces/booster';
import { SystemDeviceType } from '../../interfaces/systemDeviceType';

@Component({
  selector: 'app-booster-pump-tile',
  templateUrl: './booster-pump-tile.component.html',
  styleUrls: ['./booster-pump-tile.component.scss'],
})
export class BoosterPumpTileComponent implements OnInit {
  @Input() dataPoints$: Observable<DataPointsResult>;

  public power$: Observable<DeviceDataPoint | null>;
  public energy$: Observable<DeviceDataPoint | null>;
  public flow$: Observable<DeviceDataPoint | null>;
  public specificEnergy$: Observable<DeviceDataPoint | null>;

  ngOnInit(): void {
    this.power$ = getDatapoint(this.dataPoints$, SystemDeviceType.BoosterSystem, BoosterDataPoints.P1Power);
    this.energy$ = getDatapoint(this.dataPoints$, SystemDeviceType.BoosterSystem, BoosterDataPoints.P1Energy);
    this.flow$ = getDatapoint(this.dataPoints$, SystemDeviceType.BoosterSystem, BoosterDataPoints.Flow);
    this.specificEnergy$ = getDatapoint(this.dataPoints$, SystemDeviceType.BoosterSystem, BoosterDataPoints.SystemSpecEnergy);
  }
}
