import { AfterContentInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'app-tab-tile',
  templateUrl: './tab-tile.component.html',
  styleUrls: ['./tab-tile.component.scss']
})
export class TabTileComponent implements AfterContentInit {

  @Input()
  title: string;

  @ContentChildren(TabComponent)
  tabs: QueryList<TabComponent>;

  ngAfterContentInit() {
    // get all active tabs
    const activeTabs = this.tabs.filter((tab) => tab.active);

    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first, false);
    }
  }

  selectTab(selectedTab: TabComponent, isInitialized: boolean = true) {
    this.tabs.toArray().forEach(tab => tab.active = false);
    selectedTab.active = true;

    if (isInitialized) {
      selectedTab.tabSelected.emit(selectedTab);
    }
  }
}
