import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { PageInfoService } from '../../services/page-info.service';
import { AppError, AppErrorService, PageInfo } from 'shared';
import { FacilityService } from '../../services/facility.service';
import { ActivatedRoute } from '@angular/router';
import { EHealthCheckTargetKey, HealthCheck, HealthCheckMetadataDto } from '../../interfaces/installation';
import { HealthCheckService } from '../../services/health-check.service';
import { SchematicsService } from '../../services/schematics.service';

@Component({
  selector: 'app-health-check-page',
  templateUrl: './health-check-page.component.html',
  styleUrls: ['./health-check-page.component.scss']
})
export class HealthCheckPageComponent implements OnInit {
  public pageInfo$: Observable<PageInfo>;
  public pageError$: Observable<AppError>;

  public healthCheck$: Observable<HealthCheck | null | undefined>;
  public healthCheckMetadata$: Observable<HealthCheckMetadataDto | null | undefined>;

  constructor(
    private healthCheckService: HealthCheckService,
    private facilityService: FacilityService,
    private schematicService: SchematicsService,
    private errorService: AppErrorService,
    private pageInfoService: PageInfoService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    const healthCheckTargetKey = this.route.snapshot.paramMap.get('healthCheckTargetKey') as EHealthCheckTargetKey;
    const healthCheckKey = this.route.snapshot.paramMap.get('healthcheckKey') as string;
    const facility$ = this.facilityService.getCurrentFacility(this.route);
    const installation$ = this.facilityService.getCurrentInstallation(this.route).pipe(first());
    const firmwareVersion$ = this.schematicService.getFirmwareVersion();

    this.healthCheckMetadata$ = this.healthCheckService.getMetadataForHealthCheck(healthCheckTargetKey, healthCheckKey);

    this.pageError$ = this.errorService.createPageErrorObservable([]);

    this.pageInfo$ = combineLatest([facility$, installation$, firmwareVersion$]).pipe(
      map(([facility, installation, firmwareVersion]) => {
        return this.pageInfoService.energyDashboard(facility, installation, firmwareVersion);
      })
    );
  }
}
