<gbc-modal [title]="'energy-management-dashboard.settings-button' | translate" (dismiss)='dismiss()' class="gbc-with-dividers" data-test-id='energy-management-dashboard-settings-modal'>
  <gbc-modal-content>
    <form [formGroup]='form'>
      <gbc-modal-section>
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'energy-management-dashboard.settings.emission-factor' | translate }}</gbc-input-label>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-field
            formControlName='emissionFactor'
            inputType='number'
            unit='Kg/MWh'
            min='0'
            [errorDescriptionTranslationString]="'energy-management-dashboard.settings.enter-positive-value'">
          </gbc-input-field>
        </gbc-modal-section-column>
      </gbc-modal-section>

      <gbc-modal-section *ngIf="form.get('tankVolume')">
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'energy-management-dashboard.settings.tank-volume' | translate }}</gbc-input-label>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-field
            formControlName='tankVolume'
            inputType='number'
            unit='m3'
            min='0'
            [errorDescriptionTranslationString]="'energy-management-dashboard.settings.enter-positive-value'">
          </gbc-input-field>
        </gbc-modal-section-column>
      </gbc-modal-section>

      <gbc-modal-section>
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'energy-management-dashboard.settings.supplied-area' | translate }}</gbc-input-label>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-field
            formControlName='suppliedArea'
            inputType='number'
            unit='m2'
            min='0'
            [errorDescriptionTranslationString]="'energy-management-dashboard.settings.enter-positive-value'">
          </gbc-input-field>
        </gbc-modal-section-column>
      </gbc-modal-section>

      <gbc-modal-section>
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'energy-management-dashboard.settings.last-year-delta-t' | translate }}</gbc-input-label>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-field
            formControlName='lastYearDeltaT'
            inputType='number'
            unit='°C'
            min='0'
            [errorDescriptionTranslationString]="'energy-management-dashboard.settings.enter-positive-value'">
          </gbc-input-field>
        </gbc-modal-section-column>
      </gbc-modal-section>

      <gbc-modal-section>
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'energy-management-dashboard.settings.last-year-energy-consumption' | translate }}</gbc-input-label>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-field
            formControlName='lastYearEnergyConsumption'
            inputType='number'
            unit='MWh'
            min='0'
            [errorDescriptionTranslationString]="'energy-management-dashboard.settings.enter-positive-value'">
          </gbc-input-field>
        </gbc-modal-section-column>
      </gbc-modal-section>

      <gbc-modal-section>
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'energy-management-dashboard.settings.delta-t-penalty-factor' | translate }}</gbc-input-label>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-field
            formControlName='deltaTPenaltyFactor'
            inputType='number'
            unit='€/°C/MWh'
            min='0'
            [errorDescriptionTranslationString]="'energy-management-dashboard.settings.enter-positive-value'">
          </gbc-input-field>
        </gbc-modal-section-column>
      </gbc-modal-section>
    </form>
  </gbc-modal-content>

  <gbc-modal-buttons>
    <button gbc-modal-button (click)='close()' angulartics2On='click' angularticsAction='cancelClicked' angularticsCategory='edbSettingsClicked'>
      {{'app-cancel' | translate}}
    </button>
    <button
      gbc-modal-button
      [disabled]='(isValid$ | async) === false'
      (click)='save()'
      class='primary'
      angulartics2On='click'
      angularticsAction='saveClicked'
      angularticsCategory='edbSettingsClicked'>
      {{'app-save' | translate}}
    </button>
  </gbc-modal-buttons>
</gbc-modal>
