import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UserTrackingHelperService } from '../../../services/user-tracking-helper.service';

@Component({
  selector: 'app-weather-curve-form',
  templateUrl: './weather-curve-form.component.html',
  styleUrls: ['./weather-curve-form.component.scss'],
})
export class WeatherCurveFormComponent implements OnInit, OnDestroy {
  @Input() initialCurve: Array<[number, number]>;
  @Output() currentCurve = new EventEmitter<Array<[number, number]>>();
  @Output() submitWeatherCurve = new EventEmitter<Array<[number, number]>>();
  @Output() cancel = new EventEmitter();

  public form = new UntypedFormGroup({
    maxTemperature: new UntypedFormControl(0, [Validators.required, Validators.min(20), Validators.max(120)]),
    minTemperature: new UntypedFormControl(false, [Validators.required, Validators.min(20), Validators.max(120)]),
    slope: new UntypedFormControl(false, [Validators.required, Validators.min(0), Validators.max(5)]),
  });

  private subscription: Subscription = new Subscription();
  private hasInitialValues: boolean = false;
  private initialFormValues: { maxTemperature: number; minTemperature: number; slope: number };

  constructor(private userTrackingHelperService: UserTrackingHelperService) {}

  ngOnInit(): void {
    if (this.initialCurve) {
      this.hasInitialValues = true;
      const initialMaxTemp = this.initialCurve[0][1];
      const initialMinTemp = this.initialCurve[1][1];
      const x1 = this.initialCurve[0][0];
      const initialSlope = Math.round(((initialMaxTemp - initialMinTemp) / (20 - x1)) * 100) / 100;
      this.initialFormValues = { maxTemperature: initialMaxTemp, minTemperature: initialMinTemp, slope: initialSlope };
      this.form.setValue(this.initialFormValues);
    }

    this.subscription.add(this.userTrackingHelperService.startFormValueChangeTracking('weatherCompensation', this.form));

    this.subscription.add(
      this.form.valueChanges
        .pipe(
          map(({ minTemperature, maxTemperature, slope }) => {
            if (this.form.valid && maxTemperature >= minTemperature) {
              return this.toCurve(minTemperature, maxTemperature, slope);
            } else {
              return null;
            }
          })
        )
        .subscribe((curve) => {
          if (curve) {
            this.currentCurve.emit(curve);
          }
        })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  submitForm() {
    const { minTemperature, maxTemperature, slope } = this.form.value;
    this.submitWeatherCurve.emit(this.toCurve(minTemperature, maxTemperature, slope));
  }

  cancelEditing() {
    if (this.hasInitialValues) {
      const { minTemperature, maxTemperature, slope } = this.initialFormValues;
      this.currentCurve.emit(this.toCurve(minTemperature, maxTemperature, slope));
    }
    this.cancel.emit();
  }

  toCurve(minTemperature: number, maxTemperature: number, slope: number): [number, number][] {
    if (slope === 0 || minTemperature === maxTemperature) {
      return [
        [-30, minTemperature],
        [20, minTemperature],
      ];
    } else {
      const x1 = Math.round(10 * (20 + (minTemperature - maxTemperature) / slope)) / 10;
      return [
        [x1, maxTemperature],
        [20, minTemperature],
      ];
    }
  }
}
