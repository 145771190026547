import { Component, Inject } from '@angular/core';
import { MODAL_CONTROLLER, ModalController } from 'shared';
import { UserTrackingHelperService } from '../../../services/user-tracking-helper.service';

export interface OverrideModalResult {
  comment: string;
}

// DEPRECATED: This component does not seem to be used anywhere.

@Component({
  selector: 'app-alarm-override-modal',
  templateUrl: './alarm-override-modal.component.html',
  styleUrls: ['./alarm-override-modal.component.scss']
})
export class AlarmOverrideModalComponent {

  public comment: string;

  public onSubmit() {
    this.controller.complete({comment: this.comment});
  }

  constructor(
    @Inject(MODAL_CONTROLLER)  private controller: ModalController<OverrideModalResult>,
    private userTrackingHelperService: UserTrackingHelperService
  ) { }

  dismiss() {
    this.userTrackingHelperService.trackUserAction('acknowledgeClicked', 'exitClicked');
  }

}
