import { Injectable } from '@angular/core';
import { Facility } from '../interfaces/facilty';
import { AlarmType } from '../interfaces/system-status';
import { ConnectionState } from '../interfaces/connectionState';
import { ECommissionStatus } from 'shared';

export enum MarkerState {
  Alarm = 'alarm',
  Warning = 'warning',
  Commissioning = 'commissioning',
  Offline = 'offline',
  AllGood = 'all-good',
  AllGoodList = 'all-good-list',
  Empty = 'empty',
  EmptyList = 'empty-list',
}

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private readonly iconClass: Record<MarkerState, string>;
  private readonly markerIcons: Record<MarkerState, string>;

  constructor() {
    this.iconClass = {
      [MarkerState.Alarm]: 'alarm',
      [MarkerState.Warning]: 'warning',
      [MarkerState.Commissioning]: 'commissioning',
      [MarkerState.Offline]: 'offline',
      [MarkerState.AllGood]: 'all-good',
      [MarkerState.AllGoodList]: 'all-good-list',
      [MarkerState.Empty]: 'empty',
      [MarkerState.EmptyList]: 'empty-list',
    };
    this.markerIcons = {
      [MarkerState.Alarm]: '<img alt="alarm" class="facility-icon-img" src="./assets/svgs/alarm.svg" />',
      [MarkerState.Warning]: '<img alt="warning" class="facility-icon-img warning-icon" src="./assets/svgs/warning.svg" />',
      [MarkerState.Commissioning]: '<img alt="commissioning" class="facility-icon-img commissioning-icon" src="./assets/svgs/map-commissioning.svg" />',
      [MarkerState.Offline]: '<img alt="offline" class="facility-icon-img offline-icon" src="./assets/svgs/disconnect_outline.svg" />',
      [MarkerState.AllGood]: '',
      [MarkerState.AllGoodList]: '<img alt="all good" class="facility-icon-img allgood-icon" src="./assets/svgs/all-good.svg" />',
      [MarkerState.Empty]: '',
      [MarkerState.EmptyList]: '<img alt="empty" class="facility-icon-img empty-icon" src="./assets/svgs/question-mark.svg" />',
    };
  }

  getIconClass = (markerState: MarkerState): string => {
    return this.iconClass[markerState];
  };

  getMarkerIcon = (markerState: MarkerState): string => {
    return this.markerIcons[markerState];
  };

  getMarkerState = (facilities: Facility[]): MarkerState => {
    // Alert + offline
    // Alert + Online
    // Offline
    // Warning
    // Commissioning
    // Normal

    const installations = facilities.flatMap((facility) => facility.installations.filter(installation => !installation.parentInstallationId));
    // Empty
    if (!installations.length) {
      return MarkerState.Empty;
    }
    // Alert
    if (installations.some((installation) => installation.state && installation.state.type === AlarmType.Alarm)) {
      return MarkerState.Alarm;
    }
    // Offline
    if (installations.some((installation) => installation.connectionState === ConnectionState.Disconnected)) {
      return MarkerState.Offline;
    }
    // Warning
    if (installations.some((installation) => installation.state && installation.state.type === AlarmType.Warning)) {
      return MarkerState.Warning;
    }
    // Commissioning
    if (installations.some((installation) => installation.commissionStatus !== ECommissionStatus.Live)) {
      return MarkerState.Commissioning;
    }
    // Normal
    return MarkerState.AllGood;
  };
}
