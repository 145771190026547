import { Observable, of, OperatorFunction } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { TimeSerie } from '../services/data-points.service';
import { SystemDeviceType } from '../interfaces/systemDeviceType';
import { SchematicsService } from '../services/schematics.service';
import { AppModel, EEquipment, EValueType, PasteurizationState, SystemState } from '@ams/dumbledore';
import {
  DataPointsResult,
  DeviceDataPoint,
  EBackendUnit,
  System,
  SystemDevice,
  FormatDataPointOptions,
  DataPointState,
  DataPointType,
} from '../interfaces/data-points';
import { TranslateService } from '@ngx-translate/core';

export const WEATHER_CURVE_MIN = -25;
export const WEATHER_CURVE_MAX = 25;

export const toFixed = (n: number) => {
  return (Math.round(n * 100) / 100).toString(10);
};

export const flattenDataPoints = (systems: System[]): DeviceDataPoint[] => {
  return systems.flatMap((system) => system.devices || []).flatMap((device) => device.dataPoints || []);
};

export const systemsToPumpDevice =
  (applicationId: string): OperatorFunction<DataPointsResult, SystemDevice | undefined> =>
  (source) =>
    source.pipe(
      map((dataPoints) =>
        dataPoints?.data
          .find((system) => system.systemId === applicationId)
          ?.devices.find((device) => device.type === SystemDeviceType.Pump)
      ),
      filter((device) => typeof device !== 'undefined')
    );

export const getPumpType = (schematic$: Observable<AppModel>, applicationId: string, property = 'P_type'): Observable<string> => {
  return schematic$.pipe(
    map((schematic) => {
      const system = schematic.systems.find((s) => s.hasId === applicationId) as SystemState;
      return (system.systemInfo as any)[property] as string;
    })
  );
};

export const getPumpByDataPoint = (system: System | undefined, dumbledoreId: EEquipment): SystemDevice | undefined => {
  if (!system) {
    return undefined;
  }
  return system.devices.find((device) => device.dataPoints.find((dataPoint) => dataPoint.dumbledoreId === dumbledoreId));
};

export const getPumpOptions = (
  pumpDevice$: Observable<SystemDevice | undefined>,
  schematicsService: SchematicsService,
  applicationId: string
) => {
  return pumpDevice$.pipe(
    switchMap((pump) => {
      if (!pump) {
        return of(null);
      }
      return schematicsService.getPumpConfiguration(applicationId, pump.deviceId);
    }),
    map((options) => options?.pumpControlOptions)
  );
};

// Filter on 'Actuator', since this is the valve device that contains setpoint information.
export const systemsToValveDevice =
  (applicationId: string): OperatorFunction<DataPointsResult, SystemDevice | undefined> =>
  (source) =>
    source.pipe(
      map((dataPoints) =>
        dataPoints?.data
          .find((system) => system.systemId === applicationId)
          ?.devices.find((device) => device.type === SystemDeviceType.Actuator)
      ),
      filter((device) => typeof device !== 'undefined')
    );

// TODO: Refactor translation support for units by separating value from unit (and use | translate in templates) [#226105].
export const formatDataPoint = (
  dataPoint: DeviceDataPoint | undefined | null,
  options: FormatDataPointOptions = { valueOnly: false, unitOnly: false },
  translateService?: TranslateService
): string => {
  if (!dataPoint) {
    return '-';
  }

  if (dataPoint.value === undefined || dataPoint.value === null || dataPoint.value === '') {
    return '-';
  }

  // Ensure that 'old' data are not shown in schematics, but keep showing unit (overwrite to support unit handling).
  if (dataPoint.state === DataPointState.NotSynced && dataPoint.type === DataPointType.Telemetry) {
    return '-';
  }

  // In case we are dealing with string values
  const parsedNumber = Number(dataPoint.value);
  if (isNaN(parsedNumber)) {
    return dataPoint.value;
  }

  let value = '';
  let unit = '';

  switch (dataPoint.unitType) {
    case EBackendUnit.DegreeCelsius:
      if (dataPoint.valueType === EValueType.setPoint && parsedNumber < 0) {
        value = 'In standby'; // If we have a negative setpoint, show standby
        break;
      }
      unit = `°C`;
      value = `${parsedNumber.toFixed(1)}`;
      break;
    case EBackendUnit.CubicMeterPerHour:
      unit = `m³/h`;
      const isKamstrupHeatMeterFlowDataPoint =
        dataPoint.humanReadableId?.indexOf('HMKamstrup') !== -1 && dataPoint.humanReadableId?.startsWith('Q_');
      // Need 2 decimals for Kamstrup and 1 for pumps
      if (isKamstrupHeatMeterFlowDataPoint) {
        value = `${parsedNumber < 0.01 ? '<0.01' : parsedNumber.toFixed(2)}`;
      } else {
        value = `${parsedNumber < 0.1 ? '<0.1' : parsedNumber.toFixed(1)}`;
      }
      break;
    case EBackendUnit.Percentage:
      unit = `%`;
      value = `${parsedNumber.toFixed(0)}`;
      break;
    case EBackendUnit.WattHour:
      if (parsedNumber > 1000000) {
        unit = `MWh`;
        value = `${(parsedNumber / 1000000).toFixed(3)}`;
      } else if (parsedNumber > 1000) {
        unit = `kWh`;
        value = `${(parsedNumber / 1000).toFixed(3)}`;
      } else {
        unit = `Wh`;
        value = `${parsedNumber.toFixed(0)}`;
      }
      break;
    case EBackendUnit.Watt:
      if (parsedNumber > 1000) {
        unit = 'kW';
        value = `${(parsedNumber / 1000).toFixed(1)}`;
      } else {
        unit = 'W';
        value = `${parsedNumber.toFixed(0)}`;
      }
      break;
    case EBackendUnit.KiloWattHour:
      unit = 'kWh';
      value = `${parsedNumber.toFixed(1)}`;
      break;
    case EBackendUnit.MegaWattHour:
      unit = `MWh`;
      value = `${parsedNumber.toFixed(1)}`;
      break;
    case EBackendUnit.KiloWatt:
      unit = `kW`;
      value = `${parsedNumber.toFixed(1)}`;
      break;
    case EBackendUnit.MegaWatt:
      unit = `MW`;
      value = `${parsedNumber.toFixed(1)}`;
      break;
    case EBackendUnit.Pascal:
      unit = `Pa`;
      value = `${parsedNumber.toFixed(1)}`;
      break;
    case EBackendUnit.Joule:
      unit = `J`;
      value = `${parsedNumber.toFixed(1)}`;
      break;
    case EBackendUnit.DegreeKelvin:
      unit = `K`;
      value = `${parsedNumber.toFixed(1)}`;
      break;
    case EBackendUnit.MeterHead:
      unit = `m`;
      value = `${parsedNumber.toFixed(1)}`;
      break;
    case EBackendUnit.Bar:
      unit = `bar`;
      value = `${parsedNumber.toFixed(1)}`;
      break;
    case EBackendUnit.MilliBar:
      unit = `mbar`;
      value = `${parsedNumber.toFixed(1)}`;
      break;
    case EBackendUnit.CubicMeter:
      unit = `m³`;
      value = `${parsedNumber.toFixed(1)}`;
      break;
    case EBackendUnit.CubicMeterPerSecond:
      unit = `m³/s`;
      value = `${parsedNumber.toFixed(1)}`;
      break;
    case EBackendUnit.Day:
      // TODO: Refactor translation support for units by separating value from unit (and use | translate in templates).
      const dayString = translateService ? translateService.instant("app-unit.day") : "day";
      const daysString = translateService ? translateService.instant("app-unit.days") : "days";
      const translatedUnit = (parsedNumber === 1) ? dayString : daysString;
      value = `${parsedNumber.toFixed(0)}`;
      unit = translatedUnit;
      break;
    case EBackendUnit.Second:
      unit = `s`;
      value = `${parsedNumber.toFixed(1)}`;
      break;
    case EBackendUnit.Hertz:
      unit = 'RPM';
      value = `${(parsedNumber * 60).toFixed(0)}`;
      break;
    case EBackendUnit.RevolutionsPerMinute:
      unit = `RPM`;
      value = `${parsedNumber.toFixed(1)}`;
      break;
    case EBackendUnit.Euro:
      unit = `€`;
      value = `${parsedNumber.toFixed(2)}`;
      break;
    case EBackendUnit.KiloWattHourSquareMeter:
      unit = `kWh/㎡`;
      value = `${parsedNumber.toFixed(2)}`;
      break;
    case EBackendUnit.MetricTon:
      unit = `Tons`;
      value = `${parsedNumber.toFixed(2)}`;
      break;
    case EBackendUnit.Fraction: // We will not receive datapoints in fraction for now
    default:
      unit = ``;
      value = `${parsedNumber.toFixed(2)}`;
  }

  if (options.unitOnly) {
    return unit;
  }

  if (options.valueOnly) {
    return value;
  }

  return [value, unit].join(' ');
};

export const formatDataPointValue = (value: string, fractionDigits: number, unit: string): string => {
  const parsedNumber = Number.parseFloat(value);
  if (isNaN(parsedNumber)) {
    return '-';
  }
  return `${parsedNumber.toFixed(fractionDigits)} ${unit}`;
};

interface GetDataPoint {
  applicationId: string;
  systems: System[];
  dumbledoreId?: EEquipment | null;
  humanReadableId?: string;
  clientId?: string;
  systemType?: SystemDeviceType;
}
export const getDataPoint = ({
  applicationId,
  systems,
  dumbledoreId,
  humanReadableId,
  clientId,
  systemType,
}: GetDataPoint): DeviceDataPoint | null => {
  const system = systems.find((s) => s.systemId === applicationId);
  const devices = systemType ? system?.devices.filter((d) => d.type === systemType) : system?.devices;
  const allDataPoints = devices?.flatMap((d) => d?.dataPoints) || [];
  return (
    allDataPoints.find((dataPoint) => {
      if (dumbledoreId) {
        return dataPoint.dumbledoreId === dumbledoreId;
      }

      if (humanReadableId) {
        return dataPoint?.humanReadableId === humanReadableId;
      }

      if (clientId) {
        return dataPoint.clientId === clientId;
      }

      return null;
    }) || null
  );
};

export const getApplicationSetpointFromClientId = ({ applicationId, systems, clientId } : { applicationId: string; systems: System[]; clientId: string }): DeviceDataPoint | null => {
  const system = systems.find((s) => s.systemId === applicationId);
  const allDataPoints = system?.devices?.flatMap((device) => device?.dataPoints) || [];
  return (
    allDataPoints.find((dataPoint) => {
      return dataPoint.valueType === EValueType.setPoint && dataPoint.clientId == clientId;
    }) || null
  );
};

export const getSetPointForApplication = ({ applicationId, systems, clientId } : { applicationId: string; systems: System[]; clientId?: string }): DeviceDataPoint | null => {
  const system = systems.find((s) => s.systemId === applicationId);
  const allDataPoints = system?.devices?.flatMap((d) => d?.dataPoints) || [];
  return (
    allDataPoints.find((dataPoint) => {
      // Added second filter to ensure avoid issues with valve setpoints.
      return dataPoint.valueType === EValueType.setPoint && dataPoint.dumbledoreId.indexOf('T_') !== -1;
    }) || null
  );
};

// We use this function to extend the last two points on a heat curve. If not, the curve stops and does not represent the actual real world curve.
export function extendWeatherCurveDataPoints(curve: Array<[number, number]>): Array<[number, number]> {
  if (!curve || curve.length === 0) {
    return [];
  }

  if (curve[0][0] > WEATHER_CURVE_MIN) {
    curve = [[WEATHER_CURVE_MIN, curve[0][1]], ...curve];
  }
  curve = [...curve, [WEATHER_CURVE_MAX, curve[curve.length - 1][1]]];
  return curve;
}

export function isNumber(value: string) {
  return !Number.isNaN(Number.parseFloat(value));
}

export function parseBoolean(stringValue: string | undefined | null): boolean {
  return stringValue === 'true';
}

export function convertCubicMeterPerSecondToCubicMeterPerHour(data: TimeSerie) {
  if (!data) {
    return [];
  }

  return data.map((d) => {
    const timestamp = d[0];
    const value = d[1];
    return [timestamp, value === null ? null : value * 3600];
  });
}

export function convertPascalToMeter(data: TimeSerie) {
  return data.map((d) => {
    const timestamp = d[0];
    const value = d[1];
    return [timestamp, value === null ? null : value * 0.00010199773339984];
  });
}

export function convertWtoKW(data: TimeSerie) {
  return data.map((d) => {
    const val = d[1] !== null ? d[1] / 1000 : null;
    return [d[0], val];
  });
}

export const getDataOutdatedState = (datapoint: DeviceDataPoint | undefined | null): DataPointState | undefined => {
  return datapoint && datapoint?.value && datapoint?.state === DataPointState.NotSynced ? DataPointState.NotSynced : undefined;
};

export const getPasteurizationState = (data: System): PasteurizationState | undefined => {
  for (const device of data.devices) {
    if (device.type === SystemDeviceType.PasteurizationState) {
      // We know that there's 1 datapoint for a pasteurization state device
      const dataPoint = device.dataPoints[0];
      // If data point value is null or 0 we return undefined
      // Otherwise, we sanitize the value (strip it of decimals) and return it as a PasteurizationState
      const value = +(dataPoint.value ?? '0');
      return value === 0 ? undefined : value.toString() as PasteurizationState;
    }
  }
  return undefined;
}
