import { Component, Input, OnInit } from '@angular/core';
import { filter, first, switchMap, map } from 'rxjs/operators';
import { zip, uniq } from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { Options, SeriesOptionsType } from 'highcharts';
import { getDataPoint } from '../../../utils/data-point-utils';
import { DataPointsService, ReportPeriod, Resolution, TimeSerie, TimeSeriesDetails } from '../../../services/data-points.service';
import { DataPointsResult } from '../../../interfaces/data-points';
import { EEquipment } from '@ams/dumbledore';
import { PeriodOption } from '../../../interfaces/chart';
import { UserTrackingPeriodOptions } from '../../../services/user-tracking-helper.service';
import { SystemDeviceType } from '../../../interfaces/systemDeviceType';

export enum SerieType {
  DataPoint = 'DataPoint',
  DeltaT = 'DeltaT',
}

export interface DataPointSerie {
  type: SerieType.DataPoint;
  title: string;
  dumbledoreId: EEquipment | null;
  humanReadableId?: string;
  systemType?: SystemDeviceType;
  color?: string;
}

export interface DeltaTSerie {
  type: SerieType.DeltaT;
  title?: string;
  forwardDumbledoreId: string;
  returnDumbledoreId: string;
}

export type Serie = DataPointSerie | DeltaTSerie;

@Component({
  selector: 'app-temperature-chart-tile',
  templateUrl: './temperature-chart-tile.component.html',
  styleUrls: ['./temperature-chart-tile.component.scss'],
})
export class TemperatureChartTileComponent implements OnInit {
  @Input() title: string;
  @Input() applicationId: string;
  @Input() series: Serie[];
  @Input() public periodOptions: PeriodOption[];
  @Input() public chartOptions: Options;
  @Input() userTrackingPeriodOptions: UserTrackingPeriodOptions;
  @Input() public colors: string[] = ['#E43535', '#0069C4', '#00A650'];
  @Input() public dataPoints$: Observable<DataPointsResult>;

  public defaultOptions: Options;
  public visible$: Observable<boolean>;
  public getSeries: (period: ReportPeriod) => Observable<SeriesOptionsType[]>;

  constructor(private dataPointsService: DataPointsService) {}

  ngOnInit(): void {
    this.defaultOptions = {
      legend: {
        enabled: true,
      },
      colors: this.colors,
      yAxis: {
        title: {
          text: null,
        },
        labels: {
          format: '{value} \u00b0C',
        },
        min: 0,
        softMax: 100,
        ceiling: 200,
      },
    };
    // observable for just the data point series, (not including deltaT serie)
    const dataPointSeries = this.series.filter((serie) => serie.type === SerieType.DataPoint) as DataPointSerie[];
    const dataPoints$ = this.dataPoints$.pipe(
      filter((dataPoints) => !!dataPoints?.data?.length),
      map((dataPoints) => {
        return dataPointSeries.map((serie) => {
          return getDataPoint({
            applicationId: this.applicationId,
            systems: dataPoints.data,
            dumbledoreId: serie.dumbledoreId,
            humanReadableId: serie.humanReadableId || '',
            systemType: serie.systemType,
          });
        });
      }),
      first()
    );

    this.visible$ = dataPoints$.pipe(map((dataPoints) => dataPoints.some((dataPoint) => dataPoint)));

    const datapointsResult = this.dataPoints$.pipe(first());

    this.getSeries = (period): Observable<SeriesOptionsType[]> => {
      return combineLatest([dataPoints$, datapointsResult]).pipe(
        switchMap(([dataPoints, dataPointsResult]) => {
          if (!dataPoints) {
            return [];
          }
          const timeSeriesDetails: TimeSeriesDetails = {
            dataPoints,
            period,
            installationId: dataPointsResult.installationId,
            systemId: this.applicationId,
            requestId: this.title.replace(/\W+/g, '_').toLowerCase(),
          };
          return this.dataPointsService.getTimeSeries(timeSeriesDetails).pipe(
            map((timeSeries): SeriesOptionsType[] => {
              const sharedOptions = {
                type: 'line',
                showInLegend: true,
                tooltip: {
                  valueSuffix: ' \u00b0C',
                  valueDecimals: 1,
                },
              };
              // If there's a datapoint that we cannot find, we find the index, and filter it out, so it's not shown
              const dataPointIndexesNotFound = dataPoints.flatMap((d, i) => (!d ? [i] : []));

              const filteredDataPointSeries = dataPointSeries.filter((_, i) => !dataPointIndexesNotFound.includes(i));

              const dumbleDoreIdToTimeSeriesMap = zip(filteredDataPointSeries, timeSeries).reduce(
                (memo: { [k: string]: TimeSerie }, [dataPointSerie, timeSerie]) => {
                  memo[(dataPointSerie as DataPointSerie).dumbledoreId as string] = timeSerie as TimeSerie;
                  return memo;
                },
                {}
              );

              const dataSeries: SeriesOptionsType[] = this.series.flatMap((serie) => {
                if (serie.type === SerieType.DataPoint) {
                  const dumbledoreId = serie.dumbledoreId;
                  if (!dumbledoreId) {
                    return [{ name: serie.title, data: [], ...sharedOptions, color: serie.color } as SeriesOptionsType];
                  }
                  const timeSerie = dumbleDoreIdToTimeSeriesMap[dumbledoreId];
                  if (!timeSerie || timeSerie.length === 0) {
                    return [{ name: serie.title, data: [], ...sharedOptions, color: serie.color } as SeriesOptionsType];
                  } else {
                    return [{ name: serie.title, data: timeSerie, ...sharedOptions, color: serie.color } as SeriesOptionsType];
                  }
                } else if (serie.type === SerieType.DeltaT) {
                  const forwardTimeSerie = dumbleDoreIdToTimeSeriesMap[serie.forwardDumbledoreId];
                  const returnTimeSerie = dumbleDoreIdToTimeSeriesMap[serie.returnDumbledoreId];
                  if (forwardTimeSerie && returnTimeSerie && period.resolution !== Resolution.Seconds) {
                    const deltaTTimeSerie = this.calculateDeltaT(forwardTimeSerie, returnTimeSerie);
                    return [{ name: serie.title || 'ΔT', data: deltaTTimeSerie, ...sharedOptions, color: '#00A650' } as SeriesOptionsType];
                  } else {
                    return [{ name: serie.title || 'ΔT', data: [], ...sharedOptions, color: '#00A650' } as SeriesOptionsType];
                  }
                } else {
                  return [];
                }
              });
              return dataSeries;
            })
          );
        })
      );
    };
  }

  calculateDeltaT(supplyData: TimeSerie, returnData: TimeSerie): TimeSerie {
    const allXValues = uniq([...supplyData.map(([x]) => x), ...returnData.map(([x]) => x)]).sort();
    const supplyValues = supplyData.reduce((acc: { [k: string]: number | null }, [x, y]) => {
      acc[x] = y;
      return acc;
    }, {});
    const returnValues = returnData.reduce((acc: { [k: string]: number | null }, [x, y]) => {
      acc[x] = y;
      return acc;
    }, {});
    return allXValues.map((x) => {
      const supplyValue = supplyValues[x];
      const returnValue = returnValues[x];
      if (supplyValue !== null && returnValue !== null) {
        return [x, supplyValue - returnValue];
      }
      return [x, null];
    });
  }
}
