import { Component } from '@angular/core';
import { BehaviorSubject, concat, Observable, Subscription } from 'rxjs';
import { AppError } from '../../services/app-error.service';

// The PageSpinnerComponent is responsible for displaying a spinner while
// the page is loading. It cooperates with PageErrorHandlerComponent.
@Component({
  selector: 'gbc-page-spinner',
  templateUrl: './page-spinner.component.html',
  styleUrls: ['./page-spinner.component.scss']
})
export class PageSpinnerComponent {

  private observables: Observable<AppError>[] = [];
  public loading$ = new BehaviorSubject(false);
  private subscription: Subscription;

  private resubscribe() {
    // setTimeout needed because apparently the push/pops from PageErrorHandlerComponent happens in the wrong phase.
    setTimeout(() => {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      if (this.observables.length > 0) {
        this.loading$.next(true);
        this.subscription = concat(...this.observables).subscribe({
          complete: () => this.loading$.next(false)
        });
      }
    });
  }

  public push(observable: Observable<AppError>) {
    this.observables.push(observable);
    this.resubscribe();
  }

  public pop() {
    this.observables.pop();
    this.resubscribe();
  }
}
