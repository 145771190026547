<div class="tile">
  <div class="tile-header" *ngIf="title">
    <div class="title">
      {{title}}
      <ng-content select="[gbc-tile-left]"></ng-content>
    </div>
    <div class="right-hand-content">
      <ng-content select="[gbc-tile-right]"></ng-content>
    </div>
  </div>
  <ng-content></ng-content>
</div>
