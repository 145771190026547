<form [formGroup]="form" (ngSubmit)="submitForm()">
  <gbc-input-container>
    <gbc-input-label>{{ 'weather-curve-form.max-temp-label' | translate }} </gbc-input-label>
    <gbc-input-field
      formControlName="maxTemperature"
      inputType="number"
      [min]="20"
      [max]="120"
      errorDescriptionTranslationString="weather-curve-form.invalid-max-temp"
    >
    </gbc-input-field>
  </gbc-input-container>
  <gbc-input-container>
    <gbc-input-label>{{ 'weather-curve-form.min-temp-label' | translate }}</gbc-input-label>
    <gbc-input-field
      formControlName="minTemperature"
      inputType="number"
      [min]="20"
      [max]="120"
      errorDescriptionTranslationString="weather-curve-form.invalid-min-temp"
    ></gbc-input-field>
  </gbc-input-container>
  <gbc-input-container>
    <gbc-input-label>{{ 'weather-curve-form.slope-label' | translate }}</gbc-input-label>
    <gbc-input-field
      formControlName="slope"
      inputType="number"
      step="0.1"
      [min]="0"
      [max]="5"
      errorDescriptionTranslationString="weather-curve-form.invalid-slope"
    ></gbc-input-field>
  </gbc-input-container>
  <div class="buttons">
    <button
      gbc-button
      (click)="cancelEditing()"
      angulartics2On="click"
      angularticsAction="cancelClicked"
      angularticsCategory="weatherCompensation"
    >
      {{ 'app-cancel' | translate }}
    </button>
    <button
      gbc-button
      type="submit"
      secondary
      [disabled]="!form.valid"
      angulartics2On="click"
      angularticsAction="saveClicked"
      angularticsCategory="weatherCompensation"
    >
      {{ 'app-save' | translate }}
    </button>
  </div>
</form>
