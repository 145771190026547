import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MODAL_CONTROLLER, MODAL_DATA, ModalController } from 'shared';
import { DeviceDataPoint } from '../../interfaces/data-points';
import { UserTrackingHelperService } from '../../services/user-tracking-helper.service';
import { SystemControlMode } from '../../interfaces/system-control-mode';
import { DataPointsService } from '../../services/data-points.service';

export interface ValveModalInput {
  applicationId: string;
  installationId: string;
  openingSetpoint: DeviceDataPoint | null;
  max?: number;
  min?: number;
}

export interface ValveModalResponse {
  openingSetpoint: number;
  selectedSystemControlMode: SystemControlMode;
}

@Component({
  selector: 'app-valve-modal',
  templateUrl: './valve-modal.component.html',
  styleUrls: ['./valve-modal.component.scss'],
})
export class ValveModalComponent implements OnInit, OnDestroy {
  public min: number;
  public max: number;
  public form: FormGroup;
  public setpoint?: number;
  public isValid$: Observable<boolean>;
  private subscription = new Subscription();
  public SystemControlMode = SystemControlMode;
  currentSystemControlMode: SystemControlMode;
  selectedSystemControlMode: SystemControlMode;
  subscribedSystemControlMode: Subscription;

  constructor(
    @Inject(MODAL_DATA) public data: ValveModalInput,
    @Inject(MODAL_CONTROLLER) private controller: ModalController<ValveModalResponse>,
    private dataPointsService: DataPointsService,
    private userTrackingHelperService: UserTrackingHelperService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.min = this.data.min ? this.data.min : 0;
    this.max = this.data.max ? this.data.max : 100;
    this.setpoint = parseInt(Number(this.data.openingSetpoint?.value).toFixed(0));
    if (isNaN(this.setpoint)) {
      this.setpoint = undefined;    // Necessary to trigger the Required validator (as it considers NaN to be a valid value)
    }

    this.subscribedSystemControlMode = this.dataPointsService.getSystemControlMode(this.data.applicationId).subscribe((event) => {
      this.currentSystemControlMode = event;
    });
    this.selectedSystemControlMode = this.currentSystemControlMode;

    this.form = this.formBuilder.group({
      openingSetpoint: [{ value: this.setpoint, disabled: this.isAutoModeEnabled() || this.selectedSystemControlMode === SystemControlMode.Unknown }, [
        Validators.required,
        Validators.min(this.min),
        Validators.max(this.max),
      ]],
    });

    this.subscription.add(this.userTrackingHelperService.startFormValueChangeTracking('valveSetpointDialog', this.form));

    this.isValid$ = this.form.valueChanges.pipe(
      map((value) => {
        if (this.isAutoModeEnabled()) return true;
        return this.form.valid;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.subscribedSystemControlMode.unsubscribe();
  }

  close() {
    this.userTrackingHelperService.trackUserAction('valveSetpointDialog', 'closeClicked');
    this.controller.dismiss();
  }

  save() {
    this.controller.complete({
      openingSetpoint: this.form.controls.openingSetpoint.value,
      selectedSystemControlMode: this.selectedSystemControlMode
    });
  }

  dismiss() {
    this.userTrackingHelperService.trackUserAction('valveSetpointDialog', 'exitClicked');
  }

  isAutoModeEnabled(): boolean {
    return this.selectedSystemControlMode === SystemControlMode.Auto;
  }

  selectApplicationControlMode(selectedApplicationControlMode: SystemControlMode) {
    this.selectedSystemControlMode = selectedApplicationControlMode;
    if (this.isAutoModeEnabled()) {
      this.form.controls.openingSetpoint.disable();
    } else {
      this.form.controls.openingSetpoint.enable();
    }
  }
}
