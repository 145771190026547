import { Component, OnInit, Inject } from '@angular/core';
import { LanguageService } from 'projects/shared/src/lib/services/language.service';
import { MODAL_CONTROLLER, ModalController } from 'projects/shared/src/lib/interfaces/modal';
import { Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { languagesVisibleInFronted } from '../../../../../../../../translations/sources';

@Component({
  selector: 'gbc-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss']
})
export class LanguagePickerComponent {
  public currentLanguage$: Observable<string>;
  public languages$: Observable<{name: string, languageCode: string}[]>;

  // These languages are hardcoded on purpose
  private allLanguages: {name: string, languageCode: string}[] = languagesVisibleInFronted;

  constructor(
    private languageService: LanguageService,
    @Inject(MODAL_CONTROLLER)  private controller: ModalController<void>,
  ) {
    this.currentLanguage$  = this.languageService.currentLanguage.asObservable().pipe(
      filter(l => !!l),
      map(l => l as string)
    );

    this.languages$ = this.languageService.languages$.pipe(
      first(),
      map(languages => {
        return this.allLanguages.filter(a => languages.includes(a.languageCode));
      })
    );
  }

  chooseLanguage(language: string) {
    this.languageService.setLanguageLocallyAndRemotely(language);
    this.controller.dismiss();
    /**
     * Due to the use of "this.translateService.get('key')" this is a simple way to just reload the page
     * and all translations get updated to use the selected language.
     */
    window.location.reload();
  }

  dismiss() {
    this.controller.dismiss();
  }

}
