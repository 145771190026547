import { Pipe, PipeType } from '../../Pipe';
import { colors } from '../../../../util/colors';
import { EConnectionType } from '../../../state';
import * as metaData from './indirect-district-heating-supply-metadata';
import { Strainer } from '../../Strainer';
import React, { FunctionComponent } from 'react';

type DirtCollector = {
  location: 'PrimaryForward' | 'PrimaryReturn';
};

export const DirtCollectorComponent: FunctionComponent<DirtCollector> = ({ location }) => {
  const onForward = location === 'PrimaryForward';
  const pipeType = onForward ? PipeType.WarmSupply : PipeType.ColdReturn;
  const strainerColor = onForward ? colors.connections[EConnectionType.supply].regular : colors.connections[EConnectionType.return].regular;
  return (
    <g transform={`translate(0, ${onForward ? 0 : 100})`}>
      <Pipe
        key="left"
        x1={metaData.STRAINER_X - 10}
        y1={metaData.SUPPLY_Y}
        x2={metaData.STRAINER_X - 10}
        y2={metaData.SUPPLY_Y - 30}
        type={pipeType}
      />
      <Pipe
        key="right"
        x1={metaData.STRAINER_X + 70}
        y1={metaData.SUPPLY_Y}
        x2={metaData.STRAINER_X + 70}
        y2={metaData.SUPPLY_Y - 30}
        type={pipeType}
      />
      <Pipe
        key="top"
        x1={metaData.STRAINER_X - 10}
        y1={metaData.SUPPLY_Y - 30}
        x2={metaData.STRAINER_X + 70}
        y2={metaData.SUPPLY_Y - 30}
        type={pipeType}
      />
      <Pipe
        key="vertical-top"
        x1={metaData.STRAINER_X + 30}
        x2={metaData.STRAINER_X + 30}
        y1={metaData.STRAINER_Y - 15}
        y2={metaData.STRAINER_Y - 25}
        type={pipeType}
      />
      <circle cy={metaData.SUPPLY_Y - 45} cx={metaData.STRAINER_X + 30} r={10} fill={colors.background} stroke="black" strokeWidth="1.5" />
      <text y={metaData.SUPPLY_Y - 45} x={metaData.STRAINER_X + 30} dominantBaseline="middle" textAnchor="middle" fontSize="12">
        {'\u0394P'}
      </text>
      <Strainer fill={strainerColor} x={metaData.STRAINER_X} y={metaData.STRAINER_Y} />
    </g>
  );
};
