import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { Options, SeriesOptionsType } from 'highcharts';
import { catchError, first, shareReplay, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ReportPeriod, Resolution } from '../../../services/data-points.service';
import moment from 'moment';
import { FilterOption } from 'shared';
import { PeriodOption } from '../../../interfaces/chart';
import { UserTrackingPeriodOptions, UserTrackingHelperService } from '../../../services/user-tracking-helper.service';

@Component({
  selector: 'app-chart-tile',
  templateUrl: './chart-tile.component.html',
  styleUrls: ['./chart-tile.component.scss'],
})
export class ChartTileComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() options: Options;
  @Input() getSeries: (period: ReportPeriod) => Observable<SeriesOptionsType[]>;
  @Input() isMixit: boolean;
  @Input() periodOptions: PeriodOption[];
  @Input() userTrackingPeriodOptions: UserTrackingPeriodOptions;

  public series$: Observable<SeriesOptionsType[]>;
  public filterOptions: FilterOption<PeriodOption>[];
  public currentPeriod$ = new ReplaySubject<PeriodOption>(1);
  public spinner = true;
  private subscription: Subscription;
  public error = false;
  public errorMessage = '';

  private defaultPeriodOptions: PeriodOption[] = [
    {
      name: this.translateService.get('chart-tile.4-hours'),
      period: {
        duration: moment.duration(4, 'hour').asMilliseconds(),
        resolution: Resolution.Minutes,
      },
    },
    {
      name: this.translateService.get('chart-tile.48-hours'),
      period: {
        duration: moment.duration(48, 'hour').asMilliseconds(),
        resolution: Resolution.Minutes,
      },
    },
    {
      name: this.translateService.get('chart-tile.week'),
      period: {
        duration: moment.duration(7, 'days').asMilliseconds(),
        resolution: Resolution.Hours,
      },
    },
    {
      name: this.translateService.get('chart-tile.month'),
      period: {
        duration: moment.duration(30, 'days').asMilliseconds(),
        resolution: Resolution.Hours,
      },
    },
    {
      name: this.translateService.get('chart-tile.year'),
      period: {
        duration: moment.duration(1, 'year').asMilliseconds(),
        resolution: Resolution.Days,
      },
    },
  ];

  selectPeriod(option: PeriodOption, trackSelection: boolean = true) {
    this.spinner = true;
    this.error = false;
    this.currentPeriod$.next(option);

    if (trackSelection && this.userTrackingPeriodOptions) {
      option.name
        .pipe(
          first(),
          tap((periodName) =>
            this.userTrackingHelperService.trackPeriodSelected(
              this.userTrackingPeriodOptions.category,
              this.userTrackingPeriodOptions.intervalPrefix,
              periodName
            )
          )
        )
        .subscribe();
    }
  }

  constructor(private translateService: TranslateService, private userTrackingHelperService: UserTrackingHelperService) {}

  ngOnInit(): void {
    const displayedPeriodOptions = this.periodOptions ?? this.defaultPeriodOptions;
    const defaultSelectedPeriod = displayedPeriodOptions.findIndex((period) => period.isDefault);

    this.selectPeriod(this.getDisplayedPeriod(defaultSelectedPeriod, displayedPeriodOptions), false);

    this.filterOptions = displayedPeriodOptions.map((periodOption) => ({
      name: periodOption.name,
      value: periodOption,
    }));

    this.series$ = this.currentPeriod$.pipe(
      switchMap((periodOption) => {
        // We need to handle the potential error directly on the call to the backend, as the series$ will unsubscribe after an error is thrown
        return this.getSeries(periodOption.period).pipe(
          tap(() => {
            this.spinner = false;
          }),
          catchError((e) => {
            this.spinner = false;
            this.error = true;
            if (e.status === 429) {
              this.errorMessage = 'chart-tile.rate-limiting-error';
            } else {
              this.errorMessage = 'chart-tile.error-message';
            }
            return [];
          })
        );
      }),
      shareReplay(1)
    );
    this.subscription = this.series$.subscribe();
  }

  private getDisplayedPeriod(defaultSelectedPeriod: number, displayedPeriodOptions: PeriodOption[]) {
    return defaultSelectedPeriod > 0 ? displayedPeriodOptions[defaultSelectedPeriod] : displayedPeriodOptions[3];
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
