import { Component, Input, OnInit } from '@angular/core';
import { Installation } from '../../../interfaces/facilty';
import { Observable } from 'rxjs';
import { AppModel, HeatExchangerSystemState } from '@ams/dumbledore';
import { AlarmOverview, Application } from '../../../interfaces/alarm';
import { DataPointsResult, SystemDevice } from '../../../interfaces/data-points';
import { filter, map } from 'rxjs/operators';
import { systemsToValveDevice } from '../../../utils/data-point-utils';
import { systemHasEnergyValve } from 'projects/dumbledore/src/schematic/util/system-properties-util';

@Component({
  selector: 'app-heat-exchanger-dashboard',
  templateUrl: './heat-exchanger-dashboard.component.html',
  styleUrls: ['./heat-exchanger-dashboard.component.scss'],
})
export class HeatExchangerDashboardComponent implements OnInit {
  @Input() public alarm$: Observable<AlarmOverview>;
  @Input() public application: Application;
  @Input() public installation$: Observable<Installation>;
  @Input() public schematic$: Observable<AppModel>;
  @Input() public dataPoints$: Observable<DataPointsResult>;

  public hasEnergyValve$: Observable<boolean | undefined>;
  public hasPrimaryTemperatureSensors$: Observable<boolean | undefined>;
  public weatherCompensationEnabled$: Observable<boolean>;
  public outdoorTemp$: Observable<number | null>;
  public valveDevice$: Observable<SystemDevice | undefined>;
  private system$: Observable<HeatExchangerSystemState>;

  constructor() {}

  ngOnInit(): void {
    this.system$ = this.schematic$.pipe(
      map((schematic) => {
        return schematic.systems.find((s) => s.hasId === this.application.id) as HeatExchangerSystemState;
      })
    );

    this.valveDevice$ = this.dataPoints$.pipe(systemsToValveDevice(this.application.id));

    this.weatherCompensationEnabled$ = this.system$.pipe(
      map((system) => {
        return system?.systemInfo?.Weather_compensation_enabled || false;
      })
    );

    this.outdoorTemp$ = this.dataPoints$.pipe(
      filter((d) => !!d?.data),
      map((dataPoints) => {
        const allDataPoints = dataPoints.data.flatMap((s) => s.devices).flatMap((d) => d.dataPoints);
        const outdoorTempDataPoint = allDataPoints.find((dataPoint) => dataPoint.dumbledoreId === 'T_OUTDOOR');
        return outdoorTempDataPoint?.value ? parseInt(outdoorTempDataPoint.value, 10) : null;
      })
    );

    this.hasEnergyValve$ = this.system$.pipe(
      map((system) => {
        return systemHasEnergyValve(system?.systemInfo);
      })
    );

    this.hasPrimaryTemperatureSensors$ = this.system$.pipe(
      map((system) => {
        return (system?.systemInfo.T_PF_present || system?.systemInfo.T_PR_present || system?.systemInfo.HM_present);
      })
    );
  }
}
