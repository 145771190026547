import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlarmOverview } from '../../interfaces/alarm';

@Component({
  selector: 'app-installation-system-status',
  templateUrl: './installation-system-status.component.html',
  styleUrls: ['./installation-system-status.component.scss']
})
export class InstallationSystemStatusComponent {

  @Input()
  public showSettings: boolean = false;

  @Output()
  public alarmClick = new EventEmitter<AlarmOverview>();

  @Output()
  public settingsClick = new EventEmitter<void>();

  @Output()
  public showAllAlarms = new EventEmitter<void>();

  public alarmFilter: (alarm: AlarmOverview) => boolean = alarm => {
    const activation = alarm.currentActivation;
    return !activation.clearedTimestampEpoch;
  };
}
