<gbc-page-error-handler [content]="content" [pageError$]="pageError$"></gbc-page-error-handler>

<ng-template #content>
    <ng-container *ngIf="users$ | async as users">

        <gbc-page-header [pageInfo]="pageInfo">
            <ng-container gbc-page-header-right>
                <button gbc-button
                        [routerLink]="['/create-user']">{{'users-page.create-user' | translate}}</button>
            </ng-container>
        </gbc-page-header>

        <gbc-page-container>
            <gbc-tile [title]="'users-page.users' | translate">
                <app-user-role-explainer-toggle-link gbc-tile-right></app-user-role-explainer-toggle-link>
                <gbc-table clickableRows="true">

                    <th gbc-table-header>{{'users-page.user-name' | translate}}</th>
                    <th gbc-table-header>{{'users-page.email' | translate}}</th>
                    <th gbc-table-header>{{'users-page.role' | translate}}</th>

                    <tr gbc-table-row *ngFor="let user of users" [routerLink]="['/users', user.id, 'view']">
                        <td gbc-table-cell>
                            {{user.name}}
                        </td>
                        <td gbc-table-cell>
                            {{user.email}}
                        </td>
                        <td gbc-table-cell>
                            {{translateRole(user.accessLevel) | async}}
                        </td>
                    </tr>
                </gbc-table>
            </gbc-tile>
        </gbc-page-container>
    </ng-container>
</ng-template>
