import { Inject, Injectable } from '@angular/core';
import { IUser } from '../interfaces/user';
import { NEVER, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService, serverUrlToken, createRefreshSubject } from 'shared';
import { flatMap, map } from 'rxjs/operators';
import { AppErrorService } from 'shared';
import { AccessClaim } from 'projects/shared/src/lib/interfaces/access';
import { IAccessClaim } from '../interfaces/accessClaimsGroups';

@Injectable({
    providedIn: 'root'
})
export class CurrentUserService {

    // currentUser$ do not emit errors, also never completes
    currentUser$: Observable<IUser>;

    // initialCurrentUser$ may emit an error if initial user loading fails, this one completes
    initialCurrentUser$: Observable<IUser>;

    public refreshNow: () => Observable<IUser>;

    private httpGetCurrentUser() {
      return this.httpClient.get<IUser>(`${this.serverUrl}/api/users/current`);
    }

    private getAccessClaimsNames(user: IUser) : AccessClaim[] {
      if (user.accessClaimsGroups) {
        // We have claims groups; we must be running in Service Portal; extract claim names from the claims groups
        return user.accessClaimsGroups.flatMap(acg => acg.accessClaims.flatMap(ac => (ac as IAccessClaim).accessClaim));
      }
      else {
        // We have no claims groups; we must be running in Customer Portal; just return existing claim names
        return user.accessClaimsNames;
      }
    }

    public hasClaim(claim: AccessClaim): Observable<boolean> {
      return this.currentUser$.pipe(map(user => user.accessClaimsNames.includes(claim)));
    }

    constructor(
      private httpClient: HttpClient,
      @Inject(serverUrlToken) private serverUrl: string,
      private authService: AuthService,
      private appErrorService: AppErrorService
    ) {
      const {subject, scheduleRefresh, initialValue} = createRefreshSubject<IUser>(() => this.httpGetCurrentUser(), this.authService.authenticated.pipe(
        flatMap(authenticated => {
          if (authenticated) {
            return of();
          } else {
            return NEVER;
          }
        })));
      this.refreshNow = scheduleRefresh;
      this.currentUser$ = subject;
      this.currentUser$.subscribe(user => user.accessClaimsNames = this.getAccessClaimsNames(user))
      this.initialCurrentUser$ = initialValue.pipe(
        appErrorService.catchApiError({errorCodes: {
          'user-disabled': {
            titleKey: 'app-user-disabled-header',
            messageKey: 'app-user-disabled-body'
          },
          'user-not-found': {
            titleKey: 'app-user-not-found-header',
            messageKey: 'app-user-not-found-body'
          }
        }})
      );
    }
}
