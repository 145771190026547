<div class="field-header">
  <div class="field-label">
    <span class="label">{{ header }}</span>
    <span class="required" *ngIf="required">*</span>
    <img class="tooltip" *ngIf="tooltip" pTooltip="{{ tooltip }}" escape src="./assets/svgs/question-mark.svg" />
  </div>
  <div class="description" *ngIf="description">{{ description }}</div>
</div>
<div class="field-input">
  <ng-content></ng-content>
</div>
