import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { merge } from 'lodash';
import { Observable, Subscription } from 'rxjs';

export interface MenuPanel {
  templateRef: TemplateRef<any>;
  closed: EventEmitter<void>;
}

@Directive({
  selector: '[gbcMenuTriggerFor]'
})
export class MenuTriggerForDirective implements OnDestroy {
  private isOpen: boolean = false;
  private overlayRef: OverlayRef;
  private menuClosingActionSub = Subscription.EMPTY;
  private menuEventSub = Subscription.EMPTY;

  @Input('gbcMenuTriggerFor') public menuPanel: MenuPanel;

  @HostListener('click') function() {
    this.toggle();
  }

  constructor(private overlay: Overlay, private elementRef: ElementRef<HTMLElement>, private viewContainerRef: ViewContainerRef) {}

  toggle(): void {
    this.isOpen ? this.destroyMenu() : this.openMenu();
  }

  openMenu(): void {
    this.isOpen = true;
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      scrollStrategy: this.overlay.scrollStrategies.close(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          {
            originX: 'start',
            originY: 'bottom',
            overlayX: 'start',
            overlayY: 'top',
            offsetY: 5,
            panelClass: 'gbc-position-startTop'
          },
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
            offsetY: 5,
            panelClass: 'gbc-position-endTop'
          },
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'bottom',
            offsetY: -5,
            panelClass: 'gbc-position-startBottom'
          },
          {
            originX: 'end',
            originY: 'top',
            overlayX: 'end',
            overlayY: 'bottom',
            offsetY: -5,
            panelClass: 'gbc-position-endBottom'
          }
        ])
    });

    const templatePortal = new TemplatePortal(this.menuPanel.templateRef, this.viewContainerRef);
    this.overlayRef.attach(templatePortal);

    this.menuClosingActionSub = this.menuClosingActions().subscribe(() => {
      this.destroyMenu();
    });

    this.menuEventSub = this.menuPanel.closed.subscribe(() => {
      this.destroyMenu();
    });
  }

  private menuClosingActions(): Observable<MouseEvent | void> {
    const backdropClick$ = this.overlayRef.backdropClick();
    const detachment$ = this.overlayRef.detachments();
    return merge(backdropClick$, detachment$);
  }

  private destroyMenu(): void {
    if (!this.overlayRef || !this.isOpen) {
      return;
    }

    this.menuEventSub.unsubscribe();
    this.menuClosingActionSub.unsubscribe();
    this.isOpen = false;
    this.overlayRef.detach();
  }

  ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }
}
