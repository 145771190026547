<gbc-modal [title]="'two-factor-verification-flow-modal.title' | translate">

  <gbc-modal-content *ngIf="loading">
    <gbc-loading-spinner style="margin: 0 auto;"></gbc-loading-spinner>
  </gbc-modal-content>

  <form [formGroup]="form" *ngIf="step === 'phone' && !loading" (submit)="startVerification()">
    <gbc-modal-content>
      <p class="modal-paragraph">{{'two-factor-verification-flow-modal.enter-number-description' | translate}}</p>
      <p class="error" *ngIf="showPhoneNumberError">{{'two-factor-verification-flow-modal.error-with-phone' | translate}}</p>
      <gbc-input formControlName="mobile" name="mobile" [error]="form.controls.mobile.value && !form.controls.mobile.valid ? ('two-factor-verification-flow-modal.enter-valid-number' | translate) : ''" [placeholder]="'two-factor-verification-flow-modal.phone-number' | translate"></gbc-input>
    </gbc-modal-content>
    <gbc-modal-buttons>
      <button gbc-modal-button (click)="dismiss()" type="button">{{'app-cancel' | translate}}</button>
      <button gbc-modal-button class="primary" [disabled]="!form.controls.mobile.valid" type="submit">{{'two-factor-verification-flow-modal.verify' | translate}}</button>
    </gbc-modal-buttons>
  </form>

  <form [formGroup]="form" *ngIf="step === 'code' && !loading" (submit)="verifyCode()">
    <gbc-modal-content>

      <p class="modal-paragraph">{{'two-factor-verification-flow-modal.enter-code-sent-to-number' | translate: {phone: form.controls.mobile.value} }}</p>

      <button gbc-text-button *ngIf="seconds" type="button" disabled> {{'two-factor-verification-flow-modal.resend-code-seconds' | translate: {seconds: seconds} }}</button>
      <button gbc-text-button *ngIf="!seconds" type="button" (click)="resendCode()">{{'two-factor-verification-flow-modal.resend-code' | translate}}</button>

      <gbc-input formControlName="verification" type="number" name="verification" maxlength="6" [placeholder]="'two-factor-verification-flow-modal.verification-code' | translate" [error]="error$ | async"></gbc-input>

    </gbc-modal-content>
    <gbc-modal-buttons>
      <button gbc-modal-button (click)="dismiss()" type="button">{{'app-cancel' | translate}}</button>
      <button gbc-modal-button class="primary" [disabled]="!form.controls.verification.valid" type="submit">{{'app-submit' | translate}}</button>
    </gbc-modal-buttons>
  </form>



  <ng-container *ngIf="step === 'success' && !loading">
    <gbc-modal-content>
      <p class="modal-paragraph">{{'two-factor-verification-flow-modal.verification-success' | translate}}</p>
    </gbc-modal-content>
    <gbc-modal-buttons>
      <button gbc-modal-button (click)="done()">{{'app-done' | translate}}</button>
    </gbc-modal-buttons>
  </ng-container>
</gbc-modal>
