import { Pipe, PipeTransform } from '@angular/core';
import { DeviceDataPoint, FormatDataPointOptions } from '../interfaces/data-points';
import { formatDataPoint } from '../utils/data-point-utils';

@Pipe({
  name: 'formatDataPoint'
})
export class FormatDataPointPipe implements PipeTransform {

  transform(value: DeviceDataPoint | null, options?: FormatDataPointOptions, ...args: unknown[]): string {
    return options ? formatDataPoint(value, options) : formatDataPoint(value);
  }

}
