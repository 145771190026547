<gbc-modal [title]="data.ProductType" (dismiss)="dismiss()">
  <div class="container">
    <picture>
      <img src="./assets/pngs/mixit-device.png" />
    </picture>

    <div class="mr-8 ml-8">
      <dl>
        <dt>{{ 'mixit-product-info-modal.installation-name' | translate }}</dt>
        <dd>{{ data.Name }}</dd>

        <dt>{{ 'mixit-product-info-modal.orientation' | translate }}</dt>
        <dd>{{ data.Orientation }}</dd>

        <dt>{{ 'mixit-product-info-modal.product-number' | translate }}</dt>
        <dd>{{ data.ProductNumber }}</dd>

        <dt>
          {{ 'mixit-product-info-modal.product-identification' | translate }}
        </dt>
        <dd>{{ data.ProductId }}</dd>

        <dt>{{ 'mixit-product-info-modal.model' | translate }}</dt>
        <dd>{{ data.Model }}</dd>

        <dt>{{ 'mixit-product-info-modal.production-code' | translate }}</dt>
        <dd>{{ data.ProductionCode }}</dd>

        <dt>{{ 'mixit-product-info-modal.ble-version' | translate }}</dt>
        <dd>{{ data.BLE }}</dd>

        <dt>{{ 'mixit-product-info-modal.firmware-version' | translate }}</dt>
        <dd>{{ data.Firmware }}</dd>
      </dl>

      <div class="mb-8 mt-8" (click)="openProductCatalog()">
        <a
          gbc-button
          class="link"
          target="_blank"
          href="https://product-selection.grundfos.com/products/mixit/mixit-2?tab=explore"
        >
          {{ 'mixit-product-info-modal.link-to-gpc' | translate }}
        </a>
      </div>
    </div>
  </div>
</gbc-modal>
