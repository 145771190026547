<gbc-tile [title]="'heat-coil-protection-tile.heat-coil-protection' | translate">
  <button gbc-icon-button gbc-tile-right
    (click)="adjustCoilHeatProtection()"
    angulartics2On="click"
    angularticsAction="heatCoilProtectionShown"
    angularticsCategory="mixitSchematicClicked">
    <img [alt]="'heat-coil-protection-tile.adjust-heat-coil-protection' | translate" src="./assets/svgs/adjust-outline-black.svg">
  </button>
  <div class="tile-content">

    <div class="tile-row" [ngClass]="{disabled: (coilPreHeatEnabled$ | async) === false}">
      <div class="tile-row-name">{{'heat-coil-protection-tile.coil-protection' | translate}}:</div>
      <div class="tile-row-value">
        {{coilPreheatReturnTemperature$ | async | formatDataPoint}}
      </div>
    </div>

    <div class="tile-row" [ngClass]="{disabled: (frostProtectionEnabled$ | async) === false}">
      <div class="tile-row-name">{{'heat-coil-protection-tile.frost-protection' | translate}}:</div>
      <div class="tile-row-value">
        {{frostProtectionReturnFlowTemperature$ | async | formatDataPoint}}
      </div>
    </div>

    <div class="tile-row" [ngClass]="{disabled: (frostProtectionEnabled$ | async) === false}">
      <div class="tile-row-name">{{'heat-coil-protection-tile.air-temperature' | translate}}:</div>
      <div class="tile-row-value">
        {{frostProtectionAirTemperature$ | async | formatDataPoint}}
      </div>
    </div>

  </div>
</gbc-tile>
