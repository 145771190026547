import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import {
  IPOSTCompanyUser,
  IPOSTFacilityManager,
  IPUTCompanyUser,
  IPUTFacilityManager,
} from '../../../../../serviceportal/src/app/interfaces/user';
import { AppError, AppErrorService, PageInfo } from 'shared';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { first, map, shareReplay, tap } from 'rxjs/operators';
import { FacilityService } from '../../services/facility.service';
import { UserService } from '../../services/user.service';
import { PageInfoService } from '../../services/page-info.service';
import { Facility } from '../../interfaces/facilty';
import { CurrentUserService } from '../../../../../serviceportal/src/app/services/current-user.service';
import { IBackendUser, IBuildingConnectUser } from '../../interfaces/user';
import { EAccessLevel } from 'projects/shared/src/lib/interfaces/access';
import { formValues } from '../../../../../shared/src/lib/constants/form-values';

@Component({
  selector: 'app-edit-user-page',
  templateUrl: './edit-user-page.component.html',
  styleUrls: ['./edit-user-page.component.scss'],
})
export class EditUserPageComponent implements AfterViewInit, OnDestroy {
  private readonly user$: Observable<IBuildingConnectUser | null>;
  public pageError$: Observable<AppError>;
  public showFacilitySelector$: Observable<boolean>;
  public facilitiesForCompany$: Observable<Facility[]>;
  private subscription: Subscription = new Subscription();

  public id: string;

  public pageInfo$: Observable<PageInfo>;

  public roles$: Observable<{ name: string; id: string }[]>;

  public form = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required,  Validators.maxLength(formValues.max_length.name)]),
    email: new UntypedFormControl('', [Validators.required, Validators.email,  Validators.maxLength(formValues.max_length.email)]),
    role: new UntypedFormControl('', Validators.required),
    facilities: new UntypedFormControl([]),
  });
  private readonly companyId$: Observable<number>;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private facilityService: FacilityService,
    private userService: UserService,
    private route: ActivatedRoute,
    private pageInfoService: PageInfoService,
    private errorService: AppErrorService,
    private currentUserService: CurrentUserService
  ) {
    const userId = this.route.snapshot.params.userId;

    this.user$ = userId ? this.userService.getUser(userId).pipe(shareReplay()) : of(null);
    this.companyId$ = this.currentUserService.initialCurrentUser$.pipe(map((user) => user.companyId));

    this.roles$ = combineLatest([
      this.userService.translateRole(EAccessLevel.GrundfosClaimAccessCompany),
      this.userService.translateRole(EAccessLevel.GrundfosClaimAccessFacility),
    ]).pipe(
      map(([companyRoleName, facilityRoleName]) => {
        return [
          {
            id: EAccessLevel.GrundfosClaimAccessCompany,
            name: companyRoleName,
          },
          {
            id: EAccessLevel.GrundfosClaimAccessFacility,
            name: facilityRoleName,
          },
        ];
      }),
      shareReplay(1)
    );

    this.pageError$ = this.errorService.createPageErrorObservable([this.user$, this.companyId$, this.roles$]);

    // populate form when user has been fetched
    combineLatest([this.user$, this.roles$.pipe(first())]).subscribe(([user, roles]) => {
      if (user) {
        const role = roles.find((r) => r.id === user.accessLevel);
        this.form.patchValue({
          name: user.name,
          email: user.email,
          role,
          facilities: user.facilityIDs?.map((id) => id.toString()) || [],
        });
      }
    });

    // need to track changes to form to show facility selector when facility manager role is selected
    const roleSubject = new BehaviorSubject<{ name: string; id: string } | null>(null);
    combineLatest([this.user$, this.roles$.pipe(first())]).subscribe(([user, roles]) => {
      const formValue = roles.find((r) => r.id === user?.accessLevel) as { name: string; id: string };
      roleSubject.next(formValue);
    });
    this.form.controls.role.valueChanges.subscribe(roleSubject);
    this.showFacilitySelector$ = roleSubject.pipe(
      map((form) => {
        return form?.id === EAccessLevel.GrundfosClaimAccessFacility;
      })
    );

    // get all facilities for populating facility list
    this.facilitiesForCompany$ = this.facilityService.facilities$.pipe(
      map((f) => f as Facility[]),
      shareReplay(1)
    );

    // Set the pageInfo

    if (userId) {
      this.pageInfo$ = this.user$.pipe(map((user) => this.pageInfoService.editUser(user as unknown as IBackendUser)));
    } else {
      this.pageInfo$ = of(this.pageInfoService.createUser());
    }
  }

  // Because of how the change detection for roles works, we need to patch the form values, after the content has been initialised
  // Then we can corretcly use the valueChanges observables above in the constructor.
  ngAfterViewInit() {
    // Set the values of the form for
    if (this.route.snapshot.params.userId) {
      this.id = this.route.snapshot.params.userId;
    }
  }

  submit() {
    const role = this.form.value.role.id;

    if (this.id) {
      switch (role) {
        case EAccessLevel.GrundfosClaimAccessCompany:
          this.updateCompanyUser();
          break;
        case EAccessLevel.GrundfosClaimAccessFacility:
          this.updateFacilityManager();
          break;
        default:
          throw new Error(`Not handling updating of user with role: ${role}`);
      }
    } else {
      switch (role) {
        case EAccessLevel.GrundfosClaimAccessCompany:
          this.createCompanyUser();
          break;
        case EAccessLevel.GrundfosClaimAccessFacility:
          this.createFacilityManager();
          break;
        default:
          throw new Error(`Not handling creation of user with role: ${role}`);
      }
    }
  }

  createCompanyUser() {
    this.companyId$.subscribe((companyId) => {
      const user: IPOSTCompanyUser = {
        name: this.form.value.name,
        companyId,
        email: this.form.value.email,
        phone: '',
      };

      this.userService.createCompanyAdminUser(user).subscribe(
        (userResult) => {
          this.router.navigate(['/users', userResult.id, 'view']);
        },
        () => {
          // In case of error, we don't want to do anything special, we allow the user to edit the user info and try again
        }
      );
    });
  }

  createFacilityManager() {
    this.companyId$.subscribe((companyId) => {
      const user: IPOSTFacilityManager = {
        name: this.form.value.name,
        companyId,
        email: this.form.value.email,
        facilityIDs: this.form.value.facilities.map((f: string) => Number(f)),
      };

      this.userService.createFacilityManager(user).subscribe(
        (userResult) => {
          this.router.navigate(['/users', userResult.id, 'view']);
        },
        () => {
          // In case of error, we don't want to do anything special, we allow the user to edit the user info and try again
        }
      );
    });
  }

  updateCompanyUser() {
    this.companyId$.subscribe((companyId) => {
      const user: IPUTCompanyUser = {
        id: this.id,
        name: this.form.value.name,
        companyId,
        email: this.form.value.email,
      };

      this.userService.updateCompanyAdminUser(user).subscribe(
        (userResult) => {
          this.router.navigate(['/users', userResult.id, 'view']);
        },
        () => {}
      );
    });
  }

  updateFacilityManager() {
    this.companyId$.subscribe((companyId) => {
      const user: IPUTFacilityManager = {
        id: this.id,
        name: this.form.value.name,
        companyId,
        email: this.form.value.email,
        facilityIDs: this.form.value.facilities.map((f: string) => Number(f)),
      };

      this.userService.updateFacilityManager(user).subscribe(
        (userResult) => {
          this.router.navigate(['/users', userResult.id, 'view']);
        },
        () => {}
      );
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
