<app-multi-value-tile data-test-id="warm-weather-shutdown-tile"
  [applicationId]="applicationId"
  [installationId]="installationId"
  [tileTitle]="'warm-weather-shutdown-tile.title' | translate"
  [values]="values$ | async"
  [columns]="4"
  [showAdjust]="true"
  [disableAdjust]="!isDataAvailable"
  (adjust)="onAdjust()">
</app-multi-value-tile>
