<gbc-breadcrumb [pageInfo]="pageInfo"></gbc-breadcrumb>
<div class="header-container" *ngIf="pageInfo">
  <div class="header">
    <div class="header-button-container left" *ngIf="pageInfo.parent">
        <a class="header-button" [routerLink]="backRoute ? backRoute : pageInfo.parent.link"><img src="./assets/svgs/back.svg" alt="Back" data-test-id="back-button"></a>
    </div>
    <div class="header-headline-container">
      <h2 class="header-headline">
        {{pageInfo.title | async}}
        <button class="header-action-menu-button" *ngIf="menuItems && menuItems.length" [gbcMenuTriggerFor]="headerActionsMenu"><gbc-icon iconName="menu-more" alt="More"></gbc-icon></button>
        <gbc-menu #headerActionsMenu>
          <ng-content select="[gbc-page-header-actions]"></ng-content>
        </gbc-menu>
      </h2>
      <ng-container *ngFor="let subtitle of pageInfo.subtitles">
        <p *ngIf="subtitle" class="header-subheader">{{subtitle | async}}</p>
        <ng-content select="[gbc-page-header-left]"></ng-content>
      </ng-container>
    </div>
    <div class="header-button-container right">
      <div>
        <ng-content select="[gbc-page-header-right]"></ng-content>
      </div>
    </div>
  </div>
</div>
