import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { DataPointsResult, DeviceDataPoint, EBackendUnit } from '../../../interfaces/data-points';
import { getDatapoint } from '../../../utils/mixit-utils';
import { MixitDataPointName } from '../../../interfaces/mixit';
import { first, flatMap, map, switchMap } from 'rxjs/operators';
import { Installation } from '../../../interfaces/facilty';
import { AppModel } from '@ams/dumbledore';
import { Application } from '../../../interfaces/alarm';
import { EquipmentMetaInfo } from '../../../services/installation-configuration.service';
import { MixitBalancingLimitersModalComponent, MixitBalancingLimitersResponse, MixitBalancingLimitersSettings } from '../mixit-balancing-limiters-modal/mixit-balancing-limiters-modal.component';
import { parseBoolean } from '../../../utils/data-point-utils';
import { ICommand, MixitService } from '../../../services/mixit-service.service';
import { SchematicsService } from '../../../services/schematics.service';
import { ModalService } from 'shared';
import { SystemDeviceType } from '../../../interfaces/systemDeviceType';

@Component({
  selector: 'app-limiters-tile',
  templateUrl: './limiters-tile.component.html',
  styleUrls: ['./limiters-tile.component.scss'],
})
export class LimitersTileComponent implements OnInit {
  @Input() installation$: Observable<Installation>;
  @Input() schematic$: Observable<AppModel>;
  @Input() dataPoints$: Observable<DataPointsResult>;
  @Input() application$: Observable<Application>;

  public applicationType$: Observable<DeviceDataPoint | null>;
  public supplyFlowLimiter$: Observable<DeviceDataPoint | null>;
  public supplyFlowLimiterEnabled$: Observable<DeviceDataPoint | null>;
  public thermalPowerLimiter$: Observable<DeviceDataPoint | null>;
  public thermalPowerLimiterEnabled$: Observable<DeviceDataPoint | null>;
  public differentialTemperatureLimiter$: Observable<DeviceDataPoint | null>;
  public differentialTemperatureLimiterEnabled$: Observable<DeviceDataPoint | null>;
  public returnTemperatureLimiter$: Observable<DeviceDataPoint | null>;
  public returnTemperatureLimiterEnabled$: Observable<DeviceDataPoint | null>;

  private isAdjustmentInProgress = false;

  constructor(private schematicService: SchematicsService, private modalService: ModalService, private mixitService: MixitService) {}

  ngOnInit(): void {
    this.applicationType$ = getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.ApplicationType);
    this.supplyFlowLimiterEnabled$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.SupplyFlowLimiterEnabled
    );
    this.supplyFlowLimiter$ = getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.PrimaryFlowLimit);
    this.thermalPowerLimiterEnabled$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.ThermalPowerLimiterEnabled
    );
    this.thermalPowerLimiter$ = getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.ThermalPowerLimit);
    this.differentialTemperatureLimiterEnabled$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.DifferentialTemperatureLimiterEnabled
    );
    this.differentialTemperatureLimiter$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.DifferentialTemperatureLimit
    );
    this.returnTemperatureLimiterEnabled$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.ReturnTemperatureLimiterEnabled
    );
    const returnTempLimitHeating = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.ReturnTemperatureLimitHeating
    );
    const returnTempLimitCooling = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.ReturnTemperatureLimitCooling
    );
    this.returnTemperatureLimiter$ = combineLatest([this.applicationType$, returnTempLimitHeating, returnTempLimitCooling]).pipe(
      map(([application, heating, cooling]) => {
        if (application?.value === 'cooling') {
          // TODO: Fix this comparison, the value is just a guess
          return cooling;
        }
        return heating;
      })
    );
  }

  public adjustLimiters() {
    if (this.isAdjustmentInProgress) { return; }
    this.isAdjustmentInProgress = true;

    const supplyFlowMeta$ = this.application$.pipe(
      map((application) => application.id),
      flatMap((id) => this.schematicService.getEquipmentMetaInfo(id)),
      map((metas) => {
        return metas.find((m) => m.term === MixitDataPointName.PrimaryFlowLimit);
      })
    );

    const thermalPowerMeta$: Observable<EquipmentMetaInfo> = this.application$.pipe(
      map((application) => application.id),
      flatMap((id) => this.schematicService.getEquipmentMetaInfo(id)),
      map((metas) => {
        return metas.find((m) => m.term === MixitDataPointName.ThermalPowerLimit) as EquipmentMetaInfo;
      }),
      map((t: EquipmentMetaInfo) => {
        // TODO: Replace this with standard way of converting between units
        return {
          ...t,
          max: t.max / 1000,
          min: t.min / 1000,
        } as EquipmentMetaInfo;
      })
    );

    const differentialTempMeta$ = this.application$.pipe(
      map((application) => application.id),
      flatMap((id) => this.schematicService.getEquipmentMetaInfo(id)),
      map((metas) => {
        return metas.find((m) => m.term === MixitDataPointName.DifferentialTemperatureLimit);
      })
    );

    const returnTempMeta$ = this.application$.pipe(
      map((application) => application.id),
      switchMap((id) => this.schematicService.getEquipmentMetaInfo(id)),
      map((metas) => {
        return metas.find((m) => m.term === MixitDataPointName.ReturnTemperatureLimitHeating);
      })
    );

    combineLatest([
      this.installation$,
      this.application$,
      this.supplyFlowLimiterEnabled$,
      this.supplyFlowLimiter$,
      this.thermalPowerLimiterEnabled$,
      this.thermalPowerLimiter$,
      this.differentialTemperatureLimiterEnabled$,
      this.differentialTemperatureLimiter$,
      this.returnTemperatureLimiterEnabled$,
      this.returnTemperatureLimiter$,
      supplyFlowMeta$,
      thermalPowerMeta$,
      differentialTempMeta$,
      returnTempMeta$,
    ])
      .pipe(first())
      .subscribe(
        ([
          installation,
          application,
          supplyFlowLimiterEnabled,
          supplyFlow,
          thermalPowerLimiterEnabled,
          thermalPower,
          differentiatedPressureLimiterEnabled,
          differentiatedPressure,
          returnTemperatureLimiterEnabled,
          returnTemperature,
          supplyFlowMeta,
          thermalPowerMeta,
          differentialTempMeta,
          returnTempMeta,
        ]) => {
          const thermalPowerFloat = parseFloat((thermalPower as DeviceDataPoint)?.value as string);
          const thermalPowerInKW = Number.isNaN(thermalPowerFloat) ? null : thermalPowerFloat / 1000;

          // We need to cast all the inputs, as the combineLatest/subscribe only supports up to 6 typed inputs.
          const data: MixitBalancingLimitersSettings = {
            supplyFlowLimiterEnabled: parseBoolean((supplyFlowLimiterEnabled as DeviceDataPoint).value),
            supplyFlow: parseFloat((supplyFlow as DeviceDataPoint).value as string) || null,
            supplyFlowMeta: supplyFlowMeta as EquipmentMetaInfo,
            thermalPowerLimiterEnabled: parseBoolean((thermalPowerLimiterEnabled as DeviceDataPoint).value),
            thermalPower: thermalPowerInKW,
            thermalPowerMeta: thermalPowerMeta as EquipmentMetaInfo,
            differentiatedPressureLimiterEnabled: parseBoolean((differentiatedPressureLimiterEnabled as DeviceDataPoint).value),
            differentiatedPressure: parseFloat((differentiatedPressure as DeviceDataPoint).value as string) || null,
            differentiatedPressureMeta: differentialTempMeta as EquipmentMetaInfo,
            returnTemperatureLimiterEnabled: parseBoolean((returnTemperatureLimiterEnabled as DeviceDataPoint).value),
            returnTemperature: parseFloat((returnTemperature as DeviceDataPoint).value as string) || null,
            returnTemperatureMeta: returnTempMeta as EquipmentMetaInfo,
          };

          this.modalService
            .openDialog<MixitBalancingLimitersSettings>(MixitBalancingLimitersModalComponent, {
              data,
            })
            .subscribe((response) => {
              if (response.dismissed) {
                this.isAdjustmentInProgress = false;
                return;
              }

              const res = response.result as MixitBalancingLimitersResponse;

              const thermalPowerAsWatt = res.thermalPower ? res.thermalPower * 1000 : null;

              const commands: ICommand[] = [
                {
                  term: MixitDataPointName.PrimaryFlowLimit,
                  value: res.supplyFlow?.toString() || null,
                  unit: EBackendUnit.CubicMeterPerHour,
                },
                {
                  term: MixitDataPointName.SupplyFlowLimiterEnabled,
                  value: res.supplyFlowLimiterEnabled?.toString() || null,
                  unit: EBackendUnit.None,
                },

                {
                  term: MixitDataPointName.ThermalPowerLimit,
                  value: thermalPowerAsWatt?.toString() || null,
                  unit: EBackendUnit.Watt,
                },
                {
                  term: MixitDataPointName.ThermalPowerLimiterEnabled,
                  value: res.thermalPowerLimiterEnabled?.toString() || null,
                  unit: EBackendUnit.None,
                },

                {
                  term: MixitDataPointName.DifferentialTemperatureLimit,
                  value: res.differentiatedPressure?.toString() || null,
                  unit: EBackendUnit.DegreeCelsius,
                },
                {
                  term: MixitDataPointName.DifferentialTemperatureLimiterEnabled,
                  value: res.differentiatedPressureLimiterEnabled?.toString() || null,
                  unit: EBackendUnit.None,
                },

                {
                  term: MixitDataPointName.ReturnTemperatureLimitHeating,
                  value: res.returnTemperature?.toString() || null,
                  unit: EBackendUnit.DegreeCelsius,
                },
                {
                  term: MixitDataPointName.ReturnTemperatureLimiterEnabled,
                  value: res.returnTemperatureLimiterEnabled?.toString() || null,
                  unit: EBackendUnit.None,
                },
              ];

              this.mixitService.sendCommand(installation as Installation, (application as Application).id, commands);

              this.isAdjustmentInProgress = false;
            });
        }
      );
  }
}
