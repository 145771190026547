import {Component, EventEmitter, Host, HostBinding, HostListener, OnInit, Output} from '@angular/core';
import {TableComponent} from '../table/table.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[gbc-table-row]',
  templateUrl: './table-row.component.html',
  styleUrls: ['./table-row.component.scss']
})
export class TableRowComponent {

  @Output()
  rowClicked = new EventEmitter<Event>();

  @HostBinding('class.clickable') clickable: boolean;

  @HostListener('click')
  onClick(event: Event) {
    this.rowClicked.emit(event);
  }


  constructor(@Host() public table: TableComponent) {
    this.clickable = table.clickableRows;
  }
}
