import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModalController, MODAL_CONTROLLER, MODAL_DATA } from 'shared';
import { IPerformanceAlarmDto } from '../../interfaces/alarm-settings';
import { IAlarmSettingsInfoDto } from '../../interfaces/alarm-settings-info';
import { UserTrackingHelperService } from '../../services/user-tracking-helper.service';
import { TranslateService } from '@ngx-translate/core';
import { WarmWeatherShutdownSettingsDTO } from '../../interfaces/warm-weather-shutdown';

enum EnableState {
  Unknown = "Unknown",
  Enable = "Enable",
  Disable = "Disable"
}

@Component({
  selector: 'app-warm-weather-shutdown-settings-modal',
  templateUrl: './warm-weather-shutdown-settings-modal.component.html',
  styleUrls: ['./warm-weather-shutdown-settings-modal.component.scss']
})
export class WarmWeatherShutdownSettingsModalComponent implements OnInit, OnDestroy {
  public title: string;
  public form: FormGroup;
  public isValid$: Observable<boolean>;
  public enableOptions: any[];
  public averagingPeriodOptions: any[];
  public temperatureThresholdMin: number = 0;
  public temperatureThresholdMax: number = 25;

  private settings: WarmWeatherShutdownSettingsDTO;
  private subscription = new Subscription();

  // Properties
  public get warmWeatherShutdown(): EnableState { return this.form.controls.warmWeatherShutdown.value; }

  constructor(
    @Inject(MODAL_DATA) public data: WarmWeatherShutdownSettingsDTO,
    @Inject(MODAL_CONTROLLER) private controller: ModalController<WarmWeatherShutdownSettingsDTO>,
    private translateService: TranslateService,
    private userTrackingHelperService: UserTrackingHelperService
  ) {
    // Initialize settings and modal.
    this.settings = data ? data : { enable: false, temperatureThreshold: 20, averagingPeriod: 2 };
  }

  ngOnInit(): void {

    this.enableOptions = [
      { name: this.translateService.instant('warm-weather-shutdown-settings-modal.fields.warm-weather-shotdown-disable'), value: EnableState.Disable },
      { name: this.translateService.instant('warm-weather-shutdown-settings-modal.fields.warm-weather-shotdown-enable'), value: EnableState.Enable }
    ];

    this.averagingPeriodOptions = [
      { name: this.translateService.instant('warm-weather-shutdown-settings-modal.fields.averaging-period-day').replace("{{day}}", "1"), value: 1 },
      { name: this.translateService.instant('warm-weather-shutdown-settings-modal.fields.averaging-period-days').replace("{{days}}", "2"), value: 2 },
      { name: this.translateService.instant('warm-weather-shutdown-settings-modal.fields.averaging-period-days').replace("{{days}}", "3"), value: 3 }
    ];

    this.form = new FormGroup({
      warmWeatherShutdown: new FormControl({ value: this.settings.enable ? EnableState.Enable : EnableState.Disable, disabled: false }),
      temperatureThreshold: new FormControl({ value: this.settings.temperatureThreshold, disabled: !this.settings.enable }, [
        Validators.required,
        Validators.min(this.temperatureThresholdMin),
        Validators.max(this.temperatureThresholdMax),
      ]),
      averagingPeriod: new FormControl({ value: this.getAveragingPeriodSelection(this.settings.averagingPeriod), disabled: !this.settings.enable }, [
        Validators.required
      ])
    });

    this.isValid$ = this.form.valueChanges.pipe(
      map((value) => {
        return this.form.valid;
      })
    );

    // Analytics support.
    this.subscription.add(this.userTrackingHelperService.startFormValueChangeTracking('warmWeatherShutdownSettingsModal', this.form));
  }

  onWarmWeatherToggleChanged(change: any) {
    if (change.value === EnableState.Enable) {
      this.form.controls.temperatureThreshold.enable();
      this.form.controls.averagingPeriod.enable();
    } else {
      this.form.controls.temperatureThreshold.disable();
      this.form.controls.averagingPeriod.disable();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  close() {
    this.userTrackingHelperService.trackUserAction('warmWeatherShutdownSettingsModal', 'cancelButtonClicked');
    this.controller.dismiss();
  }

  save() {
    const enabled = this.form.controls.warmWeatherShutdown.value === EnableState.Enable;
    this.userTrackingHelperService.trackUserAction('warmWeatherShutdownSettingsModal', 'saveButtonClicked');
    this.controller.complete({
      enable: enabled,
      temperatureThreshold: enabled ? this.form.controls.temperatureThreshold.value : undefined,
      averagingPeriod: enabled ? this.form.controls.averagingPeriod.value.value : undefined
    });
  }

  dismiss() {
    this.userTrackingHelperService.trackUserAction('warmWeatherShutdownSettingsModal', 'closeIconClicked');
  }

  private getAveragingPeriodSelection(selectedValue: number) {
    return this.averagingPeriodOptions.find(option => option.value === selectedValue);
  }
}
