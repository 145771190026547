<gbc-page-error-handler [content]="content" [pageError$]="pageError$"></gbc-page-error-handler>
<ng-template #content>
  <gbc-page-header [pageInfo]="pageInfo$ | async">
    <ng-container gbc-page-header-actions>
      <button gbc-menu-item
        (click)="editInstallation()"
        angulartics2On="click"
        angularticsAction="editInstallationShown"
        angularticsCategory="connectingCoreInstallation">
        <img style="margin-right: 10px" src="./assets/svgs/edit.svg" />
        {{'mixit-installation-page.edit-installation' | translate}}
      </button>
    </ng-container>
  </gbc-page-header>
  <gbc-page-container>
    <app-mixit-x509-banner/>
    <app-alarm-bar *ngIf="alarm$ | async" [alarm]="alarm$"></app-alarm-bar>
    <app-schematics-tile
      height="500"
      [installationId]="(installation$ | async)?.id"
      [dataPoints$]="dataPointsForSchematic$"
      [commissionState]="installationCommissionState$ | async"
      [showShadow]="false">
    </app-schematics-tile>

    <div class="tile-grid">
      <app-booster-tile [dataPoints$]="dataPoints$"></app-booster-tile>

      <app-booster-pump-tile [dataPoints$]="dataPoints$"></app-booster-pump-tile>

      <app-booster-input-source-tile [dataPoints$]="dataPoints$"></app-booster-input-source-tile>
    </div>

    <app-booster-flow-pressure-chart
      [title]="'Pressure and flow trend data'"
      [dataPoints$]="dataPoints$"
      [application]="application$ | async"
      [userTrackingPeriodOptions]="{ category: 'connectingCoreInstallation', intervalPrefix: 'pressureAndFlowTrendData' }">
    </app-booster-flow-pressure-chart>

    <app-installation-system-status
      (alarmClick)="router.navigate(['system-status', 'alarm', $event.id], {relativeTo: route})"
      (showAllAlarms)="['system-status', { tab: 'overview'}]"
    ></app-installation-system-status>

  </gbc-page-container>
</ng-template>
