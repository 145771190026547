<gbc-tile [title]="'HYDRO MPC-E 2 CRIE 15-3'">
  <div class="tile-content">
    <div class="tile-row">
      <div class="tile-row-name">{{'booster-tile.operation-mode' | translate}}:</div>
      <div class="tile-row-value">
        {{operationMode$ | async | formatDataPoint }}
      </div>
    </div>

    <div class="tile-row">
      <div class="tile-row-name">{{'booster-tile.control-mode' | translate }}:</div>
      <div class="tile-row-value">
        {{controlMode$ | async | formatDataPoint }}
      </div>
    </div>

    <div class="tile-row">
      <div class="tile-row-name">{{'booster-tile.setpoint-head' | translate}}</div>
      <div class="tile-row-value">
        {{setPointHead$ | async | formatDataPoint }}
      </div>
    </div>

  </div>
</gbc-tile>
