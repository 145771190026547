<gbc-tile [title]="tileTitle">
  <div class="title-right" gbc-tile-right>
    <app-last-updated [installationId]="installationId" [applicationId]="applicationId"></app-last-updated>
    <button *ngIf="showAdjust" gbc-icon-button gbc-tile-right (click)="adjust.emit()" [disabled]="disableAdjust" data-test-id="toggleMultiValueTileAdjust">
      <img src="./assets/svgs/adjust-outline-black.svg" />
    </button>
  </div>
  <div class="content-container" *ngIf="models">
    <div class="header" *ngIf="header">{{ header }}</div>

    <div *ngFor="let model of models" class="datapoint" [ngClass]="{ bottom: model.isBottom, right: model.isRight }">
      <div class="label">{{ model.titleKey | translate }}</div>
      <div class="value-state value" *ngIf="model.showVisualState">
        <span>{{ model.value | translate }}</span>
        <div *ngIf="model.showVisualState === 'enabled'" class="status-badge {{model.visualState}}">
          <span class="circle {{model.visualState}}"></span>
          <span>{{ 'multi-value-tile.' + model.visualState | translate }}</span>
        </div>
      </div>
      <div class="value" *ngIf="!model.showVisualState">
        {{ model.value }}
        <ng-container *ngIf="model.state">
          <img
            *ngIf="model.state === state.Pending"
            class="status-icon"
            src="./assets/svgs/data_pending.svg"
            alt="pending"
            pTooltip="{{ 'datapoint.syncing' | translate }}"
            tooltipPosition="right"
            escape
          />
          <img
            *ngIf="model.state === state.Synced"
            class="status-icon"
            src="./assets/svgs/data_synced.svg"
            alt="synced"
            pTooltip="{{ 'datapoint.synced' | translate }}"
            tooltipPosition="right"
            escape
          />
          <img
            *ngIf="model.state === state.NotSynced"
            class="status-icon"
            src="./assets/svgs/data_notsynced.svg"
            alt="not-synced"
            pTooltip="{{ 'datapoint.data-outdated' | translate }}"
            tooltipPosition="right"
            escape
          />
        </ng-container>
      </div>
    </div>
  </div>
</gbc-tile>
