import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppModel, SystemState, SystemType } from '@ams/dumbledore';
import { BehaviorSubject, combineLatest, Observable, of, throwError } from 'rxjs';
import { distinctUntilChanged, filter, first, flatMap, map, shareReplay, take } from 'rxjs/operators';
import { AppErrorService } from 'shared';
import { Facility, Installation } from '../interfaces/facilty';
import { EquipmentMetaInfo, InstallationConfiguration, SystemMetaInfo } from './installation-configuration.service';
import { Application, MixingLoopType } from '../interfaces/alarm';
import { ActivatedRoute } from '@angular/router';
import { WeatherCompensationCurve } from '../interfaces/weather-compensation';
import { TranslateService } from '@ngx-translate/core';
import fastDeepEqual from 'fast-deep-equal';
import { EnergyDashboardSettingsDto } from '../interfaces/installation';
import { ScheduledEvent } from '../interfaces/scheduling';
import { IAlarmSettingsInfoDto } from '../interfaces/alarm-settings-info';

export interface ISchematicWithInstallation {
  installation: Installation;
  appModel: AppModel;
  installationMetaInfo: InstallationConfiguration;
}

export interface SchematicWithMeta {
  installationId: string;
  schematicDto: AppModel;
  installationMetaInfo: InstallationConfiguration;
  energyDashboardSettings: EnergyDashboardSettingsDto;
  scheduledEvents: ScheduledEvent[];
}

@Injectable({
  providedIn: 'root',
})
export class SchematicsService {
  public schematicAndMeta$: Observable<SchematicWithMeta>;
  public schematic$: Observable<AppModel> | null;
  public applications$: Observable<Application[]>;

  private schematics$: BehaviorSubject<SchematicWithMeta[]> = new BehaviorSubject<SchematicWithMeta[]>([]);
  private installation$: Observable<Installation> | null;

  constructor(private httpClient: HttpClient, private appErrorService: AppErrorService, private translateService: TranslateService) {}

  setup(installation$: Observable<Installation>) {
    this.installation$ = installation$;
    this.schematicAndMeta$ = installation$.pipe(
      take(1),
      flatMap((installation) =>
        this.schematics$.pipe(map((schematics) => schematics.find((schematic) => schematic.installationId === installation.id)))
      ),
      filter((schematic) => {
        return schematic != null;
      }),
      // Only emit a change, when the schematic is different
      distinctUntilChanged(fastDeepEqual),
      shareReplay(1)
    );

    this.schematic$ = this.schematicAndMeta$.pipe(
      map((res) => {
        if (res.schematicDto) {
          return res.schematicDto;
        }

        throw this.appErrorService.createError({
          titleKey: 'schematics-service.error-not-found-title',
          messageKey: 'schematics-service.error-not-found-message',
          report: false,
        });
      })
    );

    this.applications$ = this.schematic$.pipe(
      map((schematics) => {
        return (
          schematics?.systems.map((system) => {
            const result: Application = {
              title: system.systemInfo.title ? of(system.systemInfo.title) : this.translateService.get(`application-title.${system.type}`),
              id: system.hasId,
              type: system.type,
              subSystems: system.subSystems?.map(
                (ss) =>
                  ({
                    title: ss.systemInfo.title ? of(ss.systemInfo.title) : this.translateService.get(`application-title.${ss.type}`),
                    id: ss.hasId,
                    type: ss.type,
                  } as Application)
              ),
            };
            if (system.type === SystemType.MixingLoop) {
              result.subtype = system.systemInfo.MIX_mixingloopApplication as MixingLoopType;
            }
            return result;
          }) || []
        );
      })
    );
  }

  teardown() {
    this.schematic$ = null;
    this.installation$ = null;
  }

  public updateAllSchematics(incomingSchematics: SchematicWithMeta[]) {
    let schematics = this.schematics$.value || [];
    incomingSchematics.forEach((i) => {
      schematics = this.addSchematic(schematics, i);
    });
    this.schematics$.next(schematics);
  }

  public pushOrUpdateSchematics(schematic: SchematicWithMeta) {
    const schematics = this.addSchematic(this.schematics$.value || [], schematic);
    this.schematics$.next(schematics);
  }

  private addSchematic(schematics: SchematicWithMeta[], schematic: SchematicWithMeta): SchematicWithMeta[] {
    const schematicIndex = schematics.findIndex((i) => i.installationId === schematic.installationId);
    if (schematicIndex === -1) {
      schematics.push(schematic);
    } else {
      schematics[schematicIndex] = schematic;
    }

    return schematics;
  }

  // TODO: Rename application to system.
  getCurrentApplication(route: ActivatedRoute, applicationId?: Observable<string>): Observable<Application> {
    const applicationId$: Observable<string | null> = applicationId ?? route.paramMap.pipe(map((m) => m.get('applicationId')));
    return combineLatest([this.applications$, applicationId$]).pipe(
      first(),
      map(([applications, id]) => {
        const application = applications.find((app) => app.id === id);
        if (!application) {
          throw this.appErrorService.createError({
            titleKey: 'application-page.error-not-found-title',
            messageKey: 'application-page.error-not-found-message',
            report: false,
          });
        }
        return application;
      })
    );
  }

  getPumpConfiguration(applicationId: string, pumpId: string): Observable<EquipmentMetaInfo> {
    return this.schematicAndMeta$.pipe(
      map((res) => {
        const configuration = res.installationMetaInfo;
        const application = configuration.systemMetaInfos.find((info) => info.id === applicationId) as SystemMetaInfo;
        const pump = application.equipmentMetaInfos.find((info) => info.id === pumpId) as EquipmentMetaInfo;
        return pump;
      })
    );
  }

  getEnergyDashboardSettings(): Observable<EnergyDashboardSettingsDto> {
    return this.schematicAndMeta$.pipe(map((res) => res.energyDashboardSettings));
  }

  getBLEVersion() {
    return this.schematicAndMeta$.pipe(map((res) => res.installationMetaInfo.bleVersion));
  }

  getWeatherCompensationCurve(applicationId: string): Observable<WeatherCompensationCurve> {
    return this.schematicAndMeta$.pipe(
      map((res) => {
        const configuration = res.installationMetaInfo;
        const application = configuration.systemMetaInfos.find((info) => info.id === applicationId) as SystemMetaInfo;
        return application?.weatherCompensationDto?.points;
      })
    );
  }

  getEquipmentMetaInfo(applicationId: string): Observable<EquipmentMetaInfo[]> {
    return this.schematicAndMeta$.pipe(
      map((res) => {
        const configuration = res.installationMetaInfo;
        const application = configuration.systemMetaInfos.find((i) => i.id === applicationId) as SystemMetaInfo;
        return application.equipmentMetaInfos;
      })
    );
  }

  getWarmWeatherShutdownAvailableState(applicationId: string): Observable<boolean> {
    return this.schematicAndMeta$.pipe(
      map((schematicWithMeta: SchematicWithMeta) => {
        const systemMetaInfo: SystemMetaInfo = schematicWithMeta.installationMetaInfo.systemMetaInfos.find(
          (systemMetaInfo: SystemMetaInfo): boolean => systemMetaInfo.id === applicationId
        ) as SystemMetaInfo;
        return systemMetaInfo?.warmWeatherShutdownAvailable || false;
      })
    );
  }

  getFirmwareVersion() {
    return this.schematicAndMeta$.pipe(map((res) => res.installationMetaInfo.firmWareVersion));
  }

  getInstallationAlarmSettings(): Observable<IAlarmSettingsInfoDto[]> {
    return this.schematicAndMeta$.pipe(
      map((res) => {
        const alarmSettings: IAlarmSettingsInfoDto[] = [];
        const systemsMetaInfo = res.installationMetaInfo.systemMetaInfos;
        const systemsInfo = res.schematicDto.systems;

        systemsMetaInfo.forEach((systemMeta) => {
          if (systemMeta.alarmSettings) {
            systemMeta.alarmSettings.forEach((settings) => {
              const systemInfo = systemsInfo.find((info) => info.hasId === systemMeta.id);
              const systemTypeTitle = systemInfo ? this.translateService.instant('application-title.' + systemInfo.type) : '-';
              let systemTitle = systemInfo && systemInfo.systemInfo.title ? systemInfo.systemInfo.title : systemTypeTitle;

              // Append subsytem type to title if a subsystem is found
              const subSystem = systemInfo?.subSystems?.find((ss) => ss.hasId === systemMeta.subSystemId);
              if (subSystem) {
                systemTitle += ` (${this.translateService.instant('application-title.' + subSystem.type)})`;
              }

              alarmSettings.push({
                applicationId: systemMeta.id,
                applicationSubSystemId: systemMeta.subSystemId,
                applicationType: systemInfo ? systemInfo.type : '',
                applicationTitle: systemTitle,
                alarmSettings: settings,
              });
            });
          }
        });
        return alarmSettings;
      })
    );
  }

  public getSchematicsForFacility(facility: Facility): Observable<ISchematicWithInstallation[]> {
    const installationIds = facility.installations.map((i) => i.id);
    return this.getMultipleSchematics(installationIds).pipe(
      map((installationSchematics) =>
        installationSchematics.map(({ installationId, schematicDto, installationMetaInfo }) => {
          return {
            installation: facility.installations.find((i) => i.id === installationId),
            appModel: schematicDto,
            installationMetaInfo,
          } as ISchematicWithInstallation;
        })
      )
    );
  }

  public getMultipleSchematics(installationIds: string[]): Observable<SchematicWithMeta[]> {
    return this.schematics$.pipe(
      map((schematicDTOs) => {
        return schematicDTOs.filter((s) => installationIds.includes(s.installationId));
      })
    );
  }
}
