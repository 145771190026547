<gbc-page-error-handler [content]="content" [pageError$]="pageError$">
</gbc-page-error-handler>
<ng-template #content>
  <router-outlet *ngIf="route.children.length !== 0"></router-outlet>
  <ng-container *ngIf="route.children.length === 0">
    <app-building-connect-installation-page *ngIf="isBuildingConnect$ | async" [installation$]="installation$" [facility$]="facility$" [schematic$]="schematic$"></app-building-connect-installation-page>
    <app-mixit-installation-page *ngIf="isMixit$ | async" [installation$]="installation$" [facility$]="facility$" [schematic$]="schematic$"></app-mixit-installation-page>
    <app-connectingcore-installation-page *ngIf="isConnectingCore$ | async" [installation$]="installation$" [facility$]="facility$" [schematic$]="schematic$"></app-connectingcore-installation-page>
  </ng-container>
</ng-template>
