import React from 'react';
import { FORWARD_X, TOP } from './common';
import { UnknownApplication } from './UnknownApplication';
import { PIPE_BOTTOM, RadiatorOneString } from './RadiatorOneString';
import { RETURN_Y as FAN_COIL_RETURN_Y, FORWARD_Y as FAN_COIL_FORWARD_Y, FanCoil } from './FanCoil';
import { RadiatorTwoString, TWO_STRING_RETURN_TOP, TWO_STRING_SUPPLY_TOP } from './RadiatorTwoString';
import { RETURN_Y as UNDERFLOOR_RETURN_Y, FORWARD_Y as UNDERFLOOR_FORWARD_Y, Underfloor } from './Underfloor';
import { IMixingloopSystemFormInterface } from '../../../models/systems/mixingloopSystem/mixingloopSystemFormInterface';
import { IUnspecifiedLoadSystemFormInterface } from '../../../models/systems/unspecifiedLoadSystem/unspecifiedLoadSystemFormInterface';

type ApplicationType = IMixingloopSystemFormInterface['MIX_mixingloopApplication'] | IUnspecifiedLoadSystemFormInterface['Application'];

export interface ApplicationTypeProps {
  applicationType: ApplicationType;
}

export const getForwardTop = (applicationType: ApplicationType): number => {
  switch (applicationType) {
    case 'RadiatorTwoStringHeating':
      return TOP + TWO_STRING_SUPPLY_TOP;
    case 'RadiatorOneStringHeating':
      return TOP + PIPE_BOTTOM;
    case 'UnderfloorHeating':
      return TOP + UNDERFLOOR_FORWARD_Y;
    // case 'FanCoil':
    //   return TOP + FAN_COIL_FORWARD_Y;
  }
  return TOP;
};

export const getReturnTop = (applicationType: ApplicationType): number => {
  switch (applicationType) {
    case 'RadiatorTwoStringHeating':
      return TOP + TWO_STRING_RETURN_TOP;
    case 'RadiatorOneStringHeating':
      return TOP + PIPE_BOTTOM;
    case 'UnderfloorHeating':
      return TOP + UNDERFLOOR_RETURN_Y;
    // case 'FanCoil':
    //   return TOP + FAN_COIL_RETURN_Y;
  }
  return TOP;
};
export const ApplicationType: React.FC<ApplicationTypeProps> = React.memo(({ applicationType }) => {
  let Component;
  switch (applicationType) {
    case 'RadiatorTwoStringHeating':
      Component = RadiatorTwoString;
      break;
    case 'RadiatorOneStringHeating':
      Component = RadiatorOneString;
      break;
    case 'UnderfloorHeating':
      Component = Underfloor;
      break;
    // case 'FanCoil':
    //   Component = FanCoil;
    //   break;
    case 'UnknownApplication':
      Component = UnknownApplication;
      break;
  }
  if (!Component) {
    return null;
  }
  return <Component x={FORWARD_X} y={TOP} />;
});
