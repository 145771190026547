import { NgModule } from '@angular/core';
import { OverlayContainer, OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from './components/button/button.component';
import { TextButtonComponent } from './components/text-button/text-button.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { TileComponent } from './components/tile/tile.component';
import { TileFilterTabComponent } from './components/tile-filter-tab/tile-filter-tab.component';
import { TileFooterComponent } from './components/tile-footer/tile-footer.component';
import { TileSpinnerComponent } from './components/tile-spinner/tile-spinner.component';
import { InputComponent } from './components/input/input.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { ModalComponent } from './components/modal/modal.component';
import { ModalButtonComponent } from './components/modal-button/modal-button.component';
import { ModalButtonsComponent } from './components/modal-buttons/modal-buttons.component';
import { ModalContentComponent } from './components/modal-content/modal-content.component';
import { TextModalComponent } from './components/text-modal/text-modal.component';
import { PageSpinnerComponent } from './components/page-spinner/page-spinner.component';
import { PageErrorHandlerComponent } from './components/page-error-handler/page-error-handler.component';
import { ErrorTileComponent } from './components/error-tile/error-tile.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { SimpleSpinnerComponent } from './components/simple-spinner/simple-spinner.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { TopbarDropdownComponent } from './components/topbar-dropdown/topbar-dropdown.component';
import { TopbarDropdownItemComponent } from './components/topbar-dropdown-item/topbar-dropdown-item.component';
import { PageContainerComponent } from './components/page-container/page-container.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { CommandSpinnerComponent } from './components/command-spinner/command-spinner.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { TabComponent } from './components/tab/tab.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TableComponent } from './components/table/table.component';
import { TableRowComponent } from './components/table-row/table-row.component';
import { TableCellComponent } from './components/table-cell/table-cell.component';
import { TimeWithAgoPipe } from './pipes/time-with-ago.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { EmptyComponent } from './components/empty-component/empty.component';
import { TableHeaderComponent } from './components/table-header/table-header.component';
import { InputContainerComponent } from './components/input-container/input-container.component';
import { InputLabelComponent } from './components/input-label/input-label.component';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { StaticTextFieldComponent } from './components/static-text-field/static-text-field.component';
import { LanguagePickerComponent } from './components/language-picker/language-picker.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { ItemListSelectorComponent } from './components/item-list-selector/item-list-selector.component';
import { FormHintComponent } from './components/form-hint/form-hint.component';
import { BannerComponent } from './components/banner/banner.component';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { TextToggleComponent } from './components/text-toggle/text-toggle.component';
import { SupportModalComponent } from './components/support-modal/support-modal.component';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { MenuTriggerForDirective } from './directives/menu-trigger-for.directive';
import { CollapsibleComponent } from './components/collapsible/collapsible.component';
import { DropDownMenuComponent } from './components/drop-down-menu/drop-down-menu.component';
import { NgxMaskModule } from 'ngx-mask';
import { WeekdaySelectorComponent } from './components/weekday-selector/weekday-selector.component';
import { ArrayContainsPipe } from './pipes/array-contains.pipe';
import { InfoContainerComponent } from './components/info-container/info-container.component';
import { InputNumericFieldComponent } from './components/input-numeric-field/input-numeric-field.component';
import { MessageTileComponent } from './components/message-tile/message-tile.component';
import { IconComponent } from './components/icon/icon.component';
import { PopupMenuComponent } from './components/popup-menu/popup-menu.component';
import { InputTimeFieldComponent } from './components/input-time-field/input-time-field.component';
import { MenuComponent } from './components/menu/menu.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { ProgressCheckerComponent } from './components/progress-checker/progress-checker.component';
import { FormContainerComponent } from './components/form-container/form-container.component';
import { FormRowComponent } from './components/form-row/form-row.component';
import { SearchInputComponent } from './components/search-input/search-input.component';

/**
 * Currently imported PrimeNG components
 * https://primeng.org/setup
 */

import { TooltipModule } from 'primeng/tooltip';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CheckboxModule } from 'primeng/checkbox';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputTextModule } from 'primeng/inputtext';
import { ImageModule } from 'primeng/image';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ModalSectionComponent } from './components/modal-section/modal-section.component';
import { ModalSectionColumnComponent } from './components/modal-section-column/modal-section-column.component';

@NgModule({
  declarations: [
    EmptyComponent,
    ButtonComponent,
    TextButtonComponent,
    TextareaComponent,
    TileComponent,
    TileFilterTabComponent,
    TileFooterComponent,
    TileSpinnerComponent,
    InputComponent,
    IconButtonComponent,
    ModalComponent,
    ModalButtonComponent,
    ModalButtonsComponent,
    ModalContentComponent,
    ModalSectionComponent,
    ModalSectionColumnComponent,
    TextModalComponent,
    PageSpinnerComponent,
    PageErrorHandlerComponent,
    ErrorTileComponent,
    LoadingSpinnerComponent,
    SimpleSpinnerComponent,
    TopbarComponent,
    TopbarDropdownComponent,
    TopbarDropdownItemComponent,
    PageContainerComponent,
    PageHeaderComponent,
    BreadcrumbComponent,
    SnackBarComponent,
    CommandSpinnerComponent,
    StepperComponent,
    TabComponent,
    TabsComponent,
    TableComponent,
    TableRowComponent,
    TableCellComponent,
    TimeWithAgoPipe,
    TimeAgoPipe,
    ArrayContainsPipe,
    TableHeaderComponent,
    InputContainerComponent,
    InputLabelComponent,
    InputFieldComponent,
    DropdownComponent,
    StaticTextFieldComponent,
    LanguagePickerComponent,
    ToggleComponent,
    ItemListSelectorComponent,
    FormHintComponent,
    FormContainerComponent,
    FormRowComponent,
    BannerComponent,
    LoadingBarComponent,
    AccordionComponent,
    TextToggleComponent,
    SupportModalComponent,
    AutoFocusDirective,
    MenuTriggerForDirective,
    CollapsibleComponent,
    DropDownMenuComponent,
    WeekdaySelectorComponent,
    InfoContainerComponent,
    InputNumericFieldComponent,
    TextModalComponent,
    MessageTileComponent,
    IconComponent,
    PopupMenuComponent,
    InputTimeFieldComponent,
    MenuComponent,
    MenuItemComponent,
    ProgressCheckerComponent,
    SearchInputComponent
  ],
  imports: [
    NgSelectModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    TranslateModule,
    RouterModule,
    CdkStepperModule,
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot(),
    TooltipModule,
    InputSwitchModule,
    CheckboxModule,
    SelectButtonModule,
    InputTextModule,
    ImageModule,
    ButtonModule,
    MessageModule,
    MessagesModule,
    ProgressSpinnerModule
  ],
  exports: [
    TranslateModule,
    EmptyComponent,
    ButtonComponent,
    TextButtonComponent,
    TextareaComponent,
    TileComponent,
    TileFilterTabComponent,
    TileFooterComponent,
    TileSpinnerComponent,
    InputComponent,
    IconButtonComponent,
    ModalComponent,
    ModalButtonComponent,
    ModalButtonsComponent,
    ModalContentComponent,
    ModalSectionComponent,
    ModalSectionColumnComponent,
    PageSpinnerComponent,
    PageErrorHandlerComponent,
    ErrorTileComponent,
    LoadingSpinnerComponent,
    SimpleSpinnerComponent,
    TopbarComponent,
    TopbarDropdownComponent,
    TopbarDropdownItemComponent,
    PageContainerComponent,
    PageHeaderComponent,
    BreadcrumbComponent,
    StepperComponent,
    TabComponent,
    TabsComponent,
    CommandSpinnerComponent,
    TableComponent,
    TableHeaderComponent,
    TableRowComponent,
    TableCellComponent,
    TimeAgoPipe,
    TimeWithAgoPipe,
    ArrayContainsPipe,
    InputContainerComponent,
    InputLabelComponent,
    InputFieldComponent,
    DropdownComponent,
    StaticTextFieldComponent,
    ToggleComponent,
    ItemListSelectorComponent,
    FormHintComponent,
    FormContainerComponent,
    FormRowComponent,
    BannerComponent,
    LoadingBarComponent,
    AccordionComponent,
    TextToggleComponent,
    CollapsibleComponent,
    SupportModalComponent,
    AutoFocusDirective,
    MenuTriggerForDirective,
    DropDownMenuComponent,
    PopupMenuComponent,
    MenuComponent,
    MenuItemComponent,
    WeekdaySelectorComponent,
    InfoContainerComponent,
    InputNumericFieldComponent,
    TextModalComponent,
    MessageTileComponent,
    IconComponent,
    InputTimeFieldComponent,
    ProgressCheckerComponent,
    TooltipModule,
    InputSwitchModule,
    CheckboxModule,
    SelectButtonModule,
    SearchInputComponent,
    InputTextModule,
    ImageModule,
    ButtonModule,
    MessageModule,
    MessagesModule,
    ProgressSpinnerModule
  ],
  providers: [OverlayContainer, DatePipe, TimeAgoPipe, TimeWithAgoPipe, ArrayContainsPipe]
})
export class SharedModule {}
