<app-mixit-x509-banner/>
<app-alarm-bar *ngIf="alarm$ | async" [alarm]="alarm$"> </app-alarm-bar>

<app-schematics-tile
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [showShadow]="false"
  [dataPoints$]="dataPoints$">
</app-schematics-tile>

<app-heat-meter-status-tile
  [installation$]="installation$"
  [application]="application"
  [dataPoints$]="dataPoints$"
  [schematic$]="schematic$"
  [forwardTemperature]="EEquipment.T_F"
  [returnTemperature]="EEquipment.T_R">
</app-heat-meter-status-tile>

<app-pump-status-tile
  *ngIf="primaryPumpDevice$ | async"
  [installation$]="installation$"
  [application]="application"
  [pumpType$]="primaryPumpType$"
  [pumpDevice$]="primaryPumpDevice$"
  [pumpOptions]="primaryPumpOptions$ | async">
</app-pump-status-tile>

<app-pump-status-tile
  *ngIf="slavePumpDevice$ | async"
  [title]="'pump-status-tile.secondary-pump' | translate"
  [installation$]="installation$"
  [application]="application"
  [pumpType$]="slavePumpType$"
  [pumpDevice$]="slavePumpDevice$"
  [pumpOptions]="slavePumpOptions$ | async">
</app-pump-status-tile>

<app-valve-status-tile
  *ngIf="valveDevice$ | async"
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [dataPoints$]="dataPoints$"
  [valveDevice$]="valveDevice$"
  [energyValve]="hasEnergyValve$ | async">
</app-valve-status-tile>

<app-forward-return-chart-tile
  [title]="'indirect-district-heat-supply-dashboard.district-temp-chart-title' | translate"
  [applicationId]="application.id"
  forwardDumbledoreId="T_F"
  returnDumbledoreId="T_R"
  [dataPoints$]="dataPoints$"
  [userTrackingPeriodOptions]="{ category: 'application/indirectDistrictHeating', intervalPrefix: 'districtHeatTemperature' }">
</app-forward-return-chart-tile>

<app-forward-return-chart-tile
  *ngIf="hasPrimaryTemperatureSensors$ | async"
  [title]="'mixing-loop-dashboard.primary-temp-chart-title' | translate"
  [applicationId]="application.id"
  forwardDumbledoreId="T_PF"
  returnDumbledoreId="T_PR"
  [dataPoints$]="dataPoints$"
  [userTrackingPeriodOptions]="{ category: 'application/indirectDistrictHeating', intervalPrefix: 'primaryTemperature' }">
</app-forward-return-chart-tile>

<app-forward-return-chart-tile
  [title]="'mixing-loop-dashboard.secondary-temp-chart-title' | translate"
  [applicationId]="application.id"
  forwardDumbledoreId="T_SF"
  returnDumbledoreId="T_SR"
  [dataPoints$]="dataPoints$"
  [userTrackingPeriodOptions]="{ category: 'application/indirectDistrictHeating', intervalPrefix: 'secondaryTemperature' }">
</app-forward-return-chart-tile>

<app-valve-chart
  [dataPoints$]="dataPoints$"
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [hasEnergyValve]="hasEnergyValve$ | async"
  [userTrackingPeriodOptions]="{ category: 'application/indirectDistrictHeating', intervalPrefix: 'valvePosition' }">
</app-valve-chart>

<app-energy-valve-heat-and-flow-chart
  *ngIf="hasEnergyValve$ | async"
  [dataPoints$]="dataPoints$"
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id">
</app-energy-valve-heat-and-flow-chart>

<app-energy-valve-temperature-chart
  *ngIf="hasEnergyValve$ | async"
  [dataPoints$]="dataPoints$"
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id">
</app-energy-valve-temperature-chart>

<app-differential-pressure-chart
  [dataPoints$]="dataPoints$"
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [userTrackingPeriodOptions]="{ category: 'application/indirectDistrictHeating', intervalPrefix: 'differentialPressure' }">
</app-differential-pressure-chart>
