import React from 'react';
import { LabelPosition } from './types';
import { ValueLabel } from './ValueLabel';
import { ValveLabel } from './ValveLabel';
import { EValueType, TEquipmentValues, TValveOpening } from '../types';

export interface LabelsProps {
  positions: LabelPosition[];
  values: TEquipmentValues[];
}

export const Labels: React.FC<LabelsProps> = React.memo(
  ({ positions, values }: { positions: LabelPosition[]; values: TEquipmentValues[] }) => {
    if (!values) {
      return null;
    }

    const labels = positions.flatMap((meta) => {
      const value = values.find((mapping) => mapping.valueType === EValueType.value && mapping.equipment === meta.id)?.value as string;
      const setpoint = values.find((mapping) => mapping.valueType === EValueType.setPoint && mapping.equipment === meta.id)?.value;
      const valveIndicator = values.find(
        (mapping) => mapping.valueType === EValueType.valveIndication && mapping.equipment === meta.id
      )?.value;

      if (valveIndicator) {
        return [
          // data attribute is for targeting in tests
          <g data-dumbledoreid={meta.id} key={meta.id}>
            <ValveLabel
              id={meta.id}
              value={value}
              setpoint={setpoint}
              valveOpening={valveIndicator as TValveOpening}
              x={meta.x}
              y={meta.y}
              alignment={meta.horizontalAlignment}
            />
          </g>,
        ];
      }

      if (value || setpoint) {
        return [
          // data attribute is for targeting in tests
          <g data-dumbledoreid={meta.id} key={meta.id}>
            <ValueLabel id={meta.id} value={value} setpoint={setpoint} x={meta.x} y={meta.y} alignment={meta.horizontalAlignment} />
          </g>,
        ];
      }

      return [];
    });

    return <g>{labels}</g>;
  }
);
