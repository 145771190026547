import React from 'react';
import { Direction, getRotation } from './Common';

export interface PumpIconProps {
  direction: Direction;
  x: number;
  y: number;
}

const RADIUS = 15;
// the origin is the CENTER of the pump
export const PumpIcon: React.FC<PumpIconProps> = React.memo(({ direction, x, y }) => {
  return (
    <g transform={`translate(${x}, ${y}) rotate(${getRotation(direction)})`}>
      <circle cx="0" cy="0" r="15" fill="#FFF" stroke="#313131" strokeWidth="1.5" />
      <path strokeWidth="1.5" d={`M -${RADIUS} 0 L0 -${RADIUS} L${RADIUS} 0`} stroke="#313131" fill="none" />
    </g>
  );
});
