import { SystemState } from '../state';
import { SampleEquipmentValue, SAMPLE_VALUES } from './sample-equipment-values';
import { EValueType, IEquipmentValue, ISetPointValue, IValveOpenValue, TValveOpening } from '../types';
import { SAMPLE_DISTRICT_HEATING_SUPPLY } from './districtHeatingSupplySamples';
import { SAMPLE_INDIRECT_DISTRICT_HEATING_SUPPLY } from './indirectDistrictHeatingSupplySamples';
import { SAMPLE_MIXING_LOOP } from './mixingLoopSamples';
import { SAMPLE_BOILER } from './boilerSamples';
import { SAMPLE_MIXIT_2WAY, SAMPLE_MIXIT_3WAY, SAMPLE_MIXIT_3WAY_DOUBLE, SAMPLE_MIXIT_3WAY_DOUBLE_LEFT, SAMPLE_MIXIT_ORIENTATION_LEFT, SAMPLE_MIXIT_ORIENTATION_RIGHT } from './mixitSamples';
import { SAMPLE_BUFFER_TANK } from './bufferTankSamples';
import { SAMPLE_COMMERCIAL_HOT_WATER } from './commercialHotWaterSamples';
import { SAMPLE_HEAT_EXCHANGER } from './heatExchangerSamples';
import { SAMPLE_OUTDOOR_TEMPERATURE } from './outdoorTemperatureSamples';
//import { SAMPLE_HOT_WATER_PUMP_SYSTEM } from './hotWaterPumpSamples';
//import { SAMPLE_COLD_WATER_PUMP_SYSTEM } from './coldWaterPumpSamples';
import { SAMPLE_TWO_STROKE_HEAT_EXCHANGER } from './twoStrokeHeatExchangerHotWaterSamples';
import { SAMPLE_HOT_WATER_TANK_SYSTEM } from './hotWaterTankSamples';
import { SAMPLE_UNSPECIFIED_LOAD } from './unspecifiedLoadSamples';
import { SAMPLE_COMMERCIAL_HOT_WATER_STANDALONE } from './commercialHotWaterStandaloneSamples';
import { SAMPLE_PUMP_STANDALONE_SYSTEM, SAMPLE_PUMP_STANDALONE_SYSTEM_RETURN } from './pumpStandaloneSamples';
import { SAMPLE_HOT_WATER_TANK_STANDALONE_SYSTEM } from './hotWaterTankStandAloneSamples';
import { SAMPLE_COLD_WATER_SUPPLY_SYSTEM } from './coldWaterSupplySamples';
import { SAMPLE_BOOSTER_SYSTEM } from './boosterSamples';
import { SAMPLE_PRESSURE_SENSOR_SYSTEM } from './pressureSensorSamples';
import { SAMPLE_CASCADE_TANK_SYSTEM } from './cascadeTankSamples';
import { SAMPLE_CASCADE_BUFFER_TANK_CHARGING_SYSTEM } from './cascadeBufferTankChargingSamples';

export const SAMPLE_SCHEMATIC = {
  systems: [
    SAMPLE_DISTRICT_HEATING_SUPPLY,
    SAMPLE_BUFFER_TANK,
    SAMPLE_MIXING_LOOP,
    SAMPLE_COMMERCIAL_HOT_WATER,
    SAMPLE_HEAT_EXCHANGER,
    SAMPLE_BOILER,
    SAMPLE_OUTDOOR_TEMPERATURE,
    // DEPRECATED: SAMPLE_HOT_WATER_PUMP_SYSTEM,
    // DEPRECATED: SAMPLE_COLD_WATER_PUMP_SYSTEM,
    SAMPLE_TWO_STROKE_HEAT_EXCHANGER,
    SAMPLE_HOT_WATER_TANK_SYSTEM,
    SAMPLE_MIXIT_2WAY,
    SAMPLE_MIXIT_3WAY,
    SAMPLE_MIXIT_3WAY_DOUBLE,
    SAMPLE_MIXIT_3WAY_DOUBLE_LEFT,
    SAMPLE_MIXIT_ORIENTATION_LEFT,
    SAMPLE_MIXIT_ORIENTATION_RIGHT,
    SAMPLE_UNSPECIFIED_LOAD,
    SAMPLE_COMMERCIAL_HOT_WATER_STANDALONE,
    SAMPLE_PUMP_STANDALONE_SYSTEM,
    SAMPLE_PUMP_STANDALONE_SYSTEM_RETURN,
    SAMPLE_HOT_WATER_TANK_STANDALONE_SYSTEM,
    SAMPLE_COLD_WATER_SUPPLY_SYSTEM,
    SAMPLE_BOOSTER_SYSTEM,
    SAMPLE_PRESSURE_SENSOR_SYSTEM,
    SAMPLE_CASCADE_TANK_SYSTEM,
    SAMPLE_INDIRECT_DISTRICT_HEATING_SUPPLY,
    SAMPLE_CASCADE_BUFFER_TANK_CHARGING_SYSTEM
  ],
  connections: [],
  connectionPoints: [],
  valid: false,
  buildingInfo: {},
  modifiedTimestamp: Date.now(),
  draft: false
};

const getSampleDataForSystem = (system: SystemState, sampleData: SampleEquipmentValue[]) => {
  const sampleValuesForSystem = sampleData.filter(value => value.systemType === system.type);
  return sampleValuesForSystem.map(sampleValue => {
    if (sampleValue.type === EValueType.value) { return mapToValue(system, sampleValue); }
    if (sampleValue.type === EValueType.setPoint) { return mapToSetPoint(system, sampleValue); }
    if (sampleValue.type === EValueType.valveIndication) { return mapToValveIndicator(system, sampleValue); }
  });
}

export const getSampleValuesForSystem = (system: SystemState) => {
  return getSampleDataForSystem(system, SAMPLE_VALUES);
};

export const getSampleDataMaxValuesForSystem = (system: SystemState) => {
  return getSampleDataForSystem(system, SAMPLE_VALUES.map(maxValueMapping));
};

export const getSampleDataIdsForSystem = (system: SystemState) => {
  return getSampleDataForSystem(system, SAMPLE_VALUES.map(idsValueMapping));
};

const mapToValue = (system: SystemState, sampleValue: SampleEquipmentValue): IEquipmentValue => {
  return {
    valueType: EValueType.value,
    systemId: system.hasId,
    equipment: sampleValue.equipment,
    value: sampleValue.value,
    deviceId: ''
  };
};

const mapToSetPoint = (system: SystemState, sampleValue: SampleEquipmentValue): ISetPointValue => {
  return {
    valueType: EValueType.setPoint,
    systemId: system.hasId,
    equipment: sampleValue.equipment,
    value: sampleValue.value,
    deviceId: ''
  };
};

const mapToValveIndicator = (system: SystemState, sampleValue: SampleEquipmentValue): IValveOpenValue => {
  return {
    valueType: EValueType.valveIndication,
    systemId: system.hasId,
    equipment: sampleValue.equipment,
    value: sampleValue.value as TValveOpening,
    deviceId: ''
  };
};

const maxValueMapping = (item: SampleEquipmentValue): SampleEquipmentValue => {
  // Unit specification is treated and converted as a prefix to simplify.
  const maxValue: string = item.type === EValueType.valveIndication ? item.value : (item.unit !== undefined) ? convertPrefixToMaxValue(item.unit) : convertPrefixToMaxValue(item.equipment.toString());
  return { ...item, value: maxValue } as SampleEquipmentValue;
}

const idsValueMapping = (item: SampleEquipmentValue): SampleEquipmentValue => {
 const mappedValue: string = item.type === EValueType.valveIndication ? item.value : item.equipment.toString();
 return { ...item, value: mappedValue } as SampleEquipmentValue;
}

const convertPrefixToMaxValue = (name?: string): string => {
  if (name === undefined) return "-";
  const prefix = name.indexOf('_') !== -1 ? name.substring(0, name.indexOf('_')) : name;

  switch (prefix.toUpperCase())
  {
    case "T": return "99.9\u2103";
    case "Q": return "99.9\u33a5/s";
    case "P": return "9.9\u33a5/h\n99.9m";
    case "MV": return "100%";
    case "H": return "9.99 Bar";
    case "MIXITMETER" : return "9.9\u33a5/s\n99.9kW";
    case "TEMPERATURE" : return "99.9\u2103";
    default: return "-";
  }
}
