import { SystemType, DistrictHeatingSystemState, MixingLoopSystemState } from '../state';
import { EEquipment, EValueType, IEquipmentValue, ISetPointValue, IValveOpenValue } from '../types';
import { SampleEquipmentValue } from './sample-equipment-values';
import { v4 } from 'uuid';

export const SAMPLE_MIXING_LOOP: MixingLoopSystemState = {
  hasId: v4(),
  type: SystemType.MixingLoop,
  left: 10,
  top: 320,
  configured: false,
  systemInfo: {
    Control_enabled: true,
    HM_present: true,
    HM_location: 'PrimaryForward',
    MV_valveLocation: 'PrimaryForward',
    MV_valveType: 'ThreeWayValve',
    P_location: 'SecondaryForward',
    T_SR_present: true
  }
};

export const SAMPLE_VALUES_MIXING_LOOP: SampleEquipmentValue[] = [
  { systemType: SystemType.MixingLoop, type: EValueType.value, equipment: EEquipment.T_SF, value: '52.9\u2103' },
  { systemType: SystemType.MixingLoop, type: EValueType.setPoint, equipment: EEquipment.T_SF, value: '53.0\u2103' },
  { systemType: SystemType.MixingLoop, type: EValueType.value, equipment: EEquipment.T_SR, value: '33.2\u2103' },
  { systemType: SystemType.MixingLoop, type: EValueType.value, equipment: EEquipment.T_PF, value: '73.4\u2103' },
  { systemType: SystemType.MixingLoop, type: EValueType.value, equipment: EEquipment.T_PR, value: '33.0\u2103' },
  { systemType: SystemType.MixingLoop, type: EValueType.value, equipment: EEquipment.Q_PF, value: '10.3\u33a5/h' },
  { systemType: SystemType.MixingLoop, type: EValueType.value, equipment: EEquipment.Q_PR, value: '10.3\u33a5/h' },
  { systemType: SystemType.MixingLoop, type: EValueType.valveIndication, equipment: EEquipment.MV_2W_PF, value: 'opening' },  // opening
  { systemType: SystemType.MixingLoop, type: EValueType.valveIndication, equipment: EEquipment.MV_2W_PR, value: 'opening' },  // closing
  { systemType: SystemType.MixingLoop, type: EValueType.valveIndication, equipment: EEquipment.MV_3W_PF, value: 'opening' },   // steady
  { systemType: SystemType.MixingLoop, type: EValueType.valveIndication, equipment: EEquipment.MV_3W_PR, value: 'opening' },  // opening
  { systemType: SystemType.MixingLoop, type: EValueType.value, equipment: EEquipment.MV_2W_PF, value: '13%' },
  { systemType: SystemType.MixingLoop, type: EValueType.value, equipment: EEquipment.MV_2W_PR, value: '13%' },
  { systemType: SystemType.MixingLoop, type: EValueType.value, equipment: EEquipment.MV_3W_PF, value: '13%' },
  { systemType: SystemType.MixingLoop, type: EValueType.value, equipment: EEquipment.MV_3W_PR, value: '13%' },
  { systemType: SystemType.MixingLoop, type: EValueType.setPoint, equipment: EEquipment.MV_2W_PF, value: '24%' },
  { systemType: SystemType.MixingLoop, type: EValueType.setPoint, equipment: EEquipment.MV_2W_PR, value: '24%' },
  { systemType: SystemType.MixingLoop, type: EValueType.setPoint, equipment: EEquipment.MV_3W_PF, value: '24%' },
  { systemType: SystemType.MixingLoop, type: EValueType.setPoint, equipment: EEquipment.MV_3W_PR, value: '24%' },
  { systemType: SystemType.MixingLoop, type: EValueType.value, equipment: EEquipment.P_SF, value: '99.7\u33a5/h\n10.7m' },
  { systemType: SystemType.MixingLoop, type: EValueType.value, equipment: EEquipment.P_SR, value: '99.7\u33a5/h\n10.7m' },
];
