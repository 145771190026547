import { Component } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { AppError, AuthService, ConfigurationService } from 'shared';
import { Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { IUser, UserType } from 'projects/customerportal/src/app/interfaces/user';
import { AlarmService } from '../../services/alarm.service';
import { PageInfoService } from '../../services/page-info.service';
import { AccessClaim } from 'projects/shared/src/lib/interfaces/access';

@Component({
  selector: 'app-root-page',
  templateUrl: './root-page.component.html',
  styleUrls: ['./root-page.component.scss']
})
export class RootPageComponent {
  public userName$: Observable<string>;
  public accountAvailable$: Observable<boolean>;
  public userManagementAvailable$: Observable<boolean>;
  public pageError$: Observable<AppError>;
  public currentLanguage$: Observable<string>;
  public currentUser$: Observable<IUser | null>;

  constructor(
    private authService: AuthService,
    private alarmService: AlarmService,
    public userService: UserService,
    public router: Router,
    public configurationService: ConfigurationService,
    public pageInfoService: PageInfoService,
  ) {
    this.userName$ = userService.currentUser$.pipe(
        first(),
        map(user => user?.name || '')
    );
    this.accountAvailable$ = userService.currentUser$.pipe(
        first(),
        map(user => user?.type === UserType.BuildingConnect)
    );
    this.userManagementAvailable$ = this.userService.hasClaim(AccessClaim.CustomerPortalUserManagement);

    this.currentUser$ = this.userService.currentUser$.pipe(first());
  }

  navigateToRoot() {
    this.router.navigate(['/facilities'], {queryParamsHandling: 'merge', queryParams: this.pageInfoService.mapQueryParams});
  }

  public signin() {
    this.authService.signinRedirect('/');
  }

  logout(): void {
    this.authService.signout();
  }

}
