<gbc-tile [title]="'installation-system-status.title' | translate">
  <div *ngIf="showSettings" class="title-right" gbc-tile-right>
    <button gbc-icon-button gbc-tile-right (click)="settingsClick.emit($event)" data-test-id="alarm-settings-button">
      <img src="./assets/svgs/adjust-outline-black.svg" alt="sytem notification settings">
    </button>
  </div>
  <app-alarm-table
    (alarmClick)="alarmClick.emit($event)"
    [clickable]="true"
    minHeight="180px" [filter]="alarmFilter"
    [noAlarmsMessage]="'installation-system-status.no-alarms-message' | translate">
  </app-alarm-table>
  <gbc-tile-footer>
    <a (click)="showAllAlarms.emit()"
      angulartics2On="click"
      angularticsAction="showAllNotificationsShown"
      angularticsCategory="plantroom"
      class="link-button">
    {{'installation-system-status.show-all-alarms' | translate}}</a>
  </gbc-tile-footer>
</gbc-tile>
