<gbc-modal
  (dismiss)='dismiss()'
  [title]="'mixit-heat-coil-protection-modal.heat-coil-protection' | translate"
  data-test-id='mixit-heat-coil-protection-modal'
>
  <gbc-modal-content>
    <form [formGroup]='form'>
      <h2 class='modal-header'>
        {{ 'mixit-heat-coil-protection-modal.coil-preheat' | translate }}
      </h2>

      <p class='modal-text'>
        {{ 'mixit-heat-coil-protection-modal.coil-preheat-description' | translate }}
      </p>

      <gbc-input-container>
        <gbc-input-label>{{ 'app-enable' | translate }}</gbc-input-label>
        <gbc-toggle formControlName='enableCoilPreheatReturnTemperature'></gbc-toggle>
      </gbc-input-container>

      <gbc-input-container>
        <gbc-input-label> {{ 'mixit-heat-coil-protection-modal.return-temperature' | translate }}</gbc-input-label>
        <gbc-input-field
          [errorDescriptionData]='coilPreheatTemperatureMinMax'
          [max]='coilPreheatTemperatureMinMax.max'
          [min]='coilPreheatTemperatureMinMax.min'
          [showErrorTranslationString]='true'
          errorDescriptionTranslationString='app-enter-value-between'
          formControlName='coilPreheatReturnTemperature'
          inputType='number'
          unit='°C'
        ></gbc-input-field>
      </gbc-input-container>

      <h2 class='modal-header'>
        {{ 'mixit-heat-coil-protection-modal.frost-protection' | translate }}
      </h2>

      <p class='modal-text'>
        {{ 'mixit-heat-coil-protection-modal.frost-protection-description' | translate }}
      </p>

      <gbc-input-container>
        <gbc-input-label>{{ 'app-enable' | translate }}</gbc-input-label>
        <gbc-toggle formControlName='enableFrostProtection'></gbc-toggle>
      </gbc-input-container>

      <gbc-input-container>
        <gbc-input-label> {{ 'mixit-heat-coil-protection-modal.air-temperature' | translate }}</gbc-input-label>
        <gbc-input-field
          [errorDescriptionData]='frostProtectionAirTemperatureMinMax'
          [max]='frostProtectionAirTemperatureMinMax.max'
          [min]='frostProtectionAirTemperatureMinMax.min'
          [showErrorTranslationString]='true'
          errorDescriptionTranslationString='app-enter-value-between'
          formControlName='frostProtectionAirTemperature'
          inputType='number'
          unit='°C'
        ></gbc-input-field>
      </gbc-input-container>

      <gbc-input-container>
        <gbc-input-label> {{ 'mixit-heat-coil-protection-modal.return-flow-temperature' | translate }}</gbc-input-label>
        <gbc-input-field
          [errorDescriptionData]='frostProtectionReturnFlowTemperatureMinMax'
          [max]='frostProtectionReturnFlowTemperatureMinMax.max'
          [min]='frostProtectionReturnFlowTemperatureMinMax.min'
          [showErrorTranslationString]='true'
          errorDescriptionTranslationString='app-enter-value-between'
          formControlName='frostProtectionReturnFlowTemperature'
          inputType='number'
          unit='°C'
        ></gbc-input-field>
      </gbc-input-container>
    </form>
  </gbc-modal-content>
  <gbc-modal-buttons>
    <button
      (click)='close()'
      angulartics2On='click'
      angularticsAction='cancelClicked'
      angularticsCategory='adjustTemperatureSetpointConfigurationsShown'
      gbc-modal-button
    >
      {{ 'app-cancel' | translate }}
    </button>

    <button
      (click)='save()'
      [disabled]='(valid$ | async) === false'
      angulartics2On='click'
      angularticsAction='saveClicked'
      angularticsCategory='heatCoilProtectionShown'
      class='primary'
      gbc-modal-button
    >
      {{ 'app-save' | translate }}
    </button>
  </gbc-modal-buttons>
</gbc-modal>
