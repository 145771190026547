import { Component, EventEmitter, Output, TemplateRef, ViewChild } from "@angular/core";
import { MenuPanel } from "../../directives/menu-trigger-for.directive";

@Component({
  selector: 'gbc-menu',
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"]
})
export class MenuComponent implements MenuPanel {
  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;
  @Output() closed = new EventEmitter<void>();

  constructor () {}

  handleClick () {
    this.closed.emit();
  }
}
