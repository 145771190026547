import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-tile',
  templateUrl: './content-tile.component.html',
  styleUrls: ['./content-tile.component.scss'],
})
export class ContentTileComponent {
  @Input() preTitle: string;
  @Input() title: string;
}
