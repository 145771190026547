import { LabelPosition, SystemMeta } from '../../types';
import { SmallLabelDeltas } from '../../../util/constants';
import { EConnectionType, SystemType } from '../../../state';
import { EConnectionPointPlacement, EEquipment } from '../../../types';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/hot-water-pump.svg';
import hotWaterPumpSystemForm from '../../../../models/systems/hotWaterPumpSystem/hotWaterPumpSystemForm.json';

export const HEIGHT = 140;
export const WIDTH = 140;
export const PIPE_Y = 80;
export const PUMP_X = WIDTH / 2;

export const hotWaterPumpMeta: SystemMeta = {
  systemType: SystemType.HotWaterPump,
  name: 'Pump',
  thumbnail,
  form: hotWaterPumpSystemForm,
  width: WIDTH,
  height: HEIGHT,
  calculateConnectionPoints: () => [
    {
      key: EConnectionPointPlacement.connectionPrimaryForward,
      x: 0,
      y: PIPE_Y,
      type: EConnectionType.supply,
      dotted: false,
    },
    {
      key: EConnectionPointPlacement.connectionSecondaryForward,
      x: WIDTH,
      y: PIPE_Y,
      type: EConnectionType.supply,
      dotted: false,
    },
  ],
};

export const getHotWaterPumpLabels = (): LabelPosition[] => {
  const fPump: LabelPosition = {
    id: EEquipment.P_F,
    x: PUMP_X,
    y: PIPE_Y + SmallLabelDeltas.WEST_Y + 4,
    horizontalAlignment: 'middle',
  };

  return [fPump];
};
