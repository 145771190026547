import { combineLatest, Observable } from 'rxjs';
import { ChartInterface } from '../chart.interface';
import { TranslateService } from '@ngx-translate/core';
import { Application } from '../../../interfaces/alarm';
import { Options, SeriesOptionsType } from 'highcharts';
import { Component, Input, OnInit } from '@angular/core';
import { getDatapoint } from '../../../utils/mixit-utils';
import { MixitDataPointName } from '../../../interfaces/mixit';
import { convertWtoKW } from '../../../utils/data-point-utils';
import { first, map, shareReplay, flatMap } from 'rxjs/operators';
import { DataPointsResult } from '../../../interfaces/data-points';
import { SystemDeviceType } from '../../../interfaces/systemDeviceType';
import { UserTrackingPeriodOptions } from '../../../services/user-tracking-helper.service';
import { DataPointsService, ReportPeriod, TimeSerie, TimeSeriesDetails } from '../../../services/data-points.service';

@Component({
  selector: 'app-mixit-thermal-power-chart[installationId][applicationId]',
  templateUrl: './mixit-thermal-power-chart.component.html',
  styleUrls: ['./mixit-thermal-power-chart.component.scss'],
})
export class MixitThermalPowerChartComponent implements OnInit, ChartInterface {
  @Input() dataPoints$: Observable<DataPointsResult>;
  @Input() applicationId: string;
  @Input() installationId: string;
  @Input() userTrackingPeriodOptions: UserTrackingPeriodOptions;

  public options: Options;
  public dataPointsForChart$: Observable<any>;
  public installationId$: Observable<string>;
  public getSeries: (period: ReportPeriod) => Observable<SeriesOptionsType[]>;

  constructor(private translateService: TranslateService, private dataPointsService: DataPointsService) {
    this.options = {
      colors: ['#0068b4', '#0068b4', '#E43535'],
      yAxis: [
        {
          title: {
            text: null,
          },
          labels: {
            format: '{value} kW',
          },
          min: 0,
        },
        {
          title: {
            text: null,
          },
          labels: {
            format: '{value} w',
          },
          min: 0,
          opposite: true,
        },
      ],
    };
  }

  ngOnInit() {
    const thermalPowerFlowAverageLegend$ = this.translateService.get('mixit-thermal-power-chart.thermal-power-flow-average-legend');
    const thermalPowerFlowAverage$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.ThermalPowerFlowAverage
    ).pipe(first());

    const thermalPowerFlowAverageCalibratedLegend$ = this.translateService.get(
      'mixit-thermal-power-chart.thermal-power-flow-average-legend'
    );
    const thermalPowerFlowAverageCalibrated$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.ThermalPowerFlowAverageCalibrated
    ).pipe(first());

    const pumpPowerConsumptionAverageLegend$ = this.translateService.get('mixit-thermal-power-chart.pump-power-consumption-average-legend');
    const pumpPowerConsumptionAverageDataPoint$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.PumpPowerConsumptionAverage
    ).pipe(first());

    this.dataPointsForChart$ = combineLatest([
      thermalPowerFlowAverage$,
      thermalPowerFlowAverageCalibrated$,
      pumpPowerConsumptionAverageDataPoint$,
    ]);

    this.getSeries = (period: ReportPeriod) => {
      return combineLatest([
        thermalPowerFlowAverage$,
        thermalPowerFlowAverageCalibrated$,
        pumpPowerConsumptionAverageDataPoint$,
        thermalPowerFlowAverageLegend$,
        thermalPowerFlowAverageCalibratedLegend$,
        pumpPowerConsumptionAverageLegend$,
      ]).pipe(
        flatMap(
          ([
            thermalPowerFlowAverage,
            thermalPowerFlowAverageCalibrated,
            pumpPowerConsumptionAverageDataPoint,
            thermalPowerFlowAverageLegend,
            thermalPowerFlowAverageCalibratedLegend,
            pumpPowerConsumptionAverageLegend,
          ]) => {
            const timeSeriesDetails: TimeSeriesDetails = {
              dataPoints: [thermalPowerFlowAverage, thermalPowerFlowAverageCalibrated, pumpPowerConsumptionAverageDataPoint],
              period,
              installationId: this.installationId,
              systemId: this.applicationId,
              requestId: 'energy_trend_data',
            };
            return this.dataPointsService.getTimeSeries(timeSeriesDetails).pipe(
              map((seriesData: TimeSerie[]) => {
                const series: SeriesOptionsType[] = [];
                if (seriesData[0]) {
                  series.push({
                    name: thermalPowerFlowAverageLegend,
                    data: convertWtoKW(seriesData[0]),
                    type: 'line',
                    tooltip: { valueSuffix: ' kW' },
                  } as SeriesOptionsType);
                }
                if (seriesData[1]) {
                  series.push({
                    name: thermalPowerFlowAverageCalibratedLegend,
                    data: convertWtoKW(seriesData[1]),
                    type: 'line',
                    tooltip: { valueSuffix: ' kW' },
                  } as SeriesOptionsType);
                }
                if (seriesData[2]) {
                  series.push({
                    name: pumpPowerConsumptionAverageLegend,
                    data: seriesData[2],
                    type: 'line',
                    tooltip: { valueSuffix: ' W' },
                    yAxis: 1,
                  } as SeriesOptionsType);
                }
                return series;
              })
            );
          }
        ),
        shareReplay(1)
      );
    };
  }
}
