import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subscription } from 'rxjs';
import { AppError, AppErrorService, ModalService, PageInfo } from 'shared';
import { Facility, Installation } from 'projects/customerportal/src/app/interfaces/facilty';
import { first, flatMap, map, shareReplay, take, tap } from 'rxjs/operators';
import { PageInfoService } from 'projects/customerportal/src/app/services/page-info.service';
import { SchematicsService } from 'projects/customerportal/src/app/services/schematics.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataPointsResult } from 'projects/customerportal/src/app/interfaces/data-points';
import { DataPointsService } from 'projects/customerportal/src/app/services/data-points.service';
import { dataPointsToDumbledoreFormat } from 'projects/customerportal/src/app/components/schematics-tile/schematics-tile.component';
import { TranslateService } from '@ngx-translate/core';
import { AlarmType } from 'projects/customerportal/src/app/interfaces/system-status';
import { maxBy, sortBy } from 'lodash';
import { flattenDataPoints } from 'projects/customerportal/src/app/utils/data-point-utils';
import { AppModel } from '@ams/dumbledore';
import { getDatapoint, isLessThanHalfHourSinceClaim, isSchematicTypeOfSystem } from '../../utils/mixit-utils';
import { navigateToInstallation } from '../../utils/navigate-utils';
import { FacilityService } from '../../services/facility.service';
import { GroupType, SocketService } from '../../services/socket.service';
import { MixitDataPointName } from '../../interfaces/mixit';
import { AlarmOverview, Application } from '../../interfaces/alarm';
import { AlarmService } from '../../services/alarm.service';
import { UserTrackingHelperService } from '../../services/user-tracking-helper.service';
import { SystemDeviceType } from '../../interfaces/systemDeviceType';

export interface DataForInstallation {
  [k: string]: {
    lastUpdated: number;
    dataPoints: DataPointsResult;
  };
}

@Component({
  selector: 'app-mixit-facility-page',
  templateUrl: './mixit-facility-page.component.html',
  styleUrls: ['./mixit-facility-page.component.scss'],
})
export class MixitFacilityPageComponent implements OnInit, OnDestroy {
  public facility$: Observable<Facility>;
  public hasInstallations$: Observable<boolean>;

  public pageError$: Observable<AppError>;
  public pageInfo$: Observable<PageInfo>;
  public alarm$: Observable<AlarmOverview>;

  public showAverageTiles$: Observable<boolean>;
  public outDoorTemperature$: Observable<string>;
  public averageDeltaT$: Observable<string>;

  public islessThanHalfHourSinceClaim$: Observable<boolean>;

  public schematics$: Observable<
    {
      isConnect: boolean;
      isDynamic: boolean;
      installation: Installation;
      appModel: AppModel;
      alarm: { type: AlarmType | null; systemId: string };
    }[]
  >;
  public data$: Observable<DataForInstallation>;

  public commissionState$: Observable<string | null>;

  private mostImportantAlarmByInstallationSource: BehaviorSubject<Map<string, Observable<AlarmOverview>>> = new BehaviorSubject<
    Map<string, Observable<AlarmOverview>>
  >(new Map());
  public mostImportantAlarmByInstallation$: Observable<Map<string, Observable<AlarmOverview>>> =
    this.mostImportantAlarmByInstallationSource.asObservable();
  private mostImportantAlarmByInstallationSubscription: Subscription;

  constructor(
    private errorService: AppErrorService,
    private pageInfoService: PageInfoService,
    private schematicsService: SchematicsService,
    private dataPointsService: DataPointsService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private facilityService: FacilityService,
    private socketService: SocketService,
    private alarmService: AlarmService,
    private modalService: ModalService,
    private userTrackingHelperService: UserTrackingHelperService
  ) {}

  ngOnInit(): void {
    this.facility$ = this.facilityService.getCurrentFacility(this.route);

    this.hasInstallations$ = this.facility$.pipe(map((facility) => !!facility.installations.length));

    // Subscribe to data and alarms for installations under this facility
    this.facility$.pipe(first()).subscribe((facility) => {
      const ids = facility.installations.map((i) => i.id);
      this.socketService.subscribeMultiple(ids, [GroupType.Schematic, GroupType.Installation]);
    });

    const installations$ = this.facility$.pipe(
      first(),
      map((f) => f.installations),
      shareReplay()
    );

    // We use an empty list of applications when we generate alarm objects (AlarmOverview), which means any alarm objects for the facility's installations
    // will be missing info about the application. However, the alarm objects are only used to show alarm bars which don't use that info anayway.
    const applications$: Observable<Application[]> = of([]);
    this.alarmService.setup(this.facility$, installations$, applications$);

    this.mostImportantAlarmByInstallationSubscription = this.alarmService.mostImportantAlarmByInstallation$
      .pipe(
        tap((mostImportantAlarms) => {
          this.mostImportantAlarmByInstallationSource.next(mostImportantAlarms as Map<string, Observable<AlarmOverview>>);
        })
      )
      .subscribe();

    this.pageError$ = this.errorService.createPageErrorObservable([this.facility$]);
    this.pageInfo$ = this.facility$.pipe(
      map((facility) => {
        return this.pageInfoService.facility(facility);
      })
    );

    this.data$ = this.dataPointsService.dataPoints$.pipe(
      map((dataResult) => {
        const result: DataForInstallation = {};

        if (!dataResult || !dataResult.length) {
          return result;
        }

        for (const dataPoints of dataResult) {
          const mostRecentDataPoint = maxBy(flattenDataPoints(dataPoints.data), (dataPoint) => dataPoint.timestampEpoch);
          result[dataPoints.installationId] = {
            dataPoints,
            lastUpdated: mostRecentDataPoint?.timestampEpoch as number,
          };
        }
        return result;
      })
    );

    const dataAsArray$: Observable<
      {
        lastUpdated: number;
        dataPoints: DataPointsResult;
      }[]
    > = this.data$.pipe(
      map((data) => {
        const result = [];
        for (const entry in data) {
          if (data.hasOwnProperty(entry)) {
            result.push(data[entry]);
          }
        }
        return result;
      })
    );

    const schematicsForFacility$ = this.facility$.pipe(
      first(),
      flatMap((facility) => {
        return this.schematicsService.getSchematicsForFacility(facility);
      }),
      shareReplay()
    );

    // If device is not Live, a status should still be displayed if the installation is being commissioned.

    this.schematics$ = this.facility$.pipe(
      flatMap((facility) => {
        return schematicsForFacility$.pipe(
          map((installationsWithSchematic) => {
            // Order installations by name
            const sorted = sortBy(installationsWithSchematic, ({ installation }) => installation.name);
            return sorted.map(({ installation: i, appModel }) => {
              // The installation that comes from the schematic call, is not updated, but the installation from the facility is
              // So we find the installation on the facility and use the alarm state from that one
              const installation = facility.installations.find((ins) => ins.id === i.id);

              return {
                isConnect: appModel ? isSchematicTypeOfSystem(appModel, 'Connect') : false,
                isDynamic: appModel ? isSchematicTypeOfSystem(appModel, 'Dynamic') : false,
                installation: installation as Installation,
                appModel,
                alarm: {
                  type: installation?.state?.type || null,
                  systemId: appModel?.systems[0].hasId || '',
                },
              };
            });
          })
        );
      })
    );

    this.showAverageTiles$ = this.schematics$.pipe(map((schematics) => schematics.length > 1));

    this.outDoorTemperature$ = dataAsArray$.pipe(
      map((data) => {
        const values = data
          .map((i) => {
            const formatted = dataPointsToDumbledoreFormat(i.dataPoints.data, this.translateService);
            return formatted.find((f) => f.equipment === 'MIXIT_OUTDOOR')?.value || '-';
          })
          .map((value) => {
            return value.replace(' °C', '');
          })
          .map((value) => {
            return Number(value);
          });
        const numberVal = values.reduce((a, b) => a + b, 0) / data.length;
        if (isNaN(numberVal)) {
          return '-';
        }
        return numberVal.toFixed(2).toString();
      })
    );

    this.averageDeltaT$ = dataAsArray$.pipe(
      flatMap((data) => {
        const deltas = data.map((d) => {
          return getDatapoint(of(d.dataPoints), SystemDeviceType.MixitSystem, MixitDataPointName.DeltaTemp);
        });
        return forkJoin(deltas);
      }),
      map((deltaTemps) => {
        if (!deltaTemps.length) {
          return '-';
        }

        let accumulated = 0;
        for (const d of deltaTemps) {
          const parsed = parseFloat(d?.value as string);
          if (Number.isNaN(parsed)) {
            return '-';
          }
          accumulated += parsed;
        }

        const average = accumulated / deltaTemps.length;
        return `${average.toFixed(2)} °C`;
      })
    );

    this.commissionState$ = this.facility$.pipe(
      map((facility) => {
        if (facility.installations.some((installation) => installation.commissionStatus === 'Error')) {
          return 'Error';
        }
        return null;
      })
    );

    this.islessThanHalfHourSinceClaim$ = this.facility$.pipe(
      flatMap((facility) => {
        return combineLatest(facility.installations.map((i) => isLessThanHalfHourSinceClaim(of(i))));
      }),
      map((array) => array.some((v) => v))
    );
  }

  ngOnDestroy() {
    this.mostImportantAlarmByInstallationSubscription?.unsubscribe();

    this.facility$.pipe(first()).subscribe((facility) => {
      const ids = facility.installations.map((i) => i.id);
      this.socketService.unsubscribe(ids, GroupType.Installation);
      this.socketService.unsubscribe(ids, GroupType.Schematic);
    });
  }

  handleClick(installation: Installation): void {
    navigateToInstallation(this.router, this.route.snapshot.params.facilityId, installation.id);
  }

  editFacility() {
    this.router.navigate(['/edit-facility', this.route.snapshot.params.facilityId]);
  }

  editInstallation(installationId: string) {
    this.router.navigate(['/edit-installation', this.route.snapshot.params.facilityId, installationId]);
  }

  deleteFacility() {
    this.facility$.pipe(take(1)).subscribe((facility) => {
      // Check if there are installations under the facility
      if (facility.installations.length) {
        // Show dialog with message
        this.modalService.showTextModal({
          title: this.translateService.instant('mixit-facility-page.cannot-delete-facility'),
          content: this.translateService.instant('mixit-facility-page.cannot-delete-facility-description'),
          actions: [{ text: this.translateService.instant('app-ok'), type: 'primary' }],
        });
        return;
      }

      this.modalService.showTextModal({
        title: this.translateService.instant('mixit-facility-page.confirm-delete-facility'),
        content: this.translateService.instant('mixit-facility-page.confirm-delete-facility-description'),
        actions: [
          {
            text: this.translateService.instant('app-delete'),
            type: 'danger',
            handler: () => {
              this.facilityService.deleteEmptyFacility(facility).subscribe((_) => {
                this.router.navigate(['/facilities']);
              });
              this.userTrackingHelperService.trackUserAction('deleteFacilityClicked', 'deleteConfirmClicked');
            },
          },
          {
            cancel: true,
            text: this.translateService.instant('app-cancel'),
            handler: () => {
              this.userTrackingHelperService.trackUserAction('deleteFacilityClicked', 'deleteCancelClicked');
            },
          },
        ],
      });
    });
  }
}
