export interface AlarmRoot {
  installationId: string;
  alarmSources: AlarmSource[];
}

export interface AlarmSource {
  sourceId: string;
  sourceType: AlarmSourceType;
  subsourceId: string;
  systemId: string;
  alarms: Alarm[];
}

export enum AlarmSourceType {
  Application = 'Application', // Mixing loop
  Device = 'Device', // Pump, heat meter
  Installation = 'Installation', // Used by cloud alarms (IoT hub)
}

export interface AlarmActivation {
  activatedTimestampEpoch: number;
  clearedTimestampEpoch?: number;
  overriddenTimestampEpoch?: number;
}

export interface AlarmBase {
  id: number;
  type: AlarmType;
  errorCode: string;
  severity: number;
  message: string;
  alarmComments: AlarmComment[];
}

export interface Alarm extends AlarmBase {
  latestActivation: AlarmActivation;
}

export interface AlarmDetailsResponse {
  sourceId: string;
  sourceType: AlarmSourceType;
  subsourceId: string;
  alarm: AlarmDetails;
}

export interface AlarmDetails extends AlarmBase {
  alarmActivations: AlarmActivation[];
}

export interface AlarmComment {
  username: string;
  timestampEpoch: number;
  comment: string;
}

export enum AlarmType {
  Alarm = 'Alarm',
  Warning = 'Warning',
  Information = 'Information',
  Log = 'Log',
}

export enum AlarmStateType {
  Active = 'Active',
  ManualOverride = 'ManualOverride',
  Cleared = 'Cleared',
}
