import { Injectable } from '@angular/core';
import { AppErrorService, ICompany } from 'shared';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(private httpClient: HttpClient, private appErrorService: AppErrorService) { }

  getCompany(companyId: number) {
    return this.httpClient.get<ICompany>(`${environment.serverUrl}/api/companies/${companyId}`).pipe(
      this.appErrorService.catchApiError({
        fallbackMessageKey: 'company-service.get-company-failed',
        errorCodes: {
          403: {
            messageKey: 'company-service.get-company-not-allowed',
          },
        },
      })
    );
  }
}
