import { ErrorHandler, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { concat, MonoTypeOperatorFunction, Observable, of, throwError } from 'rxjs';
import { catchError, first, flatMap, shareReplay, take } from 'rxjs/operators';
import merge from 'merge';

export interface AppErrorOptions {
  userTitle?: string;
  userMessage: string;
  technicalMessage?: string;
  cause?: Error;
}

export interface CreateErrorOptions {
  titleKey?: string;
  messageKey: string;
  technicalMessage?: string;
  cause?: Error;
  report?: boolean;
}

export interface CatchApiErrorOptions {
  fallbackTitleKey?: string;
  fallbackMessageKey?: string;
  errorCodes?: ICodeToMessageKeysMap;

}

export class AppError extends Error {
  userTitle?: string;
  userMessage: string;
  cause?: string;

  constructor({userTitle, userMessage, technicalMessage, cause}: AppErrorOptions) {
    let message = technicalMessage ? technicalMessage : userMessage;
    message += '\n';
    if (cause) {
      message += `Caused by: ${cause.message}\n`;
      if (cause.stack) {
        message += cause.stack;
      }
    }
    super(message);
    Object.assign(this, {userTitle, userMessage, cause});
  }
}

export type ICodeToMessageKeysMap = Record<string | number, { titleKey?: string, messageKey: string }>;

export const NOT_FOUND_ERROR_CODE = {
  404: {
    titleKey: 'error-service.not-found-title',
    messageKey: 'error-service.not-found-message'
  }
};

export const FORBIDDEN_ERROR_CODE = {
  403: {
    titleKey: 'error-service.forbidden-title',
    messageKey: 'error-service.forbidden-message'
  }
};

export const RATE_LIMIT_ERROR_CODE = {
  429: {
    titleKey: 'error-service.too-many-requests-title',
    messageKey: 'error-service.too-many-requests-message'
  }
};

@Injectable({
  providedIn: 'root'
})
export class AppErrorService {

  constructor(private translateService: TranslateService, private errorHandler: ErrorHandler) {
  }

  public createError({titleKey, messageKey, technicalMessage, cause, report}: CreateErrorOptions) {
    const userTitle = titleKey ? this.translateService.instant(titleKey) : undefined;
    const userMessage = this.translateService.instant(messageKey);
    technicalMessage = technicalMessage || messageKey;
    const error = new AppError({userTitle, userMessage, technicalMessage, cause});
    if (report === undefined || report) {
      this.errorHandler.handleError(error);
    }
    return error;
  }

  fromHttpError(error: HttpErrorResponse, defaultErrorMessageKey: string, errorCodeToTranslationKeys?: ICodeToMessageKeysMap) {
    // Check if the response returned an error code and if we have a message corresponding to that error code
    const errorCode = error.headers?.get('ErrorCode');
    if (errorCode) {
      const messageKeys = errorCodeToTranslationKeys ? errorCodeToTranslationKeys[errorCode] : undefined;
      if (messageKeys) {
        return this.createError(Object.assign({}, messageKeys, {
          technicalMessage: 'Server API call returned error code',
          cause: error,
          report: false
        }));
      }
    }

    // Check if we have an error message corresponding to the status code
    const messageKeysFromStatus = errorCodeToTranslationKeys ? errorCodeToTranslationKeys[error.status] : undefined;
    if (messageKeysFromStatus) {
      return this.createError(Object.assign({}, messageKeysFromStatus, {
        technicalMessage: 'Server API call returned error code',
        cause: error,
        report: false
      }));
    }

    return this.createError(Object.assign({}, {titleKey: 'generic-error.title', messageKey: defaultErrorMessageKey}, {
      technicalMessage: 'Server API call returned error code',
      cause: error,
      report: error.status !== 0 // status === 0 means some kind of network error, we do not report these to sentry
    }));
  }

  catchApiError<T>(options: CatchApiErrorOptions) {
    const {fallbackTitleKey, fallbackMessageKey, errorCodes} = merge.recursive({
      fallbackTitleKey: 'generic-error.title',
      fallbackMessageKey: 'generic-error.message',
      errorCodes: {
        ...RATE_LIMIT_ERROR_CODE,
        ...NOT_FOUND_ERROR_CODE,
        ...FORBIDDEN_ERROR_CODE
      },
    }, options);
    const operator: MonoTypeOperatorFunction<T> = (observable) => {
      return observable.pipe(
          catchError(err => {
            if (err instanceof HttpErrorResponse) {
              return throwError(this.fromHttpError(err, fallbackMessageKey, errorCodes));
            } else {
              return throwError(this.createError({
                messageKey: fallbackMessageKey,
                technicalMessage: err?.toString(),
                cause: err
              }));
            }
          })
      );
    };
    return operator;
  }

  createPageErrorObservable(observables: Observable<unknown>[], parentPageError?: Observable<AppError>): Observable<AppError> {
    const emptyObservables = observables.map(observable => observable && observable.pipe(
        first(), // some of the observables don't complete, so we just wait for the first
        flatMap(() => of() as Observable<AppError>))
    );
    return concat(...[...(parentPageError ? [parentPageError] : []), ...emptyObservables]).pipe(
        catchError(err => {
          if (err instanceof AppError) {
            return of(err);
          } else if (err instanceof HttpErrorResponse) {
            return of(this.fromHttpError(err, 'generic-error.message'));
          } else {
            return of(this.createError({
              titleKey: 'generic-error.title',
              messageKey: 'generic-error.message',
              cause: err,
              report: true,
              technicalMessage: err?.toString()
            }));
          }
        }),
        take(1),
        shareReplay()
    );
  }

}
