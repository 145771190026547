import { Component } from '@angular/core';
import { ModalService } from 'shared';
import { UserRoleExplainerModalComponent } from '../user-role-explainer-modal/user-role-explainer-modal.component';

@Component({
  selector: 'app-user-role-explainer-toggle-link',
  templateUrl: './user-role-explainer-toggle-link.component.html',
  styleUrls: ['./user-role-explainer-toggle-link.component.scss'],
})
export class UserRoleExplainerToggleLinkComponent {

  constructor(private modalService: ModalService) {}

  openUserRoleExplainer() {
    this.modalService.openDialog(UserRoleExplainerModalComponent, {});
  }
}
