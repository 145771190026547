import { Component, Inject } from '@angular/core';
import { UserTrackingHelperService } from 'projects/customerportal/src/app/services/user-tracking-helper.service';
import { MODAL_DATA } from 'projects/shared/src/lib/interfaces/modal';

export interface MixitModalData {
  Name: string;
  Orientation: string;
  ProductType: string;
  ProductNumber: number;
  ProductId: string;
  Model: string;
  ProductionCode: string;
  BLE: string;
  Firmware: string;
}

@Component({
  selector: 'app-mixit-product-info-modal',
  templateUrl: './mixit-product-info-modal.component.html',
  styleUrls: ['./mixit-product-info-modal.component.scss']
})
export class MixitProductInfoModalComponent {
  constructor(
    @Inject(MODAL_DATA) public data: MixitModalData,
    private userTrackingHelperService: UserTrackingHelperService
  ) {}

  openProductCatalog() {
    this.userTrackingHelperService.trackUserAction(
      'mixitProductInfoIconClicked',
      'readMoreClicked'
    );
  }
  dismiss() {
    this.userTrackingHelperService.trackUserAction(
      'mixitProductInfoIconClicked',
      'exitClicked'
    );
  }
}
