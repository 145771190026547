import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { Facility, Installation } from '../../../interfaces/facilty';
import { ActivatedRoute } from '@angular/router';
import { AlarmOverview } from '../../../interfaces/alarm';
import { isOverridden } from '../../../utils/alarm-utils';
import { FacilityService } from '../../../services/facility.service';
import { AlarmService } from '../../../services/alarm.service';

@Component({
  selector: 'app-alarm-table',
  templateUrl: './alarm-table.component.html',
  styleUrls: ['./alarm-table.component.scss']
})
export class AlarmTableComponent implements OnInit {
  alarms$: Observable<AlarmOverview[]>;
  visible: Observable<boolean>;

  @Input() minHeight: string;
  @Input() filter?: (alarm: AlarmOverview) => boolean;
  @Input() noAlarmsMessage?: string;
  @Input() clickable = false;

  @Output()
  public alarmClick = new EventEmitter<AlarmOverview>();

  public installation$: Observable<Installation>;
  public facility$: Observable<Facility>;

  public manuallyOverriden(alarm: AlarmOverview): boolean {
    return isOverridden(alarm);
  }

  constructor(
    private facilityService: FacilityService,
    private alarmService: AlarmService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.facility$ = this.facilityService.getCurrentFacility(this.route);
    this.installation$ = this.facilityService.getCurrentInstallation(this.route);

    this.alarms$ = this.alarmService.alarms$.pipe(
        map(alarms => {
          const filteredAlarms = alarms.filter(alarm => this.filter ? this.filter(alarm) : true);
          filteredAlarms.sort((a, b) => b.currentActivation.activatedTimestampEpoch - a.currentActivation.activatedTimestampEpoch);
          return filteredAlarms;
        }),
        shareReplay({refCount: true})
    );
    this.visible = this.alarms$.pipe(
        map(alarms => alarms.length > 0)
    );
  }

  trackByAlarm(index: number, item: AlarmOverview) {
    return item.id;
  }
}
