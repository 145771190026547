import { Observable } from 'rxjs';
import { isUserLoggedInToken } from 'shared';
import { map } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { UserType } from '../interfaces/user';

export const isUserLoggedInProvider = {
  provide: isUserLoggedInToken,
  useFactory: (userService: UserService): Observable<boolean> => {
    return userService.currentUser$.pipe(map(u => u?.type === UserType.BuildingConnect));
  },
  deps: [UserService]
};
