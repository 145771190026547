import { Component, Input, OnInit } from '@angular/core';
import { AlarmType } from '../../../interfaces/system-status';
import { Observable } from 'rxjs';
import { alarmStateToIconLink } from '../../../utils/alarm-utils';
import { map } from 'rxjs/operators';
import { AlarmOverview } from '../../../interfaces/alarm';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alarm-bar',
  templateUrl: './alarm-bar.component.html',
  styleUrls: ['./alarm-bar.component.scss'],
})
export class AlarmBarComponent implements OnInit {
  @Input() alarm: Observable<AlarmOverview>;
  @Input() showAlarmName = false;

  public alarmClass: Observable<string>;
  public alarmTitle: Observable<string>;
  public showDetailedInfo: Observable<boolean>;

  public alarmStateToIconLink = alarmStateToIconLink;

  constructor(private route: ActivatedRoute, private router: Router, private translate: TranslateService) {}

  ngOnInit() {
    this.alarmClass = this.alarm.pipe(map((alarm) => (alarm?.type === AlarmType.Alarm ? 'alarm' : 'warning')));
    this.alarmTitle = this.alarm.pipe(
      map(
        (alarm) =>
          (alarm?.type === AlarmType.Alarm ? this.translate.instant('alarm-bar.alarm') : this.translate.instant('alarm-bar.warning')) +
          (this.showAlarmName && alarm.installationName ? ` - ${alarm.installationName}` : '')
      )
    );
    this.showDetailedInfo = this.alarm.pipe(map((alarm) => alarm?.showDetailedInfo));
  }

  navigate() {
    const { facilityId, installationId } = this.route.snapshot.params;
    this.router.navigate(['/facility', facilityId, 'installation', installationId, 'system-status']);
  }
}
