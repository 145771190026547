import { Component, Inject, OnInit } from '@angular/core';
import { MODAL_CONTROLLER, ModalController } from '../../interfaces/modal';

@Component({
  selector: 'gbc-support-modal',
  templateUrl: './support-modal.component.html',
  styleUrls: ['./support-modal.component.scss']
})
export class SupportModalComponent {

  constructor(
    @Inject(MODAL_CONTROLLER) private controller: ModalController<void>,
  ) { }

  close() {
    this.controller.dismiss();
  }

}
