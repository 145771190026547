import React from 'react';
import { Labels } from '../../Labels';
import { Direction } from '../../Common';
import { PumpIcon } from '../../PumpIcon';
import { FlowMeter } from '../../FlowMeter';
import { Pipe, PipeType } from '../../Pipe';
import { SupplyTriangle } from '../../SupplyTriangle';
import { System, BaseSystemProps } from '../../System';
import * as metaData from './commercial-hot-water-metadata';
import { TemperatureSensor } from '../../TemperatureSensor';
import { BathAndFaucet } from '../../heat-exchanger-common/BathAndFaucet';
import { HeatExchangerCommon } from '../../heat-exchanger-common/HeatExchangerCommon';
import { EXCHANGER_ICON_RIGHT, EXCHANGER_ICON_X, FORWARD_Y, RETURN_Y } from '../../heat-exchanger-common/heat-exchanger-common-metadata';
import { ICommercialHotWaterSystemFormInterface } from '../../../../models/systems/commercialHotWaterSystem/commercialHotWaterSystemFormInterface';

export const CommercialHotWaterSystem: React.FC<BaseSystemProps<ICommercialHotWaterSystemFormInterface>> = React.memo(
  ({ state, values, alarm, selected }) => {
    const systemInfo = state.systemInfo;

    const circulationTempSensor = () => {
      if (systemInfo.T_CR_present) {
        return (
          <g key="T_CR" transform={`translate(${metaData.CIRCULATION_TEMP_SENSOR_X},${RETURN_Y})`}>
            <TemperatureSensor direction={Direction.WEST} />
          </g>
        );
      }
    };

    const coldWaterFlow = () => {
      if (systemInfo.Q_SC_present) {
        return (
          <FlowMeter
            x={metaData.COLD_WATER_X - metaData.COLD_WATER_HORIZONTAL_LENGTH / 2}
            y={RETURN_Y + metaData.COLD_WATER_VERTICAL_LENGTH}
          />
        );
      }
    };

    return (
      <System state={state} meta={metaData.commercialHotWaterMeta} alarm={alarm} selected={selected}>
        <Pipe key="primaryForward" x1={0} y1={FORWARD_Y} x2={EXCHANGER_ICON_X} y2={FORWARD_Y} type={PipeType.WarmSupply} />
        <Pipe key="primaryReturn" x1={0} y1={RETURN_Y} x2={EXCHANGER_ICON_X} y2={RETURN_Y} type={PipeType.ColdReturn} />
        <Pipe
          key="secondaryForward"
          x1={EXCHANGER_ICON_RIGHT}
          y1={FORWARD_Y}
          x2={metaData.SECONDARY_LOOP_RIGHT}
          y2={FORWARD_Y}
          type={PipeType.WarmSupply}
        />
        <Pipe
          key="secondaryReturn"
          x1={EXCHANGER_ICON_RIGHT}
          y1={RETURN_Y}
          x2={metaData.SECONDARY_LOOP_RIGHT}
          y2={RETURN_Y}
          type={PipeType.ColdReturn}
        />
        <Pipe
          key="secondaryVertical"
          x1={metaData.SECONDARY_LOOP_RIGHT}
          y1={FORWARD_Y}
          x2={metaData.SECONDARY_LOOP_RIGHT}
          y2={RETURN_Y}
          type={PipeType.ColdReturn}
        />
        <HeatExchangerCommon state={state.systemInfo} />
        <Pipe
          key="coldWatersupplyVertical"
          x1={metaData.COLD_WATER_X}
          y1={RETURN_Y}
          x2={metaData.COLD_WATER_X}
          y2={RETURN_Y + metaData.COLD_WATER_VERTICAL_LENGTH}
          type={PipeType.ColdSupply}
        />
        <Pipe
          key="coldWaterSupplyHorizontal"
          x1={metaData.COLD_WATER_X - metaData.COLD_WATER_HORIZONTAL_LENGTH}
          y1={RETURN_Y + metaData.COLD_WATER_VERTICAL_LENGTH}
          x2={metaData.COLD_WATER_X}
          y2={RETURN_Y + metaData.COLD_WATER_VERTICAL_LENGTH}
          type={PipeType.ColdSupply}
        />
        <SupplyTriangle
          warm={false}
          x={metaData.COLD_WATER_X - metaData.COLD_WATER_HORIZONTAL_LENGTH + 2}
          y={RETURN_Y + metaData.COLD_WATER_VERTICAL_LENGTH}
          direction={Direction.EAST}
        />
        <BathAndFaucet x={metaData.SECONDARY_LOOP_RIGHT} y={FORWARD_Y} />
        <PumpIcon direction={Direction.WEST} x={EXCHANGER_ICON_RIGHT + metaData.PUMP_X} y={RETURN_Y} />

        {circulationTempSensor()}
        {coldWaterFlow()}
        <Labels positions={metaData.getCommercialHotWaterLabels(systemInfo)} values={values} />
      </System>
    );
  }
);
