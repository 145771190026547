<gbc-page-error-handler [content]="content" [pageError$]="pageError$"></gbc-page-error-handler>
<ng-template #content>
  <gbc-page-header [pageInfo]="pageInfo$ | async"></gbc-page-header>
  <gbc-command-spinner>
    <gbc-page-container data-test-id="edit-facility">
      <form [formGroup]="form">
        <gbc-tile [title]="'edit-facility-page.facility-information' | translate">
          <gbc-input-container>
            <gbc-input-label>{{ 'edit-facility-page.name' | translate }} *</gbc-input-label>
            <gbc-input-field formControlName="name" inputType="text" errorDescriptionTranslationString="edit-facility-page.name-invalid">
            </gbc-input-field>
          </gbc-input-container>

          <gbc-input-container>
            <gbc-input-label>{{ 'edit-facility-page.road' | translate }} *</gbc-input-label>
            <gbc-input-field formControlName="road" inputType="text" errorDescriptionTranslationString="edit-facility-page.road-invalid">
            </gbc-input-field>
          </gbc-input-container>

          <gbc-input-container>
            <gbc-input-label>{{ 'edit-facility-page.city' | translate }} *</gbc-input-label>
            <gbc-input-field formControlName="city" inputType="text" errorDescriptionTranslationString="edit-facility-page.city-invalid">
            </gbc-input-field>
          </gbc-input-container>

          <gbc-input-container>
            <gbc-input-label>{{ 'edit-facility-page.postal' | translate }} *</gbc-input-label>
            <gbc-input-field
              formControlName="postal"
              inputType="text"
              errorDescriptionTranslationString="edit-facility-page.postal-invalid"
            >
            </gbc-input-field>
          </gbc-input-container>
          <gbc-form-hint> * {{ 'app-must-fill-in' | translate }} </gbc-form-hint>
        </gbc-tile>
        <gbc-tile [title]="'mixit-onboarding.facility-map' | translate">
          <div class="location-container">
            <div class="location-selector">
              <div class="gps-coordinates-header">
                {{ 'geo-location.gps-subtitle' | translate }}
              </div>
              <ol class="gps-coordinates-description">
                <li>
                  {{ 'geo-location.gps-hint-1' | translate }}
                  <a [href]="this.mapsUrl$ | async" target="string_blank" data-test-id='google-map-link'>Google Maps</a>
                </li>
                <li>{{ 'geo-location.gps-hint-2' | translate }}</li>
                <li>
                  <span>{{ 'geo-location.gps-hint-3' | translate }}</span>
                  <img class="gps-image" src="./assets/pngs/google-maps.png" />
                </li>
                <li>
                  <span>{{ 'geo-location.gps-hint-4' | translate }}</span>
                  <img class="gps-image" src="./assets/pngs/copied.png" />
                </li>
                <li>
                  {{ 'geo-location.gps-hint-5' | translate }}
                </li>
              </ol>
              <gbc-input-container>
                <gbc-input-field
                  class="gps-input-lat-long"
                  errorDescriptionTranslationString="geo-location.invalid-location"
                  formControlName="latLong"
                >
                </gbc-input-field>
              </gbc-input-container>
              <gbc-input-container>
                <div class="gps-coordinate-label gps-coordinate-label--lattitude">
                  {{ 'geo-location.latitude' | translate }}: {{ latitude }}
                </div>
                <div class="gps-coordinate-label gps-coordinate-label--longitude">
                  {{ 'geo-location.longitude' | translate }}: {{ longitude }}
                </div>
              </gbc-input-container>
            </div>
            <app-map
              *ngIf="newLocationfacilityList$ | async"
              #newLocationMap
              [facilityData$]="newLocationfacilityList$"
              [hideZoom]="false"
              [interactive]="true"
              [showZeroForFacilities]="false"
              [zoomOutFromSingleFacility]="true"
              style="height: 720px"
            ></app-map>
          </div>
        </gbc-tile>
      </form>

      <button
        gbc-button
        (click)="update()"
        style="float: right"
        [disabled]="!form.valid"
        id="update"
        angulartics2On="click"
        angularticsAction="updateClicked"
        angularticsCategory="editFacilityShown"
      >
        {{ 'edit-facility-page.update' | translate }}
      </button>
    </gbc-page-container>
  </gbc-command-spinner>
</ng-template>
