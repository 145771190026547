<gbc-modal [title]="'alarm-settings-modal.title' | translate" class="gbc-with-dividers" (dismiss)="dismiss()" data-test-id="alarm-settings-dialog">
  <gbc-modal-content>
    <p>{{ 'alarm-settings-modal.info-message' | translate }}</p>
    <form [formGroup]="form">
      <gbc-info-container [header]="'alarm-settings-modal.field-labels.alarm' | translate">
        {{ alarmSettings.errorCode + ': ' + alarmInfo.alarmTitle }}
      </gbc-info-container>

      <gbc-modal-section data-test-id="upper-relative-limit-container">
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'alarm-settings-modal.field-labels.upper-relative-limit' | translate }}</gbc-input-label>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-numeric-field
            formControlName="upperRelativeLimit"
            fixedWidthStyle="200px"
            unit="{{ temperatureUnit }}"
            min="0"
            [errorDescriptionTranslationString]="'alarm-settings-modal.validation-errors.greaterThanOrEqualTo'"
            [errorDescriptionData]="{ min: 0 }">
          </gbc-input-numeric-field>
        </gbc-modal-section-column>
      </gbc-modal-section>

      <gbc-modal-section data-test-id="lower-relative-limit-container">
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'alarm-settings-modal.field-labels.lower-relative-limit' | translate }}</gbc-input-label>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-numeric-field
            formControlName="lowerRelativeLimit"
            fixedWidthStyle="200px"
            unit="{{ temperatureUnit }}"
            max="0"
            [errorDescriptionTranslationString]="'alarm-settings-modal.validation-errors.lessThanOrEqualTo'"
            [errorDescriptionData]="{ max: 0 }">
          </gbc-input-numeric-field>
        </gbc-modal-section-column>
      </gbc-modal-section>

      <gbc-modal-section data-test-id="lower-alarm-qualification-duration-container">
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'alarm-settings-modal.field-labels.lower-qualification-duration' | translate }}</gbc-input-label>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-numeric-field
            formControlName="lowerAlarmQualificationDuration"
            fixedWidthStyle="200px"
            unit="{{ durationUnit }}"
            min="0"
            step="60"
            [errorDescriptionTranslationString]="'alarm-settings-modal.validation-errors.greaterThanOrEqualTo'"
            [errorDescriptionData]="{ min: 0 }">
          </gbc-input-numeric-field>
        </gbc-modal-section-column>
      </gbc-modal-section>

      <gbc-modal-section data-test-id="higher-alarm-qualification-duration-container">
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'alarm-settings-modal.field-labels.higher-qualification-duration' | translate }}</gbc-input-label>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-numeric-field
            formControlName="higherAlarmQualificationDuration"
            fixedWidthStyle="200px"
            unit="{{ durationUnit }}"
            min="0"
            step="60"
            [errorDescriptionTranslationString]="'alarm-settings-modal.validation-errors.greaterThanOrEqualTo'"
            [errorDescriptionData]="{ min: 0 }">
          </gbc-input-numeric-field>
        </gbc-modal-section-column>
      </gbc-modal-section>

      <gbc-modal-section data-test-id="lower-alarm-dequalification-duration-container">
        <gbc-modal-section-column>
          <gbc-input-label> {{ 'alarm-settings-modal.field-labels.lower-dequalification-duration' | translate }}</gbc-input-label>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-numeric-field
            formControlName="lowerAlarmDequalificationDuration"
            fixedWidthStyle="200px"
            unit="{{ durationUnit }}"
            min="0"
            step="60"
            [errorDescriptionTranslationString]="'alarm-settings-modal.validation-errors.greaterThanOrEqualTo'"
            [errorDescriptionData]="{ min: 0 }">
          </gbc-input-numeric-field>
        </gbc-modal-section-column>
      </gbc-modal-section>

      <gbc-modal-section data-test-id="higher-alarm-dequalification-duration-container">
        <gbc-modal-section-column>
          <gbc-input-label> {{ 'alarm-settings-modal.field-labels.higher-dequalification-duration' | translate }}</gbc-input-label>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-numeric-field
            formControlName="higherAlarmDequalificationDuration"
            fixedWidthStyle="200px"
            unit="{{ durationUnit }}"
            min="0"
            step="60"
            [errorDescriptionTranslationString]="'alarm-settings-modal.validation-errors.greaterThanOrEqualTo'"
            [errorDescriptionData]="{ min: 0 }">
          </gbc-input-numeric-field>
        </gbc-modal-section-column>
      </gbc-modal-section>
    </form>
  </gbc-modal-content>

  <gbc-modal-buttons>
    <button gbc-modal-button (click)="close()" data-test-id="button-cancel">{{ 'app-cancel' | translate }}</button>
    <button gbc-modal-button [disabled]="(isValid$ | async) === false" (click)="save()" class="primary" data-test-id="button-save">
      {{ 'app-save' | translate }}
    </button>
  </gbc-modal-buttons>
</gbc-modal>
