import React from 'react';

type coordinates = {
  x: number;
  y: number;
};

export const ValveEnergyIcon: React.FC<coordinates> = React.memo(({ x, y }) => {
  return (
    <>
      <g data-dumbledoreid="hasEnergyValve" transform={`translate(${x},${y})`}>
        <circle opacity="0.1" cx="8" cy="8" r="7" fill="#2A4C1A" />
        <path d="M6.06075 12V3.9H10.2728V4.644H6.98475V7.452H10.0088V8.196H6.98475V11.232H10.2728V12H6.06075Z" fill="#2A4C1A" />
      </g>
    </>
  );
});
