<gbc-tile [title]="'mixit-weather-curve-tile.weather-compensation' | translate" class="tile">
  
  <button gbc-icon-button gbc-tile-right
    *ngIf="editingVisible$ | async"
    [title]="'weather-curve-tile.adjust-curve'| translate"
    [disabled]="editing"
    (click)="edit()"
    angulartics2On="click"
    angularticsAction="adjustMixitWeatherCurve"
    angularticsCategory="mixitWeatherCurve"
    data-test-id="mixitWeatherCurveAdjust">
    <img [alt]="'weather-curve-tile.adjust-curve' | translate" src="./assets/svgs/adjust-outline-black.svg">
  </button>

  <div class="content">

    <app-weather-curve-chart
      class="chart"
      *ngIf="!editing"
      [outdoorTemp$]="outdoorTemp$"
      [data$]="data$">
    </app-weather-curve-chart>

    <app-weather-curve-chart
      class="chart"
      *ngIf="editing"
      [outdoorTemp$]="outdoorTemp$"
      [data$]="curveBeingEdited$">
    </app-weather-curve-chart>

    <div class="controls" *ngIf="editing">

      <div class="simple-editor-container " *ngIf="isSimpleEditingMode">
        <div class="controls-header">
          <a href="javascript:void(0)" (click)="customize()">{{'mixit-weather-curve-tile.advanced-editor' | translate}}</a>
        </div>

        <div class="description-container">
          <img class="description-image" src="./assets/svgs/adjust-offset.svg">
          <div class="description">
            {{'mixit-weather-curve-tile.offset-curve' | translate}}
          </div>
        </div>

        <div class="button-container">
          <button gbc-button ghost
            [disabled]="(canMoveDown$ | async) === false"
            (click)="minusOneDegree()"
            angulartics2On="click"
            angularticsAction="minusOneDegreeClicked"
            angularticsCategory="mixitWeatherCurve">
            - 1 °C
          </button>
          <button gbc-button ghost
            [disabled]="(canMoveUp$ | async) === false"
            (click)="plusOneDegree()"
            angulartics2On="click"
            angularticsAction="plusOneDegreeClicked"
            angularticsCategory="mixitWeatherCurve">
            + 1 °C
          </button>
        </div>

        <div class="description-container">
          <img class="description-image" src="./assets/svgs/adjust-slope.svg">
          <div class="description">
            {{'mixit-weather-curve-tile.adjust-curve-slope' | translate}}
          </div>
        </div>

        <div class="button-container">
          <button gbc-button ghost
            [disabled]="(canSlopeDown$ | async) === false"
            class="icon-button"
            id="rollLeft"
            (click)="adjustCurveLeft()"
            angulartics2On="click"
            angularticsAction="adjustCurveLeftClicked"
            angularticsCategory="mixitWeatherCurve">
            <img src="./assets/svgs/adjust-roll-left.svg" alt="">
          </button>
          <button gbc-button ghost
            [disabled]="(canSlopeUp$ | async) === false"
            class="icon-button"
            id="rollRight"
            (click)="adjustCurveRight()"
            angulartics2On="click"
            angularticsAction="adjustCurveRightClicked"
            angularticsCategory="mixitWeatherCurve">
            <img src="./assets/svgs/adjust-roll-right.svg" alt="">
          </button>
        </div>

      </div>

      <ng-container *ngIf="!isSimpleEditingMode">

        <div class="controls-header">
          <a href="javascript:void(0)" (click)="customize()">{{'mixit-weather-curve-tile.simple-editor' | translate}}</a>
        </div>

        <form [formGroup]="form">
          <div class="input-container" [ngClass]="{ 'dynamic': (isDynamicCurveData$ | async) === true }">
            <div *ngIf="(isDynamicCurveData$ | async) === false" class="degree">-20°C</div>
            <gbc-input-field *ngIf="(isDynamicCurveData$ | async) === true" formControlName="dataX0" class="dynamic" unit="°C" inputType="number" errorDescriptionTranslationString="app-enter-value-between" [errorDescriptionData]="X_MIN_MAX"></gbc-input-field>
            <gbc-input-field formControlName="minus20" unit="°C" inputType="number" errorDescriptionTranslationString="app-enter-value-between" [errorDescriptionData]="MINMAX"></gbc-input-field>
          </div>
          <div class="input-container" [ngClass]="{ 'dynamic': (isDynamicCurveData$ | async) === true }">
            <div *ngIf="(isDynamicCurveData$ | async) === false" class="degree">-10°C</div>
            <gbc-input-field *ngIf="(isDynamicCurveData$ | async) === true" formControlName="dataX1" class="dynamic" unit="°C" inputType="number" errorDescriptionTranslationString="app-enter-value-between" [errorDescriptionData]="X_MIN_MAX"></gbc-input-field>
            <gbc-input-field formControlName="minus10" unit="°C" inputType="number" errorDescriptionTranslationString="app-enter-value-between" [errorDescriptionData]="MINMAX"></gbc-input-field>
          </div>
          <div class="input-container" [ngClass]="{ 'dynamic': (isDynamicCurveData$ | async) === true }">
            <div *ngIf="(isDynamicCurveData$ | async) === false" class="degree">0°C</div>
            <gbc-input-field *ngIf="(isDynamicCurveData$ | async) === true" formControlName="dataX2" class="dynamic" unit="°C" inputType="number" errorDescriptionTranslationString="app-enter-value-between" [errorDescriptionData]="X_MIN_MAX"></gbc-input-field>
            <gbc-input-field formControlName="zero" unit="°C" inputType="number" errorDescriptionTranslationString="app-enter-value-between" [errorDescriptionData]="MINMAX"></gbc-input-field>
          </div>
          <div class="input-container" [ngClass]="{ 'dynamic': (isDynamicCurveData$ | async) === true }">
            <div *ngIf="(isDynamicCurveData$ | async) === false" class="degree">10°C</div>
            <gbc-input-field *ngIf="(isDynamicCurveData$ | async) === true" formControlName="dataX3" class="dynamic" unit="°C" inputType="number" errorDescriptionTranslationString="app-enter-value-between" [errorDescriptionData]="X_MIN_MAX"></gbc-input-field>
            <gbc-input-field formControlName="plus10" unit="°C" inputType="number" errorDescriptionTranslationString="app-enter-value-between" [errorDescriptionData]="MINMAX"></gbc-input-field>
          </div>
          <div class="input-container" [ngClass]="{ 'dynamic': (isDynamicCurveData$ | async) === true }">
            <div *ngIf="(isDynamicCurveData$ | async) === false" class="degree">20°C</div>
            <gbc-input-field *ngIf="(isDynamicCurveData$ | async) === true" formControlName="dataX4" class="dynamic" unit="°C" inputType="number" errorDescriptionTranslationString="app-enter-value-between" [errorDescriptionData]="X_MIN_MAX"></gbc-input-field>
            <gbc-input-field formControlName="plus20" unit="°C" inputType="number" errorDescriptionTranslationString="app-enter-value-between" [errorDescriptionData]="MINMAX"></gbc-input-field>
          </div>
        </form>

      </ng-container>


      <div class="slope">
        <div *ngIf="slopeTooSteep$ | async">{{'mixit-weather-curve-tile.slope-too-steep' | translate}}</div>
        <div *ngIf="slopeTooFlat$ | async">{{'mixit-weather-curve-tile.slope-too-flat' | translate}}</div>
        <div *ngIf="invalidRangeOrder$ | async">{{'mixit-weather-curve-tile.outdoor-range-invalid' | translate}}</div>
      </div>
      <div class="save-cancel-buttons" *ngIf="editing">
        <div>
          <button gbc-button ghost
            (click)="cancel()"
            class="cancel-button"
            angulartics2On="click"
            angularticsAction="cancelClicked"
            angularticsCategory="mixitWeatherCurve">
            {{'app-cancel' | translate}}
          </button>

          <button gbc-button
            (click)="save()"
            [disabled]="(valid$ | async) === false"
            class="save-button"
            angulartics2On="click"
            angularticsAction="saveClicked"
            angularticsCategory="mixitWeatherCurve">
            {{'app-save' | translate}}
          </button>
        </div>
        <div class="mt-4" style="text-align: center">
          <a href="javascript:void(0)" (click)="resetToDefault()">{{'mixit-weather-curve-tile.reset-to-default' | translate}}</a>
        </div>
      </div>

    </div>
  </div>
</gbc-tile>



