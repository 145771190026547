import React from 'react';
import { Valve } from '../../Valve';
import { Labels } from '../../Labels';
import { PumpIcon } from '../../PumpIcon';
import { SvgImage } from '../../SvgImage';
import { FlowMeter } from '../../FlowMeter';
import { Pipe, PipeType } from '../../Pipe';
import * as metaData from './mixit-metadata';
import { SupplyTriangle } from '../../SupplyTriangle';
import { System, BaseSystemProps } from '../../System';
import { TemperatureSensor } from '../../TemperatureSensor';
import weatherIcon from '!raw-loader!../../../../../assets/icons/weather-icon.svg';
import { EValveVariant } from '../../../../models/systems/mixitSystem/EValveVariant';
import { getForwardTop, getReturnTop, MixitApplicationType } from '../../mixing-loop-common/MixitApplicationType';
import { IMixitSystemFormInterface } from '../../../../models/systems/mixitSystem/mixitSystemFormInterface';

export const Mixit: React.FC<BaseSystemProps<IMixitSystemFormInterface>> = React.memo(({ state, values, alarm, selected }) => {
  const  systemInfo = state.systemInfo;
  const LEFT = systemInfo.ValveVariant === EValveVariant.MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE;

  const pump = () => {
    if (!systemInfo.P_location) {
      return undefined;
    }
    const pumpX = metaData.getPumpXLocation(systemInfo);
    const pumpY = metaData.getPumpYLocation(systemInfo);
    const direction = metaData.getPumpDirection(systemInfo);
    return <PumpIcon direction={direction} x={pumpX} y={pumpY} />;
  };

  const valve = () => {
    const direction = metaData.getValveDirection(systemInfo);
    const valveType = systemInfo.MV_valveType;
    const valveX = metaData.getValveXLocation(systemInfo);
    const valveYvalue = metaData.getValveYLocation(systemInfo);

    if (!valveType) {
      return null;
    }

    return <Valve x={valveX} y={valveYvalue} type={valveType} direction={direction} />;
  };

  const getIsReversedWarmCold = (): boolean => {
    const isCooling = systemInfo.Application === 'MIXING_LOOP_APPLICATION_TYPE_COOLING_COIL';
    if (isCooling && LEFT) {
      return true;
    }
    return LEFT;
  };

  const leftHandTriangleType =
    metaData.getLeftHandPipeType(systemInfo) === PipeType.WarmReturn || metaData.getLeftHandPipeType(systemInfo) === PipeType.WarmSupply;
  const rightHandTriangleType =
    metaData.getRightHandPipeType(systemInfo) === PipeType.WarmReturn || metaData.getRightHandPipeType(systemInfo) === PipeType.WarmSupply;

  const supplyType = systemInfo.ThermalApplicationType === 'MIXING_LOOP_THERMAL_APPLICATION_TYPE_HEAT_APPL' ? PipeType.WarmSupply : PipeType.ColdSupply;
  const returnType = systemInfo.ThermalApplicationType === 'MIXING_LOOP_THERMAL_APPLICATION_TYPE_HEAT_APPL' ? PipeType.ColdReturn : PipeType.WarmReturn;

  const showWeatherIcon = state.showOutdoorTemperatureSensor;

  // Calculate drawing points for main pipes.
  const forwardTopPoint: { x: number; y: number } = {
    x: systemInfo.ValveVariant != EValveVariant.MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE ? metaData.FORWARD_X : metaData.RETURN_X,
    y: getForwardTop(systemInfo.Application, false),
  };
  const forwardBottomPoint: { x: number; y: number } = {
    x: systemInfo.ValveVariant != EValveVariant.MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE ? metaData.FORWARD_X : metaData.RETURN_X,
    y: metaData.STANDALONE_CONNECTIONS_BOTTOM_Y,
  };
  const returnTopPoint: { x: number; y: number } = {
    x: systemInfo.ValveVariant != EValveVariant.MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE ? metaData.RETURN_X : metaData.FORWARD_X,
    y: getReturnTop(systemInfo.Application, false),
  };
  const returnBottomPoint: { x: number; y: number } = {
    x: systemInfo.ValveVariant != EValveVariant.MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE ? metaData.RETURN_X : metaData.FORWARD_X,
    y: systemInfo.Standalone ? metaData.STANDALONE_CONNECTIONS_BOTTOM_Y : metaData.CONNECTIONS_BOTTOM_Y,
  };

  return (
    <System state={state} meta={metaData.mixitMetadata} alarm={alarm} selected={selected}>
      <Pipe
        key="forward"
        x1={forwardTopPoint.x}
        y1={forwardTopPoint.y}
        x2={forwardBottomPoint.x}
        y2={forwardBottomPoint.y}
        type={PipeType.WarmSupply}
      />
      <Pipe
        key="return"
        x1={returnTopPoint.x}
        y1={returnTopPoint.y}
        x2={returnBottomPoint.x}
        y2={returnBottomPoint.y}
        type={PipeType.ColdReturn}
      />

      <Pipe
        key="warmToCold1"
        x1={metaData.FORWARD_X}
        y1={metaData.WARM_TO_COLD_Y}
        x2={metaData.RETURN_X}
        y2={metaData.WARM_TO_COLD_Y}
        type={returnType}
      />

      {systemInfo.DoubleShunt ? (
        <Pipe
          key="warmToCold2"
          x1={metaData.FORWARD_X}
          y1={metaData.SECOND_WARM_TO_COLD_Y}
          x2={metaData.RETURN_X}
          y2={metaData.SECOND_WARM_TO_COLD_Y}
          type={supplyType}
        />
      ) : null}
      {systemInfo.T_SF_present ? (
        <TemperatureSensor
          key="T_SF"
          x={metaData.getForwardX(systemInfo)}
          y={metaData.getSecondaryTempSensorY(systemInfo)}
          direction={metaData.getForwardDirection(systemInfo)}
        />
      ) : null}
      {systemInfo.T_SR_present ? (
        <TemperatureSensor
          key="T_SR"
          x={metaData.getReturnX(systemInfo)}
          y={metaData.getSecondaryTempSensorY(systemInfo)}
          direction={metaData.getReturnDirection(systemInfo)}
        />
      ) : null}
      {systemInfo.T_PF_present ? (
        <TemperatureSensor
          key="T_PF"
          x={metaData.getForwardX(systemInfo)}
          y={metaData.getPrimaryTempSensorY(systemInfo)}
          direction={metaData.getForwardDirection(systemInfo)}
        />
      ) : null}
      {systemInfo.T_PR_present ? (
        <TemperatureSensor
          key="T_PR"
          x={metaData.getReturnX(systemInfo)}
          y={metaData.getPrimaryTempSensorY(systemInfo)}
          direction={metaData.getReturnDirection(systemInfo)}
        />
      ) : null}

      {pump()}
      {valve()}

      {showWeatherIcon && <SvgImage id="mixit-outdoor-temp" x={metaData.TEMP_ICON_LEFT} y={metaData.TEMP_ICON_TOP} svgText={weatherIcon} data-test-id="weather-icon" />}

      {systemInfo.Standalone && (
        <>
          <SupplyTriangle
            x={metaData.FORWARD_X}
            y={metaData.getTriangleY(metaData.getLeftHandTriangleDirection(systemInfo))}
            warm={leftHandTriangleType}
            direction={metaData.getLeftHandTriangleDirection(systemInfo)}
          />
          <SupplyTriangle
            x={metaData.RETURN_X}
            y={metaData.getTriangleY(metaData.getRightHandTriangleDirection(systemInfo))}
            warm={rightHandTriangleType}
            direction={metaData.getRightHandTriangleDirection(systemInfo)}
          />
        </>
      )}

      <MixitApplicationType applicationType={systemInfo.Application} reversedWarmCold={getIsReversedWarmCold()} />

      {systemInfo.Dynamic && <FlowMeter x={metaData.getMeterX(systemInfo)} y={metaData.getMeterY(systemInfo)} />}

      <Labels positions={metaData.getMixitLabels(systemInfo)} values={values} />
    </System>
  );
});
