import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UserService } from 'projects/customerportal/src/app/services/user.service';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgControlStatus } from 'shared';
import { UserTrackingHelperService } from '../../../services/user-tracking-helper.service';
import { AccessClaim } from 'projects/shared/src/lib/interfaces/access';
import { SchematicsService } from '../../../services/schematics.service';
import { MixitVersion, parseMixitVersion } from '../../../utils/mixit-utils';

const [FIRST_POINT_INDEX, SECOND_POINT_INDEX, THIRD_POINT_INDEX, FOURTH_POINT_INDEX, LAST_POINT_INDEX] = [0, 1, 2, 3, 4];
const X_MIN_TEMP = -20;
const X_MAX_TEMP = 20;
const MIN_TEMP = 20;
const MAX_TEMP = 90;
const MIN_SLOPE = 0.4;
const MAX_SLOPE = 2.0;

const DynamicCurveVersionNonX509 = "99503129V03.07.00.00000";
const DynamicCurveVersionX509 = "92845890V04.07.00.00000";

@Component({
  selector: 'app-mixit-weather-curve-tile',
  templateUrl: './mixit-weather-curve-tile.component.html',
  styleUrls: ['./mixit-weather-curve-tile.component.scss']
})
export class MixitWeatherCurveTileComponent implements OnInit, OnDestroy {
  @Input() public data$: Observable<Array<[number, number]>>;
  @Input() public outdoorTemp$: Observable<number>;
  @Output() public saveNewCurve = new EventEmitter<{linear: boolean, curve: Array<[number, number]>, resetToDefault: boolean}>();

  public editingVisible$: Observable<boolean>;
  public isDynamicCurveData$: Observable<boolean>;

  public editing = false;
  // Customizing is setting a fixed heat curve using coordinates rather than the controls
  public isSimpleEditingMode = true;

  public dataBeingEdited: Array<[number, number]> = [];
  public curveBeingEdited$ = new BehaviorSubject<Array<[number, number]>>([]);

  public MINMAX = {min: MIN_TEMP, max: MAX_TEMP};
  public X_MIN_MAX = { min: X_MIN_TEMP, max: X_MAX_TEMP };

  public form = new UntypedFormGroup({
    dataX0: new UntypedFormControl(-20, [Validators.required, Validators.min(X_MIN_TEMP), Validators.max(X_MAX_TEMP)]),
    dataX1: new UntypedFormControl(-10, [Validators.required, Validators.min(X_MIN_TEMP), Validators.max(X_MAX_TEMP)]),
    dataX2: new UntypedFormControl(0, [Validators.required, Validators.min(X_MIN_TEMP), Validators.max(X_MAX_TEMP)]),
    dataX3: new UntypedFormControl(10, [Validators.required, Validators.min(X_MIN_TEMP), Validators.max(X_MAX_TEMP)]),
    dataX4: new UntypedFormControl(20, [Validators.required, Validators.min(X_MIN_TEMP), Validators.max(X_MAX_TEMP)]),

    minus20: new UntypedFormControl('', [Validators.required, Validators.min(MIN_TEMP), Validators.max(MAX_TEMP)]),
    minus10: new UntypedFormControl('', [Validators.required, Validators.min(MIN_TEMP), Validators.max(MAX_TEMP)]),
    zero: new UntypedFormControl('', [Validators.required, Validators.min(MIN_TEMP), Validators.max(MAX_TEMP)]),
    plus10: new UntypedFormControl('', [Validators.required, Validators.min(MIN_TEMP), Validators.max(MAX_TEMP)]),
    plus20: new UntypedFormControl('', [Validators.required, Validators.min(MIN_TEMP), Validators.max(MAX_TEMP)]),
  });

  private subscription = new Subscription();
  private userTrackingValueChangeSubscription: Subscription = new Subscription();

  public valid$: Observable<boolean>;

  public slope$: Observable<number>;

  public canMoveUp$: Observable<boolean>;
  public canMoveDown$: Observable<boolean>;
  public canSlopeUp$: Observable<boolean>;
  public canSlopeDown$: Observable<boolean>;

  public slopeTooSteep$: Observable<boolean>;
  public slopeTooFlat$: Observable<boolean>;
  public invalidRangeOrder$: Observable<boolean>;

  private formChanged$: Observable<any>;

  constructor(
    private userService: UserService,
    private schematicsService: SchematicsService,
    private userTrackingHelperService: UserTrackingHelperService
  ) {}

  ngOnInit(): void {
    this.editingVisible$ = this.userService.hasClaim(AccessClaim.CustomerPortalWrite);
    const firmwareVersion$ = this.schematicsService.getFirmwareVersion();

    this.isDynamicCurveData$ = firmwareVersion$.pipe(
      map((version) => {
        const semantic = parseMixitVersion(version);
        return this.isDynamicCurvesSupported(semantic);
      })
    );

    // Update the curve
    this.formChanged$ = combineLatest([this.isDynamicCurveData$, this.form.valueChanges]).pipe(
      map(([isDynamic, formData]) => {
        const curveData: [number, number][] = [
          [isDynamic ? formData.dataX0 : -20, formData.minus20],
          [isDynamic ? formData.dataX1 : -10, formData.minus10],
          [isDynamic ? formData.dataX2 : 0, formData.zero],
          [isDynamic ? formData.dataX3 : 10, formData.plus10],
          [isDynamic ? formData.dataX4 : 20, formData.plus20],
        ];
        return curveData;
      })
    );

    this.subscription.add(this.formChanged$.subscribe(curveData => {
      this.updateCurveBeingEdited(curveData);
    }));

    this.userTrackingValueChangeSubscription = this.userTrackingHelperService.startFormValueChangeTracking('mixitWeatherCurve', this.form);

    this.slope$ = this.curveBeingEdited$.pipe(
      map(curve => {
        const rise =  curve[LAST_POINT_INDEX][1] - curve[FIRST_POINT_INDEX][1];
        const run =  curve[LAST_POINT_INDEX][0] - curve[FIRST_POINT_INDEX][0];
        return -(rise / run);
      })
    );

    this.slopeTooSteep$ = this.slope$.pipe(
      map(slope => slope > MAX_SLOPE)
    );

    this.slopeTooFlat$ = this.slope$.pipe(
      map(slope => slope < MIN_SLOPE)
    );

    this.invalidRangeOrder$ = this.curveBeingEdited$.pipe(
      map((curveData) => {
        return (curveData[0][0] >= curveData[1][0]) ||
               (curveData[1][0] >= curveData[2][0]) ||
               (curveData[2][0] >= curveData[3][0]) ||
               (curveData[3][0] >= curveData[4][0]);
      })
    );

    this.valid$ = combineLatest([this.curveBeingEdited$, this.form.statusChanges, this.slopeTooSteep$, this.slopeTooFlat$, this.invalidRangeOrder$]).pipe(
      map(([curve, state, tooSteep, tooFlat, invalidOrder]) => {
        const validSlope = !tooSteep && !tooFlat;
        if (this.isSimpleEditingMode) {
          // Simple mode
          return curve.every(c => c[1] >= MIN_TEMP && c[1] <= MAX_TEMP) && validSlope;
        } else {
          // Advanced mode
          return state === NgControlStatus.VALID && validSlope && !invalidOrder;
        }
      })
    );

    this.canMoveUp$ = this.curveBeingEdited$.pipe(
      map(curve => {
        return curve.every(c => c[1] <= MAX_TEMP - 1);
      })
    );

    this.canMoveDown$ = this.curveBeingEdited$.pipe(
      map(curve => {
        return curve.every(c => c[1] >= MIN_TEMP + 1);
      })
    );

    this.canSlopeDown$ = combineLatest([this.curveBeingEdited$, this.slope$]).pipe(
      map(([curve, slope]) => {
        const firstPointValid = curve[FIRST_POINT_INDEX][1] - 1 > MIN_TEMP;
        const secondPointValid = curve[SECOND_POINT_INDEX][1] - 0.75 > MIN_TEMP;
        const thirdPointValid = curve[THIRD_POINT_INDEX][1] - 0.5 > MIN_TEMP;
        const fourthPointValid = curve[FOURTH_POINT_INDEX][1] - 0.25 > MIN_TEMP;
        return firstPointValid && secondPointValid && thirdPointValid && fourthPointValid && slope > MIN_SLOPE;
      })
    );

    this.canSlopeUp$ = combineLatest([this.curveBeingEdited$, this.slope$]).pipe(
      map(([curve, slope]) => {
        const firstPointValid = curve[FIRST_POINT_INDEX][1] + 1 < MAX_TEMP;
        const secondPointValid = curve[SECOND_POINT_INDEX][1] + 0.75 < MAX_TEMP;
        const thirdPointValid = curve[THIRD_POINT_INDEX][1] + 0.5 < MAX_TEMP;
        const fourthPointValid = curve[FOURTH_POINT_INDEX][1] + 0.25 < MAX_TEMP;
        return firstPointValid && secondPointValid && thirdPointValid && fourthPointValid && slope < MAX_SLOPE;
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.userTrackingValueChangeSubscription.unsubscribe();
  }

  edit() {
    this.editing = true;
    this.data$
      .pipe(first())
      .subscribe((data: [number, number][]) => {
        this.updateCurveBeingEdited(data);
        this.setFormValues();
      });
  }

  customize() {
    this.isSimpleEditingMode = !this.isSimpleEditingMode;
    if (!this.isSimpleEditingMode) {
      this.setFormValues();
    }

    this.userTrackingHelperService.trackUserAction('mixitWeatherCurve', this.isSimpleEditingMode ? 'simpleEditorClicked' : 'advancedEditorClicked');
  }

  setFormValues() {
    this.userTrackingValueChangeSubscription.unsubscribe();

    this.form.setValue({
      dataX0: this.dataBeingEdited[FIRST_POINT_INDEX][0],
      dataX1: this.dataBeingEdited[SECOND_POINT_INDEX][0],
      dataX2: this.dataBeingEdited[THIRD_POINT_INDEX][0],
      dataX3: this.dataBeingEdited[FOURTH_POINT_INDEX][0],
      dataX4: this.dataBeingEdited[LAST_POINT_INDEX][0],

      minus20: this.dataBeingEdited[FIRST_POINT_INDEX][1],
      minus10: this.dataBeingEdited[SECOND_POINT_INDEX][1],
      zero: this.dataBeingEdited[THIRD_POINT_INDEX][1],
      plus10: this.dataBeingEdited[FOURTH_POINT_INDEX][1],
      plus20: this.dataBeingEdited[LAST_POINT_INDEX][1],
    });
    // We need the form to emit, to calculate the valid state of the curve
    setTimeout(_ => this.form.updateValueAndValidity({onlySelf: false, emitEvent: true}));

    this.userTrackingValueChangeSubscription = this.userTrackingHelperService.startFormValueChangeTracking('mixitWeatherCurve', this.form);
  }

  cancel() {
    this.editing = false;
  }

  save() {
    this.saveNewCurve.emit({
      linear: false, // No way to linearize it using this UI
      curve: this.dataBeingEdited,
      resetToDefault: false
    });

    // Reset the state of the editor
    this.editing = false;
  }

  resetToDefault() {
    this.saveNewCurve.emit({
      linear: false,
      curve: this.dataBeingEdited,
      resetToDefault: true
    });

    // Reset the state of the editor
    this.editing = false;

    this.userTrackingHelperService.trackUserAction('mixitWeatherCurve', 'resetToDefaultClicked');
  }

  public plusOneDegree() {
    this.updateCurveBeingEdited(this.dataBeingEdited.map((d) => {
      // Shift every point up one on the y-axis
      d[1] = d[1] + 1;
      return d;
    }));
  }

  public minusOneDegree() {
    this.updateCurveBeingEdited(this.dataBeingEdited.map((d) => {
      // Shift every point down one on the y-axis
      d[1] = d[1] - 1;
      return d;
    }));
  }

  public adjustCurveRight() {
    this.updateCurveBeingEdited([
      [this.dataBeingEdited[FIRST_POINT_INDEX][0], this.dataBeingEdited[FIRST_POINT_INDEX][1] + 1],
      [this.dataBeingEdited[SECOND_POINT_INDEX][0], this.dataBeingEdited[SECOND_POINT_INDEX][1] + 0.75],
      [this.dataBeingEdited[THIRD_POINT_INDEX][0], this.dataBeingEdited[THIRD_POINT_INDEX][1] + 0.5],
      [this.dataBeingEdited[FOURTH_POINT_INDEX][0], this.dataBeingEdited[FOURTH_POINT_INDEX][1] + 0.25],
      this.dataBeingEdited[LAST_POINT_INDEX]
    ]);
  }

  public adjustCurveLeft() {
    this.updateCurveBeingEdited([
      [this.dataBeingEdited[FIRST_POINT_INDEX][0], this.dataBeingEdited[FIRST_POINT_INDEX][1] - 1],
      [this.dataBeingEdited[SECOND_POINT_INDEX][0], this.dataBeingEdited[SECOND_POINT_INDEX][1] - 0.75],
      [this.dataBeingEdited[THIRD_POINT_INDEX][0], this.dataBeingEdited[THIRD_POINT_INDEX][1] - 0.5],
      [this.dataBeingEdited[FOURTH_POINT_INDEX][0], this.dataBeingEdited[FOURTH_POINT_INDEX][1] - 0.25],
      this.dataBeingEdited[LAST_POINT_INDEX]
    ]);
  }

  private updateCurveBeingEdited(points: [number, number][]) {
    this.dataBeingEdited = points;
    this.curveBeingEdited$.next(this.dataBeingEdited);
  }

  private isDynamicCurvesSupported(version: MixitVersion | undefined): boolean {
    if (version === undefined) return false;

    // Non-X509 Variant of MIXIT firmware (99503129V03.07.xx.xxxxx)
    const supportedNonX509 = parseMixitVersion(DynamicCurveVersionNonX509);
    if (version.productId === supportedNonX509?.productId) {
      return (version.major > supportedNonX509.major) || (version.major === supportedNonX509.major && version.minor >= supportedNonX509.minor);
    }
    
    // X509 Variant of MIXIT firmware (92845890V04.07.xx.xxxxx)
    const supportedX509 = parseMixitVersion(DynamicCurveVersionX509);
    if (version.productId === supportedX509?.productId) {
      return (version.major > supportedX509.major) || (version.major === supportedX509.major && version.minor >= supportedX509.minor);
    }

    // Any other products do not support dynamic curve data.
    return false;
  }
}
