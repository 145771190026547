import {ITextAreaQuestion, ITextQuestion} from '../../models/form.interface';
import React, { ChangeEvent, ChangeEventHandler, useState } from 'react';
import classnames from 'classnames';
import { QuestionTitle } from './QuestionTitle';
import styled from 'styled-components';
import { formService } from '../../services/formService';

export interface TextQuestionProps {
  question: ITextQuestion;
  data: any;
  onChange: (newData: any, oldData?: any) => void;
}

const StyledTextQuestion = styled.div`
  input {
    box-sizing: border-box;
    width: 100%;
    padding: 0 12px;
    height: 36px;
    border: solid 2px #d3d9dd;
    background-color: #ffffff;

    &.error {
      border: solid 2px #ff0000;
    }
  }

  .gbc-input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    width: 100%;
    border: solid 2px #d3d9dd;
    box-sizing: border-box;
  }

  .gbc-input-wrapper:focus-within {
    border-color: #0066b0;
  }

  .gbc-input-wrapper.error {
    border-color: #ff0000;
  }

  .gbc-input-wrapper input {
    padding: 0;
    height: 32px;
    border: none;
    outline: none;
    padding: 0 10px;
  }

  .gbc-input-wrapper input[disabled] {
    background-color: #ddd !important;
    color: #aaa;
    cursor: not-allowed;
    pointer-events: all !important;
  }
`;

type UseTextField = {
  question: ITextAreaQuestion | ITextQuestion;
  data: any;
  onChange: (newData: any, oldData?: any) => void;
};

export  const useTextField = ({ question, data, onChange }: UseTextField) => {
  const [submitted, setSubmitted] = useState(false);
  const isRequired = !!question.isRequired;

  const isDisabled = formService.isElementDisabled(question);
  const isVisible = formService.isElementVisible(question, data);
  const disabledTooltip = (isDisabled && question.disabledTooltip !== undefined && question.disabledTooltip !== "") ? question.disabledTooltip : undefined;

  const value = (isVisible && isDisabled && typeof question.disabledValue === 'string') ? question.disabledValue : data[question.name];

  const isInErrorState: boolean = (value === null || value === undefined) && isRequired && submitted;

  const onInput: ChangeEventHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setSubmitted(true);
    onChange({ ...data, [question.name]: e.target.value }, { ...data });
  };

  return [isInErrorState, isRequired, onInput, value, isDisabled, disabledTooltip];
};

export const TextQuestion: React.FC<TextQuestionProps> = ({ question, data, onChange }) => {

  const [isInErrorState, isRequired, onInput, value, isDisabled, disabledTooltip] = useTextField({question, data, onChange});

  const renderInput = () => {
    if (question.inputType && question.inputType === 'number') {
      let max: number | undefined;
      let min: number | undefined;
      if (question.validators) {
        max = question.validators[0].maxValue;
        min = question.validators[0].minValue;
      }

      return (
        <input
          type="number"
          name={ question.name }
          className={ classnames({ error: isInErrorState }) }
          onChange={ onInput }
          min={ min }
          max={ max }
          value={ value || '' }
          disabled={ isDisabled }
          title={ disabledTooltip }
        />
      );
    }
    return (
      <input type="text" name={ question.name } className={ classnames({ error: isInErrorState }) } disabled={ isDisabled } title={ disabledTooltip } onChange={ onInput } value={ value || '' } />
    );
  };
  return (
    <StyledTextQuestion>
      <QuestionTitle text={question.title} hasError={isInErrorState} isRequired={isRequired} />
      <div className={`gbc-input-wrapper ${question.unit ? 'hasUnit' : ''} ${isInErrorState ? 'error' : ''}`}>
        {renderInput()}
        {question.unit && <div className="gbc-input-unit">{question.unit}</div>}
      </div>
    </StyledTextQuestion>
  );
};
