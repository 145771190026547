import moment from 'moment';
import { ModalService } from 'shared';
import { parseBoolean } from './data-point-utils';
import { filter, first, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Facility, Installation } from '../interfaces/facilty';
import { SystemDeviceType } from '../interfaces/systemDeviceType';
import { combineLatest, Observable, OperatorFunction } from 'rxjs';
import { AppModel, EEquipment, EValueType } from '@ams/dumbledore';
import { WeatherCompensationCurve } from '../interfaces/weather-compensation';
import { DataPointsResult, DeviceDataPoint } from '../interfaces/data-points';
import { MixitEquipmentMetaInfo } from '../services/installation-configuration.service';
import { connectLicenseTypes, dynamicLicenseTypes, LicenseType, MixitDataPointName } from '../interfaces/mixit';
import { IMixitSystemFormInterface } from 'projects/dumbledore/src/models/systems/mixitSystem/mixitSystemFormInterface';
import { MixitPumpInfoModalComponent, PumpModalData } from '../components/mixit-pump-info-modal/mixit-pump-info-modal.component';
import { MixitModalData, MixitProductInfoModalComponent } from '../components/mixit-product-info-modal/mixit-product-info-modal.component';

export const MIXIT_TRIAL_PERIOD = 365;

export function getDatapoint(
  datapoints$: Observable<DataPointsResult>,
  systemType: SystemDeviceType,
  humanReadableId: string
): Observable<DeviceDataPoint | null> {
  return datapoints$.pipe(
    filter((datapoints) => !!datapoints),
    map((datapoints) => {
      if (!systemType || !humanReadableId) {
        return null;
      }

      let device = datapoints.data[0]?.devices.find(
        (d) => d.type === systemType
      );
      let datapoint = device?.dataPoints.find(
        (d) => d.humanReadableId === humanReadableId
      );

      // TODO: Fix this
      // This is a temporary fix
      if (humanReadableId === MixitDataPointName.OutdoorTemperatureAverage) {
        device = datapoints.data[0]?.devices.find((d) => {
          return (
            d.type === systemType &&
            d.dataPoints.find(
              (dp) => dp.humanReadableId === (humanReadableId as string)
            )
          );
        });
        datapoint = device?.dataPoints.find(
          (dp) => dp.humanReadableId === (humanReadableId as string)
        );
      }

      return datapoint || null;
    })
  );
}

export const addDumbledoreIdToDatapoint = (
  dumbledoreId: EEquipment
): OperatorFunction<DeviceDataPoint | null, DeviceDataPoint> => {
  return map((dataPoint: DeviceDataPoint | null) => {
    return {
      ...dataPoint,
      dumbledoreId
    } as DeviceDataPoint;
  });
};

export const installationCommissionState = (
  installation$: Observable<Installation>
) =>
  installation$.pipe(
    map((installation) => {
      return installation.commissionStatus;
    })
  );

export const weatherCurve = (dataPoints$: Observable<DataPointsResult>): Observable<WeatherCompensationCurve> => {
  return combineLatest([
    getDatapoint(dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.HeatcurveX0),
    getDatapoint(dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.HeatcurveX1),
    getDatapoint(dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.HeatcurveX2),
    getDatapoint(dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.HeatcurveX3),
    getDatapoint(dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.HeatcurveX4),
    getDatapoint(dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.HeatcurveY0),
    getDatapoint(dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.HeatcurveY1),
    getDatapoint(dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.HeatcurveY2),
    getDatapoint(dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.HeatcurveY3),
    getDatapoint(dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.HeatcurveY4)
  ]).pipe(
    map(
      ([heatcurveX0, heatcurveX1, heatcurveX2, heatcurveX3, heatcurveX4, heatcurveY0, heatcurveY1, heatcurveY2, heatcurveY3, heatcurveY4]) => {
        if ( !heatcurveX0 || !heatcurveX1 || !heatcurveX2 || !heatcurveX3 || !heatcurveX4 || !heatcurveY0 || !heatcurveY1 || !heatcurveY2 || !heatcurveY3 || !heatcurveY4 ) {
          return [];
        }
        return [
          [parseFloat(heatcurveX0.value as string), parseFloat(heatcurveY0.value as string)],
          [parseFloat(heatcurveX1.value as string), parseFloat(heatcurveY1.value as string)],
          [parseFloat(heatcurveX2.value as string), parseFloat(heatcurveY2.value as string)],
          [parseFloat(heatcurveX3.value as string), parseFloat(heatcurveY3.value as string)],
          [parseFloat(heatcurveX4.value as string), parseFloat(heatcurveY4.value as string)],
        ];
      }
    )
  );
};

export const outdoorTemp = (
  dataPoints$: Observable<DataPointsResult>
): Observable<DeviceDataPoint | null> => {
  return getDatapoint(
    dataPoints$,
    SystemDeviceType.MixitSystem,
    MixitDataPointName.OutdoorTemperatureAverage
  );
};

export const operationMode = (
  translateService: TranslateService,
  dataPoints$: Observable<DataPointsResult>
): Observable<DeviceDataPoint | null> => {
  return getDatapoint(
    dataPoints$,
    SystemDeviceType.MixitSystem,
    MixitDataPointName.OperationMode
  );
};

export const controlMode = (
  translateService: TranslateService,
  dataPoints$: Observable<DataPointsResult>
): Observable<DeviceDataPoint | null> => {
  return getDatapoint(
    dataPoints$,
    SystemDeviceType.MixitSystem,
    MixitDataPointName.PumpControlModeActual
  );
};

// The setpoint can be retrieved from two different sources
export const setpoint = (
  dataPoints$: Observable<DataPointsResult>
): Observable<DeviceDataPoint | null> => {
  return combineLatest([
    getDatapoint(
      dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.EffectiveTempSetPointAverage
    ),
    getDatapoint(
      dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.PumpFlowTemperatureAverage
    )
  ]).pipe(
    map(([effective, pumpFlow]) => {
      if (pumpFlow && pumpFlow.valueType === EValueType.setPoint) {
        return {
          ...pumpFlow,
          dumbledoreId: EEquipment.T_TOP_SETPOINT
        };
      }

      if (effective && effective.valueType === EValueType.setPoint) {
        return {
          ...effective,
          dumbledoreId: EEquipment.T_SF
        };
      }

      return null;
    })
  );
};

export const isSchematicTypeOfSystem = (
  schematic: AppModel,
  key: 'Dynamic' | 'Connect'
): boolean => {
  const system = schematic?.systems[0];
  if (!system) {
    return false;
  }

  const systemInfo = system.systemInfo as IMixitSystemFormInterface;

  return systemInfo[key] as boolean;
};

export const isDynamic = (
  schematic$: Observable<AppModel>
): Observable<boolean> => {
  return schematic$.pipe(
    map((schematic) => {
      return isSchematicTypeOfSystem(schematic, 'Dynamic');
    })
  );
};

export const isConnect = (
  schematic$: Observable<AppModel>
): Observable<boolean> => {
  return schematic$.pipe(
    map((schematic) => {
      return isSchematicTypeOfSystem(schematic, 'Connect');
    })
  );
};

export const productType = (
  schematic$: Observable<AppModel>
): Observable<string> =>
  schematic$.pipe(
    map((schematic) => {
      return (schematic.systems[0].systemInfo as IMixitSystemFormInterface)
        .ProductType as string;
    })
  );
export const shouldShowPumpTile = (schematic$: Observable<AppModel>) =>
  schematic$.pipe(
    map((schematic) => {
      return (
        (schematic.systems[0].systemInfo as IMixitSystemFormInterface)
          .P_type !== 'UnknownPumpMIXIT'
      );
    })
  );

export const setPointSource = (dataPoints$: Observable<DataPointsResult>) => {
  return getDatapoint(
    dataPoints$,
    SystemDeviceType.MixitSystem,
    MixitDataPointName.SetpointSource
  ).pipe(map((d) => d?.value as string));
};

export const manualForcedOpeningEnabled = (
  dataPoints$: Observable<DataPointsResult>
) => {
  return getDatapoint(
    dataPoints$,
    SystemDeviceType.MixitSystem,
    MixitDataPointName.OverrideEnabled
  ).pipe(map((d) => parseBoolean(d?.value as string)));
};

export const pumpType = (
  mixitMetaInfo$: Observable<MixitEquipmentMetaInfo | null>
) => {
  return mixitMetaInfo$.pipe(
    map((meta) => {
      switch (meta?.pumpName) {
        case 'Magna3':
        case 'TPE3':
          return meta?.pumpName?.toUpperCase();
        case 'Magna3D':
          return 'MAGNA3 D';
        case 'TPE3D':
          return 'TPE3 D';
        default:
          return '-';
      }
    })
  );
};

interface ProductInfo {
  modalService: ModalService;
  installation$: Observable<Installation>;
  schematic$: Observable<AppModel>;
  ble$: Observable<string>;
  firmware$: Observable<string>;
  orientation$: Observable<DeviceDataPoint | null>;
}
export const showProductInfo = ({
  modalService,
  installation$,
  schematic$,
  ble$,
  firmware$,
  orientation$
}: ProductInfo) => {
  combineLatest([installation$, schematic$, ble$, firmware$, orientation$])
    .pipe(first())
    .subscribe(
      ([installation, schematic, bleVersion, firmwareVersion, orientation]) => {
        const systemInfo = schematic.systems[0].systemInfo as any; // TODO unclear why ProductType and ProductId is not present on mixit systeminfo?
        const data: MixitModalData = {
          Name: installation.name as string,
          Orientation: orientation?.value ? orientation?.value : '-',
          ProductType: systemInfo.ProductType,
          ProductNumber: systemInfo.ProductNumber,
          ProductId: systemInfo.ProductId,
          Model: systemInfo.Model ? systemInfo.Model : '-',
          ProductionCode: systemInfo.ProductionCode
            ? systemInfo.ProductionCode
            : '-',
          BLE: bleVersion ? bleVersion : '-',
          Firmware: firmwareVersion ? firmwareVersion : '-'
        };
        modalService.openDialog(MixitProductInfoModalComponent, { data });
      }
    );
};

interface PumpInfo {
  translateService: TranslateService;
  modalService: ModalService;
  schematic$: Observable<AppModel>;
}
export const showPumpInfo = ({
  translateService,
  modalService,
  schematic$
}: PumpInfo) => {
  combineLatest([schematic$])
    .pipe(first())
    .subscribe(([schematic]) => {
      const systemInfo = schematic.systems[0].systemInfo as any; // TODO unclear why ProductType and ProductId is not present on mixit systeminfo?

      const linkMap: any = {
        Magna3:
          'https://product-selection.grundfos.com/products/magna/magna3?tab=models',
        Magna3D:
          'https://product-selection.grundfos.com/products/magna/magna3-d?tab=models',
        TPE3: 'https://product-selection.grundfos.com/products/tp-tpe/tpe3-i?tab=models',
        TPE3D:
          'https://product-selection.grundfos.com/products/tp-tpe/tped-series-2000-tpe3-d?tab=models'
      };

      const data: PumpModalData = {
        PumpImage: `./assets/pngs/pumps/${systemInfo.P_type}.png`,
        PumpProductType: systemInfo.PumpProductType
          ? systemInfo.PumpProductType
          : '-',
        PumpProductNumber: systemInfo.PumpProductNumber
          ? systemInfo.PumpProductNumber
          : '-',
        PumpSerialNumber: systemInfo.PumpSerialNumber
          ? systemInfo.PumpSerialNumber
          : '-',
        PumpProductionCode: systemInfo.PumpProductionCode
          ? systemInfo.PumpProductionCode
          : '-',
        PumpLink: linkMap[systemInfo.P_type],
        PumpTitle: translateService.instant(`pump-name.${systemInfo.P_type}`)
      };
      modalService.openDialog(MixitPumpInfoModalComponent, { data });
    });
};

export const filterFacilityInstallationsBySingleLicense = (
  facilities: Facility[],
  licenseType: LicenseType
): Facility[] => {
  // Only return facility installations...
  return facilities
    .map((f) => {
      // ...that ONLY has a license of the given type.
      const filteredInstallations = filterInstallationsByLicense(
        f.installations,
        licenseType
      );
      if (filteredInstallations.length > 0) {
        return {
          ...f,
          installations: filteredInstallations
        };
      }
      return null;
    })
    .filter(Boolean) as Facility[];
};

export const filterInstallationsByLicense = (installations: Installation[], licenseType: LicenseType) => {
  return installations.filter((installation) =>
    installation.licenseInformation.length === 1 &&
    installation.licenseInformation[0].licenseType === licenseType
  );
};

export const filterFacilityInstallationsByLicense = (
  facilities: Facility[],
  bannedLicenseTypes: LicenseType[]
): Facility[] => {
  // Only return facility installations...
  return facilities
    .map((f) => {
      // ...that holds a license of the given type.
      const filteredInstallations = f.installations.filter((i) => {
        const isInstallationEligible = i.licenseInformation.map((license) => {
          const today = moment().unix() * 1000;
          const expired = moment(license.expirationTimeEpoch).unix() * 1000;
          // First, figure out if any licenses this installation holds are banned.
          if (bannedLicenseTypes.includes(license.licenseType)) {
            // If yes, test whether or not it's expired. If it is, it's okay that the installation holds the license.
            if (expired < today) {
              return true;
            }
            // Otherwise, it's not ok.
            return false;
          }
          // If the license type is not banned, we don't mind.
          return true;
        });
        return !isInstallationEligible.includes(false);
      });
      if (filteredInstallations.length > 0) {
        return {
          ...f,
          installations: filteredInstallations
        };
      }
      return;
    })
    .filter(Boolean) as Facility[];
};

export const installationHasActivePaidLicense = (
  installation: Installation
): boolean => {
  // This function is a helper function to determine whether or not an installation is a premium installation or not

  const paidLicenses = [...connectLicenseTypes, ...dynamicLicenseTypes];
  const activeInstallationLicenses = installation.licenseInformation
    .filter((license) => {
      const today = moment().unix() * 1000;
      const expired = moment(license.expirationTimeEpoch).unix() * 1000;
      return expired < today;
    })
    .map((license) => license.licenseType);

  // Firgure out if any active license is a paid license
  return activeInstallationLicenses.some((license) =>
    paidLicenses.includes(license)
  );
};

export const isLessThanHalfHourSinceClaim = (
  installation$: Observable<Installation>
) => {
  return installation$.pipe(
    map((installation) => {
      return moment().diff(installation.createdTimestampEpoch, 'minutes') < 30;
    })
  );
};

export type MixitVersion = {
  productId: string,
  major: number,
  minor: number,
  patch: number,
  revision: number
}

export const parseMixitVersion = (rawVersion: string): MixitVersion | undefined  => {
  // Guards.
  if (rawVersion === undefined || rawVersion == "" || rawVersion == "N/A") return undefined;
  if (rawVersion.toUpperCase().indexOf('V') === -1 || rawVersion.indexOf('.') === -1) return undefined;

  // Raw version format: 99503129V02.00.00.00622
  const productId = rawVersion.substring(0, rawVersion.toUpperCase().indexOf('V'));
  const semantic = (rawVersion.substring(rawVersion.indexOf('V') + 1)).split('.');
  return {
    productId: productId,
    major: Number(semantic[0]),
    minor: Number(semantic[1]),
    patch: Number(semantic[2]),
    revision: Number(semantic[3])
  }
}
