<gbc-tile [title]="productType$ | async">
  <img gbc-tile-left
    (click)="showProductInfo()"
    class="info-img"
    src="./assets/svgs/info-circle_outline.svg"
    angulartics2On="click"
    angularticsAction="mixitProductInfoIconClicked"
    angularticsCategory="mixitSchematicClicked">

  <button gbc-icon-button gbc-tile-right
    (click)="adjustMixit()"
    angulartics2On="click"
    angularticsAction="adjustMixitConfigurationsShown"
    angularticsCategory="mixitSchematicClicked">
    <img [alt]="'mixit-tile.adjust-mixit-device' | translate" src="./assets/svgs/adjust-outline-black.svg">
  </button>

  <div class="tile-content">
    <div class="tile-row">
      <div class="tile-row-name">{{'mixit-tile.primary-flow' | translate}}:</div>
      <div class="tile-row-value">
        {{supplyFlow$ | async | formatDataPoint }}
      </div>
    </div>


    <div class="tile-row" [ngClass]="{disabled: (kvsEnabled$ | async) === false}" *ngIf="(hideKvs$ | async) === false">
      <div class="tile-row-name">{{'mixit-tile.kvs' | translate}}:</div>
      <div class="tile-row-value">
        {{kvs$ | async | formatDataPoint }}
      </div>
    </div>

    <div class="tile-row">
      <div class="tile-row-name">{{'mixit-tile.operation-mode' | translate}}:</div>
      <div class="tile-row-value">
        {{formattedOperationMode$ | async }}
      </div>
    </div>

    <ng-container>
      <div class="tile-row" [ngClass]="{disabled: (manualForcedOpeningEnabled$ | async) === false}">
        <div class="tile-row-name">{{'mixit-tile.manual-forced-opening' | translate}}:</div>
        <div class="tile-row-value">
          {{valveOpenness$ | async | formatDataPoint}}
        </div>
      </div>
    </ng-container>

  </div>
</gbc-tile>
