import { CONNECTION_SPACING } from '../../Common';
import { FORWARD_Y } from '../boiler/boiler-metadata';
import { LabelPosition, SystemMeta } from '../../types';
import { EConnectionPointPlacement } from '../../../types';
import { EConnectionType, SystemType } from '../../../state';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/heatmeter-standalone.svg';
import heatmeterStandaloneSystemForm from '../../../../models/systems/heatmeterStandaloneSystem/heatmeterStandaloneSystemForm.json';
import { getForwardReturnTempLabels, getHorizontalHeatMeterLabels } from '../../heat-meter-or-sensors/heat-meter-or-sensors-metadata';
import { IHeatmeterStandaloneSystemFormInterface } from '../../../../models/systems/heatmeterStandaloneSystem/heatmeterStandaloneSystemFormInterface';

export const WIDTH = 180;
export const SUPPLY_Y = 100;
export const RETURN_Y = SUPPLY_Y + CONNECTION_SPACING;
export const HEAT_METER_X = 100;

export const heatmeterStandaloneMeta: SystemMeta = {
  systemType: SystemType.HeatmeterStandalone,
  name: 'Heatmeter Standalone',
  thumbnail,
  width: WIDTH,
  height: 260,
  form: heatmeterStandaloneSystemForm,
  calculateConnectionPoints: () => [
    {
      key: EConnectionPointPlacement.connectionPrimaryForward,
      x: 0,
      y: SUPPLY_Y,
      type: EConnectionType.supply,
      dotted: false,
    },
    {
      key: EConnectionPointPlacement.connectionPrimaryReturn,
      x: 0,
      y: RETURN_Y,
      type: EConnectionType.return,
      dotted: true,
    },
    {
      key: EConnectionPointPlacement.connectionSecondaryForward,
      x: WIDTH,
      y: SUPPLY_Y,
      type: EConnectionType.supply,
      dotted: false,
    },
    {
      key: EConnectionPointPlacement.connectionSecondaryReturn,
      x: WIDTH,
      y: RETURN_Y,
      type: EConnectionType.return,
      dotted: true,
    },
  ],
};

export const getHeatmeterSystemLabels = (systemInfo: IHeatmeterStandaloneSystemFormInterface): LabelPosition[] => {
  return [
    ...getForwardReturnTempLabels(HEAT_METER_X, FORWARD_Y, systemInfo),
    ...getHorizontalHeatMeterLabels(HEAT_METER_X, FORWARD_Y, systemInfo),
  ];
};
