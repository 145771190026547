import { DraggingSystemState } from '../state';
import { setSystemPosition, stopDraggingSystem } from '../actions';
import { InputEventType, InputStrategy } from './input-strategy-service';

export const draggingSystemInputStrategy: InputStrategy = {
  name: 'DraggingSystem',
  handleEvent(dispatch, state, event) {
    const draggingSystemState = state.draggingSystemState as DraggingSystemState;
    switch (event.type) {
      case InputEventType.MouseMove: {
        if (!state.draggingSystemState) {
          throw new Error('Invalid state: draggingSystemInputStrategy input handler but no system is being dragged');
        }
        dispatch(
          setSystemPosition({
            systemId: draggingSystemState.systemId,
            x: event.x - draggingSystemState.offsetX,
            y: event.y - draggingSystemState.offsetY,
          })
        );
        return;
      }
      case InputEventType.MouseUp: {
        if (!state.draggingSystemState) {
          throw new Error('Invalid state: draggingSystemInputStrategy input handler but no system is being dragged');
        }
        dispatch(stopDraggingSystem());
        return;
      }
    }
  },
  render: () => [],
};
