import React from 'react';
import { TValveOpening } from '../types';
import { ValueLabel } from './ValueLabel';
import { ValveOpeningIndicator } from './ValveOpeningIndicator';

interface ValveLabelProps {
  x: number;
  y: number;
  id: string;
  alignment: 'start' | 'middle' | 'end' | undefined;
  valveOpening: TValveOpening;
  value: string;
  setpoint?: string;
}

export const TEXT_WIDTH = 30;
export const VALVE_OPENING_WIDTH = 14;
export const TOTAL_WIDTH = TEXT_WIDTH + VALVE_OPENING_WIDTH;

export const ValveLabel: React.FC<ValveLabelProps> = React.memo(({ id, x, y, alignment, valveOpening, value, setpoint }) => {
  // Create offset to position valve indication and value based on alignment.
  const { valueOffset, valveIndicationOffset } = calculateLabelOffsets(alignment);

  return (
    <g>
      <ValueLabel key={id + '_value'} id={id} value={value} setpoint={setpoint} x={x + valueOffset} y={y} alignment={alignment} />
      <ValveOpeningIndicator key={id + '_openingIndicator'} x={x + valveIndicationOffset} y={y} state={valveOpening} />
    </g>
  );
});

const calculateLabelOffsets = (alignment: 'start' | 'middle' | 'end' | undefined) : { valueOffset: number, valveIndicationOffset: number } => {
  switch (alignment) {
    case "middle" :
      return { valueOffset: (-TOTAL_WIDTH / 2) + 10, valveIndicationOffset: 4 };
    case "end" :
      return { valueOffset: -15, valveIndicationOffset: -5 };
    case "start" :
    default :
      return { valueOffset: 0, valveIndicationOffset: TEXT_WIDTH };
  }
}
