import { SystemType, DistrictHeatingSystemState, BoilerSystemState, CommercialHotWaterStandaloneSystemState } from '../state';
import { EEquipment, EValueType, IEquipmentValue, ISetPointValue, IValveOpenValue } from '../types';
import { SampleEquipmentValue } from './sample-equipment-values';
import { v4 } from 'uuid';

// WILL BE DEPRECATED
export const SAMPLE_COMMERCIAL_HOT_WATER_STANDALONE: CommercialHotWaterStandaloneSystemState = {
  hasId: v4(),
  type: SystemType.CommercialHotWaterStandalone,
  left: 420,
  top: 1800,
  configured: false,
  systemInfo: {
    P_connected: true,
    P_hasPump: true,
    P_type: 'Magna3'
  }
};

export const SAMPLE_VALUES_COMMERCIAL_HOT_WATER_STANDALONE: SampleEquipmentValue[] = [
  {
    systemType: SystemType.CommercialHotWaterStandalone,
    type: EValueType.value,
    equipment: EEquipment.P_CR,
    value: '1.7\u33a5/h\n2.3m',
  }
];
