<gbc-tile [title]="'underfloor-protection-tile.underfloor-protection' | translate">
  <button gbc-icon-button gbc-tile-right
    (click)="adjustUnderfloorProtection()"
    angulartics2On="click"
    angularticsAction="adjustUnderfloorProtectionShown"
    angularticsCategory="mixitSchematicClicked">
    <img [alt]="'underfloor-protection-tile.adjust-underfloor-protection' | translate" src="./assets/svgs/adjust-outline-black.svg">
  </button>
  <div class="tile-content">

    <div class="tile-row" [ngClass]="{disabled: (underFloorProtectionEnabled$ | async) === false}">
      <div class="tile-row-name">{{'underfloor-protection-tile.overheat-protection' | translate}}:</div>
      <div class="tile-row-value"> {{'underfloor-protection-tile.max-temperature' | translate : {maxTemperature: overheatProtectionTemperature$ | async | formatDataPoint} }}</div>
    </div>

  </div>
</gbc-tile>
