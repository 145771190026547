// Note: Why is pasteuration state a device type? Is this just a hack and piggybagging on the device data architecture? (Remember the SOLID principles)
export enum SystemDeviceType {
  Unknown = 'Unknown',
  SensorFlow = 'SensorFlow',
  SensorPosition = 'SensorPosition',
  PositionSensor = 'PositionSensor',
  Pump = 'Pump',
  MixitSystem = 'MixitSystem',
  FlowMeter = 'FlowMeter',
  HeatMeter = 'HeatMeter',
  BoosterSystem = 'BoosterSystem',
  PasteurizationState = 'PasteurizationState',
  WarmWeatherShutdown = 'WarmWeatherShutdown',
  Actuator = 'Actuator'
}
