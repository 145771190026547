import { SystemType, DistrictHeatingSystemState, BoilerSystemState, BufferTankSystemState } from '../state';
import { EEquipment, EValueType, IEquipmentValue, ISetPointValue, IValveOpenValue } from '../types';
import { SampleEquipmentValue } from './sample-equipment-values';
import { v4 } from 'uuid';

// WILL BE DEPRECATED
export const SAMPLE_BUFFER_TANK: BufferTankSystemState = {
  hasId: v4(),
  left: 1100,
  top: 1800,
  configured: false,
  type: SystemType.BufferTank,
  systemInfo: {
    title: 'Buffer Tank',
    T_SF_present: true,
    T_SR_present: true,
    T_PF_present: true,
    T_PR_present: true,
    T_CENTERTOP_present: true,
    T_CENTERBOTTOM_present: true
  }
};

export const SAMPLE_VALUES_BUFFER_TANK: SampleEquipmentValue[] = [
  {
    systemType: SystemType.BufferTank,
    type: EValueType.value,
    equipment: EEquipment.T_PF,
    value: 'T_PF',
  },
  {
    systemType: SystemType.BufferTank,
    type: EValueType.value,
    equipment: EEquipment.T_PR,
    value: 'T_PR',
  },
  {
    systemType: SystemType.BufferTank,
    type: EValueType.value,
    equipment: EEquipment.T_SF,
    // value: '72.9\u2103'
    value: 'T_SF',
  },
  {
    systemType: SystemType.BufferTank,
    type: EValueType.value,
    equipment: EEquipment.T_SR,
    value: 'T_SR',
  },
  {
    systemType: SystemType.BufferTank,
    type: EValueType.value,
    equipment: EEquipment.T_BOTTOM,
    value: 'T_B',
  },
  {
    systemType: SystemType.BufferTank,
    type: EValueType.value,
    equipment: EEquipment.T_T,
    value: 'T_T',
  },
  {
    systemType: SystemType.BufferTank,
    type: EValueType.value,
    equipment: EEquipment.T_CENTERTOP,
    value: 'T_CT',
  },
  {
    systemType: SystemType.BufferTank,
    type: EValueType.value,
    equipment: EEquipment.T_CENTERBOTTOM,
    value: 'T_CB',
  },
  {
    systemType: SystemType.BufferTank,
    type: EValueType.value,
    equipment: EEquipment.P_F,
    value: '99.7\u33a5/h\n10.7m',
  },
  {
    systemType: SystemType.BufferTank,
    type: EValueType.value,
    equipment: EEquipment.P_R,
    value: '99.7\u33a5/h\n10.7m',
  }
];
