import { Component, OnInit, OnChanges, Input, Output, EventEmitter, Self, Optional, OnDestroy, SimpleChanges } from '@angular/core';
import { NgControl, ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs';

export enum NgControlStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  PENDING = 'PENDING',
  DISABLED = 'DISABLED'
}

type TooltipPosition = "right" | "left" | "top" | "bottom";

@Component({
  selector: 'gbc-input-field',
  templateUrl: './input-field.component.html',
  styleUrls: ['./input-field.component.scss'],
})
export class InputFieldComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() max: string;
  @Input() min: string;
  @Input() step: string;
  @Input() maxlength: number;
  @Input() placeholderTranslationString: string;
  @Input() inputType = 'text';
  @Input() errorDescriptionTranslationString: string;
  @Input() errorDescriptionData: { [k: string]: string } = {};
  @Input() formControlName: string;
  @Input() unit: string;
  @Input() disabled: boolean;
  @Input() showErrorTranslationString: boolean;
  @Input() matchRelativeParentDimensions: boolean;

  /**
   * Ptooltip
   *
   * These are all related to the pTooltip that is wrapped in this custom components.
   * https://www.primefaces.org/primeng/tooltip
   */
  @Input() toolTipText: string;
  @Input() disabledToolTipText: string;
  @Input() tooltipPosition: TooltipPosition;
  tooltipTextToShow: string;

  /**
   * Ptooltip
   */

  @Output() fieldKeyupEvent = new EventEmitter<string>();

  public valid: boolean;
  private subscription = new Subscription();



  // NgModel
  innerValue: any;
  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v === this.innerValue) {
      return;
    }
    this.innerValue = v;
    this.onChangeCallback(v);
  }

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    const setValid = (status: NgControlStatus) => {
      this.valid = status === NgControlStatus.VALID || status === NgControlStatus.DISABLED;
    };

    const setTooltip = () => {
      this.tooltipTextToShow = this.ngControl?.control?.disabled ? this.disabledToolTipText : this.toolTipText;
    }

    this.subscription.add(this.ngControl?.statusChanges?.subscribe(setValid));
    this.subscription.add(this.ngControl?.statusChanges?.subscribe(setTooltip));

    // Calculate the state on start, as there is no event
    setValid(this.ngControl?.status as NgControlStatus);

    // Set correct tooltip
    setTooltip();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onKey(value: string) {
    this.fieldKeyupEvent.emit(value);
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: any) {
    if (value === this.innerValue) {
      return;
    }
    if (this.inputType === 'number') {
      this.innerValue = parseFloat(value);
    } else {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: (_: number | null) => void): void {
    this.onChangeCallback = (value) => {
      if (this.inputType === 'number') {
        fn(value === '' ? null : parseFloat(value));
      } else {
        fn(value);
      }
    };
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
}
