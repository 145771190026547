interface ITranslations {
  [k: string]: ITranslationStrings;
}

export interface ITranslationStrings {
  mixingLoops: string;
  mixingLoopSystem: string;
  mixitSystem: string;
  heatExchangingSystem: string;
  heatExchanging: string;
  supply: string;
  wasteWater: string;
  wasteWaterSystem: string;
  districtSupplyHeatingSystem: string;
  boilerSystem: string;
  other: string;
  pumpSystem: string;
  hotWaterPumpSystem: string;
  coldWaterPumpSystem: string;
  commercialHotWaterSystem: string;
  boosterSystem: string;
  hotWaterTankSystem: string;
  outdoorTemperatureSystem: string;

  help: string;
  back: string;
  deleteSchematic: string;
  cloneSystem: string;
  deleteSystem: string;
  confirmDeleteSchematic: string;
  confirmDeleteSystem: string;
  close: string;
  selectSystemPlaceholder: string;
  building: string;
  system: string;
  inputNeeded: string;

  clarifications: string;
  delete: string;
  fullScreen: string;
  closeFullScreen: string;
}

const languages: ITranslations = {
  da: {
    mixingLoops: 'Blandesløjfer',
    mixingLoopSystem: 'Blandesløjfe',
    mixitSystem: 'MIXIT',
    heatExchangingSystem: 'Varmeveksler',
    heatExchanging: 'Varmeveksling',
    supply: 'Forsyning',
    wasteWater: 'Wastewater',
    wasteWaterSystem: 'Wastewater',
    districtSupplyHeatingSystem: 'Fjernvarme',
    other: 'Andre',
    pumpSystem: 'Pumpesystem',
    hotWaterPumpSystem: 'Varmvandspumpe',
    coldWaterPumpSystem: 'Koldvandspumpe',
    boilerSystem: 'Kedel',
    commercialHotWaterSystem: 'Commercial hot water',
    boosterSystem: 'Booster',
    hotWaterTankSystem: 'Hot Water Tank',
    outdoorTemperatureSystem: 'Udendørstemperatur',

    help: 'Hjælp',
    back: 'tilbage',
    deleteSchematic: 'Slet tegning',
    deleteSystem: 'Slet system',
    cloneSystem: 'Klon system',
    confirmDeleteSchematic: 'Vil du slette den skematisk teging?',
    confirmDeleteSystem: 'Vil du slette systemet?',
    close: 'Luk',
    selectSystemPlaceholder: 'Vælg et system',
    building: 'Bygning',
    system: 'System',
    inputNeeded: 'Input påkrævet',

    clarifications: 'Afklaringer',
    delete: 'Slet',
    fullScreen: 'Fuld skærm',
    closeFullScreen: 'Luk fuld skærm',
  },
  en: {
    mixingLoops: 'Mixing loops',
    mixingLoopSystem: 'Mixing loop',
    mixitSystem: 'MIXIT',
    heatExchangingSystem: 'Heat Exchanger',
    heatExchanging: 'Heat exchanging',
    supply: 'Supply',
    wasteWater: 'Wastewater',
    wasteWaterSystem: 'Wastewater',
    districtSupplyHeatingSystem: 'District Supply\nHeating',
    other: 'Other',
    pumpSystem: 'Pump',
    hotWaterPumpSystem: 'Hot Water Pump',
    coldWaterPumpSystem: 'Cold Water Pump',
    boilerSystem: 'Boiler',
    commercialHotWaterSystem: 'Commercial hot water',
    boosterSystem: 'Booster',
    hotWaterTankSystem: 'Hot Water Tank',
    outdoorTemperatureSystem: 'Outdoor temperature',

    help: 'Help',
    back: 'back',
    deleteSchematic: 'Delete schematic',
    deleteSystem: 'Delete system',
    cloneSystem: 'Clone system',
    confirmDeleteSystem: 'Are you sure you want to delete the system?',
    confirmDeleteSchematic: 'Are you sure you want to delete the schematic?',
    close: 'Close',
    selectSystemPlaceholder: 'Please select a system',
    building: 'Building',
    system: 'System',
    inputNeeded: 'Input needed',

    clarifications: 'Clarifications',
    delete: 'Delete',
    fullScreen: 'Full screen',
    closeFullScreen: 'Close full screen',
  },
};

export type TLanguage = 'da' | 'en';

export class TranslationService {
  public get lang() {
    return this.language;
  }

  public set lang(val: TLanguage) {
    // TODO: Do some updating here
    this.language = val;
  }

  private language: TLanguage = 'en';

  public getTranslation(identifier: keyof ITranslationStrings) {
    return languages[this.lang][identifier];
  }
}

export const translationService = new TranslationService();
