<gbc-modal (dismiss)='dismiss()' [title]="'mixit-warm-weather-shutdown-modal.warm-weather-shutdown' | translate" class="gbc-with-dividers" data-test-id='mixit-warm-weather-shutdown-modal'>
  <gbc-modal-content>
    <h2 class='modal-header'>
      {{ 'mixit-warm-weather-shutdown-modal.how-it-works' | translate }}
    </h2>
    <p class='modal-text'>
      {{ 'mixit-warm-weather-shutdown-modal.warm-weather-shutdown-description' | translate }}
    </p>
    <form [formGroup]='form'>

      <gbc-modal-section>
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'app-enable' | translate }}</gbc-input-label>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-toggle formControlName='enabled'></gbc-toggle>
        </gbc-modal-section-column>
      </gbc-modal-section>

      <gbc-modal-section>
        <gbc-modal-section-column>
          <gbc-input-label> {{ 'mixit-warm-weather-shutdown-modal.outdoor-temperature-threshold' | translate }}</gbc-input-label>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-field
            [errorDescriptionData]='thresholdMinMax'
            [max]='thresholdMinMax.max'
            [min]='thresholdMinMax.min'
            [showErrorTranslationString]='true'
            errorDescriptionTranslationString='app-enter-value-between'
            formControlName='threshold'
            inputType='number'
            unit='°C'>
          </gbc-input-field>
        </gbc-modal-section-column>
      </gbc-modal-section>

      <gbc-modal-section>
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'mixit-warm-weather-shutdown-modal.average-period' | translate }}</gbc-input-label>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-dropdown
            [dropDownItems]='periods'
            formControlName='averagePeriod'
            placeholderTranslationString='app-select'>
          </gbc-dropdown>
        </gbc-modal-section-column>
      </gbc-modal-section>
    </form>
  </gbc-modal-content>
  <gbc-modal-buttons>
    <button
      (click)='close()'
      angulartics2On='click'
      angularticsAction='cancelClicked'
      angularticsCategory='adjustWarmWeatherShutdownShown'
      gbc-modal-button>
      {{ 'app-cancel' | translate }}
    </button>
    <button
      (click)='save()'
      [disabled]='(valid$ | async) === false'
      angulartics2On='click'
      angularticsAction='saveClicked'
      angularticsCategory='adjustWarmWeatherShutdownShown'
      class='primary'
      gbc-modal-button>
      {{ 'app-save' | translate }}
    </button>
  </gbc-modal-buttons>
</gbc-modal>
