import { Component, OnInit } from '@angular/core';
import { InstallationStateService } from '../../services/installation-state.service';
import { EnergyManagementDashboardData, EHealthCheckTargetKey, EnergyDashboardSettingsDto } from '../../interfaces/installation';
import { Observable, combineLatest, EMPTY } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FacilityService } from '../../services/facility.service';
import { finalize, first, map, switchMap } from 'rxjs/operators';
import { EnergyManagementDashboardSettingsModalComponent } from './energy-management-dashboard-settings-modal/energy-management-dashboard-settings-modal.component';
import { ModalService, Result } from 'shared';
import { InstallationService } from '../../services/installation.service';
import { SchematicsService } from '../../services/schematics.service';

@Component({
  selector: 'app-energy-management-dashboard',
  templateUrl: './energy-management-dashboard.component.html',
  styleUrls: ['./energy-management-dashboard.component.scss'],
})
export class EnergyManagementDashboardComponent implements OnInit {
  public dashboardData$: Observable<EnergyManagementDashboardData | null | undefined>;
  public healthCheckTargetKey = EHealthCheckTargetKey;
  private installationId: string;
  public isShowingSettingsDialog: boolean;

  constructor(
    private installationStateService: InstallationStateService,
    private installationService: InstallationService,
    private facilityService: FacilityService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private schematicsService: SchematicsService,
  ) {}

  ngOnInit(): void {
    this.dashboardData$ = combineLatest([
      this.facilityService.getCurrentInstallation(this.route),
      this.installationStateService.installationEnergyDashboards$,
    ]).pipe(
      map(([installation, energyManagementDashboards]) => {
        if (!energyManagementDashboards) {
          return null;
        }
        this.installationId = installation.id;
        return energyManagementDashboards?.find((emdb) => emdb.installationId === installation.id);
      })
    );
  }

  showSettingsDialog() {
    if (this.isShowingSettingsDialog) { return };
    this.isShowingSettingsDialog = true;

    this.schematicsService.getEnergyDashboardSettings().pipe(
      first(),
      switchMap((settings: EnergyDashboardSettingsDto) => {
        return this.modalService.openDialog<EnergyDashboardSettingsDto>(EnergyManagementDashboardSettingsModalComponent, { data: settings });
      }),
      switchMap((response: Result<EnergyDashboardSettingsDto>) => {
        if (response.dismissed) { return EMPTY; }
        return this.installationService.updateInstallationEnergyDashboardSettings(this.installationId, response.result);
      }),
    ).subscribe({
      complete: () => { this.isShowingSettingsDialog = false; }
    });
  }

  shouldShowDashboard(dashboardData: EnergyManagementDashboardData) {
    return (dashboardData.totalDistrictHeating && (dashboardData.totalDistrictHeating.kpis.length > 0 || dashboardData.totalDistrictHeating.healthChecks.length > 0)) ||
           (dashboardData.domesticHotWater && (dashboardData.domesticHotWater.kpis.length > 0 || dashboardData.domesticHotWater.healthChecks.length > 0)) ||
           (dashboardData.spaceHeating && (dashboardData.spaceHeating.kpis.length > 0 || dashboardData.spaceHeating.healthChecks.length > 0));
  }
}
