import { EEquipment, EValueType, SystemType } from '@ams/dumbledore';
import { SystemControlMode } from './system-control-mode';
import { SystemDeviceType } from './systemDeviceType';

export interface System {
  systemId: string;
  systemControlMode: SystemControlMode;
  showOutdoorTemperatureSensor: boolean,
  systemType: SystemType;
  devices: SystemDevice[];
}

export interface DataPointsResult {
  installationId: string;
  heartBeatTimestamp: number;
  data: System[];
}

export interface SystemDevice {
  deviceId: string;
  type: SystemDeviceType;
  dataPoints: DeviceDataPoint[];
}

export interface DeviceDataPoint {
  clientId: string,
  dataPointId: string;
  dumbledoreId: EEquipment;
  humanReadableId: string;
  value: string | null;
  valueType: EValueType;
  unitType: EBackendUnit;
  timestampEpoch: number;
  state: DataPointState;
  type: DataPointType;
}

export interface FormatDataPointOptions {
  valueOnly?: boolean;
  unitOnly?: boolean;
}

export enum DataPointType {
  Telemetry = 'Telemetry',
  Event = 'Event',
}

export enum DataPointState {
  Synced = 'Synced',
  Pending = 'Pending',
  NotSynced = 'NotSynced',
}

// This should be kept in sync with the backend enum UnitType
export enum EBackendUnit {
  // region None
  None = 'None',
  // endregion

  // region CubicMeter
  CubicMeter = 'CubicMeter',
  // endregion

  // region CubicMeterPerHour
  CubicMeterPerHour = 'CubicMeterPerHour',
  CubicMeterPerSecond = 'CubicMeterPerSecond',
  // endregion

  // region DegreeCelsius
  DegreeCelsius = 'DegreeCelsius',
  DegreeKelvin = 'DegreeKelvin',
  // endregion

  // region Watt
  Watt = 'Watt',
  KiloWatt = 'KiloWatt',
  MegaWatt = 'MegaWatt',
  // endregion

  // region WattHour
  WattHour = 'WattHour',
  Joule = 'Joule',
  KiloWattHour = 'KiloWattHour',
  MegaWattHour = 'MegaWattHour',
  // endregion

  // region MeterHead
  MeterHead = 'MeterHead',
  Bar = 'Bar',
  MilliBar = 'MilliBar',
  Pascal = 'Pascal',
  // endregion

  // region Hertz
  Hertz = 'Hertz',
  RevolutionsPerMinute = 'RevolutionsPerMinute',
  // endregion

  // region Percentage
  Percentage = 'Percentage',
  Fraction = 'Fraction',

  // region Time
  Day = 'Day',
  Second = 'Second',
  // endregion

  // region Currency
  Euro = 'Euro',
  // endregion

  // region EnergyReporting
  KiloWattHourSquareMeter = 'KiloWattHourSquareMeter',
  MetricTon = 'MetricTon',
  // endregion
}

export interface Device {
  name: string;
  value: number;
  timestampEpoch?: number;
}

export interface ReportDataPoint {
  avg: number;
  stdDev: number;
  intervalBeginTimestampEpoch: number;
  intervalDuration: number;
}

export type PointArray = Array<[number, number]>;

export interface Report {
  tableName: string;
  installationId: string;
  data: ReportDataPoint[];
}
