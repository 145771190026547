<gbc-page-header *ngIf="pageInfo" [pageInfo]="pageInfo"></gbc-page-header>

<gbc-page-container>
  <gbc-tile [title]="'mixit-subscription-overview.free-vs-professional' | translate">
    <button gbc-button gbc-tile-right [routerLink]="['/upgrade', 'subscribe']">
      {{'mixit-subscription-overview.purchase-upgrade' | translate}}
    </button>
    <gbc-table>
      <th gbc-table-header></th>
      <th gbc-table-header>{{'mixit-subscription-overview.functionality' | translate}}</th>
      <th gbc-table-header>{{'mixit-subscription-overview.free-monitoring' | translate}}</th>
      <th gbc-table-header>{{'mixit-subscription-overview.professional' | translate}}</th>

      <tr gbc-table-row>
        <td gbc-table-cell>
          <b>{{'mixit-subscription-overview.platform' | translate}}</b>
        </td>
        <td gbc-table-cell [innerHTML]="'mixit-subscription-overview.map-list-overview' | translate"></td>
        <td gbc-table-cell>
          <div class="cell-container">
            <img class="check" src="./assets/svgs/check-outline-green.svg">
            {{'mixit-subscription-overview.included' | translate}}
          </div>
        </td>
        <td gbc-table-cell>
          <div class="cell-container">
            <img class="check" src="./assets/svgs/check-outline-green.svg">
            {{'mixit-subscription-overview.included' | translate}}
          </div>
        </td>
      </tr>

      <tr gbc-table-row>
        <td gbc-table-cell>
          <b>{{'mixit-subscription-overview.monitoring' | translate}}</b>
        </td>
        <td gbc-table-cell [innerHTML]="'mixit-subscription-overview.temperatures-valve-opening' | translate"></td>
        <td gbc-table-cell>
          <div class="cell-container">
            <img class="check" src="./assets/svgs/check-outline-green.svg">
            {{'mixit-subscription-overview.ten-datapoints' | translate}}
          </div>
        </td>
        <td gbc-table-cell>
          <div class="cell-container">
            <img class="check" src="./assets/svgs/check-outline-green.svg">
            {{'mixit-subscription-overview.plus-twenty-five-datapoints' | translate}}
          </div>
        </td>
      </tr>

      <tr gbc-table-row>
        <td gbc-table-cell>
          <b>{{'mixit-subscription-overview.alarms' | translate}}</b>
        </td>
        <td gbc-table-cell [innerHTML]="'mixit-subscription-overview.alarm-or-warning-simple-alarms' | translate"></td>
        <td gbc-table-cell>
          <div class="cell-container">
            <img class="check" src="./assets/svgs/check-outline-green.svg">
            {{'mixit-subscription-overview.simple-alarms' | translate}}
          </div>
        </td>
        <td gbc-table-cell>
          <div class="cell-container">
            <img class="check" src="./assets/svgs/check-outline-green.svg">
            {{'mixit-subscription-overview.advanced-alarms' | translate}}
          </div>
        </td>
      </tr>

      <tr gbc-table-row>
        <td gbc-table-cell>
          <b>{{'mixit-subscription-overview.remote-control' | translate}}</b>
        </td>
        <td gbc-table-cell  [innerHTML]="'mixit-subscription-overview.temperature-setpoint-pump-controls' | translate"></td>
        <td gbc-table-cell>
          <div class="cell-container">
            <img class="check" src="./assets/svgs/close_outline-red.svg">
            {{'mixit-subscription-overview.not-included' | translate}}
          </div>
        </td>
        <td gbc-table-cell>
          <div class="cell-container">
            <img class="check" src="./assets/svgs/check-outline-green.svg">
            {{'mixit-subscription-overview.included' | translate}}
          </div>
        </td>
      </tr>

      <tr gbc-table-row>
        <td gbc-table-cell>
          <b>{{'mixit-subscription-overview.trend-data' | translate}}</b>
        </td>
        <td gbc-table-cell [innerHTML]="'mixit-subscription-overview.temperatures-valve-opening-flow' | translate"></td>
        <td gbc-table-cell>
          <div class="cell-container">
            <img class="check" src="./assets/svgs/close_outline-red.svg">
            {{'mixit-subscription-overview.not-included' | translate}}
          </div>
        </td>
        <td gbc-table-cell>
          <div class="cell-container">
            <img class="check" src="./assets/svgs/check-outline-green.svg">
            {{'mixit-subscription-overview.included' | translate}}
          </div>
        </td>
      </tr>

      <tr gbc-table-row>
        <td gbc-table-cell>
          <b>{{'mixit-subscription-overview.analytics' | translate}}</b>
        </td>
        <td gbc-table-cell [innerHTML]="'mixit-subscription-overview.benchmarking-system-insights' | translate"></td>
        <td gbc-table-cell>
          <div class="cell-container">
            <img class="check" src="./assets/svgs/close_outline-red.svg">
            {{'mixit-subscription-overview.not-included' | translate}}
          </div>
        </td>
        <td gbc-table-cell>
          <div class="cell-container">
            <img class="check" src="./assets/svgs/check-outline-green.svg">
            {{'mixit-subscription-overview.included' | translate}}
          </div>
        </td>
      </tr>

      <tr gbc-table-row>
        <td gbc-table-cell>
          <b>{{'mixit-subscription-overview.user-management' | translate}}</b>
        </td>
        <td gbc-table-cell [innerHTML]="'mixit-subscription-overview.roles-notification-types' | translate"></td>
        <td gbc-table-cell>
          <div class="cell-container">
            <img class="check" src="./assets/svgs/check-outline-green.svg">
            {{'mixit-subscription-overview.single-user' | translate}}
          </div>
        </td>
        <td gbc-table-cell>
          <div class="cell-container">
            <img class="check" src="./assets/svgs/check-outline-green.svg">
            <span [innerHTML]="'mixit-subscription-overview.multiple-users' | translate"></span>
          </div>
        </td>
      </tr>

    </gbc-table>
  </gbc-tile>

  <div>
    <p [innerHTML]="'mixit-subscription-overview.only-for-dynamic' | translate"></p>
    <p [innerHTML]="'mixit-subscription-overview.not-in-free-trial' | translate"></p>
  </div>
</gbc-page-container>
