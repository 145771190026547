import styled from "styled-components";

export const StyledDumbledoreEditor = styled.div`
  display: block;
  height: 100%;
  position: relative;
  font-family: 'GrundfosTheSansV2', sans-serif;
  background: white;
  overflow: hidden;
  .editor {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .main {
    flex: 1;
    overflow: hidden;
    display: flex;
  }
  .schematic-container {
    flex: 1;
    overflow: auto;
    background-color: white;
  }
  .sidebar {
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .form-sidebar {
    width: 350px;
    border-left: 1px solid lightgrey;
    background-color: #f3f5f8;
  }
  .form-sidebar button {
    margin-bottom: 1em;
  }
  .tab-validity-icon {
    // display: inline;
    margin: 0 !important;
    margin-bottom: -5px !important;
    margin-left: 8px !important;
  }
  .tab-header {
    background-color: white;
    opacity: 0.8;
    font-family: 'GrundfosTheSansV2';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.35px;
    text-align: center;
    color: #000000;
    display: flex;
  }
  .tab {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 12px 25px 12px 50px;
    border-bottom: 4px solid white;
    cursor: pointer;
    user-select: none;
    &.selected {
      border-bottom: 4px solid #0068cb;
    }
  }

  .fileUploadButton {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px 0px;
    border: 1px solid rgb(225, 225, 225);
    background-color: rgb(255, 255, 255);
    padding: 10px 15px;
    margin: 5px 5px;
    font-size: 16px;
    font-weight: 400;
    font-family: GrundfosTheSansV2, sans-serif;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    &::after {
      content: none !important;
    }

    &:hover {
      background-color: rgb(243, 245, 248);
      cursor: pointer;
    }
  }



`;
