<gbc-table>
  <th gbc-table-header>{{'alarm-comments-table.header-date' | translate}}</th>
  <th gbc-table-header>{{'alarm-comments-table.header-message' | translate}}</th>
  <th gbc-table-header>{{'alarm-comments-table.header-author' | translate}}</th>

  <tr gbc-table-row *ngFor="let comment of comments">
    <td gbc-table-cell>{{comment.timestampEpoch | timeWithAgo}}</td>
    <td gbc-table-cell>{{comment.comment}}</td>
    <td gbc-table-cell>{{comment.username}}</td>
  </tr>
</gbc-table>
