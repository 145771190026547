import { Component, OnInit } from '@angular/core';
import { PageInfoService } from '../../services/page-info.service';
import { PageInfo } from 'shared';

@Component({
  selector: 'app-mixit-subscription-overview',
  templateUrl: './mixit-subscription-overview.component.html',
  styleUrls: ['./mixit-subscription-overview.component.scss']
})
export class MixitSubscriptionOverviewComponent implements OnInit {
  public pageInfo: PageInfo;

  constructor(private pageInfoService: PageInfoService) { }

  ngOnInit(): void {
    this.pageInfo = this.pageInfoService.mixitSubscriptionOverviewPage();
  }

}
