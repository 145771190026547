<app-mixit-x509-banner/>
<app-alarm-bar *ngIf="alarm$ | async" [alarm]="alarm$"> </app-alarm-bar>

<app-schematics-tile
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [showShadow]="false"
  [dataPoints$]="dataPoints$"
>
</app-schematics-tile>

<app-pump-status-tile
  *ngIf="pumpDevice$ | async"
  [installation$]="installation$"
  [application]="application"
  [pumpType$]="pumpType$"
  [pumpDevice$]="pumpDevice$"
  [pumpOptions]="pumpOptions$ | async"
>
</app-pump-status-tile>

<app-heat-meter-status-tile
  [application]="application"
  [installation$]="installation$"
  [dataPoints$]="dataPoints$"
  [schematic$]="schematic$"
>
</app-heat-meter-status-tile>

<app-forward-return-chart-tile
  [title]="'boiler-dashboard.temperature-title' | translate"
  [applicationId]="application.id"
  forwardDumbledoreId="T_F"
  returnDumbledoreId="T_R"
  [dataPoints$]="dataPoints$"
  [userTrackingPeriodOptions]="{ category: 'application/boiler', intervalPrefix: 'temperature' }"
>
</app-forward-return-chart-tile>
