import { Injectable } from '@angular/core';
import { AppConfigurationClient, ConfigurationSetting } from '@azure/app-configuration';
import { environment } from '../../../../customerportal/src/environments/environment';
import { ConfigurationService } from 'shared';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeatureToggleService {
  private client: AppConfigurationClient;
  constructor(private configurationService: ConfigurationService) {
    this.configurationService.configuration.subscribe((configuration) => {
      if (configuration && configuration.appConfigurationConnectionString) {
        this.client = new AppConfigurationClient(configuration.appConfigurationConnectionString);
      }
    });
  }

  getFeatureFlag(key: string, label?: string | undefined): Observable<boolean> {
    return from(this.getFeatureFlagFromAppConfiguration(key, label));
  }

  private async getFeatureFlagFromAppConfiguration(key: string, label?: string | undefined): Promise<boolean> {
    /**
     * When we are running in test mode, ignore feature flags and show all features so that it can be tested
     */
    if (this.runningInTestMode()) {
      return true;
    }

    /**
     * If the client was not initialised correctly then return false so that features are hidden by default
     */
    if (!this.client) {
      console.error('The AppConfigurationClient was not initialised so feature flags are disabled');
      return false;
    }

    try {
      const configurationSetting: ConfigurationSetting = await this.client.getConfigurationSetting({
        key: `.appconfig.featureflag/${key}`,
        label: label
      });
      return JSON.parse(configurationSetting.value as string).enabled;
    } catch (error) {
      console.error(`An error occurred trying to retrieve the feature flag: ${key}`, error);
      return false;
    }
  }

  private runningInTestMode(): boolean {
    return environment.testMode;
  }
}
