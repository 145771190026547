import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, Self, Optional, AfterViewInit } from '@angular/core';
import { NgControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';

enum NgControlStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  PENDING = 'PENDING',
  DISABLED = 'DISABLED'
}

export class InputTimeMenuOption {
  text: string;
  value: string;
}

@Component({
  selector: 'gbc-input-time-field',
  templateUrl: './input-time-field.component.html',
  styleUrls: ['./input-time-field.component.scss']
})
export class InputTimeFieldComponent implements OnInit, OnDestroy, ControlValueAccessor, AfterViewInit {
  // Attributes
  private innerValue: string | number;
  private touched: boolean = false;
  private subscription = new Subscription();

  public timeMenuOptions: InputTimeMenuOption[];
  public showMenu: boolean = false;
  public menuWidth: string;
  public error: string;
  public valid: boolean;

  // Bindings
  @Input() disabled: boolean;
  @Input() max: number;
  @Input() min: number;
  @Input() maxLength: number;
  @Input() inputType: string;
  @Input() public placeholder: string;
  @Input() step: number = 1;
  @Input() formControlName: string;
  @Input() public mask: string;
  @Input() errorDescriptionTranslationString: string;
  @Input() errorDescriptionData: { [k: string]: string } = {};
  @Output() fieldKeyupEvent = new EventEmitter<string>();

  // Callbacks
  onChange = (value: any) => {};
  onTouched = () => {};

  // View childs
  @ViewChild('wrapper') wrapperRef: ElementRef;

  //-- Constructor ---------------------------------------------------------------------

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  //-- Properties ----------------------------------------------------------------------

  get value(): string | number {
    return this.innerValue;
  }

  set value(value: string | number) {
    if (value === this.innerValue) return;

    this.innerValue = value;
    this.onChange(value);
  }

  //-- Lifecycle -----------------------------------------------------------------------

  ngAfterViewInit() {
    this.menuWidth = this.wrapperRef.nativeElement.getBoundingClientRect().width + "px";
  }

  ngOnInit() {
    this.timeMenuOptions = this.createTimeMenuOptions();

    const setValid = (status: NgControlStatus) => {
      this.valid = status === NgControlStatus.VALID || status === NgControlStatus.DISABLED;
    };

    this.subscription.add(this.ngControl.statusChanges?.subscribe(setValid));

    // Calculate the state on start, as there is no event
    setValid(this.ngControl.status as NgControlStatus);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  //-- Interface: ControlValueAccessor -------------------------------------------------

  writeValue(value: any): void {
    if (value === this.value) return;
    this.value = this.inputType === 'number' ? parseFloat(value) : value;
  }

  registerOnChange(callback: any): void {
    this.onChange = callback;
  }

  registerOnTouched(callback: any): void {
    this.onTouched = callback;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  //-- Methods -------------------------------------------------------------------------

  toggleMenu(enable?: boolean) {
    this.showMenu = (enable === undefined) ? !this.showMenu : enable;
  }

  onKey(value: string) {
    this.fieldKeyupEvent.emit(value);
    this.toggleMenu(false);
  }

  onBlur() {
    this.markAsTouched();
    this.toggleMenu(false);
  }

  useMenuOption(value: string) {
    this.value = value;
    this.toggleMenu(false);
  }

  //-- Helpers -------------------------------------------------------------------------

  private markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  private createTimeMenuOptions(): InputTimeMenuOption[] {
    const timeMenuOptions = [];

    for (let index = 0; index < 24; index++)
    {
      const hours = index.toString().padStart(2, '0');
      timeMenuOptions.push({ text: hours + ":00", value: hours + "00" });
      timeMenuOptions.push({ text: hours + ":30", value: hours + "30" });
    }

    return timeMenuOptions;
  }
}
