export enum FeatureFlag {

}

export interface IConfiguration {
  authority: string;
  client_id: string;
  clientScope: string;
  featureFlags: Record<FeatureFlag, boolean>;
  userTrackingScriptSrc?: string;
  userTrackingReportSuiteId?: string;
  userTrackingDryRun?: boolean;
  userTrackingLogTrackingEvents?: boolean;
  userTrackingIncludeInternalUsers?: boolean;
  languages: string[],
  appConfigurationConnectionString: string
}
