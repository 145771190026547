import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PageTrackingRedundancyFilterService {

  private previousPath: string;

  saveCurrentPath(path: string) {
    this.previousPath = path;
  }

  isPathRedundant(path: string) {
    return this.isSameFacilitiesMapPage(path) || this.isSameFacilitiesListPage(path);
  }

  private isSameFacilitiesMapPage(path: string) {
    const pathPrefix = '/facilities;tab=map';
    // Rule for facilities map page: prevent page tracking when just the map coords/zoom have changed
    return path?.includes(pathPrefix) && this.previousPath?.includes(pathPrefix);
  }

  private isSameFacilitiesListPage(path: string) {
    const pathPrefix = '/facilities;tab=list';
    // Rule for facilities list page: prevent page tracking if map coords are added
    return path?.includes(pathPrefix) && this.previousPath?.includes(pathPrefix);
  }
}
