import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MODAL_CONTROLLER, MODAL_DATA, ModalController } from 'shared';
import { Device, DeviceDataPoint } from '../../interfaces/data-points';
import { InstallationConfigurationService } from '../../services/installation-configuration.service';

export interface SetpointModalInput {
  applicationId: string;
  installationId: string;
  temperatureSetPoint: DeviceDataPoint | null;
  max: number;
  min: number;
}

export interface SetpointModalResponse {
  temperatureSetPoint: number;
}

@Component({
  selector: 'app-setpoint-modal',
  templateUrl: './setpoint-modal.component.html',
  styleUrls: ['./setpoint-modal.component.scss'],
})
export class SetpointModalComponent implements OnInit {
  public min: number;
  public max: number;
  public form: UntypedFormGroup;
  public setpoint: number;
  public isValid$: Observable<boolean>;

  constructor(
    @Inject(MODAL_DATA) public data: SetpointModalInput,
    @Inject(MODAL_CONTROLLER) private controller: ModalController<void>,
    private installationConfigurationService: InstallationConfigurationService
  ) {}

  ngOnInit(): void {
    this.min = this.data.min;
    this.max = this.data.max;
    this.setpoint = parseFloat(Number(this.data.temperatureSetPoint?.value).toFixed(1));

    this.form = new UntypedFormGroup({
      temperatureSetpoint: new UntypedFormControl(this.setpoint, [Validators.min(this.min), Validators.max(this.max)]),
    });

    this.isValid$ = this.form.valueChanges.pipe(
      map((value) => {
        return this.form.valid;
      })
    );
  }

  close() {
    this.controller.dismiss();
  }

  save() {
    this.installationConfigurationService.updateSetPoint(
      this.data.installationId,
      this.data.applicationId,
      this.form.controls.temperatureSetpoint.value
    );
    this.controller.complete();
  }
}
