import React from 'react';
import { v4 } from 'uuid';

export interface StrainerProps {
  fill: string;
  x: number;
  y: number;
}

export const Strainer: React.FC<StrainerProps> = ({ fill, x, y }) => {
  // Patterns are global, so their ids need to be unique, therefore we generate new ids every render
  const firstPatternId = v4();
  const secondPatternId = v4();
  return (
    <g transform={`translate(${x},${y})`}>
      <defs>
        <pattern id={firstPatternId} patternTransform="matrix(1.8704 -1.8713 1.8713 1.8704 -26.458 -106.59)" xlinkHref={`#${secondPatternId}`}/>
        <pattern id={secondPatternId} width="2" height="1" patternTransform="translate(0) scale(10)" patternUnits="userSpaceOnUse">
          <rect y="-.5" width="1" height="2" fill={fill}/>
        </pattern>
      </defs>
      <rect width="60" height="30" ry="0" fill={`url(#${firstPatternId})`}/>
    </g>
  );
};
