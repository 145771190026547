<gbc-tile>
  <div (click)='onToggle()' class='collapsible-title'>
    <ng-content select='[gbc-collapsible-title]'></ng-content>
    <img class='arrow ml-1' src='./assets/svgs/chevron_forward.svg' />
  </div>
  <div *ngIf='toggled'>
    <div class='content-inner'>
      <ng-content></ng-content>
    </div>
  </div>
</gbc-tile>
