import React from 'react';
import { EConnectionType } from '../state';
import {getPipeConnectionType, Pipe} from './Pipe';
import { ConnectionPointViewModel } from './ConnectionPoint';

export interface ConnectionBeingCreated {
  from: ConnectionPointViewModel;
  toX: number;
  toY: number;
  type: EConnectionType;
  dotted: boolean;
}

const style = { pointerEvents: 'none' };
export const ConnectionBeingCreated: React.FC<ConnectionBeingCreated> = ({ from, toX, toY, type, dotted }) => {
  return <Pipe style={style} x1={from.x} y1={from.y} x2={toX} y2={toY} type={getPipeConnectionType(type, dotted)} noDash={!dotted} />;
};
