import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from 'projects/serviceportal/src/app/services/company.service';
import { EMPTY, Observable, of } from 'rxjs';
import { first, flatMap, map } from 'rxjs/operators';
import { PageInfo } from 'shared';
import { Facility, Installation } from '../../interfaces/facilty';
import { IBuildingConnectUser, IUser, UserType } from '../../interfaces/user';
import { UpgradeLicenseService } from '../../services/upgrade-license.service';
import { PageInfoService } from '../../services/page-info.service';
import { UserService } from '../../services/user.service';
import { FacilityService } from '../../services/facility.service';
import { LicenseType } from '../../interfaces/mixit';
import { formValues } from '../../../../../shared/src/lib/constants/form-values';

@Component({
  selector: 'app-acquire-license-page',
  templateUrl: './acquire-license-page.component.html',
  styleUrls: ['./acquire-license-page.component.scss'],
})
export class AcquireLicensePageComponent implements OnInit {
  public currentUser$: Observable<IUser | null>;
  public pageInfo: PageInfo;
  public currentInstallation$: Observable<Installation>;
  public form = this.formBuilder.group({
    companyName: ['',  [Validators.required,  Validators.maxLength(formValues.max_length.name)]],
    street: ['',  [Validators.required,  Validators.maxLength(formValues.max_length.address)]],
    postalCode: ['',  [Validators.required,  Validators.maxLength(formValues.max_length.postalCode)]],
    city: ['',  [Validators.required,  Validators.maxLength(formValues.max_length.address)]],
    country: ['',  [Validators.required,  Validators.maxLength(formValues.max_length.address)]],
    region: ['',  [Validators.required,  Validators.maxLength(formValues.max_length.address)]],
    state: ['',  [Validators.required,  Validators.maxLength(formValues.max_length.address)]],
    vatNumber: ['',  [Validators.required,  Validators.maxLength(formValues.max_length.default)]],
    accountManagerName: ['',  [Validators.required,  Validators.maxLength(formValues.max_length.name)]],
    accountManagerEmail: ['',  [Validators.required,  Validators.maxLength(formValues.max_length.email)]],
    invoiceEmail: ['',  [Validators.required,  Validators.maxLength(formValues.max_length.email)]],
    purchaseReference: ['',  [Validators.required,  Validators.maxLength(formValues.max_length.default)]],
    licenseType: ['',  [Validators.required,  Validators.maxLength(formValues.max_length.default)]],
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private pageInfoService: PageInfoService,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private upgradeService: UpgradeLicenseService,
    private router: Router,
    private facilityService: FacilityService
  ) {
    this.currentUser$ = this.userService.currentUser$.pipe(
      flatMap((user) => {
        // OIDC users do not have a account page, so we filter them
        if (user?.type === UserType.OIDC) {
          return EMPTY;
        }

        const buildingConnectUser = user as IBuildingConnectUser;

        // TODO: Should be facility Company
        this.companyService
          .getCompany(buildingConnectUser.companyId as number)
          .pipe(
            map((company) => {
              this.form.patchValue({
                companyName: company?.name,
              });
              return company;
            })
          )
          .subscribe();

        this.form.patchValue({
          accountManagerName: buildingConnectUser?.name,
          accountManagerEmail: buildingConnectUser?.email,
        });
        return of(user);
      })
    );

    this.currentInstallation$ = this.facilityService.getCurrentInstallation(this.route);

    this.facilityService
      .getCurrentFacility(this.route)
      .pipe(
        first(),
        map((facility) => {
          this.form.patchValue({
            address: facility.addressRoad,
            postalCode: facility.addressPostal,
            city: facility.addressCity,
          });
          return facility;
        })
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.pageInfo = this.pageInfoService.profile();
  }

  onSubmit(installation: Installation) {
    this.upgradeService
      .upgradeLicense(installation.id, {
        licenseType: LicenseType.CodeConnectTrial,
        licensePurchaseInformation: this.form.value,
      })
      .subscribe(() => {
        this.router.navigate(['/account']);
      });
  }
}
