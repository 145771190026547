import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IIncomingLicenseRequestPayload, IUpgradeRequestResult, ILicenseOption } from '../interfaces/premium';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UpgradeLicenseService {
  constructor(private httpClient: HttpClient) {}

  public upgradeLicense(installationId: string, licenseRequest: IIncomingLicenseRequestPayload): Observable<IUpgradeRequestResult> {
    return this.httpClient.post<IUpgradeRequestResult>(`${environment.serverUrl}/api/license/${installationId}`, licenseRequest);
  }

  public getLicenseInformation(): Observable<ILicenseOption[]> {
    return this.httpClient.get<ILicenseOption[]>(`${environment.serverUrl}/api/license/options`);
  }
}
