import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IMenuOption } from '../../interfaces/menuOption';

enum PopupPositionX {
  NotSet = 'notset',
  Left = 'left',
  Right = 'right'
}

enum PopupPositionY {
  NotSet = 'notset',
  Below = 'below',
  Above = 'above'
}

@Component({
  selector: 'gbc-popup-menu',
  templateUrl: './popup-menu.component.html',
  styleUrls: ['./popup-menu.component.scss']
})
export class PopupMenuComponent implements OnInit {
  // Attribute
  public showMenu: boolean = false;
  public makeInvisible: boolean = false;
  public menuOpen = false;
  private _positionX: PopupPositionX;
  private _positionY: PopupPositionY;

  // Observables
  public menuOptions$: Observable<IMenuOption[]>;

  // Binding attributes
  @Input() public getContentAs: string = "gbc-popup-menu-items";
  @Input() public options: IMenuOption[];
  @Input() public model: any;

  // View childs
  @ViewChild('popupMenu') popupMenu: ElementRef;
  @ViewChild('popupMenuItems') popupMenuItems: ElementRef;

  //-- Constructor ---------------------------------------------------------------------

  constructor () {}

  //-- Life Cycle ----------------------------------------------------------------------

  ngOnInit(): void {
    this.menuOptions$ = of(this.options);
  }

  //-- Properties ----------------------------------------------------------------------

  get positionX(): PopupPositionX {
    return this._positionX;
  }

  get positionY(): PopupPositionY {
    return this._positionY;
  }

  //-- Methods -------------------------------------------------------------------------

  optionClicked(option: IMenuOption) {

    this.toggleMenu(false);

    if (option.onClick && option.disabled !== true) {
      option.onClick(this.model);
    }
  }

  //-- Methods -------------------------------------------------------------------------

  toggleMenu = (enable?: boolean, event?: any) => {

    if (event) event.stopPropagation();

    this.makeInvisible = true;

    this.showMenu = (enable === undefined) ? !this.showMenu : enable;

    if (this.showMenu)
    {
      window.setTimeout(this.recalcMenuItemsPosition, 1);
    }
  }

  //-- Helpers -------------------------------------------------------------------------

  private recalcMenuItemsPosition = () => {
    if (this.popupMenu === undefined || this.popupMenuItems === undefined) return;

    const menu = this.popupMenu.nativeElement;
    const menuItems = this.popupMenuItems.nativeElement;
    const menuWidth = menuItems?.scrollWidth;
    const menuHeight = menuItems?.scrollHeight + menu.clientHeight;
    [this._positionX, this._positionY] = this.getPosition(menu.offsetLeft, menu.offsetTop, menuWidth, menuHeight);

    this.makeInvisible = false;
  }

  private getPosition(x: number, y: number, menuWidth: number, menuHeight: number): [PopupPositionX, PopupPositionY] {
    const margin = 20;

    let dirX = PopupPositionX.Right;
    let dirY = PopupPositionY.Below;

    if (x + menuWidth >= window.innerWidth + window.scrollX - margin) {
      dirX = PopupPositionX.Left;
    }
    if (y + menuHeight >= window.innerHeight + window.scrollY - margin) {
      dirY = PopupPositionY.Above;
    }
    return [dirX, dirY];
  }
}
