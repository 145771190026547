import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PumpControlMode, OperatingMode } from 'projects/customerportal/src/app/interfaces/alarm';
import { PumpOption } from 'projects/customerportal/src/app/services/installation-configuration.service';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { MODAL_CONTROLLER, MODAL_DATA, ModalController } from 'shared';
import { EBackendUnit } from '../../interfaces/data-points';
import { UnitType } from '../../interfaces/mixit';
import { UserTrackingHelperService } from '../../services/user-tracking-helper.service';
import { SystemControlMode } from '../../interfaces/system-control-mode';

export interface PumpSettings {
  mixit: boolean;
  pumpOptions: PumpOption[];
  title: string;
  controlMode: string | null | undefined;
  setpoint: string | undefined | null;
  flowSetpoint: string | undefined | null; // only used for Proportional Pressure
  stopped: boolean;
  operationMode: OperatingMode;
  unitType: EBackendUnit | null;
  systemControlMode: SystemControlMode;
  systemId: string;
}

export const defaultPumpSettings: PumpSettings = {
  mixit: false,
  pumpOptions: [],
  title: '',
  controlMode: PumpControlMode.AutoAdapt,
  setpoint: null,
  flowSetpoint: null,
  stopped: false,
  operationMode: OperatingMode.Normal,
  unitType: null,
  systemControlMode: SystemControlMode.Unknown,
  systemId: '',
};

//
// This modal is shared across BuildingConnect and Mixit
//
@Component({
  selector: 'app-pump-modal',
  templateUrl: './pump-modal.component.html',
  styleUrls: ['./pump-modal.component.scss'],
})
export class PumpModalComponent implements OnInit, OnDestroy {
  public mixit = false;
  public controlModes: { name: string; value: string }[] = [];
  public title: string;
  public showMixitSpecificText: boolean;
  public form: UntypedFormGroup;
  public isAutoAdapt$: Observable<boolean>;
  public isProportionalPressure$: Observable<boolean>;
  public isConstantPressure$: Observable<boolean>;
  public isConstantFrequency$: Observable<boolean>;
  public isConstantFlow$: Observable<boolean>;
  public isConstantTemperature$: Observable<boolean>;
  public proportionalPressureHeadMinMax: { min: string; max: string };
  public proportionalPressureFlowMinMax: { min: string; max: string };
  public constantPressureHeadMinMax: { min: string; max: string };
  public constantFrequencySpeedMinMax: { min: string; max: string };
  public constantFlowFlowMinMax: { min: string; max: string };
  public constantTemperatureMinMax: { min: string; max: string };
  public isUnsupportedControlMode: boolean;
  public isPumpStopped: boolean;
  public isValid$: Observable<boolean>;
  private subscription = new Subscription();
  public SystemControlMode = SystemControlMode;
  public currentSystemControlMode: SystemControlMode;
  public selectedSystemControlMode: SystemControlMode;
  pumpStateOptions: any[];
  currentPumpOperatingMode: OperatingMode;
  selectedPumpOperatingMode: OperatingMode;
  @Output() public selectPumpState = new EventEmitter<OperatingMode>();

  constructor(
    @Inject(MODAL_DATA) public data: PumpSettings,
    @Inject(MODAL_CONTROLLER) private controller: ModalController<PumpSettings>,
    private translate: TranslateService,
    private userTrackingHelperService: UserTrackingHelperService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.currentSystemControlMode = this.data.systemControlMode;
    this.selectedSystemControlMode = this.currentSystemControlMode;
    this.currentPumpOperatingMode = this.data.stopped ? OperatingMode.Stop : OperatingMode.Normal;
    this.selectedPumpOperatingMode = this.currentPumpOperatingMode;

    this.pumpStateOptions = [
      { name: this.translateService.instant('pump-modal.start'), value: OperatingMode.Normal },
      { name: this.translateService.instant('pump-modal.stop'), value: OperatingMode.Stop },
    ];

    this.mixit = this.data.mixit;

    const defaultDisabledState = !this.mixit && this.selectedSystemControlMode === SystemControlMode.Unknown;

    this.form = new FormGroup({
      controlMode: new FormControl({ value: '', disabled: defaultDisabledState }, [Validators.required]),
      constantPressureForm: new FormGroup({
        head: new FormControl({ value: undefined, disabled: defaultDisabledState }) }),
      constantFrequencyForm: new FormGroup({
        speed: new FormControl({ value: undefined, disabled: defaultDisabledState }) }),
      constantFlowForm: new FormGroup({
        flow: new FormControl({ value: undefined, disabled: !this.mixit && defaultDisabledState }) }),
      proportionalPressureForm: new FormGroup({
        head: new FormControl({ value: undefined, disabled: defaultDisabledState }),
        flow: new FormControl({ value: '', disabled: defaultDisabledState }) }),
      constantTemperatureForm: new FormGroup({
        temperature: new FormControl({ value: undefined, disabled: defaultDisabledState }) }),
      pumpMode: new FormControl({ value: this.currentPumpOperatingMode, disabled: this.selectedSystemControlMode === SystemControlMode.Auto || this.selectedSystemControlMode === SystemControlMode.Unknown }, [Validators.required]),
    });

    // Autoadapt: No extra parameters.
    // Constant flow: Flow needs to be set.
    // Constant speed: Speed is set as a percentage.
    // Constant pressure: Pressure is set as Head.
    // Proportional pressure: Head and flow is set.

    const allControlModes = [
      { name: this.translate.instant('mixit-control-mode.AutoAdapt'), value: PumpControlMode.AutoAdapt },
      { name: this.translate.instant('mixit-control-mode.ConstantPressure'), value: PumpControlMode.ConstantPressure },
      { name: this.translate.instant('mixit-control-mode.ConstantFlow'), value: PumpControlMode.ConstantFlow },
      { name: this.translate.instant('mixit-control-mode.ConstantSpeed'), value: PumpControlMode.ConstantFrequency },
      { name: this.translate.instant('mixit-control-mode.ProportionalPressure'), value: PumpControlMode.ProportionalPressure },
      { name: this.translate.instant('mixit-control-mode.ConstantTemperature'), value: PumpControlMode.ConstantTemperature },
    ];

    // We need to filter the modes, to what the pump tells us it supports
    this.controlModes = allControlModes.filter((m) => {
      return this.data.pumpOptions.find((p) => p.controlMode === m.value);
    });

    this.isPumpStopped = this.data.stopped;

    this.isAutoAdapt$ = this.form.controls.controlMode.valueChanges.pipe(
      map((result) => {
        return result?.value === PumpControlMode.AutoAdapt;
      })
    );

    this.isProportionalPressure$ = this.form.controls.controlMode.valueChanges.pipe(
      map((result) => {
        return result?.value === PumpControlMode.ProportionalPressure;
      })
    );

    this.isConstantPressure$ = this.form.controls.controlMode.valueChanges.pipe(
      map((result) => {
        return result?.value === PumpControlMode.ConstantPressure;
      })
    );

    this.isConstantFrequency$ = this.form.controls.controlMode.valueChanges.pipe(
      map((result) => {
        return result?.value === PumpControlMode.ConstantFrequency;
      })
    );

    this.isConstantFlow$ = this.form.controls.controlMode.valueChanges.pipe(
      map((result) => {
        return result?.value === PumpControlMode.ConstantFlow;
      })
    );

    this.isConstantTemperature$ = this.form.controls.controlMode.valueChanges.pipe(
      map((result) => {
        return result?.value === PumpControlMode.ConstantTemperature;
      })
    );

    // Specify pump option range configuration.
    this.proportionalPressureFlowMinMax = this.getPumpOptionMinMax(this.data.pumpOptions, PumpControlMode.ProportionalPressure, UnitType.CubicMeterPerHour);
    this.proportionalPressureHeadMinMax = this.getPumpOptionMinMax(this.data.pumpOptions, PumpControlMode.ProportionalPressure, UnitType.MeterHead);
    this.constantPressureHeadMinMax = this.getPumpOptionMinMax(this.data.pumpOptions, PumpControlMode.ConstantPressure);
    this.constantFrequencySpeedMinMax = this.getPumpOptionMinMax(this.data.pumpOptions, PumpControlMode.ConstantFrequency);
    this.constantFlowFlowMinMax = this.getPumpOptionMinMax(this.data.pumpOptions, PumpControlMode.ConstantFlow);
    this.constantTemperatureMinMax = this.getPumpOptionMinMax(this.data.pumpOptions, PumpControlMode.ConstantTemperature);

    this.setValidators();

    // We need to wait a bit, in order for the valueChanges observables to detect the change
    setTimeout(() => {
      this.setControlModeAndSetPoint();
    }, 5);

    this.title = this.data.title;

    this.isUnsupportedControlMode = !this.controlModes.find((c) => c.value === this.data.controlMode);

    this.isValid$ = this.form.valueChanges.pipe(
      map((value) => {
        const controlMode = value.controlMode?.value as PumpControlMode;
        switch (controlMode) {
          case PumpControlMode.ConstantPressure:
            return this.form.controls.constantPressureForm.valid;
          case PumpControlMode.ConstantFrequency:
            return this.form.controls.constantFrequencyForm.valid;
          case PumpControlMode.ConstantFlow:
            return this.form.controls.constantFlowForm.valid;
          case PumpControlMode.ProportionalPressure:
            return this.form.controls.proportionalPressureForm.valid;
          case PumpControlMode.ConstantTemperature:
            return this.form.controls.constantTemperatureForm.valid;
          case PumpControlMode.AutoAdapt:
            return true;
          default:
            return false;
        }
      })
    );

    this.updateDisabledState(this.selectedSystemControlMode);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setControlModeAndSetPoint() {
    const controlMode = this.controlModes.find((c) => c.value === this.data.controlMode) || '';
    this.form.controls.controlMode.setValue(controlMode);

    // No need to set setpoint, if we don't support the mode
    if (!controlMode) {
      return;
    }

    const value = parseFloat(this.data.setpoint as string);

    if (Number.isNaN(value)) {
      return;
    }

    switch (controlMode.value) {
      case PumpControlMode.ConstantPressure:
        (this.form.controls.constantPressureForm as UntypedFormGroup).controls.head.setValue(value);
        break;
      case PumpControlMode.ConstantFrequency:
        (this.form.controls.constantFrequencyForm as UntypedFormGroup).controls.speed.setValue(value);
        break;
      case PumpControlMode.ConstantFlow:
        (this.form.controls.constantFlowForm as UntypedFormGroup).controls.flow.setValue(value);
        break;
      case PumpControlMode.ProportionalPressure:
        (this.form.controls.proportionalPressureForm as UntypedFormGroup).controls.head.setValue(value);
        (this.form.controls.proportionalPressureForm as UntypedFormGroup).controls.flow.setValue(
          parseFloat(this.data.flowSetpoint as string)
        );
        break;
      case PumpControlMode.ConstantTemperature:
        (this.form.controls.constantTemperatureForm as UntypedFormGroup).controls.temperature.setValue(value);
        break;
      case PumpControlMode.AutoAdapt:
      default:
    }

    this.subscription.add(this.userTrackingHelperService.startFormValueChangeTracking('adjustPumpStatusShown', this.form));
  }

  setValidators() {
    for (const option of this.data.pumpOptions) {
      switch (option.controlMode) {
        case PumpControlMode.ConstantPressure:
          (this.form.controls.constantPressureForm as UntypedFormGroup).controls.head.setValidators([
            Validators.required,
            Validators.min(option.min),
            Validators.max(option.max),
          ]);
          break;
        case PumpControlMode.ConstantFrequency:
          (this.form.controls.constantFrequencyForm as UntypedFormGroup).controls.speed.setValidators([
            Validators.required,
            Validators.min(option.min),
            Validators.max(option.max),
          ]);
          break;
        case PumpControlMode.ConstantFlow:
          (this.form.controls.constantFlowForm as UntypedFormGroup).controls.flow.setValidators([
            Validators.required,
            Validators.min(option.min),
            Validators.max(option.max),
          ]);
          break;
        case PumpControlMode.ProportionalPressure:
          if (option.unitType === UnitType.MeterHead) {
            (this.form.controls.proportionalPressureForm as UntypedFormGroup).controls.head.setValidators([
              Validators.required,
              Validators.min(option.min),
              Validators.max(option.max),
            ]);
          }
          if (option.unitType === UnitType.CubicMeterPerHour) {
            (this.form.controls.proportionalPressureForm as UntypedFormGroup).controls.flow.setValidators([
              Validators.required,
              Validators.min(option.min),
              Validators.max(option.max),
            ]);
          }
          break;
        case PumpControlMode.ConstantTemperature:
          (this.form.controls.constantTemperatureForm as UntypedFormGroup).controls.temperature.setValidators([
            Validators.required,
            Validators.min(option.min),
            Validators.max(option.max),
          ]);
          break;
        case PumpControlMode.AutoAdapt:
          // Nothing to set here
          break;
      }
    }
  }

  updateDisabledState(event: SystemControlMode) {
    // Guards - Ignore for MIXIT.
    if (this.mixit) { return };

    switch (event) {
      case SystemControlMode.Auto:
        this.enableForm();
        this.form.controls.pumpMode.disable();
        break;
      case SystemControlMode.Manual:
      case SystemControlMode.NotSupported:
        this.enableForm();
        break;
      default:
        this.disableForm();
        break;
    }
  }

  private enableForm() {
    this.form.controls.controlMode.enable();
    this.form.controls.constantPressureForm.enable();
    this.form.controls.constantFrequencyForm.enable();
    this.form.controls.constantFlowForm.enable();
    this.form.controls.proportionalPressureForm.enable();
    this.form.controls.constantTemperatureForm.enable();
    this.form.controls.pumpMode.enable();
  }

  private disableForm() {
    this.form.controls.controlMode.disable();
    this.form.controls.constantPressureForm.disable();
    this.form.controls.constantFrequencyForm.disable();
    this.form.controls.constantFlowForm.disable();
    this.form.controls.proportionalPressureForm.disable();
    this.form.controls.constantTemperatureForm.disable();
    this.form.controls.pumpMode.disable();
  }

  close() {
    this.controller.dismiss();
  }

  save() {
    let setpoint;
    let unitType;
    let flowSetpoint;
    const controlMode = this.form.value.controlMode.value;
    const systemControlMode = this.selectedSystemControlMode;
    const pumpControlMode = this.form.controls.pumpMode.value;

    switch (controlMode) {
      case PumpControlMode.ConstantPressure:
        unitType = EBackendUnit.MeterHead;
        setpoint = (this.form.controls.constantPressureForm as UntypedFormGroup).controls.head.value;
        break;
      case PumpControlMode.ConstantFrequency:
        unitType = EBackendUnit.Percentage;
        setpoint = (this.form.controls.constantFrequencyForm as UntypedFormGroup).controls.speed.value;
        break;
      case PumpControlMode.ConstantFlow:
        unitType = EBackendUnit.CubicMeterPerHour;
        setpoint = (this.form.controls.constantFlowForm as UntypedFormGroup).controls.flow.value;
        break;
      case PumpControlMode.ProportionalPressure:
        unitType = EBackendUnit.MeterHead;
        setpoint = (this.form.controls.proportionalPressureForm as UntypedFormGroup).controls.head.value;
        flowSetpoint = (this.form.controls.proportionalPressureForm as UntypedFormGroup).controls.flow.value;
        break;
      case PumpControlMode.ConstantTemperature:
        unitType = EBackendUnit.DegreeCelsius;
        setpoint = (this.form.controls.constantTemperatureForm as UntypedFormGroup).controls.temperature.value;
        break;
      case PumpControlMode.AutoAdapt:
        unitType = EBackendUnit.None;
        setpoint = '0';
        break;
    }
    this.controller.complete({
      ...defaultPumpSettings,
      systemControlMode,
      flowSetpoint,
      controlMode,
      setpoint,
      operationMode: pumpControlMode,
      unitType: unitType || null,
    });
  }

  dismiss() {
    this.userTrackingHelperService.trackUserAction('adjustPumpStatusShown', 'exitClicked');
  }

  isAutoModeEnabled(): boolean {
    return this.selectedSystemControlMode === SystemControlMode.Auto;
  }

  selectApplicationControlMode(selectedApplicationControlMode: SystemControlMode) {
    this.selectedSystemControlMode = selectedApplicationControlMode;
    this.updateDisabledState(selectedApplicationControlMode);
  }

  getPumpOptionMinMax(pumpOptions: PumpOption[], controlMode: PumpControlMode, unitType?: UnitType): { min: string, max: string } {
    var pumpOption = undefined;

    if (unitType) {
      pumpOption = pumpOptions.find((p) => p.controlMode === controlMode && p.unitType === unitType);
    } else {
      pumpOption = pumpOptions.find((p) => p.controlMode === controlMode);
    }

    return {
      min: pumpOption?.min.toString() || "",
      max: pumpOption?.max.toString() || "",
    }
  }
}
