import React from 'react';
import { Labels } from '../../Labels';
import { SvgImage } from '../../SvgImage';
import { System, BaseSystemProps } from '../../System';
import * as metaData from './outdoor-temperature-metadata';
import thermometerSvg from '!raw-loader!../../../../../assets/equipment/thermometer.svg';
import { IOutdoorTemperatureSystemFormInterface } from '../../../../models/systems/outdoorTemperatureSystem/outdoorTemperatureSystemFormInterface';

export const OutdoorTemperatureSystem: React.FC<BaseSystemProps<IOutdoorTemperatureSystemFormInterface>> = React.memo(
  ({ state, values, alarm, selected }) => {
    return (
      <System state={state} meta={metaData.outdoorTemperatureMeta} alarm={alarm} selected={selected}>
        <SvgImage svgText={thermometerSvg} x={metaData.ICON_X} y={metaData.ICON_Y} />
        <Labels positions={metaData.getOutdoorTemperatureLabels()} values={values} />
      </System>
    );
  }
);
