<form [formGroup]="selectedInstallations">
  <gbc-table>
    <th gbc-table-header>{{ 'trial-flow-page.location' | translate }}</th>
    <th gbc-table-header>{{ 'trial-flow-page.mixit-unit' | translate }}</th>
    <th gbc-table-header>{{ 'trial-flow-page.serial-number' | translate }}</th>
    <th gbc-table-header>{{ 'trial-flow-page.installation-date' | translate }}</th>
    <th gbc-table-header></th>
    <ng-container *ngFor="let facility of facilities$ | async">
      <tr
        gbc-table-row
        *ngFor="let installation of facility.installations"
        [ngClass]="{ disabled: installation.connectionState !== ConnectionState.Connected }">
        <td gbc-table-cell>
          {{ facility.name }}
        </td>
        <td gbc-table-cell>
          <div class="installation-name">
            <app-mixit-connection-status [installation]="installation">
              <span>{{ installation.name }}</span>
            </app-mixit-connection-status>
          </div>
        </td>
        <td gbc-table-cell>
          {{ installation.serialNumber }}
        </td>
        <td gbc-table-cell>
          {{ installation.createdTimestampEpoch | date: 'dd/MM/yyyy' }}
        </td>
        <td gbc-table-cell>
          <p-checkbox
            [formControl]="$any(selectedInstallations).controls[installation.id]"
            [name]="installation.id"
            class="checkbox-{{ installation.id }}"
            [binary]="true"
          ></p-checkbox>
        </td>
      </tr>
    </ng-container>
  </gbc-table>
</form>
