<gbc-tile [title]="pumpType$ | async">
  <img gbc-tile-left
    (click)="showPumpInfo()"
    class="info-img"
    src="./assets/svgs/info-circle_outline.svg"
    angulartics2On="click"
    angularticsAction="mixitProductInfoIconClicked"
    angularticsCategory="mixitSchematicClicked"
    data-test-id="pump-tile-info-icon">

  <button gbc-icon-button gbc-tile-right
    (click)="adjustPump()"
    angulartics2On="click"
    angularticsAction="adjustPumpStatusShown"
    angularticsCategory="mixitSchematicClicked">
    <img [alt]="'pump-tile.adjust-pump' | translate" src="./assets/svgs/adjust-outline-black.svg">
  </button>

  <div class="tile-content">

    <div class="tile-row">
      <div class="tile-row-name">{{'pump-tile.control-mode' | translate}}:</div>
      <div class="tile-row-value">{{formattedControlMode$ | async }}</div>
    </div>

    <ng-container *ngIf="controlMode$ | async as ctrlMode">

      <div class="tile-row" *ngIf="ctrlMode.value === PumpControlModes.ConstantPressure">
        <div class="tile-row-name">{{'pump-tile.setpoint-head' | translate}}:</div>
        <div class="tile-row-value">{{setpointHead$ | async | formatDataPoint}}</div>
      </div>

      <div class="tile-row" *ngIf="ctrlMode.value === PumpControlModes.ConstantFlow">
        <div class="tile-row-name">{{'pump-tile.setpoint-flow' | translate}}:</div>
        <div class="tile-row-value">{{setpointFlow$ | async | formatDataPoint}}</div>
      </div>

      <div class="tile-row" *ngIf="ctrlMode.value === PumpControlModes.ConstantFrequency">
        <div class="tile-row-name">{{'pump-tile.setpoint-speed' | translate}}:</div>
        <div class="tile-row-value">{{setpointSpeed$ | async | formatDataPoint}}</div>
      </div>

      <ng-container *ngIf="ctrlMode.value === PumpControlModes.ProportionalPressure">
        <div class="tile-row">
          <div class="tile-row-name">{{'pump-tile.setpoint-duty-head' | translate}}:</div>
          <div class="tile-row-value">{{pumpHeadDutyPoint$ | async | formatDataPoint}}</div>
        </div>

        <div class="tile-row">
          <div class="tile-row-name">{{'pump-tile.setpoint-duty-flow' | translate}}:</div>
          <div class="tile-row-value">{{pumpFlowDutyPoint$ | async | formatDataPoint}}</div>
        </div>
      </ng-container>

    </ng-container>

    <!-- Disabled, as the datapoint is not there yet -->
    <div class="tile-row" *ngIf="false">
      <div class="tile-row-name">{{'pump-tile.energy-consumption' | translate}}:</div>
      <div class="tile-row-value">{{energy$ | async | formatDataPoint}}</div>
    </div>

  </div>
</gbc-tile>
