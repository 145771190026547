import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DataPointsResult, DeviceDataPoint } from '../../interfaces/data-points';
import { getDatapoint } from '../../utils/mixit-utils';
import { BoosterDataPoints } from '../../interfaces/booster';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SystemDeviceType } from '../../interfaces/systemDeviceType';

@Component({
  selector: 'app-booster-tile',
  templateUrl: './booster-tile.component.html',
  styleUrls: ['./booster-tile.component.scss'],
})
export class BoosterTileComponent implements OnInit {
  @Input() dataPoints$: Observable<DataPointsResult>;

  public operationMode$: Observable<DeviceDataPoint | null>;
  public controlMode$: Observable<any>;
  public setPointHead$: Observable<DeviceDataPoint | null>;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.operationMode$ = getDatapoint(this.dataPoints$, SystemDeviceType.BoosterSystem, BoosterDataPoints.SystemActMode3);
    this.controlMode$ = getDatapoint(this.dataPoints$, SystemDeviceType.BoosterSystem, BoosterDataPoints.SystemActMode1).pipe(
      map((mode) => {
        return mode && mode.value ? this.translateService.get(`mixit-control-mode.${mode.value}`) : of<string>('-');
      })
    );
    this.setPointHead$ = getDatapoint(this.dataPoints$, SystemDeviceType.BoosterSystem, BoosterDataPoints.SysRef);
  }
}
