<app-mixit-x509-banner/>
<app-alarm-bar *ngIf="alarm$ | async" [alarm]="alarm$"> </app-alarm-bar>

<app-schematics-tile
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [showShadow]="false"
  [dataPoints$]="dataPoints$"
>
</app-schematics-tile>

<app-pressure-system-chart [dataPoints$]="dataPoints$" [installationId]="(installation$ | async)?.id" [applicationId]="application.id">
</app-pressure-system-chart>
