import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AppError, AppErrorService, CommandService, ICodeToMessageKeysMap, ModalService } from 'shared';
import { Observable } from 'rxjs';
import { IFacility } from 'projects/serviceportal/src/app/interfaces/facility';
import { AppModel } from '@ams/dumbledore';
import { Facility, Installation } from 'projects/customerportal/src/app/interfaces/facilty';
import { MixitDataPointName, UnitType } from '../interfaces/mixit';
import { EBackendUnit } from '../interfaces/data-points';
import { ConnectionState } from '../interfaces/connectionState';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { IVerificationResult, ProgressState } from 'projects/shared/src/lib/components/progress-checker/progress-checker.component';
import { of } from 'rxjs';

export type ICreateFacilityForMixit = Pick<IFacility, 'name' | 'location' | 'addressCity' | 'addressRoad' | 'addressPostal'>;

export interface IClaimMixitDeviceNewFacility {
  facility: ICreateFacilityForMixit;
  installationName: string;
  claimCode: string;
}

export interface IClaimMixitDeviceExistingFacility {
  installationName: string;
  facilityId: string;
  claimCode: string;
}

export interface IProbeResponse {
  schematic: AppModel;

  installationName: string;
  latitude: string;
  longitude: string;

  type: string;
  application: string;
  commissioningTime: string;
  productSerialNumber: string;
  connectionState: ConnectionState;
}

export interface ICommand {
  term: MixitDataPointName;
  value: string | null;
  unit?: EBackendUnit;
  weatherCurve?: [number, number][];
}

export interface MixitPumpModeDto {
  controlMode: string;
  headUnit: UnitType.MeterHead;
  head: number;
  speedUnit: UnitType.Percentage;
  speed: number;
  flowUnit: UnitType.CubicMeterPerHour;
  flow: number;
}

interface ICommandResult {
  result: boolean;
  errorMessage: string | null;
}

@Injectable({
  providedIn: 'root'
})
export class MixitService {
  private readonly baseUserUrl = `${environment.serverUrl}/api/connect`;

  constructor(
    private http: HttpClient,
    private commandService: CommandService,
    private appErrorService: AppErrorService,
    private modalService: ModalService,
    private translate: TranslateService,
  ) { }

  private claimErrorCodes: ICodeToMessageKeysMap = {
    400: {
      // Something is wrong with the request, the frontend is likely at fault
      messageKey: 'mixit-service.error-with-request'
    },
    // We have experienced issues, where 404 is returned.
    // In this case, it's an issue with the backend, and we should tell the user that.
    404: {
      // There was an internal server error, we don't know what to do.
      messageKey: 'mixit-service.error-with-server'
    },

    500: {
      // There was an internal server error, we don't know what to do. Something on the backend is wrong.
      messageKey: 'mixit-service.error-with-server'
    },
    'facility-id-not-found': {
      messageKey: 'mixit-service.facility-id-not-found',
    },
    'claim-code-not-mixit': {
      messageKey: 'mixit-service.claim-code-not-mixit',
    },
    'claim-code-not-found': {
      messageKey: 'mixit-service.claim-code-not-found',
    },
    'commission-service-error-cdn': {
      messageKey: 'mixit-service.failed-to-retrieve-configuration',
    },
    'session-user-access-level-invalid': {
      messageKey: 'mixit-service.failed-to-claim-with-invalid-user-access-level'
    },
    'invalid-user': {
      messageKey: 'mixit-service.failed-to-claim-no-user-found'
    },
    'device-already-registered': {
      messageKey: 'mixit-service.failed-to-claim-code-reference-indeterminable'
    },
    'registered-device-not-found': {
      messageKey: 'mixit-service.failed-to-claim-code-reference-indeterminable'
    },
    'device-not-connected': {
      messageKey: 'mixit-service.device-not-connected'
    }
  };

  // Verify device user wants to claim is online
  public verifyDeviceOnline(claimCode: string): Observable<IVerificationResult> {
    return this.performCheck(this.http.post<boolean>(`${this.baseUserUrl}/claim/verify-device-online`, { claimCode }, { observe: 'response' }));
  }

  // Verify user is allowed to claim the device
  public verifySystemSupported(claimCode: string): Observable<IVerificationResult> {
    return this.performCheck(this.http.post<boolean>(`${this.baseUserUrl}/claim/verify-supported-system`, { claimCode }, { observe: 'response' }));
  }

  private performCheck(httpCommand: Observable<HttpResponse<boolean>>): Observable<IVerificationResult> {
    return httpCommand.pipe(
      this.appErrorService.catchApiError({ errorCodes: this.claimErrorCodes }),
      map(response => {
        const result2: IVerificationResult = { progressState: ProgressState.CompletedWithSuccess, result: response.body as boolean };
        return result2;
      }),
      shareReplay(),
      catchError(e => {
        return e instanceof AppError
          ? of(({ progressState: ProgressState.CompletedWithError, result: false, errorMessage: e.userMessage }) as IVerificationResult)
          : of(({ progressState: ProgressState.CompletedWithError, result: false, errorMessage: e.message }) as IVerificationResult)
      })
    );
  }

  // Send the code, get a schematic plus some info about the device back
  public probeDevice(claimCode: string): Observable<HttpResponse<IProbeResponse>> {
    return this.commandService.execute<HttpResponse<IProbeResponse>>(() => {
      return this.http.post<IProbeResponse>(`${this.baseUserUrl}/claim/probe`, { claimCode }, { observe: 'response' }).pipe(
        this.appErrorService.catchApiError<HttpResponse<IProbeResponse>>({
          errorCodes: this.claimErrorCodes,
          fallbackMessageKey: 'mixit-service.failed-to-find',
        })
      );
    }, { successMessageKey: 'mixit-service.successfully-found' });
  }

  // This returns a Facility
  public claimDeviceForNewFacility(body: IClaimMixitDeviceNewFacility): Observable<Facility> {
    return this.commandService.execute(() => {
      return this.http.post<Facility>(`${this.baseUserUrl}/claim`, body).pipe(
        this.appErrorService.catchApiError<Facility>({
          errorCodes: this.claimErrorCodes,
          fallbackMessageKey: 'mixit-service.failed-with-new-facility',
        })
      );
    }, { successMessageKey: 'mixit-service.successfully-claimed' });
  }

  public claimDeviceForExistingFacility(body: IClaimMixitDeviceExistingFacility): Observable<Facility> {
    return this.commandService.execute(() => {
      return this.http.put<Facility>(`${this.baseUserUrl}/claim`, body).pipe(
        this.appErrorService.catchApiError<Facility>({
          errorCodes: this.claimErrorCodes,
          fallbackMessageKey: 'mixit-service.failed-with-existing-facility',
        })
      );
    }, { successMessageKey: 'mixit-service.successfully-claimed' });
  }

  public sendCommand(installation: Installation, applicationId: string, commands: ICommand[]) {
    if (installation.connectionState === ConnectionState.Disconnected) {
      return this.showDeviceIsOfflineModal();
    }

    return this.commandService.executeAsync(() => {
      return this.http.patch(`${environment.serverUrl}/api/system/mixitSetpoint/${installation.id}/${applicationId}`, commands).pipe(
        this.appErrorService.catchApiError({
          errorCodes: {
            404: {
              titleKey: 'app-error',
              messageKey: 'mixit-service.failed-to-execute-command',
            }
          },
          fallbackMessageKey: 'mixit-service.failed-to-execute-command',
        })
      );
    }, { successMessageKey: 'mixit-service.successfully-sent-command' });
  }

  public setPumpMode(installation: Installation, applicationId: string, mixitPumpModeDTO: MixitPumpModeDto) {
    if (installation.connectionState === ConnectionState.Disconnected) {
      return this.showDeviceIsOfflineModal();
    }

    return this.commandService.executeAsync(() => {
      return this.http.patch(`${environment.serverUrl}/api/system/mixitPumpControlMode/${installation.id}/${applicationId}`, mixitPumpModeDTO).pipe(
        this.appErrorService.catchApiError({
          errorCodes: {
            404: {
              titleKey: 'app-error',
              messageKey: 'mixit-service.failed-to-execute-command',
            }
          },
          fallbackMessageKey: 'mixit-service.failed-to-execute-command',
        })
      );
    }, { successMessageKey: 'mixit-service.successfully-sent-command' });
  }

  public setWeatherCurve(installation: Installation, applicationId: string, curve: { linear: boolean, curve: Array<[number, number]>, resetToDefault: boolean }) {
    if (installation.connectionState === ConnectionState.Disconnected) {
      return this.showDeviceIsOfflineModal();
    }

    return this.commandService.executeAsync(() => {
      return this.http.patch(`${environment.serverUrl}/api/system/mixitWeatherCurve/${installation.id}/${applicationId}`, curve).pipe(
        this.appErrorService.catchApiError({
          errorCodes: {
            404: {
              titleKey: 'app-error',
              messageKey: 'mixit-service.failed-to-execute-command',
            }
          },
          fallbackMessageKey: 'mixit-service.failed-to-execute-command',
        })
      );
    }, { successMessageKey: 'mixit-service.successfully-sent-command' });
  }

  private showDeviceIsOfflineModal() {
    this.modalService.showErrorModal(this.translate.instant('mixit-service.installation-offline'), this.translate.instant('mixit-service.installation-offline-description'));
  }
}
