import React, { useRef, useLayoutEffect } from 'react';

export interface SvgImageProps {
  svgText: string;
  x?: number;
  y?: number;
  transforms?: string[];
  id?: string;
}

export const SvgImage: React.FC<SvgImageProps> = React.memo(({ x = 0, y = 0, svgText, transforms = [], id, ...props }) => {
  // we inline the SVG in order to make it work in IO manuals etc. It would be nicer to
  // add to defs part of svg and reuse, but not really worth it yet.
  const element = useRef<SVGGElement>(null);
  useLayoutEffect(() => {
    if (element.current) {
      element.current.innerHTML = svgText;
    }
  }, [element.current, svgText]);
  return <g transform={`translate(${x},${y}) ${transforms.join('')}`} ref={element} {...props} id={id} />;
});
