<gbc-page-error-handler [content]="content" [pageError$]="pageError$">
</gbc-page-error-handler>
<ng-template #content>
  <ng-container *ngIf="healthCheckMetadata$ | async as metadata">
    <gbc-page-header *ngIf="pageInfo$ | async as pageInfo" [pageInfo]="pageInfo"></gbc-page-header>
    <gbc-page-container>
      <gbc-tile [title]="'energy-management-dashboard.health-check' | translate">
        <app-key-value>
          <dt app-key>{{'energy-management-dashboard.metadata.name' | translate}}</dt>
          <dd app-value>{{metadata.healthCheckName}}</dd>
          <dt app-key>{{'energy-management-dashboard.metadata.message' | translate}}</dt>
          <dd app-value>{{metadata.message}}</dd>
          <dt app-key>{{'energy-management-dashboard.metadata.description' | translate}}</dt>
          <dd app-value>{{metadata.description}}</dd>
          <ng-container *ngIf="metadata.possibleRootCauses?.length > 0">  
            <dt app-key>{{'energy-management-dashboard.metadata.possible-root-causes' | translate}}</dt>
            <dd app-value>
              <ol>
                <li *ngFor="let possibleRootCause of metadata.possibleRootCauses">{{possibleRootCause}}</li>
              </ol>
            </dd>
          </ng-container>
          <ng-container *ngIf="metadata.remedySteps?.length > 0">  
            <dt app-key>{{'energy-management-dashboard.metadata.remedy-suggestions' | translate}}</dt>
            <dd app-value>
              <ol>
                <li *ngFor="let remedyStep of metadata.remedySteps">{{remedyStep}}</li>
              </ol>
            </dd>
          </ng-container>
        </app-key-value>
      </gbc-tile>
    </gbc-page-container>
  </ng-container>
</ng-template>
