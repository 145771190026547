<gbc-page-container data-test-id="alarm-settings-view">
  <gbc-tile [title]="'alarm-settings-view.tile-title' | translate">
    <app-alarm-settings-table
      (settingsClick)="showAlarmSettingsDialog($event)"
      [clickable]="true"
      [noAlarmsMessage]="'alarm-settings-view.no-alarm-settings' | translate"
      minHeight="180px"
      angulartics2On="click"
      angularticsAction="alarmSettingsShown"
      angularticsCategory="showAllNotificationsShown">
    </app-alarm-settings-table>
  </gbc-tile>
</gbc-page-container>
