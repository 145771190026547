import { CONNECTION_SPACING } from '../Common';

export const TOP = 55;
export const FORWARD_X = 100;
export const RETURN_X = FORWARD_X + CONNECTION_SPACING;

// The total size of the radiator icon svg
export const RADIATOR_SVG_WIDTH = 46;
export const RADIATOR_SVG_HEIGHT = 22;
// the y coordinate of the center of the radiator valve with the SVG
export const RADIATOR_VALVE_Y = 3.5;

// The position of the radiator within the radiator SVG, to make space for the valve in the icon.
export const RADIATOR_OFFSET = 4;
