<gbc-modal title="{{ 'Export energy data for all facilities' | translate }}" (dismiss)="dismiss()" class="gbc-with-dividers" data-test-id="gbc-export-energy-modal">
  <gbc-modal-content>
    <p class="description">{{ 'energy-data-export-modal.description' | translate }}</p>
    <form class="form" [formGroup]="form">

      <gbc-modal-section data-test-id="gbc-year-container">
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'energy-data-export-modal.select-period' | translate }}</gbc-input-label>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-dropdown
            formControlName="year"
            [dropDownItems]="years"
            placeholderTranslationString="energy-data-export-modal.years-placeholder"
            data-test-id="gbc-year-field">
          </gbc-dropdown>
        </gbc-modal-section-column>
      </gbc-modal-section>

      <gbc-modal-section data-test-id="gbc-month-container">
        <gbc-modal-section-column>
          <gbc-input-label></gbc-input-label>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-dropdown
            formControlName="month"
            [dropDownItems]="months"
            placeholderTranslationString="energy-data-export-modal.months-placeholder"
            data-test-id="gbc-month-field">
          </gbc-dropdown>
        </gbc-modal-section-column>
      </gbc-modal-section>

    </form>
  </gbc-modal-content>

  <div class="modal-footer">
    <gbc-modal-buttons data-test-id="gbc-form-buttons">
      <button gbc-modal-button data-test-id="gbc-cancel-button"
        (click)="close()"
        angulartics2On="click"
        angularticsAction="cancelClicked"
        angularticsCategory="energyDataExportModal">
        {{ 'app-cancel' | translate }}
      </button>

      <button gbc-modal-button data-test-id="gbc-export-button"
        [disabled]="(isValid$ | async) === false"
        (click)="export()"
        class="primary"
        angulartics2On="click"
        angularticsAction="exportClicked"
        angularticsCategory="energyDataExportModal">
        {{ 'energy-data-export-modal.buttons.export-now' | translate }}
      </button>
    </gbc-modal-buttons>
  </div>
</gbc-modal>
