<gbc-modal [title]="'warm-weather-shutdown-settings-modal.title' | translate" class="gbc-with-dividers" (dismiss)="dismiss()" data-test-id="warm-weather-shutdown-settings-dialog">
  <gbc-modal-content>
    <p>{{ 'warm-weather-shutdown-settings-modal.info-message' | translate }}</p>
    <form [formGroup]="form">

      <gbc-modal-section data-test-id="warm-weather-shutdown-container">
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'warm-weather-shutdown-settings-modal.fields.warm-weather-shotdown' | translate }}</gbc-input-label>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <p-selectButton formControlName="warmWeatherShutdown" class="toggle-button" [options]="enableOptions" (onChange)="onWarmWeatherToggleChanged($event)">
            <ng-template let-item>
              <div class="button-content">
                <span>{{ item.name }}</span>
                <i *ngIf="item.value === warmWeatherShutdown" class="pi pi-check"></i>
              </div>
            </ng-template>
          </p-selectButton>
        </gbc-modal-section-column>
      </gbc-modal-section>

      <gbc-modal-section data-test-id="outdoor-temperature-threshold-container">
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'warm-weather-shutdown-settings-modal.fields.temperature-threshold' | translate }}</gbc-input-label>
          <img pTooltip="{{ 'warm-weather-shutdown-settings-modal.fields.temperature-threshold-tooltip' | translate }}" escape class="question-mark" src="./assets/svgs/question-mark.svg" data-test-id="temperature-threshold-tooltip" />
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-field
            [min]="temperatureThresholdMin"
            [max]="temperatureThresholdMax"
            [errorDescriptionData]="{ min: temperatureThresholdMin, max: temperatureThresholdMax }"
            errorDescriptionTranslationString="warm-weather-shutdown-settings-modal.validation.temperature-threshold-range"
            fixedWidthStyle="200px"
            tooltipPosition="top"
            formControlName="temperatureThreshold"
            step="1"
            inputType="number"
            unit="°C">
          </gbc-input-field>
        </gbc-modal-section-column>
      </gbc-modal-section>

      <gbc-modal-section data-test-id="averaging-period-container">
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'warm-weather-shutdown-settings-modal.fields.averaging-period' | translate }}</gbc-input-label>
          <img pTooltip="{{ 'warm-weather-shutdown-settings-modal.fields.averaging-period-tooltip' | translate }}" escape class="question-mark" src="./assets/svgs/question-mark.svg" data-test-id="averaging-period-tooltip" />
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-dropdown
            [dropDownItems]="averagingPeriodOptions"
            class="modal-dropdown"
            formControlName="averagingPeriod"
            placeholderTranslationString="app-select">
          </gbc-dropdown>
        </gbc-modal-section-column>
      </gbc-modal-section>
    </form>
  </gbc-modal-content>
  <gbc-modal-buttons>
    <button gbc-modal-button (click)="close()" data-test-id="button-cancel">{{ 'app-cancel' | translate }}</button>
    <button gbc-modal-button (click)="save()" [disabled]="(isValid$ | async) === false" class="primary" data-test-id="button-save">{{ 'app-save' | translate }}</button>
  </gbc-modal-buttons>
</gbc-modal>
