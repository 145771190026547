import { SystemType, CascadeBufferTankChargingSystemState } from '../state';
import { EEquipment, EValueType} from '../types';
import { SampleEquipmentValue } from './sample-equipment-values';
import { v4 } from 'uuid';

export const SAMPLE_CASCADE_BUFFER_TANK_CHARGING_SYSTEM: CascadeBufferTankChargingSystemState = {
  hasId: v4(),
  left: 2060,
  top: 780,
  configured: false,
  type: SystemType.CascadeBufferTankChargingSystem,
  systemInfo: {
    title: '',
    HM_present: true,
    HM_location: 'PrimaryForward',
    HM_address: 4,
    T_SR_present: true,
    T_SF_sensortype: 'SensorPT100',
    T_SR_sensortype: 'SensorPT100',
    DP_S_present: true,
    DP_P_present: true,
    MV_Parallel_Valve_Enabled: true,
    MV_positionControl: 'ThreePointControl',
    MV_actuatorPower: '24_24-Voltage^DirectCurrent',
    SP_present: false,
    MV_valveLocation: 'PrimaryReturn',
    P_CR_type: 'Magna3',
    P_CR_connected: true,
    P_SR_type: 'Magna3',
    P_SR_connected: true,
    T_1_MIDDLE_present: true,
    T_1_BOTTOM_present: true,
    T_2_TOP_present: true,
    T_2_MIDDLE_present: true,
    T_2_BOTTOM_present: true,
    Q_SC_present: true,
    Q_SC_address: 2,
    T_1_TOP_sensortype: 'SensorPT100',
    T_1_MIDDLE_sensortype: 'SensorPT100',
    T_1_BOTTOM_sensortype: 'SensorPT100',
    T_2_TOP_sensortype: 'SensorPT100',
    T_2_MIDDLE_sensortype: 'SensorPT100',
    T_2_BOTTOM_sensortype: 'SensorPT100',
  },
};

export const SAMPLE_VALUES_CASCADE_BUFFER_TANK_CHARGING: SampleEquipmentValue[] = [
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.value,
    equipment: EEquipment.T_PF,
    value: '72.9\u2103',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.value,
    equipment: EEquipment.T_PR,
    value: '33.7\u2103',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.value,
    equipment: EEquipment.T_SF,
    value: '54.3\u2103',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.setPoint,
    equipment: EEquipment.T_SF,
    value: '55\u2103',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.value,
    equipment: EEquipment.Q_PF,
    value: '10.3\u33a5/s',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.value,
    equipment: EEquipment.Q_PR,
    value: '10.3\u33a5/s',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.value,
    equipment: EEquipment.MV_2W_PF,
    value: '13%',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.value,
    equipment: EEquipment.MV_2W_PR,
    value: '13%',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.valveIndication,
    equipment: EEquipment.MV_2W_PF,
    value: 'opening',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.valveIndication,
    equipment: EEquipment.MV_2W_PR,
    value: 'closing',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.value,
    equipment: EEquipment.H_P,
    value: '0.2 Bar',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.value,
    equipment: EEquipment.H_S,
    value: '0.3 Bar',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.value,
    equipment: EEquipment.T_SR,
    value: '25.3\u2103',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.value,
    equipment: EEquipment.P_CR,
    value: '99.7\u33a5/h\n10.7m',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.value,
    equipment: EEquipment.T_SR,
    value: '33.7\u2103',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.value,
    equipment: EEquipment.Q_SC,
    value: '0.15\u33a5/s',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.value,
    equipment: EEquipment.T_1_TOP,
    value: '32.7\u2103',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.value,
    equipment: EEquipment.T_1_MIDDLE,
    value: '30.7\u2103',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.value,
    equipment: EEquipment.T_1_BOTTOM,
    value: '28.7\u2103',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.value,
    equipment: EEquipment.T_2_TOP,
    value: '37.7\u2103',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.value,
    equipment: EEquipment.T_2_MIDDLE,
    value: '35.7\u2103',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.value,
    equipment: EEquipment.T_2_BOTTOM,
    value: '32.7\u2103',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.value,
    equipment: EEquipment.T_2_BOTTOM,
    value: '32.7\u2103',
  },
  {
    systemType: SystemType.CascadeBufferTankChargingSystem,
    type: EValueType.value,
    equipment: EEquipment.P_R,
    value: '99.7\u33a5/h\n10.7m\n67.3\u2103',
  },
  { systemType: SystemType.CascadeBufferTankChargingSystem, type: EValueType.value, equipment: EEquipment.H_DIFF_P, value: '1.23 Bar' },
  { systemType: SystemType.CascadeBufferTankChargingSystem, type: EValueType.value, equipment: EEquipment.H_DIFF_S, value: '1.23 Bar' },
];
