<gbc-page-error-handler [content]="content" [pageError$]="pageError$"></gbc-page-error-handler>

<ng-template #content>
  <gbc-page-header *ngIf="pageInfo" [pageInfo]="pageInfo"></gbc-page-header>
  <gbc-tabs>
    <gbc-tab name="settings" [title]="'app-account-page.settings-tab' | translate" class="app-tab-list">
      <app-profile-page></app-profile-page>
    </gbc-tab>
    <gbc-tab *ngIf="deviceManagementAvailable$ | async" name="devices" [title]="'app-account-page.devices-tab' | translate">
      <app-devices-and-upgrades></app-devices-and-upgrades>
    </gbc-tab>
  </gbc-tabs>
</ng-template>


