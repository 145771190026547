<ng-container>
  <gbc-page-header *ngIf="pageInfo" [pageInfo]="pageInfo"></gbc-page-header>
  <gbc-page-container *ngIf="currentUser$ | async as user">
    <gbc-tile [title]="'app-acquire-liceense.title' | translate" *ngIf="currentInstallation$ | async as installation">
      <form [formGroup]="form"  (ngSubmit)="onSubmit(installation)">
        {{ 'app-acquire.license.acquiring-for' | translate}} {{installation.name}}
        <gbc-table>
          <tr gbc-table-row>
            <td gbc-table-cell>companyName</td>
            <td gbc-table-cell><input type="text" formControlName="companyName"></td>
          </tr>
          <tr gbc-table-row>
            <td gbc-table-cell>address</td>
            <td gbc-table-cell> <input type="text" formControlName="street"></td>
          </tr>
          <tr gbc-table-row>
            <td gbc-table-cell>postalCode</td>
            <td gbc-table-cell> <input type="text" formControlName="postalCode"></td>
          </tr>
          <tr gbc-table-row>
            <td gbc-table-cell>city</td>
            <td gbc-table-cell> <input type="text" formControlName="city"></td>
           </tr>
          <tr gbc-table-row>
           <td gbc-table-cell>country</td>
           <td gbc-table-cell> <input type="text" formControlName="country"></td>
           </tr>
          <tr gbc-table-row>
           <td gbc-table-cell>region</td>
           <td gbc-table-cell> <input type="text" formControlName="region"></td>
          </tr>
          <tr gbc-table-row>
           <td gbc-table-cell>state</td>
           <td gbc-table-cell> <input type="text" formControlName="state"></td>
          </tr>
          <tr gbc-table-row>
           <td gbc-table-cell>vatNumber</td>
           <td gbc-table-cell> <input type="text" formControlName="vatNumber"></td>
          </tr>
          <tr gbc-table-row>
            <td gbc-table-cell>accountManagerName</td>
            <td gbc-table-cell> <input type="text" formControlName="accountManagerName"></td>
          </tr>
          <tr gbc-table-row>
            <td gbc-table-cell>accountManagerEmail</td>
            <td gbc-table-cell> <input type="text" formControlName="accountManagerEmail"></td>
          </tr>
          <tr gbc-table-row>
            <td gbc-table-cell>invoiceEmail</td>
            <td gbc-table-cell> <input type="text" formControlName="invoiceEmail"></td>
         </tr>
          <tr gbc-table-row>
            <td gbc-table-cell> purchaseReference</td>
             <td gbc-table-cell> <input type="text" formControlName="purchaseReference"></td>
          </tr>
          <tr gbc-table-row>
             <button type="submit" [disabled]="!form.valid">Submit</button>
          </tr>
        </gbc-table>
      </form>
    </gbc-tile>
  </gbc-page-container>
</ng-container>
