import React from 'react';
import { Valve } from '../../Valve';
import { Labels } from '../../Labels';
import { Direction } from '../../Common';
import { SvgImage } from '../../SvgImage';
import { FlowMeter } from '../../FlowMeter';
import { Pipe, PipeType } from '../../Pipe';
import { SupplyTriangle } from '../../SupplyTriangle';
import { System, BaseSystemProps } from '../../System';
import { TemperatureSensor } from '../../TemperatureSensor';
import * as metaData from './hot-water-tank-standalone-metadata';
import { HeatMeterOrSensors } from '../../heat-meter-or-sensors/HeatMeterOrSensors';
import tankSvg from '!raw-loader!../../../../../assets/equipment/hotWaterTank.svg';
import { IHotWaterTankStandAloneSystemFormInterface } from '../../../../models/systems/hotWaterTankStandAloneSystem/hotWaterTankStandAloneSystemFormInterface';
import { ValveEnergyIcon } from '../../ValveEnergyIcon';
import { systemHasEnergyValve } from '../../../util/system-properties-util';

export const HotWaterTankStandalone: React.FC<BaseSystemProps<IHotWaterTankStandAloneSystemFormInterface>> = React.memo(
  ({ state, values, alarm, selected }) => {
    const systemInfo = state.systemInfo;

    const showHeatMeter = !!systemInfo.HM_present;
    const showForwardHeatMeter = systemInfo.HM_location === 'PrimaryForward';
    const showReturnHeatMeter = systemInfo.HM_location === 'PrimaryReturn';
    const showForwardSensor = !!(systemInfo.HM_present || systemInfo.T_PF_present);
    const showReturnSensor = !!(systemInfo.HM_present || systemInfo.T_PR_present);
    const hasEnergyValve = systemHasEnergyValve(systemInfo);

    return (
      <System state={state} meta={metaData.hotWaterTankStandaloneMeta} alarm={alarm} selected={selected}>
        <Pipe key="supply" type={PipeType.WarmSupply} x1={0} y1={metaData.FORWARD_Y} x2={metaData.TANK_LEFT + 1} y2={metaData.FORWARD_Y} />
        <Pipe key="return" type={PipeType.ColdReturn} x1={0} y1={metaData.RETURN_Y} x2={metaData.TANK_LEFT + 1} y2={metaData.RETURN_Y} />
        <SvgImage svgText={tankSvg} x={metaData.TANK_LEFT} y={metaData.TANK_TOP} />
        {/* The one sticking out of the top*/}
        <Pipe
          key="loop1"
          type={PipeType.WarmSupply}
          x1={metaData.TANK_LEFT + 53}
          y1={metaData.TANK_TOP}
          x2={metaData.TANK_LEFT + 53}
          y2={metaData.SECONDARY_LOOP_TOP}
        />

        {/* The one going right out of the one of the top*/}
        <Pipe
          key="loop2"
          type={PipeType.WarmSupply}
          x1={metaData.TANK_LEFT + 53}
          y1={metaData.SECONDARY_LOOP_TOP}
          x2={metaData.SECONDARY_LOOP_RIGHT}
          y2={metaData.SECONDARY_LOOP_TOP}
        />

        {/* The warm return pipe */}
        <Pipe
          key="loop6"
          type={PipeType.WarmReturn}
          x1={metaData.TANK_LEFT + metaData.TANK_WIDTH}
          y1={metaData.FORWARD_Y}
          x2={metaData.SECONDARY_LOOP_RIGHT}
          y2={metaData.FORWARD_Y}
        />

        <Pipe
          key="cold1"
          type={PipeType.ColdSupply}
          x1={metaData.COLD_SUPPLY_LEFT}
          x2={metaData.COLD_SUPPLY_LEFT}
          y1={metaData.TANK_BOTTOM}
          y2={metaData.COLD_SUPPLY_BOTTOM}
        />

        {!systemInfo.Has_External_Cold_Water_Supply && (
          <>
            <Pipe
              key="cold2"
              type={PipeType.ColdSupply}
              x1={metaData.COLD_SUPPLY_LEFT}
              x2={metaData.COLD_SUPPLY_RIGHT}
              y1={metaData.COLD_SUPPLY_BOTTOM}
              y2={metaData.COLD_SUPPLY_BOTTOM}
            />
            <SupplyTriangle warm={false} direction={Direction.WEST} x={metaData.COLD_SUPPLY_RIGHT - 1} y={metaData.COLD_SUPPLY_BOTTOM} />
          </>
        )}

        <g key="heatMeter" transform={`translate(${metaData.HEAT_METER_X} ${metaData.FORWARD_Y})`}>
          <HeatMeterOrSensors
            direction={Direction.EAST}
            showHeatMeter={showHeatMeter}
            showForwardHeatMeter={showForwardHeatMeter}
            showReturnHeatMeter={showReturnHeatMeter}
            showForwardSensor={showForwardSensor}
            showReturnSensor={showReturnSensor}
          />
        </g>
        <g key="topSensor" transform={`translate(${metaData.TANK_LEFT + 1} ${metaData.TANK_TOP_TEMP_Y})`}>
          <TemperatureSensor direction={Direction.NORTH} />
        </g>
        {systemInfo.T_MIDDLE_present && <TemperatureSensor direction={Direction.SOUTH} x={metaData.TANK_RIGHT} y={metaData.TANK_MIDDLE} />}
        {systemInfo.T_BOTTOM_present && (
          <TemperatureSensor direction={Direction.SOUTH} x={metaData.TANK_RIGHT} y={metaData.TANK_BOTTOM_TEMP_Y} />
        )}
        {systemInfo.T_SR_present && (
          <g key="secondaryReturnSensor" transform={`translate(${metaData.T_SR_X} ${metaData.FORWARD_Y})`}>
            <TemperatureSensor direction={ Direction.EAST } />
          </g>
        )}
        {systemInfo.Q_SC_present && !systemInfo.Has_External_Cold_Water_Supply && (
          <FlowMeter key="coldSupplyFlow" x={metaData.COLD_SUPPLY_FLOW_X} y={metaData.COLD_SUPPLY_BOTTOM} />
        )}
        {systemInfo.MV_valveLocation === 'PrimaryForward' && (
          <>
            {hasEnergyValve && <ValveEnergyIcon x={metaData.VALVE_X - 25} y={metaData.FORWARD_Y - 25} />}
            <Valve type="TwoWayValve" direction={Direction.EAST} x={metaData.VALVE_X} y={metaData.FORWARD_Y} />;
          </>
        )}
        {systemInfo.MV_valveLocation === 'PrimaryReturn' && (
          <>
            {hasEnergyValve && <ValveEnergyIcon x={metaData.VALVE_X - 25} y={metaData.RETURN_Y - 40} />}
            <Valve type="TwoWayValve" direction={Direction.WEST} x={metaData.VALVE_X} y={metaData.RETURN_Y} />;
          </>
        )}
        <Labels positions={metaData.getHotWaterTankStandaloneLabels(systemInfo)} values={values} />
      </System>
    );
  }
);
