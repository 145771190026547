import React from 'react';
import { SvgImage } from '../SvgImage';
import { Pipe, PipeType } from '../Pipe';
import { CONNECTION_SPACING } from '../Common';
import heatingCoilIcon from '!raw-loader!../../../../assets/icons/heating-coil.svg';


const HEATING_COIL_SVG_WIDTH = 54;
const HEATING_COIL_SVG_HEIGHT = 33;
const ICON_LEFT = CONNECTION_SPACING / 2 - HEATING_COIL_SVG_WIDTH / 2;


export const FORWARD_Y = HEATING_COIL_SVG_HEIGHT / 2;
export const RETURN_Y = HEATING_COIL_SVG_HEIGHT / 2;

export const HeatingCoil: React.FC<{x: number, y: number, reversedWarmCold: boolean}> = ({ x, y, reversedWarmCold }) => {
  y = reversedWarmCold ? y : y + HEATING_COIL_SVG_HEIGHT;
  x = reversedWarmCold ? x : x + CONNECTION_SPACING;
  const rotation = reversedWarmCold ? 0 : 180;
  return (
      <g transform={`translate(${x}, ${y}) rotate(${rotation})`}>
        <Pipe x1={0} y1={FORWARD_Y} x2={ICON_LEFT + 4} y2={FORWARD_Y} type={PipeType.ColdReturn}/>
        <Pipe x1={CONNECTION_SPACING} y1={RETURN_Y} x2={ICON_LEFT + HEATING_COIL_SVG_WIDTH - 2} y2={RETURN_Y} type={PipeType.WarmSupply}/>
          <SvgImage  svgText={heatingCoilIcon} x={ICON_LEFT} y={0}/>
      </g>);
};
