export enum AdobeAnalyticsEvent {
  PageLoad = 'Page Load',
  UserInteraction = 'Click',
}

export interface IUserTrackingPayload {
  data: {
    pageInfo: {
      pageName: string,
      toolName?: string,
      rsid: string,
      platform: string,
      server: string,
      companyCode: string,
      siteCountry: string,
      channelTitle: string,
      language: string,
      hier1: string
    },
    user: {
      puid: string,
      accountName?: string
    },
    tabName?: string
  }
}
