import { Component, Input, OnInit, ContentChildren, QueryList } from '@angular/core';
import { TableHeaderComponent } from '../table-header/table-header.component';

@Component({
  selector: 'gbc-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  @Input()
  public clickableRows: boolean;
  public selectableRows: boolean;

  @ContentChildren(TableHeaderComponent)
  public headers: QueryList<TableHeaderComponent>;

}
