import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { AlarmOverview, Application } from '../../../interfaces/alarm';
import { Installation } from '../../../interfaces/facilty';
import { AppModel, CascadeTankSystemState, EEquipment } from '@ams/dumbledore';
import { DataPointsResult, SystemDevice } from '../../../interfaces/data-points';
import { PumpOption } from '../../../services/installation-configuration.service';
import { SchematicsService } from '../../../services/schematics.service';
import { getPumpOptions, getPumpType, systemsToPumpDevice, systemsToValveDevice } from '../../../utils/data-point-utils';
import { map } from 'rxjs/operators';
import { Serie, SerieType } from '../../charts/temperature-chart-tile/temperature-chart-tile.component';
import { TranslateService } from '@ngx-translate/core';
import { systemHasEnergyValve } from 'projects/dumbledore/src/schematic/util/system-properties-util';

@Component({
  selector: 'app-cascade-tank-dashboard',
  templateUrl: './cascade-tank-dashboard.component.html',
  styleUrls: ['./cascade-tank-dashboard.component.scss'],
})
export class CascadeTankDashboardComponent implements OnInit {
  @Input() public alarm$: Observable<AlarmOverview>;
  @Input() application: Application;
  @Input() public installation$: Observable<Installation>;
  @Input() public schematic$: Observable<AppModel>;
  @Input() public dataPoints$: Observable<DataPointsResult>;

  public pumpType$: Observable<string>;
  public pumpDevice$: Observable<SystemDevice | undefined>;
  public pumpOptions$: Observable<PumpOption[] | undefined>;
  public valveDevice$: Observable<SystemDevice | undefined>;
  public tankOneChartSeries$: Observable<Serie[]>;
  public tankTwoChartSeries$: Observable<Serie[]>;
  public EEquipment = EEquipment;
  public hasEnergyValve$: Observable<boolean | undefined>;
  public hasPrimaryTemperatureSensors$: Observable<boolean | undefined>;
  private system$: Observable<CascadeTankSystemState>;

  constructor(private schematicsService: SchematicsService, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.system$ = this.schematic$.pipe(
      map((schematic) => {
        return schematic.systems.find((s) => s.hasId === this.application.id) as CascadeTankSystemState;
      })
    );

    this.pumpDevice$ = this.dataPoints$.pipe(systemsToPumpDevice(this.application.id));

    this.pumpType$ = getPumpType(this.schematic$, this.application.id);
    this.pumpOptions$ = this.pumpOptions$ = getPumpOptions(this.pumpDevice$, this.schematicsService, this.application.id);

    this.valveDevice$ = this.dataPoints$.pipe(systemsToValveDevice(this.application.id));

    const tankTop$ = this.translateService.get('commercial-hot-water-dashboard.tank-top');
    const tankCenter$ = this.translateService.get('commercial-hot-water-dashboard.tank-center');
    const tankBottom$ = this.translateService.get('commercial-hot-water-dashboard.tank-bottom');

    this.tankOneChartSeries$ = combineLatest([this.system$, tankTop$, tankCenter$, tankBottom$]).pipe(
      map(([system, tankTop, tankCenter, tankBottom]) => {
        if (!system?.systemInfo) {
          return [];
        }

        const series: Serie[] = [];

        series.push({
          type: SerieType.DataPoint,
          title: tankTop,
          dumbledoreId: EEquipment.T_1_TOP,
        });

        if (system.systemInfo.T_1_MIDDLE_present) {
          series.push({
            type: SerieType.DataPoint,
            title: tankCenter,
            dumbledoreId: EEquipment.T_1_MIDDLE,
          });
        }

        if (system.systemInfo.T_1_BOTTOM_present) {
          series.push({
            type: SerieType.DataPoint,
            title: tankBottom,
            dumbledoreId: EEquipment.T_1_BOTTOM,
          });
        }

        return series;
      })
    );

    this.tankTwoChartSeries$ = combineLatest([this.system$, tankTop$, tankCenter$, tankBottom$]).pipe(
      map(([system, tankTop, tankCenter, tankBottom]) => {
        if (!system?.systemInfo) {
          return [];
        }

        const series: Serie[] = [];

        if (system.systemInfo.T_2_TOP_present) {
          series.push({
            type: SerieType.DataPoint,
            title: tankTop,
            dumbledoreId: EEquipment.T_2_TOP,
          });
        }

        if (system.systemInfo.T_2_MIDDLE_present) {
          series.push({
            type: SerieType.DataPoint,
            title: tankCenter,
            dumbledoreId: EEquipment.T_2_MIDDLE,
          });
        }

        if (system.systemInfo.T_2_BOTTOM_present) {
          series.push({
            type: SerieType.DataPoint,
            title: tankBottom,
            dumbledoreId: EEquipment.T_2_BOTTOM,
          });
        }

        return series;
      })
    );

    this.hasEnergyValve$ = this.system$.pipe(
      map((system) => {
        return systemHasEnergyValve(system.systemInfo);
      })
    );

    this.hasPrimaryTemperatureSensors$ = this.system$.pipe(
      map((system) => {
        return (system?.systemInfo.T_PF_present || system?.systemInfo.T_PR_present || system?.systemInfo.HM_present);
      })
    );
  }
}
