<gbc-modal (dismiss)='dismiss()' [title]="'mixit-setpoint-modal.setpoint' | translate" class="gbc-with-dividers" data-test-id='mixit-setpoint-modal'>
  <gbc-modal-content>
    <h2 class='modal-header'>
      {{ 'mixit-setpoint-modal.setpoint-temperature' | translate }}
    </h2>
    <p class='modal-text'>
      {{ 'mixit-setpoint-modal.setpoint-description' | translate }}
    </p>
    <form [formGroup]='form'>
      <gbc-modal-section>
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'mixit-setpoint-modal.setpoint' | translate }}</gbc-input-label>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-field
            [errorDescriptionData]='{ min: min, max: max }'
            [max]='max'
            [min]='min'
            errorDescriptionTranslationString='app-enter-value-between'
            formControlName='temperatureSetpoint'
            inputType='number'
            unit='°C'>
          </gbc-input-field>
        </gbc-modal-section-column>
      </gbc-modal-section>
    </form>
  </gbc-modal-content>
  <gbc-modal-buttons>
    <button
      (click)='close()'
      angulartics2On='click'
      angularticsAction='cancelClicked'
      angularticsCategory='adjustTemperatureSetpointConfigurationsShown'
      gbc-modal-button>
      {{ 'app-cancel' | translate }}
    </button>
    <button
      (click)='save()'
      [disabled]='!form.valid'
      angulartics2On='click'
      angularticsAction='saveClicked'
      angularticsCategory='adjustTemperatureSetpointConfigurationsShown'
      class='primary'
      gbc-modal-button>
      {{ 'app-save' | translate }}
    </button>
  </gbc-modal-buttons>
</gbc-modal>
