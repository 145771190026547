import { Component, Inject } from '@angular/core';
import { UserTrackingHelperService } from 'projects/customerportal/src/app/services/user-tracking-helper.service';
import { MODAL_DATA } from 'projects/shared/src/lib/interfaces/modal';

export interface PumpModalData {
  PumpImage: string;
  PumpProductType: string;
  PumpProductNumber: number;
  PumpSerialNumber: string;
  PumpProductionCode: string;
  PumpLink: string;
  PumpTitle: string;
}

@Component({
  selector: 'app-mixit-pump-info-modal',
  templateUrl: './mixit-pump-info-modal.component.html',
  styleUrls: ['./mixit-pump-info-modal.component.scss']
})
export class MixitPumpInfoModalComponent {
  constructor(
    @Inject(MODAL_DATA) public data: PumpModalData,
    private userTrackingHelperService: UserTrackingHelperService
  ) {}

  openProductCatalog() {
    this.userTrackingHelperService.trackUserAction(
      'mixitPumpInfoIconClicked',
      'readMoreClicked'
    );
  }
  dismiss() {
    this.userTrackingHelperService.trackUserAction(
      'mixitPumpInfoIconClicked',
      'exitClicked'
    );
  }
}
