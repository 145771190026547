import React from 'react';
import { Labels } from '../../Labels';
import { Direction } from '../../Common';
import { PumpIcon } from '../../PumpIcon';
import { SvgImage } from '../../SvgImage';
import { SecondPumpComponent } from './SecondPump';
import { ValveWrapperComponent } from './ValveWrapper';
import { BaseSystemProps, System } from '../../System';
import { DirtCollectorComponent } from './DirtCollector';
import { TemperatureSensor } from '../../TemperatureSensor';
import * as metaData from './indirect-district-heating-supply-metadata';
import { DifferentialpressureSensor } from '../../DifferentialPressureSensor';
import { SUPPLY_TRIANGLE_HEIGHT, SupplyTriangle } from '../../SupplyTriangle';
import { HeatMeterOrSensors } from '../../heat-meter-or-sensors/HeatMeterOrSensors';
import heatExchangerSvg from '!raw-loader!../../../../../assets/systems/heatexchanger.svg';
import { IIndirectDistrictHeatingSupplySystemFormInterface } from '../../../../models/systems/indirectDistrictHeatingSupplySystem/indirectDistrictHeatingSupplySystemFormInterface';
import {
  EXCHANGER_ICON_TOP,
  EXCHANGER_ICON_X,
  FORWARD_Y,
  RETURN_Y,
  SECONDARY_DIFFERENTIAL_PRESSURE_X,
  SECONDARY_TEMP_SENSOR_X,
} from '../../heat-exchanger-common/heat-exchanger-common-metadata';
import { ConnectionPipesComponent } from './ConnectionPipes';
import { systemHasEnergyValve } from '../../../util/system-properties-util';

export const VALVE_X = 170;
export const IndirectDistrictHeatingSupplySystem: React.FC<BaseSystemProps<IIndirectDistrictHeatingSupplySystemFormInterface>> = React.memo(
  ({ state, values, alarm, selected }) => {
    const systemInfo = state.systemInfo as IIndirectDistrictHeatingSupplySystemFormInterface;
    const showHeatMeter = !!systemInfo.HM_present;
    const showForwardHeatMeter = systemInfo.HM_location === 'PrimaryForward';
    const showReturnHeatMeter = systemInfo.HM_location === 'PrimaryReturn';
    const showForwardSensor = !!(systemInfo.HM_present || systemInfo.T_F_present);
    const showReturnSensor = !!(systemInfo.HM_present || systemInfo.T_R_present);
    const hasEnergyValve = systemHasEnergyValve(systemInfo);

    const getPumpSvg = () => {
      if (!systemInfo.P_location) {
        return null;
      }
      const pumpY = systemInfo.P_location === 'SecondaryForward' ? metaData.SUPPLY_Y : metaData.RETURN_Y;
      const direction = systemInfo.P_location === 'SecondaryForward' ? Direction.EAST : Direction.WEST;
      return <PumpIcon direction={direction} x={metaData.PUMP_X} y={pumpY} />;
    };

    return (
      <System state={state} meta={metaData.indirectDistrictHeatingSupplyMeta} alarm={alarm} selected={selected} titleX={190}>
        <SupplyTriangle key="supplyTriangle" warm={true} x={metaData.SUPPLY_X} y={metaData.SUPPLY_Y} direction={Direction.EAST} />
        <SupplyTriangle
          key="returnTriangle"
          warm={false}
          x={metaData.SUPPLY_X - SUPPLY_TRIANGLE_HEIGHT}
          y={metaData.RETURN_Y}
          direction={Direction.WEST}
        />

        {/* We can't use the diffP sensor from heat exchanger common, as this one needs to sit before the outtake */}
        {systemInfo.DP_P_present && <DifferentialpressureSensor
          key="primaryDifferentialPressure"
          x={metaData.PRIMARY_DIFFERENTIAL_PRESSURE_X}
          y={metaData.SUPPLY_Y}
        />}

        <ConnectionPipesComponent outtakeBeforeHeatExchanger={systemInfo.Outtake_Before_HeatEx}/>

        {/* We don't use the heat meter for HeatExchangerCommon, as it is positioned too closely to the heat exchanger */}
        <g transform={`translate(${metaData.HEAT_METER_X}, ${metaData.SUPPLY_Y})`}>
          <HeatMeterOrSensors
            direction={Direction.EAST}
            showHeatMeter={showHeatMeter}
            showForwardHeatMeter={showForwardHeatMeter}
            showReturnHeatMeter={showReturnHeatMeter}
            showForwardSensor={showForwardSensor}
            showReturnSensor={showReturnSensor}
          />
        </g>

        {/* The T_PF and T_PR sensors before the heat ex*/}
        <g transform={`translate(${metaData.HEAT_EXCHANGER_X + 230}, ${metaData.SUPPLY_Y})`}>
          <HeatMeterOrSensors
            direction={Direction.EAST}
            showHeatMeter={false}
            showForwardHeatMeter={false}
            showReturnHeatMeter={false}
            showForwardSensor={!!systemInfo.T_PF_present}
            showReturnSensor={!!systemInfo.T_PR_present}
          />
        </g>

        {systemInfo.hasDirtCollector && systemInfo.DP_location && (
          <DirtCollectorComponent location={systemInfo.DP_location}></DirtCollectorComponent>
        )}

        <g transform={`translate(${metaData.HEAT_EXCHANGER_X + 20}, 0)`}>
          <SvgImage key="heatExIcon" svgText={heatExchangerSvg} x={EXCHANGER_ICON_X} y={EXCHANGER_ICON_TOP} />
          {(systemInfo.Control_enabled || systemInfo.T_SF_present) && (
            <g key="T_SF" transform={`translate(${SECONDARY_TEMP_SENSOR_X},${FORWARD_Y})`}>
              <TemperatureSensor direction={Direction.EAST} />
            </g>
          )}
          {systemInfo.DP_S_present && (
            <DifferentialpressureSensor key="secondaryDifferentialPressure" x={SECONDARY_DIFFERENTIAL_PRESSURE_X} y={FORWARD_Y} />
          )}
          {systemInfo.T_SR_present && (
            <g key="T_SR" transform={`translate(${SECONDARY_TEMP_SENSOR_X},${RETURN_Y})`}>
              <TemperatureSensor direction={Direction.WEST} />
            </g>
          )}

          <ValveWrapperComponent isEnergyValve={hasEnergyValve} systemInfo={systemInfo} />
        </g>

        {getPumpSvg()}
        {systemInfo.Has_Slave_Pump && systemInfo.P_location && <SecondPumpComponent location={systemInfo.P_location} />}

        <g transform={`translate(${metaData.HEAT_EXCHANGER_X + 20}, 0)`}>
          <Labels positions={metaData.labelPositions(systemInfo)} values={values} />
        </g>
        <Labels positions={metaData.getIndirectDistrictHeatSupplyLabels(systemInfo)} values={values} />
      </System>
    );
  }
);
