import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from 'shared';
import { UserService } from '../services/user.service';
import { flatMap, map } from 'rxjs/operators';
import { UserType } from '../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class LoggedInGuard  {

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check(next, state);
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.check(next, state);
  }

  // This serves two purposes:
  // - Delay loading of route until authentication is completed
  // - Capture redirect URI, so that we can perform a signin redirect if the user is not authenticated
  check(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.authenticated.pipe(
      flatMap((authenticated: boolean) => {
        if (!authenticated) {
          this.authService.signinRedirect(state.url);
          return of(false);
        }

        return this.userService.currentUser$.pipe(
          map(u => {
            if (u?.type === UserType.OIDC) {
              this.router.navigate(['']);
              return false;
            }

            return true;
          })
        );
      })
    );
  }

}
