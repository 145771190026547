import { Component, Input, OnInit } from '@angular/core';
import { Options, SeriesOptionsType } from 'highcharts';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { extendWeatherCurveDataPoints, WEATHER_CURVE_MAX, WEATHER_CURVE_MIN } from '../../../utils/data-point-utils';

@Component({
  selector: 'app-weather-curve-chart',
  templateUrl: './weather-curve-chart.component.html',
  styleUrls: ['./weather-curve-chart.component.scss'],
})
export class WeatherCurveChartComponent implements OnInit {
  @Input() data$: Observable<Array<[number, number]>>;
  @Input() outdoorTemp$: Observable<number>;
  options: Options;
  series: Observable<SeriesOptionsType[]>;

  constructor(private translateService: TranslateService) {
    const translate = this.translateService;
    this.options = {
      colors: ['#15ac5f'],
      xAxis: {
        softMin: WEATHER_CURVE_MIN,
        softMax: WEATHER_CURVE_MAX,
        title: {
          text: this.translateService.instant('weather-curve-chart.outdoor-temperature'),
        },
        labels: {
          format: '{value} \u00b0C',
        },
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: null,
        },
        labels: {
          format: '{value} \u00b0C',
        },
      },
      plotOptions: {
        series: {
          animation: false,
          tooltip: {
            valueDecimals: 1,
          },
        },
      },
      chart: {
        animation: false,
      },
      tooltip: {
        formatter() {
          if (this.series?.type === 'scatter') {
            return translate.instant('weather-curve-chart.current-tooltip-label', {
              outdoor: Math.round(Number(this.x)),
              setpoint: Math.round(Number(this.y)),
            });
          }

          return translate.instant('weather-curve-chart.tooltip-label', {
            outdoor: Math.round(Number(this.x)),
            setpoint: Math.round(Number(this.y)),
          });
        },
      },
    };
  }

  private extendCurveToIncludeOutdoorTemp(data: Array<[number, number]>, outdoorTemp: number) {
    if (typeof outdoorTemp === 'number' && Number.isFinite(outdoorTemp) && data.length > 0) {
      // create a new point if outdoorTemp is left of curve
      if (data[0][0] > outdoorTemp) {
        data = [[outdoorTemp, data[0][1]], ...data];
      }
      // create a new point if outdoorTemp is right of curve
      if (data[data.length - 1][0] < outdoorTemp) {
        data = [...data, [outdoorTemp, data[data.length - 1][1]]];
      }
      return data;
    }
    return data;
  }

  ngOnInit(): void {
    this.series = combineLatest([this.data$, this.outdoorTemp$]).pipe(
      map(([data, outdoorTemp]) => {
        data = this.extendCurveToIncludeOutdoorTemp(data, outdoorTemp);
        data = extendWeatherCurveDataPoints(data);
        const result: SeriesOptionsType[] = [
          {
            data,
            type: 'line',
            showInLegend: false,
          },
        ];

        // Add the actual setpoint if possible
        if (typeof outdoorTemp === 'number' && Number.isFinite(outdoorTemp)) {
          result.push({
            data: [this.getActualSetpoint(data, outdoorTemp)],
            type: 'scatter',
            name: this.translateService.instant('weather-curve-chart.legend-actual-setpoint'),
            marker: {
              enabled: true,
              radius: 6,
            },
          });
        } else {
          // need to add an empty series, otherwise highcharts fail to add the new series later
          result.push({
            data: [],
            type: 'scatter',
            name: '',
          });
        }
        return result;
      })
    );
  }

  getActualSetpoint(data: Array<[number, number]>, outdoorTemp: number): [number, number] | null {
    if (data.length === 0) {
      return null;
    }
    if (data[0][0] > outdoorTemp) {
      return [outdoorTemp, data[0][1]];
    }
    if (data[data.length - 1][0] < outdoorTemp) {
      return [outdoorTemp, data[data.length - 1][1]];
    }
    for (let i = 0; i < data.length - 1; ++i) {
      if (data[i][0] <= outdoorTemp && data[i + 1][0] >= outdoorTemp) {
        const slope = (data[i + 1][1] - data[i][1]) / (data[i + 1][0] - data[i][0]);
        const interpolatedY = slope * (outdoorTemp - data[i][0]) + data[i][1];
        return [outdoorTemp, interpolatedY];
      }
    }
    return null;
  }
}
