import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-add-alarm-comment-tile',
  templateUrl: './add-alarm-comment-tile.component.html',
  styleUrls: ['./add-alarm-comment-tile.component.scss']
})
export class AddAlarmCommentTileComponent {
  @Output() commentSubmit = new EventEmitter<string>();

  public comment = '';

  onSubmit() {
    const trimmed = this.comment.trim();
    if (trimmed) {
      this.commentSubmit.emit(this.comment);
      this.comment = '';
    }
  }
}
