
import { SystemType, DistrictHeatingSystemState, BoilerSystemState } from '../state';
import { EEquipment, EValueType, IEquipmentValue, ISetPointValue, IValveOpenValue } from '../types';
import { SampleEquipmentValue } from './sample-equipment-values';
import { v4 } from 'uuid';

export const SAMPLE_BOILER: BoilerSystemState = {
  hasId: v4(),
  type: SystemType.Boiler,
  left: 1060,
  top: 10,
  configured: false,
  systemInfo: {
    HM_present: true,
    HM_location: 'PrimaryReturn',
    P_present: true,
    P_location: 'Return'
  }
};

export const SAMPLE_VALUES_BOILER: SampleEquipmentValue[] = [
  {
    systemType: SystemType.Boiler,
    type: EValueType.value,
    equipment: EEquipment.T_F,
    value: '72.9\u2103',
  },
  {
    systemType: SystemType.Boiler,
    type: EValueType.value,
    equipment: EEquipment.T_R,
    value: '33.7\u2103',
  },
  {
    systemType: SystemType.Boiler,
    type: EValueType.value,
    equipment: EEquipment.Q_PF,
    value: '10.3\u33a5/s',
  },
  {
    systemType: SystemType.Boiler,
    type: EValueType.value,
    equipment: EEquipment.Q_PR,
    value: '10.3\u33a5/s',
  },
  {
    systemType: SystemType.Boiler,
    type: EValueType.value,
    equipment: EEquipment.P_F,
    value: '99.7\u33a5/h\n10.7m',
  },
  {
    systemType: SystemType.Boiler,
    type: EValueType.value,
    equipment: EEquipment.P_R,
    value: '99.7\u33a5/h\n10.7m',
  }
];
