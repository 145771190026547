import { Component, Input } from '@angular/core';

export interface IVerificationResult {
  progressState: ProgressState;
  result?: boolean;
  errorMessage?: string;
}

export enum ProgressState {
  None = 'None',
  InProgress = 'InProgress',
  CompletedWithSuccess = 'CompletedWithSuccess',
  CompletedWithError = 'CompletedWithError',
}

@Component({
  selector: 'gbc-progress-checker',
  templateUrl: './progress-checker.component.html',
  styleUrls: ['./progress-checker.component.scss']
})
export class ProgressCheckerComponent {

  @Input() job: IVerificationResult;
  @Input() title: string;
  @Input() errorMessage: string;
  @Input() showErrorMessage: boolean = true;
}
