import React from 'react';
import { SystemType } from '../state';
import { BaseSystemProps } from './System';
import { Mixit } from './systems/mixit/Mixit';
import { CorePump } from './systems/core-pump/CorePump';
import { BufferTank } from './systems/buffer-tank/BufferTank';
import { BoilerSystem } from './systems/boiler/BoilerSystem';
import { BoosterSystem } from './systems/booster/BoosterSystem';
import { HotTemperature } from './systems/HotTemperatureSystem';
import { ColdTemperature } from './systems/ColdTemperatureSystem';
import { HotWaterTank } from './systems/hot-water-tank/HotWaterTank';
import { HotWaterPump } from './systems/hot-water-pump/HotWaterPump';
import { CascadeTankSystem } from './systems/cascade-tank/CascadeTank';
import { ColdWaterPump } from './systems/cold-water-pump/ColdWaterPump';
import { MixingLoopSystem } from './systems/mixing-loop/MixingLoopSystem';
import { UnspecifiedLoad } from './systems/unspecified-load/UnspecifiedLoad';
import { ColdWaterSupply } from './systems/cold-water-supply/ColdWaterSupply';
import { PressureSensorSystem } from './systems/pressure-sensor/PressureSensor';
import { PumpStandaloneSystem } from './systems/pump-standalone/PumpStandalone';
import { HeatExchangerSystem } from './systems/heat-exchanger/HeatExchangerSystem';
import { SimpleBufferTankSystem } from './systems/simple-buffer-tank/SimpleBufferTank';
import { DistrictHeatingSystem } from './systems/district-heating/DistrictHeatingSystem';
import { OutdoorTemperatureSystem } from './systems/outdoor-temperature/OutdoorTemperatureSystem';
import { CommercialHotWaterSystem } from './systems/commercial-hot-water/CommercialHotWaterSystem';
import { HotWaterTankStandalone } from './systems/hot-water-tank-standalone/HotWaterTankStandalone';
import { HeatMeterStandaloneSystem } from './systems/heatmeter-standalone/HeatMeterStandaloneSystem';
import { CascadeBufferTankChargingSystem } from './systems/cascade-buffer-tank-charging/CascadeBufferTankCharging';
import { MixingLoopUnspecifiedLoadSystem } from './systems/mixing-loop-unspecified-load/MixingLoopUnspecifiedLoadSystem';
import { TwoStrokeHeatExchangerHotWater } from './systems/two-stroke-heat-exchanger-hot-water/TwoStrokeHeatExchangerHotWater';
import { CommercialHotWaterStandaloneSystem } from './systems/commercial-hot-water-standalone/CommercialHotWaterStandaloneSystem';
import { IndirectDistrictHeatingSupplySystem } from './systems/indirect-district-heating-supply/IndirectDistrictHeatingSupplySystem';

export const getComponent = (type: string): React.FC<BaseSystemProps<any>> | null => {
  switch (type) {
    case SystemType.DistrictHeatingSupply:
      return DistrictHeatingSystem;
    case SystemType.MixingLoop:
      return MixingLoopSystem;
    case SystemType.MixingloopUnspecifiedLoadSystem:
      return MixingLoopUnspecifiedLoadSystem;
    case SystemType.CommercialHotWater:
      return CommercialHotWaterSystem;
    case SystemType.CommercialHotWaterStandalone:
      return CommercialHotWaterStandaloneSystem;
    case SystemType.HeatExchanger:
      return HeatExchangerSystem;
    case SystemType.Boiler:
      return BoilerSystem;
    case SystemType.OutdoorTemperature:
      return OutdoorTemperatureSystem;
    case SystemType.HotWaterPump:
      return HotWaterPump;
    case SystemType.ColdWaterPump:
      return ColdWaterPump;
    case SystemType.HotWaterTank:
      return HotWaterTank;
    case SystemType.Mixit:
      return Mixit;
    case SystemType.TwoStrokeHeatExchangerHotWaterSystem:
      return TwoStrokeHeatExchangerHotWater;
    case SystemType.UnspecifiedLoad:
      return UnspecifiedLoad;
    case SystemType.CorePumpSystem:
      return CorePump;
    case SystemType.HotTemperature:
      return HotTemperature;
    case SystemType.ColdTemperature:
      return ColdTemperature;
    case SystemType.BufferTank:
      return BufferTank;
    case SystemType.PumpStandalone:
      return PumpStandaloneSystem;
    case SystemType.HeatmeterStandalone:
      return HeatMeterStandaloneSystem;
    case SystemType.SimpleBufferTankSystem:
      return SimpleBufferTankSystem;
    case SystemType.HotWaterTankStandAloneSystem:
      return HotWaterTankStandalone;
    case SystemType.ColdWaterSupplySystem:
      return ColdWaterSupply;
    case SystemType.BoosterSystem:
      return BoosterSystem;
    case SystemType.PressureSensorSystem:
      return PressureSensorSystem;
    case SystemType.CascadeTankSystem:
      return CascadeTankSystem;
    case SystemType.IndirectDistrictHeatingSupplySystem:
      return IndirectDistrictHeatingSupplySystem;
    case SystemType.CascadeBufferTankChargingSystem:
      return CascadeBufferTankChargingSystem;
    default:
      return null;
  }
};
