import { LabelPosition, SystemMeta } from '../../types';
import { SmallLabelDeltas } from '../../../util/constants';
import { EConnectionType, SystemType } from '../../../state';
import { EConnectionPointPlacement, EEquipment } from '../../../types';
import { FORWARD_Y, RETURN_Y } from '../../heat-exchanger-common/heat-exchanger-common-metadata';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/chw-standalone.svg';
import commercialHotWaterStandaloneSystem from '../../../../models/systems/commercialHotWaterStandaloneSystem/commercialHotWaterStandaloneSystemForm.json';
import { ICommercialHotWaterStandaloneSystemFormInterface } from '../../../../models/systems/commercialHotWaterStandaloneSystem/commercialHotWaterStandaloneSystemFormInterface';

// X-position of pump center
export const PUMP_X = 75;
// X-position of point where secondary loop meets cold water supply pipe
export const COLD_WATER_X = 0;
// vertical length of cold water supply pipe
export const COLD_WATER_VERTICAL_LENGTH = 60;
export const SECONDARY_LOOP_RIGHT = 100;
export const SUPPLY_TRIANGLE_X = 8;
export const PIPE_VERTICAL_X = 50;

export const commercialHotWaterStandaloneMeta: SystemMeta = {
  systemType: SystemType.CommercialHotWaterStandalone,
  name: 'Commercial Hot Water Standalone',
  thumbnail,
  width: 200,
  height: 320,
  form: commercialHotWaterStandaloneSystem,
  calculateConnectionPoints: (form: ICommercialHotWaterStandaloneSystemFormInterface) => [
    {
      key: EConnectionPointPlacement.connectionPrimaryForward,
      x: 0,
      y: FORWARD_Y,
      type: EConnectionType.supply,
      dotted: false,
    },
    ...(form.hotReturn
      ? [
          {
            key: EConnectionPointPlacement.connectionPrimaryReturn,
            x: 0,
            y: RETURN_Y,
            type: EConnectionType.supply,
            dotted: true,
          },
        ]
      : [
          {
            key: EConnectionPointPlacement.connectionPrimaryReturn,
            x: 0,
            y: RETURN_Y,
            type: EConnectionType.return,
            dotted: true,
          },
        ]),
  ],
};

export const getCommercialHotWaterStandaloneLabels = (systemInfo: ICommercialHotWaterStandaloneSystemFormInterface): LabelPosition[] => {
  const pumpLabel: LabelPosition[] = systemInfo.P_hasPump
    ? [
        {
          id: EEquipment.P_CR,
          x: PUMP_X,
          y: RETURN_Y + SmallLabelDeltas.WEST_Y + 4, // we offset a little because pump labels are two lines
          horizontalAlignment: 'middle',
        },
      ]
    : [];
  return [...pumpLabel];
};
