import { SystemType, DistrictHeatingSystemState, BoilerSystemState, TwoStrokeHeatExchangerHotWaterSystem } from '../state';
import { EEquipment, EValueType, IEquipmentValue, ISetPointValue, IValveOpenValue } from '../types';
import { SampleEquipmentValue } from './sample-equipment-values';
import { v4 } from 'uuid';

export const SAMPLE_TWO_STROKE_HEAT_EXCHANGER: TwoStrokeHeatExchangerHotWaterSystem = {
  hasId: v4(),
  type: SystemType.TwoStrokeHeatExchangerHotWaterSystem,
  left: 1120,
  top: 780,
  configured: false,
  systemInfo: {
    T_PF_present: true,
    T_PR_present: true,
    Control_enabled: true,
    T_CR_present: true,
    P_connected: true,
    P_type: 'Magna3'
  }
};

export const SAMPLE_VALUES_TWO_STROKE_HEAT_EXCHANGER_HOT_WATER: SampleEquipmentValue[] = [
  {
    systemType: SystemType.TwoStrokeHeatExchangerHotWaterSystem,
    type: EValueType.value,
    equipment: EEquipment.T_PF,
    value: '72.9\u2103',
  },
  {
    systemType: SystemType.TwoStrokeHeatExchangerHotWaterSystem,
    type: EValueType.value,
    equipment: EEquipment.T_PR,
    value: '33.7\u2103',
  },
  {
    systemType: SystemType.TwoStrokeHeatExchangerHotWaterSystem,
    type: EValueType.value,
    equipment: EEquipment.T_SF,
    value: '54.3\u2103',
  },
  {
    systemType: SystemType.TwoStrokeHeatExchangerHotWaterSystem,
    type: EValueType.value,
    equipment: EEquipment.T_CR,
    value: '50.3\u2103',
  },
  {
    systemType: SystemType.TwoStrokeHeatExchangerHotWaterSystem,
    type: EValueType.setPoint,
    equipment: EEquipment.T_SF,
    value: '55\u2103',
  },
  {
    systemType: SystemType.TwoStrokeHeatExchangerHotWaterSystem,
    type: EValueType.value,
    equipment: EEquipment.Q_PF,
    value: '10.3\u33a5/s',
  },
  {
    systemType: SystemType.TwoStrokeHeatExchangerHotWaterSystem,
    type: EValueType.value,
    equipment: EEquipment.MV_2W_PF,
    value: '13%',
  },
  {
    systemType: SystemType.TwoStrokeHeatExchangerHotWaterSystem,
    type: EValueType.valveIndication,
    equipment: EEquipment.MV_2W_PF,
    value: 'opening',
  },
  {
    systemType: SystemType.TwoStrokeHeatExchangerHotWaterSystem,
    type: EValueType.value,
    equipment: EEquipment.P_SR,
    value: '99.7\u33a5/h\n10.7m',
  }
];
