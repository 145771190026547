import { CONNECTION_SPACING } from '../../Common';
import { LabelPosition, SystemMeta } from '../../types';
import { EConnectionType, SystemType } from '../../../state';
import { EConnectionPointPlacement, EEquipment } from '../../../types';
import { FORWARD_X, RETURN_X, TOP } from '../../mixing-loop-common/common';
import { LargeLabelDeltas, SmallLabelDeltas } from '../../../util/constants';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/mixingloop.svg';
import { FLOWMETER_DISTANCE_VERTICAL } from '../../heat-meter-or-sensors/heat-meter-or-sensors-metadata';
import mixingLoopSystemForm from '../../../../models/systems/mixingloopUnspecifiedLoadSystem/mixingloopUnspecifiedLoadSystemForm.json';
import { IMixingloopUnspecifiedLoadSystemFormInterface } from '../../../../models/systems/mixingloopUnspecifiedLoadSystem/mixingloopUnspecifiedLoadSystemFormInterface';

export const HEIGHT = 420;
export const WIDTH = 300;
export const WARM_TO_COLD_Y = 150 + TOP;
export const PUMP_Y = WARM_TO_COLD_Y - 75;
export const SF_TEMP_SENSOR_Y = WARM_TO_COLD_Y - 35;
export const TWO_WAY_VALVE_Y = WARM_TO_COLD_Y + 30;
export const HEAT_METER_Y = TWO_WAY_VALVE_Y + 30;
export const FLOWMETER_Y = HEAT_METER_Y + FLOWMETER_DISTANCE_VERTICAL;
export const PIPE_TOP = 0;

export const mixingLoopUnspecifiedLoadMeta: SystemMeta = {
  systemType: SystemType.MixingloopUnspecifiedLoadSystem,
  name: 'Mixing Loop Unspecified Load',
  thumbnail,
  width: WIDTH,
  height: HEIGHT,
  form: mixingLoopSystemForm,
  calculateConnectionPoints: () => [
    {
      key: EConnectionPointPlacement.connectionPrimaryForward,
      x: FORWARD_X,
      y: HEIGHT - CONNECTION_SPACING,
      type: EConnectionType.supply,
      dotted: false,
    },
    {
      key: EConnectionPointPlacement.connectionPrimaryReturn,
      x: RETURN_X,
      y: HEIGHT,
      type: EConnectionType.return,
      dotted: true,
    },
    {
      key: EConnectionPointPlacement.connectionSecondaryReturn,
      x: RETURN_X,
      y: PIPE_TOP,
      type: EConnectionType.return,
      dotted: true,
    },
    {
      key: EConnectionPointPlacement.connectionSecondaryForward,
      x: FORWARD_X,
      y: PIPE_TOP,
      type: EConnectionType.supply,
      dotted: false,
    },
  ],
};

export const getMixingLoopUnspecifiedLoadLabels = (systemInfo: IMixingloopUnspecifiedLoadSystemFormInterface): LabelPosition[] => {
  const sfLabel = [
    {
      id: EEquipment.T_SF,
      x: FORWARD_X + LargeLabelDeltas.NORTH_X,
      y: SF_TEMP_SENSOR_Y,
    },
  ];
  const srLabel = systemInfo.T_SR_present
    ? [
        {
          id: EEquipment.T_SR,
          x: RETURN_X + LargeLabelDeltas.SOUTH_X,
          y: SF_TEMP_SENSOR_Y,
        },
      ]
    : [];
  const pfLabel =
    systemInfo.HM_present || systemInfo.T_PF_present
      ? [
          {
            id: EEquipment.T_PF,
            x: FORWARD_X + LargeLabelDeltas.NORTH_X,
            y: HEAT_METER_Y,
          },
        ]
      : [];

  const prLabel =
    systemInfo.HM_present || systemInfo.T_PR_present
      ? [
          {
            id: EEquipment.T_PR,
            x: RETURN_X + LargeLabelDeltas.SOUTH_X,
            y: HEAT_METER_Y,
          },
        ]
      : [];

  const pfFlowLabel =
    systemInfo.HM_location === 'PrimaryForward'
      ? [
          {
            id: EEquipment.Q_PF,
            x: FORWARD_X + SmallLabelDeltas.NORTH_X,
            y: FLOWMETER_Y,
          },
        ]
      : [];

  const prFlowLabel =
    systemInfo.HM_location === 'PrimaryReturn'
      ? [
          {
            id: EEquipment.Q_PR,
            x: RETURN_X + SmallLabelDeltas.SOUTH_X,
            y: FLOWMETER_Y,
          },
        ]
      : [];

  const threewaySupplyValve =
    systemInfo.MV_valveLocation === 'PrimaryForward' && systemInfo.MV_valveType === 'ThreeWayValve'
      ? [
          {
            id: EEquipment.MV_3W_PF,
            x: FORWARD_X - LargeLabelDeltas.SOUTH_X,
            y: WARM_TO_COLD_Y,
            horizontalAlignment: 'end',
          },
        ]
      : [];

  const threewayReturnValve =
    systemInfo.MV_valveLocation === 'PrimaryReturn' && systemInfo.MV_valveType === 'ThreeWayValve'
      ? [
          {
            id: EEquipment.MV_3W_PR,
            x: RETURN_X + LargeLabelDeltas.SOUTH_X,
            y: WARM_TO_COLD_Y,
          },
        ]
      : [];

  const twoWaySupplyValve =
    systemInfo.MV_valveLocation === 'PrimaryForward' && systemInfo.MV_valveType === 'TwoWayValve'
      ? [
          {
            id: EEquipment.MV_2W_PF,
            x: FORWARD_X - LargeLabelDeltas.SOUTH_X,
            y: TWO_WAY_VALVE_Y,
            horizontalAlignment: 'end',
          },
        ]
      : [];

  const twoWayReturnValve =
    systemInfo.MV_valveLocation === 'PrimaryReturn' && systemInfo.MV_valveType === 'TwoWayValve'
      ? [
          {
            id: EEquipment.MV_2W_PR,
            x: RETURN_X + LargeLabelDeltas.SOUTH_X,
            y: TWO_WAY_VALVE_Y,
          },
        ]
      : [];

  const sfPump =
    systemInfo.P_location === 'SecondaryForward'
      ? [
          {
            id: EEquipment.P_SF,
            x: FORWARD_X + SmallLabelDeltas.NORTH_X,
            y: PUMP_Y,
          },
        ]
      : [];

  const srPump =
    systemInfo.P_location === 'SecondaryReturn'
      ? [
          {
            id: EEquipment.P_SR,
            x: RETURN_X + SmallLabelDeltas.SOUTH_X,
            y: PUMP_Y,
          },
        ]
      : [];

  return [
    ...sfLabel,
    ...srLabel,
    ...pfLabel,
    ...prLabel,
    ...pfFlowLabel,
    ...prFlowLabel,
    ...threewaySupplyValve,
    ...threewayReturnValve,
    ...twoWaySupplyValve,
    ...twoWayReturnValve,
    ...sfPump,
    ...srPump,
  ];
};
