import { Component } from '@angular/core';
import { Installation } from '../../interfaces/facilty';
import { PageInfoService } from '../../services/page-info.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AlarmOverview } from '../../interfaces/alarm';
import { PageInfo } from 'shared';
import { FacilityService } from '../../services/facility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SchematicsService } from '../../services/schematics.service';
import { UserTrackingHelperService } from '../../services/user-tracking-helper.service';
import { TabComponent } from '../../components/tab/tab.component';

@Component({
  selector: 'app-system-status-view-page',
  templateUrl: './system-status-view-page.component.html',
  styleUrls: ['./system-status-view-page.component.scss']
})
export class SystemStatusViewPageComponent {
  public pageInfo$: Observable<PageInfo>;

  public installation$: Observable<Installation>;

  public notClearedFilter: (alarm: AlarmOverview) => boolean = alarm => {
    return !alarm.currentActivation.clearedTimestampEpoch;
  };

  public clearedFilter: (alarm: AlarmOverview) => boolean = alarm => {
    return !!alarm.currentActivation.clearedTimestampEpoch;
  };

  constructor(
    private facilityService: FacilityService,
    private schematicService: SchematicsService,
    private pageInfoService: PageInfoService,
    private router: Router,
    private route: ActivatedRoute,
    private userTrackingHelperService: UserTrackingHelperService
  ) {
    this.pageInfo$ = combineLatest([this.facilityService.getCurrentFacility(this.route), this.facilityService.getCurrentInstallation(this.route), this.schematicService.getFirmwareVersion()]).pipe(
      map(([facility, installation, firmwareVersion]) => this.pageInfoService.systemStatus(facility, installation, firmwareVersion)),
    );
  }

  tabSelected(selectedTab: TabComponent, trackingPrefix: string) {
    this.userTrackingHelperService.trackUserAction('showAllNotificationsShown', `${trackingPrefix}Clicked`);
  }

  alarmClicked($event: any, trackingPrefix: string) {
    this.router.navigate(['alarm', $event.id], {relativeTo: this.route});
    this.userTrackingHelperService.trackUserAction(`${trackingPrefix}Clicked`, 'notificationClicked');
  }
}
