<gbc-tile [title]="'add-alarm-comment-tile.title' | translate">
  <div class="form-container">
    <form (ngSubmit)="onSubmit()">
      <textarea gbc-textarea name="comment"
                [placeholder]="'add-alarm-comment-tile.placeholder' | translate"
                [(ngModel)]="comment"></textarea>
      <button gbc-button style="width: 163px;" type="submit"
      angulartics2On="click"
      angularticsAction="postCommentClicked"
      angularticsCategory="notificationClicked">
    {{'add-alarm-comment-tile.post-comment' | translate}}</button>
    </form>
  </div>
</gbc-tile>
