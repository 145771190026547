<gbc-page-error-handler [content]="content" [pageError$]="pageError$"></gbc-page-error-handler>

<ng-template #content>
  <ng-container *ngIf="user$ | async as user">
    <gbc-page-header *ngIf="pageInfo$ | async as pageInfo" [pageInfo]="pageInfo">
      <ng-container gbc-page-header-actions *ngIf="menuPresent$ | async">
        <button gbc-menu-item (click)="editUser(user.id)">
          <img class="icon" style="margin-right: 10px" src="./assets/svgs/edit.svg" />
          {{ 'view-user-page.edit-user' | translate }}
        </button>
        <button gbc-menu-item *ngIf="menuPresent$ | async" (click)="deleteUser(user.id)">
          <img class="icon" style="margin-right: 10px" src="./assets/svgs/trash-can_outline.svg" />
          {{ 'view-user-page.delete-user' | translate }}
        </button>
      </ng-container>
    </gbc-page-header>

    <gbc-page-container data-test-id="view-user">
      <gbc-tile [title]="'view-user-page.user-information' | translate">
        <gbc-table>
          <tr gbc-table-row>
            <td>
              <gbc-input-container>
                <gbc-input-label>{{ 'view-user-page.name' | translate }}</gbc-input-label>
                <gbc-input-field disabled="true" [(ngModel)]="user.name" name="name"></gbc-input-field>
              </gbc-input-container>
            </td>
          </tr>
          <tr gbc-table-row>
            <td>
              <gbc-input-container>
                <gbc-input-label>{{ 'view-user-page.email' | translate }}</gbc-input-label>
                <gbc-input-field disabled="true" [(ngModel)]="user.email" name="email"></gbc-input-field>
              </gbc-input-container>
            </td>
          </tr>
          <tr gbc-table-row>
            <td>
              <gbc-input-container>
                <gbc-input-label>{{ 'view-user-page.role' | translate }}</gbc-input-label>
                <gbc-input-field disabled="true" [ngModel]="translatedRole$ | async" name="role"></gbc-input-field>
                <app-user-role-explainer-toggle-link></app-user-role-explainer-toggle-link>
              </gbc-input-container>
            </td>
          </tr>
        </gbc-table>
      </gbc-tile>
      <gbc-tile [title]="'view-user-page.assigned-to' | translate">
        <app-facility-list [facilities]="facilitiesForUser$ | async"></app-facility-list>
      </gbc-tile>
    </gbc-page-container>
  </ng-container>
</ng-template>
