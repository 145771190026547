import { Pipe, PipeTransform } from '@angular/core';
import { formatHoursMinutes } from '../utils/date-time-utils';

@Pipe({
  name: 'formatHoursMinutes'
})
export class FormatHoursMinutesPipe implements PipeTransform {

  transform(value: string | null): string {
    if (!value) {
      return '-';
    }

    return formatHoursMinutes(value);
  }

}
