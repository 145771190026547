<div class="base">
  <input
    [min]="min"
    [max]="max"
    [ngClass]="{ invalid: !valid }"
    [disabled]="disabled"
    type="{{ inputType }}"
    [(ngModel)]="value"
    #filterInputField
    class="app-input"
    [attr.name]="formControlName"
    [placeholder]="placeholder"
    (keyup)="onKey(filterInputField.value)"
  />
</div>
