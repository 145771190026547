import React from 'react';
import { SvgImage } from '../SvgImage';
import { Pipe, PipeType } from '../Pipe';
import { CONNECTION_SPACING } from '../Common';
import radiantHeatingPanelsIcon from '!raw-loader!../../../../assets/icons/radiant-heating-panels.svg';

const RADIANT_HEATING_PANELS_SVG_WIDTH = 54;
const RADIANT_HEATING_PANELS_SVG_HEIGHT = 33;
const ICON_LEFT = CONNECTION_SPACING / 2 - RADIANT_HEATING_PANELS_SVG_WIDTH / 2;

export const FORWARD_Y = 1;
export const RETURN_Y = RADIANT_HEATING_PANELS_SVG_HEIGHT - 1;
// The Y-value of the left hand pipe, when in right and left config
export const RADIANT_HEATING_PANELS_LEFT_PIPE_Y_LEFT = RADIANT_HEATING_PANELS_SVG_HEIGHT - FORWARD_Y;
export const RADIANT_HEATING_PANELS_LEFT_PIPE_Y_RIGHT = FORWARD_Y;
// The Y-value of the right hand pipe, when in right and left config
export const RADIANT_HEATING_PANELS_RIGHT_PIPE_Y_LEFT = RADIANT_HEATING_PANELS_SVG_HEIGHT - RETURN_Y;
export const RADIANT_HEATING_PANELS_RIGHT_PIPE_Y_RIGHT = RETURN_Y;

export const RadiantHeatingPanels: React.FC<{ x: number; y: number; reversedWarmCold: boolean }> = ({ x, y, reversedWarmCold }) => {
  const leftPipeY = reversedWarmCold ? RADIANT_HEATING_PANELS_LEFT_PIPE_Y_LEFT : RADIANT_HEATING_PANELS_LEFT_PIPE_Y_RIGHT;
  const rightPipeY = reversedWarmCold ? RADIANT_HEATING_PANELS_RIGHT_PIPE_Y_LEFT : RADIANT_HEATING_PANELS_RIGHT_PIPE_Y_RIGHT;
  const radingHeatingPanelsLeft = reversedWarmCold ? RADIANT_HEATING_PANELS_SVG_WIDTH + ICON_LEFT : ICON_LEFT;
  return (
    <g transform={`translate(${x} ${y})`}>
      <Pipe x1={0} y1={leftPipeY} x2={ICON_LEFT + 4} y2={leftPipeY} type={reversedWarmCold ? PipeType.ColdReturn : PipeType.WarmSupply} />
      <Pipe
        x1={CONNECTION_SPACING}
        y1={rightPipeY}
        x2={ICON_LEFT + RADIANT_HEATING_PANELS_SVG_WIDTH - 2}
        y2={rightPipeY}
        type={reversedWarmCold ? PipeType.WarmSupply : PipeType.ColdReturn}
      />
      <SvgImage
        svgText={radiantHeatingPanelsIcon}
        transforms={[`scale(${reversedWarmCold ? -1 : 1}, 1)`]}
        x={radingHeatingPanelsLeft}
        y={0}
      />
    </g>
  );
};
