import {  Component, OnDestroy, OnInit } from '@angular/core';
import { AppError, AppErrorService } from 'shared';
import { Observable } from 'rxjs';
import { Facility } from '../../interfaces/facilty';
import { ActivatedRoute } from '@angular/router';
import { EFacilityType } from '../../../../../serviceportal/src/app/interfaces/facility';
import { first, map } from 'rxjs/operators';
import { FacilityService } from '../../services/facility.service';


@Component({
  selector: 'app-facility-page',
  templateUrl: './facility-page.component.html',
  styleUrls: ['./facility-page.component.scss'],
})
export class FacilityPageComponent implements OnInit {
  public facility$: Observable<Facility>;
  public pageError$: Observable<AppError>;
  public isMixit$: Observable<boolean>;
  public isBuildingConnect$: Observable<boolean>;
  public isConnectingCore$: Observable<boolean>;

  constructor(
    private errorService: AppErrorService,
    public route: ActivatedRoute,
    private facilityService: FacilityService
  ) { }

  ngOnInit() {
    this.facility$ = this.facilityService.getCurrentFacility(this.route);
    this.pageError$ = this.errorService.createPageErrorObservable([this.facility$]);

    this.isMixit$ = this.facility$.pipe(
      map((f) => {
        return f.facilityType === EFacilityType.MIXIT;
      })
    );

    this.isBuildingConnect$ = this.facility$.pipe(
      map((f) => {
        return f.facilityType === EFacilityType.BuildingConnect;
      })
    );

    this.isConnectingCore$ = this.facility$.pipe(
      map((f) => {
        return f.facilityType === EFacilityType.ConnectingCore;
      })
    );
  }
}
