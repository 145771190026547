import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'gbc-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss']
})
export class TileComponent {

  @Input()
  public title: string;

}
