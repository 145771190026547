import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';

export interface FilterOption<T> {
  name: Observable<string>;
  value: T;
}

@Component({
  selector: 'gbc-tile-filter-tab',
  templateUrl: './tile-filter-tab.component.html',
  styleUrls: ['./tile-filter-tab.component.scss']
})
export class TileFilterTabComponent<T> implements OnInit {

  @Input()
  initialValue: T;

  @Input()
  options: FilterOption<T>[];

  @Output()
  selectionChange = new EventEmitter<T>();

  selectedValue: T;

  select(value: T) {
    if (this.selectedValue !== value) {
      this.selectedValue = value;
      this.selectionChange.emit(value);
    }
  }

  constructor() {
  }

  ngOnInit(): void {
    this.selectedValue = this.initialValue || this.options[0].value;
  }

}
