<gbc-page-error-handler [content]="content" [pageError$]="pageError$"></gbc-page-error-handler>
<ng-template #content>
  <gbc-page-header [pageInfo]="pageInfo$ | async">
    <ng-container gbc-page-header-actions>
      <button gbc-menu-item
        (click)="editFacility()"
        angulartics2On="click"
        angularticsAction="editFacilityShown"
        angularticsCategory="mixitFacility">
        <img style="margin-right: 10px" src="./assets/svgs/edit.svg" />
        {{'mixit-facility-page.edit-facility' | translate}}
      </button>

      <button gbc-menu-item
        (click)="deleteFacility()"
        angulartics2On="click"
        angularticsAction="deleteFacilityClicked"
        angularticsCategory="mixitFacility">
        <img style="margin-right: 10px; width: 16px;" src="./assets/svgs/trash-can_outline.svg" />
        {{'mixit-facility-page.delete-facility' | translate}}
      </button>
    </ng-container>
  </gbc-page-header>
  <gbc-page-container>

    <div *ngFor="let alarm of mostImportantAlarmByInstallation$ | async | keyvalue">
      <app-alarm-bar
        [alarm]="alarm.value"
        showAlarmName="true">
      </app-alarm-bar>
    </div>

    <div class="warning-bar" *ngIf="(commissionState$ | async) === 'Error'">
      <img class="img" src="./assets/svgs/warning.svg" />
      <div class="connection-state-label" [innerHtml]="'mixit-device-states.error-for-device-to-connect' | translate"></div>
    </div>

    <gbc-banner type="info" *ngIf="islessThanHalfHourSinceClaim$ | async" class="mt-8">
      <div [innerHtml]="'mixit-device-states.waiting-for-device-to-connect' | translate"></div>
    </gbc-banner>

    <gbc-tile [title]="'mixit-facility-page.mixing-loops' | translate" class="schematics-tile" *ngIf="schematics$ | async as schematics">
      <div class="schematics-container">
        <div class="schematic-container" *ngFor="let installationAndSchematic of schematics">
          <div class="schematic-title-container">
            <div class="schematic-meta">
              <span class="schematic-license-label">
                <ng-container *ngIf="installationAndSchematic.isConnect && !installationAndSchematic.isDynamic">{{'schematic.connect' | translate}}</ng-container>
                <ng-container *ngIf="installationAndSchematic.isDynamic && !installationAndSchematic.isConnect">{{'schematic.dynamic' | translate}}</ng-container>
                <ng-container *ngIf="!installationAndSchematic.isDynamic && !installationAndSchematic.isConnect">{{'schematic.freemium' | translate}}</ng-container>
                <ng-container *ngIf="installationAndSchematic.isDynamic && installationAndSchematic.isConnect">{{'schematic.connect' | translate}} + {{'schematic.dynamic' | translate}}</ng-container>
              </span>
              <button gbc-text-button
                class="schematic-edit-button"
                type="button"
                (click)="editInstallation(installationAndSchematic.installation.id)"
                angulartics2On="click"
                angularticsAction="editInstallationShown"
                angularticsCategory="mixitFacility">
                {{'mixit-facility-page.edit' | translate}}</button>
            </div>
            <div class="schematic-title">
              <app-last-updated *ngIf="installationAndSchematic.appModel" [installationId]="installationAndSchematic.installation.id" [applicationId]="installationAndSchematic.appModel.systems[0].hasId" ></app-last-updated>
              <h4>
                {{installationAndSchematic.installation.name}}
              </h4>
            </div>
          </div>

          <app-schematic
            *ngIf="installationAndSchematic.appModel"
            (applicationClicked)="handleClick(installationAndSchematic.installation)"
            [installationId]="installationAndSchematic.installation.id"
            [schematic]="installationAndSchematic.appModel"
            [dataPoints]="(data$ | async)"
            [alarms]="[installationAndSchematic.alarm]"
            class="schematic"
            angulartics2On="click"
            angularticsAction="applicationClicked"
            angularticsCategory="mixitSchematic">
          </app-schematic>

          <p *ngIf="!installationAndSchematic.appModel" style="text-align: center;">{{'mixit-facility-page.schematic-not-found' | translate}}</p>
        </div>

        <div class="no-installations" *ngIf="schematics.length === 0 && (hasInstallations$ | async)">
          <gbc-loading-spinner></gbc-loading-spinner>
        </div>

        <div class="no-installations" *ngIf="(hasInstallations$ | async) === false">
          <p>{{'mixit-facility-page.no-installations' | translate}}</p>

          <button gbc-button
            [routerLink]="['/mixit', 'onboarding']"
            data-test-id="claim-device"
            angulartics2On="click"
            angularticsAction="claimDeviceClicked"
            angularticsCategory="mixitFacility">
            {{'mixit-facility-page.claim-device' | translate}}</button>
        </div>

      </div>
    </gbc-tile>

    <div class="tiles" *ngIf="showAverageTiles$ | async">
      <gbc-tile class="tile">
        <div class="facility-tile-content">
          <div class="tile-title">{{'mixit-facility-page.average-outdoor-temperature' | translate}}</div>
          <div class="tile-value">{{outDoorTemperature$ | async}}°</div>
        </div>
      </gbc-tile>

      <gbc-tile class="tile">
        <div class="facility-tile-content">
          <div class="tile-title">{{'mixit-facility-page.average-delta-t-primary' | translate}}</div>
          <div class="tile-value">{{averageDeltaT$ | async}}</div>
        </div>
      </gbc-tile>
    </div>

  </gbc-page-container>
</ng-template>
