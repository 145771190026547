import { Component } from '@angular/core';
import { Facility, Installation } from '../../interfaces/facilty';
import { PageInfoService } from '../../services/page-info.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ECommissionStatus, PageInfo } from 'shared';
import { AlarmIconLinkPipe } from '../../pipes/alarm-icon-link.pipe';
import { navigateToInstallation } from '../../utils/navigate-utils';
import { FacilityService } from '../../services/facility.service';
import { ConnectionState } from '../../interfaces/connectionState';
import { mapCommissionStatusToTranslationKey } from '../../utils/installation-utils';

interface InstallationViewModel {
  id: string;
  navigateTo: () => void;
  iconUrl: string;
  name: string;
  stateTextKey: string;
}

@Component({
  selector: 'app-buildingconnect-facility-page',
  templateUrl: './buildingconnect-facility-page.component.html',
  styleUrls: ['./buildingconnect-facility-page.component.scss']
})
export class BuildingconnectFacilityPageComponent {
  public facility$: Observable<Facility>;
  public pageInfo$: Observable<PageInfo>;
  public installationViewModels$: Observable<InstallationViewModel[]>;

  public navigateToInstallation(facility: Facility, installation: Installation) {
    navigateToInstallation(this.router, facility.id, installation.id);
  }

  constructor(
      private router: Router,
      private pageInfoService: PageInfoService,
      private alarmIconLinkPipe: AlarmIconLinkPipe,
      private route: ActivatedRoute,
      private facilityService: FacilityService,
  ) {
    this.facility$ = this.facilityService.getCurrentFacility(this.route);

    this.installationViewModels$ = this.facility$.pipe(
      map(facility => {
        return facility.installations
          .filter(i => !i.parentInstallationId)
          .sort((a, b) => {
            const installationA = a.name.toUpperCase();
            const installationB = b.name.toUpperCase();
            return (installationA < installationB) ? -1 : (installationA > installationB) ? 1 : 0;
          })
          .map(installation => {
            const iconUrl = this.getIconUrl(installation);
            const stateTextKey = this.getStateText(installation);

            return {
              id: installation.id,
              iconUrl,
              name: installation.name || '',
              stateTextKey,
              navigateTo: () => this.navigateToInstallation(facility, installation)
            };
          });
      })
    );
    this.pageInfo$ = this.facility$.pipe(
        map(facility => this.pageInfoService.facility(facility)),
        catchError(err => {
          console.log('caught error');
          return of({parent: pageInfoService.facilityMap(), title: of('Error'), subtitles: [of('')], link: ['/']});
        })
    );
  }

  getIconUrl(installation: Installation): string {
    const alarmType = installation.state?.type;
    const underCommissioning = installation.commissionStatus !== ECommissionStatus.Live;

    if (alarmType) {
      return this.alarmIconLinkPipe.transform(alarmType);
    }

    if (underCommissioning) {
      return './assets/svgs/list-commissioning.svg';
    }

    if (installation.connectionState === ConnectionState.Disconnected) {
      return './assets/svgs/offline-circle.svg';
    }

    return './assets/svgs/all-good.svg';
  }

  getStateText(installation: Installation): string {
    if (installation.connectionState === ConnectionState.Disconnected) {
      return 'building-connect-facility-page.offline';
    }

    return mapCommissionStatusToTranslationKey(installation.commissionStatus);
  }

  editFacility() {
    this.router.navigate(['/edit-facility', this.route.snapshot.params.facilityId]);
  }
}
