import { SystemType, DistrictHeatingSystemState, BoilerSystemState, CascadeTankSystemState } from '../state';
import { EEquipment, EValueType, IEquipmentValue, ISetPointValue, IValveOpenValue } from '../types';
import { SampleEquipmentValue } from './sample-equipment-values';
import { v4 } from 'uuid';

export const SAMPLE_CASCADE_TANK_SYSTEM: CascadeTankSystemState = {
  hasId: v4(),
  left: 1580,
  top: 780,
  configured: false,
  type: SystemType.CascadeTankSystem,
  systemInfo: {
    title: '',
    Control_enabled: true,
    HM_present: true,
    HM_location: 'PrimaryForward',
    HM_address: 4,
    P_type: 'Magna3',
    P_connected: true,
    MV_positionControl: 'ThreePointControl',
    MV_actuatorPower: '24_24-Voltage^DirectCurrent',
    T_1_MIDDLE_present: true,
    T_1_BOTTOM_present: true,
    T_2_TOP_present: true,
    T_2_MIDDLE_present: true,
    T_2_BOTTOM_present: true,
    Q_SC_present: true,
    Q_SC_address: 2,
    T_SR_sensortype: 'SensorPT100',
    T_1_TOP_sensortype: 'SensorPT100',
    T_1_MIDDLE_sensortype: 'SensorPT100',
    T_1_BOTTOM_sensortype: 'SensorPT100',
    T_2_TOP_sensortype: 'SensorPT100',
    T_2_MIDDLE_sensortype: 'SensorPT100',
    T_2_BOTTOM_sensortype: 'SensorPT100'
  }
};

export const SAMPLE_VALUES_CASCADE_TANK: SampleEquipmentValue[] = [
  {
    systemType: SystemType.CascadeTankSystem,
    type: EValueType.value,
    equipment: EEquipment.MV_2W_PR,
    value: '13%',
  },
  {
    systemType: SystemType.CascadeTankSystem,
    type: EValueType.valveIndication,
    equipment: EEquipment.MV_2W_PR,
    value: 'opening',
  },
  {
    systemType: SystemType.CascadeTankSystem,
    type: EValueType.value,
    equipment: EEquipment.P_SR,
    value: '99.7\u33a5/h\n10.7m',
  },
  {
    systemType: SystemType.CascadeTankSystem,
    type: EValueType.value,
    equipment: EEquipment.T_SR,
    value: '33.7\u2103',
  },
  {
    systemType: SystemType.CascadeTankSystem,
    type: EValueType.value,
    equipment: EEquipment.Q_SC,
    value: '0.15\u33a5/s',
  },
  {
    systemType: SystemType.CascadeTankSystem,
    type: EValueType.value,
    equipment: EEquipment.T_1_TOP,
    value: '32.7\u2103',
  },
  {
    systemType: SystemType.CascadeTankSystem,
    type: EValueType.value,
    equipment: EEquipment.T_1_MIDDLE,
    value: '30.7\u2103',
  },
  {
    systemType: SystemType.CascadeTankSystem,
    type: EValueType.value,
    equipment: EEquipment.T_1_BOTTOM,
    value: '28.7\u2103',
  },
  {
    systemType: SystemType.CascadeTankSystem,
    type: EValueType.value,
    equipment: EEquipment.T_2_TOP,
    value: '37.7\u2103',
  },
  {
    systemType: SystemType.CascadeTankSystem,
    type: EValueType.value,
    equipment: EEquipment.T_2_MIDDLE,
    value: '35.7\u2103',
  },
  {
    systemType: SystemType.CascadeTankSystem,
    type: EValueType.value,
    equipment: EEquipment.T_2_BOTTOM,
    value: '32.7\u2103',
  },
  {
    systemType: SystemType.CascadeTankSystem,
    type: EValueType.value,
    equipment: EEquipment.T_PF,
    value: '68.7\u2103',
  },
  {
    systemType: SystemType.CascadeTankSystem,
    type: EValueType.value,
    equipment: EEquipment.T_PR,
    value: '24.7\u2103',
  },
  {
    systemType: SystemType.CascadeTankSystem,
    type: EValueType.value,
    equipment: EEquipment.Q_PF,
    value: '10.3\u33a5/s',
  },
  {
    systemType: SystemType.CascadeTankSystem,
    type: EValueType.value,
    equipment: EEquipment.Q_PR,
    value: '10.3\u33a5/s',
  },
];
