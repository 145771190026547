<gbc-modal [title]="'alarm-override-modal.title' | translate" (dismiss)="dismiss()">
  <form (ngSubmit)="onSubmit()">
    <gbc-modal-content>
      <div class="form-content">
        <span class="thequestion">{{'alarm-override-modal.question' | translate}}</span>
        <label for="comment">{{'alarm-override-modal.comment-label' | translate}}</label>
        <textarea
          gbc-textarea
          rows="4"
          id="overridecomment"
          name="overridecomment"
          [(ngModel)]="comment"
          placeholder="{{'alarm-override-modal.comment-placeholder' | translate}}">
        </textarea>
      </div>
    </gbc-modal-content>
    <gbc-modal-buttons>
      <button gbc-modal-button type="submit"
        angulartics2On="click"
        angularticsAction="acknowledgeNotificationClicked"
        angularticsCategory="acknowledgeClicked">
      {{'alarm-override-modal.enable-button' | translate}}</button>
    </gbc-modal-buttons>
  </form>
</gbc-modal>
