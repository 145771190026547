<gbc-tile [title]="'mixit-warm-weather-shutdown-tile.warm-weather-shutdown' | translate">
  <button gbc-icon-button gbc-tile-right
    (click)="adjustWarmWeatherShutdown()"
    angulartics2On="click"
    angularticsAction="adjustWarmWeatherShutdownShown"
    angularticsCategory="mixitSchematicClicked">
    <img [alt]="'mixit-warm-weather-shutdown-tile.adjust-warm-weather-shutdown' | translate" src="./assets/svgs/adjust-outline-black.svg">
  </button>
  <div class="tile-content">

    <div class="tile-row" [ngClass]="{disabled: (warmWeatherShutdownEnabled$ | async) === false}">
      <div class="tile-row-name">{{'mixit-warm-weather-shutdown-tile.outdoor-threshold' | translate}}:</div>
      <div class="tile-row-value">{{outdoorThreshold$ | async | formatDataPoint}}</div>
    </div>

    <div class="tile-row" [ngClass]="{disabled: (warmWeatherShutdownEnabled$ | async) === false}">
      <div class="tile-row-name">{{'mixit-warm-weather-shutdown-tile.average-period' | translate}}:</div>
      <div class="tile-row-value">{{days$ | async}}</div>
    </div>

  </div>
</gbc-tile>
