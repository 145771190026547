import { InputEventType, InputStrategy } from './input-strategy-service';
import { setConnectionPointPosition, stopDraggingConnectionPoint } from '../actions';

export const draggingConnectionPointStrategy: InputStrategy = {
  name: 'DraggingConnectionPoint',
  handleEvent(dispatch, state, event) {
    switch (event.type) {
      case InputEventType.MouseMove: {
        if (!state.connectionPointBeingDragged) {
          throw new Error('Invalid state: ConnectionBeingDragged input handler but no connection is being dragged');
        }
        dispatch(
          setConnectionPointPosition({
            id: state.connectionPointBeingDragged.id,
            x: event.x,
            y: event.y,
          })
        );
        return;
      }
      case InputEventType.MouseUp: {
        if (!state.connectionPointBeingDragged) {
          throw new Error('Invalid state: ConnectionBeingDragged input handler but no connection is being dragged');
        }
        dispatch(stopDraggingConnectionPoint());
        return;
      }
    }
  },
  render: () => [],
};
