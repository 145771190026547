import { Component } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[gbc-topbar-dropdown-item]',
  templateUrl: './topbar-dropdown-item.component.html',
  styleUrls: ['./topbar-dropdown-item.component.scss']
})
export class TopbarDropdownItemComponent {
}
