import { SystemType } from '../../../state';
import { EEquipment } from '../../../types';
import { LabelPosition, SystemMeta } from '../../types';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/outdoor.svg';
import outdoorTemperatureSystemForm from '../../../../models/systems/outdoorTemperatureSystem/outdoorTemperatureSystemForm.json';

export const WIDTH = 180;
export const HEIGHT = 140;
export const ICON_X = 52;
export const ICON_Y = 60;
export const LABEL_X = 80;
export const LABEL_Y = 80;

export const outdoorTemperatureMeta: SystemMeta = {
  systemType: SystemType.OutdoorTemperature,
  name: 'Outdoor Temperature',
  thumbnail,
  width: WIDTH,
  height: HEIGHT,
  form: outdoorTemperatureSystemForm,
  calculateConnectionPoints: () => [],
};

export const getOutdoorTemperatureLabels = (): LabelPosition[] => {
  return [
    {
      id: EEquipment.T_OUTDOOR,
      x: LABEL_X,
      y: LABEL_Y,
    },
  ];
};
