import { Component, OnInit, Inject } from '@angular/core';
import { IInstallationUpgradeRequest, EUpgradeResultStatusCodes, EUpgradeResultErrorCodes, IUpgradeRequestResult } from '../../interfaces/premium';
import { MODAL_CONTROLLER, ModalController, MODAL_DATA } from 'shared';
import { startWith } from 'rxjs/operators';

type ResultStatus = EUpgradeResultStatusCodes.ERROR | EUpgradeResultStatusCodes.PENDING | EUpgradeResultStatusCodes.SUCCESS;

export interface IMixitUpgradeStatus {
  status: ResultStatus;
  message?: string;
  requests?: IInstallationUpgradeRequest[];
}

export interface IInstallationUpgradeStatus {
  id: string;
  status: ResultStatus;
  name: string;
  errorCode?: EUpgradeResultErrorCodes;
}

export type UpgradeResult = 'error' | 'success';

@Component({
  selector: 'app-mixit-upgrade-status-modal',
  templateUrl: './mixit-upgrade-status-modal.component.html',
  styleUrls: ['./mixit-upgrade-status-modal.component.scss'],
})
export class MixitUpgradeStatusModalComponent implements OnInit {
  public installationRequests: IInstallationUpgradeRequest[];
  public installationRequestResults: IInstallationUpgradeStatus[] = [];
  public error: string;
  public status = EUpgradeResultStatusCodes;
  public currentRequest: IInstallationUpgradeRequest | null;
  public currentStatus: IUpgradeRequestResult | null;
  public requestErrors = false;
  public requestsCompleted = false;

  constructor(
    @Inject(MODAL_DATA) private data: IMixitUpgradeStatus,
    @Inject(MODAL_CONTROLLER) private controller: ModalController<IInstallationUpgradeStatus[]>
  ) {}

  ngOnInit(): void {
    if (this.data.status === EUpgradeResultStatusCodes.ERROR) {
      this.error = this.data.message as string;
    } else if (this.data.requests) {
      this.installationRequests = this.data.requests;
      this.nextRequest();
    }
  }

  addRequest(id: string, name: string, status: EUpgradeResultStatusCodes, errorCode?: EUpgradeResultErrorCodes) {
    const req = { id, name, status, errorCode };
    const reqIdx = this.installationRequestResults.findIndex((r) => req.id === r.id);
    if (reqIdx > -1) {
      this.installationRequestResults.splice(reqIdx, 1, req);
    } else {
      this.installationRequestResults.unshift(req);
    }
  }

  nextRequest() {
    this.currentRequest = this.installationRequests.shift() as IInstallationUpgradeRequest;
    const currentRequest = this.currentRequest as IInstallationUpgradeRequest;
    this.currentRequest.request.pipe(startWith({ status: EUpgradeResultStatusCodes.PENDING })).subscribe(
      (value: IUpgradeRequestResult) => {
        this.currentStatus = value;
        if (value.status === EUpgradeResultStatusCodes.ERROR) {
          this.requestErrors = true;
          this.addRequest(
            currentRequest.installationId,
            currentRequest.installationName,
            value.status,
            value.code === null ? EUpgradeResultErrorCodes.UnknownError : value.code
          );
        } else if (value.status === EUpgradeResultStatusCodes.SUCCESS) {
          this.addRequest(currentRequest.installationId, currentRequest.installationName, value.status);
        } else {
          this.addRequest(currentRequest.installationId, currentRequest.installationName, value.status);
        }
      },
      (e) => {},
      () => {
        if (this.installationRequests.length > 0) {
          this.nextRequest();
        } else {
          this.requestsCompleted = true;
          this.currentStatus = null;
          this.currentRequest = null;
        }
      }
    );
  }

  onDone() {
    if (this.requestErrors) {
      this.controller.dismiss();
    } else {
      this.controller.complete(this.installationRequestResults);
    }
  }
}
