import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModalController, MODAL_CONTROLLER, MODAL_DATA } from 'shared';
import { EnergyDashboardSettingsDto } from '../../../interfaces/installation';
import { UserTrackingHelperService } from '../../../services/user-tracking-helper.service';

@Component({
  selector: 'app-energy-management-dashboard-settings-modal',
  templateUrl: './energy-management-dashboard-settings-modal.component.html',
  styleUrls: ['./energy-management-dashboard-settings-modal.component.scss']
})
export class EnergyManagementDashboardSettingsModalComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  public form: UntypedFormGroup;
  public isValid$: Observable<boolean>;
  public title: string;

  constructor(
    @Inject(MODAL_DATA) public data: EnergyDashboardSettingsDto,
    @Inject(MODAL_CONTROLLER) private controller: ModalController<EnergyDashboardSettingsDto>,
    private userTrackingHelperService: UserTrackingHelperService
  ) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      emissionFactor: new UntypedFormControl(this.data.emissionFactor, [Validators.min(0)]),
      suppliedArea: new UntypedFormControl(this.data.suppliedArea, [Validators.min(0)]),
      lastYearDeltaT: new UntypedFormControl(this.data.lastYearDeltaT, [Validators.min(0)]),
      lastYearEnergyConsumption: new UntypedFormControl(this.data.lastYearEnergyConsumption, [Validators.min(0)]),
      deltaTPenaltyFactor: new UntypedFormControl(this.data.deltaTPenaltyFactor, [Validators.min(0)]),
    });

    if (this.data.showTankVolume) {
      this.form.addControl("tankVolume", new UntypedFormControl(this.data.tankVolume, [Validators.min(0)]));
    }

    this.subscription.add(this.userTrackingHelperService.startFormValueChangeTracking('edbSettingsClicked', this.form));

    this.isValid$ = this.form.valueChanges.pipe(
      map(value => {
        return this.form.valid;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  close() {
    this.controller.dismiss();
  }

  save() {
    this.controller.complete({
      ...this.form.value
    });
  }

  dismiss() {
    this.userTrackingHelperService.trackUserAction('edbSettingsClicked', 'exitClicked');
  }
}
