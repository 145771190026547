<ng-container *ngIf="facility$ | async as facility">
  <ng-container *ngIf="installation$ | async as installation">
    <gbc-table [clickableRows]="clickable" [ngStyle]="{'min-height': minHeight}" *ngIf="visible$ | async; else noalarmtypes" data-test-id="alarm-settings-table">
      <th gbc-table-header>{{ 'alarm-settings-table.table-header-alarm' | translate }}</th>
      <th gbc-table-header>{{ 'alarm-settings-table.table-header-application' | translate }}</th>

      <tr gbc-table-row *ngFor="let info of alarmSettingsInfo$ | async" (rowClicked)="settingsClick.emit(info)">
        <td gbc-table-cell>{{ info.alarmSettings.errorCode + ': ' + info.alarmTitle }}</td>
        <td gbc-table-cell>{{ info.applicationTitle }}</td>
      </tr>
    </gbc-table>
    <ng-template #noalarmtypes>
      <div class="container-empty" [ngStyle]="{'min-height': minHeight}">
        <div>{{ noAlarmSettingsMessage }}</div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
