import { Component, Input, OnInit } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { DataPointsResult, DeviceDataPoint, EBackendUnit } from '../../../interfaces/data-points';
import { formatDataPoint, parseBoolean } from '../../../utils/data-point-utils';
import { first, map, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { getDatapoint } from '../../../utils/mixit-utils';
import { MixitDataPointName } from '../../../interfaces/mixit';
import { Installation } from '../../../interfaces/facilty';
import { AppModel } from '@ams/dumbledore';
import { Application } from '../../../interfaces/alarm';
import { EquipmentMetaInfo } from '../../../services/installation-configuration.service';
import { MixitWarmWeatherShutdownModalComponent, MixitWarmWeatherShutdownResponse, MixitWarmWeatherShutdownSettings } from '../mixit-warm-weather-shutdown-modal/mixit-warm-weather-shutdown-modal.component';
import { ICommand, MixitService } from '../../../services/mixit-service.service';
import { SchematicsService } from '../../../services/schematics.service';
import { ModalService } from 'shared';
import { SystemDeviceType } from '../../../interfaces/systemDeviceType';

@Component({
  selector: 'app-mixit-warm-weather-shutdown-tile',
  templateUrl: './mixit-warm-weather-shutdown-tile.component.html',
  styleUrls: ['./mixit-warm-weather-shutdown-tile.component.scss'],
})
export class MixitWarmWeatherShutdownTileComponent implements OnInit {
  @Input() installation$: Observable<Installation>;
  @Input() schematic$: Observable<AppModel>;
  @Input() dataPoints$: Observable<DataPointsResult>;
  @Input() application$: Observable<Application>;

  public warmWeatherShutdownEnabled$: Observable<boolean>;
  public outdoorThreshold$: Observable<DeviceDataPoint | null>;
  public averagePeriod$: Observable<DeviceDataPoint | null>;
  public days$: Observable<number>;

  private isAdjustmentInProgress = false;

  constructor(
    private translateService: TranslateService,
    private schematicService: SchematicsService,
    private modalService: ModalService,
    private mixitService: MixitService
  ) {}

  ngOnInit(): void {
    this.warmWeatherShutdownEnabled$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.OutdoorTemperatureDependentOperationEnable
    ).pipe(map((d) => parseBoolean(d?.value)));
    this.outdoorThreshold$ = getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.TemperatureThreshold);
    this.averagePeriod$ = getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.AveragingPeriod);

    this.days$ = this.averagePeriod$.pipe(
      map((d) => {
        return this.translateService.instant('mixit-warm-weather-shutdown-tile.days', { numberOfDays: formatDataPoint(d) });
      })
    );
  }

  public adjustWarmWeatherShutdown() {
    if (this.isAdjustmentInProgress) { return; }
    this.isAdjustmentInProgress = true;

    const thresholdMeta$ = this.application$.pipe(
      map((application) => application.id),
      switchMap((id) => this.schematicService.getEquipmentMetaInfo(id)),
      map((metas) => {
        return metas.find((m) => m.term === MixitDataPointName.TemperatureThreshold);
      })
    );

    combineLatest([
      this.installation$,
      this.application$,
      this.warmWeatherShutdownEnabled$,
      this.outdoorThreshold$,
      this.averagePeriod$,
      thresholdMeta$,
    ])
      .pipe(first())
      .subscribe(
        ([installation, application, warmWeatherShutdownEnabled, outdoorThreshold, averagePeriod, thresholdMeta]: [
          Installation,
          Application,
          boolean,
          DeviceDataPoint | null,
          DeviceDataPoint | null,
          EquipmentMetaInfo | undefined
        ]) => {
          const data: MixitWarmWeatherShutdownSettings = {
            enabled: warmWeatherShutdownEnabled,
            threshold: parseFloat(outdoorThreshold?.value as string) || null,
            thresholdMeta: thresholdMeta as EquipmentMetaInfo,
            averagePeriod: parseFloat(averagePeriod?.value as string) || null,
          };

          this.modalService
            .openDialog<MixitWarmWeatherShutdownResponse>(MixitWarmWeatherShutdownModalComponent, {
              data,
            })
            .subscribe((response) => {
              if (response.dismissed) {
                this.isAdjustmentInProgress = false;
                return;
              }

              const res = response.result;

              const averagePeriodAsSeconds = res.averagePeriod ? res.averagePeriod * 24 * 3600 : null;

              const commands: ICommand[] = [
                {
                  term: MixitDataPointName.OutdoorTemperatureDependentOperationEnable,
                  value: res.enabled?.toString() || null,
                  unit: EBackendUnit.None,
                },
                {
                  term: MixitDataPointName.TemperatureThreshold,
                  value: res.threshold?.toString() || null,
                  unit: EBackendUnit.DegreeCelsius,
                },
                {
                  term: MixitDataPointName.AveragingPeriod,
                  value: averagePeriodAsSeconds?.toString() || null,
                  unit: EBackendUnit.Second,
                },
              ];

              this.mixitService.sendCommand(installation, application.id, commands);
              this.isAdjustmentInProgress = false;
            });
        }
      );
  }
}
