import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SystemControlMode } from '../../interfaces/system-control-mode';

@Component({
  selector: 'app-control-state-toggle',
  templateUrl: './control-state-toggle.component.html',
  styleUrls: ['./control-state-toggle.component.scss'],
})
export class ControlStateToggleComponent implements OnInit {
  @Input() currentApplicationControlMode: SystemControlMode;

  controlOptions: any[];
  controlMode: SystemControlMode;
  @Output() public selectedApplicationControlMode = new EventEmitter<SystemControlMode>();
  public SystemControlMode = SystemControlMode;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.controlOptions = [
      { name: this.translateService.instant('control-state-toggle.switch.auto'), value: SystemControlMode.Auto },
      { name: this.translateService.instant('control-state-toggle.switch.manual'), value: SystemControlMode.Manual },
    ];

    this.controlMode = this.currentApplicationControlMode;
  }
}
