import { CONNECTION_SPACING } from '../../Common';
import { LabelPosition, SystemMeta } from '../../types';
import { SmallLabelDeltas } from '../../../util/constants';
import { EConnectionType, SystemType } from '../../../state';
import { EConnectionPointPlacement, EEquipment } from '../../../types';
import { FORWARD_Y } from '../../heat-exchanger-common/heat-exchanger-common-metadata';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/two-stroke-heat-exchanger.svg';
import twoStrokeHeatExchangerSystemForm from '../../../../models/systems/twoStrokeHeatExchangerHotWaterSystem/twoStrokeHeatExchangerHotWaterSystemForm.json';
import { ITwoStrokeHeatExchangerHotWaterSystemFormInterface } from '../../../../models/systems/twoStrokeHeatExchangerHotWaterSystem/twoStrokeHeatExchangerHotWaterSystemFormInterface';

export const HEIGHT = 360;
export const WIDTH = 440;
// dimensions of heat exchanger SVG
export const EXCHANGER_ICON_HEIGHT = 117;
export const EXCHANGER_ICON_WIDTH = 79;
// X-position of left side of heat exchanger svg
export const EXCHANGER_ICON_LEFT = 150;
export const EXCHANGER_ICON_RIGHT = EXCHANGER_ICON_LEFT + EXCHANGER_ICON_WIDTH;
export const EXCHANGER_ICON_TOP = FORWARD_Y - (EXCHANGER_ICON_HEIGHT - CONNECTION_SPACING) / 2;
export const VALVE_X = EXCHANGER_ICON_LEFT - 30;
export const PRIMARY_TEMP_SENSOR_X = VALVE_X - 90;
export const SECONDARY_LOOP_RIGHT = EXCHANGER_ICON_RIGHT + 140;
// X-position of secondary forward temp sensor
export const SECONDARY_TEMP_SENSOR_X = EXCHANGER_ICON_RIGHT + 11;
export const FORWARD2_Y = FORWARD_Y + CONNECTION_SPACING;
export const RETURN_Y = FORWARD2_Y + CONNECTION_SPACING;
export const PUMP_X = SECONDARY_LOOP_RIGHT - 40;
export const COLD_SUPPLY_RIGHT = EXCHANGER_ICON_RIGHT + 50;

export const twoStrokeHeatExchangerHotWaterSystemMeta: SystemMeta = {
  systemType: SystemType.TwoStrokeHeatExchangerHotWaterSystem,
  name: 'Two Stroke Heat Exchanger for Domestic Hot Water',
  thumbnail,
  form: twoStrokeHeatExchangerSystemForm,
  width: WIDTH,
  height: HEIGHT,
  calculateConnectionPoints: (form: ITwoStrokeHeatExchangerHotWaterSystemFormInterface) => {
    return [
      {
        key: EConnectionPointPlacement.connectionPrimaryForward,
        x: 0,
        y: FORWARD_Y,
        type: EConnectionType.supply,
        dotted: false,
      },
      {
        key: EConnectionPointPlacement.connectionPrimaryForwardSecond,
        x: 0,
        y: FORWARD2_Y,
        type: EConnectionType.return,
        dotted: true,
      },
      {
        key: EConnectionPointPlacement.connectionPrimaryReturn,
        x: 0,
        y: RETURN_Y,
        type: EConnectionType.return,
        dotted: true,
      },
    ];
  },
};

export const getTwoStrokeHeatExchangerHotWaterSystemLabels = (
  systemInfo: ITwoStrokeHeatExchangerHotWaterSystemFormInterface
): LabelPosition[] => {
  const supplyTemp = systemInfo.T_PF_present
    ? [
        {
          id: EEquipment.T_PF,
          x: PRIMARY_TEMP_SENSOR_X + SmallLabelDeltas.TEMP_SENSOR_HORIZONTAL_DX,
          y: FORWARD_Y + SmallLabelDeltas.EAST_Y,
        },
      ]
    : [];
  const returnTemp = systemInfo.T_PR_present
    ? [
        {
          id: EEquipment.T_PR,
          x: PRIMARY_TEMP_SENSOR_X + SmallLabelDeltas.TEMP_SENSOR_HORIZONTAL_DX,
          y: RETURN_Y + SmallLabelDeltas.WEST_Y,
        },
      ]
    : [];
  const tsf = {
    id: EEquipment.T_SF,
    x: SECONDARY_TEMP_SENSOR_X + SmallLabelDeltas.TEMP_SENSOR_HORIZONTAL_DX,
    y: FORWARD_Y + SmallLabelDeltas.EAST_Y,
  };
  const tcr = systemInfo.T_CR_present
    ? [
        {
          id: EEquipment.T_CR,
          x: SECONDARY_TEMP_SENSOR_X + SmallLabelDeltas.TEMP_SENSOR_HORIZONTAL_DX,
          y: FORWARD2_Y + SmallLabelDeltas.WEST_Y,
        },
      ]
    : [];
  const valveLabel: LabelPosition[] = systemInfo.Control_enabled
    ? [
        {
          id: EEquipment.MV_2W_PF,
          x: VALVE_X,
          y: FORWARD_Y + SmallLabelDeltas.EAST_Y,
          horizontalAlignment: 'middle',
        },
      ]
    : [];
  const pumpLabel: LabelPosition[] = systemInfo.P_connected
    ? [
        {
          id: EEquipment.P_CR,
          x: PUMP_X,
          y: FORWARD2_Y + SmallLabelDeltas.WEST_Y + 4, // we offset a little because pump labels are two lines
          horizontalAlignment: 'middle',
        },
      ]
    : [];
  return [...supplyTemp, ...returnTemp, tsf, ...tcr, ...valveLabel, ...pumpLabel];
};
