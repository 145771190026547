import { Component } from '@angular/core';
import { AuthService, ConfigurationService, LanguageService } from 'shared';
import { CurrentUserService } from 'projects/serviceportal/src/app/services/current-user.service';
import { first, tap } from 'rxjs/operators';
import { GroupType, SocketService } from './services/socket.service';
import { Angulartics2LaunchByAdobe } from 'angulartics2';
import { UserTrackingService } from './services/user-tracking.service';
import { ScriptInjectorService } from './services/script-injector.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{ provide: Angulartics2LaunchByAdobe, useClass: UserTrackingService }],
})
export class AppComponent {
  title = 'portalcustomer';

  constructor(
    private configurationService: ConfigurationService,
    public authService: AuthService,
    private languageService: LanguageService,
    private currentUserService: CurrentUserService,
    private socketService: SocketService,
    private scriptInjectorService: ScriptInjectorService,
    public userTrackingService: Angulartics2LaunchByAdobe) {
    // initialize when config is loaded
    this.configurationService.configuration.subscribe(configuration => {
      if (configuration) {
        if (configuration.userTrackingScriptSrc) {
          this.scriptInjectorService.loadScript('dtm', configuration.userTrackingScriptSrc).then(() => {
            (this.userTrackingService as any).setupReportSuiteId(configuration.userTrackingReportSuiteId);
            (this.userTrackingService as any).setupDryRunTracking(!!configuration.userTrackingDryRun);
            (this.userTrackingService as any).setupLogTrackingEvents(!!configuration.userTrackingLogTrackingEvents);
            (this.userTrackingService as any).setupIncludeInternalUsers(!!configuration.userTrackingIncludeInternalUsers);
            this.userTrackingService.startTracking();
          });
        }
      }
    });

    this.configurationService.LoadConfiguration();

    // Establish connection through websocket
    this.socketService.start();

    this.currentUserService.currentUser$.pipe(
      first(),
      tap(user => {
        this.languageService.setupLanguages(user.language);

        // Subscribe to all facilities on startup
        this.socketService.subscribeMultiple([], [GroupType.AllFacilities]);
        // Subscribe to the current user
        this.socketService.subscribeMultiple([], [GroupType.CurrentUser]);
      })
    ).subscribe();
  }
}
