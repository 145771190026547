import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { BehaviorSubject, NEVER, Observable, of, throwError } from 'rxjs';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { ModalService } from '../services/modal.service'
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRenewingToken = false;
  private renewAccessTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(
    private auth: AuthService, 
    private modalService: ModalService, 
    private translateService: TranslateService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.auth.currentUser.value) {
      request = this.cloneRequest(request, this.auth.currentUser.value?.access_token);
    }
    return next.handle(request).pipe(
      catchError(response => {
        if (this.isAuthorizationError(response)) {
          if (!this.auth.currentUser.value?.access_token) {
            this.auth.signinRedirect('/');
            return throwError(response);
          }
          else {
            let errorCode = response.headers?.get('ErrorCode');
            if (errorCode === 'user-not-validated') {
              this.modalService
                .showErrorModal(
                  this.translateService.instant('modal-service.error-dialog-default-title'),
                  this.translateService.instant('auth.user-email-not-validated')
                )
                .subscribe(() => this.auth.signout());
              return NEVER;    // It's ok to NEVER complete the observable since signout navigates away from our SPA
            }
            else {
              // Even though AuthService is set up for automatic silent renewal of access token, it might not always work (eg. if
              // the client pc was offline or sleeping while the auto-renew call was being made).
              // As a fallback strategy, we try to renew the token ourselves in case of a 401 response, then repeat the failing request 
              // with the new token. We must take into account that the user might get a 401 response for other reasons than an expired token,
              // so we should first check if the token is actually expired/expiring. If not, then the user is just not allowed to make the request
              // and we should not repeat it.

              if (this.auth.currentUser.value?.expires_in > 60) {
                // Access token should still be valid; it seems the user must just not be allowed to execute the request
                return throwError(response);
              }

              return this.renewTokenAndRetryRequest(request).pipe(
                mergeMap(clonedRequest => {
                  return next.handle(clonedRequest);
                })
              );
            }
          }
        }
        else {
          return throwError(response);
        }
      })
    );
  }

  private cloneRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private renewTokenAndRetryRequest(request: HttpRequest<any>): Observable<HttpRequest<any>> {
    if (!this.isRenewingToken) {
      this.isRenewingToken = true;
      this.renewAccessTokenSubject = new BehaviorSubject<string | null>(null);
      return this.auth.signinSilent().pipe(
        mergeMap(user => {
          if (!user?.access_token) {
            this.auth.signinRedirect('/');
          }
          this.isRenewingToken = false;
          this.renewAccessTokenSubject.next(user?.access_token);
          this.renewAccessTokenSubject.complete();
          return of(this.cloneRequest(request, user?.access_token));
        }),
        catchError(err => {
          this.isRenewingToken = false;
          this.renewAccessTokenSubject.error(err);
          this.auth.signinRedirect('/');
          return throwError(err);
        })
      );
    }
    else {
      // Await token being renewed by other request
      return this.renewAccessTokenSubject.pipe(
        catchError(err => {
          return throwError(err);
        }),
        filter(token => !!token),
        mergeMap(token => {
          return of(this.cloneRequest(request, token!));
        })
      );
    }
  }

  private isAuthorizationError(error: any): boolean {
    return error instanceof HttpErrorResponse && error.status === 401
  }
}
