import { LabelPosition, SystemMeta } from '../../types';
import { SmallLabelDeltas } from '../../../util/constants';
import { EConnectionType, SystemType } from '../../../state';
import { EConnectionPointPlacement, EEquipment } from '../../../types';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/chw.svg';
import commercialHotWaterSystemForm from '../../../../models/systems/commercialHotWaterSystem/commercialHotWaterSystemForm.json';
import { ICommercialHotWaterSystemFormInterface } from '../../../../models/systems/commercialHotWaterSystem/commercialHotWaterSystemFormInterface';
import {
  commonHeatExchangerLabels,
  EXCHANGER_ICON_RIGHT,
  FORWARD_Y,
  RETURN_Y,
} from '../../heat-exchanger-common/heat-exchanger-common-metadata';

// the width of the secondary loop
export const SECONDARY_LOOP_WIDTH = 170;
// X-position of pump center
export const PUMP_X = 105;
// X-position of point where secondary loop meets cold water supply pipe
export const COLD_WATER_X = 375;
// vertical length of cold water supply pipe
export const COLD_WATER_VERTICAL_LENGTH = 60;
// horizontal length of cold water supply pipe
export const COLD_WATER_HORIZONTAL_LENGTH = 55;
// X-position of circulation temp sensor
export const CIRCULATION_TEMP_SENSOR_X = 460;
export const SECONDARY_LOOP_RIGHT = EXCHANGER_ICON_RIGHT + SECONDARY_LOOP_WIDTH;

export const commercialHotWaterMeta: SystemMeta = {
  systemType: SystemType.CommercialHotWater,
  name: 'Commercial Hot Water',
  thumbnail,
  width: 560,
  height: 320,
  form: commercialHotWaterSystemForm,
  calculateConnectionPoints: () => [
    {
      key: EConnectionPointPlacement.connectionPrimaryForward,
      x: 0,
      y: FORWARD_Y,
      type: EConnectionType.supply,
      dotted: false,
    },
    {
      key: EConnectionPointPlacement.connectionPrimaryReturn,
      x: 0,
      y: RETURN_Y,
      type: EConnectionType.return,
      dotted: true,
    },
  ],
};

export const getCommercialHotWaterLabels = (systemInfo: ICommercialHotWaterSystemFormInterface): LabelPosition[] => {
  const tcr = systemInfo.T_CR_present
    ? [
        {
          id: EEquipment.T_CR,
          x: CIRCULATION_TEMP_SENSOR_X + SmallLabelDeltas.TEMP_SENSOR_HORIZONTAL_DX,
          y: RETURN_Y + SmallLabelDeltas.WEST_Y,
        },
      ]
    : [];
  const coldWaterSupplyFlowLabel: LabelPosition[] = systemInfo.Q_SC_present
    ? [
        {
          id: EEquipment.Q_SC,
          x: COLD_WATER_X - COLD_WATER_VERTICAL_LENGTH / 2,
          y: RETURN_Y + COLD_WATER_VERTICAL_LENGTH + SmallLabelDeltas.WEST_Y,
          horizontalAlignment: 'middle',
        },
      ]
    : [];
  const pumpLabel: LabelPosition[] = systemInfo.P_connected
    ? [
        {
          id: EEquipment.P_CR,
          x: EXCHANGER_ICON_RIGHT + PUMP_X,
          y: RETURN_Y + SmallLabelDeltas.WEST_Y + 4, // we offset a little because pump labels are two lines
          horizontalAlignment: 'middle',
        },
      ]
    : [];
  return [...commonHeatExchangerLabels(systemInfo), ...tcr, ...coldWaterSupplyFlowLabel, ...pumpLabel];
};
