import { Component, Input, OnInit } from '@angular/core';
import { Observable, from, combineLatest } from 'rxjs';
import { DataUpdatedState, InstallationStateService } from '../../services/installation-state.service';
import { map, tap } from 'rxjs/operators';
import { TimeAgoPipe } from 'shared';
import { TranslateService } from '@ngx-translate/core';

interface ITooltip {
  tooltip: string;
  heartbeat: number;
}

@Component({
  selector: 'app-last-updated',
  templateUrl: './last-updated.component.html',
  styleUrls: ['./last-updated.component.scss']
})
export class LastUpdatedComponent implements OnInit {
  @Input()
  public applicationId: string;

  @Input()
  public installationId: string;

  public LastUpdatedState = DataUpdatedState;
  public state$: Observable<DataUpdatedState>;
  public heartBeat$: Observable<number | undefined>;

  public updatedText$: Observable<string | null>;
  public hideUpdatedText$: Observable<boolean>;


  constructor(
    private installationStateService: InstallationStateService,
    private timeAgoPipe: TimeAgoPipe,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.state$ = this.installationStateService.getDeviceDateUpdatedState(this.installationId, this.applicationId);
    this.heartBeat$ = this.installationStateService.getHeartBeat(this.installationId);

    this.hideUpdatedText$ = combineLatest([this.heartBeat$, this.state$]).pipe(
      map(([heartBeat, state]) => {
        if (heartBeat === 0) {
          return true;
        }
        return state === DataUpdatedState.noData || state === DataUpdatedState.offline;
      }),
    );

    this.updatedText$ = this.heartBeat$.pipe(
      map(beat => {
        if (beat === 0) {
          return null;
        }
        return this.translate.instant('dumbledore-schematics-tile.last-updated') + ' ' + (beat ? this.timeAgoPipe.transform(beat) : '');
      })
    );
  }
}
