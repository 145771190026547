import { IForm, IPanel, IQuestion } from '../models/form.interface';
import { Parser } from 'expr-eval';

export class FormService {
  public getDefaultVariableValues(form: IForm): any {
    const formPage = form.pages[0];
    const allQuestions = formPage.elements.flatMap((panel) => panel.elements);
    return allQuestions.reduce((acc: { [k: string]: string | number | null }, question) => {
      acc[question.name] = null;
      return acc;
    }, {});
  }
  public formValid(form: IForm, data: any): boolean {
    return form.pages.every((page) => page.elements.every((panel) => this.panelValid(panel, data)));
  }

  public panelValid(panel: IPanel, data: any): boolean {
    if (!this.isElementVisible(panel, data)) {
      return true;
    }

    return panel.elements.every((question) => this.questionValid(question, data));
  }

  public questionRequired(question: IQuestion, data: any): boolean {
    return this.isElementVisible(question, data) && !!question.isRequired;
  }

  public panelRequired(panel: IPanel, data: any): boolean {
    return this.isElementVisible(panel, data) && panel.elements.some((question) => this.questionRequired(question, data));
  }

  public questionValid(question: IQuestion, data: any): boolean {
    if (question.type === 'html') {
      return true;
    }
    if (!this.isElementVisible(question, data)) {
      return true;
    }

    if (question.isRequired) {
      const valid = data[question.name] !== undefined && data[question.name] !== null && data[question.name] !== '';
      return valid;
    }
    return true;
  }

  public isElementVisible(element: IQuestion | IPanel, data: any): boolean {
    try {
      if (element.hasOwnProperty('visibleIf') && element.visibleIf !== undefined) {
        const replacedVisibleIf = element.visibleIf.replace(/{/g, '').replace(/}/g, '').replace(/ [=] /g, '==');
        const parser = new Parser();
        const expr = parser.parse(replacedVisibleIf);
        try {
          return !!expr.evaluate(data);
        } catch (e) {
          return false;
        }
      }
      return true;
    } catch (e) {
      console.log('Error parsing visibleIf: ', element);
      return false;
    }
  }

  public isElementDisabled(element: IQuestion): boolean {
    return (element.hasOwnProperty('disabledValue') && element.disabledValue !== undefined);
  }

  public clearHiddenQuestionsFromData(form: IForm, data: any) {
    const panels = form.pages[0].elements;
    for (const questionName of Object.keys(data)) {
      const questionVisible = panels.some((panel) => {
        return this.isElementVisible(panel, data) && panel.elements.some((question) => question.name === questionName && this.isElementVisible(question, data));
      });
      if (!questionVisible) {
        data[questionName] = null;
      }
    }
  }
}

export const formService = new FormService();
