import { Component, OnInit } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[gbc-textarea]',
  template: '<ng-content></ng-content>',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent {
}
