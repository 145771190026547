import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataPointsResult, DeviceDataPoint, Report, System, SystemDevice } from '../interfaces/data-points';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import moment from 'moment';
import { SystemControlMode } from '../interfaces/system-control-mode';
import { ShowOutdoorTemperatureSensorPayload, SystemControlModePayload, WarmWeatherShutDownPayload } from '@ams/dumbledore';
import { FakeBooleanState, WarmWeatherShutdown } from '../components/warm-weather-shutdown-tile/warm-weather-shutdown-tile.component';

export enum Resolution {
  Seconds = 'Seconds',
  Minutes = 'Minutes',
  Hours = 'Hours',
  Days = 'Days',
}

export type TimeSerie = Array<[number, number | null]>;

export interface ReportPeriod {
  duration: number;
  resolution: Resolution;
}

export interface TimeSeriesDetails {
  dataPoints: (DeviceDataPoint | null)[];
  period: ReportPeriod;
  installationId: string;
  systemId: string;
  requestId: string;
}

interface ReportViewDetails {
  installationId: string;
  systemId: string;
  dataPointIds: string[];
  clientIds: string[];
  startTime: Date;
  resolution: Resolution;
  requestId: string;
}

type Params = {
  installationId: string;
  systemId: string;
  intervalBeginEpoch: string;
  intervalEndEpoch: string;
  resolution: string;
  clientIds?: string[];
  dataPointIds?: string[];
  requestId?: string;
};

@Injectable({ providedIn: 'root' })
export class DataPointsService {
  public dataPoints$: BehaviorSubject<DataPointsResult[]> = new BehaviorSubject<DataPointsResult[]>([]);

  constructor(private httpClient: HttpClient) {}

  public getWarmWeatherShutdownActiveForInstallation(installationId: string): Observable<WarmWeatherShutDownPayload[]> {
    return this.dataPoints$.pipe(
      map((dataPointsResults: DataPointsResult[]) => {
        const installationResults: DataPointsResult | undefined = dataPointsResults.find(
          (results: DataPointsResult): boolean => results.installationId === installationId
        );

        const payload = installationResults?.data.flatMap((system: System) => {
          const dataPoints: DeviceDataPoint[] = system.devices.flatMap((device: SystemDevice) => device.dataPoints);
          const activeStateDataPoint: DeviceDataPoint | undefined = dataPoints.find(
            (dataPoint: DeviceDataPoint): boolean => dataPoint.clientId === WarmWeatherShutdown.ActiveState
          );
          const warmWeatherShutdownActive = activeStateDataPoint?.value === ('1.000000' as FakeBooleanState);
          return {
            systemId: system.systemId,
            warmWeatherShutdown: warmWeatherShutdownActive,
          } as WarmWeatherShutDownPayload;
        });
        return payload || [];
      })
    );
  }

  public getSystemControlModesForInstallation(installationId: string): Observable<SystemControlModePayload[]> {
    return this.dataPoints$.pipe(
      map((dataPointsResults: DataPointsResult[]) => {
        const installationResults = dataPointsResults.find((results) => results.installationId === installationId);
        const payloads = installationResults?.data.map((data) => {
          return { systemId: data.systemId, systemControlMode: data.systemControlMode } as SystemControlModePayload;
        });
        return payloads || [];
      })
    );
  }

  public getSystemControlMode(systemId: string): Observable<SystemControlMode> {
    return this.dataPoints$.pipe(
      map((dataPointsResults: DataPointsResult[]) => {
        const [installation] = dataPointsResults;
        const [system] = installation.data.filter((system) => system.systemId === systemId);
        return system.systemControlMode;
      })
    );
  }

  public getShowOutdoorTemperatureSensorForInstallation(installationId: string): Observable<ShowOutdoorTemperatureSensorPayload[]> {
    return this.dataPoints$.pipe(
      map((dataPointsResults: DataPointsResult[]) => {
        const installationResults: DataPointsResult | undefined = dataPointsResults.find(
          (results: DataPointsResult): boolean => results.installationId === installationId
        );
        const payloads: ShowOutdoorTemperatureSensorPayload[] | undefined = installationResults?.data.map((data: System) => {
          return {
            systemId: data.systemId,
            showOutdoorTemperatureSensor: data.showOutdoorTemperatureSensor,
          } as ShowOutdoorTemperatureSensorPayload;
        });
        return payloads || [];
      })
    );
  }

  public pushOrUpdateDataToDataList(incomingDataPoints: DataPointsResult) {
    const dataPointsResults = this.dataPoints$.value || [];
    const facilityIndex = dataPointsResults.findIndex((f) => f.installationId === incomingDataPoints.installationId);
    if (facilityIndex === -1) {
      dataPointsResults.push(incomingDataPoints);
    } else {
      dataPointsResults[facilityIndex] = incomingDataPoints;
    }
    this.dataPoints$.next(dataPointsResults);
  }

  public getTimeSeries({ dataPoints, period, installationId, systemId, requestId }: TimeSeriesDetails): Observable<TimeSerie[]> {
    const reportViewDetails: ReportViewDetails = {
      installationId,
      systemId,
      dataPointIds: dataPoints
        .filter((d) => !!d)
        .map((p: DeviceDataPoint | null) => p?.dataPointId)
        .filter((x) => !!x) as string[],
      clientIds: dataPoints
        .filter((d) => !!d)
        .map((p: DeviceDataPoint | null) => p?.clientId)
        .filter((x) => !!x) as string[],
      startTime: moment().subtract(period.duration, 'millisecond').toDate(),
      resolution: period.resolution,
      requestId,
    };
    return this.getReportView(reportViewDetails).pipe(
      map((reports) => {
        return reports.map((report) => {
          return report?.data.map(({ avg, intervalBeginTimestampEpoch, intervalDuration }): [number, number] => {
            // The graph data is intervals. We plot each interval as a point starting at the middle of the interval.
            // The exception is intervals that have no data; in that case we use the interval start time as the point start time.
            return avg === null || avg === undefined
              ? [intervalBeginTimestampEpoch, avg]
              : [intervalBeginTimestampEpoch + intervalDuration / 2, avg];
          });
        });
      })
    );
  }

  private getReportView({
    installationId,
    systemId,
    dataPointIds,
    clientIds,
    startTime,
    resolution,
    requestId,
  }: ReportViewDetails): Observable<Report[]> {
    // In order to cache the request, we round of the start and end to nearest minute, otherwise the request changes everytime
    const minute = 1000 * 60;
    const roundedEndEpoch = +new Date(Math.round(new Date().getTime() / minute) * minute);
    const roundedStartEpoch = +new Date(Math.round(startTime.getTime() / minute) * minute);
    let params: Params = {
      installationId,
      systemId,
      intervalBeginEpoch: roundedStartEpoch.toString(),
      intervalEndEpoch: roundedEndEpoch.toString(),
      resolution: resolution.toString(),
      dataPointIds: dataPointIds,
      requestId,
    };

    if (clientIds.length > 0) {
      params.clientIds = clientIds;
      delete params.dataPointIds;
    }

    return this.httpClient.get<Report[]>(`${environment.serverUrl}/api/datapoints/reportView`, {
      params,
    });
  }

  public exportEnergyData(query: any) {
    return this.httpClient.get(`${environment.serverUrl}/api/datapoints/export`, { responseType: 'text', params: query });
  }
}
