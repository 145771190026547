import { SystemType, DistrictHeatingSystemState, BoilerSystemState, HotWaterTankSystemState } from '../state';
import { EEquipment, EValueType, IEquipmentValue, ISetPointValue, IValveOpenValue, SystemControlMode } from '../types';
import { SampleEquipmentValue } from './sample-equipment-values';
import { v4 } from 'uuid';
import { AlarmsWarnings, SystemControlModePayload } from '../actions';

export const SAMPLE_HOT_WATER_TANK_SYSTEM: HotWaterTankSystemState = {
  hasId: v4(),
  type: SystemType.HotWaterTank,
  left: 600,
  top: 780,
  configured: false,
  systemInfo: {
    Control_enabled: true,
    HM_present: true,
    HM_location: 'PrimaryForward',
    MV_valveLocation: 'PrimaryForward',
    T_SR_present: true,
    Q_SC_present: true,
    P_connected: true,
    P_type: 'Magna3'
  }
};

export const HOT_WATER_TANK_VALUE_SAMPLES: SampleEquipmentValue[] = [
  { systemType: SystemType.HotWaterTank, type: EValueType.value, equipment: EEquipment.T_PF, value: '72.9\u2103' },
  { systemType: SystemType.HotWaterTank, type: EValueType.value, equipment: EEquipment.T_PR, value: '33.7\u2103' },
  { systemType: SystemType.HotWaterTank, type: EValueType.value, equipment: EEquipment.Q_PF, value: '10.3\u33a5/s' },
  { systemType: SystemType.HotWaterTank, type: EValueType.value, equipment: EEquipment.Q_PR, value: '10.3\u33a5/s' },
  { systemType: SystemType.HotWaterTank, type: EValueType.value, equipment: EEquipment.Q_SC, value: '7.5\u33a5/s' },
  { systemType: SystemType.HotWaterTank, type: EValueType.value, equipment: EEquipment.MV_2W_PF, value: '13%' },
  { systemType: SystemType.HotWaterTank, type: EValueType.value, equipment: EEquipment.MV_2W_PR, value: '13%' },
  { systemType: SystemType.HotWaterTank, type: EValueType.setPoint, equipment: EEquipment.MV_2W_PF, value: '24%' },
  { systemType: SystemType.HotWaterTank, type: EValueType.setPoint, equipment: EEquipment.MV_2W_PR, value: '24%' },
  { systemType: SystemType.HotWaterTank, type: EValueType.valveIndication, equipment: EEquipment.MV_2W_PF, value: 'opening' },
  { systemType: SystemType.HotWaterTank, type: EValueType.valveIndication, equipment: EEquipment.MV_2W_PR, value: 'closing' },
  { systemType: SystemType.HotWaterTank, type: EValueType.value, equipment: EEquipment.P_SR, value: '1.7\u33a5/h\n2.3m' },
  { systemType: SystemType.HotWaterTank, type: EValueType.value, equipment: EEquipment.T_T, value: '55.2\u2103' },
  { systemType: SystemType.HotWaterTank, type: EValueType.setPoint, equipment: EEquipment.T_T, value: '55.2\u2103' },
  { systemType: SystemType.HotWaterTank, type: EValueType.value, equipment: EEquipment.T_SR, value: '49.3\u2103' }
];

export const HOT_WATER_TANK_ALARM_SAMPLES: AlarmsWarnings[] = [
  //{ type: "Alarm", systemId: SAMPLE_HOT_WATER_TANK_SYSTEM.hasId, }
];

export const HOT_WATER_TANK_STATE_SAMPLES: any[] = [
  { pasteurization: true, systemId: SAMPLE_HOT_WATER_TANK_SYSTEM.hasId }
];

export const HOT_WATER_TANK_SYSTEM_CONTROL_MODE_SAMPLE: SystemControlModePayload[] = [
  { systemControlMode: SystemControlMode.Manual, systemId: SAMPLE_HOT_WATER_TANK_SYSTEM.hasId }
];
