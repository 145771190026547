import React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
export interface QuestionTitleProps {
  text: string;
  hasError: boolean | undefined;
  isRequired: boolean | undefined;
}

const StyledQuestionTitle = styled.h4`
  margin-top: 20px;
  margin-bottom: 8px;

  opacity: 0.8;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.35px;
  color: #000000;
  &.error {
    color: #ff0000;
  }
`;

export const QuestionTitle: React.FC<QuestionTitleProps> = ({ text, hasError, isRequired }) => {
  return (
    <StyledQuestionTitle className={classnames({ title: true, error: !!hasError })}>
      {text}
      {isRequired ? '*' : ''}
    </StyledQuestionTitle>
  );
};
