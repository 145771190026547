import { Component, Input, OnInit } from '@angular/core';
import { AppModel } from '@ams/dumbledore';
import { AlarmOverview, Application } from '../../../interfaces/alarm';
import { Installation } from '../../../interfaces/facilty';
import { Observable, of } from 'rxjs';
import { WeatherCompensationCurve } from '../../../interfaces/weather-compensation';
import { map, switchMap } from 'rxjs/operators';
import { AlarmType } from '../../../interfaces/system-status';
import {
  MIXIT_TRIAL_PERIOD,
  controlMode,
  filterInstallationsByLicense,
  getDatapoint,
  installationCommissionState,
  isDynamic,
  isLessThanHalfHourSinceClaim,
  operationMode,
  outdoorTemp,
  productType,
  pumpType,
  setpoint,
  shouldShowPumpTile,
  showProductInfo,
  showPumpInfo,
  weatherCurve,
} from '../../../utils/mixit-utils';
import { TranslateService } from '@ngx-translate/core';
import { DataPointsResult, DeviceDataPoint } from '../../../interfaces/data-points';
import { ModalService } from 'shared';
import { LicenseType, MixitDataPointName, SetpointSource } from '../../../interfaces/mixit';
import { SchematicsService } from '../../../services/schematics.service';
import { SystemDeviceType } from '../../../interfaces/systemDeviceType';
import { MixitEquipmentMetaInfo } from '../../../services/installation-configuration.service';

@Component({
  selector: 'app-mixit-freemium-dashboard',
  templateUrl: './mixit-freemium-dashboard.component.html',
  styleUrls: ['./mixit-freemium-dashboard.component.scss'],
})
export class MixitFreemiumDashboardComponent implements OnInit {
  public MixitTrialPeriod = MIXIT_TRIAL_PERIOD;

  @Input() public installation$: Observable<Installation>;
  @Input() public schematic$: Observable<AppModel>;
  @Input() public application$: Observable<Application>;
  @Input() public dataPoints$: Observable<DataPointsResult>;
  @Input() public alarm$: Observable<AlarmOverview>;

  public installationCommissionState$: Observable<string>;
  public outdoorTemp$: Observable<number>;
  public weatherCurve$: Observable<WeatherCompensationCurve>;
  public productType$: Observable<string>;
  public operationMode$: Observable<string>;
  public controlMode$: Observable<string>;
  public setpoint$: Observable<DeviceDataPoint | null>;
  public setpointSource$: Observable<DeviceDataPoint | null>;
  public localSetpoint$: Observable<DeviceDataPoint | null>;
  public showLocalSetpoint$: Observable<boolean>;
  public showPumpTile$: Observable<boolean>;
  public showAlarmBar$: Observable<boolean>;
  public showWarningBar$: Observable<boolean>;
  public showUpgradeBar$: Observable<boolean>;
  public showWeatherCurve$: Observable<boolean>;
  public pumpType$: Observable<string>;
  private pumpMetaInfo$: Observable<MixitEquipmentMetaInfo | null>;
  public isLessThanHalfHourSinceClaim$: Observable<boolean>;
  public isDynamic$: Observable<boolean>;

  constructor(
    private translateService: TranslateService,
    private modalService: ModalService,
    private schematicService: SchematicsService
  ) {}

  ngOnInit(): void {
    this.pumpMetaInfo$ = this.application$.pipe(
      map((application) => application.id),
      switchMap((id) => this.schematicService.getEquipmentMetaInfo(id)),
      map((metas) => {
        // @ts-ignore
        return metas.find((m) => m.term === 'MixitPumpControlOptions');
      }),
      map((meta) => {
        return meta as MixitEquipmentMetaInfo;
      })
    );

    this.pumpType$ = pumpType(this.pumpMetaInfo$);
    this.installationCommissionState$ = installationCommissionState(this.installation$);
    this.outdoorTemp$ = outdoorTemp(this.dataPoints$).pipe(map((d) => parseFloat((d as DeviceDataPoint).value as string)));
    this.weatherCurve$ = weatherCurve(this.dataPoints$);
    this.productType$ = productType(this.schematic$);
    this.operationMode$ = operationMode(this.translateService, this.dataPoints$).pipe(
      switchMap((mode) => {
        return mode && mode.value ? this.translateService.get(`mixit-operation-mode.${mode.value}`) : of('-');
      })
    );
    this.controlMode$ = controlMode(this.translateService, this.dataPoints$).pipe(
      switchMap((mode) => {
        return mode && mode.value ? this.translateService.get(`mixit-control-mode.${mode.value}`) : of('-');
      })
    );
    this.setpoint$ = setpoint(this.dataPoints$);
    this.setpointSource$ = getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.SetpointSource);
    this.localSetpoint$ = getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.LocalSetpoint);
    this.showPumpTile$ = shouldShowPumpTile(this.schematic$);
    this.showLocalSetpoint$ = this.setpointSource$.pipe(
      map((setPointSource) => {
        return setPointSource?.value === SetpointSource.MixingLoopSetpointSourceLocal;
      })
    );

    // The dashboard can also be used in a GBC installation, so we need to check all the alarms
    this.showAlarmBar$ = this.alarm$.pipe(
      map((a) => {
        return a?.type === AlarmType.Alarm;
      })
    );

    this.showWarningBar$ = this.alarm$.pipe(
      map((a) => {
        return a?.type === AlarmType.Warning;
      })
    );

    this.showUpgradeBar$ = this.installation$.pipe(
      map((installation) => {
        return filterInstallationsByLicense([installation], LicenseType.Freemium).length > 0;
      })
    );

    this.showWeatherCurve$ = this.setpointSource$.pipe(
      map((source) => {
        return source?.value === SetpointSource.MixingLoopSetpointSourceOutdoorTemperatureAnalogueInput;
      })
    );

    this.isLessThanHalfHourSinceClaim$ = isLessThanHalfHourSinceClaim(this.installation$);

    this.isDynamic$ = isDynamic(this.schematic$);
  }

  public showProductInfo() {
    showProductInfo({
      modalService: this.modalService,
      installation$: this.installation$,
      schematic$: this.schematic$,
      ble$: this.schematicService.getBLEVersion(),
      firmware$: this.schematicService.getFirmwareVersion(),
      orientation$: getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.AbPortOrientation),
    });
  }

  public showPumpInfo() {
    showPumpInfo({
      translateService: this.translateService,
      modalService: this.modalService,
      schematic$: this.schematic$,
    });
  }
}
