import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gbc-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent implements OnInit {
  public iconPath: string;

  @Input() public iconName: string;
  @Input() public alt?: string;

  constructor() {}

  ngOnInit(): void {
    this.iconPath = `./assets/svgs/${ this.iconName }.svg`;
  }
}
