<form [formGroup]="licenseInfo">
  <gbc-tile [title]="title">
    <gbc-form-container class="medium-width">
      <gbc-form-row [header]="'license-info.name' | translate" required="true">
        <gbc-input-field formControlName="companyName"></gbc-input-field>
      </gbc-form-row>
      <gbc-form-row [header]="'license-info.address' | translate" required="true">
        <gbc-input-field formControlName="street"></gbc-input-field>
      </gbc-form-row>
      <gbc-form-row [header]="'license-info.postal-code' | translate" required="true">
        <gbc-input-field formControlName="postalCode"></gbc-input-field>
      </gbc-form-row>
      <gbc-form-row [header]="'license-info.city' | translate" required="true">
        <gbc-input-field formControlName="city"></gbc-input-field>
      </gbc-form-row>
      <gbc-form-row [header]="'license-info.country' | translate" required="true">
        <gbc-input-field formControlName="country"></gbc-input-field>
      </gbc-form-row>
      <gbc-form-row [header]="'license-info.region' | translate" [description]="'license-info.region-applicable-footnote' | translate">
        <gbc-input-field formControlName="region"></gbc-input-field>
      </gbc-form-row>
      <gbc-form-row [header]="'license-info.state' | translate" [description]="'license-info.region-applicable-footnote' | translate">
        <gbc-input-field formControlName="state"></gbc-input-field>
      </gbc-form-row>
      <gbc-form-row *ngIf="extendedInfo" [header]="'license-info.vat-number' | translate" required="true">
        <gbc-input-field formControlName="vatNumber"></gbc-input-field>
      </gbc-form-row>
      <gbc-form-row *ngIf="extendedInfo" [header]="'license-info.account-manager-name' | translate" required="true">
        <gbc-input-field formControlName="accountManagerName"></gbc-input-field>
      </gbc-form-row>
      <gbc-form-row *ngIf="extendedInfo" [header]="'license-info.account-manager-email' | translate" required="true">
        <gbc-input-field
          formControlName="accountManagerEmail"
          [errorDescriptionTranslationString]="'license-info.invalid-email'"
        ></gbc-input-field>
      </gbc-form-row>
      <gbc-form-row *ngIf="extendedInfo" [header]="'license-info.invoice-email' | translate" required="true">
        <gbc-input-field
          formControlName="invoiceEmail"
          [errorDescriptionTranslationString]="'license-info.invalid-email'"
        ></gbc-input-field>
      </gbc-form-row>
      <gbc-form-row *ngIf="extendedInfo" [header]="'license-info.purchase-reference' | translate" required="true">
        <gbc-input-field formControlName="purchaseReference"></gbc-input-field>
      </gbc-form-row>
    </gbc-form-container>
  </gbc-tile>
  <footer>
    <ng-content></ng-content>
    <gbc-tile [title]="'license-info.terms-tile-title' | translate">
      <gbc-form-container>
        <gbc-form-row [header]="'license-info.terms-of-use' | translate" required="true">
          <div class="p-field-checkbox">
            <p-checkbox [formControl]="$any(licenseInfo).controls['termsOfUse']" binary="true" id="terms-of-use"></p-checkbox>
            <label for="terms-of-use">
              {{ 'license-info.terms-of-use-text' | translate }}
              <a [href]="TERMS_OF_USE_LINK" target="_blank">{{ 'license-info.terms-of-use-link' | translate }}</a></label
            >
          </div>
        </gbc-form-row>
        <gbc-form-row [header]="'license-info.terms-&-conditions' | translate" required="true">
          <div class="p-field-checkbox">
            <p-checkbox
              [formControl]="$any(licenseInfo).controls['termsAndConditions']"
              binary="true"
              id="terms-and-conditions"
            ></p-checkbox>
            <label for="terms-and-conditions">
              {{ 'license-info.terms-&-conditions-text' | translate }}
              <a [href]="TERMS_AND_CONDITIONS_LINK" target="_blank">{{ 'license-info.terms-&-conditions-link' | translate }}</a></label
            >
          </div>
        </gbc-form-row>
        <gbc-form-row class="over-sized-text" [header]="'license-info.other' | translate">
          <div class="p-field-checkbox">
            <p-checkbox [formControl]="$any(licenseInfo).controls['other']" binary="true" id="other"></p-checkbox>
            <label for="other">
              {{ 'license-info.other-text' | translate }}
            </label>
          </div>
        </gbc-form-row>
      </gbc-form-container>
    </gbc-tile>
  </footer>
</form>
