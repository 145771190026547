import { Component, Input } from '@angular/core';
import { Serie, SerieType } from '../../charts/temperature-chart-tile/temperature-chart-tile.component';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { AlarmOverview, Application } from '../../../interfaces/alarm';
import { Options } from 'highcharts';
import { Installation } from '../../../interfaces/facilty';
import { AppModel, EEquipment } from '@ams/dumbledore';
import { DataPointsResult } from '../../../interfaces/data-points';

@Component({
  selector: 'app-outdoor-temp-dashboard',
  templateUrl: './outdoor-temp-dashboard.component.html',
  styleUrls: ['./outdoor-temp-dashboard.component.scss'],
})
export class OutdoorTempDashboardComponent {
  @Input() public alarm$: Observable<AlarmOverview>;
  @Input() public application: Application;
  @Input() public installation$: Observable<Installation>;
  @Input() public schematic$: Observable<AppModel>;
  @Input() public dataPoints$: Observable<DataPointsResult>;

  public series$: Observable<Serie[]>;
  public chartOptions: Options;

  constructor(private translateService: TranslateService) {
    const title$ = translateService.get('outdoor-temp-dashboard.temperature-chart-legend');
    this.series$ = title$.pipe(
      map((title) => [
        {
          type: SerieType.DataPoint,
          title,
          dumbledoreId: EEquipment.T_OUTDOOR,
        },
      ])
    );
    this.chartOptions = {
      legend: {
        enabled: true,
      },
      colors: ['#E43535'],
      yAxis: {
        title: {
          text: '\u00b0C',
        },
        softMin: -20,
        softMax: 30,
        ceiling: 60,
        floor: -50,
      },
    };
  }
}
