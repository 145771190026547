import React from 'react';
import { FORWARD_X, ICON_TOP } from '../systems/mixit/mixit-metadata';
import { IMixitSystemFormInterface } from '../../../models/systems/mixitSystem/mixitSystemFormInterface';
import { HeatingCoil, FORWARD_Y as HEATING_COIL_FORWARD_Y, RETURN_Y as HEATING_COIL_RETURN_Y } from './HeatingCoil';
import { CoolingCoil, FORWARD_Y as COOLING_COIL_FORWARD_Y, RETURN_Y as COOLING_COIL_RETURN_Y } from './CoolingCoil';
import {
  Underfloor,
  UNDERFLOOR_HEATING_LEFT_PIPE_Y_LEFT,
  UNDERFLOOR_HEATING_LEFT_PIPE_Y_RIGHT,
  UNDERFLOOR_HEATING_RIGHT_PIPE_Y_LEFT,
  UNDERFLOOR_HEATING_RIGHT_PIPE_Y_RIGHT,
} from './Underfloor';
import {
  FanCoil,
  FAN_COIL_LEFT_PIPE_Y_LEFT,
  FAN_COIL_LEFT_PIPE_Y_RIGHT,
  FAN_COIL_RIGHT_PIPE_Y_LEFT,
  FAN_COIL_RIGHT_PIPE_Y_RIGHT,
} from './FanCoil';
import {
  Radiator,
  RADIATOR_SVG_HEIGHT,
  RADIATOR_LEFT_PIPE_Y_LEFT,
  RADIATOR_LEFT_PIPE_Y_RIGHT,
  RADIATOR_RIGHT_PIPE_Y_LEFT,
} from './Radiator';
import {
  RadiantHeatingPanels,
  RADIANT_HEATING_PANELS_LEFT_PIPE_Y_LEFT,
  RADIANT_HEATING_PANELS_LEFT_PIPE_Y_RIGHT,
  RADIANT_HEATING_PANELS_RIGHT_PIPE_Y_LEFT,
  RADIANT_HEATING_PANELS_RIGHT_PIPE_Y_RIGHT,
} from './RadiantHeatingPanels';

interface MixitApplicationTypeProps {
  applicationType: IMixitSystemFormInterface['Application'];
  reversedWarmCold: boolean;
}

export const getForwardTop = (applicationType: IMixitSystemFormInterface['Application'], left: boolean): number => {
  switch (applicationType) {
    case 'MIXING_LOOP_APPLICATION_TYPE_RADIATOR_HEATING':
      if (left) {
        return ICON_TOP + RADIATOR_LEFT_PIPE_Y_LEFT;
      }
      return ICON_TOP + RADIATOR_LEFT_PIPE_Y_RIGHT;
    case 'MIXING_LOOP_APPLICATION_TYPE_FLOOR_HEATING':
      if (left) {
        return ICON_TOP + UNDERFLOOR_HEATING_LEFT_PIPE_Y_LEFT;
      }
      return ICON_TOP + UNDERFLOOR_HEATING_LEFT_PIPE_Y_RIGHT;
    case 'MIXING_LOOP_APPLICATION_TYPE_HEATING_COIL':
      return ICON_TOP + HEATING_COIL_RETURN_Y;
    case 'MIXING_LOOP_APPLICATION_TYPE_COOLING_COIL':
      return ICON_TOP + COOLING_COIL_RETURN_Y;
    case 'MIXING_LOOP_APPLICATION_TYPE_FAN_COIL':
      if (left) {
        return ICON_TOP + FAN_COIL_LEFT_PIPE_Y_LEFT;
      }
      return ICON_TOP + FAN_COIL_LEFT_PIPE_Y_RIGHT;
    case 'MIXING_LOOP_APPLICATION_TYPE_RADIANT_HEATING_PANELS':
      if (left) {
        return ICON_TOP + RADIANT_HEATING_PANELS_LEFT_PIPE_Y_LEFT;
      }
      return ICON_TOP + RADIANT_HEATING_PANELS_LEFT_PIPE_Y_RIGHT;
  }
  return ICON_TOP;
};

export const getReturnTop = (applicationType: IMixitSystemFormInterface['Application'], left: boolean): number => {
  switch (applicationType) {
    case 'MIXING_LOOP_APPLICATION_TYPE_RADIATOR_HEATING':
      if (left) {
        return ICON_TOP + RADIATOR_RIGHT_PIPE_Y_LEFT;
      }
      return ICON_TOP + RADIATOR_SVG_HEIGHT;
    case 'MIXING_LOOP_APPLICATION_TYPE_FLOOR_HEATING':
      if (left) {
        return ICON_TOP + UNDERFLOOR_HEATING_RIGHT_PIPE_Y_LEFT;
      }
      return ICON_TOP + UNDERFLOOR_HEATING_RIGHT_PIPE_Y_RIGHT;
    case 'MIXING_LOOP_APPLICATION_TYPE_HEATING_COIL':
      return ICON_TOP + HEATING_COIL_FORWARD_Y;
    case 'MIXING_LOOP_APPLICATION_TYPE_COOLING_COIL':
      return ICON_TOP + COOLING_COIL_FORWARD_Y;
    case 'MIXING_LOOP_APPLICATION_TYPE_FAN_COIL':
      if (left) {
        return ICON_TOP + FAN_COIL_RIGHT_PIPE_Y_LEFT;
      }
      return ICON_TOP + FAN_COIL_RIGHT_PIPE_Y_RIGHT;
    case 'MIXING_LOOP_APPLICATION_TYPE_RADIANT_HEATING_PANELS':
      if (left) {
        return ICON_TOP + RADIANT_HEATING_PANELS_RIGHT_PIPE_Y_LEFT;
      }
      return ICON_TOP + RADIANT_HEATING_PANELS_RIGHT_PIPE_Y_RIGHT;
  }
  return ICON_TOP;
};

export const MixitApplicationType: React.FC<MixitApplicationTypeProps> = React.memo(({ applicationType, reversedWarmCold }) => {
  let Component;
  switch (applicationType) {
    case 'MIXING_LOOP_APPLICATION_TYPE_RADIATOR_HEATING':
      Component = Radiator;
      break;
    case 'MIXING_LOOP_APPLICATION_TYPE_FLOOR_HEATING':
      Component = Underfloor;
      break;
    case 'MIXING_LOOP_APPLICATION_TYPE_HEATING_COIL':
      Component = HeatingCoil;
      break;
    case 'MIXING_LOOP_APPLICATION_TYPE_COOLING_COIL':
      Component = CoolingCoil;
      break;
    case 'MIXING_LOOP_APPLICATION_TYPE_FAN_COIL':
      Component = FanCoil;
      break;
    case 'MIXING_LOOP_APPLICATION_TYPE_RADIANT_HEATING_PANELS':
      Component = RadiantHeatingPanels;
      break;
  }
  if (!Component) {
    return null;
  }
  return <Component x={FORWARD_X} y={ICON_TOP} reversedWarmCold={reversedWarmCold} />;
});
