export enum WeekDays {
  'Monday' = 'Monday',
  'Tuesday' = 'Tuesday',
  'Wednesday' = 'Wednesday',
  'Thursday' = 'Thursday',
  'Friday' = 'Friday',
  'Saturday' = 'Saturday',
  'Sunday' = 'Sunday'
}

export enum EventTypes {
  'Auto'  = 'Auto',
  'Manual' = 'Manual',
  'Stop' = 'Stop',
  'EcoMode' = 'EcoMode',
  'Pasteurization' = 'Pasteurization',
}

export interface TimeInterval {
  startHoursMinutes: string;    // "HHmm" format
  durationMinutes: number;
  isSuppressed: boolean;
}

export interface ScheduledEvent {
  id: string | null;
  eventType: EventTypes;
  timeIntervals: TimeInterval[];
  weekDays: WeekDays[];
}

export interface PasteurizationScheduledEvent extends ScheduledEvent {
  temperature: number;
  retentionTime: number;
}

export const MINUTES_IN_DAY = 24 * 60;
