import { Connector } from '../Connector';
import React, { ReactElement } from 'react';
import { TemperatureSensor } from '../TemperatureSensor';
import { FlowMeter, FLOWMETER_SIZE } from '../FlowMeter';
import { CONNECTION_SPACING, Direction, getRotation } from '../Common';
import { FLOWMETER_DISTANCE_HORIZONTAL, FLOWMETER_DISTANCE_VERTICAL } from './heat-meter-or-sensors-metadata';

export interface HeatMeterOrSensorsProps {
  direction: Direction;
  showHeatMeter: boolean;
  showForwardHeatMeter: boolean;
  showReturnHeatMeter: boolean;
  showForwardSensor: boolean;
  showReturnSensor: boolean;
}

// the origin of this element should be the point on the supply connection that the temp sensor touches.
export const HeatMeterOrSensors: React.FC<HeatMeterOrSensorsProps> = React.memo(
  ({ direction, showHeatMeter, showForwardHeatMeter, showReturnHeatMeter, showForwardSensor, showReturnSensor }) => {
    const FLOWMETER_DISTANCE = [Direction.EAST, Direction.WEST].includes(direction)
      ? FLOWMETER_DISTANCE_HORIZONTAL
      : FLOWMETER_DISTANCE_VERTICAL;
    const elements: ReactElement[] = [];
    if (showHeatMeter) {
      // vertical line between temp sensors
      elements.push(
        <Connector
          key="verticalFlowLine"
          points={[
            [0, 0],
            [0, CONNECTION_SPACING],
          ]}
        />
      );
    }

    if (showForwardHeatMeter) {
      // the flow meter icon
      elements.push(<FlowMeter key="flowMeter" x={-FLOWMETER_DISTANCE} y={0} />);

      // horizontal part of line to sensor
      elements.push(
        <Connector
          key="supplyConnector"
          points={[
            [0, CONNECTION_SPACING / 3],
            [-FLOWMETER_DISTANCE, CONNECTION_SPACING / 3],
            [-FLOWMETER_DISTANCE, FLOWMETER_SIZE / 2],
          ]}
        />
      );
    }
    if (showReturnHeatMeter) {
      // the flow meter icon
      elements.push(<FlowMeter key="flowMeter" x={-FLOWMETER_DISTANCE} y={CONNECTION_SPACING} />);
      elements.push(
        <Connector
          key="returnConnector"
          points={[
            [0, (CONNECTION_SPACING * 2) / 3],
            [-FLOWMETER_DISTANCE, (CONNECTION_SPACING * 2) / 3],
            [-FLOWMETER_DISTANCE, CONNECTION_SPACING - FLOWMETER_SIZE / 2],
          ]}
        />
      );
    }
    if (showForwardSensor) {
      elements.push(<TemperatureSensor key="tempSensorForward" direction={Direction.EAST} />);
    }
    if (showReturnSensor) {
      elements.push(
        <g key="tempSensorReturn" transform={`translate(0, ${CONNECTION_SPACING})`}>
          <TemperatureSensor direction={Direction.WEST} />
        </g>
      );
    }
    return <g transform={`rotate(${getRotation(direction) - 90})`}>{elements}</g>;
  }
);
