import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'gbc-collapsible',
  templateUrl: './collapsible.component.html',
  styleUrls: ['./collapsible.component.scss'],
})
export class CollapsibleComponent {
  @Input() title: string;

  @Input() toggled = false;

  @Output() toggle = new EventEmitter<boolean>();

  onToggle(): void {
    this.toggled = !this.toggled;
    this.toggle.emit(this.toggled);
  }
}
