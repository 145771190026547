import { Component, OnInit, Input } from '@angular/core';
import { Application } from '../../interfaces/alarm';
import { Observable } from 'rxjs';
import { Installation } from '../../interfaces/facilty';
import { DataPointsResult, DataPointState, DeviceDataPoint } from '../../interfaces/data-points';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'shared';
import { EEquipment } from '@ams/dumbledore';
import { formatDataPoint, getApplicationSetpointFromClientId, getDataOutdatedState, getDataPoint, getSetPointForApplication } from '../../utils/data-point-utils';
import { first, map } from 'rxjs/operators';
import { Value } from '../multi-value-tile/multi-value-tile.component';
import { SetpointModalComponent, SetpointModalInput } from '../setpoint-modal/setpoint-modal.component';

@Component({
  selector: 'app-setpoint-tile',
  templateUrl: './setpoint-tile.component.html',
  styleUrls: ['./setpoint-tile.component.scss'],
})
export class SetpointTileComponent implements OnInit {
  @Input() public application: Application;
  @Input() public installation$: Observable<Installation>;
  @Input() public dataPoints$: Observable<DataPointsResult>;
  @Input() public currentTemperatureDumbledoreId?: EEquipment;
  @Input() public setpointClientId?: string;
  @Input() public headerTitle: string;

  public values$: Observable<Value[]>;
  public setpoint: DeviceDataPoint | null;

  constructor(private translateService: TranslateService, private modalService: ModalService) {}

  ngOnInit(): void {
    this.values$ = this.dataPoints$.pipe(
      map((dataPoints) => {
        this.setpoint = this.setpointClientId
          ? getApplicationSetpointFromClientId({ applicationId: this.application.id, systems: dataPoints.data, clientId: this.setpointClientId })
          : getSetPointForApplication({ applicationId: this.application.id, systems: dataPoints.data });
        const currentValue = getDataPoint({
          applicationId: this.application.id,
          systems: dataPoints.data,
          dumbledoreId: this.currentTemperatureDumbledoreId,
          humanReadableId: '',
        });

        const values: Value[] = [];

        values.push({
          titleKey: 'setpoint.source.label',
          value: this.translateService.instant('setpoint.source.fixed'),
        });

        values.push({
          titleKey: 'setpoint-tile.setpoint.temperature.label',
          value: formatDataPoint(this.setpoint),
          state: getDataOutdatedState(this.setpoint)
        });

        if (this.currentTemperatureDumbledoreId) {
          values.push({
            titleKey: 'setpoint-tile.setpoint.current.label',
            value: formatDataPoint(currentValue),
            state: getDataOutdatedState(currentValue)
          });
        }

        return values;
      })
    );
  }

  adjustSetpoint() {
    this.installation$.pipe(first()).subscribe((installtion) => {
      const data: SetpointModalInput = {
        applicationId: this.application.id,
        installationId: installtion.id,
        temperatureSetPoint: this.setpoint,
        min: 0,
        max: 75,
      };

      this.modalService.openDialog<SetpointModalInput>(SetpointModalComponent, { data }).subscribe((response) => {
        if (response.dismissed) {
          return;
        }
      });
    });
  }
}
