<gbc-tile *ngIf="pasteurizationState$ | async as pasteurizationState" class="pasteurization-status-tile">
  <div class="tile-content">
    <img class="building-icon" src="./assets/svgs/warning.svg" />
    <div class="message-wrapper" [attr.data-test-id]="'gbc-pasteurization-state-' + getPasteurizationStateName(pasteurizationState)">
      <div class="title">
        <span>{{ 'alarm-bar.warning' | translate }}</span>
        <span>{{ 'pasteurization-info.title' | translate }}</span>
      </div>
      <div *ngFor="let applicationState of applicationsState$ | async">
        <div *ngIf="applicationState.pasteurizationState !== PasteurizationState.Off" class="message">
          <span class="systemTitle">{{ applicationState.systemTitle }}</span>
          <span class="stateMessage">{{ getStateMessage(applicationState.pasteurizationState) }}</span>
        </div>
      </div>
    </div>
    <div class="button-wrapper">
      <button gbc-button danger
        [hidden]="(shouldHideStopButton$ | async) === true"
        (click)="cancelPasteurizationJob(pasteurizationState)"
        data-test-id="emergency-stop-button"
        angulartics2On="click"
        angularticsAction="cancelPasteurizationJobClicked"
        angularticsCategory="buildingConnectSchematic">
        {{ 'pasteurization-info.emergency-stop' | translate }}
      </button>
      <button gbc-button danger
        [hidden]="(shouldShowCancelingButton$ | async) === false"
        disabled="true"
        data-test-id="emergency-stop-button-canceling">
        {{ 'pasteurization-info.canceling' | translate }}
      </button>
    </div>
  </div>
</gbc-tile>
