<ng-container *ngIf="facility$ | async as facility">
  <gbc-page-header *ngIf="pageInfo$ | async as pageInfo" [pageInfo]="pageInfo">
    <ng-container gbc-page-header-actions>
      <button
        gbc-menu-item
        (click)="editFacility()"
        angulartics2On="click"
        angularticsAction="editFacilityShown"
        angularticsCategory="buildingConnectFacility"
      >
        <img style="margin-right: 10px" src="./assets/svgs/edit.svg" />
        {{ 'facility-page-content.edit-facility' | translate }}
      </button>
    </ng-container>
  </gbc-page-header>
  <gbc-page-container>
    <gbc-tile [title]="'facility-page-content.installations' | translate" *ngIf="installationViewModels$ | async as installations">
      <gbc-table clickableRows="true" *ngIf="installations.length">
        <th gbc-table-header>{{ 'facility-page-content.installation' | translate }}</th>
        <th gbc-table-header>{{ 'facility-page-content.state' | translate }}</th>
        <tr gbc-table-row *ngFor="let installation of installations" (rowClicked)="installation.navigateTo()">
          <td gbc-table-cell>
            <div class="installation-cell-content">
              <img *ngIf="installation.iconUrl" class="installation-icon" [src]="installation.iconUrl" />
              <div>{{ installation.name || installation.id }}</div>
            </div>
          </td>
          <td gbc-table-cell>
            <ng-container *ngIf="installation.stateTextKey">{{ installation.stateTextKey | translate }}</ng-container>
          </td>
        </tr>
      </gbc-table>

      <div *ngIf="!installations.length" style="text-align: center; padding: 2em">
        {{ 'facility-page-content.no-installations-on-facility' | translate }}
      </div>
    </gbc-tile>
  </gbc-page-container>
</ng-container>
