import { Component, Input } from '@angular/core';
import { AlarmOverview, Application } from 'projects/customerportal/src/app/interfaces/alarm';
import { Observable } from 'rxjs';
import { DataPointsResult } from 'projects/customerportal/src/app/interfaces/data-points';
import { SchematicsService } from '../../../services/schematics.service';
import { AppModel } from '@ams/dumbledore';
import { Installation } from '../../../interfaces/facilty';

@Component({
  selector: 'app-cold-water-supply-dashboard',
  templateUrl: './cold-water-supply-dashboard.component.html',
  styleUrls: ['./cold-water-supply-dashboard.component.scss'],
})
export class ColdWaterSupplyDashboardComponent {
  @Input() public alarm$: Observable<AlarmOverview>;
  @Input() application: Application;
  @Input() public installation$: Observable<Installation>;
  @Input() public schematic$: Observable<AppModel>;
  @Input() public dataPoints$: Observable<DataPointsResult>;

  constructor(private schematicService: SchematicsService) {}
}
