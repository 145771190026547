import { Component, OnDestroy, Input, TemplateRef, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { AppError } from '../../services/app-error.service';
import { PageSpinnerComponent } from '../page-spinner/page-spinner.component';

// This component displays an error message if the page fails to load.
// The page must provide a AppError observable, which either emits a single AppError object
// or completes without emitting anything, that is, the PageError observable should NOT throw.
// The createErrorProvider utility function is used to create PageError providers.
// Note that the AppErrors should contain appropriate messages to display to the user.
// The AppErrorService is used to create AppErrors.
@Component({
  selector: 'gbc-page-error-handler',
  templateUrl: './page-error-handler.component.html',
  styleUrls: ['./page-error-handler.component.scss']
})
export class PageErrorHandlerComponent implements OnInit, OnDestroy {

  public loading = true;
  public pageError: AppError;
  private subscription: Subscription;

  @Input()
  content: TemplateRef<any>;

  @Input()
  pageError$: Observable<AppError>;

  constructor(private pageSpinner: PageSpinnerComponent) {
  }

  ngOnInit(): void {
    this.pageSpinner.push(this.pageError$);
    this.subscription = this.pageError$.subscribe({
      next: pageError => this.pageError = pageError,
      complete: () => this.loading = false
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.pageSpinner.pop();
  }
}
