import { SystemConnectionPointMeta } from '../components/types';
import { systemMetadataMap } from '../components/system-metadata';
import { ConnectionPointViewModel } from '../components/ConnectionPoint';
import { ConnectionPointIdentifier, FreeConnectionPointState, SchematicState, SystemState } from '../state';

export const getConnectionPointViewModel = (state: SchematicState, id: ConnectionPointIdentifier): ConnectionPointViewModel => {
  switch (id.type) {
    case 'FREE':
      const point = state.points.find((p) => p.hasId === id.id) as FreeConnectionPointState;
      return {
        id,
        x: point.x,
        y: point.y,
        type: point.type,
      };
    case 'SYSTEM': {
      const system = state.systems.find((s) => s.hasId === id.systemId) as SystemState;
      const systemMeta = systemMetadataMap[system.type];
      const systemConnectionPoint = systemMeta
        .calculateConnectionPoints(system.systemInfo)
        .find((p) => p.key === id.key) as SystemConnectionPointMeta;
      return {
        id,
        x: system.left + systemConnectionPoint.x,
        y: system.top + systemConnectionPoint.y,
        type: systemConnectionPoint.type,
      };
    }
  }
};

export const getConnectionPointVms = (points: FreeConnectionPointState[], systems: SystemState[]): ConnectionPointViewModel[] => {
  const freeConnectionPoints: ConnectionPointViewModel[] = points.map((p) => {
    return {
      id: {
        type: 'FREE',
        id: p.hasId,
        connectionPointType: p.type,
        dotted: p.dotted,
      },
      x: p.x,
      y: p.y,
      type: p.type,
      connectionPointType: p.type,
    };
  });

  const systemConnectionPoints: ConnectionPointViewModel[] = systems.flatMap((system) => {
    // This is where we render the system connection points
    const systemMeta = systemMetadataMap[system.type];
    return systemMeta.calculateConnectionPoints(system.systemInfo).map((point) => {
      return {
        id: {
          type: 'SYSTEM',
          systemId: system.hasId,
          key: point.key,
          connectionPointType: point.type,
          dotted: point.dotted,
        },
        x: system.left + point.x,
        y: system.top + point.y,
        type: point.type,
        dotted: point.dotted,
      };
    });
  });
  return [...freeConnectionPoints, ...systemConnectionPoints];
};

export const connectionPointKey = (id: ConnectionPointIdentifier): string => {
  if (id.type === 'FREE') {
    return id.id;
  }
  return `${id.systemId}-${id.key}`;
};

export interface IconInfo {
  key: string;
  visible: boolean;
  offset?: number;
}

export class IconPositionHandler {
  private icons: IconInfo[] = [];
  private iconWidth: number = 24;
  private iconPadding: number = 0;

  constructor(icons?: IconInfo[], iconWidth?: number, iconPadding?: number) {
    this.iconWidth = iconWidth || this.iconWidth;
    this.iconPadding = iconPadding || this.iconPadding;
    icons?.forEach(icon => {
      this.addIcon(icon);
    });
  }

  addIcon = (icon: IconInfo) => {
    icon.offset = 0;
    this.icons.push(icon);
    this.calculateIconPositions();
  }

  getIcon = (key: string): IconInfo | undefined => {
    return this.icons.find(icon => icon.key === key);
  }

  getTotalWidth = () => {
    const visibleIcons = this.icons.filter(icon => icon.visible);
    const iconSpacingings = (visibleIcons.length > 0 ? visibleIcons.length - 1 : 0);
    return this.iconWidth * visibleIcons.length + (iconSpacingings * this.iconPadding);
  }

  // Calculates the icon's X offset with respect to icon index and number of visible icons.
  private calculateIconPositions = () => {
    let visibleIconIndex = 0;
    const iconTotalWidth = this.getTotalWidth();
    this.icons.forEach((icon) => {
      if (icon.visible) {
        icon.offset = - (iconTotalWidth / 2) + ((this.iconWidth + this.iconPadding) * visibleIconIndex);
        visibleIconIndex++;
      }
    });
  }
}
