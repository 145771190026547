<app-mixit-x509-banner/>
<app-alarm-bar *ngIf="alarm$ | async" [alarm]="alarm$"> </app-alarm-bar>

<app-schematics-tile
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [showShadow]="false"
  [dataPoints$]="dataPoints$"
>
</app-schematics-tile>

<app-pump-status-tile
  *ngIf="pumpDevice$ | async"
  [installation$]="installation$"
  [application]="application"
  [pumpType$]="pumpType$"
  [pumpDevice$]="pumpDevice$"
  [pumpOptions]="pumpOptions$ | async"
>
</app-pump-status-tile>

<app-temperature-chart-tile
  [title]="'buffer-tank-dashboard.temperatures' | translate"
  [colors]="temperatureColors"
  [applicationId]="application.id"
  [series]="temperatureSeries$ | async"
  [dataPoints$]="dataPoints$"
  [userTrackingPeriodOptions]="{ category: 'application/bufferTank', intervalPrefix: 'temperatures' }"
>
</app-temperature-chart-tile>

<app-temperature-chart-tile
  [title]="'buffer-tank-dashboard.tank-temperatures' | translate"
  [colors]="tankTemperatureColors"
  [applicationId]="application.id"
  [series]="tankTemperatureSeries$ | async"
  [dataPoints$]="dataPoints$"
  [userTrackingPeriodOptions]="{ category: 'application/bufferTank', intervalPrefix: 'tankTemperatures' }"
>
</app-temperature-chart-tile>
