import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Facility } from '../../interfaces/facilty';
import { navigateToFacility } from '../../utils/navigate-utils';
import { Router } from '@angular/router';

@Component({
  selector: 'app-facility-list',
  templateUrl: './facility-list.component.html',
  styleUrls: ['./facility-list.component.scss']
})
export class FacilityListComponent {
  @Input()
  facilities: Facility[];

  constructor(private router: Router) {}

  public navigateToFacility(facility: Facility) {
    navigateToFacility(this.router, facility);
  }


}
