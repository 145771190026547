import { SystemType, IndirectDistrictHeatingSupplySystemState } from '../state';
import { EEquipment, EValueType } from '../types';
import { SampleEquipmentValue } from './sample-equipment-values';
import { v4 } from 'uuid';

export const SAMPLE_INDIRECT_DISTRICT_HEATING_SUPPLY: IndirectDistrictHeatingSupplySystemState = {
  hasId: v4(),
  type: SystemType.IndirectDistrictHeatingSupplySystem,
  left: 230,
  top: 10,
  configured: false,
  systemInfo: {
    HM_present: true,
    HM_location: 'PrimaryReturn',
    P_location: 'SecondaryForward',
    Control_enabled: true,
    MV_valveLocation: 'PrimaryForward',
    MV_Parallel_Valve_Enabled: true,
    T_SR_present: true,
    DP_S_present: true,
    DP_P_present: true,
    hasDirtCollector: true,
    Outtake_Before_HeatEx: true,
    Has_Slave_Pump: true,
    DP_location: 'PrimaryForward',
    T_PF_present: true,
    T_PR_present: true,
    T_SF_present: true,
  },
};

export const SAMPLE_VALUES_INDIRECT_DISTRICT_HEATING_SUPPLY: SampleEquipmentValue[] = [
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.value, equipment: EEquipment.T_F, value: '82.9\u2103', unit: "Temperature" },
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.value, equipment: EEquipment.T_R, value: '42.9\u2103', unit: "Temperature" },
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.value, equipment: EEquipment.Q_PF, value: '10.3\u33a5/s' },
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.value, equipment: EEquipment.Q_PR, value: '10.3\u33a5/s' },
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.value, equipment: EEquipment.H_DIFF_P, value: '1.8 Bar' },
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.value, equipment: EEquipment.H_DIFF_S, value: '1.23 Bar' },
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.value, equipment: EEquipment.T_PF, value: '72.9\u2103' },
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.value, equipment: EEquipment.T_PR, value: '33.7\u2103' },
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.value, equipment: EEquipment.T_SF, value: '54.3\u2103' },
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.value, equipment: EEquipment.T_SR, value: '25.3\u2103' },
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.setPoint, equipment: EEquipment.T_SF, value: '55\u2103' },
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.value, equipment: EEquipment.MV_2W_PF, value: '13%' },
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.value, equipment: EEquipment.MV_2W_PR, value: '13%' },
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.setPoint, equipment: EEquipment.MV_2W_PF, value: '24%' },
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.valveIndication, equipment: EEquipment.MV_2W_PF, value: 'opening' },
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.setPoint, equipment: EEquipment.MV_2W_PR, value: '24%' },
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.valveIndication, equipment: EEquipment.MV_2W_PR, value: 'closing' },
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.value, equipment: EEquipment.P_1, value: '42.7\u2103\n10.7m\n2m' },
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.value, equipment: EEquipment.P_2, value: '69.7\u2103\n10.7m\n2m' },
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.value, equipment: EEquipment.H_P, value: '0.2 Bar' },
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.value, equipment: EEquipment.H_S, value: '0.3 Bar' },
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.value, equipment: EEquipment.H_DIFF_PR, value: '0.3 Bar' },
  { systemType: SystemType.IndirectDistrictHeatingSupplySystem, type: EValueType.value, equipment: EEquipment.H_DIFF_PF, value: '0.3 Bar' },
];
