<ng-container *ngIf="state$ | async as state">
  <div class="last-updated" pTooltip="{{ updatedText$ | async }}" tooltipPosition="top" escape>
    <div class="description">
      {{ 'last-updated.' + state | translate }}
    </div>
    <div
      class="circle"
      [ngClass]="{
        green: state === LastUpdatedState.upToDate,
        yellow: state === LastUpdatedState.delayed || state === LastUpdatedState.noData,
        red: state === LastUpdatedState.outdated || state === LastUpdatedState.alarm
      }"
    ></div>
  </div>
</ng-container>
