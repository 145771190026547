import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Angulartics2 } from 'angulartics2';
import { Subscription } from 'rxjs';

export class UserTrackingPeriodOptions {
  category: string;
  intervalPrefix: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserTrackingHelperService {

  constructor(
    private angulartics2: Angulartics2
  ) { }

  public trackUserAction(category: string, action: string) {
    this.angulartics2.eventTrack.next({
      action,
      properties: { category },
    });
  }

  public trackSetPumpControlMode(category: string, controlMode: string) {
    const action = `${controlMode}Clicked`;
    this.angulartics2.eventTrack.next({
      action,
      properties: { category },
    });
  }

  public trackTabSelected(category: string, tabName: string) {
    this.angulartics2.eventTrack.next({
      action: `${this.capitalizeFirstLetter(tabName)}Clicked`,
      properties: { category, eventName: 'Tab change', tabName },
    });
  }

  public trackPeriodSelected(category: string, trackingIntervalPrefix: string, periodName: string) {
    const action = `${trackingIntervalPrefix}${this.toPascalCase(periodName)}Clicked`;
    this.angulartics2.eventTrack.next({
      action,
      properties: { category },
    });
  }

  public startFormValueChangeTracking(category: string, group: UntypedFormGroup): Subscription {
    // Note: This method doesn't work for nested controls (sub-forms)
    const subscription = new Subscription();
    for (let key in group.controls) {
      const abstractControl = group.controls[key];
      subscription.add(abstractControl.valueChanges.subscribe(newValue => {
        if (typeof newValue !== 'object' || newValue === null || newValue === undefined) {
          const oldValue = group.value[key];
          this.trackFormValueChanged(category, key, oldValue, newValue);
        }
        else if (newValue.name) {
          const oldValue = group.value[key].value;
          this.trackFormValueChanged(category, key, oldValue, newValue.value);
        }
        else if (Object.keys(newValue).length > 0) {
          const oldValue = group.value[key];
          this.trackFormValueChanged(category, key, oldValue, newValue);
        }
      }));
    }
    return subscription;
  }

  private trackFormValueChanged(category: string, key: string, oldValue: any, newValue: any) {
    this.angulartics2.eventTrack.next({
      action: `${key}Changed`,
      properties: { category, oldValue, newValue },
    });
  }

  private capitalizeFirstLetter(s: string): string {
    return s.charAt(0).toUpperCase() + s.slice(1);
  }

  private toPascalCase(s: string): string {
    return s.split(' ').map(word => this.capitalizeFirstLetter(word)).join('');
  }
}
