import { Component, Input } from '@angular/core';

@Component({
  selector: 'gbc-form-row',
  templateUrl: './form-row.component.html',
  styleUrls: ['./form-row.component.scss'],
})
export class FormRowComponent {
  @Input() public header: string;
  @Input() public description?: string;
  @Input() public tooltip?: string;
  @Input() public required?: boolean;
}
