import React from 'react';
import { SvgImage } from '../SvgImage';
import { Pipe, PipeType } from '../Pipe';
import { CONNECTION_SPACING } from '../Common';
import radiatorIcon from '!raw-loader!../../../../assets/icons/radiator-small.svg';
import { RADIATOR_OFFSET, RADIATOR_SVG_HEIGHT, RADIATOR_SVG_WIDTH, RADIATOR_VALVE_Y } from './common';

const RADIATOR_SPACING = 10;
const TOTAL_RADIATOR_WIDTH = 2 * RADIATOR_SVG_WIDTH + RADIATOR_SPACING;
const CENTER = CONNECTION_SPACING / 2;
const FIRST_RADIATOR_LEFT = CENTER - TOTAL_RADIATOR_WIDTH / 2;
const FIRST_RADIATOR_RIGHT = FIRST_RADIATOR_LEFT + RADIATOR_SVG_WIDTH;
const SECOND_RADIATOR_LEFT = CENTER + RADIATOR_SPACING / 2;
const PIPE_TO_RADIATOR_SPACING = RADIATOR_OFFSET;
const RADIATOR_SUPPLY_X = FIRST_RADIATOR_LEFT - PIPE_TO_RADIATOR_SPACING;
const RADIATOR_RETURN_X = FIRST_RADIATOR_LEFT + TOTAL_RADIATOR_WIDTH + PIPE_TO_RADIATOR_SPACING;
const WARM_TO_COLD_Y = RADIATOR_SVG_HEIGHT + 8;
const RADIATOR_INLET_Y = RADIATOR_VALVE_Y;
const RADIATOR_OUTLET_Y = RADIATOR_SVG_HEIGHT - RADIATOR_VALVE_Y;

export const PIPE_BOTTOM = WARM_TO_COLD_Y + 16;

export const RadiatorOneString: React.FC<{ x: number; y: number }> = ({ x, y }) => {
  return (
    <g transform={`translate(${x}, ${y})`}>
      <Pipe x1={0} y1={PIPE_BOTTOM} x2={RADIATOR_SUPPLY_X} y2={PIPE_BOTTOM} type={PipeType.WarmSupply} />
      <Pipe x1={RADIATOR_SUPPLY_X} y1={PIPE_BOTTOM} x2={RADIATOR_SUPPLY_X} y2={RADIATOR_INLET_Y} type={PipeType.WarmSupply} />
      <Pipe x1={RADIATOR_SUPPLY_X} y1={RADIATOR_INLET_Y} x2={0} y2={RADIATOR_INLET_Y} type={PipeType.WarmSupply} />
      <Pipe x1={CONNECTION_SPACING} y1={PIPE_BOTTOM} x2={RADIATOR_RETURN_X} y2={PIPE_BOTTOM} type={PipeType.ColdReturn} />
      <Pipe x1={RADIATOR_RETURN_X} y1={PIPE_BOTTOM} x2={RADIATOR_RETURN_X} y2={RADIATOR_OUTLET_Y} type={PipeType.ColdReturn} />
      <Pipe
        x1={RADIATOR_RETURN_X}
        y1={RADIATOR_OUTLET_Y}
        x2={SECOND_RADIATOR_LEFT + RADIATOR_SVG_WIDTH}
        y2={RADIATOR_OUTLET_Y}
        type={PipeType.ColdReturn}
      />
      <line
        x1={RADIATOR_SUPPLY_X}
        y1={WARM_TO_COLD_Y}
        x2={RADIATOR_RETURN_X}
        y2={WARM_TO_COLD_Y + 0.00001}
        stroke="url(#warmToCold)"
        strokeWidth="2"
      />

      {/*outlet from first radiator */}
      <Pipe
        x1={FIRST_RADIATOR_RIGHT}
        y1={RADIATOR_OUTLET_Y}
        x2={FIRST_RADIATOR_RIGHT + 3}
        y2={RADIATOR_OUTLET_Y}
        type={PipeType.ColdReturn}
      />
      <Pipe
        x1={FIRST_RADIATOR_RIGHT + 3}
        y1={RADIATOR_OUTLET_Y}
        x2={FIRST_RADIATOR_RIGHT + 3}
        y2={WARM_TO_COLD_Y}
        type={PipeType.ColdSupply}
      />

      {/*inlet to second radiator */}
      <Pipe x1={SECOND_RADIATOR_LEFT} y1={WARM_TO_COLD_Y} x2={SECOND_RADIATOR_LEFT} y2={RADIATOR_INLET_Y} type={PipeType.WarmSupply} />
      <Pipe
        x1={SECOND_RADIATOR_LEFT + RADIATOR_OFFSET}
        y1={RADIATOR_INLET_Y}
        x2={SECOND_RADIATOR_LEFT}
        y2={RADIATOR_INLET_Y}
        type={PipeType.WarmSupply}
      />
      <SvgImage key="firstRadiator" svgText={radiatorIcon} x={FIRST_RADIATOR_LEFT} y={0} />
      <SvgImage key="secondRadiator" svgText={radiatorIcon} x={SECOND_RADIATOR_LEFT} y={0} />
    </g>
  );
};
