import * as metaData from './indirect-district-heating-supply-metadata';
import { Direction } from '../../Common';
import { Pipe, PipeType } from '../../Pipe';
import { PumpIcon } from '../../PumpIcon';
import React, { FunctionComponent } from 'react';

type SecondPump = {
  location: 'SecondaryForward' | 'SecondaryReturn';
};

export const SecondPumpComponent: FunctionComponent<SecondPump> = ({ location }) => {
  const xSpaceFromPump = 35;
  const ySpaceFromPump = 60;
  return (
    <>
      {location === 'SecondaryForward' && (
        <>
          <Pipe
            key="secondPumpLeft"
            x1={metaData.PUMP_X - xSpaceFromPump}
            x2={metaData.PUMP_X - xSpaceFromPump}
            y1={metaData.SUPPLY_Y}
            y2={metaData.SUPPLY_Y + ySpaceFromPump}
            type={PipeType.WarmSupply}
          />
          <Pipe
            key="secondPumpRight"
            x1={metaData.PUMP_X + xSpaceFromPump}
            x2={metaData.PUMP_X + xSpaceFromPump}
            y1={metaData.SUPPLY_Y}
            y2={metaData.SUPPLY_Y + ySpaceFromPump}
            type={PipeType.WarmSupply}
          />
          <Pipe
            key="vertical"
            x1={metaData.PUMP_X - xSpaceFromPump}
            x2={metaData.PUMP_X + xSpaceFromPump}
            y1={metaData.SUPPLY_Y + ySpaceFromPump}
            y2={metaData.SUPPLY_Y + ySpaceFromPump}
            type={PipeType.WarmSupply}
          />
          <PumpIcon direction={Direction.EAST} x={metaData.PUMP_X} y={metaData.SUPPLY_Y + ySpaceFromPump} />
        </>
      )}
      {location === 'SecondaryReturn' && (
        <>
          <Pipe
            key="secondPumpLeft"
            x1={metaData.PUMP_X - 45}
            x2={metaData.PUMP_X - 45}
            y1={metaData.RETURN_Y}
            y2={metaData.RETURN_Y - 70}
            type={PipeType.ColdReturn}
          />
          <Pipe
            key="secondPumpRight"
            x1={metaData.PUMP_X + 45}
            x2={metaData.PUMP_X + 45}
            y1={metaData.RETURN_Y}
            y2={metaData.RETURN_Y - 70}
            type={PipeType.ColdReturn}
          />
          <Pipe
            key="vertical"
            x1={metaData.PUMP_X - 45}
            x2={metaData.PUMP_X + 45}
            y1={metaData.RETURN_Y - 70}
            y2={metaData.RETURN_Y - 70}
            type={PipeType.ColdReturn}
          />
          <PumpIcon direction={Direction.WEST} x={metaData.PUMP_X} y={metaData.RETURN_Y - 70} />
        </>
      )}
    </>
  );
};
