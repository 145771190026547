import { LabelPosition, SystemMeta } from '../../types';
import { EConnectionPointPlacement } from '../../../types';
import { EConnectionType, SystemType } from '../../../state';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/heat-exchanging-system.svg';
import heatExchangerSystemForm from '../../../../models/systems/heatExchangingSystem/heatExchangingSystemForm.json';
import { commonHeatExchangerLabels, FORWARD_Y, RETURN_Y } from '../../heat-exchanger-common/heat-exchanger-common-metadata';
import { IHeatExchangingSystemFormInterface } from '../../../../models/systems/heatExchangingSystem/heatExchangingSystemFormInterface';

export const WIDTH = 380;
export const HEIGHT = 260;

export const heatExchangerMeta: SystemMeta = {
  systemType: SystemType.HeatExchanger,
  name: 'Heat Exchanger',
  thumbnail,
  form: heatExchangerSystemForm,
  width: WIDTH,
  height: HEIGHT,
  calculateConnectionPoints: () => [
    {
      key: EConnectionPointPlacement.connectionPrimaryForward,
      x: 0,
      y: FORWARD_Y,
      type: EConnectionType.supply,
      dotted: false,
    },
    {
      key: EConnectionPointPlacement.connectionPrimaryReturn,
      x: 0,
      y: RETURN_Y,
      type: EConnectionType.return,
      dotted: true,
    },
    {
      key: EConnectionPointPlacement.connectionSecondaryForward,
      x: WIDTH,
      y: FORWARD_Y,
      type: EConnectionType.supply,
      dotted: false,
    },
    {
      key: EConnectionPointPlacement.connectionSecondaryReturn,
      x: WIDTH,
      y: RETURN_Y,
      type: EConnectionType.return,
      dotted: true,
    },
  ],
};
