<gbc-modal [title]="'license-upgrade.modal-upgrade-devices' | translate" [showDismiss]="false" [allowDismissOnBackdropClick]="false">
  <gbc-modal-content>
    <div id="result">
      <div *ngIf="currentRequest" class="request">
        <h3>{{ currentRequest.installationName }}</h3>
        <gbc-loading-bar></gbc-loading-bar>
      </div>
      <ng-container *ngFor="let request of installationRequestResults">
        <div *ngIf="request.status !== status.PENDING" class="request completed">
          <h3>{{ request.name }}</h3>
          <div *ngIf="request.status === status.ERROR">
            <span>{{ 'license-upgrade.' + request.status | translate }}</span>
            <p><small>{{ 'license-upgrade.' + request.errorCode | translate }}</small></p>
          </div>
          <div *ngIf="request.status === status.SUCCESS">
            <span>{{ 'license-upgrade.' + request.status | translate }}</span>
          </div>
        </div>
      </ng-container>
    </div>
  </gbc-modal-content>
  <gbc-modal-buttons>
    <button id="done" [disabled]="!requestsCompleted" gbc-button (click)="onDone()">
      <ng-container *ngIf="requestErrors">{{ 'app-ok' | translate }}</ng-container>
      <ng-container *ngIf="!requestErrors">{{ 'app-done' | translate }}</ng-container>
    </button>
  </gbc-modal-buttons>
</gbc-modal>
