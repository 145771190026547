import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  Self,
  Optional,
  OnDestroy,
  SimpleChanges
} from '@angular/core';
import { NgControl, ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs';

enum NgControlStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  PENDING = 'PENDING',
  DISABLED = 'DISABLED'
}

@Component({
  selector: 'gbc-input-numeric-field',
  templateUrl: './input-numeric-field.component.html',
  styleUrls: ['./input-numeric-field.component.scss']
})
export class InputNumericFieldComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() max: number;
  @Input() min: number;
  @Input() step: number = 1;
  @Input() maxlength: number;
  @Input() placeholderTranslationString: string;
  @Input() inputType = 'number';
  @Input() errorDescriptionTranslationString: string;
  @Input() errorDescriptionData: { [k: string]: string } = {};
  @Input() formControlName: string;
  @Input() unit: string;
  @Input() disabled: boolean;
  @Input() showErrorTranslationString: boolean;
  @Input() matchRelativeParentDimensions: boolean;
  @Input() fixedWidthStyle: string;

  @Output() fieldKeyupEvent = new EventEmitter<string>();

  public valid: boolean;
  private subscription = new Subscription();

  // NgModel
  innerValue: any;
  private onTouchedCallback: () => void = () => {};
  private onChangeCallback: (_: any) => void = () => {};

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v === this.innerValue) {
      return;
    }
    this.innerValue = v;
    this.onChangeCallback(v);
  }

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    const setValid = (status: NgControlStatus) => {
      this.valid = status === NgControlStatus.VALID || status === NgControlStatus.DISABLED;
    };

    this.subscription.add(this.ngControl.statusChanges?.subscribe(setValid));

    // Calculate the state on start, as there is no event
    setValid(this.ngControl.status as NgControlStatus);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onKey(value: string) {
    this.fieldKeyupEvent.emit(value);
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: any) {
    if (value === this.innerValue) {
      return;
    }
    if (this.inputType === 'number') {
      this.innerValue = parseFloat(value);
    } else {
      this.innerValue = value;
    }
  }

  registerOnChange(fn: (_: number | null) => void): void {
    this.onChangeCallback = (value) => {
      if (this.inputType === 'number') {
        fn(value === '' ? null : parseFloat(value));
      } else {
        fn(value);
      }
    };
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  incrementStep(): void {
    let value = Number(this.value) + Number(this.step);
    value = isNaN(value) ? this.min : value;
    value = (value > this.max) ? Number(this.max) : value;
    value = (value < this.min) ? Number(this.min) : value;
    this.value = value;
  }

  decrementStep(): void {
    let value = Number(this.value) - Number(this.step);
    value = isNaN(value) ? this.min : value;
    value = (value < this.min) ? Number(this.min) : value;
    value = (value > this.max) ? Number(this.max) : value;
    this.value = value;
  }
}
