import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { Installation } from '../../../interfaces/facilty';
import { AppModel, SystemType } from '@ams/dumbledore';
import { AlarmOverview, Application } from '../../../interfaces/alarm';
import { first, map } from 'rxjs/operators';
import { WeatherCompensationCurve } from '../../../interfaces/weather-compensation';
import {
  getDatapoint,
  installationCommissionState,
  isDynamic,
  isLessThanHalfHourSinceClaim,
  manualForcedOpeningEnabled,
  outdoorTemp,
  setPointSource,
  shouldShowPumpTile,
  weatherCurve,
} from '../../../utils/mixit-utils';
import { TranslateService } from '@ngx-translate/core';
import { InstallationConfigurationService } from '../../../services/installation-configuration.service';
import { DataPointsResult, DeviceDataPoint } from '../../../interfaces/data-points';
import { ConfigurationService, ModalService } from 'shared';
import { ActivatedRoute, Router } from '@angular/router';
import { LicenseType, MixitDataPointName, SetpointSource } from '../../../interfaces/mixit';
import moment from 'moment';
import { SchematicsService } from '../../../services/schematics.service';
import { AlarmService } from '../../../services/alarm.service';
import { IMixitSystemFormInterface } from '../../../../../../dumbledore/src/models/systems/mixitSystem/mixitSystemFormInterface';
import { parseBoolean } from '../../../utils/data-point-utils';
import { ICommand, MixitService } from '../../../services/mixit-service.service';
import { SystemDeviceType } from '../../../interfaces/systemDeviceType';

@Component({
  selector: 'app-mixit-premium-dashboard',
  templateUrl: './mixit-premium-dashboard.component.html',
  styleUrls: ['./mixit-premium-dashboard.component.scss'],
})
export class MixitPremiumDashboardComponent implements OnInit {
  @Input() public installation$: Observable<Installation>;
  @Input() public schematic$: Observable<AppModel>;
  @Input() public application$: Observable<Application>;
  @Input() public dataPoints$: Observable<DataPointsResult>;
  @Input() public alarm$: Observable<AlarmOverview>;

  public isDynamic$: Observable<boolean>;
  public installationCommissionState$: Observable<string>;
  public outdoorTemp$: Observable<number>;
  public weatherCurve$: Observable<WeatherCompensationCurve>;
  public applicationType$: Observable<DeviceDataPoint | null>;
  public isLessThanHalfHourSinceClaim$: Observable<boolean>;
  public trialRunningMessage$: Observable<string>;
  public showLimitersBanner$: Observable<boolean>;
  public manualForcedOpeningEnabled$: Observable<boolean>;
  public showPumpTile$: Observable<boolean>;
  public setpointSource$: Observable<string>;
  public showHeatCoilProtectionTile$: Observable<boolean>;
  public showUnderfloorProtection$: Observable<boolean>;
  public showWeatherCurve$: Observable<boolean>;

  constructor(
    private alarmService: AlarmService,
    private translateService: TranslateService,
    private weatherCompensationService: InstallationConfigurationService,
    private modalService: ModalService,
    public route: ActivatedRoute,
    public router: Router,
    private schematicService: SchematicsService,
    private configurationService: ConfigurationService,
    private mixitService: MixitService
  ) {}

  ngOnInit(): void {
    // This is for debugging purposes
    // @ts-ignore
    window.sendCommand = (commands: ICommand[]) => {
      combineLatest([this.installation$, this.application$])
        .pipe(first())
        .subscribe(([installation, application]) => {
          this.mixitService.sendCommand(installation, application.id, commands);
        });
    };

    // General
    this.installationCommissionState$ = installationCommissionState(this.installation$);
    this.outdoorTemp$ = outdoorTemp(this.dataPoints$).pipe(
      map((d) => {
        return parseFloat(d?.value as string);
      })
    );
    this.weatherCurve$ = weatherCurve(this.dataPoints$);
    this.applicationType$ = getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.ApplicationType);
    this.isDynamic$ = isDynamic(this.schematic$);
    this.isLessThanHalfHourSinceClaim$ = isLessThanHalfHourSinceClaim(this.installation$);
    this.manualForcedOpeningEnabled$ = manualForcedOpeningEnabled(this.dataPoints$);

    this.showPumpTile$ = shouldShowPumpTile(this.schematic$);

    this.setpointSource$ = setPointSource(this.dataPoints$);

    this.showHeatCoilProtectionTile$ = this.schematic$.pipe(
      map((schematic) => {
        const mixitApplication = schematic.systems.find((s) => s.type === SystemType.Mixit);
        if (!mixitApplication) {
          return false;
        }
        return (mixitApplication.systemInfo as IMixitSystemFormInterface).Application === 'MIXING_LOOP_APPLICATION_TYPE_HEATING_COIL';
      })
    );

    this.showUnderfloorProtection$ = this.schematic$.pipe(
      map((schematic) => {
        const mixitApplication = schematic.systems.find((s) => s.type === SystemType.Mixit);
        if (!mixitApplication) {
          return false;
        }
        return (mixitApplication.systemInfo as IMixitSystemFormInterface).Application === 'MIXING_LOOP_APPLICATION_TYPE_FLOOR_HEATING';
      })
    );

    // Weather curve
    this.showWeatherCurve$ = this.setpointSource$.pipe(
      map((source) => {
        return source === SetpointSource.MixingLoopSetpointSourceOutdoorTemperatureAnalogueInput;
      })
    );

    // Banners
    this.trialRunningMessage$ = this.installation$.pipe(
      map((installation) => {
        const licenses = installation.licenseInformation;
        if (!licenses?.length) {
          return null;
        }

        const connectTrialLicense = licenses.find((l) => l.licenseType === LicenseType.CodeConnectTrial);
        if (!connectTrialLicense) {
          return null;
        }

        const now = +new Date();
        if (now >= connectTrialLicense.expirationTimeEpoch) {
          // The trial period has already ended
          return null;
        }
        if (!connectTrialLicense.nudgeTimeEpoch) {
          // If we haven't nudged the user, then we don't show the banner
          return null;
        }

        const days = moment(connectTrialLicense.expirationTimeEpoch).diff(moment(now), 'days');

        if (days === 0) {
          return this.translateService.instant('mixit-premium-dashboard.trial-nudge-today');
        }

        return this.translateService.instant('mixit-premium-dashboard.trial-nudge', { days });
      })
    );

    this.showLimitersBanner$ = combineLatest([
      getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.SupplyFlowLimiterEnabled),
      getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.ThermalPowerLimiterEnabled),
      getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.DifferentialTemperatureLimiterEnabled),
      getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.ReturnTemperatureLimiterEnabled),
    ]).pipe(
      map((values) => {
        return false; // Temporarily disable, until we change the values to use active instead of enabled
        return values.some((d) => parseBoolean(d?.value));
      })
    );
  }

  public saveNewCurve(curve: { linear: boolean; curve: Array<[number, number]>; resetToDefault: boolean }) {
    combineLatest([this.installation$, this.application$])
      .pipe(first())
      .subscribe(([installation, application]) => {
        this.mixitService.setWeatherCurve(installation, application.id, curve);
      });
  }

  public navigateToAlarm(alarm: AlarmOverview) {
    const { facilityId, installationId } = this.route.snapshot.params;
    this.router.navigate(['/facility', facilityId, 'installation', installationId, 'system-status', 'alarm', alarm.id]);
  }

  public navigateToAllAlarms() {
    const { facilityId, installationId } = this.route.snapshot.params;
    this.router.navigate(['/facility', facilityId, 'installation', installationId, 'system-status']);
  }
}
