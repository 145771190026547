import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { DataPointsResult, DeviceDataPoint } from '../../../interfaces/data-points';
import { UserTrackingPeriodOptions } from '../../../services/user-tracking-helper.service';
import { TranslateService } from '@ngx-translate/core';
import { filter, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { Application } from '../../../interfaces/alarm';
import { DataPointsService, ReportPeriod, TimeSerie, TimeSeriesDetails } from '../../../services/data-points.service';
import { Options, SeriesOptionsType } from 'highcharts';
import { getDataPoint } from '../../../utils/data-point-utils';
import {ChartInterface} from "../chart.interface";

@Component({
  selector: 'app-energy-valve-temperature-chart[applicationId][installationId]',
  templateUrl: './energy-valve-temperature-chart.component.html',
  styleUrls: ['./energy-valve-temperature-chart.component.scss'],
})
export class EnergyValveTemperatureChartComponent implements OnInit, ChartInterface {
  @Input() dataPoints$: Observable<DataPointsResult>;
  @Input() applicationId: string;
  @Input() installationId: string;
  @Input() userTrackingPeriodOptions: UserTrackingPeriodOptions;
  public getSeries: (period: ReportPeriod) => Observable<SeriesOptionsType[]>;
  public visible$: Observable<boolean>;
  public legends$ = combineLatest([
    this.translateService.get('energy-valve-temperature-chart.supply'),
    this.translateService.get('energy-valve-temperature-chart.return'),
    this.translateService.get('energy-valve-temperature-chart.delta-t'),
  ]);
  public dataPointsForChart$: Observable<(DeviceDataPoint | null)[]>;

  public options: Options = {
    yAxis: [
      {
        title: {
          text: null,
        },
        labels: {
          format: '{value} C',
        },
        min: 0,
      },
    ],
    plotOptions: {
      series: {
        tooltip: {
          valueDecimals: 1,
        },
      },
    },
  };

  constructor(private translateService: TranslateService, private dataPointsService: DataPointsService) {}
  ngOnInit() {
    this.dataPointsForChart$ = this.dataPoints$.pipe(
      filter((dataPoints) => !!dataPoints?.data?.length),
      map((dataPoints) => {
        return [
          getDataPoint({
            applicationId: this.applicationId,
            systems: dataPoints?.data || [],
            clientId: 'Valve_SupplyTemperature',
          }),
          getDataPoint({
            applicationId: this.applicationId,
            systems: dataPoints?.data || [],
            clientId: 'Valve_ReturnTemperature',
          }),
          getDataPoint({
            applicationId: this.applicationId,
            systems: dataPoints?.data || [],
            clientId: 'Valve_DeltaT',
          }),
        ];
      }),
      first()
    );

    this.visible$ = this.dataPointsForChart$.pipe(map((dataPoints) => dataPoints.some((dataPoint) => dataPoint)));

    this.getSeries = (period: ReportPeriod) => {
      return combineLatest([this.legends$, this.dataPointsForChart$]).pipe(
        switchMap(([legends, dataPoints]) => {
          const timeSeriesDetails: TimeSeriesDetails = {
            dataPoints,
            period,
            installationId: this.installationId,
            systemId: this.applicationId,
            requestId: 'energy-valve-temperature',
          };
          return this.dataPointsService
            .getTimeSeries(timeSeriesDetails)
            .pipe(
              map((seriesData: TimeSerie[]) => [
                { name: legends[0], data: seriesData[0], type: 'line' } as SeriesOptionsType,
                { name: legends[1], data: seriesData[1], type: 'line' } as SeriesOptionsType,
                { name: legends[2], data: seriesData[2], type: 'line'} as SeriesOptionsType,
              ])
            );
        }),
        shareReplay(1)
      );
    };
  }
}
