import React from 'react';
import { Valve } from '../../Valve';
import { Labels } from '../../Labels';
import { SvgImage } from '../../SvgImage';
import { Direction } from '../../Common';
import { PumpIcon } from '../../PumpIcon';
import { Pipe, PipeType } from '../../Pipe';
import { FlowMeter } from '../../FlowMeter';
import * as metaData from './cascade-tank-metadata';
import { SupplyTriangle } from '../../SupplyTriangle';
import { BaseSystemProps, System } from '../../System';
import { TemperatureSensor } from '../../TemperatureSensor';
import { BathAndFaucet } from '../../heat-exchanger-common/BathAndFaucet';
import tankSvg from '!raw-loader!../../../../../assets/equipment/hotWaterTank.svg';
import { HeatMeterOrSensors } from '../../heat-meter-or-sensors/HeatMeterOrSensors';
import { ICascadeTankSystemFormInterface } from '../../../../models/systems/cascadeTankSystem/cascadeTankSystemFormInterface';
import { ValveEnergyIcon } from '../../ValveEnergyIcon';
import { systemHasEnergyValve } from '../../../util/system-properties-util';

export const CascadeTankSystem: React.FC<BaseSystemProps<ICascadeTankSystemFormInterface>> = React.memo(
  ({ state, values, alarm, selected }) => {
    const systemInfo: ICascadeTankSystemFormInterface = state.systemInfo;
    const hasEnergyValve = systemHasEnergyValve(systemInfo);

    return (
      <System state={state} meta={metaData.cascadeTankMeta} alarm={alarm} selected={selected}>
        <SvgImage svgText={tankSvg} x={metaData.TANK_X} y={metaData.TOP_TANK_Y} />
        <SvgImage svgText={tankSvg} x={metaData.TANK_X} y={metaData.BOTTOM_TANK_Y} />
        {/* This pipe connects the top and bottom tanks */}
        <line
          transform="rotate(270), translate(-295, 200)"
          x1={0}
          y1={0}
          x2={15 + 0.0001}
          y2={0.00001}
          stroke="url(#warmToCold)"
          strokeWidth="2"
        />

        <Pipe
          key="supply_left"
          type={PipeType.WarmSupply}
          x1={0}
          y1={metaData.FORWARD_Y}
          x2={metaData.FORWARD_PIPE_X2}
          y2={metaData.FORWARD_Y}
        />
        <Pipe
          key="return_left"
          type={PipeType.ColdReturn}
          x1={0}
          y1={metaData.RETURN_Y}
          x2={metaData.RETURN_PIPE_X2}
          y2={metaData.RETURN_Y}
        />

        <g transform={`translate(75, 360)`}>
          <HeatMeterOrSensors
            direction={Direction.EAST}
            showHeatMeter={!!systemInfo.HM_present}
            showForwardHeatMeter={systemInfo.HM_location === 'PrimaryForward'}
            showReturnHeatMeter={systemInfo.HM_location === 'PrimaryReturn'}
            showForwardSensor={!!systemInfo.HM_present || !!systemInfo.T_PF_present}
            showReturnSensor={!!systemInfo.HM_present || !!systemInfo.T_PR_present}
          />
        </g>

        {hasEnergyValve && <ValveEnergyIcon x={metaData.VALVE_X - 25} y={metaData.RETURN_Y - 40} />}
        <Valve type={'TwoWayValve'} direction={Direction.WEST} x={metaData.VALVE_X} y={metaData.RETURN_Y} />

        <Pipe
          key="vertical-supply-pipe"
          type={PipeType.WarmSupply}
          x1={metaData.FORWARD_PIPE_X2}
          y1={metaData.FORWARD_Y}
          x2={metaData.FORWARD_PIPE_X2}
          y2={metaData.TOP_TANK_SUPPLY_Y}
        />
        <Pipe
          key="horizontal-supply-pipe"
          type={PipeType.WarmSupply}
          x1={metaData.FORWARD_PIPE_X2}
          y1={metaData.TOP_TANK_SUPPLY_Y}
          x2={metaData.TANK_X + 1}
          y2={metaData.TOP_TANK_SUPPLY_Y}
        />

        {/* The gradient is fixed, so we rotate and translate, to show it correctly, is also doesn't work on completely straight lines */}
        {/* The numbers are magic numbers */}
        <line
          transform="rotate(270), translate(-362, 130)"
          x1={0}
          y1={0}
          x2={93 + 0.0001}
          y2={0.00001}
          stroke="url(#warmToCold)"
          strokeDasharray="8 4"
          strokeWidth="2"
        />
        <Pipe
          key="blue-connector"
          type={PipeType.ColdReturn}
          x1={metaData.CONNECTOR_START_X}
          x2={metaData.CONNECTOR_END_X}
          y1={metaData.TOP_TANK_BOTTOM_CONNECTION_Y}
          y2={metaData.TOP_TANK_BOTTOM_CONNECTION_Y}
        />
        <Pipe
          key="red-connector"
          type={PipeType.WarmReturn}
          x1={metaData.CONNECTOR_START_X}
          x2={metaData.CONNECTOR_END_X}
          y1={metaData.BOTTOM_TANK_TOP_CONNECTION_Y}
          y2={metaData.BOTTOM_TANK_TOP_CONNECTION_Y}
        />

        {/* These are the pipes that connect to the bath and faucet */}
        <Pipe
          key="loop1"
          type={PipeType.WarmSupply}
          x1={metaData.TANK_LEFT + 53}
          y1={metaData.TOP_TANK_Y}
          x2={metaData.TANK_LEFT + 53}
          y2={metaData.SECONDARY_LOOP_TOP}
        />
        <Pipe
          key="loop2"
          type={PipeType.WarmSupply}
          x1={metaData.TANK_LEFT + 53}
          y1={metaData.SECONDARY_LOOP_TOP}
          x2={metaData.SECONDARY_LOOP_RIGHT}
          y2={metaData.SECONDARY_LOOP_TOP}
        />
        <Pipe
          key="loop3"
          type={PipeType.WarmReturn}
          x1={metaData.SECONDARY_LOOP_RIGHT}
          y1={metaData.SECONDARY_LOOP_TOP}
          x2={metaData.SECONDARY_LOOP_RIGHT}
          y2={metaData.PUMP_Y}
        />
        <Pipe
          key="loop4"
          type={PipeType.WarmReturn}
          x1={metaData.TANK_LEFT + 135}
          y1={metaData.PUMP_Y}
          x2={metaData.SECONDARY_LOOP_RIGHT}
          y2={metaData.PUMP_Y}
        />
        <Pipe
          key="loop5"
          type={PipeType.WarmReturn}
          x1={metaData.TANK_LEFT + 135}
          y1={metaData.PUMP_Y}
          x2={metaData.TANK_LEFT + 135}
          y2={metaData.TOP_TANK_Y + 50}
        />
        <Pipe
          key="loop6"
          type={PipeType.WarmReturn}
          x1={metaData.TANK_LEFT + 105}
          y1={metaData.TOP_TANK_Y + 50}
          x2={metaData.TANK_LEFT + 135}
          y2={metaData.TOP_TANK_Y + 50}
        />

        <BathAndFaucet x={metaData.SECONDARY_LOOP_RIGHT} y={metaData.SECONDARY_LOOP_TOP} />

        <PumpIcon direction={Direction.WEST} x={metaData.PUMP_X} y={metaData.PUMP_Y} />

        <TemperatureSensor key="T_SR" direction={Direction.EAST} x={metaData.T_SR_X} y={metaData.T_SR_Y} />

        <TemperatureSensor key="T_1_TOP" direction={Direction.NORTH} x={metaData.T_1_TOP_X} y={metaData.T_1_TOP_Y} />
        {systemInfo.T_1_MIDDLE_present && (
          <TemperatureSensor key="T_1_MIDDLE" direction={Direction.SOUTH} x={metaData.T_1_CENTER_X} y={metaData.T_1_CENTER_Y} />
        )}
        {systemInfo.T_1_BOTTOM_present && (
          <TemperatureSensor key="T_1_BOTTOM" direction={Direction.SOUTH} x={metaData.T_1_BOTTOM_X} y={metaData.T_1_BOTTOM_Y} />
        )}

        {systemInfo.T_2_TOP_present && (
          <TemperatureSensor key="T_2_TOP" direction={Direction.SOUTH} x={metaData.T_2_TOP_X} y={metaData.T_2_TOP_Y} />
        )}
        {systemInfo.T_2_MIDDLE_present && (
          <TemperatureSensor key="T_2_MIDDLE" direction={Direction.SOUTH} x={metaData.T_2_CENTER_X} y={metaData.T_2_CENTER_Y} />
        )}
        {systemInfo.T_2_BOTTOM_present && (
          <TemperatureSensor key="T_2_BOTTOM" direction={Direction.SOUTH} x={metaData.T_2_BOTTOM_X} y={metaData.T_2_BOTTOM_Y} />
        )}

        {/* These are the cold supply pipes */}
        <Pipe
          key="cold1"
          type={PipeType.ColdSupply}
          x1={metaData.COLD_SUPPLY_LEFT}
          x2={metaData.COLD_SUPPLY_LEFT}
          y1={metaData.BOTTOM_TANK_BOTTOM}
          y2={metaData.COLD_SUPPLY_BOTTOM}
        />
        <Pipe
          key="cold2"
          type={PipeType.ColdSupply}
          x1={metaData.COLD_SUPPLY_LEFT}
          x2={metaData.COLD_SUPPLY_RIGHT}
          y1={metaData.COLD_SUPPLY_BOTTOM}
          y2={metaData.COLD_SUPPLY_BOTTOM}
        />

        <SupplyTriangle warm={false} direction={Direction.WEST} x={metaData.COLD_SUPPLY_RIGHT - 1} y={metaData.COLD_SUPPLY_BOTTOM} />

        {systemInfo.Q_SC_present && <FlowMeter key="coldSupplyFlow" x={metaData.COLD_SUPPLY_FLOW_X} y={metaData.COLD_SUPPLY_BOTTOM} />}

        <Labels positions={metaData.getCascadeTankLabels(systemInfo)} values={values} />
      </System>
    );
  }
);
