import React from 'react';
import { Valve } from '../../Valve';
import { Labels } from '../../Labels';
import { Direction } from '../../Common';
import { PumpIcon } from '../../PumpIcon';
import { SvgImage } from '../../SvgImage';
import { FlowMeter } from '../../FlowMeter';
import { Pipe, PipeType } from '../../Pipe';
import * as metaData from './hot-water-tank-metadata';
import { SupplyTriangle } from '../../SupplyTriangle';
import { System, BaseSystemProps } from '../../System';
import { ValveEnergyIcon } from '../../ValveEnergyIcon';
import { TemperatureSensor } from '../../TemperatureSensor';
import { BathAndFaucet } from '../../heat-exchanger-common/BathAndFaucet';
import tankSvg from '!raw-loader!../../../../../assets/equipment/hotWaterTank.svg';
import { HeatMeterOrSensors } from '../../heat-meter-or-sensors/HeatMeterOrSensors';
import { IHotWaterTankSystemFormInterface } from '../../../../models/systems/hotWaterTankSystem/hotWaterTankSystemFormInterface';
import { systemHasEnergyValve } from '../../../util/system-properties-util';

export const HotWaterTank: React.FC<BaseSystemProps<IHotWaterTankSystemFormInterface>> = React.memo(({ state, values, alarm, selected }) => {
  const systemInfo = state.systemInfo;
  const showHeatMeter = !!systemInfo.HM_present;
  const showForwardHeatMeter = systemInfo.HM_location === 'PrimaryForward';
  const showReturnHeatMeter = systemInfo.HM_location === 'PrimaryReturn';
  const showForwardSensor = !!(systemInfo.HM_present || systemInfo.T_PF_present);
  const showReturnSensor = !!(systemInfo.HM_present || systemInfo.T_PR_present);
  const hasEnergyValve = systemHasEnergyValve(systemInfo);

  return (
    <System state={state} meta={metaData.hotWaterTankMeta} alarm={alarm} selected={selected}>
      <Pipe key="supply" type={PipeType.WarmSupply} x1={0} y1={metaData.FORWARD_Y} x2={metaData.TANK_LEFT + 1} y2={metaData.FORWARD_Y} />
      <Pipe key="return" type={PipeType.ColdReturn} x1={0} y1={metaData.RETURN_Y} x2={metaData.TANK_LEFT + 1} y2={metaData.RETURN_Y} />
      <SvgImage svgText={tankSvg} x={metaData.TANK_LEFT} y={metaData.TANK_TOP} />
      <Pipe
        key="loop1"
        type={PipeType.WarmSupply}
        x1={metaData.TANK_LEFT + 53}
        y1={metaData.TANK_TOP}
        x2={metaData.TANK_LEFT + 53}
        y2={metaData.SECONDARY_LOOP_TOP}
      />
      <Pipe
        key="loop2"
        type={PipeType.WarmSupply}
        x1={metaData.TANK_LEFT + 53}
        y1={metaData.SECONDARY_LOOP_TOP}
        x2={metaData.SECONDARY_LOOP_RIGHT}
        y2={metaData.SECONDARY_LOOP_TOP}
      />
      <Pipe
        key="loop3"
        type={PipeType.WarmReturn}
        x1={metaData.SECONDARY_LOOP_RIGHT}
        y1={metaData.SECONDARY_LOOP_TOP}
        x2={metaData.SECONDARY_LOOP_RIGHT}
        y2={metaData.PUMP_Y}
      />
      <Pipe
        key="loop4"
        type={PipeType.WarmReturn}
        x1={metaData.TANK_LEFT + 135}
        y1={metaData.PUMP_Y}
        x2={metaData.SECONDARY_LOOP_RIGHT}
        y2={metaData.PUMP_Y}
      />
      <Pipe
        key="loop5"
        type={PipeType.WarmReturn}
        x1={metaData.TANK_LEFT + 135}
        y1={metaData.PUMP_Y}
        x2={metaData.TANK_LEFT + 135}
        y2={metaData.TANK_TOP + 50}
      />
      <Pipe
        key="loop6"
        type={PipeType.WarmReturn}
        x1={metaData.TANK_LEFT + metaData.TANK_WIDTH}
        y1={metaData.TANK_TOP + 50}
        x2={metaData.TANK_LEFT + 135}
        y2={metaData.TANK_TOP + 50}
      />
      <Pipe
        key="cold1"
        type={PipeType.ColdSupply}
        x1={metaData.COLD_SUPPLY_LEFT}
        x2={metaData.COLD_SUPPLY_LEFT}
        y1={metaData.TANK_BOTTOM}
        y2={metaData.COLD_SUPPLY_BOTTOM}
      />
      <Pipe
        key="cold2"
        type={PipeType.ColdSupply}
        x1={metaData.COLD_SUPPLY_LEFT}
        x2={metaData.COLD_SUPPLY_RIGHT}
        y1={metaData.COLD_SUPPLY_BOTTOM}
        y2={metaData.COLD_SUPPLY_BOTTOM}
      />
      <BathAndFaucet x={metaData.SECONDARY_LOOP_RIGHT} y={metaData.SECONDARY_LOOP_TOP} />
      <SupplyTriangle warm={false} direction={Direction.WEST} x={metaData.COLD_SUPPLY_RIGHT - 1} y={metaData.COLD_SUPPLY_BOTTOM} />
      {systemInfo.P_hasPump && <PumpIcon direction={Direction.WEST} x={metaData.PUMP_X} y={metaData.PUMP_Y} />}
      <g key="heatMeter" transform={`translate(${metaData.HEAT_METER_X} ${metaData.FORWARD_Y})`}>
        <HeatMeterOrSensors
          direction={Direction.EAST}
          showHeatMeter={showHeatMeter}
          showForwardHeatMeter={showForwardHeatMeter}
          showReturnHeatMeter={showReturnHeatMeter}
          showForwardSensor={showForwardSensor}
          showReturnSensor={showReturnSensor}
        />
      </g>
      <g key="topSensor" transform={`translate(${metaData.TANK_LEFT + 1} ${metaData.TANK_TOP_TEMP_Y})`}>
        <TemperatureSensor direction={Direction.NORTH} />
      </g>
      {systemInfo.T_MIDDLE_present && <TemperatureSensor direction={Direction.SOUTH} x={metaData.TANK_RIGHT} y={metaData.TANK_MIDDLE} />}
      {systemInfo.T_BOTTOM_present && (
        <TemperatureSensor direction={Direction.SOUTH} x={metaData.TANK_RIGHT} y={metaData.TANK_BOTTOM_TEMP_Y} />
      )}
      {systemInfo.T_SR_present && (
        <g key="secondaryReturnSensor" transform={`translate(${metaData.T_SR_X} ${metaData.T_SR_Y})`}>
          <TemperatureSensor direction={Direction.WEST} />
        </g>
      )}
      {systemInfo.Q_SC_present && <FlowMeter key="coldSupplyFlow" x={metaData.COLD_SUPPLY_FLOW_X} y={metaData.COLD_SUPPLY_BOTTOM} />}
      {systemInfo.MV_valveLocation === 'PrimaryReturn' && (
        <>
          {hasEnergyValve && <ValveEnergyIcon x={metaData.VALVE_X - 25} y={metaData.RETURN_Y - 25} />}
          <Valve type="TwoWayValve" direction={Direction.WEST} x={metaData.VALVE_X} y={metaData.RETURN_Y} />
        </>
      )}
      {systemInfo.MV_valveLocation === 'PrimaryForward' && (
        <>
          {hasEnergyValve && <ValveEnergyIcon x={metaData.VALVE_X - 25} y={metaData.FORWARD_Y - 25} />}
          <Valve type="TwoWayValve" direction={Direction.EAST} x={metaData.VALVE_X} y={metaData.FORWARD_Y} />
        </>
      )}
      <Labels positions={metaData.getHotWaterTankLabels(systemInfo)} values={values} />
    </System>
  );
});
