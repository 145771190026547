import React from 'react';
import { Labels } from '../../Labels';
import { SvgImage } from '../../SvgImage';
import { Pipe, PipeType } from '../../Pipe';
import { System, BaseSystemProps } from '../../System';
import * as metaData from './simple-buffer-tank-metadata';
import simpleBufferTankSvg from '!raw-loader!../../../../../assets/equipment/simpleBufferTank.svg';
import { ISimpleBufferTankSystemFormInterface } from '../../../../models/systems/simpleBufferTankSystem/simpleBufferTankSystemFormInterface';

export const SimpleBufferTankSystem: React.FC<BaseSystemProps<ISimpleBufferTankSystemFormInterface>> = React.memo(
  ({ state, values, alarm, selected }) => {
    return (
      <System state={state} meta={metaData.simpleBufferTankMeta} alarm={alarm} selected={selected}>
        <Pipe
          key="supply_left"
          type={PipeType.WarmReturn}
          x1={0}
          y1={metaData.RETURN_Y}
          x2={metaData.TANK_LEFT + 1}
          y2={metaData.RETURN_Y}
          noDash={true}
        />

        <SvgImage svgText={simpleBufferTankSvg} x={metaData.TANK_LEFT} y={metaData.TANK_TOP} />

        <Pipe
          key="return_right"
          type={PipeType.WarmSupply}
          x1={metaData.TANK_RIGHT}
          y1={metaData.FORWARD_Y}
          x2={metaData.WIDTH}
          y2={metaData.FORWARD_Y}
          noDash={true}
        />

        <Labels positions={[]} values={values} />
      </System>
    );
  }
);
