import { SystemType, DistrictHeatingSystemState, BoilerSystemState, PumpStandaloneSystemState } from '../state';
import { EEquipment, EValueType, IEquipmentValue, ISetPointValue, IValveOpenValue } from '../types';
import { SampleEquipmentValue } from './sample-equipment-values';
import { v4 } from 'uuid';

// WILL BE DEPRECATED
export const SAMPLE_PUMP_STANDALONE_SYSTEM: PumpStandaloneSystemState = {
  hasId: v4(),
  left: 1440,
  top: 1800,
  configured: false,
  type: SystemType.PumpStandalone,
  systemInfo: {
    title: 'Standalone Pump',
    P_connected: true,
    P_location: 'Forward',
    PumpTemperatureSensor: true
  }
};

// WILL BE DEPRECATED
export const SAMPLE_PUMP_STANDALONE_SYSTEM_RETURN: PumpStandaloneSystemState = {
  hasId: v4(),
  left: 1660,
  top: 1800,
  configured: false,
  type: SystemType.PumpStandalone,
  systemInfo: {
    title: 'Standalone Pump',
    P_connected: true,
    P_location: 'Return',
    PumpTemperatureSensor: true
  }
};

export const SAMPLE_VALUES_PUMP_STANDALONE: SampleEquipmentValue[] = [
  {
    systemType: SystemType.PumpStandalone,
    type: EValueType.value,
    equipment: EEquipment.P_F,
    value: '42.7\u2103\n10.7m',
  },
  {
    systemType: SystemType.PumpStandalone,
    type: EValueType.value,
    equipment: EEquipment.P_R,
    value: '69.7\u2103\n10.7m',
  },
  {
    systemType: SystemType.PumpStandalone,
    type: EValueType.value,
    equipment: EEquipment.T_F,
    value: '33.7\u2103',
  },
  {
    systemType: SystemType.PumpStandalone,
    type: EValueType.value,
    equipment: EEquipment.T_R,
    value: '33.7\u2103',
  },
];
