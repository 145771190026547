import { Injectable } from '@angular/core';
import { AlarmService } from './alarm.service';
import { FacilityService } from './facility.service';
import { DataPointsService } from './data-points.service';
import { Observable, timer, BehaviorSubject } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import moment from 'moment';
import { DataPointsResult, DataPointType, DeviceDataPoint, System } from '../interfaces/data-points';
import { sortBy } from 'lodash';
import { AlarmRoot } from '../interfaces/system-status';
import { EFacilityType } from '../../../../serviceportal/src/app/interfaces/facility';
import { ConnectionState } from '../interfaces/connectionState';
import {
  EHealthCheckTargetKey,
  EnergyManagementDashboardData,
  EnergyManagementDashboardSection,
  HealthCheck
} from '../interfaces/installation';

export enum DataUpdatedState {
  noData = 'noData',
  offline = 'offline',
  upToDate = 'upToDate',
  delayed = 'delayed',
  outdated = 'outdated',
  alarm = 'alarm'
}

interface Delays {
  dataDelayInMinutes: number;
  heartBeatDelayInMinutes: number;
}

@Injectable({
  providedIn: 'root',
})
export class InstallationStateService {
  private delays: { [key in EFacilityType]: Delays } = {
    [EFacilityType.MIXIT]: {
      dataDelayInMinutes: 35,
      heartBeatDelayInMinutes: 35,
    },
    [EFacilityType.BuildingConnect]: {
      dataDelayInMinutes: 30,
      heartBeatDelayInMinutes: 30,
    },
    [EFacilityType.ConnectingCore]: {
      dataDelayInMinutes: 35,
      heartBeatDelayInMinutes: 35,
    },
  };

  public installationEnergyDashboards$: BehaviorSubject<EnergyManagementDashboardData[] | null> = new BehaviorSubject<EnergyManagementDashboardData[] | null>(null);

  constructor(private alarmService: AlarmService, private dataPointService: DataPointsService, private facilityService: FacilityService) { }

  public getHeartBeat(installationId: string): Observable<number | undefined> {
    return this.dataPointService.dataPoints$.pipe(
      filter((d) => !!d),
      map((dataPointsResult) => {
        return dataPointsResult?.find((d) => d.installationId === installationId);
      }),
      map((d) => {
        return d?.heartBeatTimestamp;
      })
    );
  }

  public getDeviceDateUpdatedState(installationId: string, applicationId?: string): Observable<DataUpdatedState> {

    // The dataPointsResult can be null, hence the use of ? everywhere
    const dataPoints$ = this.dataPointService.dataPoints$.pipe(
      filter((d) => !!d),
      map(dataPointsResult => {
        return dataPointsResult?.find(d => d.installationId === installationId);
      }),
      map((dataPoints) => {
        if (applicationId) {
          return {
            ...dataPoints,
            data: dataPoints?.data.filter(d => d.systemId === applicationId) || [],
          };
        }
        return dataPoints;
      })
    );

    const facilityType$: Observable<EFacilityType | null> = this.getInstallationFacilityType(installationId);

    return timer(0, 5000).pipe(
      withLatestFrom(dataPoints$, this.alarmService.alarmsRootByInstallation$, facilityType$, this.facilityService.getInstallation(installationId)),
      filter(([_, dataPoints, alarmRoot, facilityType, installation]) => !!(installation)),
      map(([_, dataPoints, alarmRootByInstallation, facilityType, installation]) => {
        const alarmRoot = alarmRootByInstallation.get(installation.id);

        if (installation.connectionState !== ConnectionState.Connected) {
          return DataUpdatedState.offline;
        }

        if (!facilityType) {
          return DataUpdatedState.noData;
        }

        if (!dataPoints || !dataPoints.installationId || !dataPoints.heartBeatTimestamp) {
          return DataUpdatedState.noData;
        }

        if (alarmRootByInstallation) {
          // Check for alarms
          const alarm152 = this.hasAlarm(alarmRoot, dataPoints as DataPointsResult, applicationId, facilityType);
          if (alarm152) {
            return DataUpdatedState.alarm;
          }
        }

        const flattenedPoints = this.flattenDataPoints(dataPoints.data);
        const ordered = this.orderedByLatest(flattenedPoints);
        const latestDataPoint = ordered[0];
        if (!latestDataPoint) {
          return DataUpdatedState.noData;
        }
        const latestDataPointDiffMinutes = moment().diff(latestDataPoint.timestampEpoch, 'minutes');
        const heartBeatDiffMinutes = moment().diff(dataPoints.heartBeatTimestamp, 'minutes');

        // If we received dataPoints within 30 minutes
        if (latestDataPointDiffMinutes <= this.delays[facilityType].dataDelayInMinutes) {
          return DataUpdatedState.upToDate;
        }

        // Heartbeat is within 30 minutes, but data is not
        if (
          heartBeatDiffMinutes <= this.delays[facilityType].heartBeatDelayInMinutes &&
          latestDataPointDiffMinutes > this.delays[facilityType].dataDelayInMinutes
        ) {
          return DataUpdatedState.delayed;
        }

        // Heartbeat is older than 30 minutes
        return DataUpdatedState.outdated;
      })
    );
  }

  private mapEnergyManagementDashboardDataToDeviceDataPoint(db: EnergyManagementDashboardData) {
    // TODO FIX
    const keys = Object.keys(db).filter(key => key !== 'installationId' && key !== 'debug' && key !== 'periodFrom' && key !== 'periodTo');
    // TODO: Check if it is upper or lower case here
    // @ts-ignore
    keys.forEach((val: 'totalDistrictHeating' | 'domesticHotWater' | 'spaceHeating') => {
      const section = db[val] as EnergyManagementDashboardSection;
      // TODO: Check if unit and name exist on KPI
      // @ts-ignore
      section.kpis = section.kpis.map((x) => ({ ...x, unitType: x.unit, humanReadableId: x.name, }));
      section.healthChecks = section.healthChecks.map((x) => {
        const healthCheck: HealthCheck = {
          // TODO: Same as above
          // @ts-ignore
          ...x, unitType: x.unit, humanReadableId: x.name, value: x.current, targets: x.targets.map(t => ({ value: t, unitType: x.unit }))
        };

        return healthCheck;
      });
    });
    return db;
  }

  public pushOrUpdateDataToEnergyManagementDashboardList(args: EnergyManagementDashboardData) {
    const dashboards = this.installationEnergyDashboards$.value || [];
    const dashboardIndex = dashboards.findIndex(db => db.installationId === args.installationId);
    if (dashboardIndex > -1) {
      // Replace
      dashboards[dashboardIndex] = this.mapEnergyManagementDashboardDataToDeviceDataPoint(args);
    } else {
      // Else add it
      dashboards.push(this.mapEnergyManagementDashboardDataToDeviceDataPoint(args));
    }

    this.installationEnergyDashboards$.next(dashboards);
  }

  private getInstallationFacilityType(installationId: string): Observable<EFacilityType | null> {
    return this.facilityService.facilities$.pipe(
      map((facilities) => {
        const facility = facilities?.find((f) => f.installations.find((i) => i.id === installationId));
        if (!facility) {
          return null;
        }
        return facility.facilityType;
      })
    );
  }

  private orderedByLatest(dataPoints: DeviceDataPoint[]) {
    return sortBy(dataPoints, 'timestampEpoch').reverse();
  }

  private flattenDataPoints(dataPointsResultData: System[]): any[] {
    return dataPointsResultData.flatMap((data) => {
      return data.devices.flatMap((de) => {
        return de.dataPoints.filter((dp) => dp.timestampEpoch !== 0).filter((dp) => dp.type !== DataPointType.Event);
      });
    });
  }

  private hasAlarm(alarmRoot: AlarmRoot | undefined, dataPoints: DataPointsResult, applicationId: string | undefined, facilityType: EFacilityType): boolean {
    if (facilityType === EFacilityType.BuildingConnect) {
      const sources = alarmRoot?.alarmSources?.filter((s) => {
        const alarms = s.alarms.filter((a) => !a.latestActivation?.clearedTimestampEpoch).find((a) => a.errorCode === '152');
        return !!alarms;
      });

      if (!sources) {
        return false;
      }

      if (!dataPoints) {
        return false;
      }

      if (applicationId) {
        const dataForApplication = dataPoints.data.find((d) => d.systemId === applicationId);
        if (!dataForApplication) {
          return false;
        }

        // Find a device tha matches the alarm
        const deviceForAlarm = dataForApplication.devices.find((d) => sources.find((s) => s.sourceId === d.deviceId));
        return !!deviceForAlarm;
      }

      // We don't have a application id

      // Check if there is a device that matches the source
      for (const data of dataPoints?.data) {
        const deviceForAlarm = data.devices.find((d) => sources.find((s) => s.sourceId === d.deviceId));
        if (deviceForAlarm) {
          return true;
        }
      }
    }

    if (facilityType === EFacilityType.MIXIT) {
      const source = alarmRoot?.alarmSources?.find((s) => {
        return s.sourceId === applicationId;
      });

      const alarms = source?.alarms.filter((a) => !a.latestActivation?.clearedTimestampEpoch) || [];

      return !!alarms.length;
    }

    return false;
  }
}
