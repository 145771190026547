import { SystemType, DistrictHeatingSystemState, BoilerSystemState, OutdoorTemperatureSystemState } from '../state';
import { EEquipment, EValueType, IEquipmentValue, ISetPointValue, IValveOpenValue } from '../types';
import { SampleEquipmentValue } from './sample-equipment-values';
import { v4 } from 'uuid';

export const SAMPLE_OUTDOOR_TEMPERATURE: OutdoorTemperatureSystemState = {
  hasId: v4(),
  type: SystemType.OutdoorTemperature,
  left: 10,
  top: 1580,
  configured: false,
  systemInfo: {}
};

export const SAMPLE_VALUES_OUTDOOR_TEMPERATURE: SampleEquipmentValue[] = [
  {
    systemType: SystemType.OutdoorTemperature,
    type: EValueType.value,
    equipment: EEquipment.T_OUTDOOR,
    value: '-3.3\u2103',
  }
];
