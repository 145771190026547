import React from 'react';
import { SvgImage } from '../SvgImage';
import { Pipe, PipeType } from '../Pipe';
import { CONNECTION_SPACING } from '../Common';
import { TWO_STRING_RADIATOR_LEFT } from './RadiatorTwoString';
import radiatorIcon from '!raw-loader!../../../../assets/icons/radiator.svg';

export const RADIATOR_SVG_HEIGHT = 33;
export const RADIATOR_SVG_WIDTH = 54;
export const RADIATOR_VALVE_Y = 4;
// The position of the radiator within the radiator SVG, to make space for the valve in the icon.
export const RADIATOR_OFFSET = 6;
// The Y-value of the left hand pipe, when in right and left config
export const RADIATOR_LEFT_PIPE_Y_LEFT = RADIATOR_SVG_HEIGHT - RADIATOR_VALVE_Y;
export const RADIATOR_LEFT_PIPE_Y_RIGHT = RADIATOR_VALVE_Y;
// The Y-value of the right hand pipe, when in right and left config
export const RADIATOR_RIGHT_PIPE_Y_LEFT = RADIATOR_VALVE_Y;
export const RADIATOR_RIGHT_PIPE_Y_RIGHT = RADIATOR_SVG_HEIGHT - RADIATOR_VALVE_Y;

export const Radiator: React.FC<{ x: number; y: number; reversedWarmCold: boolean }> = ({ x, y, reversedWarmCold }) => {
  const leftPipeY = reversedWarmCold ? RADIATOR_LEFT_PIPE_Y_LEFT : RADIATOR_LEFT_PIPE_Y_RIGHT;
  const rightPipeY = reversedWarmCold ? RADIATOR_RIGHT_PIPE_Y_LEFT : RADIATOR_RIGHT_PIPE_Y_RIGHT;

  const radiatorLeft = reversedWarmCold ? TWO_STRING_RADIATOR_LEFT + RADIATOR_SVG_WIDTH : TWO_STRING_RADIATOR_LEFT;

  return (
    <g transform={`translate(${x}, ${y})`}>
      <Pipe
        key="inlet"
        x1={0}
        y1={leftPipeY}
        x2={TWO_STRING_RADIATOR_LEFT + RADIATOR_OFFSET}
        y2={leftPipeY}
        type={reversedWarmCold ? PipeType.ColdReturn : PipeType.WarmSupply}
      />
      <Pipe
        key={'radiatorOutlet'}
        x1={TWO_STRING_RADIATOR_LEFT + RADIATOR_SVG_WIDTH}
        y1={rightPipeY}
        x2={CONNECTION_SPACING}
        y2={rightPipeY}
        type={reversedWarmCold ? PipeType.WarmSupply : PipeType.ColdReturn}
      />
      <SvgImage key="radiator" transforms={[`scale(${reversedWarmCold ? -1 : 1}, 1 )`]} svgText={radiatorIcon} x={radiatorLeft} y={0} />
    </g>
  );
};
