import { SchematicState } from '../schematic/state';
import { IBuildingFormInterface } from '../models/building/buildingFormInterface';

export enum TabState {
  System,
  Clarifications,
}

export interface EditorState {
  schematic: SchematicState;
  tabState: TabState;
  buildingInfo: IBuildingFormInterface;
  isFullscreen: boolean;
  showingConfirmDeleteModal: boolean;
  showingHelp: boolean;
  showingRemoveSystemModal: boolean;
  creatingNewSchematic: boolean;
  showDevTools?: boolean;
}
