import { Injectable } from '@angular/core';
import { CommandService, AppErrorService } from 'shared';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'projects/customerportal/src/environments/environment';
import { ActivityComment, EnergyDashboardSettingsDto, EnergyDashboardTargetsDto } from '../interfaces/installation';
import { PasteurizationScheduledEvent, ScheduledEvent } from '../interfaces/scheduling';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { tap, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class InstallationService {
  private readonly baseUserUrl = `${environment.serverUrl}/api/installations`;
  private activityUpdateSubject: BehaviorSubject<ActivityComment[]> = new BehaviorSubject<ActivityComment[]>([]);
  public activityUpdate$: Observable<ActivityComment[]> = this.activityUpdateSubject.asObservable();

  constructor(
    private httpClient: HttpClient,
    private commandService: CommandService,
    private appErrorService: AppErrorService) { }

  updateInstallationName(installationId: string, name: string) {
    return this.commandService.execute(
      () => {
        const header = new HttpHeaders({ 'content-type': 'application/json' });

        return this.httpClient.patch(`${this.baseUserUrl}/${installationId}`, `"${name}"`, { headers: header }).pipe(
          this.appErrorService.catchApiError({
            errorCodes: {
              404: {
                messageKey: 'installation-service.failed-to-update-no-access',
              },
            },
            fallbackMessageKey: 'installation-service.failed-to-update-installation-name',
          })
        );
      },
      { successMessageKey: 'installation-service.successfully-updated-installation-name' }
    );
  }

  updateInstallationEnergyDashboardTarget(installationId: string, energyDashboardTargetsDto: EnergyDashboardTargetsDto) {
    return this.httpClient.patch(`${environment.serverUrl}/api/system/energyDashboardTargets/${installationId}`, { targets: energyDashboardTargetsDto });
  }

  updateInstallationEnergyDashboardSettings(installationId: string, settings: EnergyDashboardSettingsDto) {
    return this.commandService.execute(() => {
      const header = new HttpHeaders({ 'content-type': 'application/json' });
      return this.httpClient.patch(`${this.baseUserUrl}/${installationId}/energyDashboardSettings`, settings, { headers: header }).pipe(
        this.appErrorService.catchApiError({
          fallbackMessageKey: 'installation-service.failed-to-update-energy-dashboard-settings',
        })
      );
    },
    { successMessageKey: 'installation-service.successfully-updated-energy-dashboard-settings' });
  }

  updatePasteurizationScheduledEvent(installationId: string, event: PasteurizationScheduledEvent) {
    return this.commandService.executeAsync(() => {
      return this.httpClient.put(`${this.baseUserUrl}/${installationId}/schedule/pasteurization`, event).pipe(
        this.appErrorService.catchApiError({
          errorCodes: {},
          fallbackMessageKey: 'installation-service.failed-to-update-scheduled-event'
        })
      );
    }, { successMessageKey: 'installation-service.successfully-updated-scheduled-event' });
  }

  createPasteurizationScheduledEvent(installationId: string, event: PasteurizationScheduledEvent) {
    return this.commandService.executeAsync(() => {
      return this.httpClient.post(`${this.baseUserUrl}/${installationId}/schedule/pasteurization`, event).pipe(
        this.appErrorService.catchApiError({
          errorCodes: {},
          fallbackMessageKey: 'installation-service.failed-to-create-scheduled-event'
        })
      );
    }, { successMessageKey: 'installation-service.successfully-created-scheduled-event' });
  }

  deleteScheduledEvent(installationId: string, event: ScheduledEvent) {
    return this.commandService.executeAsync(() => {
      return this.httpClient.delete(`${this.baseUserUrl}/${installationId}/schedule/${event.id}`).pipe(
        this.appErrorService.catchApiError({
          errorCodes: {},
          fallbackMessageKey: 'installation-service.failed-to-delete-scheduled-event'
        })
      );
    }, { successMessageKey: 'installation-service.successfully-deleted-scheduled-event' });
  }

  //-- Activity ------------------------------------------------------------------------

  getActivities(installationId: string): Observable<ActivityComment[]> {
    return this.httpClient.get<ActivityComment>(`${environment.serverUrl}/api/installations/${installationId}/activity`).pipe(
      map((activity: ActivityComment) => [activity])
    );
  }

  updateActivityComment(comment: ActivityComment): Observable<ActivityComment> {
    return this.httpClient.put<ActivityComment>(`${environment.serverUrl}/api/installations/${comment.installationId}/activity`, comment);
  }

  pushActivityUpdate(activities: ActivityComment[]): void {
    this.activityUpdateSubject.next(activities);
  }
}
