import { Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DataPointsResult, DeviceDataPoint } from '../../../interfaces/data-points';
import { getDatapoint } from '../../../utils/mixit-utils';
import { MixitDataPointName } from '../../../interfaces/mixit';
import { Application } from '../../../interfaces/alarm';
import { Installation } from '../../../interfaces/facilty';
import { AppModel } from '@ams/dumbledore';
import { SystemDeviceType } from '../../../interfaces/systemDeviceType';

@Component({
  selector: 'app-energy-monitoring-tile',
  templateUrl: './energy-monitoring-tile.component.html',
  styleUrls: ['./energy-monitoring-tile.component.scss'],
})
export class EnergyMonitoringTileComponent implements OnInit {
  @Input() installation$: Observable<Installation>;

  @Input() schematic$: Observable<AppModel>;

  @Input() dataPoints$: Observable<DataPointsResult>;

  @Input() application$: Observable<Application>;

  public thermalPowerCalibrated$: Observable<DeviceDataPoint | null>;
  public thermalPower$: Observable<DeviceDataPoint | null>;
  public thermalEnergy$: Observable<DeviceDataPoint | null>;
  public volume$: Observable<DeviceDataPoint | null>;
  public weightedDeltaT$: Observable<DeviceDataPoint | null>;

  ngOnInit(): void {
    this.thermalPowerCalibrated$ = getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.ThermalPowerFlowAverageCalibrated);
    this.thermalPower$ = getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.ThermalPowerFlowAverage);
    this.thermalEnergy$ = getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.EnergyHeatingAverage);
    this.volume$ = getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.VolumeAverage);
    this.weightedDeltaT$ = getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.WeightedDeltaT);
  }
}
