import { Inject, Injectable } from '@angular/core';
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { isEqual } from 'lodash';
import { AuthService, serverUrlToken } from 'shared';
import { Facility } from '../interfaces/facilty';
import { FacilityService } from './facility.service';
import { environment } from '../../environments/environment';
import { DataPointsResult } from '../interfaces/data-points';
import { DataPointsService } from './data-points.service';
import { AlarmService } from './alarm.service';
import { AlarmRoot } from '../interfaces/system-status';
import { IBuildingConnectUser } from '../interfaces/user';
import { UserService } from './user.service';
import { InstallationStateService } from './installation-state.service';
import { ActivityComment, EnergyManagementDashboardData } from '../interfaces/installation';
import { SchematicsService, SchematicWithMeta } from './schematics.service';
import { InstallationService } from './installation.service';
import moment from 'moment';
import { catchError, filter, first, map, mergeMap } from 'rxjs/operators';
import { BehaviorSubject, NEVER, Observable, of, throwError } from 'rxjs';

export enum StateChange {
  // Installations
  InstallationDataUpdated = 'InstallationDataUpdated',
  InstallationAlarmsUpdated = 'InstallationAlarmsUpdated',
  InstallationEnergyDashboardUpdated = 'InstallationEnergyDashboardUpdated',
  InstallationActivityUpdated = 'InstallationActivityUpdated',

  // Schematics
  AllSchematics = 'AllSchematics',
  SchematicChanged = 'SchematicChanged',

  // Facilities
  AllFacilities = 'AllFacilities',
  FacilityUpdated = 'FacilityUpdated',
  FacilityDeleted = 'FacilityDeleted',

  // Users
  UserUpdated = 'UserUpdated',
  UserDeleted = 'UserDeleted',
  CurrentUserUpdated = 'CurrentUserUpdated'
}

export enum GroupType {
  AllFacilities = 'AllFacilities', // AllFacilities(once), FacilityUpdated, FacilityCreated, FacilityDeleted
  AllUsers = 'AllUsers',
  Facility = 'Facility',
  Installation = 'Installation', // DataUpdated, AlarmsUpdated, ActivityUpdated
  Schematic = 'Schematic', // Schematic
  CurrentUser = 'CurrentUser' // The user that is logged in
}

interface SocketSubscription {
  groupType: GroupType;
  ids: string[];
}

const connectionLifetimeMinutes = 60;    // Default SignalR connection lifetime is 60 mins.
const retryConnectionAfterClosedSecs = 30;

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private hubConnection: HubConnection;
  private subscriptions: SocketSubscription[] = [];
  private connectionStartedAt: Date;
  private isRenewingToken = false;
  private renewAccessTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  // This variable keeps track of whether or not we have subscribed to users.
  // When we sub, we don't want to unsub again, as there is no need.
  public subscribedToUsers = false;

  private hasReceivedAllFacilitiesEvent = false;

  constructor(
    @Inject(serverUrlToken) private serverUrl: string,
    private authService: AuthService,
    private facilityService: FacilityService,
    private dataPointsService: DataPointsService,
    private installationStateService: InstallationStateService,
    private alarmService: AlarmService,
    private userService: UserService,
    private schematicService: SchematicsService,
    private installationService: InstallationService
  ) {
    this.preventConnectionLossOnBrowserTabSleep();
    this.setupHubConnection();
  }

  private teardownHubConnection() {
    this.hubConnection.off(StateChange.SchematicChanged);
    this.hubConnection.off(StateChange.AllSchematics);
    this.hubConnection.off(StateChange.InstallationDataUpdated);
    this.hubConnection.off(StateChange.InstallationEnergyDashboardUpdated);
    this.hubConnection.off(StateChange.InstallationActivityUpdated);
    this.hubConnection.off(StateChange.InstallationAlarmsUpdated);
    this.hubConnection.off(StateChange.AllFacilities);
    this.hubConnection.off(StateChange.FacilityUpdated);
    this.hubConnection.off(StateChange.FacilityDeleted);
    this.hubConnection.off(StateChange.CurrentUserUpdated);
    this.hubConnection.off(StateChange.UserUpdated);
    this.hubConnection.off(StateChange.UserDeleted);

    if (this.hubConnection.state === HubConnectionState.Connected) {
      this.hubConnection.stop();
    }
  }

  private setupHubConnection() {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(`${this.serverUrl + environment.deploymentPath}/updateDataHub`)
      .configureLogging(LogLevel.None)
      .withAutomaticReconnect()
      .build();

    // Resubscribe to all subscriptions after a disconnect
    this.hubConnection.onreconnected(() => this.onReconnected());

    this.hubConnection.onclose(() => this.onClosed());

    this.hubConnection.on(StateChange.SchematicChanged, (installationSchematic: string) => {
      const parsed: SchematicWithMeta = JSON.parse(installationSchematic);
      this.schematicService.pushOrUpdateSchematics(parsed);
    });

    this.hubConnection.on(StateChange.AllSchematics, (schematics: string[]) => {
      const parsed: SchematicWithMeta[] = schematics.map(s => JSON.parse(s));
      console.log('Schematics', parsed); // NID: Leave this here, it helps greatly with debugging
      this.schematicService.updateAllSchematics(parsed);
    });

    this.hubConnection.on(StateChange.InstallationDataUpdated, (args: DataPointsResult[]) => {
      if (args.length) {
        this.dataPointsService.pushOrUpdateDataToDataList(args[0]);
      }
    });

    // Installation - Dashboard Updated
    this.hubConnection.on(StateChange.InstallationEnergyDashboardUpdated, (args: EnergyManagementDashboardData) => {
      if (args) {
        // TODO: Why do we not let the serivce handle missing arguments?
        this.installationStateService.pushOrUpdateDataToEnergyManagementDashboardList(args);
      }
    });

    // Installation - Activity Updated
    this.hubConnection.on(StateChange.InstallationActivityUpdated, (args: ActivityComment) => {
      this.installationService.pushActivityUpdate([args]);
    });

    this.hubConnection.on(StateChange.InstallationAlarmsUpdated, (args: AlarmRoot) => {
      this.alarmService.pushNewAlarm(args);
    });

    this.hubConnection.on(StateChange.AllFacilities, (facilities: Facility[]) => {
      // TODO: Why not repeat the pattern above (push...) and handle in specific service?
      this.hasReceivedAllFacilitiesEvent = true;
      this.facilityService.facilities$.next(facilities);
    });

    this.hubConnection.on(StateChange.FacilityUpdated, (facility: Facility) => {
      // We don't want to react to individual changes, until we've loaded all the facilities
      // It means we throw an error when navigating to a single facility, but we receive a single update for another facility.
      if (!this.hasReceivedAllFacilitiesEvent) {
        return;
      }
      this.facilityService.pushOrUpdateFacilityToFacilityList(facility);
    });

    this.hubConnection.on(StateChange.FacilityDeleted, ({ id }: { id: number }) => {
      this.facilityService.removeFacilityFromList(id);
    });

    this.hubConnection.on(StateChange.CurrentUserUpdated, (currentUser: IBuildingConnectUser) => {
      this.userService.setCurrentUser(currentUser);
    });

    this.hubConnection.on(StateChange.UserUpdated, (users: IBuildingConnectUser[] | IBuildingConnectUser) => {
      this.userService.pushOrUpdateUsersToUsersList(Array.isArray(users) ? users : [users]);
    });

    this.hubConnection.on(StateChange.UserDeleted, ({ id }: { id: string }) => {
      this.userService.removeUserFromList(id);
    });
  }

  public async start() {
    try {
      await this.hubConnection.start();
      this.connectionStartedAt = new Date();
    } catch (e) {
      setTimeout(() => this.start(), 2000);
    }
  }

  public async subscribeMultiple(ids: string[], groupTypes: GroupType[]) {
    if (this.hubConnection.state !== HubConnectionState.Connected) {
      return this.retryRegister(ids, groupTypes);
    }

    const token = this.getToken();
    if (!token) {
      return this.retryRegister(ids, groupTypes);
    }

    groupTypes.forEach(groupType => this.addSubscription({ groupType, ids }));

    try {
      const response = await this.hubConnection.invoke('Subscribe', groupTypes.map(groupType => ({ ids, groupType, bearerToken: token })));
      if (response.statusCode === 401) {
        this.authService.signinRedirectAfterSignin(location.href);
      }
    } catch (e) {
      console.log(e);
    }
  }

  public unsubscribe(ids: string[], groupType: GroupType) {
    this.removeSubcription({ groupType, ids });

    if (this.hubConnection.state !== HubConnectionState.Connected) {
      // There is no need to retry the unsubscribe, as it will not resubscribe because it has been removed above
      return;
    }

    const token = this.getToken();
    if (!token) {
      return;
    }

    this.hubConnection.invoke('Unsubscribe', [{ ids, groupType, bearerToken: token }])
      .then(() => {
        if (groupType === GroupType.Installation) {
          // Reset datapoints
          this.dataPointsService.dataPoints$.next([]);
        }
      });
  }

  private isConnectionExpired(): boolean {
    return moment().diff(this.connectionStartedAt, 'minutes') >= connectionLifetimeMinutes - 1;
  }

  private isLoginExpired(): boolean {
    return (this.authService.currentUser.value?.expires_in ?? 0) <= 60;
  }

  private onClosed(error?: Error) {
    if (this.hubConnection.state === HubConnectionState.Disconnected) {
      this.hubConnection.start()
        .then(() => {
          this.connectionStartedAt = new Date();
          this.reconnectHubConnection();
        })
        .catch(e => {
          setTimeout(_ => this.onClosed(), retryConnectionAfterClosedSecs * 1000);
        });
    }
  }

  private onReconnected() {
    this.reconnectHubConnection();
  }

  private reconnectHubConnection() {
    // We're reconnected to the SignalR hub at this point, but we may need to renew our subscriptions, since they may have expired on the server.
    // Additionally, the user's JWT token may be expired, in which case we need to renew it in order to renew the subscriptions.

    const ensureValidLoginToken = this.isLoginExpired()
      ? this.renewLoginToken()
      : of('');

    ensureValidLoginToken.pipe(
      first(),
      map(() => {
        if (!this.isConnectionExpired()) {
          // SignalR connection is still valid, but we may need to renew subscriptions to the SignalR hub
          this.resetSubscriptions();
        }
        else {
          // SignalR connection is expired, so we cannot simply renew subscriptions. Instead, recreate the connection, then renew the subscriptions
          this.teardownHubConnection();
          this.setupHubConnection();
          this.start()
            .then(() => {
              this.resetSubscriptions();
            })
          }
      }),
      catchError(err => {
        this.authService.signout();
        return NEVER;    // It's ok to NEVER complete the observable since signout navigates away from our SPA
      })
    ).subscribe();
  }

  private renewLoginToken(): Observable<string> {
    if (!this.isRenewingToken) {
      this.isRenewingToken = true;
      this.renewAccessTokenSubject = new BehaviorSubject<string | null>(null);
      return this.authService.signinSilent().pipe(
        mergeMap(user => {
          if (!user?.access_token) {
            this.authService.signinRedirect('/');
          }
          this.isRenewingToken = false;
          this.renewAccessTokenSubject.next(user?.access_token);
          this.renewAccessTokenSubject.complete();
          return of(user?.access_token);
        }),
        catchError(err => {
          this.isRenewingToken = false;
          this.renewAccessTokenSubject.error(err);
          this.authService.signinRedirect('/');
          return throwError(err);
        })
      );
    }
    else {
      // Await token being renewed by other request
      return this.renewAccessTokenSubject.pipe(
        catchError(err => {
          return throwError(err);
        }),
        filter(token => !!token),
        mergeMap(token => {
          return of(token!);
        })
      );
    }
  }

  private resetSubscriptions() {
    const copyOfSubscriptions = [...this.subscriptions];
    // We need to reset subscriptions, as each sub will be pushed to this.subscriptions in this.subscribeMultiple
    this.subscriptions = [];

    for (const sub of copyOfSubscriptions) {
      this.subscribeMultiple(sub.ids, [sub.groupType]);
    }
  }

  private addSubscription(sub: SocketSubscription) {
    this.subscriptions.push(sub);
  }

  private removeSubcription(sub: SocketSubscription) {
    const index = this.subscriptions.findIndex(s => s.groupType === sub.groupType && isEqual(sub.ids, s.ids));
    this.subscriptions.splice(index, 1);
  }

  private retryRegister(ids: string[], groupTypes: GroupType[]) {
    window.setTimeout(() => {
      this.subscribeMultiple(ids, groupTypes);
    }, 2000);
  }

  private getToken(): string | undefined {
    if (environment.testMode) {
      const index = document.cookie.indexOf('=');
      const roleName = document.cookie.slice(index + 1, document.cookie.length);
      return roleName;
    }
    return this.authService.currentUser.value?.access_token;
  }

  private preventConnectionLossOnBrowserTabSleep() {
    // If the browser tab becomes inactive it may go into sleep mode, which can mean we lose our SignalR connection.
    // To prevent this we create a web lock to signal to the browser that the tab should be kept active.
    // See: https://learn.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-6.0&tabs=visual-studio#bsleep

    let lockResolver;
    if ('locks' in navigator) {
      const lockManager = <LockManager>(navigator as any)['locks'];    // Stupid workaround due to missing declaration of navigator.locks in typelib defs file
        const promise = new Promise((res) => {
            lockResolver = res;
        });

        lockManager.request('buildingconnect_lock', { mode: "shared" }, () => {
            return promise;
        });
    }
  }
}
