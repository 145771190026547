import moment from 'moment';
import { License } from '../interfaces/facilty';

export const isLicenseActive = (license: License): boolean => {
  // A license is considered expired if the expirationTimeEpoch is less than moment.now();
  const now = moment.now();
  if (license.expirationTimeEpoch !== null && license.expirationTimeEpoch < now) {
    return false;
  }

  // A license is considered active if its activationTimeEpoch is larger than 1 and expirationTimeEpoch is null.
  // This means the license will not expire.
  if (license.activationTimeEpoch > 1 && license.expirationTimeEpoch === null) {
    return true;
  }

  return license.activationTimeEpoch > 1 && license.expirationTimeEpoch > now;
};
