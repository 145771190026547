<gbc-page-header *ngIf="pageInfo$ | async as pageInfo" [pageInfo]="pageInfo">
  <ng-container gbc-page-header-actions>
    <button gbc-menu-item (click)="editInstallation()" angulartics2On="click" angularticsAction="editInstallationShown" angularticsCategory="buildingConnectInstallation">
      <img style="margin-right: 10px" src="./assets/svgs/edit.svg" />
      {{ 'installation-view.edit-installation' | translate }}
    </button>
    <button gbc-menu-item *ngIf="showDeveloperButton$ | async" [routerLink]="['data-table']">
      <img style="margin-right: 10px" src="./assets/svgs/edit.svg" />
      Datapoints
    </button>
  </ng-container>
</gbc-page-header>
<gbc-tabs>
  <gbc-tab name="plantroom" [title]="'installation-view.page-tab-plantroom' | translate" class="app-tab-list">
    <gbc-page-container>
      <app-pasteurization-status-tile
        [dataPoints$]="dataPoints$"
        [installationId]="(installation$ | async)?.id"
        [systemId]="(pasteurizationSupportedSystemApplication$ | async)?.id">
      </app-pasteurization-status-tile>
      <app-mixit-x509-banner/>
      <app-alarm-bar *ngIf="alarm$ | async" [alarm]="alarm$"></app-alarm-bar>
      <app-schematics-tile
        [installationId]="(installation$ | async)?.id"
        [commissionState]="installationCommissionState$ | async"
        [dataPoints$]="dataPoints$"
        (applicationClicked)="navigateToApplication($event)"
        angulartics2On="click"
        angularticsAction="applicationClicked"
        angularticsCategory="buildingConnectSchematic">
      </app-schematics-tile>
      <app-scheduling-tile *ngIf="showScheduling$ | async" [installation$]="installation$"></app-scheduling-tile>
      <app-installation-system-status
        (alarmClick)="router.navigate(['system-status', 'alarm', $event.id], { relativeTo: route })"
        (settingsClick)="router.navigate(['system-status', { tab: 'settings' }], { relativeTo: route })"
        (showAllAlarms)="router.navigate(['system-status', { tab: 'overview' }], { relativeTo: route })"
        [showSettings]="true">
      </app-installation-system-status>
      <app-forward-return-chart-tile
        *ngIf="districtHeatingApplication$ | async as districtHeatingApplication"
        [title]="'installation-view.district-heating-temp-title' | translate"
        [applicationId]="districtHeatingApplication.id"
        forwardDumbledoreId="T_PF"
        returnDumbledoreId="T_PR"
        [dataPoints$]="dataPoints$"
        [userTrackingPeriodOptions]="{ category: 'application/buildingConnectSchematic', intervalPrefix: 'primaryTemperature' }">
      </app-forward-return-chart-tile>
    </gbc-page-container>
  </gbc-tab>
  <gbc-tab name="energy_dashboard" [title]="'installation-view.page-tab-energy-dashboard' | translate" class="app-tab-list">
    <app-energy-management-dashboard></app-energy-management-dashboard>
  </gbc-tab>
  <gbc-tab name="activity" [title]="'installation-view.page-tab-activity' | translate" class="app-tab-list">
    <gbc-page-container data-test-id="activity-page-container">
      <app-installation-activity-tile [installationId]="(installation$ | async)?.id" [activities$]="activityComments$" />
    </gbc-page-container>
  </gbc-tab>
</gbc-tabs>
