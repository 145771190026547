import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Facility, Installation } from '../../interfaces/facilty';
import { PageInfoService } from '../../services/page-info.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, from, Observable, of, Subscription } from 'rxjs';
import { filter, first, map, shareReplay, tap } from 'rxjs/operators';
import { AlarmOverview, Application } from '../../interfaces/alarm';
import { ECommissionStatus, PageInfo } from 'shared';
import { AppModel, SystemType } from '@ams/dumbledore';
import { SchematicsService } from '../../services/schematics.service';
import { DataPointsResult } from '../../interfaces/data-points';
import { DataPointsService } from '../../services/data-points.service';
import { AlarmService } from '../../services/alarm.service';
import { UserService } from '../../services/user.service';
import { AccessClaim } from 'projects/shared/src/lib/interfaces/access';
import { InstallationService } from '../../services/installation.service';
import { ActivityComment } from '../../interfaces/installation';
import { FeatureToggleService } from '../../../../../shared/src/lib/services/feature-toggle.service';

@Component({
  selector: 'app-building-connect-installation-page',
  templateUrl: './building-connect-installation-page.component.html',
  styleUrls: ['./building-connect-installation-page.component.scss'],
})
export class BuildingConnectInstallationPageComponent implements OnInit, OnDestroy {
  @Input() public installation$: Observable<Installation>;
  @Input() public facility$: Observable<Facility>;
  @Input() public schematic$: Observable<AppModel>;

  public pageInfo$: Observable<PageInfo>;
  public districtHeatingApplication$: Observable<Application | undefined>;
  public pasteurizationSupportedSystemApplication$: Observable<Application | undefined>;
  public alarm$: Observable<AlarmOverview>;
  public dataPoints$: Observable<DataPointsResult | null>;
  private applications$: Observable<Application[]>;
  public installationCommissionState$: Observable<ECommissionStatus>;
  public firmwareVersion$: Observable<string>;
  public showDeveloperButton$: Observable<boolean>;
  public showScheduling$: Observable<boolean>;
  private initialInstallationSubscription: Subscription;
  public activityComments$: Observable<ActivityComment[]> = this.installationService.activityUpdate$;

  private featureFlagPasteurizationHotWaterTank$: Observable<boolean>;
  private featureFlagPasteurizationHotWaterTankStandAlone$: Observable<boolean>;
  private featureFlagPasteurizationCommercialHotWater$: Observable<boolean>;
  private featureFlagPasteurizationCommercialHotWaterStandAlone$: Observable<boolean>;
  private featureFlagPasteurizationCascadeTank$: Observable<boolean>;

  constructor(
    private alarmService: AlarmService,
    private pageInfoService: PageInfoService,
    public route: ActivatedRoute,
    public router: Router,
    private schematicService: SchematicsService,
    private dataPointService: DataPointsService,
    private userService: UserService,
    private installationService: InstallationService,
    private featureToggleService: FeatureToggleService
  ) {}

  ngOnInit() {
    this.applications$ = this.schematicService.applications$;

    this.dataPoints$ = combineLatest([this.installation$, this.dataPointService.dataPoints$]).pipe(
      filter(([_, dataPoints]) => !!dataPoints),
      map(([installation, dataPoints]) => {
        return dataPoints.find((d) => d.installationId === installation.id) || null;
      })
    );

    this.featureFlagPasteurizationHotWaterTank$ = this.featureToggleService.getFeatureFlag('PasteurizationSchedule-HotWaterTankSystem');
    this.featureFlagPasteurizationHotWaterTankStandAlone$ = this.featureToggleService.getFeatureFlag('PasteurizationSchedule-HotWaterTankStandAloneSystem');
    this.featureFlagPasteurizationCommercialHotWater$ = this.featureToggleService.getFeatureFlag('PasteurizationSchedule-CommercialHotWaterSystem');
    this.featureFlagPasteurizationCommercialHotWaterStandAlone$ = this.featureToggleService.getFeatureFlag('PasteurizationSchedule-CommercialHotWaterStandaloneSystem');
    this.featureFlagPasteurizationCascadeTank$ = this.featureToggleService.getFeatureFlag('PasteurizationSchedule-CascadeTankSystem');

    this.firmwareVersion$ = this.schematicService.getFirmwareVersion();

    this.installationCommissionState$ = this.installation$.pipe(map((installation) => installation.commissionStatus));

    this.pageInfo$ = combineLatest([this.facility$, this.installation$, this.firmwareVersion$]).pipe(
      map(([facility, installation, version]) => this.pageInfoService.installation(facility, installation, version)),
      shareReplay()
    );

    this.districtHeatingApplication$ = this.applications$.pipe(
      map((applications) => applications.find((application) => application.type === SystemType.DistrictHeatingSupply))
    );

    this.pasteurizationSupportedSystemApplication$ = this.applications$.pipe(
      map((applications) => applications.find((application) => application.type === SystemType.CommercialHotWaterStandalone || application.type === SystemType.HotWaterTank))
    );

    this.alarm$ = this.alarmService.mostImportantAlarm$ as Observable<AlarmOverview>;

    this.showDeveloperButton$ = this.userService.hasClaim(AccessClaim.CustomerPortalDataPointsTable);

    this.showScheduling$ = combineLatest([
      this.schematic$,
      this.userService.hasClaim(AccessClaim.CustomerPortalTestFeatures),
      this.featureFlagPasteurizationHotWaterTank$,
      this.featureFlagPasteurizationHotWaterTankStandAlone$,
      this.featureFlagPasteurizationCommercialHotWater$,
      this.featureFlagPasteurizationCommercialHotWaterStandAlone$,
      this.featureFlagPasteurizationCascadeTank$,
    ]).pipe(
      map(([schematic, hasTestFeaturesClaim, featureFlagPasteurizationHotWaterTank, featureFlagPasteurizationHotWaterTankStandAlone, featureFlagPasteurizationCommercialHotWater, featureFlagPasteurizationCommercialHotWaterStandAlone, featureFlagPasteurizationCascadeTank]) => {
        // Check for 'Commercial Hot Water Standalone' system (and Mercure-hack-claim).
        if (schematic.systems.some((s) => s.type === SystemType.CommercialHotWaterStandalone) && (hasTestFeaturesClaim || featureFlagPasteurizationCommercialHotWaterStandAlone)) return true;

        // Check for 'Hot Water Tank' system (and feature flag).
        if (schematic.systems.some((s) => s.type === SystemType.HotWaterTank) && featureFlagPasteurizationHotWaterTank) return true;

        // Check for 'Hot Water Tank Stand Alone' system (and feature flag).
        if (schematic.systems.some((s) => s.type === SystemType.HotWaterTankStandAloneSystem) && featureFlagPasteurizationHotWaterTankStandAlone) return true;

        // Check for 'Commercial Hot Water' system (and feature flag).
        if (schematic.systems.some((s) => s.type === SystemType.CommercialHotWater) && featureFlagPasteurizationCommercialHotWater) return true;

        // Check for 'Cascade Tank' system (and feature flag).
        if (schematic.systems.some((s) => s.type === SystemType.CascadeTankSystem) && featureFlagPasteurizationCascadeTank) return true;

        // Otherwise
        return false;
      })
    );

    // Initial load of activities.
    this.initialInstallationSubscription = this.installation$.subscribe((installation: Installation) => {
      this.installationService.getActivities(installation.id).subscribe((latestActivities: ActivityComment[]) => {
        this.installationService.pushActivityUpdate(latestActivities);
      });
    });
  }

  ngOnDestroy(): void {
    this.initialInstallationSubscription.unsubscribe();
  }

  public navigateToApplication(applicationId: string) {
    this.router.navigate(['application', applicationId], { relativeTo: this.route });
  }

  public editInstallation() {
    combineLatest([this.facility$, this.installation$])
      .pipe(
        first(),
        tap(([facility, installation]) => {
          this.router.navigate(['/edit-installation', facility.id, installation.id]);
        })
      )
      .subscribe();
  }
}
