import { PasteurizationState, SystemState, SystemType } from '@ams/dumbledore';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { DataPointsResult, System } from '../../interfaces/data-points';
import { ModalService } from 'shared';
import { InstallationConfigurationService } from '../../services/installation-configuration.service';
import { UserTrackingHelperService } from '../../services/user-tracking-helper.service';
import { SchematicsService, SchematicWithMeta } from '../../services/schematics.service';
import { EventTypes } from '../../interfaces/scheduling';
import { getPasteurizationState } from '../../utils/data-point-utils';
import { SystemDeviceType } from '../../interfaces/systemDeviceType';

type applicationInfo = {
  id: string;
  systemTitle: string;
  pasteurizationState: PasteurizationState;
};

@Component({
  selector: 'app-pasteurization-status-tile',
  templateUrl: './pasteurization-status-tile.component.html',
  styleUrls: ['./pasteurization-status-tile.component.scss'],
})
export class PasteurizationStatusTileComponent implements OnInit {
  constructor(
    private schematicsService: SchematicsService,
    private translateService: TranslateService,
    private installationConfigurationService: InstallationConfigurationService,
    private modalService: ModalService,
    private userTrackingHelperService: UserTrackingHelperService
  ) {}

  @Input() public dataPoints$: Observable<DataPointsResult>;
  @Input() public installationId: string;
  @Input() public systemId: string;

  public pasteurizationState$: Observable<PasteurizationState | undefined>;
  public shouldHideStopButton$: Observable<boolean>;
  public shouldShowCancelingButton$: Observable<boolean>;
  public applicationsState$: Observable<applicationInfo[]>;

  private userCanceledAtStateSource = new BehaviorSubject<PasteurizationState | undefined>(undefined);
  private userCanceledAtState$ = this.userCanceledAtStateSource.asObservable();

  protected readonly PasteurizationState = PasteurizationState;

  ngOnInit(): void {
    this.pasteurizationState$ = this.dataPoints$.pipe(
      filter((dataPoints) => !!dataPoints),
      map((dataPoints) => {
        for (const data of dataPoints.data) {
          let pasteurizationState = getPasteurizationState(data);
          if (pasteurizationState !== undefined) {
            return pasteurizationState;
          }
        }
        return undefined;
      }),
      tap((pasteurizationState) => {
        if (
          pasteurizationState === undefined ||
          (this.userCanceledAtStateSource.value !== undefined && pasteurizationState < this.userCanceledAtStateSource.value)
        ) {
          // If the state is lower (earlier) than at the point where the user canceled a job,
          // it must mean it's another job that is currently running than the one that the user canceled;
          // reset any local canceling state
          this.userCanceledAtStateSource.next(undefined);
        } else if (pasteurizationState === PasteurizationState.CoolDown) {
          // If state has changed to cooldown, the user may no longer cancel; reset any local canceling state
          this.userCanceledAtStateSource.next(undefined);
        }
      })
    );

    this.applicationsState$ = combineLatest([this.dataPoints$, this.schematicsService.schematicAndMeta$]).pipe(
      filter((dataPoints) => !!dataPoints),
      map(([dataPointsResult, schematicWithMeta]) => {
        return this.systemsSupportingPasteurization(schematicWithMeta).map((systemState: SystemState) => {
          const targetSystemId = systemState.hasId;
          const system = dataPointsResult.data.find((system) => system.systemId === targetSystemId);
          return {
            id: targetSystemId,
            systemTitle: systemState.systemInfo.title || this.getDefaultSystemTitle(systemState.type),
            pasteurizationState: system ? this.getPasturizationStateForSystem(system, targetSystemId) : PasteurizationState.Off,
          };
        });
      })
    );

    this.shouldHideStopButton$ = combineLatest([
      this.pasteurizationState$,
      this.schematicsService.schematicAndMeta$,
      this.userCanceledAtState$,
    ]).pipe(
      map(([pasteurizationState, schematic, userCanceledAtState]) => {
        if (userCanceledAtState !== undefined && pasteurizationState !== undefined && userCanceledAtState <= pasteurizationState) {
          return true;
        }

        if (pasteurizationState === PasteurizationState.CoolDown) {
          return true;
        }

        // Find out if any jobs are suppressed (ie. canceling or canceled)
        const pasteurizationScheduledevents = schematic.scheduledEvents.filter((s) => s.eventType == EventTypes.Pasteurization);
        const cancelingJobs = pasteurizationScheduledevents.filter((s) => s.timeIntervals.some((ti) => ti.isSuppressed));
        return cancelingJobs.length > 0;
      })
    );

    this.shouldShowCancelingButton$ = combineLatest([this.pasteurizationState$, this.userCanceledAtState$]).pipe(
      map(([pasteurizationState, userCanceledAtState]) => {
        // TODO: In case we want to show a "Canceling" button after canceling a pasteurization job, use this line:
        //return userCanceledAtState !== undefined && (pasteurizationState === PasteurizationState.Heating || pasteurizationState === PasteurizationState.Retention);

        return false;
      })
    );
  }

  public getStateMessage(pasteurizationState: PasteurizationState): string {
    const pasteurizationStateKey = Object.keys(PasteurizationState)[+pasteurizationState];
    return this.translateService.instant(`pasteurization-info.message-${pasteurizationStateKey.toLowerCase()}`);
  }

  public getPasteurizationStateName(state: PasteurizationState): string {
    return Object.keys(PasteurizationState)[+state].toLowerCase();
  }

  private getPasturizationStateForSystem(system: System, targetSystemId: string): PasteurizationState {
    const device = system.devices.find(
      (device) => device.deviceId === targetSystemId && device.type === SystemDeviceType.PasteurizationState
    );
    if (!device) return PasteurizationState.Off;
    const value = +(device.dataPoints[0].value ?? 0);
    return value === 0 ? PasteurizationState.Off : (value.toString() as PasteurizationState);
  }

  public cancelPasteurizationJob(currentPasteurizationState: PasteurizationState) {
    this.modalService.showTextModal({
      title: this.translateService.instant('pasteurization-info.confirm-cancel-job-title'),
      content: this.translateService.instant('pasteurization-info.confirm-cancel-job-description'),
      actions: [
        {
          text: this.translateService.instant('pasteurization-info.stop-button'),
          type: 'danger',
          handler: () => {
            this.userCanceledAtStateSource.next(currentPasteurizationState);
            this.userTrackingHelperService.trackUserAction('cancelPasteurizationJobClicked', 'stopJobConfirmClicked');
            return this.installationConfigurationService.cancelPasteurizationJob(this.installationId).subscribe();
          },
        },
        {
          cancel: true,
          text: this.translateService.instant('app-cancel'),
          handler: () => {
            this.userTrackingHelperService.trackUserAction('cancelPasteurizationJobClicked', 'stopJobCancelClicked');
          },
        },
      ],
    });
  }

  private systemsSupportingPasteurization(schematicWithMeta: SchematicWithMeta): SystemState[] {
    // Update with other types as needed.
    // REVIEW: Why not include all systems, since only systems supporting pasteurization should send this info.
    const supportedSystemTypes: SystemType[] = [
      SystemType.HotWaterTank,
      SystemType.HotWaterTankStandAloneSystem,
      SystemType.CommercialHotWater,
      SystemType.CommercialHotWaterStandalone,
      SystemType.CascadeTankSystem
    ];
    return schematicWithMeta.schematicDto.systems.filter((system: SystemState) => supportedSystemTypes.includes(system.type));
  }

  private getDefaultSystemTitle = (systemType: SystemType) => {
    // The default systems tiles are hard-coded to mimic SystemMeta.Name used by Dumbledore (not translated).
    switch (systemType) {
      case SystemType.CommercialHotWater : return "Commercial Hot Water";
      case SystemType.CommercialHotWaterStandalone : return "Commercial Hot Water Standalone";
      case SystemType.HotWaterTank : return "Hot Water Tank";
      case SystemType.HotWaterTankStandAloneSystem : return "Hot Water Tank Standalone";
      case SystemType.CascadeTankSystem : return "Cascade Tank System";
      default : return systemType.toString();
    }
  }
}
