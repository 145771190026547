<!--  Temperature tile -->
<gbc-tile [title]="'mixit-premium-dashboard.temperatures' | translate">
  <!--  Disabled until we can write to local_setpoint  -->
  <button gbc-icon-button gbc-tile-right
    (click)="adjustTemperatureSetpoint()"
    angulartics2On="click"
    angularticsAction="adjustTemperatureSetpointConfigurationsShown"
    angularticsCategory="mixitSchematicClicked">
    <img [alt]="'mixit-premium-dashboard.adjust-temperature-setpoint' | translate" src="./assets/svgs/adjust-outline-black.svg">
  </button>
  <div class="tile-content">

    <div class="tile-row">
      <div class="tile-row-name">{{'mixit-premium-dashboard.setpoint' | translate}}:</div>
      <div *ngIf="(isLocalSetPoint$ | async) === false" class="tile-row-value">
        {{setpoint$ | async | formatDataPoint}}
      </div>
      <div *ngIf="isLocalSetPoint$ | async" class="tile-row-value">
        {{localSetpoint$ | async | formatDataPoint}}
      </div>
    </div>

    <div class="tile-row">
      <div class="tile-row-name">{{'mixit-premium-dashhboard.setpoint-source' | translate}}:</div>
      <div class="tile-row-value" *ngIf="setpointSource$ | async as setpointSource">
        {{ 'mixit-setpoint-source.' + setpointSource | translate}}
      </div>
    </div>

  </div>
</gbc-tile>
