import classnames from 'classnames';
import React, { useState } from 'react';
import styled from 'styled-components';
import { TextQuestion } from './TextQuestion';
import { BooleanQuestion } from './BooleanQuestion';
import { formService } from '../../services/formService';
import { TextAreaQuestion } from './TextAreaQuestion';
import { DropdownQuestion } from './DropdownQuestion';
import { HtmlPanelContent } from './HtmlPanelContent';
import panelValidSVG from '!svg-url-loader!../../../assets/icons/panel-valid.svg';
import panelArrowSVG from '!svg-url-loader!../../../assets/icons/panel-arrow.svg';
import panelInvalidSVG from '!svg-url-loader!../../../assets/icons/panel-invalid.svg';
import { IBooleanQuestion, IDropdownQuestion, IFormPage, IHTMLQuestion, IPanel, ITextQuestion, ITextAreaQuestion } from '../../models/form.interface';

interface PanelProps {
  panel: IPanel;
  page?: IFormPage;
  defaultVariableValues?: any;
  data: any;
  onChange: (newData: any, oldData?: any) => void;
}

const padding = '18px';
const panelHeaderBorder = '1px solid #d9dddf';
const StyledPanel = styled.div`
  border-top: ${panelHeaderBorder};
  .panel-header {
    display: flex;
    padding: ${padding} 0;
    padding-left: 19px;

    cursor: pointer;
  }

  .panel-title {
    user-select: none;
    flex: 1;
    margin: 0;
    font-weight: 700;

    opacity: 0.8;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.35px;
    color: #000000;
  }

  .panel-validity-icon {
    margin-right: 14px;
    // display: inline-block;
  }

  .panel-open-closed-icon {
    margin-right: 22px;
  }

  .panel-closed-icon {
    transform: rotate(180deg);
  }

  .panel-body {
    border-top: ${panelHeaderBorder};
    padding: ${padding};
    padding-top: 0;
  }
`;

export const Panel: React.FC<PanelProps> = ({ panel, data, onChange }) => {
  const [open, setOpen] = useState(false);
  const valid = formService.panelValid(panel, data);
  let panelIcon;
  if (valid) {
    panelIcon = <img className="panel-validity-icon" src={panelValidSVG} alt="Valid icon" />;
  } else {
    panelIcon = <img className="panel-validity-icon" src={panelInvalidSVG} alt="Invalid icon" />;
  }

  const onQuestionChange = (newData: any, oldData?: any) => {
    onChange(newData, oldData);
  };

  if (!formService.isElementVisible(panel, data)) {
    return null;
  }

  const questions = panel.elements
    .filter((element) => formService.isElementVisible(element, data))
    .map((element: IBooleanQuestion | ITextQuestion | ITextAreaQuestion | IDropdownQuestion | IHTMLQuestion) => {
      switch (element.type) {
        case 'dropdown':
          return (
            <DropdownQuestion
              key={`${element.name}-${element.title}-${element.visibleIf}`}
              question={element}
              data={data}
              onChange={onQuestionChange}
            />
          );
        case 'boolean':
          return (
            <BooleanQuestion
              key={`${element.name}-${element.title}-${element.visibleIf}`}
              question={element}
              data={data}
              onChange={onQuestionChange}
            />
          );
        case 'text':
          return (
            <TextQuestion
              key={`${element.name}-${element.title}-${element.visibleIf}`}
              data={data}
              question={element}
              onChange={onQuestionChange}
            />
          );
        case 'textarea':
          return (
            <TextAreaQuestion
              key={`${element.name}-${element.title}-${element.visibleIf}`}
              data={data}
              question={element}
              onChange={onQuestionChange}
            />
          );
        case 'html':
          return <HtmlPanelContent key={`${element.name}-${element.title}-${element.visibleIf}`} htmlText={element.html} />;
      }
    });

  if (questions.length === 0) {
    return null;
  }

  return (
    <StyledPanel className={classnames({ panel: true, 'panel-open': open, 'panel-closed': !open })}>
      <div className="panel-header" onClick={(_) => setOpen(!open)} data-name={panel.name}>
        <h4 className="panel-title">{panel.title}</h4>
        {panelIcon}
        <img src={panelArrowSVG} className={classnames({ 'panel-closed-icon': !open, 'panel-open-closed-icon': true })} alt="" />
      </div>
      {open && <div className="panel-body">{questions}</div>}
    </StyledPanel>
  );
};
