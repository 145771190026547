import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataPointsService } from '../../services/data-points.service';
import { PageInfoService } from '../../services/page-info.service';
import { combineLatest, Observable, timer } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import { DataPointsResult, DeviceDataPoint } from '../../interfaces/data-points';
import { PageInfo } from 'shared';
import { FacilityService } from '../../services/facility.service';
import { SchematicsService } from '../../services/schematics.service';

interface DataForDisplay extends DeviceDataPoint {
  secondsAgo: number;
}

@Component({
  selector: 'app-developer-data-page',
  templateUrl: './developer-data-page.component.html',
  styleUrls: ['./developer-data-page.component.scss']
})
export class DeveloperDataPageComponent implements OnInit {

  public dataPoints$: Observable<DataPointsResult[]>;

  public pageInfo$: Observable<PageInfo>;

  public rows$: Observable<DataForDisplay[]>;
  public columns: any[] = [
    {prop: 'dataPointId', minWidth: 300},
    {prop: 'dumbledoreId', minWidth: 150},
    {prop: 'humanReadableId', minWidth: 280},
    {prop: 'value', minWidth: 220},
    {prop: 'valueType', minWidth: 120},
    {prop: 'unitType', minWidth: 150},
    {prop: 'timestampEpoch', minWidth: 150},
    {prop: 'state', minWidth: 100},
    {prop: 'type', minWidth: 120},
    {prop: 'secondsAgo', minWidth: 120},
  ];

  constructor(
    public route: ActivatedRoute,
    public dataPointService: DataPointsService,
    public pageInfoService: PageInfoService,
    public facilityService: FacilityService,
    private schematicService: SchematicsService,
  ) { }

  ngOnInit(): void {
    this.dataPoints$ = this.dataPointService.dataPoints$;
    const facility$ = this.facilityService.getCurrentFacility(this.route);
    const installation$ = this.facilityService.getCurrentInstallation(this.route);
    const firmwareVersion$ = this.schematicService.getFirmwareVersion();

    this.pageInfo$ = combineLatest([facility$, installation$, firmwareVersion$]).pipe(
      map(([facility, installation, firmwareVersion]) => {
        return this.pageInfoService.dataPointsPage(facility, installation, firmwareVersion);
      })
    );


    this.rows$ = timer(0, 5000).pipe(
      withLatestFrom(installation$, this.dataPoints$.pipe(filter(d => !!d))),
      map(([_, installation, dataPoints]) => {
        return dataPoints.find(d => d.installationId === installation.id);
      }),
      filter(dataPoints => !!(dataPoints?.data)),
      map(dataPoints => {
        return (dataPoints as DataPointsResult).data.flatMap(data => {
          return data.devices.flatMap(device => {
            return device.dataPoints.map(d => {
              const secs: number = Math.round((+new Date() - d.timestampEpoch) / 1000);
              const value: DataForDisplay = {
                ...d,
                secondsAgo: secs,
              };
              return value;
            });
          });
        });
      })
    );
  }

  getRowClass(row: DataForDisplay) {
    return {
      'recently-updated': row.secondsAgo <= 20,
      'event-data': row.type === 'Event',
    };
  }
}
