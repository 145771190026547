import { Component, OnInit } from '@angular/core';
import { AppError, PageInfo, AppErrorService } from 'shared';
import { Observable, combineLatest } from 'rxjs';
import { PageInfoService } from 'projects/customerportal/src/app/services/page-info.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Facility } from 'projects/customerportal/src/app/interfaces/facilty';
import { map, debounceTime, startWith, first, tap } from 'rxjs/operators';
import { FacilityService } from 'projects/customerportal/src/app/services/facility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { navigateToFacility } from '../../utils/navigate-utils';
import { formValues } from '../../../../../shared/src/lib/constants/form-values';
import { FacilityData } from '../../components/map/map.component';

@Component({
  selector: 'app-edit-facility-page',
  templateUrl: './edit-facility-page.component.html',
  styleUrls: ['./edit-facility-page.component.scss'],
})
export class EditFacilityPageComponent implements OnInit {
  public pageError$: Observable<AppError>;
  public pageInfo$: Observable<PageInfo>;
  public facility$: Observable<Facility>;
  public mapsUrl$: Observable<string>;

  latitude: number;
  longitude: number;

  public newLocationfacilityList$: Observable<FacilityData>;

  public form = new UntypedFormGroup({
    name: new UntypedFormControl(undefined, [
      Validators.required,
      Validators.minLength(2),
      Validators.maxLength(formValues.max_length.name),
    ]),
    road: new UntypedFormControl(undefined, [Validators.required, Validators.maxLength(formValues.max_length.address)]),
    city: new UntypedFormControl(undefined, [Validators.required, Validators.maxLength(formValues.max_length.address)]),
    postal: new UntypedFormControl(undefined, [Validators.required, Validators.maxLength(formValues.max_length.address)]),
    latLong: new UntypedFormControl(undefined, [
      Validators.required,
      Validators.pattern(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/),
    ]),
  });

  constructor(
    private appErrorService: AppErrorService,
    private pageInfoService: PageInfoService,
    private facilityService: FacilityService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.facility$ = this.facilityService.getCurrentFacility(this.route);

    this.pageError$ = this.appErrorService.createPageErrorObservable([this.facility$]);
    this.pageInfo$ = this.facility$.pipe(
      map((facility) => {
        return this.pageInfoService.editFacility(facility);
      })
    );
  }

  ngOnInit() {
    this.facility$
      .pipe(
        first(),
        tap((facility: Facility) => {
          this.latitude = facility.location.latitude;
          this.longitude = facility.location.longitude;
          this.form.patchValue({
            name: facility.name,
            road: facility.addressRoad,
            city: facility.addressCity,
            postal: facility.addressPostal,
            latLong: `${facility.location.latitude}, ${facility.location.longitude}`,
          });

          this.initMap();
        })
      )
      .subscribe();
  }

  private initMap() {
    this.newLocationfacilityList$ = combineLatest([
      this.form.controls.name.valueChanges.pipe(startWith(this.form.controls.name.value)),
      this.form.controls.latLong.valueChanges.pipe(startWith(this.form.controls.latLong.value)),
    ]).pipe(
      debounceTime(500),
      map(([name, latLong]: [string, string]) => {
        if (!latLong) return { data: [], trigger: "NewLocationFacility" };
        const [lat, long] = latLong.split(',');
        const partialFacility: Partial<Facility> = {
          id: 0,
          name,
          installations: [],
          location: {
            latitude: Number(lat),
            longitude: Number(long),
          },          
        };
        return { data: [partialFacility as Facility], trigger: "NewLocationFacility" };
      })
    );

    this.mapsUrl$ = this.form.valueChanges.pipe(
      startWith(this.form.value),
      map(({ road, postal, city }) => {
        if (road && postal && city) {
          // Replace all spaces with + then remove any duplicate +.
          return `https://www.google.com/maps/place/${road}+${postal}+${city}`.split(' ').join('+').replace(/\++/g, '+');
        }
        return `https://www.google.com/maps`;
      })
    );
  }

  update() {
    this.facility$
      .pipe(
        first(),
        tap((facility) => {
          const [lat, long] = this.form.value.latLong.split(',');
          this.facilityService
            .updateFacility({
              id: this.route.snapshot.params.facilityId,
              name: this.form.value.name,
              addressRoad: this.form.value.road,
              addressCity: this.form.value.city,
              addressPostal: this.form.value.postal,
              location: {
                latitude: Number(lat),
                longitude: Number(long),
              },
            })
            .subscribe(() => {
              navigateToFacility(this.router, facility);
            });
        })
      )
      .subscribe();
  }
}
