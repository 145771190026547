import React from 'react';
import { Labels } from '../../Labels';
import { Strainer } from '../../Strainer';
import { Pipe, PipeType } from '../../Pipe';
import { colors } from '../../../../util/colors';
import { EConnectionType } from '../../../state';
import { BaseSystemProps, System } from '../../System';
import { getLabels, PIPE_Y, pressureSensorMetadata, WIDTH } from './pressure-sensor-metadata';
import { IPressureSensorSystemFormInterface } from '../../../../models/systems/pressureSensorSystem/pressureSensorSystemFormInterface';

const DIFF_PRESSURE_CIRCLE_RADIUS = 10;

export const PressureSensorSystem: React.FC<BaseSystemProps<IPressureSensorSystemFormInterface>> = React.memo(
  ({ state, values, alarm, selected }) => {
    const systemInfo: IPressureSensorSystemFormInterface = state.systemInfo;

    const pipeType = systemInfo.Location === 'Forward' ? PipeType.WarmSupply : PipeType.ColdReturn;

    const dirtCollector = () => {
      const verticalSpace = 50;
      const pipeTop = 100;

      const sensorTop = pipeTop - 20;

      const strainerWidth = 60;
      const strainerHeight = 30;
      const strainerColor =
        systemInfo.Location === 'Forward'
          ? colors.connections[EConnectionType.supply].regular
          : colors.connections[EConnectionType.return].regular;
      return (
        <>
          {/* Bottom left vertical pipe */}
          <Pipe x1={0} x2={WIDTH / 2 - strainerWidth / 2} y1={PIPE_Y} y2={PIPE_Y} type={pipeType} />

          {/* Bottom right vertical pipe */}
          <Pipe x1={WIDTH / 2 + strainerWidth / 2} x2={WIDTH} y1={PIPE_Y} y2={PIPE_Y} type={pipeType} />

          {/* Left horizontal pipe */}
          <Pipe x1={verticalSpace} y1={PIPE_Y} x2={verticalSpace} y2={pipeTop} type={pipeType} />

          {/* Right horizontal pipe */}
          <Pipe x1={verticalSpace} y1={pipeTop} x2={WIDTH - verticalSpace} y2={pipeTop} type={pipeType} />

          {/* Top vertical pipe */}
          <Pipe x1={WIDTH - verticalSpace} y1={PIPE_Y} x2={WIDTH - verticalSpace} y2={pipeTop} type={pipeType} />

          {/* Top horizontal pipe going to sensor */}
          <Pipe x1={WIDTH / 2} y1={pipeTop} x2={WIDTH / 2} y2={sensorTop} type={pipeType} />

          <circle cy={sensorTop} cx={WIDTH / 2} r={DIFF_PRESSURE_CIRCLE_RADIUS} fill={colors.background} stroke="black" strokeWidth="1.5" />
          <text y={sensorTop + 1.25} x={WIDTH / 2} dominantBaseline="middle" textAnchor="middle" fontSize="12">
            {'\u0394P'}
          </text>

          <Strainer fill={strainerColor} x={WIDTH / 2 - strainerWidth / 2} y={PIPE_Y - strainerHeight / 2} />
        </>
      );
    };

    const noDirtCollector = () => {
      const sensorTop = PIPE_Y - 20;
      return (
        <>
          <Pipe x1={0} x2={WIDTH} y1={PIPE_Y} y2={PIPE_Y} type={pipeType} />
          <Pipe x1={WIDTH / 2} y1={PIPE_Y} x2={WIDTH / 2} y2={sensorTop} type={pipeType} />
          <circle cy={sensorTop} cx={WIDTH / 2} r={DIFF_PRESSURE_CIRCLE_RADIUS} fill={colors.background} stroke="black" strokeWidth="1.5" />
          <text y={sensorTop + 1.25} x={WIDTH / 2} dominantBaseline="middle" textAnchor="middle" fontSize="12">
            {'P'}
          </text>
        </>
      );
    };

    return (
      <System
        key={state.hasId}
        state={state}
        meta={pressureSensorMetadata}
        alarm={alarm}
        selected={selected}
        titleY={systemInfo.hasDirtCollector ? 60 : 75}
        titleX={WIDTH / 2}
      >
        {systemInfo.hasDirtCollector ? dirtCollector() : noDirtCollector()}
        <Labels positions={getLabels(systemInfo)} values={values} />
      </System>
    );
  }
);
