import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppError, AppErrorService, PageInfo, ECommissionStatus } from 'shared';
import { Facility, Installation } from 'projects/customerportal/src/app/interfaces/facilty';
import { map, flatMap, shareReplay, withLatestFrom, first, filter } from 'rxjs/operators';
import { PageInfoService } from 'projects/customerportal/src/app/services/page-info.service';
import {
  ISchematicWithInstallation,
  SchematicsService
} from 'projects/customerportal/src/app/services/schematics.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataPointsResult } from 'projects/customerportal/src/app/interfaces/data-points';
import { DataPointsService } from 'projects/customerportal/src/app/services/data-points.service';
import { TranslateService } from '@ngx-translate/core';
import { AlarmType } from 'projects/customerportal/src/app/interfaces/system-status';
import { maxBy } from 'lodash';
import { flattenDataPoints } from 'projects/customerportal/src/app/utils/data-point-utils';
import { navigateToInstallation } from '../../utils/navigate-utils';
import { FacilityService } from '../../services/facility.service';
import { GroupType, SocketService } from '../../services/socket.service';


interface ISchematicWithInstallationAndDataPoints extends ISchematicWithInstallation {
  dataPoints: DataPointsResult;
}

interface ISchematicWithInstallationAndDataPointsAndAlarms extends ISchematicWithInstallationAndDataPoints {
  alarm: { type: AlarmType, systemId: string };
  lastUpdated: number;
}

@Component({
  selector: 'app-connectingcore-facility-page',
  templateUrl: './connectingcore-facility-page.component.html',
  styleUrls: ['./connectingcore-facility-page.component.scss'],
})
export class ConnectingcoreFacilityPageComponent implements OnInit, OnDestroy {
  public facility$: Observable<Facility>;
  public installationsAndSchematics$: Observable<ISchematicWithInstallationAndDataPointsAndAlarms[]>;

  public pageError$: Observable<AppError>;
  public pageInfo$: Observable<PageInfo>;
  public showAlarmBar$: Observable<boolean>;
  public showWarningBar$: Observable<boolean>;

  public outDoorTemperature$: Observable<string>;
  public averageDeltaT$: Observable<string>;

  public liveStatus = ECommissionStatus.Live;

  constructor(
    private errorService: AppErrorService,
    private pageInfoService: PageInfoService,
    private schematicsService: SchematicsService,
    private dataPointsService: DataPointsService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private facilityService: FacilityService,
    private socketService: SocketService,
  ) { }

  ngOnInit(): void {
    this.facility$ = this.facilityService.getCurrentFacility(this.route);

    this.pageError$ = this.errorService.createPageErrorObservable([this.facility$]);
    this.pageInfo$ = this.facility$.pipe(
      map(facility => {
        return this.pageInfoService.facility(facility);
      })
    );


    this.installationsAndSchematics$ = this.facility$.pipe(
      flatMap(facility => this.schematicsService.getSchematicsForFacility(facility)),
      // We need to load all the schematics up front, in order for the rendering to work properly
      // The Dumbledore schematics take up as much space as they have, so they need to be retrieved at the same time.
      flatMap(installationsAndSchematics => {
        const ids = installationsAndSchematics.map(installationAndSchematic => {
          return installationAndSchematic.installation.id;
        });

        return this.dataPointsService.dataPoints$.pipe(
          filter((dataPoints) => !!dataPoints),
          map(dataPointResults => {
            return installationsAndSchematics.map(installationAndSchematic => {
              const dataPointsForInstallation = dataPointResults.find(dataPointResult => dataPointResult.installationId === installationAndSchematic.installation.id) ||
                { data: [], heartBeatTimestamp: 0, installationId: installationAndSchematic.installation.id };
              const mostRecentDataPoint = maxBy(flattenDataPoints(dataPointsForInstallation.data), dataPoint => dataPoint.timestampEpoch);
              return {
                installation: installationAndSchematic.installation,
                appModel: installationAndSchematic.appModel,
                dataPoints: dataPointsForInstallation,
                alarm: {
                  type: installationAndSchematic.installation.state?.type,
                  systemId: installationAndSchematic.appModel.systems[0].hasId
                },
                lastUpdated: mostRecentDataPoint?.timestampEpoch || 0,
                installationMetaInfo: installationAndSchematic.installationMetaInfo
              };
            });
          })
        );
      }),
      shareReplay(),
    );

    this.showAlarmBar$ = this.facility$.pipe(map(facility => {
      return facility.installations.some(installation => installation.state?.type === 'Alarm');
    }));

    this.showWarningBar$ = this.facility$.pipe(
      map(facility => {
        return facility.installations.some(installation => installation.state?.type === 'Warning');
      }),
      withLatestFrom(this.showAlarmBar$),
      map(([showWarning, showAlarm]) => {
        if (showAlarm) {
          return false;
        }
        return showWarning;
      }),
    );

    this.facility$.pipe(
      first(),
    ).subscribe(facility => {
      const ids = facility.installations.map(i => i.id);
      this.socketService.subscribeMultiple(ids, [GroupType.Schematic, GroupType.Installation]);
    });

  }

  ngOnDestroy() {
    this.facility$.pipe(
      first(),
    ).subscribe(facility => {
      const ids = facility.installations.map(i => i.id);
      this.socketService.unsubscribe(ids, GroupType.Installation);
      this.socketService.unsubscribe(ids, GroupType.Schematic);
    });
  }

  handleClick(installation: Installation): void {
    navigateToInstallation(this.router, this.route.snapshot.params.facilityId, installation.id);
  }

  editFacility() {
    this.router.navigate(['/edit-facility', this.route.snapshot.params.facilityId]);
  }

  editInstallation(installationId: string) {
    this.router.navigate(['/edit-installation', this.route.snapshot.params.facilityId, installationId]);
  }
}
