import { SystemType, DistrictHeatingSystemState, BoilerSystemState, HeatExchangerSystemState } from '../state';
import { EEquipment, EValueType, IEquipmentValue, ISetPointValue, IValveOpenValue } from '../types';
import { SampleEquipmentValue } from './sample-equipment-values';
import { v4 } from 'uuid';

// WILL BE DEPRECATED
export const SAMPLE_HEAT_EXCHANGER: HeatExchangerSystemState = {
  hasId: v4(),
  type: SystemType.HeatExchanger,
  left: 10,
  top: 1800,
  configured: false,
  systemInfo: {
    Control_enabled: true,
    HM_present: true,
    HM_location: 'PrimaryForward',
    MV_valveLocation: 'PrimaryReturn',
    MV_Parallel_Valve_Enabled: true,
    T_SR_present: true,
    DP_S_present: true,
    DP_P_present: true
  }
};

export const SAMPLE_VALUES_HEAT_EXCHANGER: SampleEquipmentValue[] = [
  {
    systemType: SystemType.HeatExchanger,
    type: EValueType.value,
    equipment: EEquipment.T_PF,
    value: '72.9\u2103',
  },
  {
    systemType: SystemType.HeatExchanger,
    type: EValueType.value,
    equipment: EEquipment.T_PR,
    value: '33.7\u2103',
  },
  {
    systemType: SystemType.HeatExchanger,
    type: EValueType.value,
    equipment: EEquipment.T_SF,
    value: '54.3\u2103',
  },
  {
    systemType: SystemType.HeatExchanger,
    type: EValueType.value,
    equipment: EEquipment.T_SR,
    value: '25.3\u2103',
  },
  {
    systemType: SystemType.HeatExchanger,
    type: EValueType.setPoint,
    equipment: EEquipment.T_SF,
    value: '55\u2103',
  },
  {
    systemType: SystemType.HeatExchanger,
    type: EValueType.value,
    equipment: EEquipment.Q_PF,
    value: '10.3\u33a5/s',
  },
  {
    systemType: SystemType.HeatExchanger,
    type: EValueType.value,
    equipment: EEquipment.Q_PR,
    value: '10.3\u33a5/s',
  },
  { systemType: SystemType.HeatExchanger, type: EValueType.value, equipment: EEquipment.MV_2W_PF, value: '13%' },
  { systemType: SystemType.HeatExchanger, type: EValueType.value, equipment: EEquipment.MV_2W_PR, value: '13%' },
  { systemType: SystemType.HeatExchanger, type: EValueType.setPoint, equipment: EEquipment.MV_2W_PF, value: '24%' },
  { systemType: SystemType.HeatExchanger, type: EValueType.setPoint, equipment: EEquipment.MV_2W_PR, value: '24%' },
  { systemType: SystemType.HeatExchanger, type: EValueType.valveIndication, equipment: EEquipment.MV_2W_PF, value: 'opening' },
  { systemType: SystemType.HeatExchanger, type: EValueType.valveIndication, equipment: EEquipment.MV_2W_PR, value: 'closing' },
  { systemType: SystemType.HeatExchanger, type: EValueType.value, equipment: EEquipment.H_DIFF_P, value: '1.23 Bar' },
  { systemType: SystemType.HeatExchanger, type: EValueType.value, equipment: EEquipment.H_DIFF_S, value: '1.23 Bar' },
  {
    systemType: SystemType.HeatExchanger,
    type: EValueType.value,
    equipment: EEquipment.H_P,
    value: '0.2 Bar',
  },
  {
    systemType: SystemType.HeatExchanger,
    type: EValueType.value,
    equipment: EEquipment.H_S,
    value: '0.3 Bar',
  }
];
