<app-mixit-x509-banner/>
<app-alarm-bar *ngIf="alarm$ | async" [alarm]="alarm$"> </app-alarm-bar>

<gbc-banner type="info" *ngIf="manualForcedOpeningEnabled$ | async" [class]="'mt-4'">
  <img src="./assets/svgs/valve.svg" />
  <div>{{ 'mixit-premium-dashboard.manual-forced-opening-warning' | translate }}</div>
</gbc-banner>

<gbc-banner type="info" *ngIf="showLimitersBanner$ | async" [class]="'mt-4'">
  <img src="./assets/svgs/limiter-icon.svg" />
  <div>{{ 'mixit-premium-dashboard.balancing-limiters-active' | translate }}</div>
</gbc-banner>

<gbc-banner type="info" *ngIf="trialRunningMessage$ | async" [class]="'mt-4'">
  <div>{{ trialRunningMessage$ | async }}</div>
  <button gbc-button>{{ 'mixit-premium-dashboard.upgrade-now' | translate }}</button>
</gbc-banner>

<gbc-banner type="warning" *ngIf="(installationCommissionState$ | async) === 'Error'" class="mt-8">
  <img src="./assets/svgs/warning.svg" />
  <div [innerHtml]="'mixit-device-states.error-for-device-to-connect' | translate"></div>
</gbc-banner>

<gbc-banner type="info" *ngIf="isLessThanHalfHourSinceClaim$ | async" class="mt-8">
  <div [innerHtml]="'mixit-device-states.waiting-for-device-to-connect' | translate"></div>
</gbc-banner>

<app-schematics-tile
  height="500"
  [applicationId]="(application$ | async)?.id"
  [installationId]="(installation$ | async)?.id"
  [dataPoints$]="dataPoints$"
  [commissionState]="installationCommissionState$ | async"
  [showShadow]="false"
>
</app-schematics-tile>

<div class="tile-grid">
  <app-mixit-tile [installation$]="installation$" [schematic$]="schematic$" [dataPoints$]="dataPoints$" [application$]="application$">
  </app-mixit-tile>

  <app-pump-tile
    *ngIf="showPumpTile$ | async"
    class="large-tile"
    [installation$]="installation$"
    [schematic$]="schematic$"
    [dataPoints$]="dataPoints$"
    [application$]="application$"
  >
  </app-pump-tile>

  <app-mixit-temperature-tile
    [installation$]="installation$"
    [schematic$]="schematic$"
    [dataPoints$]="dataPoints$"
    [application$]="application$"
  >
  </app-mixit-temperature-tile>

  <app-limiters-tile
    *ngIf="isDynamic$ | async"
    [installation$]="installation$"
    [schematic$]="schematic$"
    [dataPoints$]="dataPoints$"
    [application$]="application$"
  ></app-limiters-tile>

  <app-energy-monitoring-tile
    *ngIf="isDynamic$ | async"
    [installation$]="installation$"
    [schematic$]="schematic$"
    [dataPoints$]="dataPoints$"
    [application$]="application$"
  ></app-energy-monitoring-tile>

  <app-heat-coil-protection-tile
    *ngIf="showHeatCoilProtectionTile$ | async"
    [installation$]="installation$"
    [schematic$]="schematic$"
    [dataPoints$]="dataPoints$"
    [application$]="application$"
  ></app-heat-coil-protection-tile>

  <app-mixit-warm-weather-shutdown-tile
    *ngIf="showWeatherCurve$ | async"
    [installation$]="installation$"
    [schematic$]="schematic$"
    [dataPoints$]="dataPoints$"
    [application$]="application$"
  ></app-mixit-warm-weather-shutdown-tile>

  <app-underfloor-protection-tile
    *ngIf="showUnderfloorProtection$ | async"
    [installation$]="installation$"
    [schematic$]="schematic$"
    [dataPoints$]="dataPoints$"
    [application$]="application$"
  ></app-underfloor-protection-tile>
</div>

<ng-container *ngIf="application$ | async as application">
  <app-mixit-weather-curve-tile
    *ngIf="showWeatherCurve$ | async"
    [data$]="weatherCurve$"
    [outdoorTemp$]="outdoorTemp$"
    (saveNewCurve)="saveNewCurve($event)"
  ></app-mixit-weather-curve-tile>

  <app-mixit-temperatures-chart
    [title]="'mixit-premium-dashboard.temperature-trend-data' | translate"
    [dataPoints$]="dataPoints$"
    [applicationId]="application.id"
    [userTrackingPeriodOptions]="{ category: 'mixitPremiumDashboard', intervalPrefix: 'temperatureTrendData' }"
  >
  </app-mixit-temperatures-chart>

  <app-mixit-pump-chart
    [dataPoints$]="dataPoints$"
    [application]="application"
    [userTrackingPeriodOptions]="{ category: 'mixitPremiumDashboard', intervalPrefix: 'pumpTrendData' }"
  >
  </app-mixit-pump-chart>

  <app-mixit-flow-valve-chart
    [title]="'mixit-premium-dashboard.flow-trend-data' | translate"
    [dataPoints$]="dataPoints$"
    [installationId]="(installation$ | async)?.id"
    [applicationId]="application.id"
    [userTrackingPeriodOptions]="{ category: 'mixitPremiumDashboard', intervalPrefix: 'flowTrendData' }"
  >
  </app-mixit-flow-valve-chart>

  <app-mixit-thermal-power-chart
    *ngIf="isDynamic$ | async"
    [dataPoints$]="dataPoints$"
    [installationId]="(installation$ | async)?.id"
    [applicationId]="application.id"
    [userTrackingPeriodOptions]="{ category: 'mixitPremiumDashboard', intervalPrefix: 'thermalPower' }"
  >
  </app-mixit-thermal-power-chart>
</ng-container>

<app-installation-system-status
  (alarmClick)="navigateToAlarm($event)"
  (showAllAlarms)="navigateToAllAlarms()"
></app-installation-system-status>
