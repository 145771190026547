import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MODAL_CONTROLLER, MODAL_DATA, ModalController } from 'shared';
import { UserTrackingHelperService } from '../../../services/user-tracking-helper.service';

export interface MixitSetpointModalInput {
  temperatureSetPoint: number | null;
  max: number;
  min: number;
}

export interface MixitSetpointModalResponse {
  temperatureSetPoint: number;
}

@Component({
  selector: 'app-mixit-setpoint-modal',
  templateUrl: './mixit-setpoint-modal.component.html',
  styleUrls: ['./mixit-setpoint-modal.component.scss'],
})
export class MixitSetpointModalComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  public min: number;
  public max: number;

  public form = new UntypedFormGroup({
    temperatureSetpoint: new UntypedFormControl(''),
  });

  constructor(
    @Inject(MODAL_DATA) public data: MixitSetpointModalInput,
    @Inject(MODAL_CONTROLLER) private controller: ModalController<MixitSetpointModalResponse>,
    private userTrackingHelperService: UserTrackingHelperService
  ) {}

  ngOnInit(): void {
    this.min = this.data.min;
    this.max = this.data.max;

    this.form.controls.temperatureSetpoint.setValidators([Validators.min(this.min), Validators.max(this.max)]);
    this.form.controls.temperatureSetpoint.setValue(this.data.temperatureSetPoint);

    this.subscription.add(
      this.userTrackingHelperService.startFormValueChangeTracking('adjustTemperatureSetpointConfigurationsShown', this.form)
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  close() {
    this.controller.dismiss();
  }

  save() {
    this.controller.complete({ temperatureSetPoint: this.form.controls.temperatureSetpoint.value });
  }

  dismiss() {
    this.userTrackingHelperService.trackUserAction('adjustTemperatureSetpointConfigurationsShown', 'exitClicked');
  }
}
