import { CONNECTION_SPACING } from '../../Common';
import { LabelPosition, SystemMeta } from '../../types';
import { EConnectionType, SystemType } from '../../../state';
import { EConnectionPointPlacement, EEquipment } from '../../../types';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/cascade-tanks.svg';
import cascadeTankSystemForm from '../../../../models/systems/cascadeTankSystem/cascadeTankSystemForm.json';
import { ICascadeTankSystemFormInterface } from '../../../../models/systems/cascadeTankSystem/cascadeTankSystemFormInterface';

export const WIDTH = 450;
export const HEIGHT = 540;

export const FORWARD_Y = 360;
export const RETURN_Y = FORWARD_Y + CONNECTION_SPACING;

export const FORWARD_PIPE_X2 = 90;
export const RETURN_PIPE_X2 = 150;

export const TOP_TANK_Y = 104;
export const TANK_X = 150;
export const BOTTOM_TANK_Y = TOP_TANK_Y + 190;
export const TOP_TANK_SUPPLY_Y = 171;

export const VALVE_X = 120;

export const TOP_TANK_BOTTOM_CONNECTION_Y = 270;
export const BOTTOM_TANK_TOP_CONNECTION_Y = 361;
export const CONNECTOR_START_X = 130;
export const CONNECTOR_END_X = 150;

export const TANK_LEFT = 150;
export const SECONDARY_LOOP_RIGHT = 376;
export const SECONDARY_LOOP_TOP = 50;
export const BOTTOM_TANK_BOTTOM = 470;

export const PUMP_X = 357;
export const PUMP_Y = 101;

export const COLD_SUPPLY_BOTTOM = 500;
export const COLD_SUPPLY_LEFT = 205;
export const COLD_SUPPLY_RIGHT = 330;
export const COLD_SUPPLY_FLOW_X = 275;

export const T_SR_X = 305;
export const T_SR_Y = 105;

export const T_1_TOP_X = 154;
export const T_1_TOP_Y = 140;

export const T_1_CENTER_X = 252;
export const T_1_CENTER_Y = 210;

export const T_1_BOTTOM_X = 252;
export const T_1_BOTTOM_Y = 260;

export const T_2_TOP_X = 252;
export const T_2_TOP_Y = 330;

export const T_2_CENTER_X = 252;
export const T_2_CENTER_Y = 390;

export const T_2_BOTTOM_X = 252;
export const T_2_BOTTOM_Y = 450;

export const cascadeTankMeta: SystemMeta = {
  systemType: SystemType.CascadeTankSystem,
  name: 'Cascade Tank System',
  thumbnail,
  form: cascadeTankSystemForm,
  width: WIDTH,
  height: HEIGHT,
  calculateConnectionPoints: () => [
    {
      key: EConnectionPointPlacement.connectionPrimaryForward,
      x: 0,
      y: FORWARD_Y,
      type: EConnectionType.supply,
      dotted: false,
    },
    {
      key: EConnectionPointPlacement.connectionPrimaryReturn,
      x: 0,
      y: RETURN_Y,
      type: EConnectionType.return,
      dotted: true,
    },
  ],
};

export const getCascadeTankLabels = (systemInfo: ICascadeTankSystemFormInterface): LabelPosition[] => {
  const T_SR: LabelPosition[] = [
    {
      id: EEquipment.T_SR,
      x: 290,
      y: 120,
    },
  ];

  const P_SR: LabelPosition[] = [
    {
      id: EEquipment.P_SR,
      x: 345,
      y: 140,
    },
  ];

  const MV_2W_PR: LabelPosition[] = [
    {
      id: EEquipment.MV_2W_PR,
      x: 110,
      y: 480,
    },
  ];

  const Q_PF: LabelPosition[] =
    systemInfo.HM_present && systemInfo.HM_location === 'PrimaryForward'
      ? [
          {
            id: EEquipment.Q_PF,
            x: 50,
            y: 330,
            horizontalAlignment: 'end',
          },
        ]
      : [];

  const Q_PR: LabelPosition[] =
    systemInfo.HM_present && systemInfo.HM_location === 'PrimaryReturn'
      ? [
          {
            id: EEquipment.Q_PR,
            x: 50,
            y: 485,
            horizontalAlignment: 'end',
          },
        ]
      : [];

  const Q_SC: LabelPosition[] = systemInfo.Q_SC_present
    ? [
        {
          id: EEquipment.Q_SC,
          x: 260,
          y: 530,
        },
      ]
    : [];

  const T_PF: LabelPosition[] =
    systemInfo.HM_present || systemInfo.T_PF_present
      ? [
          {
            id: EEquipment.T_PF,
            x: 85,
            y: 315,
            horizontalAlignment: 'end',
          },
        ]
      : [];

  const T_PR: LabelPosition[] =
    systemInfo.HM_present || systemInfo.T_PR_present
      ? [
          {
            id: EEquipment.T_PR,
            x: 85,
            y: 505,
            horizontalAlignment: 'end',
          },
        ]
      : [];

  const T_1_TOP: LabelPosition[] = [
    {
      id: EEquipment.T_1_TOP,
      x: 105,
      y: 140,
      horizontalAlignment: 'end',
    },
  ];

  const T_1_MIDDLE: LabelPosition[] = systemInfo.T_1_MIDDLE_present
    ? [
        {
          id: EEquipment.T_1_MIDDLE,
          x: 290,
          y: 210,
        },
      ]
    : [];

  const T_1_BOTTOM: LabelPosition[] = systemInfo.T_1_BOTTOM_present
    ? [
        {
          id: EEquipment.T_1_BOTTOM,
          x: 290,
          y: 260,
        },
      ]
    : [];

  const T_2_TOP: LabelPosition[] = systemInfo.T_2_TOP_present
    ? [
        {
          id: EEquipment.T_2_TOP,
          x: 290,
          y: 330,
        },
      ]
    : [];

  const T_2_MIDDLE: LabelPosition[] = systemInfo.T_2_MIDDLE_present
    ? [
        {
          id: EEquipment.T_2_MIDDLE,
          x: 290,
          y: 390,
        },
      ]
    : [];

  const T_2_BOTTOM: LabelPosition[] = systemInfo.T_2_BOTTOM_present
    ? [
        {
          id: EEquipment.T_2_BOTTOM,
          x: 290,
          y: 450,
        },
      ]
    : [];

  return [
    ...T_PR,
    ...T_PF,
    ...T_SR,
    ...P_SR,
    ...MV_2W_PR,
    ...Q_PR,
    ...Q_PF,
    ...Q_SC,
    ...T_1_TOP,
    ...T_1_MIDDLE,
    ...T_1_BOTTOM,
    ...T_2_TOP,
    ...T_2_MIDDLE,
    ...T_2_BOTTOM,
  ];
};
