import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { ModalController, MODAL_CONTROLLER, MODAL_DATA } from 'shared';
import { map } from 'rxjs/operators';
import { EquipmentMetaInfo } from '../../../services/installation-configuration.service';
import { UserTrackingHelperService } from '../../../services/user-tracking-helper.service';

export interface MixitBalancingLimitersResponse {
  supplyFlowLimiterEnabled: boolean;
  supplyFlow: number;
  thermalPowerLimiterEnabled: boolean;
  thermalPower: number;
  differentiatedPressureLimiterEnabled: boolean;
  differentiatedPressure: number;
  returnTemperatureLimiterEnabled: boolean;
  returnTemperature: number;
}

export interface MixitBalancingLimitersSettings {
  supplyFlowLimiterEnabled: boolean;
  supplyFlow: number | null;
  supplyFlowMeta: EquipmentMetaInfo;
  thermalPowerLimiterEnabled: boolean;
  thermalPower: number | null;
  thermalPowerMeta: EquipmentMetaInfo;
  differentiatedPressureLimiterEnabled: boolean;
  differentiatedPressure: number | null;
  differentiatedPressureMeta: EquipmentMetaInfo;
  returnTemperatureLimiterEnabled: boolean;
  returnTemperature: number | null;
  returnTemperatureMeta: EquipmentMetaInfo;
}

@Component({
  selector: 'app-mixit-balancing-limiters-modal',
  templateUrl: './mixit-balancing-limiters-modal.component.html',
  styleUrls: ['./mixit-balancing-limiters-modal.component.scss'],
})
export class MixitBalancingLimitersModalComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  public valid$: Observable<boolean>;
  public form: UntypedFormGroup;
  public supplyFlowMinMax: { min: number; max: number };
  public thermalPowerMinMax: { min: number; max: number };
  public differentiatedPressureMinMax: { min: number; max: number };
  public returnTemperatureMinMax: { min: number; max: number };

  constructor(
    @Inject(MODAL_DATA) public data: MixitBalancingLimitersSettings,
    @Inject(MODAL_CONTROLLER) private controller: ModalController<MixitBalancingLimitersResponse>,
    private userTrackingHelperService: UserTrackingHelperService
  ) {}

  ngOnInit(): void {
    this.form = new UntypedFormGroup({
      supplyFlowLimiterEnabled: new UntypedFormControl(),
      supplyFlow: new UntypedFormControl('', [
        Validators.required,
        Validators.min(this.data.supplyFlowMeta.min),
        Validators.max(this.data.supplyFlowMeta.max),
      ]),
      thermalPowerLimiterEnabled: new UntypedFormControl(),
      thermalPower: new UntypedFormControl('', [
        Validators.required,
        Validators.min(this.data.thermalPowerMeta.min),
        Validators.max(this.data.thermalPowerMeta.max),
      ]),
      differentiatedPressureLimiterEnabled: new UntypedFormControl(),
      differentiatedPressure: new UntypedFormControl('', [
        Validators.required,
        Validators.min(this.data.differentiatedPressureMeta.min),
        Validators.max(this.data.differentiatedPressureMeta.max),
      ]),
      returnTemperatureLimiterEnabled: new UntypedFormControl(),
      returnTemperature: new UntypedFormControl('', [
        Validators.required,
        Validators.min(this.data.returnTemperatureMeta.min),
        Validators.max(this.data.returnTemperatureMeta.max),
      ]),
    });

    this.supplyFlowMinMax = {
      min: this.data.supplyFlowMeta.min,
      max: this.data.supplyFlowMeta.max,
    };

    this.thermalPowerMinMax = {
      min: this.data.thermalPowerMeta.min,
      max: this.data.thermalPowerMeta.max,
    };

    this.differentiatedPressureMinMax = {
      min: this.data.differentiatedPressureMeta.min,
      max: this.data.differentiatedPressureMeta.max,
    };

    this.returnTemperatureMinMax = {
      min: this.data.returnTemperatureMeta.min,
      max: this.data.returnTemperatureMeta.max,
    };

    this.valid$ = this.form.valueChanges.pipe(
      map((form) => {
        let [supplyValid, thermalValid, dfValid, returnTempValid] = [true, true, true, true];

        if (form.supplyFlowLimiterEnabled) {
          supplyValid = this.form.controls.supplyFlow.valid;
        }

        if (form.thermalPowerLimiterEnabled) {
          thermalValid = this.form.controls.thermalPower.valid;
        }

        if (form.differentiatedPressureLimiterEnabled) {
          dfValid = this.form.controls.differentiatedPressure.valid;
        }

        if (form.returnTemperatureLimiterEnabled) {
          returnTempValid = this.form.controls.returnTemperature.valid;
        }

        return supplyValid && thermalValid && dfValid && returnTempValid;
      })
    );

    this.setSupplyFlowLimiterDisabled(this.data.supplyFlowLimiterEnabled);
    this.setThermalPowerLimiterDisabled(this.data.thermalPowerLimiterEnabled);
    this.setDifferentiatedPressureLimiterDisabled(this.data.differentiatedPressureLimiterEnabled);
    this.setReturnTemperatureLimiterDisabled(this.data.returnTemperatureLimiterEnabled);

    this.subscription.add(
      this.form.controls.supplyFlowLimiterEnabled.valueChanges.subscribe((form) => this.setSupplyFlowLimiterDisabled(form))
    );
    this.subscription.add(
      this.form.controls.thermalPowerLimiterEnabled.valueChanges.subscribe((form) => this.setThermalPowerLimiterDisabled(form))
    );
    this.subscription.add(
      this.form.controls.differentiatedPressureLimiterEnabled.valueChanges.subscribe((form) =>
        this.setDifferentiatedPressureLimiterDisabled(form)
      )
    );
    this.subscription.add(
      this.form.controls.returnTemperatureLimiterEnabled.valueChanges.subscribe((form) => this.setReturnTemperatureLimiterDisabled(form))
    );

    setTimeout(() => {
      this.form.setValue({
        supplyFlowLimiterEnabled: this.data.supplyFlowLimiterEnabled,
        supplyFlow: this.data.supplyFlow,
        thermalPowerLimiterEnabled: this.data.thermalPowerLimiterEnabled,
        thermalPower: this.data.thermalPower,
        differentiatedPressureLimiterEnabled: this.data.differentiatedPressureLimiterEnabled,
        differentiatedPressure: this.data.differentiatedPressure,
        returnTemperatureLimiterEnabled: this.data.returnTemperatureLimiterEnabled,
        returnTemperature: this.data.returnTemperature,
      });

      this.subscription.add(this.userTrackingHelperService.startFormValueChangeTracking('adjustBalancingLimitersShown', this.form));
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setSupplyFlowLimiterDisabled(supplyFlowLimiterEnabled: boolean) {
    supplyFlowLimiterEnabled
      ? this.form.controls.supplyFlow.enable({ emitEvent: false })
      : this.form.controls.supplyFlow.disable({ emitEvent: false });
    // Force validation since we used emitEvent=false above, which means validation is not automatically triggered
    this.form.controls.supplyFlow.updateValueAndValidity();
  }

  setThermalPowerLimiterDisabled(thermalPowerLimiterEnabled: boolean) {
    thermalPowerLimiterEnabled
      ? this.form.controls.thermalPower.enable({ emitEvent: false })
      : this.form.controls.thermalPower.disable({ emitEvent: false });
    // Force validation since we used emitEvent=false above, which means validation is not automatically triggered
    this.form.controls.thermalPower.updateValueAndValidity();
  }

  setDifferentiatedPressureLimiterDisabled(differentiatedPressureLimiterEnabled: boolean) {
    differentiatedPressureLimiterEnabled
      ? this.form.controls.differentiatedPressure.enable({ emitEvent: false })
      : this.form.controls.differentiatedPressure.disable({ emitEvent: false });
    // Force validation since we used emitEvent=false above, which means validation is not automatically triggered
    this.form.controls.differentiatedPressure.updateValueAndValidity();
  }

  setReturnTemperatureLimiterDisabled(returnTemperatureLimiterEnabled: boolean) {
    returnTemperatureLimiterEnabled
      ? this.form.controls.returnTemperature.enable({ emitEvent: false })
      : this.form.controls.returnTemperature.disable({ emitEvent: false });
    // Force validation since we used emitEvent=false above, which means validation is not automatically triggered
    this.form.controls.returnTemperature.updateValueAndValidity();
  }

  close() {
    this.controller.dismiss();
  }

  save() {
    this.controller.complete({
      supplyFlowLimiterEnabled: this.form.controls.supplyFlowLimiterEnabled.value,
      supplyFlow: this.form.controls.supplyFlow.value,
      thermalPowerLimiterEnabled: this.form.controls.thermalPowerLimiterEnabled.value,
      thermalPower: this.form.controls.thermalPower.value,
      differentiatedPressureLimiterEnabled: this.form.controls.differentiatedPressureLimiterEnabled.value,
      differentiatedPressure: this.form.controls.differentiatedPressure.value,
      returnTemperatureLimiterEnabled: this.form.controls.returnTemperatureLimiterEnabled.value,
      returnTemperature: this.form.controls.returnTemperature.value,
    });
  }

  dismiss() {
    this.userTrackingHelperService.trackUserAction('adjustBalancingLimitersShown', 'exitClicked');
  }
}
