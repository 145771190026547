import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Options, SeriesOptionsType } from 'highcharts';
import { Observable } from 'rxjs';
import * as Highcharts from 'highcharts';
import { merge } from 'lodash';
import * as moment from 'moment';
import 'moment-timezone';
import { map, shareReplay, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
// required by Highcharts :(
// https://www.highcharts.com/errors/25/
// @ts-ignore
window.moment = moment;

// @ts-ignore
const timezone = moment.tz.guess();

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit {
  @Input() series: Observable<SeriesOptionsType[]>;
  @Input() options: Options;

  public Highcharts = Highcharts;

  options$: Observable<Options>;
  visible = false;

  private baseChartOptions: Options = {
    legend: {
      enabled: true,
      align: 'right',
      verticalAlign: 'top',
      x: 48,
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    chart: {
      spacingTop: 10,
      spacingBottom: 30,
      spacingLeft: 40,
      spacingRight: 80,
      zooming: {
        type: 'x',
      },
    },
    colors: ['#E43535', '#0069C4', '#00A650', '#2D2E44'],
    xAxis: {
      type: 'datetime',
      title: {
        text: this.translateService.instant('valve-chart.time'),
        align: 'high',
        style: {
          color: '#474747',
          fontSize: '14px',
        },
      },
    },
    yAxis: {
      title: {
        align: 'high',
        rotation: 0,
        style: {
          color: '#474747',
          fontSize: '14px',
        },
      },
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
    },
    time: {
      timezone,
    },
    tooltip: {
      shared: true,
      valueDecimals: 2,
      useHTML: true,
      xDateFormat: '%A, %b %d %H:%M',
      headerFormat: '<div><strong>{point.key}</strong></div><table>',
      footerFormat: '</table>',
    },
  };

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    this.options$ = this.series.pipe(
      map((series) => {
        const options = merge({}, this.baseChartOptions, this.options);
        return { ...options, series };
      }),
      // We make the chart visible asynchronously because otherwise highcharts fail to create chart
      // with the correct width.
      tap(() =>
        setTimeout(() => {
          this.visible = true;
        })
      ),
      shareReplay()
    );
  }
}
