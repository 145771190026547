import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AppError, AppErrorService, ModalService, PageInfo } from 'shared';
import { PageInfoService } from 'projects/customerportal/src/app/services/page-info.service';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { IProbeResponse, MixitService } from 'projects/customerportal/src/app/services/mixit-service.service';
import { CdkStepper } from '@angular/cdk/stepper';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FacilityService } from 'projects/customerportal/src/app/services/facility.service';
import { Facility, Installation } from 'projects/customerportal/src/app/interfaces/facilty';
import { debounceTime, first, map, shareReplay, startWith } from 'rxjs/operators';
import { AppModel } from '@ams/dumbledore';
import { FacilityData, MapComponent } from 'projects/customerportal/src/app/components/map/map.component';
import { EFacilityType } from 'projects/serviceportal/src/app/interfaces/facility';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { navigateToInstallation } from '../../utils/navigate-utils';
import { sortBy } from 'lodash';
import { TERMS_AND_CONDITIONS_LINK } from '../../utils/links';
import { UserType } from '../../interfaces/user';
import { GroupType, SocketService } from '../../services/socket.service';
import { UserTrackingHelperService } from '../../services/user-tracking-helper.service';
import { IVerificationResult, ProgressState } from 'projects/shared/src/lib/components/progress-checker/progress-checker.component';
import { formValues } from '../../../../../shared/src/lib/constants/form-values';

type FacilityDropdown = Facility | { name: string; id: string };

const NEW_LOCATION_ID = 'newLocation';

@Component({
  selector: 'app-mixit-onboarding',
  templateUrl: './mixit-onboarding.component.html',
  styleUrls: ['./mixit-onboarding.component.scss'],
})
export class MixitOnboardingComponent implements OnInit, OnDestroy, AfterViewInit {
  public pageInfo: PageInfo;
  public pageError$: Observable<AppError>;
  public locationValid$: Observable<boolean>;

  public preCheckDeviceOnline: Observable<IVerificationResult>;

  private preCheckDeviceOnlineValidSource: BehaviorSubject<IVerificationResult> = new BehaviorSubject<IVerificationResult>({
    progressState: ProgressState.None,
  });
  public preCheckDeviceOnlineValid$ = this.preCheckDeviceOnlineValidSource.asObservable();
  private preCheckSupportedSystemValidSource: BehaviorSubject<IVerificationResult> = new BehaviorSubject<IVerificationResult>({
    progressState: ProgressState.None,
  });
  public preCheckSupportedSystemValid$ = this.preCheckSupportedSystemValidSource.asObservable();
  private preChecksSubscription: Subscription;
  private probeResult: IProbeResponse | null;

  public preChecksValid$: Observable<boolean>;
  public facilities$: Observable<FacilityDropdown[]>;
  public showCreateNewFacility$: Observable<boolean>;
  public showExistingFacility$: Observable<boolean>;
  public newLocationfacilityList$: Observable<FacilityData>;
  public existingLocationfacilityList$: Observable<FacilityData>;

  public mapsUrl$: Observable<string>;

  public TERMS_AND_CONDITIONS_LINK = TERMS_AND_CONDITIONS_LINK;

  // Properties that are to be shown, but not edited by the user
  public type: string;
  public application: string;
  public commissioningTime = '';
  public productSerial: string;

  latitude: number;
  longitude: number;

  public schematic$: BehaviorSubject<AppModel | null> = new BehaviorSubject<AppModel | null>(null);

  @ViewChild('stepper') stepper: CdkStepper;
  @ViewChild('newLocationMap') newLocationMap: MapComponent;
  @ViewChild('existingLocationMap') existingLocationMap: MapComponent;
  @ViewChild('newLocationSummaryMap') newLocationSummaryMap: MapComponent;
  @ViewChild('existingLocationSummaryMap') existingLocationSummaryMap: MapComponent;

  public applicationTypeTranslationMap: { [k: string]: string } = {
    MIXING_LOOP_APPLICATION_TYPE_RADIATOR_HEATING: 'mixit-onboarding.application-type-radiator-heating',
    MIXING_LOOP_APPLICATION_TYPE_FLOOR_HEATING: 'mixit-onboarding.application-type-floor-heating',
    MIXING_LOOP_APPLICATION_TYPE_HEATING_COIL: 'mixit-onboarding.application-type-heating-coil',
    MIXING_LOOP_APPLICATION_TYPE_COOLING_COIL: 'mixit-onboarding.application-type-cooling-coil',
    MIXING_LOOP_APPLICATION_TYPE_NONE: 'mixit-onboarding.application-type-none',
  };

  public newLocationFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, Validators.maxLength(formValues.max_length.name)]),
    road: new UntypedFormControl('', [Validators.required, Validators.maxLength(formValues.max_length.address)]),
    postalCode: new UntypedFormControl('', [Validators.required, Validators.maxLength(formValues.max_length.postalCode)]),
    city: new UntypedFormControl('', [Validators.required, Validators.maxLength(formValues.max_length.address)]),
    latLong: new UntypedFormControl(undefined, [
      Validators.required,
      Validators.pattern(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/),
    ]),
  });

  public form = new UntypedFormGroup({
    claimCode: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(12),
      Validators.maxLength(12),
      Validators.pattern(/^[A-Z2-7]+$/),
    ]),
    existingLocation: new UntypedFormControl(null, Validators.required),
    newLocation: this.newLocationFormGroup,
    name: new UntypedFormControl('', [Validators.required, Validators.minLength(2)]),
    termsConditions: new UntypedFormControl(false, [Validators.requiredTrue]),
  });

  constructor(
    private pageInfoService: PageInfoService,
    private appErrorService: AppErrorService,
    private mixitService: MixitService,
    private translate: TranslateService,
    private facilityService: FacilityService,
    private modalService: ModalService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private socketService: SocketService,
    private userTrackingHelperService: UserTrackingHelperService
  ) {}

  ngOnInit() {
    this.pageInfo = this.pageInfoService.mixitOnboardingComponent();
    this.pageError$ = this.appErrorService.createPageErrorObservable([this.userService.currentUser$.pipe(first())]);

    this.facilities$ = this.facilityService.facilities$.pipe(
      map((facilities) => {
        if (!facilities) {
          facilities = [];
        }
        const newLocationOption = {
          id: NEW_LOCATION_ID,
          name: this.translate.instant('mixit-onboarding.new-facility'),
        };
        return [newLocationOption, ...facilities.filter((facility) => facility.facilityType === EFacilityType.MIXIT)];
      })
    );

    this.showCreateNewFacility$ = this.form.controls.existingLocation.valueChanges.pipe(
      map((existingLocation) => {
        return existingLocation && existingLocation.id === NEW_LOCATION_ID;
      }),
      shareReplay()
    );

    this.showExistingFacility$ = this.showCreateNewFacility$.pipe(
      map((showCreateNew) => {
        return !showCreateNew;
      })
    );

    this.locationValid$ = combineLatest([
      this.form.controls.existingLocation.valueChanges.pipe(startWith(this.form.controls.existingLocation.value)),
      this.form.controls.newLocation.valueChanges.pipe(startWith(this.form.controls.newLocation.value)),
    ]).pipe(
      map(([existingLocation, newLocation]) => {
        if (existingLocation && existingLocation.id === NEW_LOCATION_ID) {
          return this.form.controls.newLocation.valid;
        } else {
          return this.form.controls.existingLocation.valid;
        }
      })
    );

    const nameControl = this.newLocationFormGroup.controls.name;
    const latLongControl = this.newLocationFormGroup.controls.latLong;

    this.newLocationfacilityList$ = combineLatest([
      nameControl.valueChanges.pipe(startWith(nameControl.value)),
      latLongControl.valueChanges.pipe(startWith(latLongControl.value)),
    ]).pipe(
      debounceTime(500),
      map((_: [string, string]) => {
        const latLong = this.form.value.newLocation.latLong;
        const name = this.form.value.newLocation.name;

        if (latLong === null || latLongControl.invalid) {
          return { data: [], trigger: "NewFacility" };
        }

        const [lat, long] = latLong.split(',');
        this.latitude = Number(lat);
        this.longitude = Number(long);

        const newFacility: Partial<Facility> = {
          id: 0,
          name,
          installations: [],
          location: {
            latitude: Number(lat),
            longitude: Number(long),
          },
        };

        return { data: [newFacility as Facility], trigger: "NewFacility" };
      }),
      startWith({ data: [], trigger: "NewFacility" })
    );

    this.existingLocationfacilityList$ = this.form.controls.existingLocation.valueChanges.pipe(
      map((facility) => {
        if (!facility || facility.id === NEW_LOCATION_ID) {
          return { data: [], trigger: "NewLocationId" };
        }

        const partialFacility: Partial<Facility> = {
          id: 0,
          name: facility.name,
          installations: [],
          location: facility.location,
        };

        return { data: [partialFacility as Facility], trigger: "ExistingFacility" };
      }),
      shareReplay()
    );

    this.mapsUrl$ = this.newLocationFormGroup.valueChanges.pipe(
      startWith(this.form.value),
      map(({ road, postalCode, city }) => {
        if (road && postalCode && city) {
          // Replace all spaces with + then remove any duplicate +.
          return `https://www.google.com/maps/place/${road}+${postalCode}+${city}`.split(' ').join('+').replace(/\++/g, '+');
        }
        return 'https://www.google.com/maps';
      })
    );

    this.preChecksValid$ = combineLatest([this.preCheckSupportedSystemValid$, this.preCheckDeviceOnlineValid$]).pipe(
      map(([supportedSystem, deviceOnline]) => supportedSystem.result === true && deviceOnline.result === true)
    );
  }

  ngOnDestroy() {
    this.preChecksSubscription?.unsubscribe();
  }

  ngAfterViewInit() {
    const claimCode = this.route.snapshot.queryParamMap.get('claimCode');
    if (claimCode) {
      this.form.patchValue({ claimCode });
      setTimeout(() => this.stepper?.next());
    }
  }

  stepperChange(step: any) {
    this.resetPreChecks();

    switch (step.selectedIndex) {
      case 0: // Connect new device step
        this.userTrackingHelperService.trackUserAction('confirmDevice', 'backClicked');

        // We only hit this case, if we navigate from the second step, back to the first.
        // In this case we reset everything, so they can start afresh.
        this.form.reset({
          claimCode: this.form.value.claimCode,
        });
        this.probeResult = null;
        break;

      case 1: // Check conditions step
        if (step.previouslySelectedIndex < step.selectedIndex) {
          this.userTrackingHelperService.trackUserAction('claimWizardConnectNewDevice', 'nextClicked');
        } else {
          this.userTrackingHelperService.trackUserAction('claimWizardConfirmDevice', 'backClicked');
        }

        // We're only interested in retrieving the probe for the device, if we are coming from the first step.
        // If we're coming from any screen besides the first screen, then we already have the probe data, and just perform the pre-checks.
        if (step.previouslySelectedIndex !== 0) {
          this.performPreChecks(this.form.value.claimCode);
          return;
        }

        this.mixitService.probeDevice(this.form.value.claimCode).subscribe(
          (response) => {
            this.probeResult = response.body as IProbeResponse;
            if (response.status === 208) {
              this.showAlreadyCommissionedModal().subscribe(() => {
                this.performPreChecks(this.form.value.claimCode);
              });
            } else {
              this.performPreChecks(this.form.value.claimCode);
            }
          },
          (err) => {
            this.stepper.previous();
          }
        );

        break;

      case 2: // Confirm device step
        if (step.previouslySelectedIndex < step.selectedIndex) {
          this.userTrackingHelperService.trackUserAction('claimWizardCheckConditions', 'nextClicked');
        } else {
          this.userTrackingHelperService.trackUserAction('claimWizardDeviceLocation', 'backClicked');
        }

        if (!this.probeResult) {
          // Should never happen. Go to the first page if it does anyway
          this.stepper.selectedIndex = 0;
          return;
        }

        this.populateFormAndSchematic(this.probeResult as IProbeResponse);
        break;

      case 3: // Device location step
        if (step.previouslySelectedIndex < step.selectedIndex) {
          this.userTrackingHelperService.trackUserAction('claimWizardConfirmDevice', 'nextClicked');
        } else {
          this.userTrackingHelperService.trackUserAction('claimWizardSummary', 'backClicked');
        }
        break;

      case 4: // Summary step
        if (step.previouslySelectedIndex < step.selectedIndex) {
          this.userTrackingHelperService.trackUserAction('claimWizardDeviceLocation', 'nextClicked');
        } else {
          this.userTrackingHelperService.trackUserAction('claimWizardSummary', 'backClicked');
        }

        this.newLocationSummaryMap?.resize();
        this.existingLocationSummaryMap?.resize();
        break;
    }
  }

  private populateFormAndSchematic(result: IProbeResponse) {
    // Set schematic and update the view
    this.schematic$.next(result.schematic);
    window.dispatchEvent(new CustomEvent('resize'));

    this.form.patchValue({
      name: result.installationName,
    });

    this.newLocationFormGroup.patchValue({
      name: result.installationName,
      latLong: `${result.latitude}, ${result.longitude}`,
    });

    this.type = result.type;
    this.application = result.application;
    this.commissioningTime = result.commissioningTime;
    this.productSerial = result.productSerialNumber;
  }

  private showAlreadyCommissionedModal(): Observable<any> {
    return this.modalService.showTextModal({
      title: this.translate.instant('mixit-onboarding.device-already-commissioned'),
      content: this.translate.instant('mixit-onboarding.device-already-commissioned-description'),
      actions: [
        {
          text: this.translate.instant('mixit-onboarding.cancel'),
          handler: () => {
            this.stepper.previous();
            this.userTrackingHelperService.trackUserAction('confirmReplaceAlreadyCommissioned', 'cancelClicked');
          },
        },
        {
          text: this.translate.instant('mixit-onboarding.recommission'),
          cancel: true,
          type: 'primary',
          handler: () => {
            this.userTrackingHelperService.trackUserAction('confirmReplaceAlreadyCommissioned', 'recommissionClicked');
          },
        },
      ],
    });
  }

  private performPreChecks(claimCode: string) {
    this.preChecksSubscription?.unsubscribe();
    this.preChecksSubscription = new Subscription();

    this.preCheckSupportedSystemValidSource.next({ progressState: ProgressState.InProgress });
    this.preCheckDeviceOnlineValidSource.next({ progressState: ProgressState.InProgress });

    this.preChecksSubscription.add(
      this.mixitService
        .verifySystemSupported(claimCode)
        .pipe(map((result) => this.preCheckSupportedSystemValidSource.next(result)))
        .subscribe()
    );

    this.preChecksSubscription.add(
      this.mixitService
        .verifyDeviceOnline(claimCode)
        .pipe(map((result) => this.preCheckDeviceOnlineValidSource.next(result)))
        .subscribe()
    );
  }

  private resetPreChecks() {
    this.preChecksSubscription?.unsubscribe();

    this.preCheckSupportedSystemValidSource.next({ progressState: ProgressState.None });
    this.preCheckDeviceOnlineValidSource.next({ progressState: ProgressState.None });
  }

  nextDisabled(selectedStep: number): Observable<boolean> {
    return selectedStep === 1 ? this.preChecksValid$.pipe(map((valid) => !valid)) : of(false);
  }

  done() {
    if (!this.form.value.termsConditions) {
      return;
    }

    this.userTrackingHelperService.trackUserAction('claimWizardSummary', 'nextClicked');

    if (this.form.value.existingLocation.id === NEW_LOCATION_ID) {
      this.claimNewFacility();
    } else {
      this.claimExistingFacility();
    }
  }

  private claimNewFacility() {
    this.mixitService
      .claimDeviceForNewFacility({
        claimCode: this.form.value.claimCode,
        installationName: this.form.value.name,
        facility: {
          name: this.form.value.newLocation.name,
          location: {
            latitude: this.latitude,
            longitude: this.longitude,
          },
          addressRoad: this.form.value.newLocation.road as string,
          addressPostal: this.form.value.newLocation.postalCode as string,
          addressCity: this.form.value.newLocation.city as string,
        },
      })
      .subscribe((createdFacility) => {
        const installation = this.findNewestInstallation(createdFacility);
        this.facilityService.pushOrUpdateFacilityToFacilityList(createdFacility);
        this.navigateToInstallationWhenUserReady(createdFacility, installation);
      });
  }

  private claimExistingFacility() {
    this.mixitService
      .claimDeviceForExistingFacility({
        installationName: this.form.value.name,
        facilityId: this.form.value.existingLocation.id,
        claimCode: this.form.value.claimCode,
      })
      .subscribe((createdFacility) => {
        const installation = this.findNewestInstallation(createdFacility);
        this.facilityService.pushOrUpdateFacilityToFacilityList(createdFacility);
        this.navigateToInstallationWhenUserReady(createdFacility, installation);
      });
  }

  // The user might not be registered in GBC, so we wait until we have a registered user, before we navigate
  // If we don't wait, we risk running into permission issues, as they don't have access to the installation.
  navigateToInstallationWhenUserReady(facility: Facility, installation: Installation) {
    // We need to subscribe to the current user, before we receive a new one below
    this.socketService.subscribeMultiple([], [GroupType.CurrentUser]);

    this.userService.currentUser$
      .pipe(
        first((u) => u?.type === UserType.BuildingConnect) // The userType might be OIDC
      )
      .subscribe((u) => {
        navigateToInstallation(this.router, facility.id, installation.id);
      });
  }

  private findNewestInstallation(facility: Facility): Installation {
    const sorted = sortBy(facility.installations, (i) => i.createdTimestampEpoch);
    return sorted.reverse()[0];
  }
}
