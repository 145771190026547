import { AlarmsWarnings, SystemControlModePayload } from '../actions';
import { SystemType, DistrictHeatingSystemState, BoilerSystemState, HotWaterTankStandAloneSystemState } from '../state';
import { EEquipment, EValueType, IEquipmentValue, ISetPointValue, IValveOpenValue, SystemControlMode } from '../types';
import { SampleEquipmentValue } from './sample-equipment-values';
import { v4 } from 'uuid';

// WILL BE DEPRECATED
export const SAMPLE_HOT_WATER_TANK_STANDALONE_SYSTEM: HotWaterTankStandAloneSystemState = {
  hasId: v4(),
  type: SystemType.HotWaterTankStandAloneSystem,
  left: 640,
  top: 1800,
  configured: false,
  systemInfo: {
    Control_enabled: true,
    HM_present: true,
    HM_location: 'PrimaryForward',
    MV_valveLocation: 'PrimaryForward',
    T_SR_present: true,
    Q_SC_present: true,
    T_MIDDLE_present: true,
    T_BOTTOM_present: true
  }
};

export const HOT_WATER_TANK_STANDALONE_VALUE_SAMPLES: SampleEquipmentValue[] = [
  { systemType: SystemType.HotWaterTankStandAloneSystem, type: EValueType.value, equipment: EEquipment.T_PF, value: '72.9\u2103' },
  { systemType: SystemType.HotWaterTankStandAloneSystem, type: EValueType.value, equipment: EEquipment.T_PR, value: '33.7\u2103' },
  { systemType: SystemType.HotWaterTankStandAloneSystem, type: EValueType.value, equipment: EEquipment.Q_PF, value: '10.3\u33a5/s' },
  { systemType: SystemType.HotWaterTankStandAloneSystem, type: EValueType.value, equipment: EEquipment.Q_PR, value: '10.3\u33a5/s' },
  { systemType: SystemType.HotWaterTankStandAloneSystem, type: EValueType.value, equipment: EEquipment.Q_SC, value: '7.5\u33a5/s' },
  { systemType: SystemType.HotWaterTankStandAloneSystem, type: EValueType.value, equipment: EEquipment.MV_2W_PF, value: '13%' },
  { systemType: SystemType.HotWaterTankStandAloneSystem, type: EValueType.value, equipment: EEquipment.MV_2W_PR, value: '13%' },
  { systemType: SystemType.HotWaterTankStandAloneSystem, type: EValueType.setPoint, equipment: EEquipment.MV_2W_PF, value: '24%' },
  { systemType: SystemType.HotWaterTankStandAloneSystem, type: EValueType.setPoint, equipment: EEquipment.MV_2W_PR, value: '24%' },
  { systemType: SystemType.HotWaterTankStandAloneSystem, type: EValueType.valveIndication, equipment: EEquipment.MV_2W_PF, value: 'opening' },
  { systemType: SystemType.HotWaterTankStandAloneSystem, type: EValueType.valveIndication, equipment: EEquipment.MV_2W_PR, value: 'closing' },
  { systemType: SystemType.HotWaterTankStandAloneSystem, type: EValueType.value, equipment: EEquipment.P_SR, value: '1.7\u33a5/h\n2.3m' },
  { systemType: SystemType.HotWaterTankStandAloneSystem, type: EValueType.value, equipment: EEquipment.T_T, value: '55.2\u2103' },
  { systemType: SystemType.HotWaterTankStandAloneSystem, type: EValueType.setPoint, equipment: EEquipment.T_T, value: '55.2\u2103' },
  { systemType: SystemType.HotWaterTankStandAloneSystem, type: EValueType.value, equipment: EEquipment.T_SR, value: '49.3\u2103' },
  { systemType: SystemType.HotWaterTankStandAloneSystem, type: EValueType.value, equipment: EEquipment.T_MIDDLE, value: '50.4\u2103' },
  { systemType: SystemType.HotWaterTankStandAloneSystem, type: EValueType.value, equipment: EEquipment.T_BOTTOM, value: '51.5\u2103' }
];

export const HOT_WATER_TANK_STANDALONE_ALARM_SAMPLES: AlarmsWarnings[] = [
  //{ type: "Alarm", systemId: SAMPLE_HOT_WATER_TANK_STANDALONE_SYSTEM.hasId, }
];

export const HOT_WATER_TANK_STANDALONE_STATE_SAMPLES: any[] = [
  //{ pasteurization: true, systemId: SAMPLE_HOT_WATER_TANK_STANDALONE_SYSTEM.hasId }
];

export const HOT_WATER_TANK_STANDALONE_SYSTEM_CONTROL_MODE_SAMPLE: SystemControlModePayload[] = [
  //{ systemControlMode: SystemControlMode.Manual, systemId: SAMPLE_HOT_WATER_TANK_STANDALONE_SYSTEM.hasId }
];
