import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { DataPointsResult, DeviceDataPoint } from '../../../interfaces/data-points';
import { Options, SeriesOptionsType } from 'highcharts';
import { TranslateService } from '@ngx-translate/core';
import { first, switchMap, map, shareReplay } from 'rxjs/operators';
import { getDatapoint } from '../../../utils/mixit-utils';
import { MixitDataPointName } from '../../../interfaces/mixit';
import { DataPointsService, ReportPeriod, TimeSerie, Resolution, TimeSeriesDetails } from '../../../services/data-points.service';
import { PeriodOption } from '../../../interfaces/chart';
import moment from 'moment';
import { convertCubicMeterPerSecondToCubicMeterPerHour } from '../../../utils/data-point-utils';
import { UserTrackingPeriodOptions } from '../../../services/user-tracking-helper.service';
import { ChartInterface } from '../chart.interface';
import { Application } from '../../../interfaces/alarm';
import { SystemDeviceType } from '../../../interfaces/systemDeviceType';

@Component({
  selector: 'app-mixit-flow-valve-chart[installationId][applicationId]',
  templateUrl: './mixit-flow-valve-chart.component.html',
  styleUrls: ['./mixit-flow-valve-chart.component.scss'],
})
export class MixitFlowValveChartComponent implements OnInit, ChartInterface {
  @Input() dataPoints$: Observable<DataPointsResult>;
  @Input() title: string;
  @Input() userTrackingPeriodOptions: UserTrackingPeriodOptions;
  @Input() applicationId: string;
  @Input() installationId: string;

  public getSeries: (period: ReportPeriod) => Observable<SeriesOptionsType[]>;
  public dataPointsForChart$: Observable<(DeviceDataPoint | null)[]>;

  public options: Options = {
    colors: ['#CCC82D', '#009EE3'],
    yAxis: [
      {
        title: {
          text: null,
        },
        labels: {
          format: '{value} m3/h',
        },
        min: 0,
      },
      {
        title: {
          text: null,
        },
        labels: {
          format: '{value} %',
        },
        min: 0,
        max: 100,
        opposite: true,
      },
    ],
  };

  public legends$ = combineLatest([
    this.translateService.get('mixit-flow-valve-chart.supply-flow'),
    this.translateService.get('mixit-flow-valve-chart.valve-opening'),
  ]);

  public installationId$: Observable<string>;

  constructor(private translateService: TranslateService, private dataPointsService: DataPointsService) {}

  ngOnInit(): void {
    const supplyDataPoint$ = getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.ValveSupplyFlowAverage).pipe(
      first()
    );
    const valveDataPoint$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.RequestedValveOpeningAverage
    ).pipe(first());

    this.dataPointsForChart$ = combineLatest([supplyDataPoint$, valveDataPoint$]);

    this.getSeries = (period: ReportPeriod) => {
      return combineLatest([this.dataPointsForChart$, this.legends$]).pipe(
        switchMap(([[supplyDataPoint, valveDataPoint], legends]) => {
          const timeSeriesDetails: TimeSeriesDetails = {
            dataPoints: [supplyDataPoint, valveDataPoint],
            period,
            installationId: this.installationId,
            systemId: this.applicationId,
            requestId: this.title.replace(/\W+/g, '_').toLowerCase(),
          };
          return this.dataPointsService.getTimeSeries(timeSeriesDetails).pipe(
            map((seriesData: TimeSerie[]) => {
              return [
                {
                  name: legends[0],
                  data: convertCubicMeterPerSecondToCubicMeterPerHour(seriesData[0]),
                  type: 'line',
                  tooltip: { valueSuffix: ' m3/h' },
                } as SeriesOptionsType,
                { name: legends[1], data: seriesData[1], type: 'line', tooltip: { valueSuffix: ' %' }, yAxis: 1 } as SeriesOptionsType,
              ];
            })
          );
        }),
        shareReplay(1)
      );
    };
  }
}
