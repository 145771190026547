import { Pipe, PipeType } from '../../Pipe';
import React, { FunctionComponent } from 'react';
import { VALVE_X } from './IndirectDistrictHeatingSupplySystem';
import { FORWARD_Y, RETURN_Y } from '../../heat-exchanger-common/heat-exchanger-common-metadata';
import { IIndirectDistrictHeatingSupplySystemFormInterface } from '../../../../models/systems/indirectDistrictHeatingSupplySystem/indirectDistrictHeatingSupplySystemFormInterface';
import { Valve } from '../../Valve';
import { ValveEnergyIcon } from '../../ValveEnergyIcon';
import { Direction } from '../../Common';

type ValveWrapper = {
  isEnergyValve: boolean;
  systemInfo: IIndirectDistrictHeatingSupplySystemFormInterface;
};

export const PrimaryForwardValvePipes: FunctionComponent = () => (
  <>
    <Pipe key="parallelValvePipeLeft" x1={VALVE_X - 20} y1={FORWARD_Y} x2={VALVE_X - 20} y2={FORWARD_Y + 40} type={PipeType.WarmSupply} />
    <Pipe key="parallelValvePipeRight" x1={VALVE_X + 20} y1={FORWARD_Y} x2={VALVE_X + 20} y2={FORWARD_Y + 40} type={PipeType.WarmSupply} />
    <Pipe
      key="parallelValvePipeBottom"
      x1={VALVE_X - 20}
      y1={FORWARD_Y + 40}
      x2={VALVE_X + 20}
      y2={FORWARD_Y + 40}
      type={PipeType.WarmSupply}
    />
  </>
);

export const PrimaryReturnValvePipes: FunctionComponent = () => (
  <>
    <Pipe key="parallelValvePipeLeft" x1={VALVE_X + 20} y1={RETURN_Y} x2={VALVE_X + 20} y2={RETURN_Y - 40} type={PipeType.ColdReturn} />
    <Pipe key="parallelValvePipeRight" x1={VALVE_X - 20} y1={RETURN_Y} x2={VALVE_X - 20} y2={RETURN_Y - 40} type={PipeType.ColdReturn} />
    <Pipe
      key="parallelValvePipeBottom"
      x1={VALVE_X + 20}
      y1={RETURN_Y - 40}
      x2={VALVE_X - 20}
      y2={RETURN_Y - 40}
      type={PipeType.ColdReturn}
    />
  </>
);

export const ValveWrapperComponent: FunctionComponent<ValveWrapper> = ({ isEnergyValve, systemInfo }) => {
  return (
    <>
      {systemInfo.MV_Parallel_Valve_Enabled && systemInfo.MV_valveLocation === 'PrimaryForward' && <PrimaryForwardValvePipes />}
      {systemInfo.MV_Parallel_Valve_Enabled && systemInfo.MV_valveLocation === 'PrimaryReturn' && <PrimaryReturnValvePipes />}
      {systemInfo.MV_valveLocation === 'PrimaryForward' && (
        <>
          {isEnergyValve && <ValveEnergyIcon x={VALVE_X - 25} y={FORWARD_Y - 30} />}
          <Valve key="valve" type="TwoWayValve" direction={Direction.WEST} x={VALVE_X} y={FORWARD_Y} />
          {systemInfo.MV_Parallel_Valve_Enabled && (
            <Valve key="valve2" type="TwoWayValve" direction={Direction.WEST} x={VALVE_X} y={FORWARD_Y + 40} />
          )}
        </>
      )}
      {systemInfo.MV_valveLocation === 'PrimaryReturn' && (
        <>
          {isEnergyValve && <ValveEnergyIcon x={VALVE_X - 25} y={RETURN_Y - 30} />}
          <Valve key="valve" type="TwoWayValve" direction={Direction.WEST} x={VALVE_X} y={RETURN_Y} />;
          {systemInfo.MV_Parallel_Valve_Enabled && (
            <Valve key="valve2" type="TwoWayValve" direction={Direction.WEST} x={VALVE_X} y={RETURN_Y - 40} />
          )}
        </>
      )}
    </>
  );
};
