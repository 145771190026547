import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gbc-weekday-selector',
  templateUrl: './weekday-selector.component.html',
  styleUrls: ['./weekday-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: WeekdaySelectorComponent
    }
  ]
})
export class WeekdaySelectorComponent implements ControlValueAccessor {

  public weekDays = [
    [this.translateService.instant('days.monday-short-format'), 'Monday'],
    [this.translateService.instant('days.tuesday-short-format'), 'Tuesday'],
    [this.translateService.instant('days.wednesday-short-format'), 'Wednesday'],
    [this.translateService.instant('days.thursday-short-format'), 'Thursday'],
    [this.translateService.instant('days.friday-short-format'), 'Friday'],
    [this.translateService.instant('days.saturday-short-format'), 'Saturday'],
    [this.translateService.instant('days.sunday-short-format'), 'Sunday'],
  ];

  private innerValue: string[] = [];

  private onChangeCallback: (val: string[]) => void = () => {};

  get value(): string[] {
    return this.innerValue;
  }

  set value(v: string[]) {
    this.innerValue = v;
    this.onChangeCallback(v);
  }

  constructor(private translateService: TranslateService) { }

  public writeValue(value: any) {
    if (value === this.innerValue) {
      return;
    }

    this.innerValue = value;
  }

  public registerOnChange(fn: (val: string[]) => void) {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any) {
  }

  public setDisabledState(isDisabled: boolean) {}


  public toggleWeekDay(day: string) {
    if (this.value.includes(day)) {
      const index = this.value.findIndex(d => d === day);
      const copy = [...this.value];
      copy.splice(index, 1);
      this.value = [...copy];
    } else {
      this.value = [...this.value, day];
    }
  }
}
