<app-temperature-chart-tile
  *ngIf="dataPoints$ | async"
  [title]="title"
  [colors]="colors"
  [applicationId]="applicationId"
  [chartOptions]="chartOptions"
  [series]="series$ | async"
  [dataPoints$]="dataPoints$"
  [userTrackingPeriodOptions]="userTrackingPeriodOptions"
>
</app-temperature-chart-tile>
