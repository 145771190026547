<gbc-modal [title]="'user-role-explainer.modal-title' | translate">
  <gbc-modal-content>
    <div class="columns">
      <div class="col">
        <header>
          <h2>{{ 'user-role-explainer.facility-manager' | translate }}</h2>
          <p class="lead">{{ 'user-role-explainer.facility-manager-description' | translate }}</p>
        </header>
        <section>
          <p><strong>{{ 'user-role-explainer.onboarding-of-devices' | translate }}</strong></p>
          <p><img src="./assets/svgs/check-outline-green.svg" /> {{ 'user-role-explainer.claim-new-device' | translate }}</p>
        </section>
        <section>
          <p><strong>{{ 'user-role-explainer.alarms' | translate }}</strong></p>
          <p><img src="./assets/svgs/check-outline-green.svg" /> {{ 'user-role-explainer.notification-channels' | translate }}</p>
        </section>
        <section>
          <p><strong>{{ 'user-role-explainer.locations' | translate }}</strong></p>
          <p><img src="./assets/svgs/check-outline-green.svg" /> {{ 'user-role-explainer.edit-existing-locations' | translate }}</p>
        </section>
      </div>
      <div class="col">
        <header>
          <h2>{{ 'user-role-explainer.administrator' | translate }}</h2>
          <p class="lead">{{ 'user-role-explainer.administrator-description' | translate }}</p>
        </header>
        <section>
          <p><strong>{{ 'user-role-explainer.onboarding-of-devices' | translate }}</strong></p>
          <p><img src="./assets/svgs/check-outline-green.svg" /> {{ 'user-role-explainer.claim-new-device' | translate }}</p>
        </section>
        <section>
          <p><strong>{{ 'user-role-explainer.alarms' | translate }}</strong></p>
          <p><img src="./assets/svgs/check-outline-green.svg" /> {{ 'user-role-explainer.notification-channels' | translate }}</p>
        </section>
        <section>
          <p><strong>{{ 'user-role-explainer.locations' | translate }}</strong></p>
          <p><img src="./assets/svgs/check-outline-green.svg" /> {{ 'user-role-explainer.edit-existing-locations' | translate }}</p>
          <p><img src="./assets/svgs/check-outline-green.svg" /> {{ 'user-role-explainer.create-new-locations' | translate }}</p>
        </section>
        <section>
          <p><strong>{{ 'user-role-explainer.user-management' | translate }}</strong></p>
          <p><img src="./assets/svgs/check-outline-green.svg" /> {{ 'user-role-explainer.create-user' | translate }}</p>
          <p><img src="./assets/svgs/check-outline-green.svg" /> {{ 'user-role-explainer.edit-user-roles' | translate }}</p>
          <p><img src="./assets/svgs/check-outline-green.svg" /> {{ 'user-role-explainer.administrate-location-access' | translate }}</p>
        </section>
        <section>
          <p><strong>{{ 'user-role-explainer.managing-upgrades' | translate }}</strong></p>
          <p><img src="./assets/svgs/check-outline-green.svg" /> {{ 'user-role-explainer.upgrade-devices' | translate }}</p>
          <p><img src="./assets/svgs/check-outline-green.svg" /> {{ 'user-role-explainer.cancel-subscription' | translate }}</p>
        </section>
      </div>
    </div>
  </gbc-modal-content>
</gbc-modal>
