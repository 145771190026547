import { AppDispatch } from '../reducer';
import { ConnectionPointIdentifier, SchematicState } from '../state';
import React, { ReactElement, useContext, useMemo, useCallback } from 'react';

export enum InputEventType {
  BackgroundDoubleClick = 'BackgroundDoubleClic',
  ConnectionPointDoubleClick = 'ConnectionPointDoubleClick',
  MouseMove = 'MouseMove',
  MouseUp = 'MouseUp',
  MouseRightClick = 'MouseRightClick',
  ConnectionDoubleClick = 'ConnectionDoubleClick',
  ConnectionPointBackspace = 'ConnectionPointBackspace',
  ConnectionBackspace = 'ConnectionBackspace',
  ConnectionPointMouseDown = 'ConnectionPointMouseDown',
  DropSystem = 'DropSystem',
  Backspace = 'Backspace',
  SystemMouseDown = 'SystemMouseDown',
  SystemClick = 'SystemClick',
  BackgroundClick = 'BackgroundClick',
}

export interface BackgroundDoubleClickEvent {
  type: InputEventType.BackgroundDoubleClick;
  x: number;
  y: number;
}

export interface ConnectionPointDoubleClickEvent {
  type: InputEventType.ConnectionPointDoubleClick;
  connectionPointId: ConnectionPointIdentifier;
  x: number;
  y: number;
}

export interface MouseMoveEvent {
  type: InputEventType.MouseMove;
  x: number;
  y: number;
}

export interface ConnectionDoubleClickEvent {
  type: InputEventType.ConnectionDoubleClick;
  targetId: string;
  x: number;
  y: number;
}

export interface ConnectionPointBackspaceEvent {
  type: InputEventType.ConnectionPointBackspace;
  connectionPointId: ConnectionPointIdentifier;
}

export interface ConnectionBackspaceEvent {
  type: InputEventType.ConnectionBackspace;
  targetId: string;
}

export interface ConnectionPointMouseDownEvent {
  type: InputEventType.ConnectionPointMouseDown;
  connectionPointId: ConnectionPointIdentifier;
}

export interface MouseUpEvent {
  type: InputEventType.MouseUp;
}

export interface MouseRightClickEvent {
  type: InputEventType.MouseRightClick;
  x: number;
  y: number;
}

export interface DropSystemEvent {
  type: InputEventType.DropSystem;
  systemType: string;
  x: number;
  y: number;
}

export interface BackspaceEvent {
  type: InputEventType.Backspace;
}

export interface SystemMouseDownEvent {
  type: InputEventType.SystemMouseDown;
  systemId: string;
  offsetX: number;
  offsetY: number;
}

export interface SystemClickEvent {
  type: InputEventType.SystemClick;
  systemId: string;
}

export interface BackgroundClickEvent {
  type: InputEventType.BackgroundClick;
}

export type InputEvent =
  | BackgroundDoubleClickEvent
  | ConnectionDoubleClickEvent
  | ConnectionPointDoubleClickEvent
  | MouseMoveEvent
  | MouseUpEvent
  | ConnectionBackspaceEvent
  | ConnectionPointBackspaceEvent
  | ConnectionPointMouseDownEvent
  | MouseRightClickEvent
  | DropSystemEvent
  | BackspaceEvent
  | SystemMouseDownEvent
  | SystemClickEvent
  | BackgroundClickEvent;

export interface InputStrategy {
  name: string;
  handleEvent: (dispatch: AppDispatch, state: SchematicState, event: InputEvent) => void;
  render: (state: SchematicState) => ReactElement[];
}

export type DispatchInputEvent = (event: InputEvent) => void;

export class InputStrategyService {
  constructor(private strategies: InputStrategy[], private getState: () => SchematicState, private dispatch: AppDispatch) {}

  public getStrategy(): InputStrategy {
    const inputStrategy = this.strategies.find((strategy) => strategy.name === this.getState().inputStrategyName);
    if (!inputStrategy) {
      throw new Error(`No input strategy registered for ${this.getState().inputStrategyName}`);
    }
    return inputStrategy;
  }

  public dispatchInputEvent(event: InputEvent): void {
    const inputStrategy = this.getStrategy();
    inputStrategy.handleEvent(this.dispatch, this.getState(), event);
  }
}

const DispatchInputEventContext = React.createContext<DispatchInputEvent | null>(() => undefined);
const InputStrategyContext = React.createContext<InputStrategy | null>(null);

export const DispatchInputEventProvider: React.FC<{
  strategies: InputStrategy[];
  getState: () => SchematicState;
  dispatch: AppDispatch;
}> = ({ getState, strategies, dispatch, children }) => {
  const service = useMemo(() => new InputStrategyService(strategies, getState, dispatch), [getState, dispatch]);
  const dispatchEvent: DispatchInputEvent = useCallback(
    (event) => {
      service.dispatchInputEvent(event);
    },
    [service]
  );
  return (
    <DispatchInputEventContext.Provider value={dispatchEvent}>
      <InputStrategyContext.Provider value={service.getStrategy()}>{children}</InputStrategyContext.Provider>
    </DispatchInputEventContext.Provider>
  );
};

export const useInputStrategy = (): InputStrategy => {
  const strategy = useContext(InputStrategyContext);
  if (!strategy) {
    throw new Error('No input strategy provided');
  }
  return strategy;
};

export const useDispatchInputEvent = (): DispatchInputEvent => {
  const dispatchEvent = useContext(DispatchInputEventContext);
  if (!dispatchEvent) {
    throw new Error('No event dispatch available');
  }
  return dispatchEvent;
};
