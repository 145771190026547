import { Component, Input, OnInit } from '@angular/core';
import { DataPointsService, ReportPeriod, TimeSerie, TimeSeriesDetails } from '../../../services/data-points.service';
import { combineLatest, Observable } from 'rxjs';
import { SeriesLineOptions, SeriesOptionsType } from 'highcharts';
import { DataPointsResult, DeviceDataPoint } from '../../../interfaces/data-points';
import { Application } from '../../../interfaces/alarm';
import { TranslateService } from '@ngx-translate/core';
import { filter, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { getDataPoint } from '../../../utils/data-point-utils';
import { EEquipment } from '@ams/dumbledore';
import { ChartInterface } from '../chart.interface';

@Component({
  selector: 'app-pressure-system-chart[installationId][applicationId]',
  templateUrl: './pressure-system-chart.component.html',
  styleUrls: ['./pressure-system-chart.component.scss'],
})
export class PressureSystemChartComponent implements OnInit, ChartInterface {
  @Input() dataPoints$: Observable<DataPointsResult>;
  @Input() applicationId: string;
  @Input() installationId: string;

  public options = {
    yAxis: {
      title: {
        text: 'bar',
      },
      min: 0,
      softMax: 1,
    },
  };
  public visible$: Observable<boolean>;
  public getSeries: (period: ReportPeriod) => Observable<SeriesOptionsType[]>;
  private pressureDataPoints$: Observable<(DeviceDataPoint | null)[]>;
  public legends$ = combineLatest([
    this.translateService.get('pressure-system-chart.pressure'),
    this.translateService.get('pressure-system-chart.differential-pressure'),
  ]);

  constructor(private translateService: TranslateService, private dataPointsService: DataPointsService) {}

  ngOnInit(): void {
    this.pressureDataPoints$ = this.dataPoints$.pipe(
      filter((dataPoints) => !!dataPoints?.data?.length),
      map((dataPoints) => {
        return [
          getDataPoint({
            applicationId: this.applicationId,
            systems: dataPoints?.data || [],
            dumbledoreId: EEquipment.H_F,
            humanReadableId: '',
          }),
          getDataPoint({
            applicationId: this.applicationId,
            systems: dataPoints?.data || [],
            dumbledoreId: EEquipment.H_R,
            humanReadableId: '',
          }),
          getDataPoint({
            applicationId: this.applicationId,
            systems: dataPoints?.data || [],
            dumbledoreId: EEquipment.H_DIFF_P,
            humanReadableId: '',
          }),
        ];
      }),
      first()
    );

    this.visible$ = this.pressureDataPoints$.pipe(map((dataPoints) => dataPoints.some((dataPoint) => dataPoint)));


    this.getSeries = (period: ReportPeriod) => {
      return combineLatest([this.legends$, this.pressureDataPoints$]).pipe(
        switchMap(([legends, dataPoints]) => {
          const timeSeriesDetails: TimeSeriesDetails = {
            dataPoints,
            period,
            installationId: this.installationId,
            systemId: this.applicationId,
            requestId: 'pressure_chart',
          };
          return this.dataPointsService.getTimeSeries(timeSeriesDetails).pipe(
            map((seriesData: TimeSerie[]) => {
              const isDiff = dataPoints.some((d) => d?.dumbledoreId === EEquipment.H_DIFF_P);
              // There will only be one of the three datapoints available, always as the first element of the array
              return [{ name: isDiff ? legends[1] : legends[0], data: seriesData[0], type: 'line' } as SeriesLineOptions].filter(
                (series) => series.data
              );
            }),
            shareReplay(1)
          );
        })
      );
    };
  }
}
