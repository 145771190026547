<div  *ngIf="loading" class="spinner-container" [ngStyle]="{'height': height}">
  <img src="./assets/svgs/loading.svg">
</div>
<div *ngIf="failed" style="text-align: center;">
  {{'tile-spinner.error-message' | translate}}
</div>
<div *ngIf="loaded">
  <ng-content></ng-content>
</div>


