import { v4 } from 'uuid';
import { SystemState } from '../state';
import { snap } from '../components/Common';
import { systemMetadataMap } from '../components/system-metadata';
import { InputEventType, InputStrategy } from './input-strategy-service';
import {
  addSystem,
  confirmDeleteSystem,
  deleteConnection,
  deleteConnectionPoint,
  splitConnection,
  startCreatingConnection,
  startDraggingConnectionPoint,
  startDraggingSystem,
  systemSelect,
  systemUnselect,
} from '../actions';

export const defaultInputStrategy: InputStrategy = {
  name: 'Default',
  handleEvent: (dispatch, state, event) => {
    switch (event.type) {
      case InputEventType.ConnectionPointDoubleClick: {
        dispatch(
          startCreatingConnection({
            from: event.connectionPointId,
            toX: event.x,
            toY: event.y,
          })
        );
        return;
      }
      case InputEventType.ConnectionBackspace: {
        dispatch(
          deleteConnection({
            targetId: event.targetId,
          })
        );
        return;
      }
      case InputEventType.ConnectionDoubleClick: {
        dispatch(
          splitConnection({
            connectionId: event.targetId,
            x: event.x,
            y: event.y,
          })
        );
        return;
      }
      case InputEventType.ConnectionPointBackspace: {
        if (event.connectionPointId.type === 'FREE') {
          dispatch(
            deleteConnectionPoint({
              connectionPointId: event.connectionPointId,
            })
          );
        }
        return;
      }
      case InputEventType.ConnectionPointMouseDown: {
        if (event.connectionPointId.type === 'FREE') {
          dispatch(
            startDraggingConnectionPoint({
              targetId: event.connectionPointId.id,
            })
          );
        }
        return;
      }
      case InputEventType.DropSystem: {
        const systemMeta = systemMetadataMap[event.systemType];
        const systemState = {
          hasId: v4(),
          left: snap(event.x - systemMeta.width / 2),
          top: snap(event.y - systemMeta.height / 2),
          type: event.systemType,
          configured: false,
          systemInfo: {},
        };
        dispatch(
          addSystem({
            system: systemState as SystemState,
          })
        );
        dispatch(systemSelect({ systemId: systemState.hasId }));
        return;
      }
      case InputEventType.Backspace: {
        // We do not want backspace to delete the system when we are using any other control such as an input field.
        if (document.activeElement !== document.body) {
          return;
        }
        const systemId = state.selectedSystemId;
        if (systemId) {
          dispatch(confirmDeleteSystem({ systemId }));
        }
        return;
      }
      case InputEventType.SystemMouseDown: {
        dispatch(
          startDraggingSystem({
            systemId: event.systemId,
            offsetX: event.offsetX,
            offsetY: event.offsetY,
          })
        );
        return;
      }
      case InputEventType.SystemClick: {
        dispatch(systemSelect({ systemId: event.systemId }));
        return;
      }
      case InputEventType.BackgroundClick: {
        dispatch(systemUnselect());
        return;
      }
    }
  },
  render: () => [],
};
