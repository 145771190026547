<app-mixit-x509-banner/>
<app-alarm-bar *ngIf="alarm$ | async" [alarm]="alarm$"> </app-alarm-bar>

<app-schematics-tile [applicationId]="application.id" [showShadow]="false" [dataPoints$]="dataPoints$"></app-schematics-tile>

<app-pump-status-tile
  *ngIf="pumpDevice$ | async"
  [application]="application"
  [installation$]="installation$"
  [pumpType$]="pumpType$"
  [pumpDevice$]="pumpDevice$"
  [pumpOptions]="pumpOptions$ | async"
>
</app-pump-status-tile>

<app-temperature-chart-tile
  [title]="'pump-standalone-dashboard.temperatures' | translate"
  [colors]="temperatureColors"
  [applicationId]="application.id"
  [series]="temperatureSeries$ | async"
  [dataPoints$]="dataPoints$"
  [userTrackingPeriodOptions]="{ category: 'application/pumpStandalone', intervalPrefix: 'temperatures' }"
>
</app-temperature-chart-tile>
