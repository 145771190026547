import { LabelPosition } from '../types';
import { CONNECTION_SPACING } from '../Common';
import { EEquipment } from '../../../schematic/types';
import { SmallLabelDeltas } from '../../util/constants';
import { IBoilerSystemFormInterface } from '../../../models/systems/boilerSystem/boilerSystemFormInterface';
import { IBufferTankSystemFormInterface } from '../../../models/systems/bufferTankSystem/bufferTankSystemFormInterface';
import { IHeatExchangingSystemFormInterface } from '../../../models/systems/heatExchangingSystem/heatExchangingSystemFormInterface';
import { ICommercialHotWaterSystemFormInterface } from '../../../models/systems/commercialHotWaterSystem/commercialHotWaterSystemFormInterface';
import { IDistrictHeatingSupplySystemFormInterface } from '../../../models/systems/districtHeatingSupplySystem/districtHeatingSupplySystemFormInterface';
import { ITwoStrokeHeatExchangerHotWaterSystemFormInterface } from '../../../models/systems/twoStrokeHeatExchangerHotWaterSystem/twoStrokeHeatExchangerHotWaterSystemFormInterface';

export const FLOWMETER_DISTANCE_HORIZONTAL = 45;
export const FLOWMETER_DISTANCE_VERTICAL = 28;

export type HeatMeterSystemInfo =
  | IBoilerSystemFormInterface
  | IDistrictHeatingSupplySystemFormInterface
  | IHeatExchangingSystemFormInterface
  | ICommercialHotWaterSystemFormInterface;

const T_F_POSITION = {
  x: SmallLabelDeltas.TEMP_SENSOR_HORIZONTAL_DX,
  y: SmallLabelDeltas.EAST_Y,
};

const T_R_POSITION = {
  x: SmallLabelDeltas.TEMP_SENSOR_HORIZONTAL_DX,
  y: CONNECTION_SPACING + SmallLabelDeltas.WEST_Y,
};

// Find usages and add temp labels
export const getHorizontalHeatMeterLabels = (x: number, y: number, systemInfo: HeatMeterSystemInfo) => {
  if (!systemInfo.HM_present) {
    return [];
  }
  const supplyFlowLabel: LabelPosition[] =
    systemInfo.HM_location === 'PrimaryForward'
      ? [
          {
            id: EEquipment.Q_PF,
            x: x - FLOWMETER_DISTANCE_HORIZONTAL,
            y: y + SmallLabelDeltas.EAST_Y,
            horizontalAlignment: 'middle',
          },
        ]
      : [];
  const returnFlowLabel: LabelPosition[] =
    systemInfo.HM_location === 'PrimaryReturn'
      ? [
          {
            id: EEquipment.Q_PR,
            x: x - FLOWMETER_DISTANCE_HORIZONTAL,
            y: y + CONNECTION_SPACING + SmallLabelDeltas.WEST_Y,
            horizontalAlignment: 'middle',
          },
        ]
      : [];
  return [...supplyFlowLabel, ...returnFlowLabel];
};

export const getForwardReturnTempLabels = (
  x: number,
  y: number,
  systemInfo: IBoilerSystemFormInterface | IDistrictHeatingSupplySystemFormInterface
): LabelPosition[] => {
  const supplyTempSensor = [
    {
      id: EEquipment.T_F,
      x: x + T_F_POSITION.x,
      y: y + T_F_POSITION.y,
    },
  ];
  const returnTempSensor = [
    {
      id: EEquipment.T_R,
      x: x + T_R_POSITION.x,
      y: y + T_R_POSITION.y,
    },
  ];

  if (systemInfo.HM_present) {
    return [...supplyTempSensor, ...returnTempSensor];
  }
  // Don't show them if the heatmeter isn't there and none of the temp sensors are there
  return [...(systemInfo.T_F_present ? supplyTempSensor : []), ...(systemInfo.T_R_present ? returnTempSensor : [])];
};

export const getPrimaryTempLabels = (
  x: number,
  y: number,
  systemInfo:
    | ICommercialHotWaterSystemFormInterface
    | IHeatExchangingSystemFormInterface
    | ITwoStrokeHeatExchangerHotWaterSystemFormInterface
    | IBufferTankSystemFormInterface,
  ignoreHeatMeter = false
): LabelPosition[] => {
  // We also want to show the temp labels, if we have a heat meter on the system
  let hasHeatMeter = false;
  if (isTypeWithHeatMeter(systemInfo)) {
    hasHeatMeter = !!systemInfo.HM_present;
  }

  if (ignoreHeatMeter) {
    hasHeatMeter = false;
  }

  const supplyTemp =
    systemInfo.T_PF_present || hasHeatMeter
      ? [
          {
            id: EEquipment.T_PF,
            x: x + T_F_POSITION.x,
            y: y + T_F_POSITION.y,
          },
        ]
      : [];
  const returnTemp =
    systemInfo.T_PR_present || hasHeatMeter
      ? [
          {
            id: EEquipment.T_PR,
            x: x + T_R_POSITION.x,
            y: y + T_R_POSITION.y,
          },
        ]
      : [];
  return [...supplyTemp, ...returnTemp];
};


type AllSystems =
  | ICommercialHotWaterSystemFormInterface
  | IHeatExchangingSystemFormInterface
  | ITwoStrokeHeatExchangerHotWaterSystemFormInterface;
type SystemsWithHeatMeter = ICommercialHotWaterSystemFormInterface | IHeatExchangingSystemFormInterface;
type SystemWithPumpTempSensor = IBufferTankSystemFormInterface;

// This is a helper function to determine if the system is a type, that has a heat meter
function isTypeWithHeatMeter(systemInfo: AllSystems): systemInfo is SystemsWithHeatMeter {
  return systemInfo.hasOwnProperty('HM_present');
}

function isTypeWithPumpTemperatureSensor(systemInfo: AllSystems): systemInfo is SystemWithPumpTempSensor {
  return systemInfo.hasOwnProperty('PumpTemperatureSensor');
}
