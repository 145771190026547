import { Component, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, filter, first, flatMap, map, shareReplay } from 'rxjs/operators';
import { AlarmStateType } from '../../interfaces/system-status';
import { PageInfoService } from '../../services/page-info.service';
import { Facility, Installation } from '../../interfaces/facilty';
import { isOverridden } from '../../utils/alarm-utils';
import { AppError, AppErrorService, ModalService, PageInfo } from 'shared';
import { AlarmExtended } from '../../interfaces/alarm';
import { AlarmService } from '../../services/alarm.service';
import { FacilityService } from '../../services/facility.service';
import { ActivatedRoute } from '@angular/router';
import { SchematicsService } from '../../services/schematics.service';
import { AlarmOverrideModalComponent, OverrideModalResult } from '../../components/alarms/alarm-override-modal/alarm-override-modal.component';
import { DataPointsService } from '../../services/data-points.service';
import { DataPointsResult } from '../../interfaces/data-points';


@Component({
  selector: 'app-alarm-page',
  templateUrl: './alarm-page.component.html',
  styleUrls: ['./alarm-page.component.scss'],
})
export class AlarmPageComponent implements OnInit {
  private facility$: Observable<Facility>;
  public pageInfo$: Observable<PageInfo>;
  public alarm$: Observable<AlarmExtended>;
  public overriden$: Observable<boolean>;
  public AlarmStateType = AlarmStateType;
  public installation$: Observable<Installation>;
  public initialAlarm$: Observable<AlarmExtended>;
  public pageError$: Observable<AppError>;
  private alarmId: string;

  constructor(
    private facilityService: FacilityService,
    private errorService: AppErrorService,
    private pageInfoService: PageInfoService,
    private alarmService: AlarmService,
    private schematicService: SchematicsService,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private dataPointsService: DataPointsService,
  ) { }

  ngOnInit() {
    this.alarmId = this.route.snapshot.paramMap.get('alarmId') as string;
    this.facility$ = this.facilityService.getCurrentFacility(this.route);
    this.installation$ = this.facilityService.getCurrentInstallation(this.route).pipe(first());

    const dataPoints$ = combineLatest([this.installation$, this.dataPointsService.dataPoints$]).pipe(
      filter(([_, dataPoints]) => !!dataPoints),
      map(([installation, datapoints]) => {
        return datapoints.find(d => d.installationId === installation.id);
      }),
      first()
    );

    // We only need to load this once
    const facilityApplicationsInstallation$ = combineLatest([this.facility$, this.schematicService.applications$, this.installation$]).pipe(
      first(),
    );

    this.alarm$ = this.alarmService.alarmsRoot$.pipe(
      debounceTime(1000),
      flatMap(_ => {
        return facilityApplicationsInstallation$;
      }),
      flatMap(([facility, applications, installation]) => {
        return this.alarmService.getAlarmDetails(installation, facility.facilityType, this.alarmId, applications, dataPoints$ as Observable<DataPointsResult>);
      }),
      shareReplay(1)
    );

    this.initialAlarm$ = this.alarm$.pipe(first());

    this.pageError$ = this.errorService.createPageErrorObservable([
      this.alarm$.pipe(first()),
    ]);

    this.overriden$ = this.alarm$.pipe(
      map(alarm => isOverridden(alarm))
    );
    this.pageInfo$ = combineLatest([this.initialAlarm$, this.facilityService.getCurrentFacility(this.route), this.installation$, this.schematicService.getFirmwareVersion()]).pipe(
      map(([alarm, facility, installation, firmwareVersion]) =>
        this.pageInfoService.alarm(facility, installation, alarm, firmwareVersion))
    );
  }

  public handleComment(comment: string) {
    this.installation$.subscribe((installation) => {
      this.alarmService.putComment(installation.id, this.alarmId, comment).subscribe();
    });
  }

  public handleOverride(overridden: boolean) {
    this.installation$.subscribe((installation) => {
      if (overridden) {
        this.alarmService.setOverride(installation.id, this.alarmId, false);
      } else {
        this.modalService.openDialog<OverrideModalResult>(AlarmOverrideModalComponent, {}).subscribe(result => {
          if (!result.dismissed) {
            const res = result.result;
            const { comment } = res;
            const trimmedComment = (comment || '').trim();
            this.alarmService.setOverride(installation.id, this.alarmId, true, trimmedComment.length > 0 ? trimmedComment : undefined);
          }
        });
      }
    });
  }
}
