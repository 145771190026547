import { CONNECTION_SPACING } from '../../Common';
import { LabelPosition, SystemMeta } from '../../types';
import { EConnectionType, SystemType } from '../../../state';
import { EConnectionPointPlacement, EEquipment } from '../../../types';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/unspecified-load-vertical.svg';
import unspecifiedLoadSystemForm from '../../../../models/systems/unspecifiedLoadSystem/unspecifiedLoadSystemForm.json';
import { IUnspecifiedLoadSystemFormInterface } from 'projects/dumbledore/src/models/systems/unspecifiedLoadSystem/unspecifiedLoadSystemFormInterface';

// Coordinates
const WIDTH = 300;
const HEIGHT = 260 + CONNECTION_SPACING;
const OFFSET_X = 100;
const LOAD_TOP_X = OFFSET_X - 10;
const LOAD_TOP_Y = 55;
const HEAT_METER_Y = 200;
const TEMP_SENSOR_LABEL_OFFSET = 40;
const FLOW_METER_OFFSET_X = 20;
const FLOW_METER_OFFSET_Y = 28;

// Positions
export const primaryForwardPosition = { x: OFFSET_X, y: HEIGHT - CONNECTION_SPACING };
export const primaryReturnPosition = { x: OFFSET_X + CONNECTION_SPACING, y: HEIGHT };
export const loadPosition = { x: LOAD_TOP_X, y: LOAD_TOP_Y };
export const supplyPipeEndPosition = { x: OFFSET_X, y: 57 };
export const returnPipeEndPosition = { x: OFFSET_X + CONNECTION_SPACING, y: 87 };
export const heatMeterPosition = { x: OFFSET_X, y: HEAT_METER_Y };

export const unspecifiedLoadMetadata: SystemMeta = {
  systemType: SystemType.UnspecifiedLoad,
  name: 'Unspecified Load',
  thumbnail,
  width: WIDTH,
  height: HEIGHT,
  form: unspecifiedLoadSystemForm,
  calculateConnectionPoints: () => [
    { key: EConnectionPointPlacement.connectionPrimaryForward, ...primaryForwardPosition, type: EConnectionType.supply, dotted: false },
    { key: EConnectionPointPlacement.connectionPrimaryReturn, ...primaryReturnPosition, type: EConnectionType.return, dotted: true },
  ],
};

export const getLabels = (systemInfo: IUnspecifiedLoadSystemFormInterface): LabelPosition[] => {
  if (!systemInfo.HM_present && !systemInfo.T_F_present && !systemInfo.T_R_present) return [];

  const supplyFlowPosition = { x: heatMeterPosition.x - FLOW_METER_OFFSET_X, y: heatMeterPosition.y + FLOW_METER_OFFSET_Y };

  const returnFlowPosition = {
    x: heatMeterPosition.x + CONNECTION_SPACING + FLOW_METER_OFFSET_X,
    y: heatMeterPosition.y + FLOW_METER_OFFSET_Y,
  };

  const heatMeterForwardTempPosition = { x: heatMeterPosition.x - TEMP_SENSOR_LABEL_OFFSET, y: heatMeterPosition.y };

  const heatMeterReturnTempPosition = { x: heatMeterPosition.x + CONNECTION_SPACING + TEMP_SENSOR_LABEL_OFFSET, y: heatMeterPosition.y };

  const supplyFlowLabel: LabelPosition[] =
    systemInfo.HM_location === 'PrimaryForward' ? [{ id: EEquipment.Q_PF, ...supplyFlowPosition, horizontalAlignment: 'end' }] : [];

  const returnFlowLabel: LabelPosition[] =
    systemInfo.HM_location === 'PrimaryReturn' ? [{ id: EEquipment.Q_PR, ...returnFlowPosition, horizontalAlignment: 'start' }] : [];

  const supplyTempLabel: LabelPosition[] =
    systemInfo.HM_present || systemInfo.T_F_present
      ? [{ id: EEquipment.T_F, ...heatMeterForwardTempPosition, horizontalAlignment: 'end' }]
      : [];

  const returnTempLabel: LabelPosition[] =
    systemInfo.HM_present || systemInfo.T_R_present
      ? [{ id: EEquipment.T_R, ...heatMeterReturnTempPosition, horizontalAlignment: 'start' }]
      : [];

  return [...supplyTempLabel, ...returnTempLabel, ...supplyFlowLabel, ...returnFlowLabel];
};
