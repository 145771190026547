import React from 'react';
import styled from 'styled-components';
import { AppDispatch } from '../../schematic/reducer';
import { showConfirmDeleteSchematic, showHelp } from '../actions';
import helpSVG from '!svg-url-loader!../../../assets/icons/help.svg';
import copySVG from '!svg-url-loader!../../../assets/icons/copy.svg';
import { translationService } from '../../services/translationService';
import closeSVG from '!svg-url-loader!../../../assets/icons/close.svg';
import deleteSVG from '!svg-url-loader!../../../assets/icons/delete.svg';
import { confirmDeleteSystem, cloneSystem } from '../../schematic/actions';
import fullscreenSVG from '!svg-url-loader!../../../assets/icons/fullscreen.svg';

export interface HeaderProps {
  isFullscreen: boolean;
  onFullscreen: () => void;
  dispatch: AppDispatch;
  selectedSystemId?: string | null;
}

export const StyledHeader = styled.header`
  border-bottom: 1px solid lightgrey;
  background-color: white;

  .header-buttons {
    padding: 4px 0;
    display: flex;
    justify-content: flex-end;
  }

  .button {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    border: solid 1px #e1e1e1;
    background-color: #ffffff;
    padding: 10px 15px;
    margin: 5px 10px;
    font-size: 16px;
    font-weight: 400;
    font-family: 'GrundfosTheSansV2', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button:focus {
    outline: 0;
  }

  .button-icon {
    height: 16px;
    margin-top: -3px;
  }

  .button-text {
    margin-left: 10px;
  }
`;

export const Header: React.FC<HeaderProps> = React.memo(({ isFullscreen, onFullscreen, dispatch, selectedSystemId }) => {
  const exitFullscreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  };
  return (
    <StyledHeader>
      <div className="header-buttons">
        {!selectedSystemId && (
          <button className="button" onClick={() => dispatch(showConfirmDeleteSchematic())}>
            <img className="button-icon" src={deleteSVG} alt="" />
            <span className="button-text">{translationService.getTranslation('deleteSchematic')}</span>
          </button>
        )}

        {selectedSystemId && (
          <React.Fragment>
            <button className="button" onClick={() => dispatch(confirmDeleteSystem({ systemId: selectedSystemId }))}>
              <img className="button-icon" src={deleteSVG} alt="" />
              <span className="button-text">{translationService.getTranslation('deleteSystem')}</span>
            </button>
            <button className="button" onClick={() => dispatch(cloneSystem({ systemId: selectedSystemId }))}>
              <img className="button-icon" src={copySVG} alt="" />
              <span className="button-text">{translationService.getTranslation('cloneSystem')}</span>
            </button>
          </React.Fragment>
        )}

        <button className="button" onClick={() => dispatch(showHelp())}>
          <img className="button-icon" src={helpSVG} alt="" />
          <span className="button-text">{translationService.getTranslation('help')}</span>
        </button>

        {!isFullscreen && (
          <button className="button" onClick={onFullscreen}>
            <img className="button-icon" src={fullscreenSVG} alt="" />
            <span className="button-text">{translationService.getTranslation('fullScreen')}</span>
          </button>
        )}

        {isFullscreen && (
          <button className="button" onClick={exitFullscreen}>
            <img className="button-icon" src={closeSVG} alt="" />
            <span className="button-text">{translationService.getTranslation('closeFullScreen')}</span>
          </button>
        )}
      </div>
    </StyledHeader>
  );
});
