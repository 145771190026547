<gbc-tile [title]="'scheduling-tile.title' | translate" data-test-id="scheduling-tile">
  <button
    gbc-button
    small
    gbc-tile-right
    (click)="openSchedulingModal()"
    [disabled]="hasItems | async"
    angulartics2On="click"
    angularticsAction="createSchedule"
    angularticsCategory="plantroom"
    data-test-id="scheduling-add-button"
  >
    <gbc-icon iconName="plus_outline"></gbc-icon>
    <span>{{ 'scheduling-tile.schedule-session' | translate }}</span>
  </button>

  <gbc-table *ngIf="hasItems | async; else noitems">
    <div class="header">
      <div class="header-items">{{ 'scheduling-tile.operation' | translate }}</div>
      <div class="header-items">{{ 'scheduling-tile.scheduled' | translate }}</div>
    </div>
    <div class="scheduled-event-list">
      <ng-container *ngFor="let event of scheduledEvents$ | async">
        <div class="scheduled-event">
          <ng-container *ngIf="event.eventType === EventTypes.Pasteurization">
            <div class="scheduled-event-info">{{ 'scheduling-tile.session-type.' + event.eventType | translate }}</div>
            <div class="scheduled-event-info">
              <div class="event-icon" data-test-id="pasteurization-event-icon">
                <gbc-icon iconName="warning" pTooltip="{{ 'scheduling-modal.advice-occupants-warning' | translate }}" tooltipPosition="right"></gbc-icon>
              </div>
              <div class="event-content">
                <span class="period">
                  {{ getPeriodText(event) }}
                </span>
                <span class="interval" *ngIf="event.timeIntervals[0]">
                  {{ event.timeIntervals[0].startHoursMinutes | formatHoursMinutes }}
                  -
                  {{ event.timeIntervals[0].startHoursMinutes | formatHoursMinutesWithDuration : event.timeIntervals[0].durationMinutes }}
                </span>
              </div>
              <div class="scheduling-actions">
                <gbc-popup-menu class="scheduling-dropdown" [options]="schedulingMenuOptions" [model]="event"></gbc-popup-menu>
              </div>
            </div>
          </ng-container>
        </div>
        <div class="spacer">
          <div class="spacer-third"></div>
          <div class="spacer-third"></div>
          <div class="spacer-third"></div>
        </div>
      </ng-container>
    </div>
  </gbc-table>
  <ng-template #noitems>
    <div class="no-items-container">
      <div>{{ 'scheduling-tile.no-items' | translate }}</div>
    </div>
  </ng-template>
</gbc-tile>
