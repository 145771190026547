import React from 'react';

import { System, BaseSystemProps } from '../../System';
import { heatExchangerMeta, WIDTH } from './heat-exchanger-metadata';
import { Pipe, PipeType } from '../../Pipe';
import { HeatExchangerCommon } from '../../heat-exchanger-common/HeatExchangerCommon';
import {
  commonHeatExchangerLabels,
  FORWARD_Y,
  RETURN_Y
} from '../../heat-exchanger-common/heat-exchanger-common-metadata';
import { Labels } from '../../Labels';
import { IHeatExchangingSystemFormInterface } from '../../../../models/systems/heatExchangingSystem/heatExchangingSystemFormInterface';

export const HeatExchangerSystem: React.FC<BaseSystemProps<IHeatExchangingSystemFormInterface>> = React.memo(
  ({ state, values, alarm, selected }) => {
    return (
      <System state={state} meta={heatExchangerMeta} alarm={alarm} selected={selected}>
        <Pipe key="forwardPipe" x1={0} y1={FORWARD_Y} x2={WIDTH} y2={FORWARD_Y} type={PipeType.WarmSupply} />
        <Pipe key="returnPipe" x1={0} y1={RETURN_Y} x2={WIDTH} y2={RETURN_Y} type={PipeType.ColdReturn} />
        <HeatExchangerCommon state={state.systemInfo} />
        <Labels positions={[...commonHeatExchangerLabels(state.systemInfo)]} values={values} />
      </System>
    );
  }
);
