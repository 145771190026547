import React from 'react';
import { Connector } from './Connector';
import { colors } from '../../util/colors';
import { CONNECTION_SPACING, pointsToSvgPathD } from './Common';

const DIFF_PRESSURE_TRIANGLE_HEIGHT = 17;
const DIFF_PRESSURE_TRIANGLE_WIDTH = 19;
const DIFF_PRESSURE_CIRCLE_RADIUS = 10;

export interface DifferentialPressureSensorProps {
  x: number;
  y: number;
}

const TRIANGLE_POINTS = [
  [0, 0],
  [DIFF_PRESSURE_TRIANGLE_WIDTH / 2, DIFF_PRESSURE_TRIANGLE_HEIGHT],
  [-DIFF_PRESSURE_TRIANGLE_WIDTH / 2, DIFF_PRESSURE_TRIANGLE_HEIGHT],
];

export const DifferentialpressureSensor: React.FC<DifferentialPressureSensorProps> = React.memo(({ x, y }) => {
  return (
    <g transform={`translate(${x}, ${y})`}>
      <path d={pointsToSvgPathD(TRIANGLE_POINTS, true)} stroke="black" fill={colors.background} />
      <path
        transform={`translate(${0}, ${CONNECTION_SPACING}) rotate(180)`}
        d={pointsToSvgPathD(TRIANGLE_POINTS, true)}
        stroke="black"
        fill={colors.background}
      />
      <Connector
        key="connector"
        points={[
          [0, DIFF_PRESSURE_TRIANGLE_HEIGHT],
          [0, CONNECTION_SPACING - DIFF_PRESSURE_TRIANGLE_HEIGHT],
        ]}
      />
      <circle cy={CONNECTION_SPACING / 2} r={DIFF_PRESSURE_CIRCLE_RADIUS} fill={colors.background} stroke="black" strokeWidth="1.5" />
      <text y={CONNECTION_SPACING / 2 + 1.25} dominantBaseline="middle" textAnchor="middle" fontSize="12">
        {'\u0394P'}
      </text>
    </g>
  );
});
