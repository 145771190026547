<gbc-tile [title]="'limiters-tile.balancing-limiters' | translate">
  <button gbc-icon-button gbc-tile-right
    (click)="adjustLimiters()"
    angulartics2On="click"
    angularticsAction="adjustBalancingLimitersShown"
    angularticsCategory="mixitSchematicClicked">
    <img [alt]="'limiters-tile.adjust-balancing-limiters' | translate" src="./assets/svgs/adjust-outline-black.svg">
  </button>
  <div class="tile-content">

    <div class="tile-row" [ngClass]="{disabled: (supplyFlowLimiterEnabled$ | async)?.value === 'false'}">
      <div class="tile-row-name">{{'limiters-tile.supply-flow' | translate}}:</div>
      <div class="tile-row-value">{{supplyFlowLimiter$ | async | formatDataPoint}}</div>
    </div>

    <div class="tile-row" [ngClass]="{disabled: (returnTemperatureLimiterEnabled$ | async)?.value === 'false'}">
      <div class="tile-row-name">{{'limiters-tile.return-temperature' | translate}}:</div>
      <div class="tile-row-value">{{returnTemperatureLimiter$ | async | formatDataPoint}}</div>
    </div>

    <div class="tile-row" [ngClass]="{disabled: (thermalPowerLimiterEnabled$ | async)?.value === 'false'}">
      <div class="tile-row-name">{{'limiters-tile.thermal-power' | translate}}:</div>
      <div class="tile-row-value">{{thermalPowerLimiter$ | async | formatDataPoint}}</div>
    </div>

    <div class="tile-row" [ngClass]="{disabled: (differentialTemperatureLimiterEnabled$ | async)?.value === 'false'}">
      <div class="tile-row-name">{{'limiters-tile.differential-temperature' | translate}}:</div>
      <div class="tile-row-value">{{differentialTemperatureLimiter$ | async | formatDataPoint}}</div>
    </div>

  </div>
</gbc-tile>
