import { Component, Input, OnInit } from '@angular/core';
import { AlarmOverview, Application } from '../../../interfaces/alarm';
import { getPumpOptions, getPumpType, systemsToPumpDevice, systemsToValveDevice } from '../../../utils/data-point-utils';
import { DataPointsResult, SystemDevice } from '../../../interfaces/data-points';
import { Observable } from 'rxjs';
import { SchematicsService } from '../../../services/schematics.service';
import { AppModel, CommercialHotWaterSystemState } from '@ams/dumbledore';
import { PumpOption } from 'projects/customerportal/src/app/services/installation-configuration.service';
import { Installation } from '../../../interfaces/facilty';
import { map } from "rxjs/operators";
import { systemHasEnergyValve } from 'projects/dumbledore/src/schematic/util/system-properties-util';

@Component({
  selector: 'app-commercial-hot-water-dashboard',
  templateUrl: './commercial-hot-water-dashboard.component.html',
  styleUrls: ['./commercial-hot-water-dashboard.component.scss'],
})
export class CommercialHotWaterDashboardComponent implements OnInit {
  @Input() public alarm$: Observable<AlarmOverview>;
  @Input() public application: Application;
  @Input() public installation$: Observable<Installation>;
  @Input() public schematic$: Observable<AppModel>;
  @Input() public dataPoints$: Observable<DataPointsResult>;

  public pumpDevice$: Observable<SystemDevice | undefined>;
  public pumpType$: Observable<string>;
  public pumpOptions$: Observable<PumpOption[] | undefined>;
  public valveDevice$: Observable<SystemDevice | undefined>;
  public hasEnergyValve$: Observable<boolean | undefined>;
  public hasPrimaryTemperatureSensors$: Observable<boolean | undefined>;
  private system$: Observable<CommercialHotWaterSystemState>;

  constructor(private schematicsService: SchematicsService) {}

  ngOnInit(): void {
    this.system$ = this.schematic$.pipe(
      map((schematic) => {
        return schematic.systems.find((s) => s.hasId === this.application.id) as CommercialHotWaterSystemState;
      })
    );

    this.pumpDevice$ = this.dataPoints$.pipe(systemsToPumpDevice(this.application.id));

    this.pumpType$ = getPumpType(this.schematic$, this.application.id);

    this.pumpOptions$ = getPumpOptions(this.pumpDevice$, this.schematicsService, this.application.id);

    this.valveDevice$ = this.dataPoints$.pipe(systemsToValveDevice(this.application.id));

    this.hasEnergyValve$ = this.system$.pipe(
      map((system) => {
        return systemHasEnergyValve(system?.systemInfo);
      })
    );

    this.hasPrimaryTemperatureSensors$ = this.system$.pipe(
      map((system) => {
        return (system?.systemInfo.T_PF_present || system?.systemInfo.T_PR_present || system?.systemInfo.HM_present);
      })
    );
  }
}
