import React from 'react';
import { SvgImage } from '../SvgImage';
import { Pipe, PipeType } from '../Pipe';
import { CONNECTION_SPACING } from '../Common';
import underfloorIcon from '!raw-loader!../../../../assets/icons/underfloor-heating.svg';

const UNDERFLOOR_SVG_WIDTH = 56;
const UNDERFLOOR_SVG_HEIGHT = 33;
const ICON_LEFT = CONNECTION_SPACING / 2 - UNDERFLOOR_SVG_WIDTH / 2;

export const FORWARD_Y = 1;
export const RETURN_Y = UNDERFLOOR_SVG_HEIGHT - 1;
export const UNDERFLOOR_HEATING_LEFT_PIPE_Y_LEFT = UNDERFLOOR_SVG_HEIGHT - FORWARD_Y;
export const UNDERFLOOR_HEATING_LEFT_PIPE_Y_RIGHT = FORWARD_Y;
export const UNDERFLOOR_HEATING_RIGHT_PIPE_Y_LEFT = UNDERFLOOR_SVG_HEIGHT - RETURN_Y;
export const UNDERFLOOR_HEATING_RIGHT_PIPE_Y_RIGHT = RETURN_Y;

export const Underfloor: React.FC<{ x: number; y: number; reversedWarmCold?: boolean }> = ({ x, y, reversedWarmCold }) => {
  const leftHandPipeY = reversedWarmCold ? UNDERFLOOR_HEATING_LEFT_PIPE_Y_LEFT : UNDERFLOOR_HEATING_LEFT_PIPE_Y_RIGHT;
  const rightHandPipeY = reversedWarmCold ? UNDERFLOOR_HEATING_RIGHT_PIPE_Y_LEFT : UNDERFLOOR_HEATING_RIGHT_PIPE_Y_RIGHT;

  const underFloorHeatingLeft = reversedWarmCold ? ICON_LEFT + UNDERFLOOR_SVG_WIDTH : ICON_LEFT;
  return (
    <g transform={`translate(${x}, ${y}) `}>
      <Pipe
        x1={0}
        y1={leftHandPipeY}
        x2={ICON_LEFT + 4}
        y2={leftHandPipeY}
        type={reversedWarmCold ? PipeType.ColdReturn : PipeType.WarmSupply}
      />
      <Pipe
        x1={CONNECTION_SPACING}
        y1={rightHandPipeY}
        x2={ICON_LEFT + UNDERFLOOR_SVG_WIDTH - 2}
        y2={rightHandPipeY}
        type={reversedWarmCold ? PipeType.WarmSupply : PipeType.ColdReturn}
      />
      <SvgImage svgText={underfloorIcon} transforms={[`scale(${reversedWarmCold ? -1 : 1}, 1)`]} x={underFloorHeatingLeft} y={0} />
    </g>
  );
};
