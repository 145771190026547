import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController, MODAL_CONTROLLER, MODAL_DATA } from 'shared';
import { Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EquipmentMetaInfo } from '../../../services/installation-configuration.service';
import { UserTrackingHelperService } from '../../../services/user-tracking-helper.service';

export interface MixitUnderfloorProtectionResponse {
  enabled: boolean;
  maxTemperature: number;
}

export interface MixitUnderfloorProtectionSettings {
  enabled: boolean;
  maxTemperature: number | null;
  maxTemperatureMeta: EquipmentMetaInfo;
}

@Component({
  selector: 'app-mixit-underfloor-protection-modal',
  templateUrl: './mixit-underfloor-protection-modal.component.html',
  styleUrls: ['./mixit-underfloor-protection-modal.component.scss'],
})
export class MixitUnderfloorProtectionModalComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  public valid$: Observable<boolean>;

  public maxTemperatureMinMax: { min: number; max: number };

  public form: UntypedFormGroup;

  constructor(
    @Inject(MODAL_DATA) public data: MixitUnderfloorProtectionSettings,
    @Inject(MODAL_CONTROLLER) private controller: ModalController<MixitUnderfloorProtectionResponse>,
    private userTrackingHelperService: UserTrackingHelperService
  ) {}

  ngOnInit(): void {
    this.maxTemperatureMinMax = {
      min: this.data.maxTemperatureMeta.min,
      max: this.data.maxTemperatureMeta.max,
    };

    this.form = new UntypedFormGroup({
      enabled: new UntypedFormControl(),
      maxTemperature: new UntypedFormControl('', [
        Validators.required,
        Validators.min(this.data.maxTemperatureMeta.min),
        Validators.max(this.data.maxTemperatureMeta.max),
      ]),
    });

    this.valid$ = this.form.valueChanges.pipe(
      map((form) => {
        if (form.enabled) {
          return this.form.controls.maxTemperature.valid;
        }
        return true;
      })
    );

    this.subscription.add(
      this.form.controls.enabled.valueChanges
        .pipe(
          tap((enabled) => {
            this.toggleEnabled(enabled);
          })
        )
        .subscribe()
    );

    this.toggleEnabled(this.data.enabled);

    setTimeout(() => {
      this.form.setValue({
        enabled: this.data.enabled,
        maxTemperature: this.data.maxTemperature,
      });

      this.subscription.add(this.userTrackingHelperService.startFormValueChangeTracking('adjustUnderfloorProtectionShown', this.form));
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  close() {
    this.controller.dismiss();
  }

  save() {
    this.controller.complete({
      enabled: this.form.controls.enabled.value,
      maxTemperature: this.form.controls.maxTemperature.value,
    });
  }

  private toggleEnabled(enabled: any) {
    if (enabled) {
      this.form.controls.maxTemperature.enable();
    } else {
      this.form.controls.maxTemperature.disable();
    }
  }

  dismiss() {
    this.userTrackingHelperService.trackUserAction('adjustUnderfloorProtectionShown', 'exitClicked');
  }
}
