import { Component, Input, OnInit } from '@angular/core';
import { DataPointsResult, DeviceDataPoint, System, SystemDevice } from '../../../interfaces/data-points';
import { Options, SeriesOptionsType } from 'highcharts';
import { filter, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { EValueType } from '@ams/dumbledore';
import { Application } from '../../../interfaces/alarm';
import { DataPointsService, ReportPeriod, TimeSerie, TimeSeriesDetails } from '../../../services/data-points.service';
import { UserTrackingPeriodOptions } from '../../../services/user-tracking-helper.service';
import { ChartInterface } from '../chart.interface';
import { SystemDeviceType } from '../../../interfaces/systemDeviceType';

// This component is used for both Mixit and BuildingConnect,
// We look for two different data points when showing this graph
@Component({
  selector: 'app-valve-chart[installationId][applicationId]',
  templateUrl: './valve-chart.component.html',
  styleUrls: ['./valve-chart.component.scss'],
})
export class ValveChartComponent implements OnInit, ChartInterface {
  @Input() dataPoints$: Observable<DataPointsResult>;
  @Input() applicationId: string;
  @Input() installationId: string;
  @Input() userTrackingPeriodOptions: UserTrackingPeriodOptions;
  @Input() hasEnergyValve: boolean;

  public valveDataPoint$: Observable<DeviceDataPoint>;
  public installationId$: Observable<string>;
  public getSeries: (period: ReportPeriod) => Observable<SeriesOptionsType[]>;
  public legends$ = this.translateService.get('valve-chart.legend').pipe(map((s) => [s]));
  public options: Options = {
    yAxis: {
      title: {
        text: '%',
      },
      min: 0,
      max: 100,
    },
    plotOptions: {
      series: {
        tooltip: {
          valueDecimals: 1,
        },
      },
    },
  };

  constructor(private translateService: TranslateService, private dataPointsService: DataPointsService) {}

  ngOnInit() {
    const getValveDataPoint = (systems: System[], applicationId: string): DeviceDataPoint => {
      const system = systems.find((s) => s.systemId === applicationId) as System;
      const isMixit = system.devices.some((s) => s.type === SystemDeviceType.MixitSystem);
      const devices = system?.devices || [];
      let valveDevice: SystemDevice | undefined;
      if (isMixit) {
        // Mixit uses a different sensor, that has a different name
        valveDevice = devices.find((d) => d.type === SystemDeviceType.SensorPosition);
      } else {
        valveDevice = devices.find((d) => d.type === SystemDeviceType.PositionSensor || d.type === SystemDeviceType.Actuator);
      }
      return valveDevice?.dataPoints.find((d) => d.valueType === EValueType.value) as DeviceDataPoint;
    };

    this.valveDataPoint$ = this.dataPoints$.pipe(
      filter((dataPoints) => !!dataPoints?.data?.length),
      map((dataPoints) => {
        return getValveDataPoint(dataPoints.data, this.applicationId);
      }),
      first()
    );


    this.getSeries = (period: ReportPeriod) => {
      return combineLatest([this.valveDataPoint$.pipe(first()), this.legends$]).pipe(
        switchMap(([valveDataPoint, legends]) => {
          const timeSeriesDetails: TimeSeriesDetails = {
            dataPoints: [valveDataPoint],
            period,
            installationId: this.installationId,
            systemId: this.applicationId,
            requestId: 'valve_position',
          };
          return this.dataPointsService
            .getTimeSeries(timeSeriesDetails)
            .pipe(map((seriesData: TimeSerie[]) => [{ name: legends[0], data: seriesData[0], type: 'line' } as SeriesOptionsType]));
        }),
        shareReplay(1)
      );
    };
  }

  getTitle(): string {
    return this.hasEnergyValve ? 'valve-chart.energy-valve-position-chart-title' : 'mixing-loop-dashboard.valve-position-chart-title';
  }
}
