import React from 'react';
import produce from 'immer';
import { Panel } from './Panel';
import styled from 'styled-components';
import { IForm, IFormPage, IPanel } from '../../models/form.interface';
import { formService } from '../../services/formService';

export type DataChangeCallback = (newData: any, oldData?: any) => void;

export interface FormProps {
  form: IForm;
  data: any;
  onChange: DataChangeCallback;
}

export const StyledForm = styled.form`
  // make space for dropdowns in form
  padding-bottom: 100px;
`;

export const Form: React.FC<FormProps> = React.memo(({ form, data, onChange }) => {

  const handleChange: DataChangeCallback = (newData: any, oldData?: any) => {

    const dataWithoutHidden = produce(newData, (draftData: any) => {

      // Update data for disabled fields.
      form.pages.forEach((page: IFormPage) => {
        page.elements.forEach((panel: IPanel) => {
          panel.elements.forEach((element) => {
            if (element.disabledValue !== undefined) {
              const newVisible = formService.isElementVisible(element, newData);
              const oldVisible = formService.isElementVisible(element, oldData);

              // Clear field when 'Disabled' field is not longer disabled.
              if (oldVisible === true && newVisible === false) {
                draftData[element.name] = null;
              }

              if (formService.isElementVisible(element, draftData) && formService.isElementDisabled(element)) {
                draftData[element.name] = element.disabledValue;
              }
            }
          });
        });
      });

      formService.clearHiddenQuestionsFromData(form, draftData);
    });

    // Parent callback to update data.
    onChange(dataWithoutHidden, data);
  };

  const dataWithDefaults = { ...formService.getDefaultVariableValues(form), ...data };

  return (
    <StyledForm
      onSubmit={(e) => {
        // Stop the event from bubbling up and causing a reload
        e.preventDefault();
      }}
    >
      {form.pages[0].elements.map((panel) => (
        <Panel key={panel.name} panel={panel} data={dataWithDefaults} onChange={handleChange} />
      ))}
    </StyledForm>
  );
});
