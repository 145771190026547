import React from 'react';
import { colors } from '../../util/colors';
import { EConnectionType } from '../state';

export const CONNECTION_SPACING = 100;

export enum Direction {
  NORTH,
  EAST,
  SOUTH,
  WEST,
}

export const getRotation = (direction: Direction): number => {
  switch (direction) {
    case Direction.NORTH:
      return 0;
    case Direction.EAST:
      return 90;
    case Direction.SOUTH:
      return 180;
    case Direction.WEST:
      return 270;
  }
};

const GRID = 20;
// snap coordinate to a 5 x 5 grid
export const snap = (coord: number): number => {
  let gridPos = Math.floor(coord / GRID);
  if (coord - gridPos * GRID >= GRID / 2) {
    gridPos += 1;
  }
  return gridPos * GRID;
};

export const getAbsoluteCoordinates = (mouseEvent: React.MouseEvent): { x: number; y: number } => {
  const targetElement = mouseEvent.nativeEvent.target as HTMLElement;
  const svgElement = targetElement.closest('svg.dumbledore-schematic-svg') as unknown as HTMLElement;
  if (!svgElement) {
    throw new Error('Failed to get coordinates for mouse event');
  }
  return getRelativeCoordinates(mouseEvent, svgElement);
};

export const getRelativeCoordinates = (
  mouseEvent: React.MouseEvent,
  element: HTMLElement | SVGImageElement | SVGGElement
): { x: number; y: number } => {
  const elementBoundingRect = element.getBoundingClientRect();
  if (!elementBoundingRect) {
    throw new Error('Failed to get coordinates for mouse event');
  }
  return {
    x: mouseEvent.clientX - elementBoundingRect.left,
    y: mouseEvent.clientY - elementBoundingRect.top,
  };
};

export const svgConnectionLine = (key: string, x1: number, y1: number, x2: number, y2: number, type: EConnectionType) => {
  const color = colors.connections[type].regular;
  const strokeDash = type === EConnectionType.return ? '8 4' : undefined;
  return <line key={key} x1={x1} y1={y1} x2={x2} y2={y2} stroke={color} strokeWidth="2px" strokeDasharray={strokeDash} />;
};

export const pointsToSvgPathD = (points: number[][], terminate = false): string => {
  const first = `M${points[0][0]} ${points[0][1]}`;
  const remaining = points
    .slice(1)
    .map((point) => `L${point[0]} ${point[1]}`)
    .join(' ');
  return `${first} ${remaining}${terminate ? 'Z' : ''}`;
};

