import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Facility, Installation } from '../interfaces/facilty';
import { environment } from '../../environments/environment';
import { AlarmDetailsResponse, AlarmRoot, AlarmSourceType, AlarmType } from '../interfaces/system-status';
import { AlarmExtended, AlarmMetadata, AlarmOverview, Application } from '../interfaces/alarm';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subscription, throwError } from 'rxjs';
import { catchError, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { DataPointsResult, System } from 'projects/customerportal/src/app/interfaces/data-points';
import { EFacilityType } from '../../../../serviceportal/src/app/interfaces/facility';
import { DataPointsService } from './data-points.service';
import { AppErrorService, AuthService, LanguageService } from 'shared';
import { LicenseType } from '../interfaces/mixit';
import { isLicenseActive } from '../utils/license-utils';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AlarmService {
  public alarmsRoot$: BehaviorSubject<AlarmRoot | null> = new BehaviorSubject<AlarmRoot | null>(null);
  public alarmsRootByInstallation$: BehaviorSubject<Map<string, AlarmRoot>> = new BehaviorSubject<Map<string, AlarmRoot>>(new Map<string, AlarmRoot>());
  public alarms$: Observable<AlarmOverview[]>;
  public mostImportantAlarm$: Observable<AlarmOverview | null>;
  public mostImportantAlarmByInstallation$: Observable<Map<string, Observable<AlarmOverview | null>>>;
  private alarmMetadatasSource: BehaviorSubject<AlarmMetadata[]> = new BehaviorSubject<AlarmMetadata[]>([]);
  public alarmMetadatas$: Observable<AlarmMetadata[]> = this.alarmMetadatasSource.asObservable();

  constructor(
    private httpClient: HttpClient,
    private dataPointsService: DataPointsService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private authService: AuthService,
    private appErrorService: AppErrorService,
  ) {
    // Get the alarm metadata on startup
    combineLatest([this.languageService.currentLanguage, this.authService.authenticated]).pipe(
      switchMap(([language, authenticated]) => {
        if (!authenticated) {
          return of([]);
        }
        return this.httpClient.get<AlarmMetadata[]>(`${environment.serverUrl}/api/alarms/metadata/${language}`).pipe(
          catchError((e) => {
            return of([]);
          })
        );
      }),
      tap(metadata => this.alarmMetadatasSource.next(metadata)),
      shareReplay(1)
    ).subscribe();
  }

  public pushNewAlarm(incomingAlarm: AlarmRoot) {
    this.alarmsRoot$.next(incomingAlarm);

    const alarmsRootByInstallation = this.alarmsRootByInstallation$.value;
    alarmsRootByInstallation.set(incomingAlarm.installationId, incomingAlarm);
    this.alarmsRootByInstallation$.next(alarmsRootByInstallation);
  }

  // This is used to create an observable for alarms for an installation
  public setup(facility$: Observable<Facility>, installations$: Observable<Installation[]>, applications$: Observable<Application[]>) {
    const dataPoints$ = this.dataPointsService.dataPoints$.pipe(
      map((d) => {
        if (d?.length) {
          return d[0];
        }
        return null;
      })
    );

    this.alarms$ = combineLatest([
      this.alarmsRoot$,
      this.alarmMetadatas$,
      dataPoints$,
      facility$.pipe(first()),
      installations$.pipe(first()),
      applications$.pipe(first()),
    ]).pipe(
      map(([alarmRoot, alarmMetadata, dataPoints, facility, installations, applications]) => {
        const installation = installations.find(i => i.id === alarmRoot?.installationId);
        return this.getAlarmOverviewsForAlarmSource(alarmRoot, facility.facilityType, installation, applications, dataPoints?.data || [], alarmMetadata);
      }),
      shareReplay(1)
    );

    this.mostImportantAlarm$ = this.alarms$.pipe(
      map((alarms) => {
        return this.findMostImportantAlarm(alarms) || null;
      })
    );

    const alarmsByInstallation$: Observable<Map<string, AlarmOverview[]>> = combineLatest([
      this.alarmsRootByInstallation$,
      dataPoints$,
      facility$.pipe(first()),
      installations$.pipe(first()),
      applications$.pipe(first()),
    ]).pipe(
      map(([alarmRootByInstallation, dataPoints, facility, installations, applications]) => {
        const result = new Map<string, AlarmOverview[]>();
        alarmRootByInstallation.forEach((value, key) => {
          const installation = installations.find(i => i.id === key) as Installation;
          result.set(key, this.getAlarmOverviewsForAlarmSource(value, facility.facilityType, installation, applications, dataPoints?.data || [], this.alarmMetadatasSource.value));
        });
        return result;
      }),
      shareReplay(1)
    );

    this.mostImportantAlarmByInstallation$ = alarmsByInstallation$.pipe(
      map((alarms) => {
        const result = new Map<string, Observable<AlarmOverview | null>>();
        alarms.forEach((value, key) => {
          const alarm: Observable<AlarmOverview | null> = of(this.findMostImportantAlarm(value) || null);
          result.set(key, alarm);
        });
        return result;
      })
    );
  }

  private getAlarmsForApplication(application: Application): Observable<AlarmOverview[]> {
    return this.alarms$.pipe(
      map((alarms) => {
        return alarms.filter((a) => a.application?.id === application.id);
      })
    );
  }

  public getMostImportantAlarmForApplication(application: Application): Observable<AlarmOverview | null> {
    return this.getAlarmsForApplication(application)
      .pipe(
        map((alarms) => this.findMostImportantAlarm(alarms) || null)
      );
  }

  private getMetadataForAlarm(
    facilityType: EFacilityType,
    errorCode: string,
    message: string,
    subSourceId: string,
  ): Observable<AlarmMetadata> {
    return this.alarmMetadatas$.pipe(
      map((alarmMetadatas) => {
        return this.getMetadataForAlarmSync(alarmMetadatas, facilityType, errorCode, message, subSourceId);
      })
    );
  }

  private getMetadataForAlarmSync(alarmMetadatas: AlarmMetadata[], facilityType: EFacilityType, errorCode: string, message: string, subSourceId: string): AlarmMetadata {
    // The alarm metadata has a product name, so we need to determine which one is relevant for this installation
    let productName = (facilityType === EFacilityType.MIXIT) ? 'MIXIT' : 'BuildingConnect';

    // If we have an alarm for a pump on a mixit system (seems like we ignore ConnectingCore).
    if (subSourceId) {
      productName = subSourceId;
    }

    // Error code format: MainErrorCode-SubErrorCode
    const errorCodes = errorCode.split('-');
    const mainErrorCode = errorCodes[0];
    const subErrorCode = (errorCodes.length > 1) ? errorCodes[1] : '0';   // Backend will always return '0' for no sub error codes.

    const metadata = alarmMetadatas.find(
      (met: AlarmMetadata) => met.mainErrorCode === mainErrorCode && met.subErrorCode === subErrorCode && met.productName === productName.toLowerCase()
    );

    if (metadata) {
      return metadata;
    }

    // ProductName lookup failed - try to find default.
    const defaultmetadata = alarmMetadatas.find(
      (met: AlarmMetadata) => met.mainErrorCode === mainErrorCode && met.subErrorCode === subErrorCode && met.productName === 'default'
    );

    if (defaultmetadata) {
      return defaultmetadata;
    }

    // Shouldn't happen!? - But did happen, when default mapping did not include subErrorCode.
    return {
      productName,
      mainErrorCode,
      subErrorCode,
      title: message,
      conditions: [],
      causes: [],
    };
  }

  private getAlarmOverviewsForAlarmSource(
    alarmRoot: AlarmRoot | null,
    facilityType: EFacilityType,
    installation: Installation | undefined,
    applications: Application[],
    systems: System[],
    alarmMetadata: AlarmMetadata[]
  ): AlarmOverview[] {
    const result: AlarmOverview[] = [];
    if (!installation) {
      return result;
    }
    for (const alarmSource of alarmRoot?.alarmSources || []) {
      for (const alarm of alarmSource.alarms) {
        if (!alarm.latestActivation) {
          continue;
        }
        if (alarmSource.sourceType === AlarmSourceType.Application || alarmSource.sourceType === AlarmSourceType.Device) {
          const application = this.getApplicationForSource(applications, systems, alarmSource.sourceId, alarmSource.subsourceId, alarmSource.sourceType) as Application;
          const overview: AlarmOverview = {
            id: alarm.id,
            application,
            type: alarm.type,
            currentActivation: alarm.latestActivation,
            metadata: this.getMetadataForAlarmSync(alarmMetadata, facilityType, alarm.errorCode, alarm.message, alarmSource.subsourceId),
            installationName: installation.name as string,
            showDetailedInfo: this.shouldShowDetailedAlarmInfo(facilityType, installation)
          };
          result.push(overview);
        }
        else if (alarmSource.sourceType === AlarmSourceType.Installation) {
          const overview: AlarmOverview = {
            id: alarm.id,
            application: undefined,
            type: alarm.type,
            currentActivation: alarm.latestActivation,
            metadata: this.getMetadataForAlarmSync(alarmMetadata, facilityType, alarm.errorCode, alarm.message, alarmSource.subsourceId),
            installationName: installation.name as string,
            showDetailedInfo: this.shouldShowDetailedAlarmInfo(facilityType, installation)
          };
          result.push(overview);
        }
      }
    }
    return result;
  }

  private shouldShowDetailedAlarmInfo(facilityType: EFacilityType, installation: Installation): boolean {
    // Show detailed alarm info if:
    // 1) facility type is not Mixit
    // or
    // 2) there are active licenses of type pro or trial (it doesn't matter if there is simultaneously an active freemium license)

    if (facilityType !== EFacilityType.MIXIT) {
      return true;
    }
    const activeProAndTrialLicenses = installation?.licenseInformation?.filter(
      li => (li.licenseType === LicenseType.CodeConnectTrial ||
        li.licenseType === LicenseType.CodeConnectUnlimited ||
        li.licenseType === LicenseType.CodeDynamicUnlimited ||
        li.licenseType === LicenseType.CodeConnectUnlimitedPhysical ||
        li.licenseType === LicenseType.CodeDynamicUnlimitedPhysical ||
        li.licenseType === LicenseType.CodeConnectSubscription ||
        li.licenseType === LicenseType.CodeDynamicSubscription) && isLicenseActive(li));
    return activeProAndTrialLicenses.length > 0;
  }

  private alarmDetailsResponseToAlarmExtended(
    response: AlarmDetailsResponse,
    facilityType: EFacilityType,
    installation: Installation,
    applications: Application[],
    systems: System[]
  ): Observable<AlarmExtended> {
    const application = this.getApplicationForSource(applications, systems, response.sourceId, response.subsourceId, response.sourceType) as Application;
    const sortedActivations = response.alarm.alarmActivations.sort((a, b) => b.activatedTimestampEpoch - a.activatedTimestampEpoch);
    return this.getMetadataForAlarm(facilityType, response.alarm.errorCode, response.alarm.message, response.subsourceId).pipe(
      map((metadata) => {
        // the occurred timestamp is the most recent active state that followed a cleared state OR the last one if no
        // cleared state can be found. This is because activation states can also be caused by a disabling of manual override.

        const alarm: AlarmExtended = {
          id: response.alarm.id,
          application,
          type: response.alarm.type,
          currentActivation: response.alarm.alarmActivations[0],
          history: sortedActivations,
          metadata,
          comments: response.alarm.alarmComments,
          installationName: installation.name as string,
          showDetailedInfo: this.shouldShowDetailedAlarmInfo(facilityType, installation)
        };
        return alarm;
      })
    );
  }

  private alarmNotFoundError() {
    return this.appErrorService.createError({
      titleKey: 'page-error-service.not-found',
      messageKey: 'page-error-service.alarm-not-found',
      report: false,
    });
  }

  public getAlarmDetails(
    installation: Installation,
    facilityType: EFacilityType,
    alarmId: string,
    applications: Application[],
    dataPoints$: Observable<DataPointsResult>
  ): Observable<AlarmExtended> {
    const response$ = this.httpClient.get<AlarmDetailsResponse>(
      `${environment.serverUrl}/api/alarms/details/${installation.id}/${alarmId}`
    );
    return combineLatest([response$, dataPoints$]).pipe(
      switchMap(([response, dataPoints]) => {
        return this.alarmDetailsResponseToAlarmExtended(response, facilityType, installation, applications, dataPoints?.data || []);
      }),
      catchError((e) => {
        if (e.status === 400) {
          throw this.alarmNotFoundError();
        }
        return throwError(() => e);
      })
    );
  }

  public putComment(installationId: string, alarmId: string, comment: string): Observable<any> {
    return this.httpClient.put(`${environment.serverUrl}/api/alarms/comment/${installationId}/${alarmId}`, { comment });
  }

  public setOverride(installationId: string, alarmId: string, overridden: boolean, comment?: string): Subscription {
    const setOverrideResult$ = this.httpClient.post(`${environment.serverUrl}/api/alarms/override/${installationId}/${alarmId}`, {
      overridden,
    });
    const ops = [setOverrideResult$];
    if (comment) {
      const putCommentResult = this.putComment(installationId, alarmId, comment);
      ops.push(putCommentResult);
    }
    return forkJoin(ops).subscribe();
  }

  private findMostImportantAlarm(alarms: AlarmOverview[]): AlarmOverview | undefined {
    let mostImportantAlarm: AlarmOverview | undefined;
    const typesToReactOn: AlarmType[] = [AlarmType.Warning, AlarmType.Alarm];
    for (const current of alarms) {
      if (
        typesToReactOn.find((x) => x === current.type) &&
        !current.currentActivation.overriddenTimestampEpoch &&
        !current.currentActivation.clearedTimestampEpoch
      ) {
        if (mostImportantAlarm === undefined) {
          mostImportantAlarm = current;
        } else if (
          current.type < mostImportantAlarm.type ||
          (current.type === mostImportantAlarm.type &&
            current.currentActivation.activatedTimestampEpoch < mostImportantAlarm.currentActivation.activatedTimestampEpoch)
        ) {
          mostImportantAlarm = current;
        }
      }
    }
    return mostImportantAlarm;
  }

  private getApplicationIdForDeviceId(systems: System[], deviceId: string): string | undefined {
    for (const system of systems) {
      for (const device of system.devices) {
        if (device.deviceId === deviceId) {
          return system.systemId;
        }
      }
    }
    return undefined;
  }

  private getApplicationForSource(
    applications: Application[],
    systems: System[],
    sourceId: string,
    subSourceId: string,
    sourceType: AlarmSourceType,
  ): Application | undefined {
    if (sourceType === AlarmSourceType.Application) {
      // We find the appropriate application, either by directly matching its id, or by matching the id of any subsystem the app may contain
      const app = applications.find((a) => a.id === sourceId)
        ?? applications.find((a) => a.subSystems?.find((ss) => ss.id === sourceId));
      return app;
    } else if (sourceType === AlarmSourceType.Device) {
      if (subSourceId) {
        // When we have a sub source, we manually create the application for it
        const application = applications.find((a) => a.id === sourceId);
        return {
          id: application?.id || '',
          title: combineLatest([this.translateService.get(`application-title.${subSourceId}`), application?.title || of(undefined)]).pipe(
            map(([pump, systemName]) => {
              if(systemName)
              {
                return `${pump} - ${systemName}`;
              }
              return `${pump}`;
            })
          ),
          // @ts-ignore
          type: sourceType,
        };
      }
      // The source is a pump, sensor or other. But we don't have those ids from the schematic, so we need to get the datapoints and look for it there
      const applicationId = this.getApplicationIdForDeviceId(systems, sourceId);
      return applications.find((application) => application.id === applicationId);
    } else {
      return undefined;
    }
  }
}
