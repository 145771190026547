<span *ngIf="overridden" class="override-enabled-text">
  {{'manual-override-box.enabled-message' | translate}}
</span>
<span *ngIf="!overridden">
  {{'manual-override-box.disabled-message' | translate}}
</span>
<button gbc-text-button (click)="toggleManualOverride()"
  angulartics2On="click"
  angularticsAction="acknowledgeClicked"
  angularticsCategory="notificationClicked">
  {{overridden ? ('manual-override-box.disable-button' | translate) : ('manual-override-box.enable-button' | translate)}}
</button>
