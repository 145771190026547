import { CONNECTION_SPACING } from '../../Common';
import { FORWARD_Y } from '../boiler/boiler-metadata';
import { LabelPosition, SystemMeta } from '../../types';
import { EConnectionType, SystemType } from '../../../state';
import { EConnectionPointPlacement, EEquipment } from '../../../types';
import { LargeLabelDeltas, SmallLabelDeltas } from '../../../util/constants';
import { IBoilerSystemFormInterface } from '../../../../models/systems/boilerSystem/boilerSystemFormInterface';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/district-supply-heat-exchanger.svg';
import { IDistrictHeatingSupplySystemFormInterface } from '../../../../models/systems/districtHeatingSupplySystem/districtHeatingSupplySystemFormInterface';
import districtHeatingHeatExchangerSystemForm from '../../../../models/systems/indirectDistrictHeatingSupplySystem/indirectDistrictHeatingSupplySystemForm.json';
import { IIndirectDistrictHeatingSupplySystemFormInterface } from '../../../../models/systems/indirectDistrictHeatingSupplySystem/indirectDistrictHeatingSupplySystemFormInterface';
import {
  ICommercialHotWaterSystemFormInterface
} from '../../../../models/systems/commercialHotWaterSystem/commercialHotWaterSystemFormInterface';
import {
  IHeatExchangingSystemFormInterface
} from '../../../../models/systems/heatExchangingSystem/heatExchangingSystemFormInterface';
import {
  SECONDARY_DIFFERENTIAL_PRESSURE_X,
  SECONDARY_TEMP_SENSOR_X
} from '../../heat-exchanger-common/heat-exchanger-common-metadata';
import { getPrimaryTempLabels } from '../../heat-meter-or-sensors/heat-meter-or-sensors-metadata';
import { VALVE_X } from './IndirectDistrictHeatingSupplySystem';

export const HEIGHT = 260;
export const WIDTH = 780;
export const SUPPLY_X = 26;
export const SUPPLY_Y = 100;
export const RETURN_Y = SUPPLY_Y + CONNECTION_SPACING;
export const HEAT_METER_X = 100;
export const PUMP_X = 720;
export const PUMP_SPACING = 35;
export const HEAT_EXCHANGER_X = 265;

export const CONNECTION_POINT_PRIMARY_FORWARD_X = 320;
export const CONNECTION_POINT_FORWARD_Y = SUPPLY_Y - 40;

export const STRAINER_X = 220;
export const STRAINER_Y = SUPPLY_Y - 15;

export const PRIMARY_DIFFERENTIAL_PRESSURE_X = 150;
export const PRIMARY_DIFFERENTIAL_PRESSURE_LABEL_Y = 150;

export const CONNECTION_POINT_PRIMARY_RETURN_X = CONNECTION_POINT_PRIMARY_FORWARD_X + CONNECTION_SPACING;

export const indirectDistrictHeatingSupplyMeta: SystemMeta = {
  systemType: SystemType.IndirectDistrictHeatingSupplySystem,
  name: 'Indirect District Heating Supply',
  thumbnail,
  width: WIDTH,
  height: HEIGHT,
  form: districtHeatingHeatExchangerSystemForm,
  calculateConnectionPoints: (systemInfo: IIndirectDistrictHeatingSupplySystemFormInterface) => [
    ...(systemInfo.Outtake_Before_HeatEx
      ? [
          {
            key: EConnectionPointPlacement.connectionPrimaryForward,
            x: CONNECTION_POINT_PRIMARY_FORWARD_X,
            y: CONNECTION_POINT_FORWARD_Y,
            type: EConnectionType.supply,
            dotted: false,
          },
          {
            key: EConnectionPointPlacement.connectionPrimaryReturn,
            x: CONNECTION_POINT_PRIMARY_RETURN_X,
            y: CONNECTION_POINT_FORWARD_Y,
            type: EConnectionType.return,
            dotted: true,
          },
        ]
      : []),
    // The connection points on the right hand side.
    {
      key: EConnectionPointPlacement.connectionSecondaryForward,
      x: WIDTH,
      y: SUPPLY_Y,
      type: EConnectionType.supply,
      dotted: false,
    },
    {
      key: EConnectionPointPlacement.connectionSecondaryReturn,
      x: WIDTH,
      y: RETURN_Y,
      type: EConnectionType.return,
      dotted: true,
    },
  ],
};

export const labelPositions = (systemInfo: ICommercialHotWaterSystemFormInterface | IHeatExchangingSystemFormInterface): LabelPosition[] => {
  const tsf = [
    {
      id: EEquipment.T_SF,
      x: SECONDARY_TEMP_SENSOR_X + SmallLabelDeltas.TEMP_SENSOR_HORIZONTAL_DX,
      y: FORWARD_Y + SmallLabelDeltas.EAST_Y,
    },
  ];
  const tsr = systemInfo.T_SR_present
    ? [
      {
        id: EEquipment.T_SR,
        x: SECONDARY_TEMP_SENSOR_X + SmallLabelDeltas.TEMP_SENSOR_HORIZONTAL_DX,
        y: RETURN_Y + SmallLabelDeltas.WEST_Y,
      },
    ]
    : [];
  const valveLabel: LabelPosition[] =
    systemInfo.MV_valveLocation === 'PrimaryForward'
      ? [
        {
          id: EEquipment.MV_2W_PF,
          x: VALVE_X + 10,
          y: FORWARD_Y - 42,
          horizontalAlignment: 'middle',
        },
      ]
      : [
        {
          id: EEquipment.MV_2W_PR,
          x: VALVE_X + 10,
          y: RETURN_Y + 30,
          horizontalAlignment: 'middle',
        },
      ];

  const pressureSensorSecondaryLabel: LabelPosition[] = systemInfo.DP_S_present
    ? [
      {
        id: EEquipment.H_DIFF_S,
        x: SECONDARY_DIFFERENTIAL_PRESSURE_X + 15,
        y: (FORWARD_Y + RETURN_Y) / 2,
      },
    ]
    : [];

  return [
    ...getPrimaryTempLabels(VALVE_X + 50, FORWARD_Y, systemInfo, true),
    ...tsf,
    ...tsr,
    ...valveLabel,
    ...pressureSensorSecondaryLabel,
  ];
};

// We also use the common heat exchanger labels for this system, see the React component
export const getIndirectDistrictHeatSupplyLabels = (systemInfo: IIndirectDistrictHeatingSupplySystemFormInterface): LabelPosition[] => {
  const pumpLabelX = PUMP_X + 3;
  const T_F_POSITION = {
    x: SmallLabelDeltas.TEMP_SENSOR_HORIZONTAL_DX,
    y: SmallLabelDeltas.EAST_Y,
  };

  const T_R_POSITION = {
    x: SmallLabelDeltas.TEMP_SENSOR_HORIZONTAL_DX,
    y: CONNECTION_SPACING + SmallLabelDeltas.WEST_Y,
  };

  const forwardReturnTempLabels = (
    x: number,
    y: number,
    systemInfo: IBoilerSystemFormInterface | IDistrictHeatingSupplySystemFormInterface
  ): LabelPosition[] => {
    const supplyTempSensor = [
      {
        id: EEquipment.T_F,
        x: x + T_F_POSITION.x,
        y: y + T_F_POSITION.y,
      },
    ];
    const returnTempSensor = [
      {
        id: EEquipment.T_R,
        x: x + T_R_POSITION.x,
        y: y + T_R_POSITION.y,
      },
    ];

    if (systemInfo.HM_present) {
      return [...supplyTempSensor, ...returnTempSensor];
    }
    // Don't show them if the heatmeter isn't there and none of the temp sensors are there
    return [...(systemInfo.T_F_present ? supplyTempSensor : []), ...(systemInfo.T_R_present ? returnTempSensor : [])];
  };

  const heatMeterLabel: LabelPosition[] =
    systemInfo.HM_location === 'PrimaryForward'
      ? [
          {
            id: EEquipment.Q_PF,
            x: HEAT_METER_X - 65,
            y: SUPPLY_Y + LargeLabelDeltas.EAST_Y,
          },
        ]
      : [
          {
            id: EEquipment.Q_PR,
            x: HEAT_METER_X - 65,
            y: RETURN_Y - LargeLabelDeltas.EAST_Y,
          },
        ];

  const pump1Label: LabelPosition[] =
    systemInfo.P_location === 'SecondaryForward'
      ? [
          {
            id: EEquipment.P_SF,
            x: pumpLabelX,
            y: SUPPLY_Y + PUMP_SPACING,
          },
        ]
      : [
          {
            id: EEquipment.P_SR,
            x: pumpLabelX,
            y: RETURN_Y + PUMP_SPACING,
          },
        ];

  const pump2Label: LabelPosition[] =
    systemInfo.P_location === 'SecondaryForward'
      ? [
          {
            id: EEquipment.P_SF_SLAVE,
            x: pumpLabelX,
            y: SUPPLY_Y - PUMP_SPACING,
          },
        ]
      : [
          {
            id: EEquipment.P_SR_SLAVE,
            x: pumpLabelX,
            y: RETURN_Y - PUMP_SPACING,
          },
        ];

  const strainerLabelForward: LabelPosition[] =
    systemInfo.hasDirtCollector && systemInfo.DP_location === 'PrimaryForward'
      ? [
          {
            id: EEquipment.H_DIFF_PF,
            x: STRAINER_X + 45,
            y: STRAINER_Y - 30,
          },
        ]
      : [];

  const strainerLabelReturn: LabelPosition[] =
    systemInfo.hasDirtCollector && systemInfo.DP_location === 'PrimaryReturn'
      ? [
          {
            id: EEquipment.H_DIFF_PR,
            x: STRAINER_X + 45,
            y: STRAINER_Y + 70,
          },
        ]
      : [];

  const primaryDifferentialPressureSensorLabel: LabelPosition[] = systemInfo.DP_P_present
    ? [
        {
          id: EEquipment.H_DIFF_P,
          x: PRIMARY_DIFFERENTIAL_PRESSURE_X + 15,
          y: PRIMARY_DIFFERENTIAL_PRESSURE_LABEL_Y,
        },
      ]
    : [];

  return [
    ...forwardReturnTempLabels(HEAT_METER_X, FORWARD_Y, systemInfo),
    ...heatMeterLabel,
    ...pump1Label,
    ...pump2Label,
    ...strainerLabelForward,
    ...strainerLabelReturn,
    ...primaryDifferentialPressureSensorLabel,
  ];
};
