<div class="menu" cdkOverlayOrigin #trigger="cdkOverlayOrigin" #popupMenu>
  <button class="menu-button" gbc-text-button (click)="toggleMenu()" data-test-id="popup-menu-button">
    <img alt="More" src="./assets/svgs/menu-more.svg" />
  </button>
  <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="showMenu" (detach)="toggleMenu(false)" (overlayOutsideClick)="toggleMenu(false, $event)">
    <div [ngClass]="{ 'invisible': makeInvisible }" [class]="'menu-wrapper position-' + positionX + ' position-' + positionY" data-test-id="gbc-popup-menu-wrapper" #popupMenuItems>
      <ul class="menu-buttons">
        <li *ngFor="let option of menuOptions$ | async" [ngClass]="{ 'disabled': option.disabled }" (click)="optionClicked(option)" angulartics2On="click" attr.angularticsCategory="{{ option.analyticsCategory }}" attr.angularticsAction="{{ option.analyticsAction }}" [attr.data-test-id]="option.testId">
          <span *ngIf="option.icon !== undefined" class="icon">
            <gbc-icon [iconName]="option.icon"></gbc-icon>
          </span>
          <span class="title">{{ option.title | translate }}</span>
        </li>
      </ul>
    </div>
  </ng-template>
</div>
