import { Component, Input, OnInit } from '@angular/core';
import { DataPointSerie, DeltaTSerie, Serie, SerieType } from '../temperature-chart-tile/temperature-chart-tile.component';
import { combineLatest, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { DataPointsResult } from '../../../interfaces/data-points';
import { EEquipment } from '@ams/dumbledore';
import { UserTrackingPeriodOptions } from '../../../services/user-tracking-helper.service';
import {ChartInterface} from "../chart.interface";

@Component({
  selector: 'app-forward-return-chart-tile[applicationId]',
  templateUrl: './forward-return-chart-tile.component.html',
  styleUrls: ['./forward-return-chart-tile.component.scss'],
})
export class ForwardReturnChartTileComponent implements OnInit {
  @Input() title: string;
  @Input() applicationId: string;
  @Input() forwardDumbledoreId: string;
  @Input() returnDumbledoreId: string;
  @Input() dataPoints$: Observable<DataPointsResult>;
  @Input() userTrackingPeriodOptions: UserTrackingPeriodOptions;

  public series$: Observable<Serie[]>;

  constructor(private translateService: TranslateService) {}

  ngOnInit(): void {
    const forwardLegend$ = this.translateService.get('forward-return-chart-tile.flow-legend');
    const returnLegend$ = this.translateService.get('forward-return-chart-tile.return-legend');
    this.series$ = combineLatest([forwardLegend$, returnLegend$]).pipe(
      map(([forwardLegend, returnLegend]: [string, string]) => {
        const series: (DeltaTSerie | DataPointSerie)[] = [];
        if (this.forwardDumbledoreId) {
          series.push({
            color: '#E43535',
            type: SerieType.DataPoint,
            title: forwardLegend,
            dumbledoreId: this.forwardDumbledoreId as EEquipment,
          });
        }
        if (this.returnDumbledoreId) {
          series.push({
            color: '#0069C4',
            type: SerieType.DataPoint,
            title: returnLegend,
            dumbledoreId: this.returnDumbledoreId as EEquipment,
          });
        }
        if (this.forwardDumbledoreId && this.returnDumbledoreId) {
          series.push({
            type: SerieType.DeltaT,
            forwardDumbledoreId: this.forwardDumbledoreId,
            returnDumbledoreId: this.returnDumbledoreId,
          });
        }
        return series;
      })
    );
  }
}
