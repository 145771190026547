import React from 'react';
import { Labels } from '../../Labels';
import { Direction } from '../../Common';
import { PumpIcon } from '../../PumpIcon';
import { Pipe, PipeType } from '../../Pipe';
import { System, BaseSystemProps } from '../../System';
import { getColdWaterPumpLabels, coldWaterPumpMeta, PIPE_Y, WIDTH } from './cold-water-pump-metadata';
import { IColdWaterPumpSystemFormInterface } from '../../../../models/systems/coldWaterPumpSystem/coldWaterPumpSystemFormInterface';

export const ColdWaterPump: React.FC<BaseSystemProps<IColdWaterPumpSystemFormInterface>> = React.memo(
  ({ state, values, alarm, selected }) => {
    return (
      <System state={state} meta={coldWaterPumpMeta} alarm={alarm} selected={selected}>
        <Pipe key="PIPE" x1={0} y1={PIPE_Y} x2={WIDTH} y2={PIPE_Y} type={PipeType.ColdReturn} />
        <PumpIcon direction={Direction.WEST} x={WIDTH / 2} y={PIPE_Y} />
        <Labels positions={getColdWaterPumpLabels()} values={values} />
      </System>
    );
  }
);
