import React from 'react';
import { Valve } from '../../Valve';
import { Labels } from '../../Labels';
import { PumpIcon } from '../../PumpIcon';
import { Pipe, PipeType } from '../../Pipe';
import { System, BaseSystemProps } from '../../System';
import { TemperatureSensor } from '../../TemperatureSensor';
import { CONNECTION_SPACING, Direction } from '../../Common';
import * as metaData from './mixing-loop-unspecified-load-metadata';
import { FORWARD_X, RETURN_X } from '../../mixing-loop-common/common';
import { HeatMeterOrSensors } from '../../heat-meter-or-sensors/HeatMeterOrSensors';
import { IMixingloopUnspecifiedLoadSystemFormInterface } from '../../../../models/systems/mixingloopUnspecifiedLoadSystem/mixingloopUnspecifiedLoadSystemFormInterface';
import { ValveEnergyIcon } from '../../ValveEnergyIcon';
import { systemHasEnergyValve } from '../../../util/system-properties-util';

const valve = (isEnergyValve: boolean, MV_valveType: any, MV_valveLocation: any) => {
  const ThreeWayValve = MV_valveType === 'ThreeWayValve';
  const TwoWayValve = MV_valveType === 'TwoWayValve';
  const PrimaryForward = MV_valveLocation === 'PrimaryForward';
  const PrimaryReturn = MV_valveLocation === 'PrimaryReturn';
  const energyIconOffset = 30;
  return (
    <>
      {TwoWayValve && PrimaryForward && (
        <>
          {isEnergyValve && <ValveEnergyIcon x={FORWARD_X - energyIconOffset} y={metaData.TWO_WAY_VALVE_Y - energyIconOffset} />}
          <Valve type="TwoWayValve" direction={Direction.NORTH} x={FORWARD_X} y={metaData.TWO_WAY_VALVE_Y} />
        </>
      )}
      {TwoWayValve && PrimaryReturn && (
        <>
          {isEnergyValve && <ValveEnergyIcon x={RETURN_X - energyIconOffset} y={metaData.TWO_WAY_VALVE_Y - energyIconOffset} />}
          <Valve type="TwoWayValve" direction={Direction.SOUTH} x={RETURN_X} y={metaData.TWO_WAY_VALVE_Y} />
        </>
      )}
      {ThreeWayValve && PrimaryForward && (
        <>
          {isEnergyValve && <ValveEnergyIcon x={FORWARD_X - energyIconOffset} y={metaData.WARM_TO_COLD_Y - energyIconOffset} />}
          <Valve type="ThreeWayValve" direction={Direction.NORTH} x={FORWARD_X} y={metaData.WARM_TO_COLD_Y} />
        </>
      )}
      {ThreeWayValve && PrimaryReturn && (
        <>
          {isEnergyValve && <ValveEnergyIcon x={RETURN_X - energyIconOffset} y={metaData.WARM_TO_COLD_Y - energyIconOffset} />}
          <Valve type="ThreeWayValve" direction={Direction.SOUTH} x={RETURN_X} y={metaData.WARM_TO_COLD_Y} />
        </>
      )}
    </>
  );
};

export const MixingLoopUnspecifiedLoadSystem: React.FC<BaseSystemProps<IMixingloopUnspecifiedLoadSystemFormInterface>> = React.memo(
  ({ state, values, alarm, selected }) => {
    const systemInfo = state.systemInfo;
    const showSecondaryReturnTemp = !!state.systemInfo.T_SR_present;

    const showHeatMeter = !!systemInfo.HM_present;
    const showForwardHeatMeter = systemInfo.HM_location === 'PrimaryForward';
    const showReturnHeatMeter = systemInfo.HM_location === 'PrimaryReturn';
    const showForwardSensor = !!(systemInfo.HM_present || systemInfo.T_PF_present);
    const showReturnSensor = !!(systemInfo.HM_present || systemInfo.T_PR_present);
    const hasEnergyValve = systemHasEnergyValve(systemInfo);

    const getPumpSvg = () => {
      if (!systemInfo.P_location) {
        return undefined;
      }
      const pumpX = systemInfo.P_location === 'SecondaryForward' ? FORWARD_X : RETURN_X;
      const direction = systemInfo.P_location === 'SecondaryForward' ? Direction.NORTH : Direction.SOUTH;
      return <PumpIcon direction={direction} x={pumpX} y={metaData.PUMP_Y} />;
    };

    return (
      <System state={state} meta={metaData.mixingLoopUnspecifiedLoadMeta} alarm={alarm} selected={selected} hideName={true}>
        <Pipe
          key="forwardPipe"
          x1={FORWARD_X}
          y1={metaData.PIPE_TOP}
          x2={FORWARD_X}
          y2={metaData.HEIGHT - CONNECTION_SPACING}
          type={PipeType.WarmSupply}
        />
        <Pipe key="returnPipe" x1={RETURN_X} y1={metaData.PIPE_TOP} x2={RETURN_X} y2={metaData.HEIGHT} type={PipeType.ColdReturn} />
        {/* Gradients do not work on 100% horizontal lines due to insane SVG spec corner case, so we make the line slighty non-horizontal */}
        <line
          x1={FORWARD_X}
          y1={metaData.WARM_TO_COLD_Y}
          x2={RETURN_X}
          y2={metaData.WARM_TO_COLD_Y + 0.00001}
          stroke="url(#warmToCold)"
          strokeDasharray="8 4"
          strokeWidth="2"
        />
        {systemInfo.T_SF_present && <TemperatureSensor x={FORWARD_X} y={metaData.SF_TEMP_SENSOR_Y} direction={Direction.NORTH} />}
        {showSecondaryReturnTemp && (
          <g transform={`translate(${RETURN_X},${metaData.SF_TEMP_SENSOR_Y})`}>
            <TemperatureSensor direction={Direction.SOUTH} />
          </g>
        ) }
        {getPumpSvg()}
        <g transform={`translate(${FORWARD_X}, ${metaData.HEAT_METER_Y})`}>
          <HeatMeterOrSensors
            direction={Direction.NORTH}
            showHeatMeter={showHeatMeter}
            showForwardHeatMeter={showForwardHeatMeter}
            showReturnHeatMeter={showReturnHeatMeter}
            showForwardSensor={showForwardSensor}
            showReturnSensor={showReturnSensor}
          />
        </g>
        {valve(hasEnergyValve, systemInfo.MV_valveType, systemInfo.MV_valveLocation)}
        <Labels positions={metaData.getMixingLoopUnspecifiedLoadLabels(systemInfo)} values={values} />
      </System>
    );
  }
);
