export enum ERole {
  CompanyAdmin = 'CompanyAdmin',
  CompanyUser = 'CompanyUser',
  CompanyFacilityManager = 'CompanyFacilityManager',
  GFAdmin = 'GFAdmin',
  GFService = 'GFService',
  GFDeveloperAdmin = 'GFDeveloperAdmin',
  GFSupportTraining = 'GFSupportTraining',
}

export enum EAccessLevel {
  None = 'None',
  GrundfosClaimAccessAny = 'GrundfosClaimAccessAny',
  GrundfosClaimAccessServiceGroup = 'GrundfosClaimAccessServiceGroup',
  GrundfosClaimAccessCompany = 'GrundfosClaimAccessCompany',
  GrundfosClaimAccessFacility = 'GrundfosClaimAccessFacility'
}

export enum AccessClaim {
    ServicePortalServiceGroup = 'ServicePortalServiceGroup',
    ServicePortalFacilityTypes = 'ServicePortalFacilityTypes',
    ServicePortalFactoryAccess = 'ServicePortalFactoryAccess',
    ServicePortalCommission = 'ServicePortalCommission',
    ServicePortalDeviceConfiguration = 'ServicePortalDeviceConfiguration',
    ServicePortalAccess = 'ServicePortalAccess',
    ServicePortalArchiveForDeletion = 'ServicePortalArchiveForDeletion',
    ServicePortalDeletion = 'ServicePortalDeletion',
    ServicePortalSupportBase = 'ServicePortalSupportBase',
    ServicePortalBase = 'ServicePortalBase',
    ServicePortalDeveloperMenu = 'ServicePortalDeveloperMenu',

    CustomerPortalBase = 'CustomerPortalBase',
    CustomerPortalAlarmAdmin = 'CustomerPortalAlarmAdmin',
    CustomerPortalLicenseManagement = 'CustomerPortalLicenseManagement',
    CustomerPortalLicensePurchase = 'CustomerPortalLicensePurchase',
    CustomerPortalWrite = 'CustomerPortalWrite',
    CustomerPortalUserManagement = 'CustomerPortalUserManagement',
    CustomerPortalTestFeatures = 'CustomerPortalTestFeatures',
    CustomerPortalDataPointsTable = 'CustomerPortalDataPointsTable',
  }
