import { InjectionToken, Injector } from '@angular/core';

export const MODAL_DATA = new InjectionToken('MODAL_DATA');
export const MODAL_CONTROLLER = new InjectionToken('MODAL_CONTROLLER');
export const MODAL_CONFIG = new InjectionToken('MODAL_CONFIG');

export interface Result<T> {
  dismissed: boolean;
  result: T;
}

export interface ModalController<T> {
  dismiss(): void;
  complete(result: T): void;
}

export interface OpenDialogOptions {
  injector?: Injector;
  data?: any;
}

export interface ITextModalAction {
  text: string;
  type?: 'default' | 'danger' | 'primary';
  handler?: () => void;
  cancel?: boolean;
}

export interface ITextModalData {
  iconUrl?: string;
  headline?: string;
  title: string;
  content: string;
  actions: ITextModalAction[];
}
