import { CONNECTION_SPACING } from '../../Common';
import { LabelPosition, SystemMeta } from '../../types';
import { SmallLabelDeltas } from '../../../util/constants';
import { EConnectionType, SystemType } from '../../../state';
import { EConnectionPointPlacement, EEquipment } from '../../../types';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/boiler.svg';
import boilerSystemForm from '../../../../models/systems/boilerSystem/boilerSystemForm.json';
import { IBoilerSystemFormInterface } from '../../../../models/systems/boilerSystem/boilerSystemFormInterface';
import { getForwardReturnTempLabels, getHorizontalHeatMeterLabels } from '../../heat-meter-or-sensors/heat-meter-or-sensors-metadata';

export const HEIGHT = 260;
export const WIDTH = 340;
export const FORWARD_Y = 100;
export const BOILER_ICON_X = 12;
export const HEAT_METER_X = 200;
export const PUMP_X = 290;
export const BOILER_ICON_WIDTH = 120;
export const BOILER_ICON_HEIGHT = 121;
export const RETURN_Y = FORWARD_Y + CONNECTION_SPACING;
export const BOILER_ICON_RIGHT = BOILER_ICON_X + BOILER_ICON_WIDTH;

export const boilerSystemMeta: SystemMeta = {
  systemType: SystemType.Boiler,
  name: 'Boiler',
  thumbnail,
  width: WIDTH,
  height: HEIGHT,
  form: boilerSystemForm,
  calculateConnectionPoints: () => [
    {
      key: EConnectionPointPlacement.connectionSecondaryForward,
      x: WIDTH,
      y: FORWARD_Y,
      type: EConnectionType.supply,
      dotted: false,
    },
    {
      key: EConnectionPointPlacement.connectionSecondaryReturn,
      x: WIDTH,
      y: RETURN_Y,
      type: EConnectionType.return,
      dotted: true,
    },
  ],
};

export const getBoilerLabels = (systemInfo: IBoilerSystemFormInterface): LabelPosition[] => {
  const fPump: LabelPosition[] =
    systemInfo.P_location === 'Forward'
      ? [
          {
            id: EEquipment.P_F,
            x: PUMP_X,
            y: FORWARD_Y + SmallLabelDeltas.EAST_Y - 7,
            horizontalAlignment: 'middle',
          },
        ]
      : [];

  const rPump: LabelPosition[] =
    systemInfo.P_location === 'Return'
      ? [
          {
            id: EEquipment.P_R,
            x: PUMP_X,
            y: RETURN_Y + SmallLabelDeltas.WEST_Y + 7,
            horizontalAlignment: 'middle',
          },
        ]
      : [];

  return [
    ...getForwardReturnTempLabels(HEAT_METER_X, FORWARD_Y, systemInfo),
    ...getHorizontalHeatMeterLabels(HEAT_METER_X, FORWARD_Y, systemInfo),
    ...fPump,
    ...rPump,
  ];
};
