import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as Sentry from '@sentry/browser';
import { environment } from '../../environments/environment';
import { catchError, filter, first, flatMap, map, shareReplay, tap } from 'rxjs/operators';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { IBackendUser, IBuildingConnectUser, IUser, UserType } from 'projects/customerportal/src/app/interfaces/user';
import { AppErrorService, AuthService, CommandService, NOT_FOUND_ERROR_CODE } from 'shared';
import {
  IPOSTCompanyUser,
  IPOSTFacilityManager,
  IPUTCompanyUser,
  IPUTFacilityManager,
} from '../../../../serviceportal/src/app/interfaces/user';
import { TranslateService } from '@ngx-translate/core';
import { AccessClaim, EAccessLevel } from 'projects/shared/src/lib/interfaces/access';

export interface INotificationOptions {
  emailActive?: boolean;
  mobile?: string;
  mobileActive?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly baseUserUrl = `${environment.serverUrl}/api/users`;

  public users$: BehaviorSubject<IBuildingConnectUser[] | null> = new BehaviorSubject<IBuildingConnectUser[] | null>(null);

  private _currentUser$: BehaviorSubject<IUser | null> = new BehaviorSubject<IUser | null>(null);
  public currentUser$: Observable<IUser | null>;
  public initialUser$: Observable<IUser | null>;

  private roleTranslations: Record<string, string> = {
    [EAccessLevel.GrundfosClaimAccessCompany]: 'roles.administrator',
    [EAccessLevel.GrundfosClaimAccessFacility]: 'roles.facility-manager',
  };

  public hasClaim(claimName: AccessClaim): Observable<boolean> {
    return this.currentUser$.pipe(
      map((user) => {
        if (!user) {
          return false;
        }
        return (
          user.type === UserType.BuildingConnect && user.hasOwnProperty('accessClaimsNames') && user.accessClaimsNames.includes(claimName)
        );
      })
    );
  }

  public hasClaimAccessAny(): Observable<boolean> {
    return this.currentUser$.pipe(
      first(),
      map((currentUser) => {
        if (currentUser && currentUser.type === UserType.BuildingConnect) {
          return currentUser.accessLevel === EAccessLevel.GrundfosClaimAccessAny;
        }
        return false;
      })
    );
  }

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private errorService: AppErrorService,
    private commandService: CommandService,
    private translateService: TranslateService
  ) {
    this.authService.currentUser
      .pipe(
        filter((user) => !!user),
        first(),
        flatMap((oidcUser) => {
          return this.httpClient.get<IBackendUser>(`${this.baseUserUrl}/current`).pipe(
            map((backendUser) => ({ type: UserType.BuildingConnect, ...backendUser })),
            catchError((errorResponse: HttpErrorResponse) => {
              const header = errorResponse?.headers?.get('errorcode');
              if (header?.includes('user-not-found') && oidcUser) {
                return of({
                  type: UserType.OIDC,
                  name: `${oidcUser.profile.given_name} ${oidcUser.profile.family_name}`,
                } as IUser);
              } else {
                return throwError(errorResponse);
              }
            }),
            errorService.catchApiError({
              errorCodes: {
                'user-disabled': {
                  titleKey: 'user-service.error-user-disabled-header',
                  messageKey: 'user-service.error-user-disabled-body',
                },
              },
            }),
            tap((user) => {
              if (user.type === UserType.BuildingConnect) {
                Sentry.setUser({
                  type: user.type,
                  id: user.id,
                });
              } else {
                if (oidcUser) {
                  Sentry.setUser({
                    type: user.type,
                    sid: oidcUser.profile.sid,
                  });
                }
              }
            })
          );
        })
      )
      .subscribe((u) => {
        this._currentUser$.next(u);
      });

    this.currentUser$ = this._currentUser$.pipe(filter((u) => !!u));
    this.initialUser$ = this.currentUser$.pipe(first());
  }

  public setCurrentUser(user: IBuildingConnectUser) {
    this._currentUser$.next({ ...user, type: UserType.BuildingConnect });
  }

  public pushOrUpdateUsersToUsersList(users: IBuildingConnectUser[]) {
    const allUsers = this.users$.value || [];

    for (const user of users) {
      const userIndex = allUsers.findIndex((f) => f.id === user.id);
      if (userIndex === -1) {
        allUsers.push(user);
      } else {
        allUsers[userIndex] = user;
      }
    }

    this.users$.next(allUsers);
  }

  public removeUserFromList(userId: string) {
    const allUsers = this.users$.value || [];
    const userListWithoutUserId = allUsers.filter((u) => u.id !== userId);
    this.users$.next(userListWithoutUserId);
  }

  public translateRole(role: EAccessLevel): Observable<string> {
    if (!this.roleTranslations[role]) {
      return this.translateService.get('user-service.role-other');
    }
    return this.translateService.get(this.roleTranslations[role]);
  }

  public getUser(userId: string) {
    return this.users$.pipe(
      filter((u) => !!u),
      map((users) => {
        const user = users?.find((u) => u.id === userId);
        if (!user) {
          throw Error();
        }
        return user;
      }),
      catchError((_) => {
        return throwError(
          this.errorService.createError({
            ...NOT_FOUND_ERROR_CODE[404],
            report: false,
          })
        );
      })
    );
  }

  public setNotificationPreferences(options: INotificationOptions) {
    return this.commandService.executeAsync(
      () => {
        return this.httpClient.put<IUser>(`${this.baseUserUrl}/notificationsettings`, options).pipe(
          this.errorService.catchApiError({
            fallbackMessageKey: 'user-service.update-notifcation-preferences-failed',
          })
        );
      },
      { successMessageKey: 'user-service.update-notification-preferences-success' }
    );
  }

  public sendConfirmationCode(code: number) {
    return this.httpClient.put<IUser>(`${this.baseUserUrl}/notificationsettings/code`, { code });
  }

  public resendConfirmationCode() {
    return this.httpClient.get(`${this.baseUserUrl}/notificationsettings/resend`);
  }

  public createCompanyAdminUser(user: IPOSTCompanyUser): Observable<IBuildingConnectUser> {
    return this.commandService.execute(
      () => {
        return this.httpClient.post<IBuildingConnectUser>(`${environment.serverUrl}/api/users/CompanyAdmin`, user).pipe(
          this.errorService.catchApiError({
            fallbackMessageKey: 'user-service.create-company-admin-error',
            errorCodes: {
              302: {
                titleKey: 'user-service.user-already-exists-title',
                messageKey: 'user-service.user-already-exists-message',
              },
            },
          }),
          shareReplay(),
          tap((createdUser) => {
            this.pushOrUpdateUsersToUsersList([createdUser]);
          })
        );
      },
      { successMessageKey: 'user-service.create-user-success' }
    );
  }

  public updateCompanyAdminUser(user: IPUTCompanyUser): Observable<IBackendUser> {
    return this.commandService.execute(
      () => {
        return this.httpClient.put<IBackendUser>(`${environment.serverUrl}/api/users/CompanyAdmin/${user.id}`, user).pipe(
          this.errorService.catchApiError({
            fallbackMessageKey: 'user-service.update-user-error',
            errorCodes: {
              302: {
                titleKey: 'user-service.user-already-exists',
                messageKey: 'user-service.user-already-exists-message',
              },
            },
          })
        );
      },
      { successMessageKey: 'user-service.update-user-success' }
    );
  }

  public createFacilityManager(user: IPOSTFacilityManager): Observable<IBuildingConnectUser> {
    return this.commandService.execute(
      () => {
        return this.httpClient.post<IBuildingConnectUser>(`${environment.serverUrl}/api/users/FacilityManager`, user).pipe(
          this.errorService.catchApiError({
            fallbackMessageKey: 'user-service.create-user-error',
            errorCodes: {
              302: {
                titleKey: 'user-service.user-already-exists',
                messageKey: 'user-service.user-already-exists-message',
              },
            },
          }),
          shareReplay(),
          tap((createdUser) => {
            this.pushOrUpdateUsersToUsersList([createdUser]);
          })
        );
      },
      { successMessageKey: 'user-service.create-user-success' }
    );
  }

  public updateFacilityManager(user: IPUTFacilityManager): Observable<IBackendUser> {
    return this.commandService.execute(
      () => {
        return this.httpClient.put<IBackendUser>(`${environment.serverUrl}/api/users/FacilityManager/${user.id}`, user).pipe(
          this.errorService.catchApiError({
            fallbackMessageKey: 'user-service.update-user-error',
            errorCodes: {
              302: {
                titleKey: 'user-service.user-already-exists',
                messageKey: 'user-service.user-already-exists-message',
              },
            },
          })
        );
      },
      { successMessageKey: 'user-service.update-user-success' }
    );
  }

  public deleteUser(userId: string) {
    return this.commandService.execute(
      () => {
        return this.httpClient.delete(`${this.baseUserUrl}/${userId}`);
      },
      { successMessageKey: 'user-service.delete-user-success' }
    );
  }
}
