import { Action } from 'redux';
import { EditorState, TabState } from './state';
import { CaseReducer, createReducer, PayloadAction, Reducer } from '@reduxjs/toolkit';
import { initialState as initialSchematicState, schematicReducer } from '../schematic/reducer';
import {
  ConfirmDeleteSystem,
  confirmDeleteSystem,
  loadSchematic,
  LoadSchematic,
  removeSystem,
  systemSelect,
  SystemSelect,
  systemUnselect,
} from '../schematic/actions';
import {
  cancelDeleteSchematic,
  cancelRemoveSystem,
  deleteSchematic,
  enterFullscreen,
  hideHelp,
  leaveFullscreen,
  setBuildingInfo,
  SetBuildingInfo,
  setTabState,
  SetTabState,
  showConfirmDeleteSchematic,
  showHelp,
  showDevTools
} from './actions';

const initialState = (): EditorState => {
  return {
    buildingInfo: {},
    schematic: initialSchematicState(),
    tabState: TabState.Clarifications,
    isFullscreen: false,
    showingConfirmDeleteModal: false,
    showingHelp: false,
    showingRemoveSystemModal: false,
    creatingNewSchematic: true,
  };
};

type EditorCaseReducer<Payload> = CaseReducer<EditorState, PayloadAction<Payload>>;

const setTabStateReducer: EditorCaseReducer<SetTabState> = (state, { payload }) => {
  state.tabState = payload.tabState;
  return state;
};

const systemSelectReducer: EditorCaseReducer<SystemSelect> = (state) => {
  setTabStateReducer(state, setTabState({ tabState: TabState.System }));
  return state;
};

const systemUnselectReducer: EditorCaseReducer<void> = (state) => {
  setTabStateReducer(state, setTabState({ tabState: TabState.Clarifications }));
  return state;
};

const setBuildingInfoReducer: EditorCaseReducer<SetBuildingInfo> = (state, { payload }) => {
  state.buildingInfo = payload.buildingInfo;
  return state;
};

const enterFullscreenReducer: EditorCaseReducer<void> = (state) => {
  state.isFullscreen = true;
  return state;
};

const leaveFullscreenReducer: EditorCaseReducer<void> = (state) => {
  state.isFullscreen = false;
  return state;
};

const showConfirmDeleteSchematicReducer: EditorCaseReducer<void> = (state) => {
  state.showingConfirmDeleteModal = true;
  return state;
};

const cancelDeleteSchematicReducer: EditorCaseReducer<void> = (state) => {
  state.showingConfirmDeleteModal = false;
  return state;
};

const cancelRemoveSystemReducer: EditorCaseReducer<void> = (state) => {
  state.showingRemoveSystemModal = false;
  return state;
};

const confirmDeleteSystemReducer: EditorCaseReducer<ConfirmDeleteSystem> = (state, { payload }) => {
  state.showingRemoveSystemModal = true;
  state.schematic.selectedSystemId = payload.systemId;
  return state;
};

const showHelpReducer: EditorCaseReducer<void> = (state) => {
  state.showingHelp = true;
  return state;
};

const hideHelpReducer: EditorCaseReducer<void> = (state) => {
  state.showingHelp = false;
  return state;
};

const deleteSchematicReducer: EditorCaseReducer<void> = (state) => {
  state.schematic.systems = [];
  state.schematic.points = [];
  state.schematic.connections = [];
  state.buildingInfo = {};
  state.schematic.selectedSystemId = null;
  state.tabState = TabState.Clarifications;
  state.showingConfirmDeleteModal = false;
  return state;
};

const removeSystemReducer: EditorCaseReducer<void> = (state) => {
  state.showingRemoveSystemModal = false;
  // actually removing the system from the schematic is handled by the schematic reducer.
  return state;
};

const loadSchematicReducer: EditorCaseReducer<LoadSchematic> = (state, { payload }) => {
  state.buildingInfo = payload.schematic.buildingInfo || {};
  state.creatingNewSchematic = false;
};

const showDevToolsReducer: EditorCaseReducer<void> = (state) => {
  state.showDevTools = true;
  return state;
};

const editorOnlyReducer = createReducer(initialState(), {
  [setTabState.type]: setTabStateReducer,
  [systemSelect.type]: systemSelectReducer,
  [systemUnselect.type]: systemUnselectReducer,
  [setBuildingInfo.type]: setBuildingInfoReducer,
  [enterFullscreen.type]: enterFullscreenReducer,
  [leaveFullscreen.type]: leaveFullscreenReducer,
  [showConfirmDeleteSchematic.type]: showConfirmDeleteSchematicReducer,
  [cancelDeleteSchematic.type]: cancelDeleteSchematicReducer,
  [deleteSchematic.type]: deleteSchematicReducer,
  [cancelRemoveSystem.type]: cancelRemoveSystemReducer,
  [confirmDeleteSystem.type]: confirmDeleteSystemReducer,
  [showHelp.type]: showHelpReducer,
  [hideHelp.type]: hideHelpReducer,
  [removeSystem.type]: removeSystemReducer,
  [loadSchematic.type]: loadSchematicReducer,
  [showDevTools.type]: showDevToolsReducer,
});

export const reducer: Reducer<EditorState, Action> = (state: EditorState | undefined, action: Action): EditorState => {
  let newState = editorOnlyReducer(state, action);
  newState = { ...newState, schematic: schematicReducer(newState.schematic, action) };
  return newState;
};
