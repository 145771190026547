import { ECommissionStatus } from 'shared';
import { AlarmType, AlarmStateType } from './system-status';
import { DeviceDataPoint, EBackendUnit } from './data-points';

export interface IInstallation {
  id: string;
  name: string;
  commissionStatus: ECommissionStatus;
  createdTimestampEpoch: number;
  state: {
    alarmState: AlarmStateType;
    type: AlarmType;
  };
}

export enum EBackendHealthCheckStatus {
  OkTargetNotOvershot = 'OkTargetNotOvershot',
  OkTargetNotUndershot = 'OkTargetNotUndershot',
  ErrorTargetOvershot = 'ErrorTargetOvershot',
  ErrorTargetUndershot = 'ErrorTargetUndershot',
}

export enum EHealthCheckTargetKey {
  TotalDistrictHeating = 'TotalDistrictHeating',
  SpaceHeating = 'SpaceHeating',
  DomesticHotWater = 'DomesticHotWater',
}

export interface HealthCheck {
  value: number;
  targets: HealthCheckTarget[];
  unitType: EBackendUnit;
  status: EBackendHealthCheckStatus;
  name: string;
  potentialSaving: PotentialSaving
}

export interface PotentialSaving {
  value: number
  unitType: EBackendUnit;
}
export interface HealthCheckTarget {
  value: number;
  unit: EBackendUnit;
}

export type EnergyManagementDashboardSection = {
  kpis: DeviceDataPoint[];
  healthChecks: HealthCheck[];
};

export interface EnergyManagementDashboardData {
  installationId: string;
  totalDistrictHeating: EnergyManagementDashboardSection;
  domesticHotWater: EnergyManagementDashboardSection;
  spaceHeating: EnergyManagementDashboardSection;
  periodFrom?: number;
  periodTo?: number;
}

export interface EnergyDashboardTarget {
  name: string;
  value: number;
  unit: EBackendUnit;
}

export type EnergyDashboardTargetsDto = EnergyDashboardTarget[];

export interface EnergyDashboardSettingsDto {
  emissionFactor?: number;
  tankVolume?: number;
  suppliedArea?: number;
  lastYearDeltaT?: number;
  lastYearEnergyConsumption?: number;
  deltaTPenaltyFactor?: number;
  showTankVolume: boolean;
}

export interface HealthCheckMetadataDto
{
  healthCheckTargetKey: string;
  healthCheckTargetName: string;
  healthCheckKey: string;
  healthCheckName: string;
  message: string;
  decription: string;
  possibleRootCauses: string[];
  remedySteps: string[];
}

export interface ActivityComment {
  id?: string,
  installationId: string,
  text: string,
  lastEditedBy?: string,
  lastEditedAt?: Date
}
