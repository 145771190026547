import React from 'react';
import { SvgImage } from '../SvgImage';
import { Pipe, PipeType } from '../Pipe';
import bathIconSvg from '!raw-loader!../../../../assets/icons/bathtub_small.svg';
import faucetIconSvg from '!raw-loader!../../../../assets/icons/faucet_small.svg';

export const BathAndFaucet: React.FC<{ x: number; y: number }> = ({ x, y }) => {
  // the horizontal length of the pipe from the secondary loop to the faucet/bath icons.
  const secondarySupplyExtra = 45;
// dimension of small icons (faucet, bath)
  const smallIconWidth = 16;

// distance bewteen bath and faucet icons
  const bathIconLeftX = 10;
  const bathIconCenterX = bathIconLeftX + smallIconWidth / 2;
  const faucetIconLeftX = bathIconLeftX + smallIconWidth + 10;
  const FAUCET_ICON_CENTER_X = faucetIconLeftX + smallIconWidth / 2;
// the length of the vertical part of pipe to bath/faucet icons
  const supplyVerticalDistance = 23;
  return (
    <g transform={`translate(${x} ${y})`}>
      <Pipe key="secondaryForward" x1={0} y1={0} x2={secondarySupplyExtra} y2={0} type={PipeType.WarmSupply} />
      <Pipe
        key="bathSupply"
        x1={bathIconCenterX}
        y1={0}
        x2={bathIconCenterX}
        y2={supplyVerticalDistance}
        type={PipeType.WarmSupply}
      />
      <Pipe
        key="faucetSupply"
        x1={FAUCET_ICON_CENTER_X}
        y1={0}
        x2={FAUCET_ICON_CENTER_X}
        y2={supplyVerticalDistance}
        type={PipeType.WarmSupply}
      />
      <SvgImage key="bathIcon" svgText={bathIconSvg} x={bathIconLeftX} y={supplyVerticalDistance + 7} />
      <SvgImage key="faucetIcon" svgText={faucetIconSvg} x={faucetIconLeftX} y={supplyVerticalDistance + 7} />
    </g>
  );
};
