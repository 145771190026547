import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'gbc-tile-spinner',
  templateUrl: './tile-spinner.component.html',
  styleUrls: ['./tile-spinner.component.scss']
})
export class TileSpinnerComponent implements OnInit, OnDestroy {
  @Input()
  public height: string;

  @Input()
  observable: Observable<any>;

  public failed = false;
  public loading = true;
  public loaded = false;
  private subscription: Subscription;

  ngOnInit(): void {
    this.subscription = this.observable.subscribe({
      next: () => {
        this.loading = false;
        this.loaded = true;
      },
      error: err => {
        console.error(err);
        this.loading = false;
        this.failed = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
