import React from 'react';
import { Labels } from '../../Labels';
import { Direction } from '../../Common';
import { PumpIcon } from '../../PumpIcon';
import { SvgImage } from '../../SvgImage';
import { Pipe, PipeType } from '../../Pipe';
import { FlowMeter } from '../../FlowMeter';
import { SupplyTriangle } from '../../SupplyTriangle';
import { BaseSystemProps, System } from '../../System';
import { TemperatureSensor } from '../../TemperatureSensor';
import * as metaData from './cascade-buffer-tank-charging-metadata';
import { BathAndFaucet } from '../../heat-exchanger-common/BathAndFaucet';
import bufferTankSvg from '!raw-loader!../../../../../assets/equipment/bufferTank.svg';
import { HeatExchangerCommon } from '../../heat-exchanger-common/HeatExchangerCommon';
import { commonHeatExchangerLabels } from '../../heat-exchanger-common/heat-exchanger-common-metadata';
import { ICascadeBufferTankChargingSystemFormInterface } from '../../../../models/systems/cascadeBufferTankChargingSystem/cascadeBufferTankChargingSystemFormInterface';

export const CascadeBufferTankChargingSystem: React.FC<BaseSystemProps<ICascadeBufferTankChargingSystemFormInterface>> = React.memo(
  ({ state, values, alarm, selected }) => {
    const systemInfo: ICascadeBufferTankChargingSystemFormInterface = state.systemInfo;

    return (
      <System state={state} meta={metaData.cascadeBufferTankChargingMeta} alarm={alarm} selected={selected}>
        <SvgImage svgText={bufferTankSvg} x={metaData.TANK_X} y={metaData.TOP_TANK_Y} />
        <SvgImage svgText={bufferTankSvg} x={metaData.TANK_X} y={metaData.BOTTOM_TANK_Y} />
        {/* This pipe connects the top and bottom tanks */}
        <line
          transform={`rotate(270), translate(-295, ${metaData.TANK_X + 50})`}
          x1={0}
          y1={0}
          x2={15 + 0.0001}
          y2={0.00001}
          stroke="url(#warmToCold)"
          strokeWidth="2"
        />

        <Pipe
          key="supply_left"
          type={PipeType.WarmSupply}
          x1={0}
          y1={metaData.FORWARD_Y}
          x2={metaData.FORWARD_PIPE_X2}
          y2={metaData.FORWARD_Y}
        />
        <Pipe
          key="return_left"
          type={PipeType.ColdReturn}
          x1={0}
          y1={metaData.RETURN_Y}
          x2={metaData.RETURN_PIPE_X2}
          y2={metaData.RETURN_Y}
        />

        <g transform={`translate(0, 260)`}>
          <HeatExchangerCommon state={systemInfo} />
        </g>

        <PumpIcon direction={Direction.WEST} x={metaData.PUMP_RETURN_X} y={metaData.PUMP_RETURN_Y} />

        <Pipe
          key="vertical-supply-pipe"
          type={PipeType.WarmSupply}
          x1={metaData.FORWARD_PIPE_X2}
          y1={metaData.FORWARD_Y}
          x2={metaData.FORWARD_PIPE_X2}
          y2={metaData.TOP_TANK_SUPPLY_Y}
        />
        <Pipe
          key="horizontal-supply-pipe"
          type={PipeType.WarmSupply}
          x1={metaData.FORWARD_PIPE_X2}
          y1={metaData.TOP_TANK_SUPPLY_Y}
          x2={metaData.TANK_X + 1}
          y2={metaData.TOP_TANK_SUPPLY_Y}
        />

        {/* These are the pipes that connect to the bath and faucet */}
        <Pipe
          key="loop1"
          type={PipeType.WarmSupply}
          x1={metaData.TANK_X + 53}
          y1={metaData.TOP_TANK_Y}
          x2={metaData.TANK_X + 53}
          y2={metaData.SECONDARY_LOOP_TOP}
        />
        <Pipe
          key="loop2"
          type={PipeType.WarmSupply}
          x1={metaData.TANK_X + 53}
          y1={metaData.SECONDARY_LOOP_TOP}
          x2={metaData.SECONDARY_LOOP_RIGHT}
          y2={metaData.SECONDARY_LOOP_TOP}
        />
        <Pipe
          key="loop3"
          type={PipeType.WarmReturn}
          x1={metaData.SECONDARY_LOOP_RIGHT}
          y1={metaData.SECONDARY_LOOP_TOP}
          x2={metaData.SECONDARY_LOOP_RIGHT}
          y2={metaData.CIRCULATION_PUMP_Y}
        />
        <Pipe
          key="loop4"
          type={PipeType.WarmReturn}
          x1={metaData.TANK_X + 135}
          y1={metaData.CIRCULATION_PUMP_Y}
          x2={metaData.SECONDARY_LOOP_RIGHT}
          y2={metaData.CIRCULATION_PUMP_Y}
        />
        <Pipe
          key="loop5"
          type={PipeType.WarmReturn}
          x1={metaData.TANK_X + 135}
          y1={metaData.CIRCULATION_PUMP_Y}
          x2={metaData.TANK_X + 135}
          y2={metaData.TOP_TANK_Y + 50}
        />
        <Pipe
          key="loop6"
          type={PipeType.WarmReturn}
          x1={metaData.TANK_X + 105}
          y1={metaData.TOP_TANK_Y + 50}
          x2={metaData.TANK_X + 135}
          y2={metaData.TOP_TANK_Y + 50}
        />

        <BathAndFaucet x={metaData.SECONDARY_LOOP_RIGHT} y={metaData.SECONDARY_LOOP_TOP} />

        <PumpIcon direction={Direction.WEST} x={metaData.CIRCULATION_PUMP_X} y={metaData.CIRCULATION_PUMP_Y} />

        <TemperatureSensor key="T_CR" direction={Direction.EAST} x={metaData.T_CR_X} y={metaData.T_CR_Y} />

        {(systemInfo.T_1_TOP_present ||
          systemInfo.T_START === 'TopFirst' ||
          systemInfo.T_STOP === 'TopFirst') && 
          <TemperatureSensor key="T_1_TOP" direction={Direction.NORTH} x={metaData.T_1_TOP_X} y={metaData.T_1_TOP_Y} />
        }
        {(systemInfo.T_1_MIDDLE_present ||
          systemInfo.T_START === 'MiddleFirst' ||
          systemInfo.T_STOP === 'MiddleFirst') &&
          <TemperatureSensor key="T_1_MIDDLE" direction={Direction.SOUTH} x={metaData.T_1_CENTER_X} y={metaData.T_1_CENTER_Y} />
        }
        {(systemInfo.T_1_BOTTOM_present ||
          systemInfo.T_START === 'BottomFirst' ||
          systemInfo.T_STOP === 'BottomFirst') &&
            <TemperatureSensor key="T_1_BOTTOM" direction={Direction.SOUTH} x={metaData.T_1_BOTTOM_X} y={metaData.T_1_BOTTOM_Y} />
        }

        {(systemInfo.T_2_TOP_present ||
          systemInfo.T_START === 'TopSecond' ||
          systemInfo.T_STOP === 'TopSecond') &&
            <TemperatureSensor key="T_2_TOP" direction={Direction.SOUTH} x={metaData.T_2_TOP_X} y={metaData.T_2_TOP_Y} />
        }
        {(systemInfo.T_2_MIDDLE_present ||
          systemInfo.T_START === 'MiddleSecond' ||
          systemInfo.T_STOP === 'MiddleSecond') &&
            <TemperatureSensor key="T_2_MIDDLE" direction={Direction.SOUTH} x={metaData.T_2_CENTER_X} y={metaData.T_2_CENTER_Y} />
        }
        {(systemInfo.T_2_BOTTOM_present ||
          systemInfo.T_START === 'BottomSecond' ||
          systemInfo.T_STOP === 'BottomSecond') &&
            <TemperatureSensor key="T_2_BOTTOM" direction={Direction.SOUTH} x={metaData.T_2_BOTTOM_X} y={metaData.T_2_BOTTOM_Y} />
        }

        {/* These are the cold supply pipes */}
        <Pipe
          key="cold1"
          type={PipeType.ColdSupply}
          x1={metaData.COLD_SUPPLY_LEFT}
          x2={metaData.COLD_SUPPLY_LEFT}
          y1={metaData.COLD_SUPPLY_TOP}
          y2={metaData.COLD_SUPPLY_BOTTOM}
        />
        <Pipe
          key="cold2"
          type={PipeType.ColdSupply}
          x1={metaData.COLD_SUPPLY_LEFT}
          x2={metaData.COLD_SUPPLY_RIGHT}
          y1={metaData.COLD_SUPPLY_BOTTOM}
          y2={metaData.COLD_SUPPLY_BOTTOM}
        />

        <SupplyTriangle warm={false} direction={Direction.WEST} x={metaData.COLD_SUPPLY_RIGHT - 1} y={metaData.COLD_SUPPLY_BOTTOM} />

        {systemInfo.Q_SC_present && <FlowMeter key="coldSupplyFlow" x={metaData.COLD_SUPPLY_FLOW_X} y={metaData.COLD_SUPPLY_BOTTOM} />}

        <g transform={`translate(0, 260)`}>
          <Labels positions={commonHeatExchangerLabels(systemInfo)} values={values} />
        </g>
        <Labels positions={metaData.getCascadeBufferTankChargingLabels(systemInfo)} values={values} />
      </System>
    );
  }
);
