import React from 'react';
import { SvgImage } from '../SvgImage';
import { Pipe, PipeType } from '../Pipe';
import { CONNECTION_SPACING } from '../Common';
import radiatorIcon from '!raw-loader!../../../../assets/icons/radiator-small.svg';
import { RADIATOR_OFFSET, RADIATOR_SVG_HEIGHT, RADIATOR_SVG_WIDTH, RADIATOR_VALVE_Y } from './common';

// the left of the two radiators
export const TWO_STRING_RADIATOR_LEFT = (CONNECTION_SPACING - RADIATOR_SVG_WIDTH - RADIATOR_OFFSET) / 2;
// the top of the second of the two radiators
export const TWO_STRING_RADIATOR_SECOND_TOP = RADIATOR_SVG_HEIGHT + 5;
// the top of the vertical supply pipe
export const TWO_STRING_SUPPLY_TOP = RADIATOR_VALVE_Y;
// the top of the vertical return pipe for the mixing loop
export const TWO_STRING_RETURN_TOP = RADIATOR_SVG_HEIGHT - RADIATOR_VALVE_Y;

export const RadiatorTwoString: React.FC<{x: number, y: number}> = ({ x, y }) => {
  return <g transform={`translate(${x}, ${y})`}>
    <Pipe key="topRadiatorInlet"
          x1={0}
          y1={RADIATOR_VALVE_Y}
          x2={TWO_STRING_RADIATOR_LEFT + RADIATOR_OFFSET}
          y2={RADIATOR_VALVE_Y}
          type={PipeType.WarmSupply}/>
    <Pipe key="bottomRadiatorInlet"
          x1={0}
          y1={TWO_STRING_RADIATOR_SECOND_TOP + RADIATOR_VALVE_Y}
          x2={TWO_STRING_RADIATOR_LEFT + RADIATOR_OFFSET}
          y2={TWO_STRING_RADIATOR_SECOND_TOP + RADIATOR_VALVE_Y}
          type={PipeType.WarmSupply}/>
    <Pipe key={'topRadiatorOutlet'}
          x1={TWO_STRING_RADIATOR_LEFT + RADIATOR_SVG_WIDTH}
          y1={RADIATOR_SVG_HEIGHT - RADIATOR_VALVE_Y}
          x2={CONNECTION_SPACING}
          y2={RADIATOR_SVG_HEIGHT - RADIATOR_VALVE_Y}
          type={PipeType.ColdReturn}/>
    <Pipe key={'bottomRadiatorOutlet'}
          x1={TWO_STRING_RADIATOR_LEFT + RADIATOR_SVG_WIDTH}
          y1={TWO_STRING_RADIATOR_SECOND_TOP + RADIATOR_SVG_HEIGHT - RADIATOR_VALVE_Y}
          x2={CONNECTION_SPACING}
          y2={TWO_STRING_RADIATOR_SECOND_TOP + RADIATOR_SVG_HEIGHT - RADIATOR_VALVE_Y}
          type={PipeType.ColdReturn}/>
    <SvgImage key="topRadiator" svgText={radiatorIcon} x={TWO_STRING_RADIATOR_LEFT} y={0}/>
    <SvgImage key="bottomRadiator" svgText={radiatorIcon} x={TWO_STRING_RADIATOR_LEFT} y={TWO_STRING_RADIATOR_SECOND_TOP}/>
  </g>;
};
