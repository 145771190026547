import { SchematicMode } from '../types';
import { useMode } from './mode-provider';
import { colors } from '../../util/colors';
import { getAbsoluteCoordinates } from './Common';
import React, { KeyboardEventHandler } from 'react';
import styled, { IStyledComponent } from 'styled-components';
import { ConnectionPointIdentifier, EConnectionType } from '../state';
import {
  ConnectionPointBackspaceEvent,
  ConnectionPointDoubleClickEvent,
  ConnectionPointMouseDownEvent,
  InputEventType,
  useDispatchInputEvent,
} from '../input-strategies/input-strategy-service';

export interface ConnectionPointViewModel {
  id: ConnectionPointIdentifier;
  x: number;
  y: number;
  type: EConnectionType;
}

export interface ConnectionPointProps {
  model: ConnectionPointViewModel;
}

// @ts-ignore
export const ConnectionPoint: IStyledComponent<React.FC<ConnectionPointProps>, any> = styled(({ model }) => {
  const mode = useMode();
  const dispatchEvent = useDispatchInputEvent();
  const onDoubleClick = (e: React.MouseEvent<SVGCircleElement>) => {
    e.stopPropagation();
    const { x, y } = getAbsoluteCoordinates(e);
    const inputEvent: ConnectionPointDoubleClickEvent = {
      type: InputEventType.ConnectionPointDoubleClick,
      connectionPointId: model.id,
      x,
      y,
    };
    dispatchEvent(inputEvent);
  };
  const onMouseDown = () => {
    if (model.id.type === 'SYSTEM') {
      return;
    }
    const inputEvent: ConnectionPointMouseDownEvent = {
      type: InputEventType.ConnectionPointMouseDown,
      connectionPointId: model.id,
    };
    dispatchEvent(inputEvent);
  };

  const onKeyDown: KeyboardEventHandler = (event) => {
    if (model.id.type === 'SYSTEM') {
      return;
    }
    if (event.key === 'Backspace' || event.key === 'Delete') {
      const inputEvent: ConnectionPointBackspaceEvent = {
        type: InputEventType.ConnectionPointBackspace,
        connectionPointId: model.id,
      };
      dispatchEvent(inputEvent);
    }
  };
  const color = colors.connections[model.type as EConnectionType].regular;
  return (
    <circle
      className="connection-point"
      cx={model.x}
      cy={model.y}
      fill={color}
      r="8"
      tabIndex={mode === SchematicMode.Edit ? -1 : undefined}
      onMouseDown={onMouseDown}
      onDoubleClick={onDoubleClick}
      onKeyDown={onKeyDown}
    />
  );
})`
  :focus {
    outline: solid blue 1px;
  }
`;
