import { Component, Input } from '@angular/core';

@Component({
  selector: 'gbc-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
  @Input()
  toggleTitle: string;
  class: string;

  public toggled = false;

  onToggle(): void {
    this.toggled = !this.toggled;
  }
}
