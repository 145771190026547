import React from 'react';
import { Labels } from '../../Labels';
import { Direction } from '../../Common';
import { Pipe, PipeType } from '../../Pipe';
import { System, BaseSystemProps } from '../../System';
import { SUPPLY_TRIANGLE_HEIGHT, SupplyTriangle } from '../../SupplyTriangle';
import { HeatMeterOrSensors } from '../../heat-meter-or-sensors/HeatMeterOrSensors';
import { IDistrictHeatingSupplySystemFormInterface } from '../../../../models/systems/districtHeatingSupplySystem/districtHeatingSupplySystemFormInterface';
import * as metaData from './district-heating-metadata';

export const DistrictHeatingSystem: React.FC<BaseSystemProps<IDistrictHeatingSupplySystemFormInterface>> = React.memo(
  ({ state, values, alarm, selected }) => {
    const systemInfo = state.systemInfo;
    const showHeatMeter = !!systemInfo.HM_present;
    const showForwardHeatMeter = systemInfo.HM_location === 'PrimaryForward';
    const showReturnHeatMeter = systemInfo.HM_location === 'PrimaryReturn';
    const showForwardSensor = !!(systemInfo.HM_present || systemInfo.T_F_present);
    const showReturnSensor = !!(systemInfo.HM_present || systemInfo.T_R_present);
    return (
      <System state={state} meta={metaData.districtHeatingSupplyMeta} alarm={alarm} selected={selected}>
        <SupplyTriangle key="supplyTriangle" warm={true} x={metaData.SUPPLY_X} y={metaData.SUPPLY_Y} direction={Direction.EAST} />
        <SupplyTriangle
          key="returnTriangle"
          warm={false}
          x={metaData.SUPPLY_X - SUPPLY_TRIANGLE_HEIGHT}
          y={metaData.RETURN_Y}
          direction={Direction.WEST}
        />
        <Pipe
          key="supplyPipe"
          x1={metaData.SUPPLY_X - 1}
          y1={metaData.SUPPLY_Y}
          x2={metaData.districtHeatingSupplyMeta.width}
          y2={metaData.SUPPLY_Y}
          type={PipeType.WarmSupply}
        />
        <Pipe
          key="returnPipe"
          x1={metaData.SUPPLY_X - 1}
          y1={metaData.RETURN_Y}
          x2={metaData.districtHeatingSupplyMeta.width}
          y2={metaData.RETURN_Y}
          type={PipeType.ColdReturn}
        />
        <g transform={`translate(${metaData.HEAT_METER_X}, ${metaData.SUPPLY_Y})`}>
          <HeatMeterOrSensors
            direction={Direction.EAST}
            showHeatMeter={showHeatMeter}
            showForwardHeatMeter={showForwardHeatMeter}
            showReturnHeatMeter={showReturnHeatMeter}
            showForwardSensor={showForwardSensor}
            showReturnSensor={showReturnSensor}
          />
        </g>
        <Labels positions={metaData.getDistrictHeatingLabels(systemInfo)} values={values} />
      </System>
    );
  }
);
