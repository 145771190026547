import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModalController, MODAL_CONTROLLER, MODAL_DATA } from 'shared';
import { IPerformanceAlarmDto } from '../../interfaces/alarm-settings';
import { IAlarmSettingsInfoDto } from '../../interfaces/alarm-settings-info';
import { UserTrackingHelperService } from '../../services/user-tracking-helper.service';

@Component({
  selector: 'app-alarm-settings-modal',
  templateUrl: './alarm-settings-modal.component.html',
  styleUrls: ['./alarm-settings-modal.component.scss']
})
export class AlarmSettingsModalComponent implements OnInit, OnDestroy {
  public form: UntypedFormGroup;
  public isValid$: Observable<boolean>;
  public title: string;
  public alarmSettings: IPerformanceAlarmDto;
  public alarmInfo: IAlarmSettingsInfoDto;

  public temperatureUnit: string;
  public durationUnit: string;

  private subscription = new Subscription();

  constructor(
    @Inject(MODAL_DATA) public data: IAlarmSettingsInfoDto,
    @Inject(MODAL_CONTROLLER) private controller: ModalController<IAlarmSettingsInfoDto>,
    private userTrackingHelperService: UserTrackingHelperService
  ) { }

  ngOnInit(): void {
    this.alarmInfo = this.data;
    this.alarmSettings = this.data.alarmSettings as IPerformanceAlarmDto;

    this.temperatureUnit = this.getUnitForDisplay(this.alarmSettings.temperatureUnit);
    this.durationUnit = this.getUnitForDisplay(this.alarmSettings.durationUnit);

    this.form = new UntypedFormGroup({
      alarmSettingsType: new UntypedFormControl(this.alarmSettings.alarmSettingsType.toString()),
      upperRelativeLimit: new UntypedFormControl(this.alarmSettings.upperRelativeLimit, [Validators.min(0)]),
      lowerRelativeLimit: new UntypedFormControl(this.alarmSettings.lowerRelativeLimit, [Validators.max(0)]),
      higherAlarmQualificationDuration: new UntypedFormControl(this.alarmSettings.higherAlarmQualificationDuration, [Validators.min(0)]),
      lowerAlarmQualificationDuration: new UntypedFormControl(this.alarmSettings.lowerAlarmQualificationDuration, [Validators.min(0)]),
      higherAlarmDequalificationDuration: new UntypedFormControl(this.alarmSettings.higherAlarmDequalificationDuration, [Validators.min(0)]),
      lowerAlarmDequalificationDuration: new UntypedFormControl(this.alarmSettings.lowerAlarmDequalificationDuration, [Validators.min(0)]),
    });

    this.subscription.add(this.userTrackingHelperService.startFormValueChangeTracking('adjustAlarmSettingsShown', this.form));

    this.isValid$ = this.form.valueChanges.pipe(
      map(value => {
        return this.form.valid;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  close() {
    this.controller.dismiss();
  }

  save() {
    const outputDto: IAlarmSettingsInfoDto = { ...this.data };
    outputDto.alarmSettings = { ...outputDto.alarmSettings, ...this.form.value };
    this.controller.complete(outputDto);
  }

  dismiss() {
    this.userTrackingHelperService.trackUserAction('adjustAlarmSettingsShown', 'exitClicked');
  }

  private getUnitForDisplay(unitType: string): string {
    switch (unitType.toLowerCase())
    {
      case "degreecelsius" : return "°C";
      case "second" : return "s";
      default : return '-';
    }
  }
}
