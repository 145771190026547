import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController, MODAL_CONTROLLER, MODAL_DATA } from 'shared';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { EquipmentMetaInfo } from '../../../services/installation-configuration.service';
import { UserTrackingHelperService } from '../../../services/user-tracking-helper.service';

export interface MixitHeatCoilProtectionInput {
  enableCoilPreheatReturnTemperature: boolean;
  coilPreheatReturnTemperature: number;
  coilPreHeatReturnTemperatureMeta: EquipmentMetaInfo;

  enableFrostProtection: boolean;
  frostProtectionAirTemperature: number;
  frostProtectionAirTemperatureMeta: EquipmentMetaInfo;
  frostProtectionReturnFlowTemperature: number;
  frostProtectionReturnFlowTemperatureMeta: EquipmentMetaInfo;
}

export interface MixitHeatCoilProtectionResponse {
  enableCoilPreheatReturnTemperature: boolean;
  coilPreheatReturnTemperature: number;

  enableFrostProtection: boolean;
  frostProtectionAirTemperature: number;
  frostProtectionReturnFlowTemperature: number;
}

@Component({
  selector: 'app-mixit-heat-coil-protection-modal',
  templateUrl: './mixit-heat-coil-protection-modal.component.html',
  styleUrls: ['./mixit-heat-coil-protection-modal.component.scss'],
})
export class MixitHeatCoilProtectionModalComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  public valid$: Observable<boolean>;

  public form: UntypedFormGroup;

  public coilPreheatTemperatureMinMax: { min: number; max: number };
  public frostProtectionAirTemperatureMinMax: { min: number; max: number };
  public frostProtectionReturnFlowTemperatureMinMax: { min: number; max: number };

  constructor(
    @Inject(MODAL_DATA) public data: MixitHeatCoilProtectionInput,
    @Inject(MODAL_CONTROLLER) private controller: ModalController<MixitHeatCoilProtectionResponse>,
    private userTrackingHelperService: UserTrackingHelperService
  ) {}

  ngOnInit(): void {
    this.coilPreheatTemperatureMinMax = {
      min: this.data.coilPreHeatReturnTemperatureMeta.min,
      max: this.data.coilPreHeatReturnTemperatureMeta.max,
    };

    this.frostProtectionAirTemperatureMinMax = {
      min: this.data.frostProtectionAirTemperatureMeta.min,
      max: this.data.frostProtectionAirTemperatureMeta.max,
    };

    this.frostProtectionReturnFlowTemperatureMinMax = {
      min: this.data.frostProtectionReturnFlowTemperatureMeta.min,
      max: this.data.frostProtectionReturnFlowTemperatureMeta.max,
    };

    this.form = new UntypedFormGroup({
      enableCoilPreheatReturnTemperature: new UntypedFormControl(''),
      coilPreheatReturnTemperature: new UntypedFormControl('', [
        Validators.required,
        Validators.min(this.data.coilPreHeatReturnTemperatureMeta.min),
        Validators.max(this.data.coilPreHeatReturnTemperatureMeta.max),
      ]),

      enableFrostProtection: new UntypedFormControl(''),
      frostProtectionAirTemperature: new UntypedFormControl('', [
        Validators.required,
        Validators.min(this.data.frostProtectionAirTemperatureMeta.min),
        Validators.max(this.data.frostProtectionAirTemperatureMeta.max),
      ]),
      frostProtectionReturnFlowTemperature: new UntypedFormControl('', [
        Validators.required,
        Validators.min(this.data.frostProtectionReturnFlowTemperatureMeta.min),
        Validators.max(this.data.frostProtectionReturnFlowTemperatureMeta.max),
      ]),
    });

    this.valid$ = this.form.valueChanges.pipe(
      map((form) => {
        let [coilValid, frostValid] = [true, true];
        if (form.enableCoilPreheatReturnTemperature) {
          coilValid = this.form.controls.coilPreheatReturnTemperature.valid;
        }

        if (form.enableFrostProtection) {
          frostValid =
            this.form.controls.frostProtectionAirTemperature.valid && this.form.controls.frostProtectionReturnFlowTemperature.valid;
        }

        return coilValid && frostValid;
      })
    );

    this.subscription.add(
      combineLatest([
        this.form.controls.enableCoilPreheatReturnTemperature.valueChanges,
        this.form.controls.enableFrostProtection.valueChanges,
      ])
        .pipe(
          tap(([enableCoilPreheatReturnTemperature, enableFrostProtection]: [any, any]) => {
            this.toggleInputDisabled(enableCoilPreheatReturnTemperature, enableFrostProtection);
          })
        )
        .subscribe()
    );

    // The valid$ observable doesn't register a change, unless we use setTimeout
    setTimeout(() => {
      this.form.setValue({
        enableCoilPreheatReturnTemperature: this.data.enableCoilPreheatReturnTemperature,
        coilPreheatReturnTemperature: this.data.coilPreheatReturnTemperature,

        enableFrostProtection: this.data.enableFrostProtection,
        frostProtectionAirTemperature: this.data.frostProtectionAirTemperature,
        frostProtectionReturnFlowTemperature: this.data.frostProtectionReturnFlowTemperature,
      });

      this.subscription.add(this.userTrackingHelperService.startFormValueChangeTracking('heatCoilProtectionShown', this.form));
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  toggleInputDisabled(enableCoilPreheatReturnTemperature: boolean, enableFrostProtection: boolean) {
    if (enableCoilPreheatReturnTemperature) {
      this.form.controls.coilPreheatReturnTemperature.enable();
    } else {
      this.form.controls.coilPreheatReturnTemperature.disable();
    }

    if (enableFrostProtection) {
      this.form.controls.frostProtectionAirTemperature.enable();
      this.form.controls.frostProtectionReturnFlowTemperature.enable();
    } else {
      this.form.controls.frostProtectionAirTemperature.disable();
      this.form.controls.frostProtectionReturnFlowTemperature.disable();
    }
  }

  close() {
    this.controller.dismiss();
  }

  save() {
    this.controller.complete({
      enableCoilPreheatReturnTemperature: this.form.controls.enableCoilPreheatReturnTemperature.value,
      coilPreheatReturnTemperature: this.form.controls.coilPreheatReturnTemperature.value,

      enableFrostProtection: this.form.controls.enableFrostProtection.value,
      frostProtectionAirTemperature: this.form.controls.frostProtectionAirTemperature.value,
      frostProtectionReturnFlowTemperature: this.form.controls.frostProtectionReturnFlowTemperature.value,
    });
  }

  dismiss() {
    this.userTrackingHelperService.trackUserAction('heatCoilProtectionShown', 'exitClicked');
  }
}
