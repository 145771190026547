import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import {
  AppModel, loadSchematic,
  renderSchematic,
  SchematicEventType,
  SchematicMode,
  setValues,
  setSystemAlarmsWarnings,
} from '@ams/dumbledore';
import { Subscription } from 'rxjs';
import { dataPointsToDumbledoreFormat } from 'projects/customerportal/src/app/components/schematics-tile/schematics-tile.component';
import { TranslateService } from '@ngx-translate/core';
import { DataPointsResult } from 'projects/customerportal/src/app/interfaces/data-points';
import { DataForInstallation } from '../../pages/mixit-facility-page/mixit-facility-page.component';

@Component({
  selector: 'app-schematic',
  templateUrl: './schematic.component.html',
  styleUrls: ['./schematic.component.scss']
})
export class SchematicComponent implements AfterViewInit, OnDestroy, OnChanges {
  private subscription = new Subscription();

  @ViewChild('dumbledore', {static: false})
  private dumbledore: ElementRef<HTMLDivElement>;

  @Input() schematic: AppModel;

  @Input() dataPoints: DataForInstallation;

  @Input() installationId: string;

  @Input() showShadow = true;

  @Input() alarms: {type: 'Alarm' | 'Warning', systemId: string}[] = [];

  @Output() applicationClicked = new EventEmitter<string>();

  private dispatch: (action: any) => void;

  constructor(
    private translateService: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // Only update if changed
    if (changes.alarms?.previousValue !== undefined){
      this.setAlarmData();
    }
    // Only update the data, if the data is new
    if (changes.dataPoints?.previousValue !== undefined) {
      this.setData();
    }
  }

  ngAfterViewInit() {
    if (this.schematic) {
      const {dispatch} = renderSchematic(this.dumbledore.nativeElement, (event => {
        if (event.type === SchematicEventType.SystemClick) {
          this.applicationClicked.emit(event.systemId);
        }
      }), SchematicMode.View);
      this.dispatch = dispatch;
      dispatch(loadSchematic({schematic: this.schematic}));

      if (this.installationId) {
        this.setData();
        this.setAlarmData();
      }
    }
  }

  setData() {
    const dataPoints: DataPointsResult = this.dataPoints[this.installationId]?.dataPoints;
    if (dataPoints) {
      this.dispatch(setValues({values: dataPointsToDumbledoreFormat(dataPoints.data, this.translateService)}));
    }
  }

  setAlarmData() {
    if (this.alarms && this.alarms.length && this.dispatch) {
      this.dispatch(setSystemAlarmsWarnings(this.alarms));
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
