import { Component } from '@angular/core';
import { Installation } from '../../interfaces/facilty';
import { PageInfoService } from '../../services/page-info.service';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, switchMap, map, withLatestFrom, tap, mergeMap } from 'rxjs/operators';
import { AlarmOverview, Application } from '../../interfaces/alarm';
import { AppError, AppErrorService, PageInfo } from 'shared';
import { AppModel, SystemType } from '@ams/dumbledore';
import { ActivatedRoute } from '@angular/router';
import { FacilityService } from '../../services/facility.service';
import { DataPointsResult } from '../../interfaces/data-points';
import { SchematicsService } from '../../services/schematics.service';
import { DataPointsService } from '../../services/data-points.service';
import { AlarmService } from '../../services/alarm.service';
import { isConnect } from '../../utils/mixit-utils';
import { SystemControlMode } from '../../interfaces/system-control-mode';

@Component({
  selector: 'app-application-page',
  templateUrl: './application-page.component.html',
  styleUrls: ['./application-page.component.scss'],
})
export class ApplicationPageComponent {
  public application$: Observable<Application>;
  public pageError$: Observable<AppError>;
  public pageInfo$: Observable<PageInfo>;
  public ApplicationType = SystemType;
  public installation$: Observable<Installation>;
  public applications$: Observable<Application[]>;
  public firmwareVersion$: Observable<string>;
  public schematic$: Observable<AppModel>;
  public dataPoints$: Observable<DataPointsResult | null>;
  public alarm$: Observable<AlarmOverview>;
  public isMixitPremium$: Observable<boolean>;
  public systemControlMode$: Observable<SystemControlMode>;

  constructor(
    private pageInfoService: PageInfoService,
    private errorService: AppErrorService,
    private currentSchematicService: SchematicsService,
    private facilityService: FacilityService,
    private dataPointsService: DataPointsService,
    private route: ActivatedRoute,
    private alarmService: AlarmService
  ) {
    this.firmwareVersion$ = this.currentSchematicService.getFirmwareVersion();

    this.applications$ = this.currentSchematicService.applications$;
    this.installation$ = this.facilityService.getCurrentInstallation(this.route);

    this.application$ = this.currentSchematicService.getCurrentApplication(this.route);

    this.schematic$ = (this.currentSchematicService.schematic$ as Observable<AppModel>).pipe(
      withLatestFrom(this.application$),
      map(([schematic, application]) => {
        return {
          ...schematic,
          systems: schematic.systems.filter((s) => s.hasId === application.id),
        } as AppModel;
      })
    );

    this.dataPoints$ = combineLatest([this.installation$, this.dataPointsService.dataPoints$]).pipe(
      filter(([_, dataPoints]) => !!dataPoints),
      map(([installation, dataPoints]) => {
        return dataPoints.find((d) => d.installationId === installation.id) || null;
      }),
      withLatestFrom(this.application$),
      map(([dataPoints, application]) => {
        // Scope the data to just the application we are looking at
        // Primary to make the Mixit dashboards work here
        const dataForApplication = dataPoints?.data.find((d) => {
          return d.systemId === application.id;
        });
        return {
          ...dataPoints,
          data: dataForApplication ? [dataForApplication] : [],
        } as DataPointsResult;
      })
    );

    this.pageError$ = this.errorService.createPageErrorObservable([this.application$]);

    this.pageInfo$ = combineLatest([
      this.facilityService.getCurrentFacility(this.route),
      this.installation$,
      this.application$,
      this.firmwareVersion$,
    ]).pipe(
      map(([facility, installation, application, firmwareVersion]) => {
        return this.pageInfoService.application(facility, installation, application, firmwareVersion);
      })
    );

    this.alarm$ = this.application$.pipe(
      switchMap((application) => this.alarmService.getMostImportantAlarmForApplication(application) as Observable<AlarmOverview>)
    );

    this.isMixitPremium$ = isConnect(this.schematic$);
  }
}
