import { LicenseType } from './mixit';
import { HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface ITrialUpgradeInformation {
  companyName: string;
  street: string;
  postalCode: string;
  city: string;
  country: string;
  region: string;
  state: string;
  termsOfUse?: boolean;
  termsAndConditions?: boolean;
  other?: boolean;
}

export interface ILicensePurchaseInformation extends ITrialUpgradeInformation {
  vatNumber: string;
  accountManagerName: string;
  accountManagerEmail: string;
  invoiceEmail: string;
  purchaseReference: string;
}

export interface IIncomingLicenseRequest extends IIncomingLicenseRequestPayload {
  id: string;
}

export interface IIncomingLicenseRequestPayload {
  licenseType: LicenseType;
  licensePurchaseInformation: ILicensePurchaseInformation | ITrialUpgradeInformation;
}

export interface IInstallationUpgradeRequest {
  installationId: string;
  installationName: string;
  request: Observable<IUpgradeRequestResult>;
}

export interface IUpgradeRequestResult {
  status: EUpgradeResultStatusCodes;
  code?: EUpgradeResultErrorCodes;
  message?: string;
}

export interface ILicenseOption {
  licenseType: LicenseType;
  priceEuro?: number;
}

export interface ILicenseCategory {
  key: CategoryType;
  licenses: ILicenseChoice[];
}

export interface ILicenseChoice extends ILicenseOption {
  translationKey: 'mixit-dynamic' | 'building-connect';
  bannedLicenseTypes: LicenseType[];
}

export type CategoryType = 'one-time-fee' | 'subscription';

export enum EUpgradeResultStatusCodes {
  PENDING = 'Pending',
  SUCCESS = 'Success',
  ERROR = 'Error',
}

export enum EUpgradeResultErrorCodes {
  InvalidLicenseType = 'invalid-license-type',
  FailedLoadFacility = 'failed-load-facility',
  LicenseNotApplicableConstrained = 'license-not-applicable-constrained',
  InvalidFacilityCompany = 'invalid-facility-company',
  InvalidCompanyDetails = 'invalid-company-details',
  InvalidUserFacilityCompany = 'invalid-user-facility-company',
  FailedBuildingLoad = 'failed-building-load',
  FailedAcquireLicense = 'failed-acquire-license',
  FailedUpgradeDevice = 'failed-upgrade-device',
  FailedToRegisterLicense = 'failed-to-register-license',
  InvalidPaymentReference = 'invalid-payment-reference',
  InvalidPaymentInformation = 'invalid-payment-information',
  UnknownError = 'unknown-error',
}
