<gbc-tile-spinner [observable]="options$" height="400px">
  <ng-container *ngIf="visible">
    <highcharts-chart
      *ngIf="options$ | async as options"
      [Highcharts]="Highcharts"
      [options]="options"
      style="width: 100%; height: 400px; display: block"
    ></highcharts-chart>
  </ng-container>
</gbc-tile-spinner>
