<table>
  <thead *ngIf="headers && headers.length">
    <tr>
      <ng-content select="[gbc-table-header]"></ng-content>
      <th *ngIf="clickableRows" class="chevron-header"></th>
      <th *ngIf="selectableRows" class="selectable-header"></th>
    </tr>
  </thead>
  <tbody>
    <ng-content></ng-content>
  </tbody>
</table>
