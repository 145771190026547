import React from 'react';
import { getConnectionPointViewModel } from '../util/rendering-util';
import { connectionPointIdentifierEquals } from '../util/model-util';
import { ConnectionBeingCreatedState, SchematicState } from '../state';
import { InputEventType, InputStrategy } from './input-strategy-service';
import { ConnectionBeingCreated } from '../components/ConnectionBeingCreated';
import { cancelCreatingConnection, finishCreatingConnection, updateConnectionBeingCreatedPosition } from '../actions';

export const creatingConnectionInputStrategy: InputStrategy = {
  name: 'CreatingConnection',
  handleEvent: (dispatch, state, event) => {
    switch (event.type) {
      case InputEventType.ConnectionPointDoubleClick: {
        const from = (state.connectionBeingCreated as ConnectionBeingCreatedState).from;
        if (connectionPointIdentifierEquals(from, event.connectionPointId)) {
          dispatch(cancelCreatingConnection());
          return;
        }
        const fromVm = getConnectionPointViewModel(state, from);
        const toVm = getConnectionPointViewModel(state, event.connectionPointId);
        if (fromVm.type !== toVm.type) {
          return;
        }
        dispatch(
          finishCreatingConnection({
            to: event.connectionPointId,
          })
        );
        return;
      }
      case InputEventType.MouseMove: {
        dispatch(
          updateConnectionBeingCreatedPosition({
            newX: event.x,
            newY: event.y,
          })
        );
        return;
      }
      case InputEventType.BackgroundDoubleClick:
      case InputEventType.MouseRightClick: {
        dispatch(cancelCreatingConnection());
        return;
      }
    }
  },
  render: (state: SchematicState) => {
    if (!state.connectionBeingCreated) {
      throw new Error('Invalid state: No connectionBeingCreated when input strategy is CreatingConnection');
    }
    const { from, toX, toY } = state.connectionBeingCreated;
    if (!from) {
      throw new Error('ConnectionBeingCreated specified point that does not exist in state');
    }
    const fromVm = getConnectionPointViewModel(state, from);
    return [
      <ConnectionBeingCreated key="ConnectionBeingCreated" from={fromVm} toX={toX} toY={toY} dotted={from.dotted} type={fromVm.type} />,
    ];
  },
};
