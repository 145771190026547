import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
  AppModel,
  EEquipment,
  EValueType,
  InfoState,
  loadSchematic,
  PasteurizationState,
  renderSchematic,
  SchematicEventType,
  SchematicMode,
  setSystemAlarmsWarnings,
  setSystemControlMode,
  setWarmWeatherShutDown,
  setSystemInfoState,
  setValues,
  SystemControlModePayload,
  SystemType,
  TEquipmentValues,
  WarmWeatherShutDownPayload,
  ShowOutdoorTemperatureSensorPayload,
  setShowOutdoorTemperatureSensor
} from '@ams/dumbledore';
import { DataPointsResult, System, SystemDevice } from '../../interfaces/data-points';
import { Observable, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { formatDataPoint, getPasteurizationState } from '../../utils/data-point-utils';
import { OperatingMode } from 'projects/customerportal/src/app/interfaces/alarm';
import { formatAlarmsForDumbledore } from 'projects/customerportal/src/app/utils/alarm-utils';
import { SchematicsService } from '../../services/schematics.service';
import { AlarmService } from '../../services/alarm.service';
import { MixitDataPointName } from '../../interfaces/mixit';
import { filter, map } from 'rxjs/operators';
import { IMixitSystemFormInterface } from '../../../../../dumbledore/src/models/systems/mixitSystem/mixitSystemFormInterface';
import { SystemDeviceType } from '../../interfaces/systemDeviceType';
import { DataPointsService } from '../../services/data-points.service';

const addPumpDatapoint = (result: TEquipmentValues[], system: System, device: SystemDevice, translationService: TranslateService) => {
  const isMixit = device.type === SystemDeviceType.MixitSystem;
  const differentialPressureDataPoint = device.dataPoints.find(
    (dp) => dp.humanReadableId === MixitDataPointName.PumpHead || dp.humanReadableId?.includes('H_')
  );
  const flowDatapoint = device.dataPoints.find(
    (dp) => dp.humanReadableId === MixitDataPointName.PumpFlowAverage || dp.humanReadableId?.includes('Q_')
  );
  const pumpOperatingMode = device.dataPoints.find((dp) => dp.humanReadableId?.includes('ActualMode'));

  const tempDatapoint = device.dataPoints.find((dp) => ['T_R', 'T_F', 'T_SR', 'T_CR', 'T_SF', 'T_PR', 'T_PF'].includes(dp.dumbledoreId));
  if (!differentialPressureDataPoint && !flowDatapoint) {
    return;
  }
  const values: string[] = [];
  if (pumpOperatingMode && pumpOperatingMode.value === OperatingMode.Stop) {
    values.push(translationService.instant('dumbledore-schematics-tile.pump-stopped'));
  } else {
    if (flowDatapoint) {
      values.push(formatDataPoint(flowDatapoint));
    }
  }
  if (differentialPressureDataPoint) {
    values.push(formatDataPoint(differentialPressureDataPoint));
  }

  // There is no temp data point to show together with the pump for a mixit;
  if (tempDatapoint && !isMixit) {
    values.push(formatDataPoint(tempDatapoint));
  }
  const value = values.join('\n');
  const equipment = differentialPressureDataPoint?.dumbledoreId || (flowDatapoint?.dumbledoreId as EEquipment);
  result.push({
    systemId: system.systemId,
    deviceId: device.deviceId,
    value,
    equipment,
    valueType: EValueType.value,
  });
};

const addMixitSpecificDataPoints = (
  result: TEquipmentValues[],
  system: System,
  device: SystemDevice,
  translationService: TranslateService
) => {
  addPumpDatapoint(result, system, device, translationService);
  const valveSupplyFlowAverage = device.dataPoints.find((d) => d.humanReadableId === MixitDataPointName.ValveSupplyFlowAverage);
  const thermalPower = device.dataPoints.find((d) => d.humanReadableId === MixitDataPointName.ThermalPowerFlowAverageCalibrated);

  const formattedSupply = formatDataPoint(valveSupplyFlowAverage);
  const formattedThermal = formatDataPoint(thermalPower);

  const formatted = `${formattedSupply}\n${formattedThermal}`;
  result.push({
    valueType: EValueType.value,
    systemId: system.systemId,
    deviceId: device.deviceId,
    value: formatted,
    equipment: EEquipment.MixitMeter,
  });

  // Add setpoint for T_SF
  const setPoint = device.dataPoints.find((d) => d.humanReadableId === MixitDataPointName.EffectiveTempSetPointAverage);
  result.push({
    valueType: EValueType.setPoint,
    systemId: system.systemId,
    deviceId: device.deviceId,
    value: formatDataPoint(setPoint),
    equipment: EEquipment.T_SF,
  });

  // TODO: Add setpoint for T_TOP_SETPOINT
};

export const dataPointsToDumbledoreFormat = (systems: System[], translationService: TranslateService): TEquipmentValues[] => {
  const result: TEquipmentValues[] = [];
  for (const system of systems) {
    for (const device of system.devices) {
      if (device.type === SystemDeviceType.MixitSystem) {
        addMixitSpecificDataPoints(result, system, device, translationService);
      } else if (device.type === SystemDeviceType.Pump) {
        addPumpDatapoint(result, system, device, translationService);
      }

      for (const dataPoint of device.dataPoints) {
        const formatted = formatDataPoint(dataPoint);
        if ([EEquipment.Q_PF, EEquipment.Q_PR].includes(dataPoint.dumbledoreId)) {
          // these labels for heat meter are handled separately, because they also need to include power in addition to flow.
          continue;
        }
        const value: TEquipmentValues = {
          valueType: dataPoint.valueType,
          systemId: system.systemId,
          deviceId: device.deviceId,
          value: formatted,
          equipment: dataPoint.dumbledoreId as EEquipment,
        } as TEquipmentValues;
        result.push(value);
      }
    }

    const primaryFlowDevice = system.devices.find((device) =>
      device.dataPoints.some((dataPoint) => [EEquipment.Q_PF, EEquipment.Q_PR].includes(dataPoint.dumbledoreId))
    );
    const primaryFlowDataPoint = primaryFlowDevice?.dataPoints.find((dataPoint) =>
      [EEquipment.Q_PF, EEquipment.Q_PR].includes(dataPoint.dumbledoreId)
    );
    if (primaryFlowDevice && primaryFlowDataPoint) {
      const allDataPoints = system.devices.flatMap((device) => device.dataPoints);
      const powerDatapoint = allDataPoints.find((dataPoint) => dataPoint.dumbledoreId === EEquipment.POW);
      const formattedFlow = formatDataPoint(primaryFlowDataPoint);
      const formattedPower = powerDatapoint ? formatDataPoint(powerDatapoint) : '';
      const formatted = `${formattedFlow}\n${formattedPower}`;
      result.push({
        valueType: EValueType.value,
        systemId: system.systemId,
        deviceId: primaryFlowDevice.deviceId,
        value: formatted,
        equipment: primaryFlowDataPoint.dumbledoreId as EEquipment,
      });
    }
  }

  return result;
};

@Component({
  selector: 'app-schematics-tile',
  templateUrl: './schematics-tile.component.html',
  styleUrls: ['./schematics-tile.component.scss'],
})
export class SchematicsTileComponent implements OnInit, AfterViewInit, OnDestroy {
  private schematicSubscription: Subscription;
  private dataPointsSubscription: Subscription;
  private alarmSubscription: Subscription;
  private pasteurizationModeSubscription: Subscription;
  private systemControlModeSubscription: Subscription;
  private warmWeatherShutdownSubscription: Subscription;
  private showOutdoorTemperatureSensorSubscription: Subscription;

  @ViewChild('dumbledore', { static: false })
  private dumbledore: ElementRef<HTMLElement>;

  @Output() applicationClicked = new EventEmitter<string>();

  @Input() applicationId: string;
  @Input() installationId: string;
  @Input() height: number;
  @Input() commissionState = '';
  @Input() showShadow = true;
  @Input() disableAlarms = false;
  @Input() dataPoints$: Observable<DataPointsResult>;

  public isMixit$: Observable<boolean>;
  public isDynamic$: Observable<boolean>;
  public isConnect$: Observable<boolean>;

  private schematicView: any;
  private schematics$: Observable<AppModel>;

  private pasteurizationState$: Observable<InfoState[]>;
  private systemControlModes$: Observable<SystemControlModePayload[]>;
  private warmWeatherShutdown$: Observable<WarmWeatherShutDownPayload[]>;
  private showOutdoorTemperatureSensor$: Observable<ShowOutdoorTemperatureSensorPayload[]>;

  constructor(
    private alarmService: AlarmService,
    private translateService: TranslateService,
    private schematicService: SchematicsService,
    private dataPointService: DataPointsService
  ) {}

  ngOnInit() {
    this.schematics$ = this.schematicService.schematic$ as Observable<AppModel>;

    this.isMixit$ = this.schematics$.pipe(
      map((schematic) => {
        const firstSystem = schematic.systems[0];
        return firstSystem.type === SystemType.Mixit;
      })
    );

    this.isConnect$ = this.schematics$.pipe(
      map((schematic) => {
        const firstSystem = schematic.systems[0];
        return (firstSystem.systemInfo as IMixitSystemFormInterface).Connect || false;
      })
    );

    this.isDynamic$ = this.schematics$.pipe(
      map((schematic) => {
        const firstSystem = schematic.systems[0];
        return (firstSystem.systemInfo as IMixitSystemFormInterface).Dynamic || false;
      })
    );

    // TODO: Move to service for better encapsulation...
    this.pasteurizationState$ = this.dataPoints$.pipe(
      filter((dataPoints) => !!dataPoints),
      map((dataPoints) => this.getPasteurizationInfoState(dataPoints))
    );

    this.systemControlModes$ = this.dataPointService.getSystemControlModesForInstallation(this.installationId);

    this.warmWeatherShutdown$ = this.dataPointService.getWarmWeatherShutdownActiveForInstallation(this.installationId);

    this.showOutdoorTemperatureSensor$ = this.dataPointService.getShowOutdoorTemperatureSensorForInstallation(this.installationId);
  }

  ngAfterViewInit(): void {
    this.schematicSubscription = this.schematics$.subscribe((schematics) => {
      // Create new schematics (will clean schematics state).
      this.schematicView = renderSchematic(
        this.dumbledore.nativeElement,
        (event) => {
          switch (event.type) {
            case SchematicEventType.SystemClick:
              this.applicationClicked.emit(event.systemId);
              break;
          }
        },
        SchematicMode.View
      );

      this.schematicView.dispatch(loadSchematic({ schematic: schematics, systemId: this.applicationId }));

      // Subscribe to ensure data and re-subscribe when schematics are updated.
      this.subscribeToDataPoints();
      this.subscribeToAlarms();
      this.subscribeToSystemControlMode();
      this.subscribeToPasteurizationMode();
      this.subscribeToWarmWeatherShutdown();
      this.subscribeToShowOutdoorTemperatureSensor();
    });
  }

  ngOnDestroy(): void {
    // Clear subscriptions.
    this.schematicSubscription?.unsubscribe();
    this.dataPointsSubscription?.unsubscribe();
    this.alarmSubscription?.unsubscribe();
    this.pasteurizationModeSubscription?.unsubscribe();
    this.systemControlModeSubscription?.unsubscribe();
    this.warmWeatherShutdownSubscription?.unsubscribe();
    this.showOutdoorTemperatureSensorSubscription?.unsubscribe();
  }

  fullscreen() {
    this.dumbledore.nativeElement.requestFullscreen?.();
  }

  //-- Subscriptions -------------------------------------------------------------------

  private subscribeToDataPoints = () => {
    this.dataPointsSubscription?.unsubscribe();

    this.dataPointsSubscription = this.dataPoints$.subscribe((dataPoints) => {
      if (dataPoints && dataPoints.data) {
        this.schematicView.dispatch(setValues({ values: dataPointsToDumbledoreFormat(dataPoints.data, this.translateService) }));
      }
    });
  };

  private subscribeToAlarms = () => {
    if (this.disableAlarms) {
      return;
    }

    this.alarmSubscription?.unsubscribe();

    this.alarmSubscription = this.alarmService.alarms$.subscribe((alarms) => {
      if (!this.schematicView) {
        return;
      }
      this.schematicView.dispatch(setSystemAlarmsWarnings(formatAlarmsForDumbledore(alarms)));
    });
  };

  private subscribeToSystemControlMode = () => {
    this.systemControlModeSubscription?.unsubscribe();

    this.systemControlModeSubscription = this.systemControlModes$.subscribe((systemControlModes) => {
      this.schematicView.dispatch(setSystemControlMode(systemControlModes));
    });
  };

  private subscribeToPasteurizationMode = () => {
    this.pasteurizationModeSubscription?.unsubscribe();

    this.pasteurizationModeSubscription = this.pasteurizationState$.subscribe((infoStates) => {
      this.schematicView.dispatch(setSystemInfoState(infoStates));
    });
  };

  private subscribeToWarmWeatherShutdown = () => {
    this.warmWeatherShutdownSubscription?.unsubscribe();

    this.warmWeatherShutdownSubscription = this.warmWeatherShutdown$.subscribe((warmWeatherShutDownStates) => {
      this.schematicView.dispatch(setWarmWeatherShutDown(warmWeatherShutDownStates));
    });
  };

  private subscribeToShowOutdoorTemperatureSensor = () => {
    this.showOutdoorTemperatureSensorSubscription?.unsubscribe();

    this.showOutdoorTemperatureSensorSubscription = this.showOutdoorTemperatureSensor$.subscribe((showOutdoorTemperatureSensorStates) => {
      this.schematicView.dispatch(setShowOutdoorTemperatureSensor(showOutdoorTemperatureSensorStates));
    });
  };

  //-- Helpers -------------------------------------------------------------------------

  private getPasteurizationInfoState(dataPoints: DataPointsResult): InfoState[] {
    const pasteurizationOutput: InfoState[] = [];
    for (let data of dataPoints.data) {
      let pasteurizationState = getPasteurizationState(data);
      pasteurizationOutput.push({
        systemId: data.systemId,
        pasteurization: pasteurizationState === undefined
          ? false
          : pasteurizationState !== PasteurizationState.Off,
      });
    }
    return pasteurizationOutput;
  }
}
