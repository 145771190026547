import { LabelPosition } from '../types';
import { EEquipment } from '../../types';
import { CONNECTION_SPACING } from '../Common';
import { SmallLabelDeltas } from '../../util/constants';
import { getHorizontalHeatMeterLabels, getPrimaryTempLabels } from '../heat-meter-or-sensors/heat-meter-or-sensors-metadata';
import { IHeatExchangingSystemFormInterface } from '../../../models/systems/heatExchangingSystem/heatExchangingSystemFormInterface';
import { ICommercialHotWaterSystemFormInterface } from '../../../models/systems/commercialHotWaterSystem/commercialHotWaterSystemFormInterface';

// The Y coordinate of the forward pipe
export const FORWARD_Y = 100;
export const RETURN_Y = FORWARD_Y + CONNECTION_SPACING;
// dimensions of heat exchanger SVG
export const EXCHANGER_ICON_HEIGHT = 117;
export const EXCHANGER_ICON_WIDTH = 79;
// X-position of left side of heat exchanger svg
export const EXCHANGER_ICON_X = 225;
export const PUMP_X = 75;
// X-position of valve center
export const VALVE_X = 182;
// X-position of heat meter center (where primary temp sensor touches pipe)
export const HEAT_METER_X = 75;
// X-position of secondary forward/return temp sensors
export const SECONDARY_TEMP_SENSOR_X = 315;
export const DIFFERENTIAL_PRESSURE_DISTANCE_TO_EXCHANGER_X = 25;
export const EXCHANGER_ICON_RIGHT = EXCHANGER_ICON_X + EXCHANGER_ICON_WIDTH;
export const EXCHANGER_ICON_TOP = FORWARD_Y - (EXCHANGER_ICON_HEIGHT - CONNECTION_SPACING) / 2;
export const PRIMARY_DIFFERENTIAL_PRESSURE_X = 130;
export const SECONDARY_DIFFERENTIAL_PRESSURE_X = EXCHANGER_ICON_RIGHT + DIFFERENTIAL_PRESSURE_DISTANCE_TO_EXCHANGER_X;

export const getPressureSensorX = (doubleValvePresent: boolean) => {
  if (doubleValvePresent) {
    return PRIMARY_DIFFERENTIAL_PRESSURE_X - 6;
  }
  return PRIMARY_DIFFERENTIAL_PRESSURE_X - 16;
};

export const getPressureSensorY = (doubleValvePresent: boolean, valveLocation?: 'PrimaryForward' | 'PrimaryReturn') => {
  if (!doubleValvePresent) {
    return (FORWARD_Y + RETURN_Y) / 2;
  }
  if (valveLocation === 'PrimaryForward') {
    return (FORWARD_Y + RETURN_Y) / 2 + 16;
  }
  return (FORWARD_Y + RETURN_Y) / 2 - 16;
};

export const commonHeatExchangerLabels = (
  systemInfo: ICommercialHotWaterSystemFormInterface | IHeatExchangingSystemFormInterface,
  ignoreHeatMeter = false,
  ignorePrimaryDeltaPSensor = false
): LabelPosition[] => {
  const tsf = [
    {
      id: EEquipment.T_SF,
      x: SECONDARY_TEMP_SENSOR_X + SmallLabelDeltas.TEMP_SENSOR_HORIZONTAL_DX,
      y: FORWARD_Y + SmallLabelDeltas.EAST_Y,
    },
  ];
  const tsr = systemInfo.T_SR_present
    ? [
        {
          id: EEquipment.T_SR,
          x: SECONDARY_TEMP_SENSOR_X + SmallLabelDeltas.TEMP_SENSOR_HORIZONTAL_DX,
          y: RETURN_Y + SmallLabelDeltas.WEST_Y,
        },
      ]
    : [];
  const valveLabel: LabelPosition[] =
    systemInfo.MV_valveLocation === 'PrimaryForward'
      ? [
          {
            id: EEquipment.MV_2W_PF,
            x: VALVE_X + 40,
            y: FORWARD_Y - 30,
            horizontalAlignment: 'middle',
          },
        ]
      : [
          {
            id: EEquipment.MV_2W_PR,
            x: VALVE_X + 40,
            y: RETURN_Y + 30,
            horizontalAlignment: 'middle',
          },
        ];

  const hasDoubleValve = !!systemInfo.MV_Parallel_Valve_Enabled;
  // This label is tricky, as the double valve can be in the way
  const pressureSensorPrimaryLabel: LabelPosition[] =
    systemInfo.DP_P_present && !ignorePrimaryDeltaPSensor
      ? [
          {
            id: EEquipment.H_DIFF_P,
            x: getPressureSensorX(hasDoubleValve),
            y: getPressureSensorY(hasDoubleValve, systemInfo.MV_valveLocation),
            horizontalAlignment: 'end',
          },
        ]
      : [];

  const pressureSensorSecondaryLabel: LabelPosition[] = systemInfo.DP_S_present
    ? [
        {
          id: EEquipment.H_DIFF_S,
          x: SECONDARY_DIFFERENTIAL_PRESSURE_X + 15,
          y: (FORWARD_Y + RETURN_Y) / 2,
        },
      ]
    : [];

  return [
    ...(!ignoreHeatMeter ? getHorizontalHeatMeterLabels(HEAT_METER_X, FORWARD_Y, systemInfo) : []),
    ...getPrimaryTempLabels(HEAT_METER_X, FORWARD_Y, systemInfo, ignoreHeatMeter),
    ...tsf,
    ...tsr,
    ...valveLabel,
    ...pressureSensorPrimaryLabel,
    ...pressureSensorSecondaryLabel,
  ];
};
