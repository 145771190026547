import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { MODAL_CONTROLLER, ModalController, MODAL_CONFIG } from '../../interfaces/modal';

export interface ModalConfig {
  allowDismissOnBackdropClick: boolean;
}

@Component({
  selector: 'gbc-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() public title: string;
  @Input() public showDismiss = true;
  @Input() public allowDismissOnBackdropClick = false;
  @Input() public hideHeader = false;
  @Output() public dismiss: EventEmitter<ModalComponent> = new EventEmitter<ModalComponent>();

  @HostListener('window:popstate', ['$event'])
  dismissModal() {
    this.dismiss.emit();
    this.controller.dismiss();
  }

  constructor(@Inject(MODAL_CONTROLLER) private controller: ModalController<any>, @Inject(MODAL_CONFIG) private config: ModalConfig) {}

  ngOnInit() {
    this.config.allowDismissOnBackdropClick = this.allowDismissOnBackdropClick;
  }
}
