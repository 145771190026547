import { SAMPLE_VALUES_DISTRICT_HEATING_SUPPLY } from './districtHeatingSupplySamples';
import { SAMPLE_VALUES_INDIRECT_DISTRICT_HEATING_SUPPLY } from './indirectDistrictHeatingSupplySamples';
import { SAMPLE_VALUES_MIXING_LOOP } from './mixingLoopSamples';
import { EEquipment, EValueType, TValveOpening } from '../types';
import { SAMPLE_VALUES_BOILER } from './boilerSamples';
import { SAMPLE_VALUES_MIXIT } from './mixitSamples';
import { SAMPLE_VALUES_COMMERCIAL_HOT_WATER } from './commercialHotWaterSamples';
import { SAMPLE_VALUES_COMMERCIAL_HOT_WATER_STANDALONE } from './commercialHotWaterStandaloneSamples';
import { SAMPLE_VALUES_HEAT_EXCHANGER } from './heatExchangerSamples';
import { SAMPLE_VALUES_OUTDOOR_TEMPERATURE } from './outdoorTemperatureSamples';
//import { SAMPLE_VALUES_HOT_WATER_PUMP } from './hotWaterPumpSamples';
//import { SAMPLE_VALUES_COLD_WATER_PUMP } from './coldWaterPumpSamples';
import { HOT_WATER_TANK_VALUE_SAMPLES } from './hotWaterTankSamples';
import { HOT_WATER_TANK_STANDALONE_VALUE_SAMPLES } from './hotWaterTankStandAloneSamples';
import { SAMPLE_VALUES_TWO_STROKE_HEAT_EXCHANGER_HOT_WATER } from './twoStrokeHeatExchangerHotWaterSamples';
import { SAMPLE_VALUES_UNSPECIFIED_LOAD } from './unspecifiedLoadSamples';
import { SAMPLE_VALUES_BUFFER_TANK } from './bufferTankSamples';
import { SAMPLE_VALUES_PUMP_STANDALONE } from './pumpStandaloneSamples';
import { SAMPLE_VALUES_COLD_WATER_SUPPLY } from './coldWaterSupplySamples';
import { SAMPLE_VALUES_BOOSTER } from './boosterSamples';
import { SAMPLE_VALUES_PRESSURE_SENSOR } from './pressureSensorSamples';
import { SAMPLE_VALUES_CASCADE_TANK } from './cascadeTankSamples';
import { SAMPLE_VALUES_CASCADE_BUFFER_TANK_CHARGING } from './cascadeBufferTankChargingSamples';


export interface SampleValue {
  systemType: string;
  type: EValueType.value;
  equipment: EEquipment;
  value: string;
  unit?: string;
}

export interface SampleSetpoint {
  systemType: string;
  type: EValueType.setPoint;
  equipment: EEquipment;
  value: string;
  unit?: string;
}

export interface SampleValveIndication {
  systemType: string;
  type: EValueType.valveIndication;
  equipment: EEquipment;
  value: TValveOpening;
  unit?: string;
}

export type SampleEquipmentValue = SampleValue | SampleSetpoint | SampleValveIndication;

export const SAMPLE_VALUES: SampleEquipmentValue[] = [
  ...SAMPLE_VALUES_DISTRICT_HEATING_SUPPLY,
  ...SAMPLE_VALUES_INDIRECT_DISTRICT_HEATING_SUPPLY,
  ...SAMPLE_VALUES_MIXING_LOOP,
  ...SAMPLE_VALUES_COMMERCIAL_HOT_WATER,
  ...SAMPLE_VALUES_COMMERCIAL_HOT_WATER_STANDALONE,
  ...SAMPLE_VALUES_HEAT_EXCHANGER,
  ...SAMPLE_VALUES_BOILER,
  ...SAMPLE_VALUES_OUTDOOR_TEMPERATURE,
  // DEPRECATED: ...SAMPLE_VALUES_HOT_WATER_PUMP,
  // DEPRECATED: ...SAMPLE_VALUES_COLD_WATER_PUMP,
  ...HOT_WATER_TANK_VALUE_SAMPLES,
  ...HOT_WATER_TANK_STANDALONE_VALUE_SAMPLES,
  ...SAMPLE_VALUES_MIXIT,
  ...SAMPLE_VALUES_TWO_STROKE_HEAT_EXCHANGER_HOT_WATER,
  ...SAMPLE_VALUES_UNSPECIFIED_LOAD,
  ...SAMPLE_VALUES_BUFFER_TANK,
  ...SAMPLE_VALUES_PUMP_STANDALONE,
  ...SAMPLE_VALUES_COLD_WATER_SUPPLY,
  ...SAMPLE_VALUES_BOOSTER,
  ...SAMPLE_VALUES_PRESSURE_SENSOR,
  ...SAMPLE_VALUES_CASCADE_TANK,
  ...SAMPLE_VALUES_CASCADE_BUFFER_TANK_CHARGING
];
