import React from 'react';
import { Labels } from '../Labels';
import { Direction } from '../Common';
import { Pipe, PipeType } from '../Pipe';
import { BaseSystemProps, System } from '../System';
import { LabelPosition, SystemMeta } from '../types';
import { LargeLabelDeltas } from '../../util/constants';
import { TemperatureSensor } from '../TemperatureSensor';
import { EConnectionType, SystemType } from '../../state';
import { EConnectionPointPlacement, EEquipment } from '../../types';
import thumbnail from '!svg-url-loader!../../../../assets/system_thumbnails/hot-temperature.svg';
import hotWaterTemperatureSystemForm from '../../../models/systems/hotTemperatureSystem/hotTemperatureSystemForm.json';
import { IHotTemperatureSystemFormInterface } from '../../../models/systems/hotTemperatureSystem/hotTemperatureSystemFormInterface';

export const HEIGHT = 140;
export const WIDTH = 100;
export const PIPE_Y = 100;
export const TEMP_X = WIDTH / 2;

export const hotTemperatureMeta: SystemMeta = {
  systemType: SystemType.HotTemperature,
  name: 'Temperature Sensor',
  thumbnail,
  form: hotWaterTemperatureSystemForm,
  width: WIDTH,
  height: HEIGHT,
  calculateConnectionPoints: () => [
    {
      key: EConnectionPointPlacement.connectionPrimaryForward,
      x: 0,
      y: PIPE_Y,
      type: EConnectionType.supply,
      dotted: false,
    },
    {
      key: EConnectionPointPlacement.connectionSecondaryForward,
      x: WIDTH,
      y: PIPE_Y,
      type: EConnectionType.supply,
      dotted: false,
    },
  ],
};

export const getLabels = (): LabelPosition[] => {
  return [
    {
      id: EEquipment.T_F,
      x: TEMP_X,
      y: PIPE_Y - 45,
      horizontalAlignment: 'middle',
    },
  ];
};

export const HotTemperature: React.FC<BaseSystemProps<IHotTemperatureSystemFormInterface>> = React.memo(
  ({ state, values, alarm, selected }) => {
    return (
      <System state={state} meta={hotTemperatureMeta} alarm={alarm} selected={selected}>
        <Pipe x1={0} x2={WIDTH} y1={PIPE_Y} y2={PIPE_Y} type={PipeType.WarmSupply} />
        <TemperatureSensor x={WIDTH / 2} y={PIPE_Y} direction={Direction.EAST} />
        <Labels positions={getLabels()} values={values} />
      </System>
    );
  }
);
