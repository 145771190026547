import React from 'react';
import { ITextAreaQuestion } from '../../models/form.interface';
import { QuestionTitle } from './QuestionTitle';
import styled from 'styled-components';
import classnames from 'classnames';
import {useTextField} from "./TextQuestion";

export interface TextAreaQuestionProps {
  question: ITextAreaQuestion;
  data: any;
  onChange: (newData: any, oldData?: any) => void;
}

const StyledTextAreaQuestion = styled.div`
  textarea {
    box-sizing: border-box;
    width: 100%;
    padding: 12px;
    border: solid 2px #d3d9dd;
    background-color: #ffffff;
    font-family: 'GrundfosTheSansV2', sans-serif;

    &.error {
      border: solid 2px #ff0000;
    }
  }

  textarea[disabled] {
    background-color: #ddd !important;
    color: #aaa;
    cursor: not-allowed;
    pointer-events: all !important;
  }
`;

export const TextAreaQuestion: React.FC<TextAreaQuestionProps> = ({ question, data, onChange }) => {
  const [isInErrorState, isRequired, onInput, value, isDisabled, disabledTooltip] = useTextField({question, data, onChange});
  return (
    <StyledTextAreaQuestion>
      <QuestionTitle text={ question.title } hasError={ isInErrorState } isRequired={ isRequired } />
      <textarea
        rows={ 10 }
        name={ question.name }
        className={ classnames({ error: isInErrorState }) }
        onChange={ onInput }
        disabled={ isDisabled }
        title={ disabledTooltip }
        value={ value || '' } />
    </StyledTextAreaQuestion>
  );
};
