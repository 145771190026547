import { SchematicMode, SystemControlMode, TEquipmentValues } from './types';
import { AlarmsWarnings } from '../schematic/actions';
import { IBoilerSystemFormInterface } from '../models/systems/boilerSystem/boilerSystemFormInterface';
import { IMixitSystemFormInterface } from '../models/systems/mixitSystem/mixitSystemFormInterface';
import { IBoosterSystemFormInterface } from '../models/systems/boosterSystem/boosterSystemFormInterface';
import { IMixingloopSystemFormInterface } from '../models/systems/mixingloopSystem/mixingloopSystemFormInterface';
import { IBufferTankSystemFormInterface } from '../models/systems/bufferTankSystem/bufferTankSystemFormInterface';
import { ICascadeTankSystemFormInterface } from '../models/systems/cascadeTankSystem/cascadeTankSystemFormInterface';
import { IHotWaterTankSystemFormInterface } from '../models/systems/hotWaterTankSystem/hotWaterTankSystemFormInterface';
import { IHotWaterPumpSystemFormInterface } from '../models/systems/hotWaterPumpSystem/hotWaterPumpSystemFormInterface';
import { IColdWaterPumpSystemFormInterface } from '../models/systems/coldWaterPumpSystem/coldWaterPumpSystemFormInterface';
import { IHeatExchangingSystemFormInterface } from '../models/systems/heatExchangingSystem/heatExchangingSystemFormInterface';
import { IPumpStandaloneSystemFormInterface } from '../models/systems/pumpStandaloneSystem/pumpStandaloneSystemFormInterface';
import { IPressureSensorSystemFormInterface } from '../models/systems/pressureSensorSystem/pressureSensorSystemFormInterface';
import { IHotTemperatureSystemFormInterface } from '../models/systems/hotTemperatureSystem/hotTemperatureSystemFormInterface';
import { IUnspecifiedLoadSystemFormInterface } from '../models/systems/unspecifiedLoadSystem/unspecifiedLoadSystemFormInterface';
import { IColdTemperatureSystemFormInterface } from '../models/systems/coldTemperatureSystem/coldTemperatureSystemFormInterface';
import { IColdWaterSupplySystemFormInterface } from '../models/systems/coldWaterSupplySystem/coldWaterSupplySystemFormInterface';
import { ISimpleBufferTankSystemFormInterface } from '../models/systems/simpleBufferTankSystem/simpleBufferTankSystemFormInterface';
import { ICommercialHotWaterSystemFormInterface } from '../models/systems/commercialHotWaterSystem/commercialHotWaterSystemFormInterface';
import { IOutdoorTemperatureSystemFormInterface } from '../models/systems/outdoorTemperatureSystem/outdoorTemperatureSystemFormInterface';
import { IHeatmeterStandaloneSystemFormInterface } from '../models/systems/heatmeterStandaloneSystem/heatmeterStandaloneSystemFormInterface';
import { IDistrictHeatingSupplySystemFormInterface } from '../models/systems/districtHeatingSupplySystem/districtHeatingSupplySystemFormInterface';
import { IHotWaterTankStandAloneSystemFormInterface } from '../models/systems/hotWaterTankStandAloneSystem/hotWaterTankStandAloneSystemFormInterface';
import { ICascadeBufferTankChargingSystemFormInterface } from '../models/systems/cascadeBufferTankChargingSystem/cascadeBufferTankChargingSystemFormInterface';
import { ICommercialHotWaterStandaloneSystemFormInterface } from '../models/systems/commercialHotWaterStandaloneSystem/commercialHotWaterStandaloneSystemFormInterface';
import { IIndirectDistrictHeatingSupplySystemFormInterface } from '../models/systems/indirectDistrictHeatingSupplySystem/indirectDistrictHeatingSupplySystemFormInterface';
import { ITwoStrokeHeatExchangerHotWaterSystemFormInterface } from '../models/systems/twoStrokeHeatExchangerHotWaterSystem/twoStrokeHeatExchangerHotWaterSystemFormInterface';

export enum SystemType {
  DistrictHeatingSupply = 'DistrictHeatingSupplySystem',
  MixingLoop = 'MixingloopSystem',
  MixingloopUnspecifiedLoadSystem = 'MixingloopUnspecifiedLoadSystem',
  CommercialHotWater = 'CommercialHotWaterSystem',
  CommercialHotWaterStandalone = 'CommercialHotWaterStandaloneSystem',
  HeatExchanger = 'HeatExchangingSystem',
  TwoStrokeHeatExchangerHotWaterSystem = 'TwoStrokeHeatExchangerHotWaterSystem',
  Boiler = 'BoilerSystem',
  OutdoorTemperature = 'OutdoorTemperatureSystem',
  HotWaterPump = 'HotWaterPumpSystem',
  ColdWaterPump = 'ColdWaterPumpSystem',
  HotWaterTank = 'HotWaterTankSystem',
  Mixit = 'MixitSystem',
  UnspecifiedLoad = 'UnspecifiedLoadSystem',
  CorePumpSystem = 'CorePumpSystem',
  HotTemperature = 'HotTemperatureSystem',
  ColdTemperature = 'ColdTemperatureSystem',
  BufferTank = 'BufferTankSystem',
  PumpStandalone = 'PumpStandaloneSystem',
  HeatmeterStandalone = 'HeatmeterStandaloneSystem',
  SimpleBufferTankSystem = 'SimpleBufferTankSystem',
  HotWaterTankStandAloneSystem = 'HotWaterTankStandAloneSystem',
  ColdWaterSupplySystem = 'ColdWaterSupplySystem',
  BoosterSystem = 'BoosterSystem',
  PressureSensorSystem = 'PressureSensorSystem',
  CascadeTankSystem = 'CascadeTankSystem',
  IndirectDistrictHeatingSupplySystem = 'IndirectDistrictHeatingSupplySystem',
  CascadeBufferTankChargingSystem = 'CascadeBufferTankChargingSystem',
}

export interface SchematicPosition {
  x: number;
  y: number;
}

export interface SystemBaseState<T> {
  hasId: string;
  configured: boolean;
  top: number;
  left: number;
  systemInfo: T;

  systemControlMode?: SystemControlMode;
  pasteurizationState?: boolean;
  warmWeatherShutdown?: boolean;
  showOutdoorTemperatureSensor?: boolean;

  subSystems?: SystemState[];
}

export interface DistrictHeatingSystemState extends SystemBaseState<IDistrictHeatingSupplySystemFormInterface> {
  type: SystemType.DistrictHeatingSupply;
}

export interface MixingLoopSystemState extends SystemBaseState<IMixingloopSystemFormInterface> {
  type: SystemType.MixingLoop;
}

export interface MixingLoopUnspecifiedLoadSystemState extends SystemBaseState<IMixingloopSystemFormInterface> {
  type: SystemType.MixingloopUnspecifiedLoadSystem;
}

export interface CommercialHotWaterSystemState extends SystemBaseState<ICommercialHotWaterSystemFormInterface> {
  type: SystemType.CommercialHotWater;
}

export interface CommercialHotWaterStandaloneSystemState extends SystemBaseState<ICommercialHotWaterStandaloneSystemFormInterface> {
  type: SystemType.CommercialHotWaterStandalone;
}

export interface HeatExchangerSystemState extends SystemBaseState<IHeatExchangingSystemFormInterface> {
  type: SystemType.HeatExchanger;
}

export interface BoilerSystemState extends SystemBaseState<IBoilerSystemFormInterface> {
  type: SystemType.Boiler;
}

export interface OutdoorTemperatureSystemState extends SystemBaseState<IOutdoorTemperatureSystemFormInterface> {
  type: SystemType.OutdoorTemperature;
}

export interface HotWaterPumpSystemState extends SystemBaseState<IHotWaterPumpSystemFormInterface> {
  type: SystemType.HotWaterPump;
}

export interface ColdWaterPumpSystemState extends SystemBaseState<IColdWaterPumpSystemFormInterface> {
  type: SystemType.ColdWaterPump;
}

export interface HotWaterTankSystemState extends SystemBaseState<IHotWaterTankSystemFormInterface> {
  type: SystemType.HotWaterTank;
}

export interface MixitSystemState extends SystemBaseState<IMixitSystemFormInterface> {
  type: SystemType.Mixit;
}

export interface TwoStrokeHeatExchangerHotWaterSystem extends SystemBaseState<ITwoStrokeHeatExchangerHotWaterSystemFormInterface> {
  type: SystemType.TwoStrokeHeatExchangerHotWaterSystem;
}

export interface UnspecifiedLoadSystem extends SystemBaseState<IUnspecifiedLoadSystemFormInterface> {
  type: SystemType.UnspecifiedLoad;
}

export interface HotTemperatureSystem extends SystemBaseState<IHotTemperatureSystemFormInterface> {
  type: SystemType.HotTemperature;
}

export interface ColdTemperatureSystem extends SystemBaseState<IColdTemperatureSystemFormInterface> {
  type: SystemType.ColdTemperature;
}

export interface BufferTankSystemState extends SystemBaseState<IBufferTankSystemFormInterface> {
  type: SystemType.BufferTank;
}

export interface PumpStandaloneSystemState extends SystemBaseState<IPumpStandaloneSystemFormInterface> {
  type: SystemType.PumpStandalone;
}

export interface HeatmeterStandaloneSystemState extends SystemBaseState<IHeatmeterStandaloneSystemFormInterface> {
  type: SystemType.HeatmeterStandalone;
}

export interface SimpleBufferTankSystemState extends SystemBaseState<ISimpleBufferTankSystemFormInterface> {
  type: SystemType.SimpleBufferTankSystem;
}

export interface HotWaterTankStandAloneSystemState extends SystemBaseState<IHotWaterTankStandAloneSystemFormInterface> {
  type: SystemType.HotWaterTankStandAloneSystem;
}

export interface ColdWaterSupplySystemState extends SystemBaseState<IColdWaterSupplySystemFormInterface> {
  type: SystemType.ColdWaterSupplySystem;
}

export interface BoosterSystemState extends SystemBaseState<IBoosterSystemFormInterface> {
  type: SystemType.BoosterSystem;
}

export interface PressureSensorSystemState extends SystemBaseState<IPressureSensorSystemFormInterface> {
  type: SystemType.PressureSensorSystem;
}

export interface CascadeTankSystemState extends SystemBaseState<ICascadeTankSystemFormInterface> {
  type: SystemType.CascadeTankSystem;
}

export interface IndirectDistrictHeatingSupplySystemState extends SystemBaseState<IIndirectDistrictHeatingSupplySystemFormInterface> {
  type: SystemType.IndirectDistrictHeatingSupplySystem;
}

export interface CascadeBufferTankChargingSystemState extends SystemBaseState<ICascadeBufferTankChargingSystemFormInterface> {
  type: SystemType.CascadeBufferTankChargingSystem;
}

export type SystemState =
  | DistrictHeatingSystemState
  | MixingLoopSystemState
  | MixingLoopUnspecifiedLoadSystemState
  | CommercialHotWaterSystemState
  | CommercialHotWaterStandaloneSystemState
  | HeatExchangerSystemState
  | BoilerSystemState
  | OutdoorTemperatureSystemState
  | HotWaterPumpSystemState
  | ColdWaterPumpSystemState
  | HotWaterTankSystemState
  | MixitSystemState
  | TwoStrokeHeatExchangerHotWaterSystem
  | UnspecifiedLoadSystem
  | BufferTankSystemState
  | PumpStandaloneSystemState
  | HeatmeterStandaloneSystemState
  | SimpleBufferTankSystemState
  | HotWaterTankStandAloneSystemState
  | ColdWaterSupplySystemState
  | BoosterSystemState
  | PressureSensorSystemState
  | CascadeTankSystemState
  | IndirectDistrictHeatingSupplySystemState
  | CascadeBufferTankChargingSystemState;

export interface DraggingSystemState {
  systemId: string;
  offsetX: number;
  offsetY: number;
}

export interface FreeConnectionPointState {
  hasId: string;
  x: number;
  y: number;
  type: EConnectionType;
  dotted: boolean;
}

// system connection points are the connection points that are attached to a system
export interface SystemConnectionPointIdentifier {
  type: 'SYSTEM';
  connectionPointType: EConnectionType;
  systemId: string;
  key: string;
  dotted: boolean;
}

export interface FreeConnectionPointIdentifier {
  type: 'FREE';
  connectionPointType: EConnectionType;
  id: string;
  dotted: boolean;
}

export type ConnectionPointIdentifier = SystemConnectionPointIdentifier | FreeConnectionPointIdentifier;

export const isSystemConnectionPoint = (
  point: SystemConnectionPointIdentifier | FreeConnectionPointIdentifier
): point is SystemConnectionPointIdentifier => {
  return point.type === 'SYSTEM';
};

export interface ConnectionState {
  id: string;
  dotted: boolean;
  from: ConnectionPointIdentifier;
  to: ConnectionPointIdentifier;
}

export interface SchematicState {
  alarmsWarnings: AlarmsWarnings[];
  systems: SystemState[];
  points: FreeConnectionPointState[];
  connections: ConnectionState[];
  mode: SchematicMode;
  inputStrategyName: string;
  values: TEquipmentValues[];
  selectedSystemId: string | null;
  connectionBeingCreated: ConnectionBeingCreatedState | null;
  connectionPointBeingDragged: { id: string; } | null;
  draggingSystemState: DraggingSystemState | null;
}

export interface ConnectionBeingCreatedState {
  from: ConnectionPointIdentifier;
  toX: number;
  toY: number;
}

export enum EConnectionType {
  supply = 'Supply',
  return = 'Return',
}

export interface ConnectionBeingCreated {
  from: ConnectionPointIdentifier;
  toX: number;
  toY: number;
}

export interface VisualState {
  systemControlMode?: SystemControlMode;
  pasteurization?: boolean;
}
