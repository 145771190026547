import { Component, Input, OnInit } from '@angular/core';
import { AlarmOverview, Application } from '../../../interfaces/alarm';
import { DataPointsResult } from '../../../interfaces/data-points';
import { Observable } from 'rxjs';
import { AppModel } from '@ams/dumbledore';
import { Installation } from '../../../interfaces/facilty';

@Component({
  selector: 'app-district-heating-dashboard',
  templateUrl: './district-heating-dashboard.component.html',
  styleUrls: ['./district-heating-dashboard.component.scss'],
})
export class DistrictHeatingDashboardComponent {
  @Input() public alarm$: Observable<AlarmOverview>;
  @Input() public application: Application;
  @Input() public installation$: Observable<Installation>;
  @Input() public schematic$: Observable<AppModel>;
  @Input() public dataPoints$: Observable<DataPointsResult>;
}
