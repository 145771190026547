import React from 'react';
import { Labels } from '../../Labels';
import { Direction } from '../../Common';
import { PumpIcon } from '../../PumpIcon';
import { Pipe, PipeType } from '../../Pipe';
import { SupplyTriangle } from '../../SupplyTriangle';
import { System, BaseSystemProps } from '../../System';
import * as metaData from './commercial-hot-water-standalone-metadata';
import { BathAndFaucet } from '../../heat-exchanger-common/BathAndFaucet';
import { FORWARD_Y, RETURN_Y } from '../../heat-exchanger-common/heat-exchanger-common-metadata';
import { ICommercialHotWaterStandaloneSystemFormInterface } from '../../../../models/systems/commercialHotWaterStandaloneSystem/commercialHotWaterStandaloneSystemFormInterface';

export const CommercialHotWaterStandaloneSystem: React.FC<BaseSystemProps<ICommercialHotWaterStandaloneSystemFormInterface>> = React.memo(
  ({ state, values, alarm, selected }) => {
    const systemInfo = state.systemInfo as ICommercialHotWaterStandaloneSystemFormInterface;
    const showPump = !!systemInfo.P_hasPump;
    const isHotReturn = systemInfo.hotReturn;
    return (
      <System state={state} meta={metaData.commercialHotWaterStandaloneMeta} alarm={alarm} selected={selected}>
        <Pipe
          key="secondaryForward"
          x1={metaData.COLD_WATER_X}
          y1={FORWARD_Y}
          x2={metaData.SECONDARY_LOOP_RIGHT}
          y2={FORWARD_Y}
          type={PipeType.WarmSupply}
        />

        <Pipe
          key="secondaryReturn"
          x1={metaData.COLD_WATER_X}
          y1={RETURN_Y}
          x2={metaData.SECONDARY_LOOP_RIGHT}
          y2={RETURN_Y}
          type={isHotReturn ? PipeType.WarmReturn : PipeType.ColdReturn}
        />

        <Pipe
          key="secondaryVertical"
          x1={metaData.SECONDARY_LOOP_RIGHT}
          y1={FORWARD_Y}
          x2={metaData.SECONDARY_LOOP_RIGHT}
          y2={RETURN_Y}
          type={isHotReturn ? PipeType.WarmReturn : PipeType.ColdReturn}
        />

        {!isHotReturn && (
          <>
            <Pipe
              key="coldWatersupplyVertical"
              x1={metaData.PIPE_VERTICAL_X}
              y1={RETURN_Y}
              x2={metaData.PIPE_VERTICAL_X}
              y2={RETURN_Y + metaData.COLD_WATER_VERTICAL_LENGTH}
              type={PipeType.ColdSupply}
            />
            <Pipe
              key="coldWaterSupplyHorizontal"
              x1={0}
              y1={RETURN_Y + metaData.COLD_WATER_VERTICAL_LENGTH}
              x2={metaData.PIPE_VERTICAL_X}
              y2={RETURN_Y + metaData.COLD_WATER_VERTICAL_LENGTH}
              type={PipeType.ColdSupply}
            />
            <SupplyTriangle
              warm={false}
              x={metaData.SUPPLY_TRIANGLE_X}
              y={RETURN_Y + metaData.COLD_WATER_VERTICAL_LENGTH}
              direction={Direction.EAST}
            />
          </>
        )}

        <BathAndFaucet x={metaData.SECONDARY_LOOP_RIGHT} y={FORWARD_Y} />
        {showPump && <PumpIcon direction={Direction.WEST} x={metaData.PUMP_X} y={RETURN_Y} />}

        <Labels positions={metaData.getCommercialHotWaterStandaloneLabels(systemInfo)} values={values} />
      </System>
    );
  }
);
