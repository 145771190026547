<gbc-page-container *ngIf="dashboardData$ | async as dashboardData">
  <div class="section-wrapper">
    <ng-container *ngIf="shouldShowDashboard(dashboardData)">
      <div>{{'energy-management-dashboard.period-from-to' | translate: { fromDate: dashboardData.periodFrom | date, toDate: dashboardData.periodTo | date } }}</div>
      <div class="adjust-settings"
        (click)="showSettingsDialog()"
        role="button"
        [ngClass]="{ 'disabled': isShowingSettingsDialog }"
        angulartics2On="click"
        angularticsAction="edbSettingsClicked"
        angularticsCategory="EDB">
        <img src="./assets/svgs/adjust-outline.svg">
        <span>{{'energy-management-dashboard.settings-button'| translate}}</span>
      </div>

      <app-energy-management-dashboard-section id="totalDistrictHeating" humanReadableId="TotalDistrictHeating" *ngIf="dashboardData.totalDistrictHeating && (dashboardData.totalDistrictHeating.kpis.length > 0 || dashboardData.totalDistrictHeating.healthChecks.length > 0)" [installationId]="dashboardData.installationId" [sectionData]="dashboardData.totalDistrictHeating" [healthCheckTargetKey]="healthCheckTargetKey.TotalDistrictHeating">
        <header class="section-header" section-header>
          <img src="./assets/svgs/heat_outline.svg" />
          <h3>{{ 'energy-management-dashboard.totalDistricHeating' | translate }}</h3>
        </header>
      </app-energy-management-dashboard-section>
      <app-energy-management-dashboard-section id="spaceHeating" humanReadableId="SpaceHeating" *ngIf="dashboardData.spaceHeating && (dashboardData.spaceHeating.kpis.length > 0 || dashboardData.spaceHeating .healthChecks.length > 0)" [installationId]="dashboardData.installationId" [sectionData]="dashboardData.spaceHeating" [healthCheckTargetKey]="healthCheckTargetKey.SpaceHeating">
        <header class="section-header" section-header>
          <img src="./assets/svgs/radiator_outline.svg" />
          <h3>{{ 'energy-management-dashboard.spaceHeating' | translate }}</h3>
        </header>
      </app-energy-management-dashboard-section>
      <app-energy-management-dashboard-section id="domesticHotWater" humanReadableId="DomesticHotWater" *ngIf="dashboardData.domesticHotWater && (dashboardData.domesticHotWater.kpis.length > 0 || dashboardData.domesticHotWater.healthChecks.length > 0)" [installationId]="dashboardData.installationId" [sectionData]="dashboardData.domesticHotWater" [healthCheckTargetKey]="healthCheckTargetKey.DomesticHotWater">
        <header class="section-header" section-header>
          <img src="./assets/svgs/water-tap-high_outline.svg" />
          <h3>{{ 'energy-management-dashboard.domesticHotWater' | translate }}</h3>
        </header>
      </app-energy-management-dashboard-section>
    </ng-container>

    <ng-container *ngIf="!shouldShowDashboard(dashboardData)">
      <div>This installation does not currently support Energy Dashboards.</div>
      <div>To enable the Energy Dashboard, heatmeters need to be connected to BuildingConnect</div>
    </ng-container>

  </div>
</gbc-page-container>
