<gbc-modal (dismiss)="dismiss()" [title]="'valve-modal.title' | translate" class="gbc-large gbc-with-dividers" data-test-id="valve-modal">
  <app-control-state-toggle
    [currentApplicationControlMode]="currentSystemControlMode"
    (selectedApplicationControlMode)="selectApplicationControlMode($event)">
  </app-control-state-toggle>
  <gbc-modal-content>
    <p class="modal-text">{{ 'valve-modal.description' | translate }}</p>
    <form [formGroup]="form">
      <gbc-modal-section data-test-id="valve-modal-setpoint-container">
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'valve-modal.fields.opening-setpoint' | translate }}</gbc-input-label>
          <img pTooltip="{{ 'valve-modal.fields.opening-setpoint-tooltip' | translate }}" escape class="question-mark" src="./assets/svgs/question-mark.svg" data-test-id="valve-modal-opening-setpoint-tooltip" />
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-field
            [min]="min"
            [max]="max"
            [errorDescriptionData]="{ min: min, max: max }"
            errorDescriptionTranslationString="valve-modal.validation-errors.between"
            tooltipPosition="top"
            disabledToolTipText="{{ 'valve-modal.disabled-setpoint-tooltip' | translate }}"
            formControlName="openingSetpoint"
            step="1"
            inputType="number"
            unit="%">
          </gbc-input-field>
        </gbc-modal-section-column>
      </gbc-modal-section>
    </form>
  </gbc-modal-content>
  <gbc-modal-buttons>
    <button gbc-modal-button (click)="close()" data-test-id="cancel-button" angulartics2On="click" angularticsAction="cancelClicked" angularticsCategory="valveSetpointDialog">
      {{ 'app-cancel' | translate }}
    </button>
    <button
      gbc-modal-button
      (click)="save()"
      data-test-id="save-button"
      [disabled]="(isValid$ | async) === false || selectedSystemControlMode === SystemControlMode.Unknown"
      class="primary"
      angulartics2On="click"
      angularticsAction="saveClicked"
      angularticsCategory="valveSetpointDialog">
      {{ 'app-save' | translate }}
    </button>
  </gbc-modal-buttons>
</gbc-modal>
