<ng-container>
  <gbc-breadcrumb [pageInfo]="pageInfo"></gbc-breadcrumb>
  <gbc-stepper
    #stepper
    linear
    (done)="done()"
    [nextDisabled]="(canContinue$ | async) === false"
    [doneDisabled]="!licenseInfo.value.termsOfUse || !licenseInfo.value.termsAndConditions ||  !licenseInfo.valid"
    [backLabel]="'app-back' | translate"
    [nextLabel]="'app-next' | translate"
    [doneLabel]="'app-done' | translate"
  >
    <cdk-step [label]="'upgrade-flow.unit-selection' | translate">
      <gbc-tile>
        <app-content-tile
          [preTitle]="'trial-flow-page.content-pre-title' | translate"
          [title]="'trial-flow-page.content-title' | translate"
        >
          <figure>
            <img src="./assets/svgs/bc-professional.svg" />
          </figure>
          <p class="main-message">
            <strong>{{ 'trial-flow-page.content-paragraph-bold' | translate: { trialPeriod: MixitTrialPeriod } }}</strong>
          </p>
          <p>{{ 'trial-flow-page.content-paragraph-regular' | translate }}</p>
          <p class="benefits">{{ 'license-upgrade.see-benefits' | translate }}</p>
          <ul>
            <li>
              <span>{{ 'building-connect.benefit-1' | translate }}</span>
            </li>
            <li>
              <span>{{ 'building-connect.benefit-2' | translate }}</span>
            </li>
            <li>
              <span>{{ 'building-connect.benefit-3' | translate }}</span>
            </li>
          </ul>
        </app-content-tile>
      </gbc-tile>
      <gbc-tile [title]="'trial-flow-page.units-upgrade-tile-title' | translate">
        <app-mixit-installation-selector
          [preSelectedInstallation]="preSelectedInstallation"
          [facilities$]="facilities$"
          (installationsSelect)="onSelectInstallations($event)"
        ></app-mixit-installation-selector>
      </gbc-tile>
    </cdk-step>
    <cdk-step [label]="'upgrade-flow.customer-information' | translate">
      <form [formGroup]="licenseInfo">
        <gbc-tile [title]="'trial-flow-page.company-info-tile-title' | translate" *ngIf="currentUser$ | async as user">
          <gbc-form-container>
            <gbc-form-row [header]="'license-info.name' | translate" required="true">
              <gbc-input-field formControlName="companyName"></gbc-input-field>
            </gbc-form-row>
            <gbc-form-row [header]="'license-info.address' | translate" required="true">
              <gbc-input-field formControlName="street"></gbc-input-field>
            </gbc-form-row>
            <gbc-form-row [header]="'license-info.postal-code' | translate" required="true">
              <gbc-input-field formControlName="postalCode"></gbc-input-field>
            </gbc-form-row>
            <gbc-form-row [header]="'license-info.city' | translate" required="true">
              <gbc-input-field formControlName="city"></gbc-input-field>
            </gbc-form-row>
            <gbc-form-row [header]="'license-info.country' | translate" required="true">
              <gbc-input-field formControlName="country"></gbc-input-field>
            </gbc-form-row>
            <gbc-form-row
              [header]="'license-info.region' | translate"
              [description]="'license-info.region-applicable-footnote' | translate"
            >
              <gbc-input-field formControlName="region"></gbc-input-field>
            </gbc-form-row>
          </gbc-form-container>
        </gbc-tile>
        <gbc-tile [title]="'trial-flow-page.account-manager-details-tile-title' | translate" *ngIf="currentUser$ | async as user">
          <gbc-form-container>
            <gbc-form-row [header]="'license-info.full-name' | translate">
              <gbc-input-field formControlName="fullName"></gbc-input-field>
            </gbc-form-row>
            <gbc-form-row [header]="'license-info.phone-number' | translate">
              <gbc-input-field formControlName="phoneNumber"></gbc-input-field>
            </gbc-form-row>
            <gbc-form-row [header]="'license-info.email' | translate">
              <gbc-input-field formControlName="email"></gbc-input-field>
            </gbc-form-row>
          </gbc-form-container>
        </gbc-tile>
        <gbc-tile [title]="'trial-flow-page.terms-tile-title' | translate" *ngIf="currentUser$ | async as user">
          <gbc-form-container>
            <gbc-form-row [header]="'license-info.terms-of-use' | translate" required="true">
              <div class="p-field-checkbox">
                <p-checkbox
                  [formControl]="$any(licenseInfo).controls['termsOfUse']"
                  binary="true"
                  id="terms-of-use"
                ></p-checkbox>
                <label for="terms-of-use">
                  {{ 'license-info.terms-of-use-text' | translate }}
                  <a [href]="TERMS_OF_USE_LINK" target="_blank">{{ 'license-info.terms-of-use-link' | translate }}</a></label
                >
              </div>
            </gbc-form-row>
            <gbc-form-row [header]="'license-info.terms-&-conditions' | translate" required="true">
              <div class="p-field-checkbox">
                <p-checkbox
                  [formControl]="$any(licenseInfo).controls['termsAndConditions']"
                  binary="true"
                  id="terms-and-conditions"
                ></p-checkbox>
                <label for="terms-and-conditions">
                  {{ 'license-info.terms-&-conditions-text' | translate }}
                  <a [href]="TERMS_AND_CONDITIONS_LINK" target="_blank">{{ 'license-info.terms-&-conditions-link' | translate }}</a></label
                >
              </div>
            </gbc-form-row>
            <gbc-form-row class='over-sized-text' [header]="'license-info.other' | translate">
              <div class="p-field-checkbox">
                <p-checkbox [formControl]="$any(licenseInfo).controls['other']" binary="true" id="other"></p-checkbox>
                <label for="other">
                  {{ 'license-info.other-text' | translate }}
                </label>
              </div>
            </gbc-form-row>
          </gbc-form-container>
        </gbc-tile>
      </form>
    </cdk-step>
  </gbc-stepper>
</ng-container>
