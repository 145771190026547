import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DataPointsResult, DataPointState, DeviceDataPoint } from '../../interfaces/data-points';
import { Value } from '../multi-value-tile/multi-value-tile.component';
import { map } from 'rxjs/operators';
import { AppModel, EEquipment, SystemState } from '@ams/dumbledore';
import { formatDataPoint, formatDataPointValue, getDataOutdatedState } from '../../utils/data-point-utils';
import { Application } from '../../interfaces/alarm';
import { IDistrictHeatingSupplySystemFormInterface } from '../../../../../dumbledore/src/models/systems/districtHeatingSupplySystem/districtHeatingSupplySystemFormInterface';
import { Installation } from '../../interfaces/facilty';
import { SystemDeviceType } from '../../interfaces/systemDeviceType';

@Component({
  selector: 'app-heat-meter-status-tile',
  templateUrl: './heat-meter-status-tile.component.html',
  styleUrls: ['./heat-meter-status-tile.component.scss'],
})
export class HeatMeterStatusTileComponent implements OnInit {
  @Input() public application: Application;
  @Input() public schematic$: Observable<AppModel>;
  @Input() public dataPoints$: Observable<DataPointsResult>;
  @Input() public installation$: Observable<Installation>;
  @Input() public forwardTemperature: EEquipment;
  @Input() public returnTemperature: EEquipment;

  public hasHeatMeter$: Observable<boolean>;
  public lastUpdated$: Observable<number>;
  public values$: Observable<Value[]>;

  ngOnInit(): void {
    this.hasHeatMeter$ = this.schematic$.pipe(
      map((schematic) => {
        const system = schematic.systems.find((s) => s.hasId === this.application.id) as SystemState;
        const systemInfo = system.systemInfo as IDistrictHeatingSupplySystemFormInterface;
        return systemInfo.HM_present || false;
      })
    );

    const heatMeterSystem$ = this.dataPoints$.pipe(
      map((dataPointsResponse) => dataPointsResponse?.data.find((s) => s.systemId === this.application.id))
    );

    this.lastUpdated$ = this.dataPoints$.pipe(map((dataPointsResponse) => dataPointsResponse?.heartBeatTimestamp));

    this.values$ = heatMeterSystem$.pipe(
      map((system) => {
        /* A heat meter and a flow meter both have volume and flow datapoints. To make sure we use the right ones,
           we'll only read those particular datapoints from a device of type SystemDeviceType.HeatMeter. */
        const heatMeterDataPoints = system?.devices?.find((device) => device.type === SystemDeviceType.HeatMeter)?.dataPoints;
        const otherDataPoints = system?.devices
          ?.filter((device) => device.type !== SystemDeviceType.HeatMeter)
          .flatMap((device) => device.dataPoints);
        const tf = otherDataPoints?.find((dataPoint) => {
          if (this.forwardTemperature) {
            return dataPoint.dumbledoreId === this.forwardTemperature;
          }
          return [EEquipment.T_PF, EEquipment.T_F].includes(dataPoint.dumbledoreId);
        });
        const tr = otherDataPoints?.find((dataPoint) => {
          if (this.returnTemperature) {
            return dataPoint.dumbledoreId === this.returnTemperature;
          }
          return [EEquipment.T_PR, EEquipment.T_R].includes(dataPoint.dumbledoreId);
        });
        const flow = heatMeterDataPoints?.find((dataPoint) => [EEquipment.Q_PF, EEquipment.Q_PR].includes(dataPoint.dumbledoreId));
        const pow = heatMeterDataPoints?.find((dataPoint) => dataPoint.dumbledoreId === EEquipment.POW);
        const vol = heatMeterDataPoints?.find((dataPoint) => dataPoint.dumbledoreId === EEquipment.VOL);
        const e = heatMeterDataPoints?.find((dataPoint) => dataPoint.dumbledoreId === EEquipment.E);
        const tfNumber = typeof tf?.value === 'string' ? Number.parseFloat(tf.value) : null;
        const trNumber = typeof tr?.value === 'string' ? Number.parseFloat(tr.value) : null;
        const deltaT = typeof tfNumber === 'number' && typeof trNumber === 'number' ? tfNumber - trNumber : null;
        const deltaTOutdated = tf?.state === DataPointState.NotSynced || tr?.state === DataPointState.NotSynced;
        const deltaTValue = typeof deltaT === 'number' && !deltaTOutdated ? formatDataPointValue(`${deltaT}`, 1, '°C') : '-';

        const values: Value[] = [
          {
            titleKey: 'heat-meter-tile.power',
            value: formatDataPoint(pow),
            state: getDataOutdatedState(pow)
          },
          {
            titleKey: 'heat-meter-tile.flow',
            value: formatDataPoint(flow),
            state: getDataOutdatedState(flow)
          },
          {
            titleKey: 'heat-meter-tile.forward-temp',
            value: formatDataPoint(tf),
            state: getDataOutdatedState(tf)
          },
          {
            titleKey: 'heat-meter-tile.return-temp',
            value: formatDataPoint(tr),
            state: getDataOutdatedState(tr)
          },
          {
            titleKey: 'heat-meter-tile.delta-t',
            value: deltaTValue,
            state: deltaTOutdated ? DataPointState.NotSynced : undefined
          },
          {
            titleKey: 'heat-meter-tile.energy',
            value: formatDataPoint(e),
            state: getDataOutdatedState(e)
          },
          {
            titleKey: 'heat-meter-tile.volume',
            value: formatDataPoint(vol),
            state: getDataOutdatedState(vol)
          },
        ];
        return values;
      })
    );
  }
}
