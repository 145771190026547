import { Component, Input, OnInit } from '@angular/core';
import { getDatapoint, setpoint, setPointSource } from '../../../utils/mixit-utils';
import { DataPointsResult, DeviceDataPoint, EBackendUnit } from '../../../interfaces/data-points';
import { MixitDataPointName, SetpointSource } from '../../../interfaces/mixit';
import { first, map, switchMap } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { MixitSetpointModalComponent, MixitSetpointModalInput } from '../mixit-setpoint-modal/mixit-setpoint-modal.component';
import { SchematicsService } from '../../../services/schematics.service';
import { Application } from '../../../interfaces/alarm';
import { AppModel } from '@ams/dumbledore';
import { Installation } from '../../../interfaces/facilty';
import { ModalService } from 'shared';
import { TranslateService } from '@ngx-translate/core';
import { MixitService } from '../../../services/mixit-service.service';
import { SystemDeviceType } from '../../../interfaces/systemDeviceType';

@Component({
  selector: 'app-mixit-temperature-tile',
  templateUrl: './mixit-temperature-tile.component.html',
  styleUrls: ['./mixit-temperature-tile.component.scss'],
})
export class MixitTemperatureTileComponent implements OnInit {
  @Input() installation$: Observable<Installation>;
  @Input() schematic$: Observable<AppModel>;
  @Input() dataPoints$: Observable<DataPointsResult>;
  @Input() application$: Observable<Application>;

  public setpoint$: Observable<DeviceDataPoint | null>;
  public localSetpoint$: Observable<DeviceDataPoint | null>;
  public isLocalSetPoint$: Observable<boolean>;
  public setpointSource$: Observable<string>;

  private isAdjustmentsInProgress = false;

  constructor(
    private schematicService: SchematicsService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private mixitService: MixitService
  ) {}

  ngOnInit(): void {
    this.setpoint$ = setpoint(this.dataPoints$);
    this.setpointSource$ = setPointSource(this.dataPoints$);
    this.isLocalSetPoint$ = this.setpointSource$.pipe(map((d) => d === SetpointSource.MixingLoopSetpointSourceLocal));
    this.localSetpoint$ = getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.LocalSetpoint);
  }

  public adjustTemperatureSetpoint() {
    if (this.isAdjustmentsInProgress) { return; }
    this.isAdjustmentsInProgress = true;

    const setpointMeta$ = this.application$.pipe(
      map((application) => application.id),
      switchMap((id) => this.schematicService.getEquipmentMetaInfo(id)),
      map((metas) => {
        const meta = metas.find((m) => m.term === MixitDataPointName.LocalSetpoint);
        // TODO: Remove once implemented
        if (!meta) {
          return { min: 0, max: 100 };
        }
        return meta;
      })
    );

    combineLatest([this.installation$, this.application$, this.isLocalSetPoint$, this.localSetpoint$, setpointMeta$, this.setpointSource$])
      .pipe(
        first(),
        map(([installation, application, isLocalSetpoint, localSetpoint, setpointMeta, setpointSource]) => {
          if (!isLocalSetpoint) {
            let textKey;

            switch (setpointSource) {
              case SetpointSource.MixingLoopSetpointSourceAnalogueInput:
                textKey = 'mixit-premium-dashboard.setpoint-not-controllable-analogue';
                break;
              case SetpointSource.MixingLoopSetpointSourceOutdoorTemperatureAnalogueInput:
                textKey = 'mixit-premium-dashboard.setpoint-not-controllable-outdoor-temp';
                break;
              case SetpointSource.MixingLoopSetpointSourceFieldbus:
              case SetpointSource.MixingLoopSetpointSourceOutdoorTemperatureFieldbus:
                textKey = 'mixit-premium-dashboard.setpoint-not-controllable-fieldbus';
                break;
              default:
                textKey = 'mixit-premium-dashboard.setpoint-not-controllable-description';
                break;
            }

            this.modalService.showTextModal({
              title: this.translateService.instant('mixit-premium-dashboard.setpoint-not-controllable-title'),
              content: this.translateService.instant(textKey),
              actions: [
                {
                  text: this.translateService.instant('app-cancel'),
                  cancel: true,
                },
              ],
            }).subscribe(() => {
              this.isAdjustmentsInProgress = false;
            });

            return;
          }

          const data: MixitSetpointModalInput = {
            temperatureSetPoint: parseFloat(localSetpoint?.value as string) || null,
            max: setpointMeta.max,
            min: setpointMeta.min,
          };

          this.modalService
            .openDialog<MixitSetpointModalInput>(MixitSetpointModalComponent, { data })
            .subscribe((response) => {
              if (response.dismissed) {
                this.isAdjustmentsInProgress = false;
                return;
              }

              const res = response.result;

              this.mixitService.sendCommand(installation, (application as Application).id, [
                {
                  term: MixitDataPointName.LocalSetpoint,
                  value: res.temperatureSetPoint?.toString() || null,
                  unit: EBackendUnit.DegreeCelsius,
                },
              ]);

              this.isAdjustmentsInProgress = false;
            });
        })
      )
      .subscribe();
  }
}
