import { ILocation } from '../../../../shared/src/lib/interfaces/location';
import { ICompany } from '../../../../shared/src/lib/interfaces/company';
import { IReducedInstallationDTO } from './installation';
import { EArchiveState } from './archiveState';

export enum EFacilityType {
  BuildingConnect = 'BuildingConnect',
  MIXIT = 'MIXIT',
  ConnectingCore = 'ConnectingCore',
}

export interface IPutPatchFacility extends Omit<IFacility, 'archiveState'> { }

export interface IFacility {
  id?: number;
  name: string;
  facilityType: EFacilityType;
  location: ILocation;
  address?: string;
  addressRoad: string;
  addressPostal: string;
  addressCity: string;
  company: ICompany;
  installations: IReducedInstallationDTO[];
  archiveState: EArchiveState;
}
