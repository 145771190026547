import React from 'react';
import { colors } from '../../util/colors';

export interface ValueLabelProps {
  id?: string;
  value?: string;
  setpoint?: string;
  x: number;
  y: number;
  alignment?: 'start' | 'middle' | 'end';
}

export const FONT_SIZE = 14;
export const LINE_HEIGHT = 14;

export const ValueLabel: React.FC<ValueLabelProps> = React.memo<ValueLabelProps>(({ id, value, setpoint, x, y, alignment }) => {
  if (!value && !setpoint) {
    return null;
  }
  const nonSetpointValues = value ? value.split('\n') : [];
  const texts = nonSetpointValues.map((v) => ({ type: 'value', text: v }));
  if (setpoint) {
    texts.push({ type: 'setpoint', text: setpoint });
  }
  const tspans = texts.map((text, idx) => {
    return (
      <tspan
        key={idx}
        y={LINE_HEIGHT * idx}
        x="0"
        style={{
          fontWeight: text.type === 'value' ? 600 : 400,
          fill: text.type === 'value' ? colors.textColor : colors.setPointTextColor,
        }}
      >
        {text.text}
      </tspan>
    );
  });
  const verticalOffset = (tspans.length / 2) * LINE_HEIGHT - 2;
  return (
    <text
      key={id}
      transform={`translate(${x}, ${y - verticalOffset})`}
      textAnchor={alignment}
      dominantBaseline="hanging"
      style={{ fontFamily: 'GrundfosTheSansV2', fontSize: `${FONT_SIZE}px`, lineHeight: `${LINE_HEIGHT}px` }}>
      {...tspans}
    </text>
  );
});
