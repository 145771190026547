import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { DataPointsResult, DeviceDataPoint, EBackendUnit } from '../../../interfaces/data-points';
import { first, flatMap, map, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'shared';
import { Installation } from '../../../interfaces/facilty';
import { AppModel } from '@ams/dumbledore';
import { getDatapoint, manualForcedOpeningEnabled, operationMode, productType, showProductInfo } from '../../../utils/mixit-utils';
import { parseBoolean } from '../../../utils/data-point-utils';
import { MixitDataPointName } from '../../../interfaces/mixit';
import { IMixitSystemFormInterface } from '../../../../../../dumbledore/src/models/systems/mixitSystem/mixitSystemFormInterface';
import { SchematicsService } from '../../../services/schematics.service';
import { MixitDeviceModalComponent, MixitDeviceSettingsInput, MixitDeviceSettingsResponse } from '../mixit-device-modal/mixit-device-modal.component';
import { EquipmentMetaInfo } from '../../../services/installation-configuration.service';
import { ICommand, MixitService } from '../../../services/mixit-service.service';
import { Application } from '../../../interfaces/alarm';
import { SystemDeviceType } from '../../../interfaces/systemDeviceType';
import { parseNumber } from '../../../utils/string-utils';

@Component({
  selector: 'app-mixit-tile',
  templateUrl: './mixit-tile.component.html',
  styleUrls: ['./mixit-tile.component.scss']
})
export class MixitTileComponent implements OnInit {

  @Input() installation$: Observable<Installation>;
  @Input() schematic$: Observable<AppModel>;
  @Input() dataPoints$: Observable<DataPointsResult>;
  @Input() application$: Observable<Application>;

  public productType$: Observable<string>;
  public supplyFlow$: Observable<DeviceDataPoint | null>;
  public hideKvs$: Observable<boolean>;
  public kvsEnabled$: Observable<boolean>;
  public kvs$: Observable<DeviceDataPoint | null>;
  public operationMode$: Observable<DeviceDataPoint | null>;
  public valveOpenness$: Observable<DeviceDataPoint | null>;
  public manualForcedOpeningEnabled$: Observable<boolean>;
  public formattedOperationMode$: Observable<string>;

  private isAdjustmentsInProgress: boolean = false;

  constructor(
    private translateService: TranslateService,
    private modalService: ModalService,
    private schematicService: SchematicsService,
    private mixitService: MixitService
  ) {}

  ngOnInit(): void {

    this.productType$ = productType(this.schematic$);

    this.supplyFlow$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.ValveSupplyFlowAverage
    );

    this.hideKvs$ = this.schematic$.pipe(
      map((schematic) => {
        return (
          (schematic.systems[0].systemInfo as IMixitSystemFormInterface)
            .MV_valveType === 'ThreeWayValve'
        );
      })
    );

    this.kvs$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.LimitedCapacity
    );

    this.kvsEnabled$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.LimitedCapacityEnabled
    ).pipe(map((d) => parseBoolean(d?.value as string)));

    this.operationMode$ = operationMode(
      this.translateService,
      this.dataPoints$
    );

    this.formattedOperationMode$ = this.operationMode$.pipe(
      switchMap((mode) => {
        return mode && mode.value
          ? this.translateService.get(`mixit-operation-mode.${mode.value}`)
          : of('-');
      })
    );

    this.valveOpenness$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.OverrideOpening
    );

    this.manualForcedOpeningEnabled$ = manualForcedOpeningEnabled(
      this.dataPoints$
    );
  }

  public adjustMixit() {
    if (this.isAdjustmentsInProgress) { return; }
    this.isAdjustmentsInProgress = true;

    const valveOpennessMeta$ = this.application$.pipe(
      map((application) => application.id),
      flatMap((id) => this.schematicService.getEquipmentMetaInfo(id)),
      map((metas) => {
        return metas.find((m) => m.term === MixitDataPointName.OverrideOpening);
      })
    );

    const kvsMeta$ = this.application$.pipe(
      map((application) => application.id),
      flatMap((id) => this.schematicService.getEquipmentMetaInfo(id)),
      map((metas) => {
        return metas.find((m) => m.term === MixitDataPointName.LimitedCapacity);
      })
    );

    combineLatest([
      this.installation$,
      this.application$,
      this.hideKvs$,
      this.productType$,
      this.kvs$,
      this.kvsEnabled$,
      this.manualForcedOpeningEnabled$,
      this.valveOpenness$,
      valveOpennessMeta$,
      kvsMeta$]).pipe(first()).subscribe(([
        installation,
        application,
        hideKvs,
        productTypeValue,
        kvs,
        kvsEnabled,
        manualForcedOpeningEnabled,
        valveOpenness,
        valveOpennessMeta,
        kvsMeta]) => {
          const data: MixitDeviceSettingsInput = {
            title: productTypeValue as string,
            hideKvs: hideKvs as boolean,
            kvsEnabled: kvsEnabled as boolean,
            kvs: parseNumber((kvs as DeviceDataPoint)?.value as string),
            kvsMin: (kvsMeta as EquipmentMetaInfo).min,
            kvsMax: (kvsMeta as EquipmentMetaInfo).max,
            manualOpeningEnabled: manualForcedOpeningEnabled as boolean,
            valveMin: (valveOpennessMeta as EquipmentMetaInfo).min,
            valveMax: (valveOpennessMeta as EquipmentMetaInfo).max,
            valveOpenness: parseNumber((valveOpenness as DeviceDataPoint)?.value as string)
          };

          this.modalService.openDialog<MixitDeviceSettingsResponse>(MixitDeviceModalComponent, { data }).subscribe((response) => {
            if (response.dismissed) {
              this.isAdjustmentsInProgress = false;
              return;
            }

            const res = response.result;
            const kvsAsMPerSecond = res.kvs ? res.kvs / 3600 : null;

            const commands: ICommand[] = [
              {
                term: MixitDataPointName.OverrideOpening,
                value: res.valveOpenness?.toString() || null,
                unit: EBackendUnit.Percentage
              },
              {
                term: MixitDataPointName.OverrideEnabled,
                value: res.manualOpeningEnabled?.toString() || null,
                unit: EBackendUnit.None
              },
              {
                term: MixitDataPointName.LimitedCapacity,
                value: kvsAsMPerSecond?.toString() || null,
                unit: EBackendUnit.CubicMeterPerSecond // This is MeterHead per second?
              },
              {
                term: MixitDataPointName.LimitedCapacityEnabled,
                value: res.kvsEnabled?.toString() || null,
                unit: EBackendUnit.None
              }
            ];
            this.mixitService.sendCommand(
              installation as Installation,
              (application as Application).id,
              commands
            );

            this.isAdjustmentsInProgress = false;
          });
        }
      );
  }

  public showProductInfo() {
    showProductInfo({
      modalService: this.modalService,
      installation$: this.installation$,
      schematic$: this.schematic$,
      ble$: this.schematicService.getBLEVersion(),
      firmware$: this.schematicService.getFirmwareVersion(),
      orientation$: getDatapoint(
        this.dataPoints$,
        SystemDeviceType.MixitSystem,
        MixitDataPointName.AbPortOrientation
      )
    });
  }
}
