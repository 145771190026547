import { SystemType, DistrictHeatingSystemState, MixitSystemState } from '../state';
import { EEquipment, EValueType, IEquipmentValue, ISetPointValue, IValveOpenValue } from '../types';
import { SampleEquipmentValue } from './sample-equipment-values';
import { v4 } from 'uuid';

export const SAMPLE_MIXIT_2WAY: MixitSystemState = {
  hasId: v4(),
  configured: true,
  type: SystemType.Mixit,
  left: 340,
  top: 320,
  systemInfo: {
    Standalone: true,
    Dynamic: true,
    T_PF_present: true,
    T_PR_present: true,
    T_SR_present: false,
    P_type: 'Magna3',
    P_location: 'SecondaryForward',
    MV_valveLocation: 'PrimaryForward',
    MV_valveType: 'TwoWayValve',
    DoubleShunt: false,
    HydraulicConfiguration: 'MIXING_LOOP_HYDRAULIC_CONFIGURATION_INJECTION_TWO_WAY',
    title: 'mixit2way',
    ThermalApplicationType: 'MIXING_LOOP_THERMAL_APPLICATION_TYPE_HEAT_APPL',
    Orientation: 'L',
    ValveVariant: 'MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE',
    IsOnboarded: true
  }
};

export const SAMPLE_MIXIT_3WAY: MixitSystemState = {
  hasId: v4(),
  configured: true,
  type: SystemType.Mixit,
  left: 660,
  top: 320,
  systemInfo: {
    Standalone: true,
    Dynamic: true,
    T_PF_present: true,
    T_PR_present: true,
    T_SR_present: false,
    P_type: 'Magna3',
    P_location: 'SecondaryForward',
    MV_valveLocation: 'PrimaryForward',
    MV_valveType: 'ThreeWayValve',
    DoubleShunt: false,
    ThermalApplicationType: 'MIXING_LOOP_THERMAL_APPLICATION_TYPE_HEAT_APPL',
    Orientation: 'R',
    HydraulicConfiguration: 'MIXING_LOOP_HYDRAULIC_CONFIGURATION_MIXING_THREE_WAY',
    ValveVariant: 'MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_RIGHT_VALVE',
    IsOnboarded: true
  }
};

export const SAMPLE_MIXIT_3WAY_DOUBLE: MixitSystemState = {
  hasId: v4(),
  left: 980,
  top: 320,
  configured: true,
  type: SystemType.Mixit,
  systemInfo: {
    Standalone: true,
    Dynamic: true,
    T_PF_present: true,
    T_PR_present: false,
    T_SR_present: true,
    P_type: 'Magna3',
    P_location: 'SecondaryForward',
    MV_valveLocation: 'PrimaryForward',
    MV_valveType: 'ThreeWayValve',
    DoubleShunt: true,
    ThermalApplicationType: 'MIXING_LOOP_THERMAL_APPLICATION_TYPE_HEAT_APPL',
    Orientation: 'R',
    HydraulicConfiguration: 'MIXING_LOOP_HYDRAULIC_CONFIGURATION_INJECTION_THREE_WAY',
    ValveVariant: 'MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_RIGHT_VALVE',
    IsOnboarded: true
  }
};

export const SAMPLE_MIXIT_3WAY_DOUBLE_LEFT: MixitSystemState = {
  hasId: v4(),
  left: 1300,
  top: 320,
  configured: true,
  type: SystemType.Mixit,
  systemInfo: {
    Standalone: true,
    Dynamic: true,
    T_PF_present: true,
    T_PR_present: false,
    T_SR_present: true,
    P_type: 'Magna3',
    P_location: 'SecondaryForward',
    MV_valveLocation: 'PrimaryForward',
    MV_valveType: 'ThreeWayValve',
    DoubleShunt: true,
    ThermalApplicationType: 'MIXING_LOOP_THERMAL_APPLICATION_TYPE_HEAT_APPL',
    Orientation: 'L',
    HydraulicConfiguration: 'MIXING_LOOP_HYDRAULIC_CONFIGURATION_INJECTION_THREE_WAY',
    ValveVariant: 'MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE',
    IsOnboarded: true
  }
};

export const SAMPLE_MIXIT_ORIENTATION_LEFT: MixitSystemState = {
  hasId: v4(),
  left: 1620,
  top: 320,
  type: SystemType.Mixit,
  systemInfo: {
    Standalone: false,
    Connect: true,
    Dynamic: true,
    DoubleShunt: false,
    ProductType: 'MIXIT 25-6.3 L NRV',
    Application: 'MIXING_LOOP_APPLICATION_TYPE_RADIATOR_HEATING',
    ThermalApplicationType: 'MIXING_LOOP_THERMAL_APPLICATION_TYPE_HEAT_APPL',
    Orientation: 'L',
    HydraulicConfiguration: 'MIXING_LOOP_HYDRAULIC_CONFIGURATION_INJECTION_TWO_WAY',
    ValveVariant: 'MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE',
    T_PF_present: true,
    T_PR_present: true,
    T_SR_present: false,
    P_type: 'Magna3',
    P_location: 'SecondaryForward',
    MV_valveLocation: 'PrimaryForward',
    MV_valveType: 'TwoWayValve',
    Control_enabled: false,
    HM_BusModule_present: false,
    HM_isMounted: false,
    HM_present: false,
    MV_isNotMounted: false,
    Modulation_control: false,
    OnOff_control: false,
    P_CIM050_present: false,
    P_connected: false,
    P_present: false,
    PumpTemperatureSensor: false,
    SP_present: false,
    T_AIR_isMounted: false,
    T_PF_isMounted: false,
    T_PR_isMounted: false,
    T_P_F_isMounted: false,
    T_P_PF_isMounted: false,
    T_P_PR_isMounted: false,
    T_P_R_isMounted: false,
    T_SF_present: true,
    Weather_compensation_enabled: false,
    Model: 'A',
    Name: 'MIXIT',
    ProductId: '9952456310000016',
    ProductNumber: '99524563',
    HM_address: 0.0,
    MV_kvs: 0.0,
    Max_property_value: 0.0,
    Min_property_value: 0.0,
    Pipe_dimension: 0.0,
    Q_SC_address: 0.0,
    Q_SF_nominal: 0.0,
    Setpoint: 0.0,
    T_supply: 0.0,
    ValveTravelTime: 0.0,
    IsOnboarded: true
  },
  configured: true,
};

export const SAMPLE_MIXIT_ORIENTATION_RIGHT: MixitSystemState = {
  hasId: v4(),
  left: 1940,
  top: 320,
  type: SystemType.Mixit,
  systemInfo: {
    T_PF_present: true,
    T_PR_present: true,
    T_SF_present: true,
    T_SR_present: false,
    DoubleShunt: false,
    ThermalApplicationType: 'MIXING_LOOP_THERMAL_APPLICATION_TYPE_HEAT_APPL',
    Orientation: 'R',
    Application: 'MIXING_LOOP_APPLICATION_TYPE_FLOOR_HEATING',
    HydraulicConfiguration: 'MIXING_LOOP_HYDRAULIC_CONFIGURATION_MIXING_THREE_WAY',
    ValveVariant: 'MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_RIGHT_VALVE',
    ProductType: 'MIXIT 25-6.3 R NRV',
    ProductNumber: '99508818',
    ProductId: '9950881810000115',
    Model: 'A',
    Standalone: false,
    Connect: true,
    Dynamic: true,
    P_type: 'Magna3',
    P_location: 'SecondaryForward',
    MV_valveLocation: 'PrimaryForward',
    MV_valveType: 'TwoWayValve',
    Control_enabled: false,
    HM_BusModule_present: false,
    HM_isMounted: false,
    HM_present: false,
    MV_isNotMounted: false,
    Modulation_control: false,
    OnOff_control: false,
    P_CIM050_present: false,
    P_connected: false,
    P_present: false,
    PumpTemperatureSensor: false,
    SP_present: false,
    T_AIR_isMounted: false,
    T_PF_isMounted: false,
    T_PR_isMounted: false,
    T_P_F_isMounted: false,
    T_P_PF_isMounted: false,
    T_P_PR_isMounted: false,
    T_P_R_isMounted: false,
    Weather_compensation_enabled: false,
    Name: 'MIXIT RIGHT',
    HM_address: 0.0,
    MV_kvs: 0.0,
    Max_property_value: 0.0,
    Min_property_value: 0.0,
    Pipe_dimension: 0.0,
    Q_SC_address: 0.0,
    Q_SF_nominal: 0.0,
    Setpoint: 0.0,
    T_supply: 0.0,
    ValveTravelTime: 0.0,
    IsOnboarded: true
  },
  configured: true,
};

export const SAMPLE_VALUES_MIXIT: SampleEquipmentValue[] = [
  {
    systemType: SystemType.Mixit,
    type: EValueType.value,
    value: '45.0\u2103',
    equipment: EEquipment.T_PF,
  },
  {
    systemType: SystemType.Mixit,
    type: EValueType.value,
    value: '40.0\u2103',
    equipment: EEquipment.T_PR,
  },
  {
    systemType: SystemType.Mixit,
    type: EValueType.value,
    value: '58.9\u2103',
    equipment: EEquipment.T_SF,
  },
  {
    systemType: SystemType.Mixit,
    type: EValueType.setPoint,
    value: '60\u2103',
    equipment: EEquipment.T_SF,
  },
  {
    systemType: SystemType.Mixit,
    type: EValueType.setPoint,
    value: '52.4\u2103',
    equipment: EEquipment.T_TOP_SETPOINT,
  },
  {
    systemType: SystemType.Mixit,
    type: EValueType.value,
    value: 'P_SF',
    equipment: EEquipment.P_SF,
  },
  {
    systemType: SystemType.Mixit,
    type: EValueType.value,
    value: 'P_SR',
    equipment: EEquipment.P_SR,
  },
  {
    systemType: SystemType.Mixit,
    type: EValueType.value,
    value: '27.3°C',
    equipment: EEquipment.T_SR,
  },
  {
    systemType: SystemType.Mixit,
    type: EValueType.value,
    value: '27.0°C',
    equipment: EEquipment.MIXIT_OUTDOOR,
  },
  { systemType: SystemType.Mixit, type: EValueType.value,  value: '27%', equipment: EEquipment.MV_2W_PF },
  { systemType: SystemType.Mixit, type: EValueType.value, value: '27%', equipment: EEquipment.MV_2W_PR },
  { systemType: SystemType.Mixit, type: EValueType.value, value: '27%', equipment: EEquipment.MV_3W_PR },
  { systemType: SystemType.Mixit, type: EValueType.value, value: '27%', equipment: EEquipment.MV_3W_PF },
  { systemType: SystemType.Mixit, type: EValueType.setPoint,  value: '34%', equipment: EEquipment.MV_2W_PF },
  { systemType: SystemType.Mixit, type: EValueType.setPoint, value: '34%', equipment: EEquipment.MV_2W_PR },
  { systemType: SystemType.Mixit, type: EValueType.setPoint, value: '34%', equipment: EEquipment.MV_3W_PR },
  { systemType: SystemType.Mixit, type: EValueType.setPoint, value: '34%', equipment: EEquipment.MV_3W_PF },
  { systemType: SystemType.Mixit, type: EValueType.valveIndication, value: 'opening', equipment: EEquipment.MV_2W_PF },
  { systemType: SystemType.Mixit, type: EValueType.valveIndication, value: 'opening', equipment: EEquipment.MV_2W_PR },
  { systemType: SystemType.Mixit, type: EValueType.valveIndication, value: 'opening', equipment: EEquipment.MV_3W_PR },
  { systemType: SystemType.Mixit, type: EValueType.valveIndication, value: 'opening', equipment: EEquipment.MV_3W_PF },
  {
    systemType: SystemType.Mixit,
    type: EValueType.setPoint,
    value: '60\u2103',
    equipment: EEquipment.T_TOP_SETPOINT,
  },
  {
    systemType: SystemType.Mixit,
    type: EValueType.value,
    equipment: EEquipment.MixitMeter,
    value: '4.3\u33a5/s\n3.2kW',
  }
];
