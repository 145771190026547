import { LabelPosition, SystemMeta } from '../../types';
import { EConnectionType, SystemType } from '../../../state';
import pumpStandaloneSystemForm from '../../../../models/systems/pumpStandaloneSystem/pumpStandaloneSystemForm.json';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/pump-standalone-system.svg';
import { EConnectionPointPlacement, EEquipment } from '../../../types';
import { SmallLabelDeltas } from '../../../util/constants';
import { CONNECTION_SPACING } from '../../Common';
import { IPumpStandaloneSystemFormInterface } from '../../../../models/systems/pumpStandaloneSystem/pumpStandaloneSystemFormInterface';

export const HEIGHT = 240;
export const WIDTH = 190;
export const CENTER = 170;
export const FORWARD_Y = 100;
export const RETURN_Y = FORWARD_Y + CONNECTION_SPACING;
export const PUMP_X = 140;
export const FORWARD_TEMP_X = 50;

export const pumpStandaloneMeta: SystemMeta = {
  systemType: SystemType.PumpStandalone,
  name: 'Pump Standalone System',
  thumbnail,
  form: pumpStandaloneSystemForm,
  width: WIDTH,
  height: HEIGHT,
  calculateConnectionPoints: () => [
    {
      key: EConnectionPointPlacement.connectionPrimaryForward,
      x: 0,
      y: FORWARD_Y,
      type: EConnectionType.supply,
      dotted: false,
    },
    {
      key: EConnectionPointPlacement.connectionPrimaryReturn,
      x: 0,
      y: RETURN_Y,
      type: EConnectionType.return,
      dotted: true,
    },
    {
      key: EConnectionPointPlacement.connectionSecondaryForward,
      x: WIDTH,
      y: FORWARD_Y,
      type: EConnectionType.supply,
      dotted: false,
    },
    {
      key: EConnectionPointPlacement.connectionSecondaryReturn,
      x: WIDTH,
      y: RETURN_Y,
      type: EConnectionType.return,
      dotted: true,
    },
  ],
};

export const getPumpStandaloneLabels = (systemInfo: IPumpStandaloneSystemFormInterface): LabelPosition[] => {
  const fPump: LabelPosition[] =
    systemInfo.P_location === 'Forward'
      ? [
          {
            id: EEquipment.P_F,
            x: PUMP_X,
            y: FORWARD_Y + SmallLabelDeltas.EAST_Y - 7,
            horizontalAlignment: 'middle',
          },
        ]
      : [];

  const rPump: LabelPosition[] =
    systemInfo.P_location === 'Return'
      ? [
          {
            id: EEquipment.P_R,
            x: PUMP_X,
            y: RETURN_Y + SmallLabelDeltas.WEST_Y + 7,
            horizontalAlignment: 'middle',
          },
        ]
      : [];

  const fTemp: LabelPosition[] =
    systemInfo.P_location === 'Forward'
      ? [
          {
            id: EEquipment.T_R,
            x: FORWARD_TEMP_X + 25,
            y: RETURN_Y + SmallLabelDeltas.EAST_Y + 57,
            horizontalAlignment: 'middle',
          },
        ]
      : [];

  const rTemp: LabelPosition[] =
    systemInfo.P_location === 'Return'
      ? [
          {
            id: EEquipment.T_F,
            x: FORWARD_TEMP_X + 25,
            y: FORWARD_Y + SmallLabelDeltas.EAST_Y,
            horizontalAlignment: 'middle',
          },
        ]
      : [];

  return [...fTemp, ...rTemp, ...fPump, ...rPump];
};
