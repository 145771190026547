import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent {
  public active = false;

  @Input()
  public title: string;

  @Output()
  public tabSelected: EventEmitter<TabComponent> = new EventEmitter<TabComponent>();
}
