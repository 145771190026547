import { EConnectionType, SystemState } from './state';
import { IBuildingFormInterface } from '../models/building/buildingFormInterface';

export interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

export enum EConnectionPointPlacement {
  connectionPrimaryForward = 'ConnectionPrimaryForward',
  connectionPrimaryForwardSecond = 'ConnectionPrimaryForwardSecond', // for two stroke heat exchangers
  connectionSecondaryForward = 'ConnectionSecondaryForward',
  connectionPrimaryReturn = 'ConnectionPrimaryReturn',
  connectionSecondaryReturn = 'ConnectionSecondaryReturn',
  connectionBottomForward = 'ConnectionBottomForward',
  connectionFloating = 'ConnectionFloating',
}

export enum EEquipment {
  // Temperature sensors
  T = 'T',
  T_PF = 'T_PF',
  T_SF = 'T_SF',
  T_PR = 'T_PR',
  T_SR = 'T_SR',
  T_F = 'T_F',
  T_R = 'T_R',

  // Cascade Tank system
  T_1_TOP = 'T_1_TOP',
  T_1_MIDDLE = 'T_1_MIDDLE',
  T_1_BOTTOM = 'T_1_BOTTOM',

  T_2_TOP = 'T_2_TOP',
  T_2_MIDDLE = 'T_2_MIDDLE',
  T_2_BOTTOM = 'T_2_BOTTOM',

  // These are used for the tank system
  T_T = 'T_T', // Top
  T_MIDDLE = 'T_MIDDLE', // Middle
  T_BOTTOM = 'T_BOTTOM',

  // Used for Buffer Tank
  T_CENTERTOP = 'T_CENTERTOP',
  T_CENTERBOTTOM = 'T_CENTERBOTTOM',

  // Used for CHW system
  T_CR = 'T_CR',

  // Boiler
  T_B = 'T_B',

  // Pumps
  P_PR = 'P_PR',
  P_PF = 'P_PF',
  P_SR = 'P_SR',
  P_SF = 'P_SF',
  P_CR = 'P_CR',
  P_SF_SLAVE = 'P_SF_SLAVE',
  P_SR_SLAVE = 'P_SR_SLAVE',
  P_PF_SLAVE = 'P_PF_SLAVE',
  P_PR_SLAVE = 'P_PR_SLAVE',
  P_CR_SLAVE = 'P_CR_SLAVE',
  P_F = 'P_F', // Used for pump systems and boiler
  P_R = 'P_R', // Used for pump systems and boiler

  // Booster system
  P_1 = 'P_1',
  P_2 = 'P_2',
  P_3 = 'P_3',
  P_4 = 'P_4',
  P_5 = 'P_5',
  P_6 = 'P_6',

  // Motor valves
  MV_2W_PF = 'MV_2W_PF',
  MV_2W_PR = 'MV_2W_PR',
  MV_3W_PF = 'MV_3W_PF',
  MV_3W_PR = 'MV_3W_PR',

  // Flow meter
  Q_PF = 'Q_PF',
  Q_PR = 'Q_PR',
  Q_SF = 'Q_SF',
  Q_SC = 'Q_SC', // Flow meter for hot water tank for cold water on secondary side

  // Heat meter
  POW = 'POW',
  VOL = 'VOL',
  E = 'E',

  // Waste water
  WWS = 'WWS',

  // Outdoor temperature
  T_OUTDOOR = 'T_OUTDOOR',
  MIXIT_OUTDOOR = 'MIXIT_OUTDOOR',

  // Connecting Core
  H_PF = 'H_PF',
  H_SF = 'H_SF',

  // Pressure sensors around heat exchanger
  H_P = 'H_P',
  H_S = 'H_S',

  // Used for pressure sensor system
  H_F = 'H_F',
  H_R = 'H_R',
  H_DIFF_P = 'H_DIFF_P',
  H_DIFF_S = 'H_DIFF_S',
  H_DIFF_PR = 'H_DIFF_PR',
  H_DIFF_PF = 'H_DIFF_PF',

  // Specific SetPoint for Mixit
  T_TOP_SETPOINT = 'T_TOP_SETPOINT',

  // Mixit setpoint
  MixitSetPoint = 'MixitSetPoint',
  MixitMeter = 'MixitMeter',
}

// This is the base interface for any mapping
export interface IEquipmentBaseMapping {
  systemId: string;
  equipment: EEquipment;
  deviceId: string;
}

export enum EValueType {
  value = 'value',
  valveIndication = 'valveIndication',
  setPoint = 'setPoint',
}

// This is the base inteface for value
export interface IEquipmentValue extends IEquipmentBaseMapping {
  valueType: EValueType.value;
  value: string;
}

export type TValveOpening = 'opening' | 'closing' | 'steady';

// Valve opening
export interface IValveOpenValue extends IEquipmentBaseMapping {
  valueType: EValueType.valveIndication;
  value: TValveOpening;
}

export interface ISetPointValue extends IEquipmentBaseMapping {
  valueType: EValueType.setPoint;
  value: string;
}

export type TEquipmentValues = IValveOpenValue | IEquipmentValue | ISetPointValue;

export enum EConnectionPointViewPlacement {
  left = 0,
  top = 1,
  right = 2,
  bottom = 3,
}

export interface ConnectionModel {
  hasId: string;
  connectionPointIds: string[];
  type: EConnectionType;
}

export interface ConnectionPointModel {
  hasId: string;
  left: number;
  top: number;
  type: EConnectionType;
  dotted: boolean;
}

export interface SystemConnectionPointModel extends ConnectionPointModel {
  connectionPointPlacement?: EConnectionPointPlacement;
  systemId: string;
}

export interface AppModel {
  systems: SystemState[];
  connections: ConnectionModel[];
  connectionPoints: (ConnectionPointModel | SystemConnectionPointModel)[];
  buildingInfo: IBuildingFormInterface;
  valid: boolean;
  modifiedTimestamp: number;
  draft: boolean;
}

export enum SchematicMode {
  View = 'View',
  Edit = 'Edit',
  Present = 'Present',
}

// TODO: String enums should be used for string, not numering string values.
export enum PasteurizationState {
  Off = '0',
  Heating = '1',
  Retention = '2',
  CoolDown = '3'
}

// TODO: Should be generated from back-end and shared between frontend projects.
export enum SystemControlMode {
  Off = 'Off',
  Auto = 'Auto',
  Manual = 'Manual',
  Unknown = 'Unknown',
  NotSupported = 'NotSupported'
}
