import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MODAL_CONTROLLER, MODAL_DATA, ModalController } from 'shared';
import { EventTypes, MINUTES_IN_DAY, PasteurizationScheduledEvent, WeekDays } from '../../interfaces/scheduling';
import { UserTrackingHelperService } from '../../services/user-tracking-helper.service';
import { formatHoursMinutes, formatHoursMinutesWithDuration } from '../../utils/date-time-utils';

@Component({
  selector: 'app-scheduling-modal',
  templateUrl: './scheduling-modal.component.html',
  styleUrls: ['./scheduling-modal.component.scss'],
})
export class SchedulingModalComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(MODAL_DATA) public data: PasteurizationScheduledEvent,
    @Inject(MODAL_CONTROLLER) private controller: ModalController<any>,
    private userTrackingHelperService: UserTrackingHelperService
  ) {}
  public readonly temperatureMin: number = 50;
  public readonly temperatureMax: number = 70;
  public readonly retentionTimeMin: number = 15;
  public showWarningBanner$: Observable<boolean>;
  public timeValidators = [Validators.required, Validators.minLength(4), Validators.maxLength(4)];
  public form = new UntypedFormGroup(
    {
      temperature: new UntypedFormControl(this.temperatureMin, [
        Validators.required,
        Validators.min(this.temperatureMin),
        Validators.max(this.temperatureMax),
      ]),
      retentionTime: new UntypedFormControl(this.retentionTimeMin, [Validators.required, Validators.min(this.retentionTimeMin)]),
      startTime: new UntypedFormControl('0000', this.timeValidators),
      stopTime: new UntypedFormControl('0430', this.timeValidators),
      days: new UntypedFormControl([], Validators.required),
    },
    { validators: [SchedulingModalComponent.invalidRetentionTimeValidator] }
  );

  private subscription = new Subscription();

  private static timeStringToMinutes(time: string): number {
    if (time.length !== 4) {
      throw Error('Invalid time string');
    }

    const minutes = parseInt(time.slice(2, 4), 10);
    const hours = parseInt(time.slice(0, 2), 10);

    return minutes + hours * 60;
  }

  private static calculateDuration(startTime: number, stopTime: number): number {
    let minutes = (stopTime - startTime) % MINUTES_IN_DAY;
    if (minutes < 0) {
      minutes += MINUTES_IN_DAY;
    }
    return minutes;
  }

  // -- Validators -----------------------------------------------------------------------

  private static invalidRetentionTimeValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    try {
      const numericStartTime = SchedulingModalComponent.timeStringToMinutes(control.get('startTime')?.value);
      const numericStopTime = SchedulingModalComponent.timeStringToMinutes(control.get('stopTime')?.value);
      const retentionTime = control.get('retentionTime')?.value;
      const midnightCorrection = 1440;

      // Calculate time period in minutes and handle midnight overlap.
      const timePeriodInMinutes =
        numericStopTime - numericStartTime >= 0
          ? numericStopTime - numericStartTime
          : numericStopTime - numericStartTime + midnightCorrection;

      // Validate that retention time is within specificed time period.
      return retentionTime > timePeriodInMinutes ? { retiontionTimeExceedTimePeriod: true } : null;
    } catch {
      return null;
    }
  };

  ngOnInit(): void {
    if (this.data) {
      const startTime = formatHoursMinutes(this.data.timeIntervals[0].startHoursMinutes).replace(':', '');
      const stopTime = formatHoursMinutesWithDuration(
        this.data.timeIntervals[0].startHoursMinutes,
        this.data.timeIntervals[0].durationMinutes
      ).replace(':', '');

      this.form.setValue({
        temperature: this.data && this.data.temperature ? this.data.temperature : this.temperatureMin,
        retentionTime: this.data && this.data.retentionTime ? this.data.retentionTime : this.retentionTimeMin,
        startTime,
        stopTime,
        days: this.data.weekDays,
      });

      this.subscription.add(this.userTrackingHelperService.startFormValueChangeTracking('pasteurizationSchedule', this.form));
    }

    // Get initial form values.
    const { startTime: initialStartTime, stopTime: initialStopTime } = this.form.value;

    // Ensure warning banner is displayed outside recommended pasteurization schedule.
    this.showWarningBanner$ = combineLatest([
      this.form.controls.startTime.valueChanges.pipe(startWith(initialStartTime)),
      this.form.controls.stopTime.valueChanges.pipe(startWith(initialStopTime)),
    ]).pipe(
      map(([startTime, stopTime]) => {
        try {
          const numericStartTime = SchedulingModalComponent.timeStringToMinutes(startTime);
          const numericStopTime = SchedulingModalComponent.timeStringToMinutes(stopTime);
          const numericRangeStart = SchedulingModalComponent.timeStringToMinutes('0000');
          const numericRangeEnd = SchedulingModalComponent.timeStringToMinutes('0430');
          const midnightOverlap = numericStopTime < numericStartTime;
          const validStartTime = numericStartTime >= numericRangeStart && numericStartTime <= numericRangeEnd;
          const validStopTime = numericStopTime >= numericRangeStart && numericStopTime <= numericRangeEnd;
          return !validStartTime || !validStopTime || (validStartTime && validStopTime && midnightOverlap);
        } catch {
          return false;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  apply() {
    // Get form values.
    const { temperature, retentionTime, startTime, stopTime, days } = this.form.value;

    const result: PasteurizationScheduledEvent = {
      id: this.data?.id || null,
      eventType: EventTypes.Pasteurization,
      temperature,
      retentionTime,
      timeIntervals: [
        {
          startHoursMinutes: startTime.replace(':', ''),
          durationMinutes: SchedulingModalComponent.calculateDuration(
            SchedulingModalComponent.timeStringToMinutes(startTime),
            SchedulingModalComponent.timeStringToMinutes(stopTime)
          ),
          isSuppressed: false,
        },
      ],
      weekDays: days as WeekDays[],
    };

    // Submit form.
    this.controller.complete(result);
  }

  close() {
    this.controller.dismiss();
  }

  dismiss() {
    this.userTrackingHelperService.trackUserAction('pasteurizationSchedule', 'exitClicked');
  }
}
