import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'gbc-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent implements OnInit {
  @Input()
  public name: string;
  @Input()
  public title: string;
  @Output()
  public selectTab: EventEmitter<TabComponent> = new EventEmitter<TabComponent>();

  public active: Observable<boolean>;

  constructor(private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    if (!this.name) {
      console.error(`Please provide a name for the tab with title: ${this.title}`);
    }

    this.active = this.activatedRoute.params.pipe(
      map(params => params.tab === this.name)
    );
  }

}
