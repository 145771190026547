export const sourceLanguage = 'en-US';

// This type is the language codes that we use in our application, these are the source of truth
export enum languages {
  'nl-NL' = 'nl-NL',
  'en' = 'en',
  'da-DK' = 'da-DK',
  'fr-FR' = 'fr-FR',
  'de-DE' = 'de-DE',
  'sv-SE' = 'sv-SE',
  'es' = 'es',
  'it-IT' = 'it-IT',
  'no-NO' = 'no-NO',
  'hun' = 'hun',
  'sk' = 'sk',
  'pol' = 'pol',
  'cs' = 'cs',
}; 

// These are the languages that we pull from CTF
export const targetLanguages: Array<languages> = [
  languages['de-DE'], 
  languages['nl-NL'],
  languages['en'],
  languages['da-DK'], 
  languages['sv-SE'],
  languages['fr-FR'],
  languages['es'],
  languages['it-IT'],
  languages['no-NO'],
  languages['hun'],
  languages['sk'],
  languages['pol'],
  languages['cs'],
];

// This is a map, that translates our language codes to the codes CTF use
export const languageMap: {[k: string]: string} = {
  [languages.en]: 'ENU',
  [languages['de-DE']]: 'DEU',
  [languages['nl-NL']]:  'NLD',
  [languages['da-DK']]: 'DAN',
  [languages['sv-SE']]: 'SVE',
  [languages['fr-FR']]: 'FRA',
  [languages['es']]: 'ESP',
  [languages['it-IT']]: 'ITA',
  [languages['no-NO']]: 'NOR',
  [languages['hun']]: 'HUN',
  [languages['sk']]: 'SKY',
  [languages['pol']]: 'PLK',
  [languages['cs']]: 'CSY',
}

// We use certain locale features from moment for some dates and times
export const momentLanguageMap: {[k: string]: string} = {
  [languages.en]: 'en-gb',
  [languages['de-DE']]: 'de',
  [languages['nl-NL']]:  'nl',
  [languages['da-DK']]: 'da',
  [languages['sv-SE']]: 'sv',
  [languages['fr-FR']]: 'fr',
  [languages['es']]: 'es',
  [languages['it-IT']]: 'it',
  [languages['no-NO']]: 'nn',
  [languages['hun']]: 'hu',
  [languages['sk']]: 'sk',
  [languages['pol']]: 'pl',
  [languages['cs']]: 'cs',
}

// As the name implies, these are the languages that are shown in the frontend
export const languagesVisibleInFronted: {name: string, languageCode: languages}[] = [
  {
    name: 'English',
    languageCode: languages['en'],
  },
  {
    name: 'Čeština',
    languageCode: languages['cs'],
  },
  {
    name: 'Dansk',
    languageCode: languages['da-DK'],
  },
  {
    name: 'Deutsch',
    languageCode: languages['de-DE'],
  },
  {
    name: 'Español',
    languageCode: languages['es'],
  },
  {
    name: 'Français',
    languageCode: languages['fr-FR'],
  },
  {
    name: 'Italiano',
    languageCode: languages['it-IT'],
  },
  {
    name: 'Nederlands',
    languageCode: languages['nl-NL']
  },
  {
    name: 'Norsk',
    languageCode: languages['no-NO'],
  },
  {
    name: 'Magyar',
    languageCode: languages['hun'],
  },
  {
    name: 'Polski',
    languageCode: languages['pol'],
  },
  {
    name: 'Slovenčina',
    languageCode: languages['sk'],
  },
  {
    name: 'Svenska',
    languageCode: languages['sv-SE'],
  },  
]



export interface ISource {
  name: string;
  output: string;
  format: 'nested' | 'flattened';
}

export const sources: ISource[] = [
  { 
    name: 'CUSTOMERPORTAL', 
    output: '../../frontends/buildingconnect/projects/customerportal/src/assets/i18n',
    format: 'nested',
  },
  { 
    name: 'SERVICEPORTAL', 
    output: '../../frontends/buildingconnect/projects/serviceportal/src/assets/i18n',
    format: 'nested',
  },
  { 
    name: 'PDFMANUAL', 
    output: '../../backend/ConsumerDocumentGenerator/Data/PDFAssets/Localizations/',
    format: 'flattened',
  },
  { 
    name: 'MESSAGINGSERVICE', 
    output: '../../backend/ConsumerMessagingService/Localizations/',
    format: 'flattened',
  },
];
