<gbc-modal [title]="'support-modal.title' | translate">
  <gbc-modal-content>
    <div class="mt-8 mb-8" data-test-id="support-modal">
      {{'support-modal.description' | translate}}
      <ul class="contact-list">
        <li class="phone"><img src='./assets/svgs/phone_outline.svg'><a href="tel:{{'support-modal.phone' | translate}}">{{'support-modal.phone' | translate}}</a></li>
        <li class="email"><img src='./assets/svgs/email_outline.svg'><a href = "mailto: {{'support-modal.email' | translate}}">{{'support-modal.email' | translate}}</a></li>
      </ul>
    </div>
  </gbc-modal-content>
</gbc-modal>
