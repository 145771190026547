import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { cloneDeep } from 'lodash';
import { Facility, Installation } from '../../interfaces/facilty';
import { AppModel, EEquipment } from '@ams/dumbledore';
import { filter, first, switchMap, map, tap, withLatestFrom } from 'rxjs/operators';
import { AppError, AppErrorService, ModalService, PageInfo } from 'shared';
import { PageInfoService } from '../../services/page-info.service';
import { AlarmOverview, Application } from '../../interfaces/alarm';
import { TranslateService } from '@ngx-translate/core';
import {
  MixitModalData,
  MixitProductInfoModalComponent,
} from '../../components/mixit-product-info-modal/mixit-product-info-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DataPointsResult, DeviceDataPoint, SystemDevice } from '../../interfaces/data-points';
import { getPumpType, systemsToPumpDevice } from '../../utils/data-point-utils';
import { DataPointsService } from '../../services/data-points.service';
import { AlarmService } from '../../services/alarm.service';
import { addDumbledoreIdToDatapoint, getDatapoint } from '../../utils/mixit-utils';
import { BoosterDataPoints } from '../../interfaces/booster';
import { SystemDeviceType } from '../../interfaces/systemDeviceType';

@Component({
  selector: 'app-connectingcore-installation-page',
  templateUrl: './connectingcore-installation-page.component.html',
  styleUrls: ['./connectingcore-installation-page.component.scss'],
})
export class ConnectingcoreInstallationPageComponent implements OnInit {
  @Input()
  public facility$: Observable<Facility>;

  @Input()
  public installation$: Observable<Installation>;

  @Input()
  public schematic$: Observable<AppModel>;

  public application$: Observable<Application>;
  public pageInfo$: Observable<PageInfo>;
  public pageError$: Observable<AppError>;
  public installationCommissionState$: Observable<string>;
  public alarm$: Observable<AlarmOverview>;
  public dataPoints$: Observable<DataPointsResult>;

  public dataPointsForSchematic$: Observable<DataPointsResult>;

  public pumpType$: Observable<string>;
  public pumpDevice$: Observable<SystemDevice>;

  constructor(
    private appErrorService: AppErrorService,
    private pageInfoService: PageInfoService,
    private translateService: TranslateService,
    private modalService: ModalService,
    public router: Router,
    public route: ActivatedRoute,
    private dataPointsService: DataPointsService,
    private alarmService: AlarmService
  ) {}

  ngOnInit() {
    this.dataPoints$ = combineLatest([this.installation$, this.dataPointsService.dataPoints$]).pipe(
      filter(([_, dataPoints]) => !!dataPoints),
      map(([installation, dataPoints]) => {
        return dataPoints.find((d) => d.installationId === installation.id) as DataPointsResult;
      })
    );

    // TODO: Clean up this mess
    //
    // Start of mess
    //

    const H_PF = getDatapoint(this.dataPoints$, SystemDeviceType.BoosterSystem, BoosterDataPoints.SystemPrepress).pipe(
      addDumbledoreIdToDatapoint(EEquipment.H_PF)
    );
    const H_SF = getDatapoint(this.dataPoints$, SystemDeviceType.BoosterSystem, BoosterDataPoints.SystemPress).pipe(
      addDumbledoreIdToDatapoint(EEquipment.H_SF)
    );
    const Q_SF = getDatapoint(this.dataPoints$, SystemDeviceType.BoosterSystem, BoosterDataPoints.Flow).pipe(
      addDumbledoreIdToDatapoint(EEquipment.Q_SF)
    );
    const P_1 = getDatapoint(this.dataPoints$, SystemDeviceType.BoosterSystem, BoosterDataPoints.P1FAct).pipe(
      addDumbledoreIdToDatapoint(EEquipment.P_1)
    );
    const P_2 = getDatapoint(this.dataPoints$, SystemDeviceType.BoosterSystem, BoosterDataPoints.P2FAct).pipe(
      addDumbledoreIdToDatapoint(EEquipment.P_2)
    );
    const P_3 = getDatapoint(this.dataPoints$, SystemDeviceType.BoosterSystem, BoosterDataPoints.P3FAct).pipe(
      addDumbledoreIdToDatapoint(EEquipment.P_3)
    );
    const P_4 = getDatapoint(this.dataPoints$, SystemDeviceType.BoosterSystem, BoosterDataPoints.P4FAct).pipe(
      addDumbledoreIdToDatapoint(EEquipment.P_4)
    );
    const P_5 = getDatapoint(this.dataPoints$, SystemDeviceType.BoosterSystem, BoosterDataPoints.P5FAct).pipe(
      addDumbledoreIdToDatapoint(EEquipment.P_5)
    );
    const P_6 = getDatapoint(this.dataPoints$, SystemDeviceType.BoosterSystem, BoosterDataPoints.P6FAct).pipe(
      addDumbledoreIdToDatapoint(EEquipment.P_6)
    );

    const dataPointsWithDumbledoreIds = [H_PF, H_SF, Q_SF, P_1, P_2, P_3, P_4, P_5, P_6];

    this.dataPointsForSchematic$ = combineLatest(dataPointsWithDumbledoreIds).pipe(
      withLatestFrom(this.dataPoints$),
      map(([withDumbledoreIds, datapoints]: [DeviceDataPoint[], DataPointsResult]) => {
        const dataPointsCopy = cloneDeep(datapoints);
        dataPointsCopy.data[0].devices[0].dataPoints = withDumbledoreIds;
        return dataPointsCopy;
      }),
      tap(console.log)
    );

    //
    // Stop of mess
    //

    this.application$ = this.schematic$.pipe(
      map((schematic) => {
        const system = schematic.systems[0];
        const type = system.type;
        const result: Application = {
          title: system.systemInfo.title ? of(system.systemInfo.title) : this.translateService.get(`application-title.${type}`),
          id: system.hasId,
          type,
        };
        return result;
      })
    );

    this.pageError$ = this.appErrorService.createPageErrorObservable([this.facility$, this.installation$, this.schematic$]);

    this.pageInfo$ = combineLatest([this.facility$, this.installation$]).pipe(
      map(([facility, installation]) => {
        return this.pageInfoService.installation(facility, installation);
      })
    );

    this.installationCommissionState$ = this.installation$.pipe(
      map((installation) => {
        return installation.commissionStatus;
      })
    );

    this.pumpDevice$ = combineLatest([this.dataPoints$, this.application$]).pipe(
      switchMap(([dataPoints, application]) => {
        return systemsToPumpDevice(application.id)(of(dataPoints)) as Observable<SystemDevice>;
      })
    );

    this.pumpType$ = this.application$.pipe(switchMap((application) => getPumpType(this.schematic$, application.id)));

    this.alarm$ = this.alarmService.mostImportantAlarm$ as Observable<AlarmOverview>;
  }

  public editInstallation() {
    this.router.navigate(['/edit-installation', this.route.snapshot.params.facilityId, this.route.snapshot.params.installationId]);
  }

  public showProductInfo() {
    combineLatest([this.installation$, this.schematic$])
      .pipe(first())
      .subscribe(([installation, schematic]) => {
        const systemInfo = schematic.systems[0].systemInfo as any; // TODO unclear why ProductType and ProductId is not present on mixit systeminfo?
        const orientations = {
          0: 'LEFT',
          1: 'RIGHT',
          2: 'UP',
          3: 'DOWN',
        };

        const data: MixitModalData = {
          Name: installation.name || '',
          // @ts-ignore
          Orientation: orientations[systemInfo.Orientation],
          ProductType: systemInfo.ProductType,
          ProductNumber: systemInfo.ProductNumber,
          ProductId: systemInfo.ProductId,
          Model: systemInfo.Model,
          ProductionCode: systemInfo.ProductionCode,
          BLE: '',
          Firmware: '',
        };
        this.modalService.openDialog(MixitProductInfoModalComponent, { data });
      });
  }
}
