import { ConnectionPointIdentifier, FreeConnectionPointIdentifier, SystemConnectionPointIdentifier } from '../state';

export const connectionPointIdentifierEquals = (a: ConnectionPointIdentifier, b: ConnectionPointIdentifier): boolean => {
  if (a.type !== b.type) {
    return false;
  }
  if (a.type === 'FREE') {
    return a.id === (b as FreeConnectionPointIdentifier).id;
  }
  return a.systemId === (b as SystemConnectionPointIdentifier).systemId && a.key === (b as SystemConnectionPointIdentifier).key;
};
