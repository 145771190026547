<gbc-page-error-handler [content]="content" [pageError$]="pageError$"></gbc-page-error-handler>
<ng-template #content>
  <gbc-page-header *ngIf="pageInfo$ | async as pageInfo" [pageInfo]="pageInfo"></gbc-page-header>
  <gbc-command-spinner>
    <gbc-page-container data-test-id="edit-installation">
      <form [formGroup]="form">
        <gbc-tile [title]="'edit-installation-page.edit-installation' | translate">
          <gbc-input-container style="padding-bottom: 1.5em">
            <gbc-input-label>{{ 'edit-installation-page.name' | translate }}</gbc-input-label>
            <gbc-input-field formControlName="name" errorDescriptionTranslationString="edit-installation-page.name-invalid">
            </gbc-input-field>
          </gbc-input-container>
        </gbc-tile>

        <button
          gbc-button
          (click)="update()"
          id="update"
          style="float: right"
          [disabled]="!form.valid"
          angulartics2On="click"
          angularticsAction="updateClicked"
          angularticsCategory="editInstallationShown"
        >
          {{ 'edit-installation-page.update' | translate }}
        </button>
      </form>
    </gbc-page-container>
  </gbc-command-spinner>
</ng-template>
