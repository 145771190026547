import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { isLicenseActive } from '../../utils/license-utils';
import { filter,first, map, shareReplay } from 'rxjs/operators';
import { FacilityService } from '../../services/facility.service';
import { AppError, AppErrorService } from 'shared';
import { Facility, Installation, License } from '../../interfaces/facilty';
import { EControllerType } from '../../../../../shared/src/lib/interfaces/controllerType';
import { connectLicenseTypes, dynamicLicenseTypes, LicenseType } from '../../interfaces/mixit';

enum LicenseCategory {
  Unknown = 'unknown',
  Subscription = 'subscription',
  Trial = 'trial',
  OneTimeFee = 'one-time-fee',
  Free = 'free',
}

interface ExtendedFacility extends Facility {
  installations: InstallationWithParentInstallation[];
}

interface InstallationWithParentInstallation extends Installation {
  parentInstallation: Installation | undefined;
}

@Component({
  selector: 'app-devices-and-upgrades',
  templateUrl: './devices-and-upgrades.component.html',
  styleUrls: ['./devices-and-upgrades.component.scss'],
})
export class DevicesAndUpgradesComponent implements OnInit {
  public pageError$: Observable<AppError>;
  public mixitFacilities$: Observable<ExtendedFacility[]>;

  constructor(
    private facilityService: FacilityService,
    private errorService: AppErrorService
  ) {}

  ngOnInit(): void {
    this.mixitFacilities$ = this.facilityService.facilities$.pipe(
      filter((f) => !!f),
      map((facilities: Facility[] | null) => {
        const allInstallations = (facilities || []).flatMap((f) => f.installations);

        return (facilities as ExtendedFacility[])
          .map(({ installations, ...facility }) => {
            const sortedInstallations = installations
              .filter((i) => i.controllerType !== EControllerType.MOXA)
              .map(({ licenseInformation, ...installation }) => {
                return {
                  ...installation,
                  licenseInformation: licenseInformation.sort((a, b) => (isLicenseActive(a) ? 1 : -1)),
                };
              })
              .map((installation) => {
                if (installation.parentInstallationId) {
                  installation.parentInstallation = allInstallations.find((i) => i.id === installation.parentInstallationId);
                }
                return installation;
              });

            return {
              ...facility,
              installations: sortedInstallations.sort((a, b) => (this.getSomeActivePaid(a.licenseInformation) ? 1 : -1)),
            } as ExtendedFacility;
          })
          .sort((a, b) => (this.getSomeActivePaid(a.installations.flatMap((installation) => installation.licenseInformation)) ? 1 : -1));
      }),
      shareReplay()
    );
    this.pageError$ = this.errorService.createPageErrorObservable([this.mixitFacilities$.pipe(first())]);
  }

  getLatest(licenseInformation: License[]) {
    const extendedLicenseInformation = licenseInformation
      .filter((l) => this.getLicenseType(l) !== LicenseCategory.Free)
      .map((license) => {
        return {
          ...license,
          isActive: isLicenseActive(license),
          isTrial: this.getLicenseType(license) === LicenseCategory.Trial,
          isSubscription: this.getLicenseType(license) === LicenseCategory.Subscription,
          isOneTimeFee: this.getLicenseType(license) === LicenseCategory.OneTimeFee,
          isConnect: connectLicenseTypes.some((lt) => lt === license.licenseType),
          isDynamic: dynamicLicenseTypes.some((lt) => lt === license.licenseType),
        };
      }); // Sort licenses by the most recent active license.

    return extendedLicenseInformation.filter((l) => l.isActive);
  }

  getLicenseType(license: License): LicenseCategory {
    if (!license) {
      return LicenseCategory.Unknown;
    }
    switch (license.licenseType) {
      case LicenseType.CodeConnectSubscription:
      case LicenseType.CodeDynamicSubscription:
        return LicenseCategory.Subscription;
      case LicenseType.CodeConnectTrial:
        return LicenseCategory.Trial;
      case LicenseType.CodeConnectUnlimited:
      case LicenseType.CodeDynamicUnlimited:
      case LicenseType.CodeDynamicUnlimitedPhysical:
      case LicenseType.CodeConnectUnlimitedPhysical:
        return LicenseCategory.OneTimeFee;
      case LicenseType.Freemium:
        return LicenseCategory.Free;
      case LicenseType.Unknown:
      default:
        return LicenseCategory.Unknown;
    }
  }

  getSomeActivePaid(licenseInformation: License[]) {
    return licenseInformation.some((l) => isLicenseActive(l) && (l.isSubscription || l.isOneTimeFee || l.isTrial));
  }
}
