import { SystemType, DistrictHeatingSystemState, BoilerSystemState, ColdWaterSupplySystemState } from '../state';
import { EEquipment, EValueType, IEquipmentValue, ISetPointValue, IValveOpenValue } from '../types';
import { SampleEquipmentValue } from './sample-equipment-values';
import { v4 } from 'uuid';

// WILL BE DEPRECATED
export const SAMPLE_COLD_WATER_SUPPLY_SYSTEM: ColdWaterSupplySystemState = {
  hasId: v4(),
  left: 1880,
  top: 1800,
  configured: false,
  type: SystemType.ColdWaterSupplySystem,
  systemInfo: {
    title: 'Cold water supply',
    LeftToRight: false,
    Q_SC_present: true,
    Q_SC_address: 2
  }
};

export const SAMPLE_VALUES_COLD_WATER_SUPPLY: SampleEquipmentValue[] = [
  {
    systemType: SystemType.ColdWaterSupplySystem,
    type: EValueType.value,
    equipment: EEquipment.Q_SC,
    value: '7.5\u33a5/s',
  }
];
