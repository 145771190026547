import { ECommissionStatus } from 'shared';

export const mapCommissionStatusToTranslationKey = (commissionStatus: ECommissionStatus) => {
  switch (commissionStatus) {
    case ECommissionStatus.Created:
    case ECommissionStatus.DeviceIdRegistered:
    case ECommissionStatus.DeviceRegisteredInIotHub:
      return 'facility-page-content.state-being-assembled';
    case ECommissionStatus.ReadyForTest:
    case ECommissionStatus.BeingTested:
    case ECommissionStatus.TestSuccess:
      return 'facility-page-content.state-being-installed';
    case ECommissionStatus.Live:
    case null:
    case undefined:
      return 'facility-page-content.state-live';
    default:
      return 'facility-page-content.state-unknown-comissioning';
  }
};
