<gbc-page-error-handler [content]="content" [pageError$]="pageError$"></gbc-page-error-handler>
<ng-template #content>
  <gbc-page-header [pageInfo]="pageInfo"></gbc-page-header>
  <gbc-command-spinner>
    <form [formGroup]="form" data-test-id="mixit-onboarding">
      <gbc-stepper
        #stepper
        (done)="done()"
        (selectionChange)="stepperChange($event)"
        [backLabel]="'app-back' | translate"
        [doneDisabled]="!form.controls.termsConditions.valid"
        [doneLabel]="'app-done' | translate"
        [nextDisabled]="nextDisabled(stepper.selectedIndex) | async"
        [nextLabel]="'app-next' | translate"
        linear>
        <cdk-step [completed]="form.controls.claimCode.valid" [label]="'mixit-onboarding.connect-a-new-device' | translate">
          <gbc-tile [title]="'mixit-onboarding.please-enter-device-identification-number' | translate" class="top-tile">
            <p class="din-description">{{ 'mixit-onboarding.enter-din' | translate }}</p>

            <gbc-input-container class="padding-bottom">
              <gbc-input-label>{{ 'mixit-onboarding.device-identification-number' | translate }}</gbc-input-label>
              <gbc-input-field
                errorDescriptionTranslationString="mixit-onboarding.invalid-claim-code"
                formControlName="claimCode"
                maxlength="12"
                required
                (input)="form.controls.claimCode.setValue(form.controls.claimCode.value.toUpperCase())">
              </gbc-input-field>
            </gbc-input-container>
          </gbc-tile>
        </cdk-step>

        <cdk-step [label]="'mixit-onboarding.pre-checks' | translate">
          <gbc-tile [title]="'mixit-onboarding.pre-checks-title' | translate" class="top-tile">
            <div class="progress-checker-area">
              <gbc-progress-checker
                [title]="'mixit-onboarding.pre-check-device-online' | translate"
                [job]="preCheckDeviceOnlineValid$ | async">
              </gbc-progress-checker>

              <gbc-progress-checker
                [title]="'mixit-onboarding.pre-check-supported-system' | translate"
                [job]="preCheckSupportedSystemValid$ | async">
              </gbc-progress-checker>
            </div>
          </gbc-tile>
        </cdk-step>

        <cdk-step [label]="'mixit-onboarding.confirm-device' | translate" [stepControl]="form.controls.name">
          <gbc-tile [title]="'mixit-onboarding.device-data' | translate" class="top-tile">
            <gbc-input-container>
              <gbc-input-label>{{ 'mixit-onboarding.device-name' | translate }}</gbc-input-label>
              <gbc-input-field errorDescriptionTranslationString="mixit-onboarding.invalid-name" formControlName="name"></gbc-input-field>
            </gbc-input-container>

            <gbc-input-container>
              <gbc-input-label>{{ 'mixit-onboarding.application' | translate }}</gbc-input-label>
              <gbc-static-text-field>{{ applicationTypeTranslationMap[application] | translate }}</gbc-static-text-field>
            </gbc-input-container>

            <gbc-input-container>
              <gbc-input-label>{{ 'mixit-onboarding.type' | translate }}</gbc-input-label>
              <gbc-static-text-field>{{ type }}</gbc-static-text-field>
            </gbc-input-container>

            <gbc-input-container>
              <gbc-input-label>{{ 'mixit-onboarding.cloud-connection-date' | translate }}</gbc-input-label>
              <gbc-static-text-field>{{ commissioningTime | date: 'medium' }}</gbc-static-text-field>
            </gbc-input-container>

            <gbc-input-container class="padding-bottom">
              <gbc-input-label>{{ 'mixit-onboarding.product-serial' | translate }}</gbc-input-label>
              <gbc-static-text-field>{{ productSerial }}</gbc-static-text-field>
            </gbc-input-container>
          </gbc-tile>

          <gbc-tile [title]="'mixit-onboarding.schematic' | translate" class="schematics-tile">
            <app-schematic *ngIf="schematic$ | async as schematic" [schematic]="schematic" [showShadow]="false" class="schematic">
            </app-schematic>
          </gbc-tile>
        </cdk-step>

        <cdk-step [completed]="locationValid$ | async" [label]="'mixit-onboarding.device-location' | translate">
          <gbc-tile [title]="'mixit-onboarding.device-location' | translate" class="top-tile">
            <gbc-input-container>
              <gbc-input-label>{{ 'mixit-onboarding.select-device-facility' | translate }}</gbc-input-label>
              <gbc-dropdown [dropDownItems]="facilities$ | async" formControlName="existingLocation"></gbc-dropdown>
            </gbc-input-container>

            <div class="padding-bottom"></div>
          </gbc-tile>

          <form [formGroup]="form.controls.newLocation">
            <gbc-tile *ngIf="showCreateNewFacility$ | async" [title]="'mixit-onboarding.facility-information' | translate">
              <gbc-input-container>
                <gbc-input-label>{{ 'mixit-onboarding.facility-name' | translate }} *</gbc-input-label>
                <gbc-input-field errorDescriptionTranslationString="mixit-onboarding.invalid-name" formControlName="name"></gbc-input-field>
              </gbc-input-container>
              <gbc-input-container>
                <gbc-input-label>{{ 'mixit-onboarding.road' | translate }} *</gbc-input-label>
                <gbc-input-field errorDescriptionTranslationString="mixit-onboarding.road-required" formControlName="road"></gbc-input-field>
              </gbc-input-container>
              <gbc-input-container>
                <gbc-input-label>{{ 'mixit-onboarding.postal-code' | translate }} *</gbc-input-label>
                <gbc-input-field errorDescriptionTranslationString="mixit-onboarding.postal-code-required" formControlName="postalCode"></gbc-input-field>
              </gbc-input-container>
              <gbc-input-container class="padding-bottom">
                <gbc-input-label>{{ 'mixit-onboarding.city' | translate }} *</gbc-input-label>
                <gbc-input-field errorDescriptionTranslationString="mixit-onboarding.city-required" formControlName="city"></gbc-input-field>
              </gbc-input-container>
              <gbc-form-hint> * {{ 'app-must-fill-in' | translate }} </gbc-form-hint>
            </gbc-tile>

            <gbc-tile *ngIf="showCreateNewFacility$ | async" [title]="'mixit-onboarding.new-facility' | translate">
              <div class="location-container">
                <div class="location-selector">
                  <div class="gps-coordinates-header">
                    {{ 'geo-location.gps-subtitle' | translate }}
                  </div>
                  <ol class="gps-coordinates-description">
                    <li>
                      {{ 'geo-location.gps-hint-1' | translate }}
                      <a [href]="this.mapsUrl$ | async" target="string_blank" data-test-id='google-map-link'>Google Maps</a>
                    </li>
                    <li>{{ 'geo-location.gps-hint-2' | translate }}</li>
                    <li>
                      <span>{{ 'geo-location.gps-hint-3' | translate }}</span>
                      <img class="gps-image" src="./assets/pngs/google-maps.png" />
                    </li>
                    <li>
                      <span>{{ 'geo-location.gps-hint-4' | translate }}</span>
                      <img class="gps-image" src="./assets/pngs/copied.png" />
                    </li>
                    <li>
                      {{ 'geo-location.gps-hint-5' | translate }}
                    </li>
                  </ol>
                  <gbc-input-container>
                    <gbc-input-field
                      class="gps-input-lat-long"
                      errorDescriptionTranslationString="geo-location.invalid-location"
                      formControlName="latLong"
                      type='number'>
                    </gbc-input-field>
                  </gbc-input-container>
                  <gbc-input-container>
                    <div class="gps-coordinate-label gps-coordinate-label--lattitude">
                      {{ 'geo-location.latitude' | translate }}: {{ latitude }}
                    </div>
                    <div class="gps-coordinate-label gps-coordinate-label--longitude">
                      {{ 'geo-location.longitude' | translate }}: {{ longitude }}
                    </div>
                  </gbc-input-container>
                </div>

                <app-map
                  *ngIf="newLocationfacilityList$ | async"
                  #newLocationMap
                  [facilityData$]="newLocationfacilityList$"
                  [hideZoom]="false"
                  [interactive]="true"
                  [showZeroForFacilities]="false"
                  [zoomOutFromSingleFacility]="true"
                  style="height: 720px"
                ></app-map>
              </div>
            </gbc-tile>
          </form>
          <ng-container *ngIf="existingLocationfacilityList$ | async as facilityList">
            <gbc-tile *ngIf="facilityList.data.length" [title]="'mixit-onboarding.facility-map' | translate">
              <app-map
                #existingLocationMap
                [facilityData$]="existingLocationfacilityList$"
                [hideZoom]="false"
                [interactive]="true"
                [showZeroForFacilities]="false"
                [zoomOutFromSingleFacility]="true"
                style="height: 540px"
              ></app-map>
            </gbc-tile>
          </ng-container>
        </cdk-step>

        <cdk-step [label]="'mixit-onboarding.summary' | translate">
          <gbc-tile [title]="'mixit-onboarding.device-data' | translate" class="top-tile">
            <gbc-table>
              <tr gbc-table-row>
                <td class="table-row-title" gbc-table-cell>{{ 'mixit-onboarding.device-name' | translate }}</td>
                <td gbc-table-cell>{{ form.value.name }}</td>
              </tr>
              <tr gbc-table-row>
                <td class="table-row-title" gbc-table-cell>{{ 'mixit-onboarding.application' | translate }}</td>
                <td gbc-table-cell>{{ applicationTypeTranslationMap[application] | translate }}</td>
              </tr>
              <tr gbc-table-row>
                <td class="table-row-title" gbc-table-cell>{{ 'mixit-onboarding.type' | translate }}</td>
                <td gbc-table-cell>{{ type }}</td>
              </tr>
              <tr gbc-table-row>
                <td class="table-row-title" gbc-table-cell>{{ 'mixit-onboarding.cloud-connection-date' | translate }}</td>
                <td gbc-table-cell>{{ commissioningTime | date: 'medium' }}</td>
              </tr>
              <tr gbc-table-row>
                <td class="table-row-title" gbc-table-cell>{{ 'mixit-onboarding.product-serial' | translate }}</td>
                <td gbc-table-cell>{{ productSerial }}</td>
              </tr>
            </gbc-table>
          </gbc-tile>

          <gbc-tile [title]="'mixit-onboarding.schematic' | translate" class="schematics-tile">
            <app-schematic *ngIf="schematic$ | async as schematic" [schematic]="schematic" [showShadow]="false" class="schematic">
            </app-schematic>
          </gbc-tile>

          <gbc-tile [title]="'mixit-onboarding.device-facility' | translate">
            <!-- Create new facility -->
            <gbc-table *ngIf="form.value.newLocation && showCreateNewFacility$ | async">
              <tr gbc-table-row>
                <td class="table-row-title" gbc-table-cell>{{ 'mixit-onboarding.new-facility-name' | translate }}</td>
                <td gbc-table-cell>{{ form.value.newLocation.name }}</td>
              </tr>
              <tr gbc-table-row>
                <td class="table-row-title" gbc-table-cell>{{ 'mixit-onboarding.road' | translate }}</td>
                <td gbc-table-cell>{{ form.value.newLocation.road }}</td>
              </tr>
              <tr gbc-table-row>
                <td class="table-row-title" gbc-table-cell>{{ 'mixit-onboarding.postal-code' | translate }}</td>
                <td gbc-table-cell>{{ form.value.newLocation.postalCode }}</td>
              </tr>
              <tr gbc-table-row>
                <td class="table-row-title" gbc-table-cell>{{ 'mixit-onboarding.city' | translate }}</td>
                <td gbc-table-cell>{{ form.value.newLocation.city }}</td>
              </tr>
            </gbc-table>

            <app-map
              #newLocationSummaryMap
              *ngIf="showCreateNewFacility$ | async"
              [facilityData$]="newLocationfacilityList$"
              [hideZoom]="true"
              [interactive]="false"
              [showZeroForFacilities]="false"
              [zoomOutFromSingleFacility]="true"
              style="height: 540px">
            </app-map>

            <gbc-table *ngIf="form.value.existingLocation && showExistingFacility$ | async">
              <tr gbc-table-row>
                <td class="table-row-title" gbc-table-cell>{{ 'mixit-onboarding.existing-facility-name' | translate }}</td>
                <td gbc-table-cell>{{ form.value.existingLocation.name }}</td>
              </tr>
              <tr gbc-table-row>
                <td class="table-row-title" gbc-table-cell>{{ 'mixit-onboarding.road' | translate }}</td>
                <td gbc-table-cell>{{ form.value.existingLocation.addressRoad }}</td>
              </tr>
              <tr gbc-table-row>
                <td class="table-row-title" gbc-table-cell>{{ 'mixit-onboarding.postal-code' | translate }}</td>
                <td gbc-table-cell>{{ form.value.existingLocation.addressPostal }}</td>
              </tr>
              <tr gbc-table-row>
                <td class="table-row-title" gbc-table-cell>{{ 'mixit-onboarding.city' | translate }}</td>
                <td gbc-table-cell>{{ form.value.existingLocation.addressCity }}</td>
              </tr>
            </gbc-table>

            <ng-container *ngIf="existingLocationfacilityList$ | async as facilityList">
              <app-map
                #existingLocationSummaryMap
                *ngIf="facilityList.data.length"
                [facilityData$]="existingLocationfacilityList$"
                [hideZoom]="false"
                [interactive]="true"
                [showZeroForFacilities]="false"
                [zoomOutFromSingleFacility]="true"
                style="height: 540px"
              ></app-map>
            </ng-container>
          </gbc-tile>
          <footer>
            <p-checkbox
              [formControl]="$any(form).controls['termsConditions']"
              id="terms-accepted"
              [binary]="true"
              data-test-id="terms-accepted"
            ></p-checkbox>
            <label for="terms-accepted">
              <a [href]="TERMS_AND_CONDITIONS_LINK" target="_blank">{{ 'mixit-onboarding.terms-and-conditions' | translate }}</a>
            </label>
          </footer>
        </cdk-step>
      </gbc-stepper>
    </form>
  </gbc-command-spinner>
</ng-template>
