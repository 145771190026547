<div class="border">
  <button
    *ngFor="let day of weekDays"
    [attr.data-test-id]="'weekday-button_' + day[1] | lowercase"
    class="weekday-button"
    type="button"
    [ngClass]="{ active: value | arrayContains: day[1] }"
    (click)="toggleWeekDay(day[1])"
  >
    {{ day[0] }}
    <img *ngIf="value.includes(day[1])" src="./assets/svgs/check_outline_white.svg" />
  </button>
</div>
