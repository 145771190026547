<gbc-topbar [title]="'core.ams-description-title' | translate" (headerClick)="navigateToRoot()">
  <gbc-topbar-dropdown data-hj-suppress [placeholder]="(userName$ | async) || ('app-sign-in' | translate)" id="topbar-dropdown">

    <button gbc-topbar-dropdown-item *ngIf="(currentUser$ | async) === null" (click)="signin()">
      {{'app-sign-in' | translate}}
    </button>

    <button gbc-topbar-dropdown-item
      *ngIf="accountAvailable$ | async"
      [routerLink]="'/account'"
      angulartics2On="click"
      angularticsAction="accountClicked"
      angularticsCategory="userMenu">
      {{'app-account-page.title' | translate}}
    </button>

    <button gbc-topbar-dropdown-item
      *ngIf="userManagementAvailable$ | async"
      [routerLink]="'/users'"
      angulartics2On="click"
      angularticsAction="userManagementClicked"
      angularticsCategory="userMenu">
      {{'users-page.title' | translate}}
    </button>

    <button gbc-topbar-dropdown-item
      *ngIf="currentUser$ | async"
      (click)="logout()"
      angulartics2On="click"
      angularticsAction="logoutClicked"
      angularticsCategory="userMenu">
      {{'app-menu-logout' | translate}}
    </button>

  </gbc-topbar-dropdown>

  <button gbc-current-language>
    {{currentLanguage$ | async}}
  </button>

</gbc-topbar>

<div class="outlet-container">
  <gbc-page-spinner>
    <div class="outlet">
      <router-outlet></router-outlet>
    </div>
  </gbc-page-spinner>
</div>
