<gbc-tile [title]="'booster-tile.pump-performance' | translate">
  <div class="tile-content">
    <div class="tile-row">
      <div class="tile-row-name">{{'booster-tile.power' | translate}}</div>
      <div class="tile-row-value">
        {{power$ | async | formatDataPoint }}
      </div>
    </div>

    <div class="tile-row">
      <div class="tile-row-name">{{'booster-tile.energy' | translate}}</div>
      <div class="tile-row-value">
        {{energy$ | async | formatDataPoint }}
      </div>
    </div>

    <div class="tile-row">
      <div class="tile-row-name">{{'booster-tile.flow' | translate}}</div>
      <div class="tile-row-value">
        {{flow$ | async | formatDataPoint}}
      </div>
    </div>

    <div class="tile-row">
      <div class="tile-row-name">{{ 'booster-tile.specific-energy' | translate}}</div>
      <div class="tile-row-value">
        {{specificEnergy$ | async | formatDataPoint}}
      </div>
    </div>

  </div>
</gbc-tile>
