import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Angulartics2 } from 'angulartics2';
import { environment } from '../../environments/environment';
import { AdobeAnalyticsEvent, IUserTrackingPayload } from '../interfaces/user-tracking';
import { PageTrackingRedundancyFilterService } from './page-tracking-redundancy-filter.service';

declare var _satellite: any;
declare const digitalData: Array<unknown>;

type UserInfo = {
  userId: string;
  signInName: string;
  companyName: string;
}

const defaultPayload: IUserTrackingPayload = environment.userTrackingDefaults as IUserTrackingPayload;

@Injectable({
  providedIn: 'root',
})
export class UserTrackingService {

  constructor(
    private angulartics2: Angulartics2,
    private translateService: TranslateService,
    private pageTrackingRedundancyFilterService: PageTrackingRedundancyFilterService
  ) {
    this.refreshUserInfo();
  }

  private payload: IUserTrackingPayload = defaultPayload;
  private dryRunTracking: boolean;
  private logTrackingEvents: boolean;
  private includeInternalUsers: boolean;
  private userInfo: UserInfo | null;
  
  private get isTrackingDisabled(): boolean {
    if (this.dryRunTracking) {
      return false;
    }
    if (this.includeInternalUsers) {
      return false;
    }
    return this.userInfo?.signInName?.endsWith('@grundfos.com') || this.userInfo?.companyName === 'Grundfos';
  }

  private get isUserLoggedIn(): boolean {
    return Object.keys(sessionStorage).some(key => key.startsWith("oidc.user:"));
  }

  setupReportSuiteId(rsid: string) {
    if (!defaultPayload) {
      return;
    }
    defaultPayload.data.pageInfo.rsid = rsid;
    this.payload = defaultPayload;
  }

  setupDryRunTracking(dryRunTracking: boolean) {
    this.dryRunTracking = dryRunTracking;
  }

  setupLogTrackingEvents(logTrackingEvents: boolean) {
    this.logTrackingEvents = logTrackingEvents;
  }

  setupIncludeInternalUsers(includeInternalUsers: boolean) {
    this.includeInternalUsers = includeInternalUsers;
  }

  startTracking(): void {
    this.angulartics2.pageTrack.pipe(this.angulartics2.filterDeveloperMode()).subscribe((x) => this.pageTrack(x.path ?? ''));
    this.angulartics2.eventTrack.pipe(this.angulartics2.filterDeveloperMode()).subscribe((x) => this.eventTrack(x.action ?? 'Click', x.properties));
  }

  /**
   * @param path path of page to track.
   * Implementation of method pageTrack => dictates behavior when logging page changes.
   * This method is inspired by the existing implementation of pageTrack
   */
  private pageTrack(path: string) {
    this.refreshUserInfo();
    if (!this.isTrackingDisabled && this.isUserLoggedIn) {
      this.doPageTrack(path, this.payload);
    }
  }

  private doPageTrack(path: string, payload: IUserTrackingPayload) {
    if (this.pageTrackingRedundancyFilterService.isPathRedundant(path)) {
      return;
    }
    this.pageTrackingRedundancyFilterService.saveCurrentPath(path);

    const toolName = payload.data.pageInfo.toolName;
    const segmentedPath = path.substring(1).replace(/\//g, ':');
    const language = this.translateService.currentLang ?? this.translateService.defaultLang;
    const newPayload = {
      ...payload,
      event: AdobeAnalyticsEvent.PageLoad,
      data: {
        user: {
          puid: this.userInfo?.userId ?? '',
          accountName: this.userInfo?.companyName ?? ''
        },
        pageInfo: {
          ...payload.data.pageInfo,
          pageName: `${toolName}:${segmentedPath}`,
          language,
          channelTitle: `${this.getPageDisplayName(path)} page`,
          hier1: `${toolName}${path}/${language}`,
          toolName,
        },
        genericElementAction: null
      },
    };
    this.payload = newPayload;

    if (!this.dryRunTracking) {
      if (typeof _satellite !== 'undefined' && _satellite) {
        // _satellite.track(AdobeAnalyticsEvent.PageLoad, newPayload); // Manual call not supported for now.
        digitalData.push(newPayload);
      }
    }
    this.logTracking('Page load', '', newPayload.data.pageInfo.pageName);
  }

  /**
   * @param action associated with the event
   * @param properties associated with the event
   */
  private eventTrack(action: string, properties: {[k: string]: string}) {
    this.refreshUserInfo();
    if (!this.isTrackingDisabled && this.isUserLoggedIn) {
      this.doEventTrack(action, properties, this.payload);
    }
  }

  private doEventTrack(action: string, properties: {[k: string]: string}, payload: IUserTrackingPayload) {
    const newPayload = {
      ...payload,
      event: AdobeAnalyticsEvent.UserInteraction,
      data: {
        eventName: properties.eventName ?? 'User interaction',
        user: {
          puid: this.userInfo?.userId ?? '',
          accountName: this.userInfo?.companyName ?? ''
        },
        pageInfo: {
          ...payload.data.pageInfo
        },
        genericElementAction: action,
        genericElementCategory: properties.category
      },
      properties,
    };
    // Cleanup for tab change event
    if (properties.tabName) {
      (newPayload as IUserTrackingPayload).data.tabName = properties.tabName;
      delete newPayload.properties.tabName;
      (newPayload as IUserTrackingPayload).data.tabName = properties.eventName;
      delete newPayload.properties.eventName;
    }
    this.payload = newPayload;
    if (!this.dryRunTracking) {
      if (typeof _satellite !== 'undefined' && _satellite) {
        digitalData.push(newPayload);
      }
    }
    this.logTracking(`${newPayload.data.eventName}`, properties.category, action, properties);
  }

  private getPageDisplayName(path: string): string {
    // Return the last part of the path which is not an id or a tab
    // Ex: 'http://<server>/facility/862/installation/946b39c2-0351-4527-b830-c34ddd819c28;tab=plantroom' -> 'installation'

    const isNumber = (s: string) => {
      const parsed = parseInt(s, 10);
      return !isNaN(parsed) && isFinite(parsed);
    };

    const isGuid = (s: string) => {
      return new RegExp(/^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/i).test(s);
    };

    const tabSeparator = path.indexOf(';');
    if (tabSeparator >= 0) {
      path = path.substring(0, tabSeparator);
    }
    const segments = path.split('/');
    for (let i = segments.length - 1; i >= 0; i--) {
      const segment = segments[i];
      if (!isNumber(segment) && !isGuid(segment)) {
        return segment;
      }
    }
    return path;
  }

  private logTracking(interactionType: string, category: string, message: string, properties?: {[k: string]: string}) {
    if (this.logTrackingEvents) {
      let strProperties: string | null = null;
      if (properties) {
        const filteredProperties = {...properties};
        delete filteredProperties['category'];
        strProperties = Object.keys(filteredProperties).length > 0 ? JSON.stringify(filteredProperties) : null;
      }
      console.log(`%cTracking (${interactionType}):`, 'color: lightgreen; font-style: italic', category ? `${category}.${message}` : message, strProperties);
    }
  }

  private getUserInfoFromLoginToken(): UserInfo | null {
    var key = Object.keys(sessionStorage).find(key => key.startsWith("oidc.user:"));
    if (key) {
      try {
        const strTokenInfo = sessionStorage[key];
        const tokenInfo = JSON.parse(strTokenInfo);
        return {
          userId: tokenInfo.profile?.puid,
          signInName: tokenInfo.profile?.signInName,
          companyName: tokenInfo.profile?.Company
        };
      }
      catch (e) {
        // NOOP
      }
    }
    return null;
  }

  private refreshUserInfo() {
    if (!this.userInfo?.userId) {
      this.userInfo = this.getUserInfoFromLoginToken();
    }
  }
}
