import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AppError, AppErrorService, PageInfo } from 'shared';
import { PageInfoService } from '../../services/page-info.service';
import { AccessClaim } from 'projects/shared/src/lib/interfaces/access';

@Component({
  selector: 'app-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.scss'],
})
export class AccountPageComponent implements OnInit {
  public pageInfo: PageInfo;
  public pageError$: Observable<AppError>;
  public deviceManagementAvailable$: Observable<boolean>;

  constructor(private pageInfoService: PageInfoService, private userService: UserService, private errorService: AppErrorService) {}

  ngOnInit(): void {
    this.pageInfo = this.pageInfoService.account();
    this.deviceManagementAvailable$ = this.userService.hasClaim(AccessClaim.CustomerPortalLicenseManagement);
    this.pageError$ = this.errorService.createPageErrorObservable([]);
  }
}
