import { CONNECTION_SPACING } from '../../Common';
import { LabelPosition, SystemMeta } from '../../types';
import { EConnectionType, SystemType } from '../../../state';
import { EConnectionPointPlacement, EEquipment } from '../../../types';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/cascade-tanks.svg';
import { ICascadeBufferTankChargingSystemFormInterface } from '../../../../models/systems/cascadeBufferTankChargingSystem/cascadeBufferTankChargingSystemFormInterface';
import cascadeBufferTankChargingSystemForm from '../../../../models/systems/cascadeBufferTankChargingSystem/cascadeBufferTankChargingSystemForm.json';

export const WIDTH = 750;
export const HEIGHT = 540;

export const FORWARD_Y = 360;
export const RETURN_Y = FORWARD_Y + CONNECTION_SPACING;

export const TOP_TANK_Y = 104;
export const TANK_X = 450;
export const BOTTOM_TANK_Y = TOP_TANK_Y + 190;
export const TOP_TANK_SUPPLY_Y = 171;

export const FORWARD_PIPE_X2 = TANK_X - 60;
export const RETURN_PIPE_X2 = TANK_X;

export const SECONDARY_LOOP_RIGHT = TANK_X + 236;
export const SECONDARY_LOOP_TOP = 50;

export const PUMP_RETURN_X = 395;
export const PUMP_RETURN_Y = RETURN_Y;

export const CIRCULATION_PUMP_X = TANK_X + 207;
export const CIRCULATION_PUMP_Y = 101;

export const COLD_SUPPLY_TOP = RETURN_Y - 1;
export const COLD_SUPPLY_BOTTOM = 500;
export const COLD_SUPPLY_LEFT = TANK_X - 30;
export const COLD_SUPPLY_RIGHT = COLD_SUPPLY_LEFT + 125;
export const COLD_SUPPLY_FLOW_X = COLD_SUPPLY_LEFT + 70;

export const T_CR_X = TANK_X + 155;
export const T_CR_Y = 105;

export const T_1_TOP_X = TANK_X + 4;
export const T_1_TOP_Y = 140;

const TEMPERATURE_SENSOR_TANK_X = TANK_X + 102;

export const T_1_CENTER_X = TEMPERATURE_SENSOR_TANK_X;
export const T_1_CENTER_Y = 210;

export const T_1_BOTTOM_X = TEMPERATURE_SENSOR_TANK_X;
export const T_1_BOTTOM_Y = 260;

export const T_2_TOP_X = TEMPERATURE_SENSOR_TANK_X;
export const T_2_TOP_Y = 330;

export const T_2_CENTER_X = TEMPERATURE_SENSOR_TANK_X;
export const T_2_CENTER_Y = 390;

export const T_2_BOTTOM_X = TEMPERATURE_SENSOR_TANK_X;
export const T_2_BOTTOM_Y = 450;

export const cascadeBufferTankChargingMeta: SystemMeta = {
  systemType: SystemType.CascadeBufferTankChargingSystem,
  name: 'Cascade Buffer Tank Charging System',
  thumbnail,
  form: cascadeBufferTankChargingSystemForm,
  width: WIDTH,
  height: HEIGHT,

  calculateConnectionPoints: () => [
    {
      key: EConnectionPointPlacement.connectionPrimaryForward,
      x: 0,
      y: FORWARD_Y,
      type: EConnectionType.supply,
      dotted: false,
    },
    {
      key: EConnectionPointPlacement.connectionPrimaryReturn,
      x: 0,
      y: RETURN_Y,
      type: EConnectionType.return,
      dotted: true,
    },
  ],
};

export const getCascadeBufferTankChargingLabels = (systemInfo: ICascadeBufferTankChargingSystemFormInterface): LabelPosition[] => {
  const T_CR: LabelPosition[] = [
    {
      id: EEquipment.T_CR,
      x: T_CR_X - 10,
      y: T_CR_Y + 10,
    },
  ];

  const P_CR: LabelPosition[] = [
    {
      id: EEquipment.P_CR,
      x: CIRCULATION_PUMP_X - 10,
      y: CIRCULATION_PUMP_Y + 40,
    },
  ];

  const P_R: LabelPosition[] = [
    {
      id: EEquipment.P_SR,
      x: PUMP_RETURN_X,
      y: PUMP_RETURN_Y - 35,
    },
  ];

  const Q_SC: LabelPosition[] = systemInfo.Q_SC_present
    ? [
        {
          id: EEquipment.Q_SC,
          x: COLD_SUPPLY_LEFT + 50,
          y: COLD_SUPPLY_TOP + 70,
        },
      ]
    : [];

  const T_1_TOP: LabelPosition[] = [
    {
      id: EEquipment.T_1_TOP,
      x: T_1_TOP_X - 40,
      y: T_1_TOP_Y,
      horizontalAlignment: 'end',
    },
  ];

  const T_1_MIDDLE: LabelPosition[] = (systemInfo.T_1_MIDDLE_present || systemInfo.T_START === 'MiddleFirst' || systemInfo.T_STOP === 'MiddleFirst')
    ? [
        {
          id: EEquipment.T_1_MIDDLE,
          x: T_1_CENTER_X + 40,
          y: T_1_CENTER_Y,
        },
      ]
    : [];

  const T_1_BOTTOM: LabelPosition[] = (systemInfo.T_1_BOTTOM_present || systemInfo.T_START === 'BottomFirst' || systemInfo.T_STOP === 'BottomFirst')
    ? [
        {
          id: EEquipment.T_1_BOTTOM,
          x: T_1_BOTTOM_X + 40,
          y: T_1_BOTTOM_Y,
        },
      ]
    : [];

  const T_2_TOP: LabelPosition[] = (systemInfo.T_2_TOP_present || systemInfo.T_START === 'TopSecond' || systemInfo.T_STOP === 'TopSecond')
    ? [
        {
          id: EEquipment.T_2_TOP,
          x: T_2_TOP_X + 40,
          y: T_2_TOP_Y,
        },
      ]
    : [];

  const T_2_MIDDLE: LabelPosition[] = (systemInfo.T_2_MIDDLE_present || systemInfo.T_START === 'MiddleSecond' || systemInfo.T_STOP === 'MiddleSecond')
    ? [
        {
          id: EEquipment.T_2_MIDDLE,
          x: T_2_CENTER_X + 40,
          y: T_2_CENTER_Y,
        },
      ]
    : [];

  const T_2_BOTTOM: LabelPosition[] = (systemInfo.T_2_BOTTOM_present || systemInfo.T_START === 'BottomSecond' || systemInfo.T_STOP === 'BottomSecond')
    ? [
        {
          id: EEquipment.T_2_BOTTOM,
          x: T_2_BOTTOM_X + 40,
          y: T_2_BOTTOM_Y,
        },
      ]
    : [];

  return [...T_CR, ...Q_SC, ...P_CR, ...P_R, ...T_1_TOP, ...T_1_MIDDLE, ...T_1_BOTTOM, ...T_2_TOP, ...T_2_MIDDLE, ...T_2_BOTTOM];
};
