import { Form } from './Form';
import { TabState } from '../state';
import classnames from 'classnames';
import React, { useCallback } from 'react';
import { IForm } from '../../models/form.interface';
import { SystemState } from '../../schematic/state';
import { AppDispatch } from '../../schematic/reducer';
import { setSystemInfo } from '../../schematic/actions';
import { buildingFormValid, systemValid } from '../util';
import { setBuildingInfo, setTabState } from '../actions';
import buildingForm from '../../models/building/buildingForm.json';
import { translationService } from '../../services/translationService';
import { systemMetadataMap } from '../../schematic/components/system-metadata';
import panelValidSVG from '!svg-url-loader!../../../assets/icons/panel-valid.svg';
import panelInvalidSVG from '!svg-url-loader!../../../assets/icons/panel-invalid.svg';
import { IBuildingFormInterface } from '../../models/building/buildingFormInterface';

export interface FormSidebarProps {
  tabState: TabState;
  dispatch: AppDispatch;
  buildingInfo: IBuildingFormInterface;
  systemState: SystemState | null | undefined;
}

export const getCurrentFormAndData = (
  tabState: TabState,
  buildingInfo: IBuildingFormInterface,
  systemState: SystemState | null | undefined
): { form: IForm | null; data: any } => {
  if (tabState === TabState.Clarifications) {
    return { form: buildingForm as IForm, data: buildingInfo };
  }
  if (!systemState) {
    return { form: null, data: null };
  }
  const systemMeta = systemMetadataMap[systemState.type];
  return { form: systemMeta.form, data: systemState.systemInfo };
};

export const FormSidebar: React.FC<FormSidebarProps> = React.memo(({ tabState, dispatch, buildingInfo, systemState }) => {

  const onFormChange = useCallback((newData: any) => {
    if (tabState === TabState.Clarifications) {
      dispatch(setBuildingInfo({ buildingInfo: newData }));
      return;
    }
    if (systemState) {
      dispatch(setSystemInfo({ systemId: systemState.hasId, systemInfo: newData, configured: false }));
    }
  },
  [tabState, systemState?.hasId]);

  const { form, data } = getCurrentFormAndData(tabState, buildingInfo, systemState);

  return (
    <div className="sidebar form-sidebar">
      <div className="tabs">
        <div className="tab-header">
          <div
            className={classnames({ tab: true, selected: tabState === TabState.Clarifications })}
            onClick={() => dispatch(setTabState({ tabState: TabState.Clarifications }))}
          >
            { translationService.getTranslation('building') }
            { buildingFormValid(buildingInfo) ? (
              <img className="tab-validity-icon" src={panelValidSVG} alt="Valid icon" />
            ) : (
              <img className="tab-validity-icon" src={panelInvalidSVG} alt="Invalid icon" />
            )}
          </div>
          <div
            className={classnames({ tab: true, selected: tabState === TabState.System })}
            onClick={() => dispatch(setTabState({ tabState: TabState.System }))}
          >
            { translationService.getTranslation('system')}
            { systemState && (systemValid(systemState) ? (
              <img className="tab-validity-icon" src={panelValidSVG} alt="Valid icon" />
            ) : (
              <img className="tab-validity-icon" src={panelInvalidSVG} alt="Invalid icon" />
            ))}
          </div>
        </div>
        <div className="tab-content">
          {form && (
            <Form
              key={tabState === TabState.Clarifications ? 'clarifications' : systemState?.hasId}
              form={form}
              data={data}
              onChange={onFormChange} />
          )}
        </div>
      </div>
    </div>
  );
});
