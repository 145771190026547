import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { User, UserManager } from 'oidc-client';
import { ConfigurationService } from './configuration.service';
import { BehaviorSubject, AsyncSubject, Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

const toAbsoluteUrl = (path: string): string => {
  return new URL(path, document.baseURI).href;
};

const redirectUri = toAbsoluteUrl('callback');
const logoutRedirectUri = toAbsoluteUrl('logout/callback');
const silentRedirectUri = toAbsoluteUrl('silent-renew.html');

const SESSION_STORAGE_PATH_KEY = '@@ams-serviceportal/redirect-uri';

@Injectable({
  providedIn: 'root'
})
export class AuthService   {
  private userManager = new AsyncSubject<UserManager>();
  public currentUser: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  public authenticated = new AsyncSubject<boolean>();
  constructor(
    private configurationService: ConfigurationService,
    private router: Router
  ) {
    // initialize when config is loaded
    this.configurationService.configuration.subscribe(configuration => {
      if (configuration) {
        const settings = {
          authority: configuration.authority,
          client_id: configuration.client_id,
          redirect_uri: redirectUri,
          post_logout_redirect_uri: logoutRedirectUri,
          silent_redirect_uri: silentRedirectUri,
          automaticSilentRenew: true,
          response_type: 'token id_token',
          scope: `openid ${configuration.clientScope}`,
          loadUserInfo: false
        };
        this.userManager.next(new UserManager(settings));
        this.userManager.complete();
        (async () => {
          try {
            const userManager = await this.userManager.toPromise();
            if(!userManager) return;
            const absolutePath = `${window.location.origin}${window.location.pathname}`;
            if (absolutePath === redirectUri) {
              const user = await userManager.signinRedirectCallback();
              this.currentUser.next(user);
              this.authenticated.next(true);
              this.authenticated.complete();
              userManager.events.addUserLoaded(u => {
                this.currentUser.next(u);
              });
              const redirectTo = sessionStorage.getItem(SESSION_STORAGE_PATH_KEY) || '/';
              sessionStorage.removeItem(SESSION_STORAGE_PATH_KEY);
              this.router.navigateByUrl(redirectTo, {replaceUrl: true});
            } else if (absolutePath === silentRedirectUri) {
              await userManager.signinSilentCallback();
            } else if (absolutePath === logoutRedirectUri) {
              await userManager.signoutRedirectCallback();
              window.location.replace(document.baseURI);
            } else {
              try {
                // check if we have a session with AD so silent renew does not fail later
                const newUser = await userManager.signinSilent();
                this.currentUser.next(newUser);
                this.authenticated.next(true);
                this.authenticated.complete();
                return;
              } catch (e) {
                // if silent renew fails we do not have a session with the AD.
                this.authenticated.next(false);
                this.authenticated.complete();
              }
            }
          } catch (e) {
            console.error(e);
            window.location.replace(document.baseURI);
          }
        })();
      }
    });
  }

  public signout() {
    this.userManager.subscribe(userManager => {
      userManager.signoutRedirect();
    });
  }

  public signinRedirect(redirectTo: string, signup = false) {
    sessionStorage.setItem(SESSION_STORAGE_PATH_KEY, redirectTo || '/');
    this.userManager.subscribe(userManager => {
      const extraQueryParams: Record<string, string>  = {};
      if (signup) {
        extraQueryParams.showSignup = '1';
      }
      userManager.signinRedirect({extraQueryParams});
    });
  }

  public signinRedirectAfterSignin(redirectTo: string) {
    sessionStorage.setItem(SESSION_STORAGE_PATH_KEY, redirectTo || '/');
    this.userManager.subscribe(userManager => {
      const extraQueryParams: Record<string, string>  = {};
      userManager.signinRedirect({extraQueryParams})
        .then(() => {
          location.href = redirectTo;
        });
    });
  }

  public signinSilent(): Observable<User> {
    return this.userManager.pipe(
      mergeMap(async userManager => {
        return await userManager.signinSilent();
      }),
      tap(user => {
        this.currentUser.next(user);
        this.authenticated.next(true);
        this.authenticated.complete();
      })
    );
  }

  // The canActivate callback serves two purposes:
  // - Delay loading of route until authentication is completed
  // - Capture redirect URI, so that we can perform a signin redirect if the user is not authenticated
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    this.authenticated.subscribe(authenticated => {
      if (!authenticated) {
        this.signinRedirect(state.url);
      }
    });
    return this.authenticated;
  }
}
