import { Component, Input, OnInit } from '@angular/core';
import { AlarmOverview, Application } from '../../../interfaces/alarm';
import { Observable } from 'rxjs';
import { DataPointsResult, SystemDevice } from '../../../interfaces/data-points';
import { getPumpOptions, getPumpType, systemsToPumpDevice } from '../../../utils/data-point-utils';
import { SchematicsService } from '../../../services/schematics.service';
import { AppModel } from '@ams/dumbledore';
import { PumpOption } from 'projects/customerportal/src/app/services/installation-configuration.service';
import { Installation } from '../../../interfaces/facilty';

@Component({
  selector: 'app-boiler-dashboard',
  templateUrl: './boiler-dashboard.component.html',
  styleUrls: ['./boiler-dashboard.component.scss'],
})
export class BoilerDashboardComponent implements OnInit {
  @Input() public alarm$: Observable<AlarmOverview>;
  @Input() public application: Application;
  @Input() public installation$: Observable<Installation>;
  @Input() public schematic$: Observable<AppModel>;
  @Input() public dataPoints$: Observable<DataPointsResult>;

  public pumpType$: Observable<string>;
  public pumpDevice$: Observable<SystemDevice | undefined>;
  public pumpOptions$: Observable<PumpOption[] | undefined>;

  constructor(private schematicsService: SchematicsService) {}

  ngOnInit(): void {
    this.pumpDevice$ = this.dataPoints$.pipe(systemsToPumpDevice(this.application.id));

    this.pumpType$ = getPumpType(this.schematic$, this.application.id);

    this.pumpOptions$ = getPumpOptions(this.pumpDevice$, this.schematicsService, this.application.id);
  }
}
