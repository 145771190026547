import { Component, Input, OnInit } from '@angular/core';
import { Installation } from '../../../interfaces/facilty';
import { Application } from '../../../interfaces/alarm';
import { BehaviorSubject, Observable } from 'rxjs';
import { InstallationConfigurationService } from '../../../services/installation-configuration.service';
import { map } from 'rxjs/operators';
import { WeatherCompensationCurve } from '../../../interfaces/weather-compensation';
import { UserService } from '../../../services/user.service';
import { SchematicsService } from '../../../services/schematics.service';
import { UserTrackingHelperService } from '../../../services/user-tracking-helper.service';
import { AccessClaim } from 'projects/shared/src/lib/interfaces/access';

@Component({
  selector: 'app-building-connect-weather-curve-tile',
  templateUrl: './building-connect-weather-curve-tile.component.html',
  styleUrls: ['./building-connect-weather-curve-tile.component.scss'],
})
export class BuildingConnectWeatherCurveTileComponent implements OnInit {
  @Input() installation: Installation;
  @Input() application: Application;
  @Input() outdoorTemp$: Observable<number>;

  editingEnabled$: Observable<boolean>;
  data$: Observable<Array<[number, number]>>;
  editing = false;
  curve$ = new BehaviorSubject<WeatherCompensationCurve | null>(null);

  adjustCurveClicked() {
    this.editing = !this.editing;
    this.userTrackingHelperService.trackUserAction('applicationWeatherCompensation', 'adjustCurveToggled');
  }

  cancelEditing() {
    this.editing = false;
    this.userTrackingHelperService.trackUserAction('weatherCompensation', 'cancelClicked');
  }

  formSubmitted(curve: WeatherCompensationCurve) {
    this.editing = false;
    this.userTrackingHelperService.trackUserAction('weatherCompensation', 'saveClicked');
    this.curve$.next(curve);
    this.weatherCompensationService.setWeathercompensationCurve(this.installation.id, this.application.id, curve);
  }

  constructor(
    private weatherCompensationService: InstallationConfigurationService,
    private userService: UserService,
    private schematicService: SchematicsService,
    private userTrackingHelperService: UserTrackingHelperService
  ) {}

  ngOnInit(): void {
    this.editingEnabled$ = this.userService.hasClaim(AccessClaim.CustomerPortalWrite);
    this.schematicService.getWeatherCompensationCurve(this.application.id).subscribe((curve) => {
      this.curve$.next(curve);
    });
    this.data$ = this.curve$.pipe(
      map((curve: Array<[number, number]> | null) => {
        if (!curve) {
          return [];
        }
        return curve;
      })
    );
  }

  updateCurve(curve: Array<[number, number]>) {
    this.curve$.next(curve);
  }
}
