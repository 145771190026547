import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { DataPointsResult, DeviceDataPoint, System, SystemDevice } from '../../interfaces/data-points';
import { formatDataPoint, getDataOutdatedState } from '../../utils/data-point-utils';
import { Value } from '../multi-value-tile/multi-value-tile.component';
import { ModalService } from 'shared';
import {
  InstallationConfigurationService,
  UpdateSystemModeAndValvePositionDTO,
} from 'projects/customerportal/src/app/services/installation-configuration.service';
import { UserTrackingHelperService } from '../../services/user-tracking-helper.service';
import { EValueType } from '@ams/dumbledore';
import { ValveModalComponent, ValveModalResponse } from '../valve-modal/valve-modal.component';
import { SystemControlMode } from '../../interfaces/system-control-mode';
import { DataPointsService } from '../../services/data-points.service';

@Component({
  selector: 'app-valve-status-tile[installationId][applicationId]',
  templateUrl: './valve-status-tile.component.html',
  styleUrls: ['./valve-status-tile.component.scss'],
})
export class ValveStatusTileComponent implements OnInit, OnDestroy {
  @Input() public installationId: string;
  @Input() public applicationId: string;
  @Input() public title: string;
  @Input() public energyValve: boolean;
  @Input() public dataPoints$: Observable<DataPointsResult>;
  @Input() public valveDevice$: Observable<SystemDevice>;
  public values$: Observable<Value[]>;
  public openingSetpoint: DeviceDataPoint | null;
  public valuesToShow = 2;
  public systemControlMode: SystemControlMode;
  public SystemControlModeOptions = SystemControlMode;
  private systemControlModeSubscription: Subscription;
  private valveDeviceSubscription: Subscription;

  private orderedEnergyValues: string[] = [
    'Valve_CurrentOpening',
    'Valve_OpeningSetpoint',
    'Valve_HeatOutput',
    'Valve_Flow',
    'Valve_AccumulatedEnergyConsumption',
    'Valve_SupplyTemperature',
    'Valve_ReturnTemperature',
    'Valve_DeltaT',
  ];

  constructor(
    private modalService: ModalService,
    private installationConfigurationService: InstallationConfigurationService,
    private userTrackingHelperService: UserTrackingHelperService,
    private dataPointsService: DataPointsService
  ) {}

  ngOnInit(): void {
    this.values$ = this.dataPoints$.pipe(
      map((dataPoints) => {
        const allDataPoints = this.getAllDataPoints(dataPoints.data);
        const energyValveValues = this.getEnergyValveDataPoints(allDataPoints);
        return this.energyValve ? this.getEnergyTileValues(energyValveValues) : this.getDefaultTileValues(allDataPoints);
      })
    );

    this.systemControlModeSubscription = this.dataPointsService.getSystemControlMode(this.applicationId).subscribe((controlMode) => {
      this.systemControlMode = controlMode;
    });

    if (this.energyValve) {
      this.valuesToShow = 8;
    }
  }

  private getDefaultTileValues(allDataPoints: DeviceDataPoint[]) {
    const currentValue = this.getValveDataPoint(allDataPoints);
    this.openingSetpoint = this.getValveSetPoint(allDataPoints);
    return [
      {
        titleKey: 'valve-status-tile.labels.opening-setpoint',
        value: formatDataPoint(this.openingSetpoint),
        state: getDataOutdatedState(this.openingSetpoint),
      },
      {
        titleKey: 'valve-status-tile.labels.opening-current',
        value: formatDataPoint(currentValue),
        state: getDataOutdatedState(currentValue),
      },
    ];
  }

  private getEnergyTileValues(energyValues: DeviceDataPoint[] | null) {
    if (!energyValues) return [];
    const values: Value[] = [];
    this.orderedEnergyValues.forEach((clientId) => {
      const deviceDataPoint = this.getValueFromArray(energyValues, clientId);
      values.push({
        titleKey: `valve-status-tile.energy-labels.valve${clientId
          .split('_')[1]
          .replace(/([A-Z])/g, '-$1')
          .toLocaleLowerCase()}`,
        value: formatDataPoint(deviceDataPoint),
        state: getDataOutdatedState(deviceDataPoint),
      });
    });
    return values;
  }

  private getValueFromArray(energyValues: DeviceDataPoint[], value: string) {
    return energyValues.find((item) => item.clientId === value);
  }

  ngOnDestroy(): void {
    this.systemControlModeSubscription?.unsubscribe();
    this.valveDeviceSubscription?.unsubscribe();
  }

  adjustValve() {
    this.userTrackingHelperService.trackUserAction('valveSetpointTile', 'adjustValveSetpointClicked');
    const data = {
      applicationId: this.applicationId,
      installationId: this.installationId,
      openingSetpoint: this.openingSetpoint,
    };
    this.modalService.openDialog<ValveModalResponse>(ValveModalComponent, { data }).subscribe((response) => {
      if (response.dismissed) {
        return;
      }
      const { openingSetpoint, selectedSystemControlMode } = response.result;
      this.setValveSetpoint(openingSetpoint, selectedSystemControlMode);
    });
  }

  private setValveSetpoint(setpointPercentage: number, selectedSystemControlMode: SystemControlMode) {
    this.valveDeviceSubscription = this.valveDevice$.pipe(first()).subscribe((valveDevice) => {
      const data: UpdateSystemModeAndValvePositionDTO = {
        systemControlMode: selectedSystemControlMode,
        valveId: valveDevice.deviceId,
        valvePosition:
          selectedSystemControlMode === SystemControlMode.Manual ? { valvePositionFraction: setpointPercentage / 100 } : undefined,
      };
      this.installationConfigurationService.setSystemModeAndValvePosition(this.installationId, this.applicationId, data);
    });
  }

  private getAllDataPoints(systems: System[]) {
    const system = systems.find((s) => s.systemId === this.applicationId);
    return system?.devices?.flatMap((d) => d?.dataPoints) || [];
  }

  private getValveSetPoint = (allDataPoints: DeviceDataPoint[]): DeviceDataPoint | null => {
    return (
      allDataPoints.find((dataPoint) => {
        return dataPoint.valueType === EValueType.setPoint && dataPoint.dumbledoreId.indexOf('MV_') !== -1;
      }) || null
    );
  };

  private getValveDataPoint = (allDataPoints: DeviceDataPoint[]): DeviceDataPoint | null => {
    return (
      allDataPoints.find((dataPoint) => {
        return dataPoint.valueType === EValueType.value && dataPoint.dumbledoreId.indexOf('MV_') !== -1;
      }) || null
    );
  };

  private getEnergyValveDataPoints = (allDataPoints: DeviceDataPoint[]): DeviceDataPoint[] | null => {
    return (
      allDataPoints.filter((dataPoint) => {
        return dataPoint.clientId && dataPoint.clientId.indexOf('Valve_') !== -1;
      }) || null
    );
  };

  getTitle(): string {
    return this.energyValve ? 'energy-valve-status-tile.title' : 'valve-status-tile.title';
  }
}
