<gbc-modal [title]="'mixit-setpoint-modal.setpoint' | translate" class="gbc-with-dividers" data-test-id='setpoint-modal'>
  <gbc-modal-content>
    <p class='modal-text'>
      {{ 'setpoint-modal.setpoint-description' | translate }}
    </p>
    <form [formGroup]='form'>
      <gbc-info-container [header]="'setpoint.source.label' | translate">
        {{ 'setpoint.source.fixed' | translate }}
      </gbc-info-container>
      <gbc-modal-section>
        <gbc-modal-section-column>
          <gbc-input-label>{{ 'setpoint-modal.form.temperatureSetpoint' | translate }}</gbc-input-label>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-numeric-field
            [errorDescriptionData]='{ min: min, max: max }'
            [max]='max'
            [min]='min'
            errorDescriptionTranslationString='setpoint-modal.validation-errors.between'
            formControlName='temperatureSetpoint'
            step='1'
            unit='°C'>
          </gbc-input-numeric-field>
        </gbc-modal-section-column>
      </gbc-modal-section>
    </form>
  </gbc-modal-content>
  <gbc-modal-buttons>
    <button (click)='close()' gbc-modal-button>
      {{ 'app-cancel' | translate }}
    </button>
    <button (click)='save()' [disabled]='(isValid$ | async) === false' class='primary' gbc-modal-button>
      {{ 'app-save' | translate }}
    </button>
  </gbc-modal-buttons>
</gbc-modal>
