import React from 'react';
import { CONNECTION_SPACING } from '../Common';
import coolingCoilIcon from '!raw-loader!../../../../assets/icons/cooling-coil.svg';
import { SvgImage } from '../SvgImage';
import { Pipe, PipeType } from '../Pipe';
const COOLING_COIL_SVG_WIDTH = 54;
const COOLING_COIL_SVG_HEIGHT = 33;
const ICON_LEFT = CONNECTION_SPACING / 2 - COOLING_COIL_SVG_WIDTH / 2;
export const FORWARD_Y = COOLING_COIL_SVG_HEIGHT / 2;
export const RETURN_Y = COOLING_COIL_SVG_HEIGHT / 2;
export const CoolingCoil: React.FC<{ x: number; y: number; reversedWarmCold: boolean }> = ({ x, y, reversedWarmCold }) => {
  y = reversedWarmCold ? y + COOLING_COIL_SVG_HEIGHT : y;
  x = reversedWarmCold ? x + CONNECTION_SPACING : x;
  const rotation = reversedWarmCold ? 180 : 0;
  return (
    <g transform={`translate(${x}, ${y}) rotate(${rotation})`}>
      <Pipe x1={0} y1={FORWARD_Y} x2={ICON_LEFT + 4} y2={FORWARD_Y} type={PipeType.ColdSupply} />
      <Pipe x1={CONNECTION_SPACING} y1={RETURN_Y} x2={ICON_LEFT + COOLING_COIL_SVG_WIDTH - 2} y2={RETURN_Y} type={PipeType.WarmReturn} />
      <SvgImage svgText={coolingCoilIcon} x={ICON_LEFT} y={0} />
    </g>
  );
};
