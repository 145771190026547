import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { SnackBarService } from './snack-bar.service';
import { catchError, finalize, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ICommandOptions } from '../interfaces/command';
import { TranslateService } from '@ngx-translate/core';
import { AppError } from './app-error.service';
import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root'
})
export class CommandService {

  private DEFAULT_SUCCESS_KEY = 'command-service.operation-succeeded-snack';
  private DEFAULT_ERROR_KEY = 'command-service.operation-failed-snack';
  commandInProgress = new BehaviorSubject(false);

  constructor(
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private modalService: ModalService
  ) {}


  // This should be used when executing a blocking command.
  // This could be as part of a flow, ie. updating a user, claiming a device, where there is nothing to show while we wait for the result.
  // Use of <gbc-command-spinner> is required on that page, in order for a spinner to be shown properly
  // If you don't want a giant loading spinner blocking the page, then use executeAsync below
  execute<T>(commandFn: () => Observable<T>, options?: ICommandOptions): Observable<T> {
    if (this.commandInProgress.value) {
      console.error('attempted to execute command while another command is already in progress');
      return of();
    }
    this.commandInProgress.next(true);
    const result = commandFn().pipe(
      tap(() => {
        if (options?.successMessageKey) {
          this.snackBarService.show(this.translateService.instant(options.successMessageKey || this.DEFAULT_SUCCESS_KEY));
        }
      }),
      tap({
        next: () => this.commandInProgress.next(false),
        error: () => this.commandInProgress.next(false)
      }),
      shareReplay()
    );
    result.pipe(
      catchError(e => {
        const modalCompleted$ = e instanceof AppError ?
          this.modalService.showErrorModal(e.userTitle, e.userMessage) :
          this.modalService.showErrorModal('Error', this.translateService.instant(this.DEFAULT_ERROR_KEY));
        return modalCompleted$.pipe(
          switchMap(() => of())
        );
      }),
    ).subscribe(() => undefined, () => undefined);
    return result;
  }

  // This one is for use on dashboards or other places, where we want to send a command, but not block the entire flow of the page.
  executeAsync<T>(commandFn: () => Observable<T>, options?: ICommandOptions): Observable<T> {
    const result = commandFn().pipe(
      tap(() => {
        if (options?.successMessageKey) {
          this.snackBarService.show(this.translateService.instant(options.successMessageKey || this.DEFAULT_SUCCESS_KEY));
        }
      }),
      shareReplay()
    );
    result.pipe(
      catchError(e => {
        const modalCompleted$ = e instanceof AppError ?
          this.modalService.showErrorModal(e.userTitle, e.userMessage) :
          this.modalService.showErrorModal('Error', this.translateService.instant(this.DEFAULT_ERROR_KEY));
        return modalCompleted$.pipe(
          switchMap(() => of())
        );
      }),
    ).subscribe(() => undefined, () => undefined);
    return result;
  }
}
