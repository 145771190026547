import { Component, Input, OnInit } from '@angular/core';
import { DataPointsService, ReportPeriod, TimeSerie, TimeSeriesDetails } from '../../../services/data-points.service';
import { combineLatest, Observable } from 'rxjs';
import { Options, SeriesOptionsType } from 'highcharts';
import { DataPointsResult } from '../../../interfaces/data-points';
import { Application } from '../../../interfaces/alarm';
import { TranslateService } from '@ngx-translate/core';
import { filter, first, map, shareReplay, switchMap } from 'rxjs/operators';
import { getDataPoint } from '../../../utils/data-point-utils';
import { EEquipment } from '@ams/dumbledore';
import { UserTrackingPeriodOptions } from '../../../services/user-tracking-helper.service';

@Component({
  selector: 'app-water-meter-chart-tile',
  templateUrl: './water-meter-chart-tile.component.html',
  styleUrls: ['./water-meter-chart-tile.component.scss'],
})
export class WaterMeterChartTileComponent implements OnInit {
  @Input() public dataPoints$: Observable<DataPointsResult>;
  @Input() applicationId: string;
  @Input() installationId: string;
  @Input() userTrackingPeriodOptions: UserTrackingPeriodOptions;

  public options: Options;
  public visible$: Observable<boolean>;
  public legends$ = combineLatest([this.translateService.get('water-meter-chart-tile.flow')]);

  constructor(private translateService: TranslateService, private dataPointsService: DataPointsService) {
    this.options = {
      colors: ['#0069C4'],
      yAxis: {
        title: {
          text: null,
        },
        labels: {
          format: '{value} m3/h',
        },
        min: 0,
        softMax: 1.0,
        opposite: true,
      },
      plotOptions: {
        series: {
          tooltip: {
            valueDecimals: 1,
          },
        },
      },
    };
  }

  getSeries: (period: ReportPeriod) => Observable<SeriesOptionsType[] | null[]>;

  ngOnInit() {
    const dataPoints$ = this.dataPoints$.pipe(
      filter((dataPoints) => !!dataPoints?.data?.length),
      map((dataPoints) => {
        return [
          getDataPoint({
            applicationId: this.applicationId,
            systems: dataPoints?.data || [],
            dumbledoreId: EEquipment.Q_SC,
            humanReadableId: '',
          }),
        ];
      }),
      first()
    );
    this.visible$ = dataPoints$.pipe(map((dataPoints) => dataPoints.some((dataPoint) => dataPoint)));

    this.getSeries = (period) =>
      combineLatest([this.legends$, dataPoints$]).pipe(
        switchMap(([legends, dataPoints]) => {
          const timeSeriesDetails: TimeSeriesDetails = {
            dataPoints,
            period,
            installationId: this.installationId,
            systemId: this.applicationId,
            requestId: 'water_meter',
          };
          return this.dataPointsService
            .getTimeSeries(timeSeriesDetails)
            .pipe(map((seriesData: TimeSerie[]) => [{ name: legends[0], data: seriesData[0], type: 'line' } as SeriesOptionsType]));
        }),
        shareReplay(1)
      );
  }
}
