<gbc-tile [title]="'booster-input-source-tile.input-source' | translate">
  <div class="tile-content">
    <div class="tile-row">
      <div class="tile-row-name">{{'booster-input-source-tile.outlet-pressure' | translate}}</div>
      <div class="tile-row-value">
        {{outletPressure$ | async | formatDataPoint}}
      </div>
    </div>

    <div class="tile-row">
      <div class="tile-row-name">{{'booster-input-source-tile.inlet-pressure' | translate}}</div>
      <div class="tile-row-value">
        {{inletPressure$ | async | formatDataPoint}}
      </div>
    </div>

  </div>
</gbc-tile>
