import React from 'react';
import { Labels } from '../../Labels';
import { SvgImage } from '../../SvgImage';
import { PumpIcon } from '../../PumpIcon';
import { Pipe, PipeType } from '../../Pipe';
import * as metaData from './boiler-metadata';
import { System, BaseSystemProps } from '../../System';
import { CONNECTION_SPACING, Direction } from '../../Common';
import boilerSvg from '!raw-loader!../../../../../assets/systems/boiler.svg';
import { HeatMeterOrSensors } from '../../heat-meter-or-sensors/HeatMeterOrSensors';
import { IBoilerSystemFormInterface } from '../../../../models/systems/boilerSystem/boilerSystemFormInterface';

export const BoilerSystem: React.FC<BaseSystemProps<IBoilerSystemFormInterface>> = React.memo(({ state, values, alarm, selected }) => {
  const systemInfo = state.systemInfo;
  const showHeatMeter = !!systemInfo.HM_present;
  const showForwardHeatMeter = systemInfo.HM_location === 'PrimaryForward';
  const showReturnHeatMeter = systemInfo.HM_location === 'PrimaryReturn';
  const showForwardSensor = !!(systemInfo.HM_present || systemInfo.T_F_present);
  const showReturnSensor = !!(systemInfo.HM_present || systemInfo.T_R_present);
  const pump = () => {
    if (systemInfo.P_location === 'Forward') {
      return <PumpIcon direction={Direction.EAST} x={metaData.PUMP_X} y={metaData.FORWARD_Y} />;
    }
    if (systemInfo.P_location === 'Return') {
      return <PumpIcon direction={Direction.WEST} x={metaData.PUMP_X} y={metaData.RETURN_Y} />;
    }
  };
  return (
    <System state={state} meta={metaData.boilerSystemMeta} alarm={alarm} selected={selected}>
      <Pipe
        key="supplyPipe"
        x1={metaData.BOILER_ICON_RIGHT}
        y1={metaData.FORWARD_Y}
        x2={metaData.boilerSystemMeta.width}
        y2={metaData.FORWARD_Y}
        type={PipeType.WarmSupply}
      />
      <Pipe
        key="returnPipe"
        x1={metaData.BOILER_ICON_RIGHT}
        y1={metaData.RETURN_Y}
        x2={metaData.boilerSystemMeta.width}
        y2={metaData.RETURN_Y}
        type={PipeType.ColdReturn}
      />
      <SvgImage
        svgText={boilerSvg}
        x={metaData.BOILER_ICON_X}
        y={metaData.FORWARD_Y - (metaData.BOILER_ICON_HEIGHT - CONNECTION_SPACING) / 2}
      />
      <g transform={`translate(${metaData.HEAT_METER_X}, ${metaData.FORWARD_Y})`}>
        <HeatMeterOrSensors
          direction={Direction.EAST}
          showHeatMeter={showHeatMeter}
          showForwardHeatMeter={showForwardHeatMeter}
          showReturnHeatMeter={showReturnHeatMeter}
          showForwardSensor={showForwardSensor}
          showReturnSensor={showReturnSensor}
        />
      </g>
      {pump()}
      <Labels positions={metaData.getBoilerLabels(systemInfo)} values={values} />
    </System>
  );
});
