<app-pasteurization-status-tile
  [dataPoints$]="dataPoints$"
  [installationId]="(installation$ | async)?.id"
  [systemId]="application.id">
</app-pasteurization-status-tile>

<app-mixit-x509-banner/>
<app-alarm-bar *ngIf="alarm$ | async" [alarm]="alarm$"> </app-alarm-bar>

<app-schematics-tile
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [showShadow]="false"
  [dataPoints$]="dataPoints$">
</app-schematics-tile>

<app-setpoint-tile
  [application]="application"
  [installation$]="installation$"
  [dataPoints$]="dataPoints$"
  [setpointClientId]="'Application_TemperatureSetpoint'"
  [headerTitle]="'commercial-hot-water-dashboard.setpoint-tile-title' | translate">
</app-setpoint-tile>

<app-pump-status-tile
  *ngIf="pumpDevice$ | async"
  [installation$]="installation$"
  [application]="application"
  [pumpType$]="pumpType$"
  [pumpDevice$]="pumpDevice$"
  [pumpOptions]="pumpOptions$ | async">
</app-pump-status-tile>

<app-valve-status-tile
  *ngIf="valveDevice$ | async"
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [dataPoints$]="dataPoints$"
  [valveDevice$]="valveDevice$"
  [energyValve]="hasEnergyValve$ | async">
</app-valve-status-tile>

<app-heat-meter-status-tile
  [installation$]="installation$"
  [application]="application"
  [dataPoints$]="dataPoints$"
  [schematic$]="schematic$">
</app-heat-meter-status-tile>

<app-forward-return-chart-tile
  *ngIf="hasPrimaryTemperatureSensors$ | async"
  [title]="'commercial-hot-water-dashboard.primary-chart-title' | translate"
  [applicationId]="application.id"
  forwardDumbledoreId="T_PF"
  returnDumbledoreId="T_PR"
  [dataPoints$]="dataPoints$"
  [userTrackingPeriodOptions]="{ category: 'application/commercialHotWater', intervalPrefix: 'primaryTemperature' }">
</app-forward-return-chart-tile>

<app-forward-return-chart-tile
  [title]="'commercial-hot-water-dashboard.secondary-chart-title' | translate"
  [applicationId]="application.id"
  forwardDumbledoreId="T_SF"
  returnDumbledoreId="T_SR"
  [dataPoints$]="dataPoints$"
  [userTrackingPeriodOptions]="{ category: 'application/commercialHotWater', intervalPrefix: 'secondaryTemperature' }">
</app-forward-return-chart-tile>

<app-valve-chart
  [dataPoints$]="dataPoints$"
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [hasEnergyValve]="hasEnergyValve$ | async"
  [userTrackingPeriodOptions]="{ category: 'application/commercialHotWater', intervalPrefix: 'valvePosition' }">
</app-valve-chart>

<app-energy-valve-heat-and-flow-chart
  *ngIf="hasEnergyValve$ | async"
  [dataPoints$]="dataPoints$"
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id">
</app-energy-valve-heat-and-flow-chart>

<app-energy-valve-temperature-chart
  *ngIf="hasEnergyValve$ | async"
  [dataPoints$]="dataPoints$"
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id">
</app-energy-valve-temperature-chart>

<app-differential-pressure-chart
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [dataPoints$]="dataPoints$"
  [userTrackingPeriodOptions]="{ category: 'application/commercialHotWater', intervalPrefix: 'differentialPressure' }">
</app-differential-pressure-chart>

<app-flow-heat-meter-chart-tile
  [dataPoints$]="dataPoints$"
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [userTrackingPeriodOptions]="{ category: 'application/commercialHotWater', intervalPrefix: 'flowAndHeatMeter' }">
</app-flow-heat-meter-chart-tile>

<app-water-meter-chart-tile
  [dataPoints$]="dataPoints$"
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [userTrackingPeriodOptions]="{ category: 'application/commercialHotWater', intervalPrefix: 'waterMeter' }">
</app-water-meter-chart-tile>
