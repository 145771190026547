import { Component, OnInit, Input } from '@angular/core';
import { AlarmOverview, Application } from 'projects/customerportal/src/app/interfaces/alarm';
import { Observable } from 'rxjs';
import { DataPointsResult, SystemDevice } from 'projects/customerportal/src/app/interfaces/data-points';
import { getPumpOptions, getPumpType, systemsToPumpDevice } from 'projects/customerportal/src/app/utils/data-point-utils';
import { SchematicsService } from '../../../services/schematics.service';
import { AppModel } from '@ams/dumbledore';
import { PumpOption } from 'projects/customerportal/src/app/services/installation-configuration.service';
import { Installation } from '../../../interfaces/facilty';

@Component({
  selector: 'app-pump-system-dashboard',
  templateUrl: './pump-system-dashboard.component.html',
  styleUrls: ['./pump-system-dashboard.component.scss'],
})
export class PumpSystemDashboardComponent implements OnInit {
  @Input() public alarm$: Observable<AlarmOverview>;
  @Input() application: Application;
  @Input() public installation$: Observable<Installation>;
  @Input() public schematic$: Observable<AppModel>;
  @Input() public dataPoints$: Observable<DataPointsResult>;

  public pumpDevice$: Observable<SystemDevice | undefined>;
  public pumpType$: Observable<string>;
  public pumpOptions$: Observable<PumpOption[] | undefined>;

  constructor(private schematicService: SchematicsService) {}

  ngOnInit(): void {
    this.pumpDevice$ = this.dataPoints$.pipe(systemsToPumpDevice(this.application.id));

    this.pumpType$ = getPumpType(this.schematic$, this.application.id);

    this.pumpOptions$ = getPumpOptions(this.pumpDevice$, this.schematicService, this.application.id);
  }
}
