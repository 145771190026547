<div class="steps-wrapper">
  <div class="steps">
    <div class="step" *ngFor="let step of steps; let i = index">
      <div class="bar" [ngClass]="{enabled: selectedIndex === i}"></div>
      <div class="step-label" [ngClass]="{enabled: selectedIndex === i}">{{step.label}}</div>
    </div>
  </div>
</div>

<gbc-page-container>
  <div *ngIf="selected">
    <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
  </div>

  <footer class="stepper-navigation my-8">
    <button gbc-button cdkStepperPrevious [disabled]="selectedIndex === 0 || backDisabled" class="stepper-button stepper-button--back primary" data-test-id="stepper-button-back">
      {{backLabel}}
    </button>

    <button gbc-button cdkStepperNext *ngIf="selectedIndex !== (steps.length - 1)" [disabled]="nextDisabled" class="stepper-button primary" data-test-id="stepper-button-next">
      {{nextLabel}}
    </button>

    <button gbc-button class="stepper-button" *ngIf="selectedIndex === (steps.length - 1) " (click)="done.emit()" [disabled]="doneDisabled" data-test-id="stepper-button-done">
      {{doneLabel}}
    </button>
  </footer>
</gbc-page-container>
