import {Component, Input, OnInit} from '@angular/core';
import {AlarmBase, AlarmComment} from '../../../interfaces/system-status';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-alarm-comments-table',
  templateUrl: './alarm-comments-table.component.html',
  styleUrls: ['./alarm-comments-table.component.scss']
})
export class AlarmCommentsTableComponent {

  @Input()
  comments: AlarmComment[];
}
