import React, { useState } from 'react';
import { AppDispatch } from '../../../schematic/reducer';
import { setValues } from '../../../schematic/actions';
import { getSampleDataIdsForSystem, getSampleDataMaxValuesForSystem, getSampleValuesForSystem } from '../../../schematic/samples/sample-systems';
import { EditorState } from '../../state';
import { IEquipmentValue, ISetPointValue, IValveOpenValue, TEquipmentValues } from '../../../schematic/types';
import { SystemState } from '../../../schematic/state';
import { StyledFooter } from "./FooterStyled";

export interface FooterProps {
  dispatch: AppDispatch;
  selectedSystemId?: string | null;
  getState: () => EditorState;
}

interface SampleData {
  dataSetName: string;
  getValues: (system: SystemState) => (IValveOpenValue | IEquipmentValue | ISetPointValue | undefined)[];
}

export const Footer: React.FC<FooterProps> = React.memo(({ children, dispatch, selectedSystemId, getState }) => {
  const [dataSet, setDataSet] = useState("samples");

  const showSampleData = () => {
    showData({ dataSetName: "samples", getValues: getSampleValuesForSystem });
  }
  const showMaxValues = () => {
    showData({ dataSetName: "maxvalues", getValues: getSampleDataMaxValuesForSystem });
  }
  const showIds = () => {
    showData({ dataSetName: "ids", getValues: getSampleDataIdsForSystem });
  }

  function showData(sampleData: SampleData) {
    setDataSet(sampleData.dataSetName);
    const state = getState();
    const values = state.schematic.systems.flatMap(sampleData.getValues) as TEquipmentValues[];
    dispatch(setValues({ values }));
  }

  return (
    <StyledFooter>
      <div className="footer-buttons">
        <div className="section left">
          { children }
        </div>
        <div className="section">
          <label>Data</label>
          <div className='toggle-container'>
            <button className={ "button" + (dataSet === "samples" ? ' selected' : '') } onClick={ showSampleData }>Samples</button>
            <button className={ "button" + (dataSet === "maxvalues" ? ' selected' : '') } onClick={ showMaxValues }>Max Values</button>
            <button className={ "button" + (dataSet === "ids" ? ' selected' : '') } onClick={ showIds }>ID's</button>
          </div>
        </div>
      </div>
    </StyledFooter>
  );
});
