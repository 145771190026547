import { AlarmOverview } from '../interfaces/alarm';
import { AlarmType } from '../interfaces/system-status';
import { Alarm, AlarmsWarnings, Warning } from '@ams/dumbledore';

export const alarmStateToIconLink = (state: AlarmType): string | null => {
  switch (state) {
    case AlarmType.Alarm:
      return './assets/svgs/alarm.svg';
    case AlarmType.Warning:
      return './assets/svgs/warning.svg';
    default:
      return null;
  }
};

export const isOverridden = (alarm: AlarmOverview): boolean => {
  return !!alarm.currentActivation.overriddenTimestampEpoch;
};

export const formatAlarmsForDumbledore = (alarms: AlarmOverview[]): AlarmsWarnings[] => {
  return alarms
    .filter((alarm) => {
      if (!alarm.currentActivation) {
        return true;
      }
      return !alarm.currentActivation.clearedTimestampEpoch && !alarm.currentActivation.overriddenTimestampEpoch;
    })
    .reduce((allAlarms: AlarmsWarnings[], alarm) => {
      if (alarm.application) {
        allAlarms.push({
          type: alarm.type as Alarm | Warning,
          systemId: alarm.application.id,
        });
      }
      return allAlarms;
    }, []);
};
