<app-mixit-x509-banner/>
<app-alarm-bar *ngIf="alarm$ | async" [alarm]="alarm$"> </app-alarm-bar>

<app-schematics-tile
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [showShadow]="false"
  [dataPoints$]="dataPoints$">
</app-schematics-tile>

<app-heat-meter-status-tile
  [installation$]="installation$"
  [application]="application"
  [dataPoints$]="dataPoints$"
  [schematic$]="schematic$">
</app-heat-meter-status-tile>

<app-forward-return-chart-tile
  [title]="'district-heating-dashboard.temperature-title' | translate"
  [applicationId]="application.id"
  forwardDumbledoreId="T_PF"
  returnDumbledoreId="T_PR"
  [dataPoints$]="dataPoints$"
  [userTrackingPeriodOptions]="{ category: 'application/districtHeating', intervalPrefix: 'temperature' }">
</app-forward-return-chart-tile>
<!-- Unfortunately, a different dumbledoreID is used when set up with temp sensors instead of heat meter -->
<app-forward-return-chart-tile
  [title]="'district-heating-dashboard.temperature-title' | translate"
  [applicationId]="application.id"
  forwardDumbledoreId="T_F"
  returnDumbledoreId="T_R"
  [dataPoints$]="dataPoints$"
  [userTrackingPeriodOptions]="{ category: 'application/districtHeating', intervalPrefix: 'temperature' }">
</app-forward-return-chart-tile>

<app-flow-heat-meter-chart-tile
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [dataPoints$]="dataPoints$"
  [userTrackingPeriodOptions]="{ category: 'application/districtHeating', intervalPrefix: 'flowAndHeatMeter' }">
</app-flow-heat-meter-chart-tile>
