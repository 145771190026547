import React from 'react';
import { colors } from '../../util/colors';
import { EConnectionType } from '../state';

export enum PipeType {
  WarmSupply,
  WarmReturn,
  ColdSupply,
  ColdReturn,
}

export interface PipeProps {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
  type: PipeType;
  style?: Record<string, any>;
  noDash?: boolean;
}

export const getPipeConnectionType = (type: EConnectionType, dotted: boolean): PipeType => {
  if (type === EConnectionType.supply) {
    return dotted ? PipeType.WarmReturn : PipeType.WarmSupply;
  }
  return dotted ? PipeType.ColdReturn : PipeType.ColdSupply;
};

export const Pipe: React.FC<PipeProps> = React.memo(({ x1, y1, x2, y2, type, style, noDash = false }) => {
  const color = [PipeType.WarmSupply, PipeType.WarmReturn].includes(type)
    ? colors.connections[EConnectionType.supply].regular
    : colors.connections[EConnectionType.return].regular;
  const strokeDash = [PipeType.WarmReturn, PipeType.ColdReturn].includes(type) && !noDash ? '8 4' : undefined;
  return <line style={style} x1={x1} y1={y1} x2={x2} y2={y2} stroke={color} strokeWidth="2px" strokeDasharray={strokeDash} />;
});
