import { PipeType } from '../../Pipe';
import { LabelPosition, SystemMeta } from '../../types';
import { EConnectionType, SystemType } from '../../../state';
import { CONNECTION_SPACING, Direction } from '../../Common';
import { SUPPLY_TRIANGLE_HEIGHT } from '../../SupplyTriangle';
import { EConnectionPointPlacement, EEquipment } from '../../../types';
import { LargeLabelDeltas, SmallLabelDeltas } from '../../../util/constants';
import mixitForm from '../../../../models/systems/mixitSystem/mixitSystemForm.json';
import { EValveVariant } from '../../../../models/systems/mixitSystem/EValveVariant';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/mixingloop.svg';
import { IMixitSystemFormInterface } from '../../../../models/systems/mixitSystem/mixitSystemFormInterface';

export const WIDTH = 300;
export const HEIGHT = 420;

export const TOP_SETPOINT_TOP = 65;
export const ICON_TOP = TOP_SETPOINT_TOP + 10;
export const FORWARD_X = 100;
export const RETURN_X = FORWARD_X + CONNECTION_SPACING;
export const WARM_TO_COLD_Y = TOP_SETPOINT_TOP + 145;
export const SECOND_WARM_TO_COLD_Y = WARM_TO_COLD_Y + 50;
export const STANDALONE_CONNECTIONS_BOTTOM_Y = WARM_TO_COLD_Y + 110;
export const CONNECTIONS_BOTTOM_Y = HEIGHT;
export const SECONDARY_TEMP_SENSOR_Y = WARM_TO_COLD_Y - 34;
export const PUMP_Y = WARM_TO_COLD_Y - 73;
export const TEMP_ICON_LEFT = 10;
export const TEMP_ICON_TOP = 35;
export const METER_Y = 280;
export const METER_X_LEFT = FORWARD_X;
export const METER_X_RIGHT = FORWARD_X + CONNECTION_SPACING;

export const mixitMetadata: SystemMeta = {
  systemType: SystemType.Mixit,
  width: WIDTH,
  name: 'MIXIT',
  height: HEIGHT,
  thumbnail,
  form: mixitForm,
  calculateConnectionPoints: (systemInfo: IMixitSystemFormInterface) =>
    systemInfo.Standalone
      ? []
      : [
          {
            key: EConnectionPointPlacement.connectionPrimaryForward,
            x: systemInfo.ValveVariant == EValveVariant.MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE ? RETURN_X : FORWARD_X,
            y: STANDALONE_CONNECTIONS_BOTTOM_Y,
            type: EConnectionType.supply,
            dotted: false,
          },
          {
            key: EConnectionPointPlacement.connectionPrimaryReturn,
            x: systemInfo.ValveVariant == EValveVariant.MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE ? FORWARD_X : RETURN_X,
            y: systemInfo.Standalone ? STANDALONE_CONNECTIONS_BOTTOM_Y : CONNECTIONS_BOTTOM_Y,
            type: EConnectionType.return,
            dotted: true,
          },
        ],
};

export const getValveDirection = (systemInfo: any): Direction => {
  const LEFT = systemInfo.ValveVariant === EValveVariant.MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE;
  const isInjection3Way = systemInfo.HydraulicConfiguration === 'MIXING_LOOP_HYDRAULIC_CONFIGURATION_INJECTION_THREE_WAY';
  if (isInjection3Way) {
    return LEFT ? Direction.NORTH : Direction.SOUTH;
  }

  if (LEFT) {
    return systemInfo.MV_valveLocation === 'PrimaryForward' ? Direction.SOUTH : Direction.NORTH;
  }

  return systemInfo.MV_valveLocation === 'PrimaryForward' ? Direction.NORTH : Direction.SOUTH;
};

export const getValveXLocation = (systemInfo: any): number => {
  const LEFT = systemInfo.ValveVariant === EValveVariant.MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE;
  const isInjection3Way = systemInfo.HydraulicConfiguration === 'MIXING_LOOP_HYDRAULIC_CONFIGURATION_INJECTION_THREE_WAY';
  if (isInjection3Way) {
    return LEFT ? FORWARD_X : RETURN_X;
  }

  if (LEFT) {
    return systemInfo.MV_valveLocation === 'PrimaryForward' ? RETURN_X : FORWARD_X;
  }

  return systemInfo.MV_valveLocation === 'PrimaryForward' ? FORWARD_X : RETURN_X;
};

export const getValveYLocation = (systemInfo: any): number => {
  return systemInfo.DoubleShunt ? SECOND_WARM_TO_COLD_Y : WARM_TO_COLD_Y;
};

export const getPumpXLocation = (systemInfo: any): number => {
  const LEFT = systemInfo.ValveVariant === EValveVariant.MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE;
  if (LEFT) {
    return RETURN_X;
  }

  return systemInfo.P_location === 'SecondaryForward' ? FORWARD_X : RETURN_X;
};

export const getPumpYLocation = (systemInfo: any): number => {
  return PUMP_Y;
};

export const getPumpDirection = (systemInfo: any): Direction => {
  const LEFT = systemInfo.ValveVariant === EValveVariant.MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE;
  if (LEFT) {
    return Direction.NORTH;
  }

  return systemInfo.P_location === 'SecondaryForward' ? Direction.NORTH : Direction.SOUTH;
};

//
// Temp sensor placement
//

export const getForwardX = (systemInfo: IMixitSystemFormInterface): number => {
  const LEFT = systemInfo.ValveVariant === EValveVariant.MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE;
  if (LEFT) {
    return RETURN_X;
  }
  return FORWARD_X;
};

export const getSecondaryTempSensorY = (systemInfo: IMixitSystemFormInterface): number => {
  return SECONDARY_TEMP_SENSOR_Y;
};

export const getReturnX = (systemInfo: IMixitSystemFormInterface): number => {
  const LEFT = systemInfo.ValveVariant === EValveVariant.MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE;
  if (LEFT) {
    return FORWARD_X;
  }
  return RETURN_X;
};

export const getPrimaryTempSensorY = (systemInfo: IMixitSystemFormInterface): number => {
  return systemInfo.DoubleShunt ? SECOND_WARM_TO_COLD_Y + 43 : WARM_TO_COLD_Y + 43;
};

export const getForwardDirection = (systemInfo: IMixitSystemFormInterface): Direction => {
  const LEFT = systemInfo.ValveVariant === EValveVariant.MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE;
  if (LEFT) {
    return Direction.SOUTH;
  }
  return Direction.NORTH;
};

export const getReturnDirection = (systemInfo: IMixitSystemFormInterface): Direction => {
  const LEFT = systemInfo.ValveVariant === EValveVariant.MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE;
  if (LEFT) {
    return Direction.NORTH;
  }
  return Direction.SOUTH;
};

export const getLeftHandPipeType = (systemInfo: IMixitSystemFormInterface): PipeType => {
  const LEFT = systemInfo.ValveVariant === EValveVariant.MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE;
  if (systemInfo.Application === 'MIXING_LOOP_APPLICATION_TYPE_COOLING_COIL') {
    if (LEFT) {
      return PipeType.WarmReturn;
    }

    return PipeType.ColdSupply;
  }

  if (LEFT) {
    return PipeType.ColdReturn;
  }

  return PipeType.WarmSupply;
};

export const getLeftHandTriangleDirection = (systemInfo: IMixitSystemFormInterface): Direction => {
  const LEFT = systemInfo.ValveVariant === EValveVariant.MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE;
  const COOLING = systemInfo.Application === 'MIXING_LOOP_APPLICATION_TYPE_COOLING_COIL';
  if (COOLING) {
    if (LEFT) {
      return Direction.NORTH;
    }
    return Direction.SOUTH;
  }

  if (LEFT) {
    return Direction.SOUTH;
  }
  return Direction.NORTH;
};

export const getRightHandPipeType = (systemInfo: IMixitSystemFormInterface): PipeType => {
  const LEFT = systemInfo.ValveVariant === EValveVariant.MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE;
  if (systemInfo.Application === 'MIXING_LOOP_APPLICATION_TYPE_COOLING_COIL') {
    if (LEFT) {
      return PipeType.ColdSupply;
    }

    return PipeType.WarmReturn;
  }

  if (LEFT) {
    return PipeType.WarmSupply;
  }

  return PipeType.ColdReturn;
};

export const getRightHandTriangleDirection = (systemInfo: IMixitSystemFormInterface): Direction => {
  const LEFT = systemInfo.ValveVariant === EValveVariant.MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE;
  const COOLING = systemInfo.Application === 'MIXING_LOOP_APPLICATION_TYPE_COOLING_COIL';
  if (COOLING) {
    if (LEFT) {
      return Direction.SOUTH;
    }
    return Direction.NORTH;
  }

  if (LEFT) {
    return Direction.NORTH;
  }
  return Direction.SOUTH;
};

export const getTriangleY = (direction: Direction): number => {
  if (direction === Direction.NORTH) {
    return STANDALONE_CONNECTIONS_BOTTOM_Y - 2;
  }

  if (direction === Direction.SOUTH) {
    return STANDALONE_CONNECTIONS_BOTTOM_Y - 2 + SUPPLY_TRIANGLE_HEIGHT;
  }
  return 0;
};

export const getMeterX = (systemInfo: IMixitSystemFormInterface): number => {
  const LEFT = systemInfo.ValveVariant === EValveVariant.MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE;

  if (systemInfo.DoubleShunt) {
    if (LEFT) {
      return METER_X_RIGHT;
    }
    return METER_X_LEFT;
  }

  if (LEFT) {
    return METER_X_LEFT;
  }
  return METER_X_RIGHT;
};

export const getMeterY = (systemInfo: IMixitSystemFormInterface): number => {
  return METER_Y;
};

export const getMixitLabels = (systemInfo: IMixitSystemFormInterface): LabelPosition[] => {
  const LEFT = systemInfo.ValveVariant === EValveVariant.MIXING_LOOP_VALVE_LEFT_RIGHT_VARIANT_LEFT_VALVE;
  const getForwardTemperatureLabelX = (s: IMixitSystemFormInterface) => {
    if (LEFT) {
      return RETURN_X + LargeLabelDeltas.SOUTH_X;
    }
    return FORWARD_X + LargeLabelDeltas.NORTH_X;
  };

  const getReturnTemperatureLabelX = (s: IMixitSystemFormInterface): number => {
    if (LEFT) {
      return FORWARD_X + LargeLabelDeltas.NORTH_X;
    }
    return RETURN_X + LargeLabelDeltas.SOUTH_X;
  };

  const getReturnValveLabelX = (s: IMixitSystemFormInterface): number => {
    if (LEFT) {
      return FORWARD_X + LargeLabelDeltas.NORTH_X;
    }
    return RETURN_X + LargeLabelDeltas.SOUTH_X;
  };

  const getSupplyValveLabelX = (s: IMixitSystemFormInterface): number => {
    if (systemInfo.DoubleShunt) {
      if (LEFT) {
        return FORWARD_X - LargeLabelDeltas.SOUTH_X;
      }
      return RETURN_X + LargeLabelDeltas.SOUTH_X;
    }

    if (LEFT) {
      return RETURN_X + LargeLabelDeltas.SOUTH_X;
    }
    return FORWARD_X - LargeLabelDeltas.SOUTH_X;
  };

  const getPumpLabelX = (s: IMixitSystemFormInterface): number => {
    if (LEFT) {
      return RETURN_X + SmallLabelDeltas.SOUTH_X;
    }
    return FORWARD_X + SmallLabelDeltas.NORTH_X;
  };

  const getMeterLabelX = (s: IMixitSystemFormInterface): number => {
    if (systemInfo.DoubleShunt) {
      if (LEFT) {
        return RETURN_X + SmallLabelDeltas.SOUTH_X;
      }
      return FORWARD_X + SmallLabelDeltas.NORTH_X;
    }

    if (LEFT) {
      return FORWARD_X + SmallLabelDeltas.NORTH_X;
    }
    return RETURN_X + SmallLabelDeltas.SOUTH_X;
  };

  const getMeterLabelY = (s: IMixitSystemFormInterface): number => {
    return METER_Y;
  };

  const sfLabel = [
    {
      id: EEquipment.T_SF,
      x: getForwardTemperatureLabelX(systemInfo),
      y: SECONDARY_TEMP_SENSOR_Y,
    },
  ];
  const srLabel = systemInfo.T_SR_present
    ? [
        {
          id: EEquipment.T_SR,
          x: getReturnTemperatureLabelX(systemInfo),
          y: SECONDARY_TEMP_SENSOR_Y,
        },
      ]
    : [];
  const pfLabel = systemInfo.T_PF_present
    ? [
        {
          id: EEquipment.T_PF,
          x: getForwardTemperatureLabelX(systemInfo),
          y: getPrimaryTempSensorY(systemInfo),
        },
      ]
    : [];

  const prLabel = systemInfo.T_PR_present
    ? [
        {
          id: EEquipment.T_PR,
          x: getReturnTemperatureLabelX(systemInfo),
          y: getPrimaryTempSensorY(systemInfo),
        },
      ]
    : [];

  const threewaySupplyValve =
    systemInfo.MV_valveLocation === 'PrimaryForward' && systemInfo.MV_valveType === 'ThreeWayValve'
      ? [
          {
            id: EEquipment.MV_3W_PF,
            x: getSupplyValveLabelX(systemInfo),
            y: getValveYLocation(systemInfo),
            horizontalAlignment: systemInfo.DoubleShunt ? (LEFT ? 'end' : undefined) : LEFT ? undefined : 'end',
          },
        ]
      : [];

  const threewayReturnValve =
    systemInfo.MV_valveLocation === 'PrimaryReturn' && systemInfo.MV_valveType === 'ThreeWayValve'
      ? [
          {
            id: EEquipment.MV_3W_PR,
            x: getReturnValveLabelX(systemInfo),
            y: getValveYLocation(systemInfo),
          },
        ]
      : [];

  const twoWaySupplyValve =
    systemInfo.MV_valveLocation === 'PrimaryForward' && systemInfo.MV_valveType === 'TwoWayValve'
      ? [
          {
            id: EEquipment.MV_2W_PF,
            x: getSupplyValveLabelX(systemInfo),
            y: getValveYLocation(systemInfo),
            horizontalAlignment: LEFT ? undefined : 'end',
          },
        ]
      : [];

  const twoWayReturnValve =
    systemInfo.MV_valveLocation === 'PrimaryReturn' && systemInfo.MV_valveType === 'TwoWayValve'
      ? [
          {
            id: EEquipment.MV_2W_PR,
            x: getReturnValveLabelX(systemInfo),
            y: getValveYLocation(systemInfo),
          },
        ]
      : [];

  const sfPump =
    systemInfo.P_location === 'SecondaryForward'
      ? [
          {
            id: EEquipment.P_SF,
            x: getPumpLabelX(systemInfo),
            y: PUMP_Y,
          },
        ]
      : [];

  const srPump =
    systemInfo.P_location === 'SecondaryReturn'
      ? [
          {
            id: EEquipment.P_SR,
            x: getPumpLabelX(systemInfo),
            y: PUMP_Y,
          },
        ]
      : [];

  const tOutdoor = systemInfo.Weather_compensation_enabled
    ? [
        {
          id: EEquipment.MIXIT_OUTDOOR,
          x: TEMP_ICON_LEFT + 50,
          y: TEMP_ICON_TOP + 20,
        },
      ]
    : [];

  const tTop = {
    id: EEquipment.T_TOP_SETPOINT,
    x: WIDTH / 2,
    y: TOP_SETPOINT_TOP,
    horizontalAlignment: 'middle',
  };

  const meter = systemInfo.Dynamic
    ? [
        {
          id: EEquipment.MixitMeter,
          x: getMeterLabelX(systemInfo),
          y: getMeterLabelY(systemInfo),
        },
      ]
    : [];

  return [
    ...sfLabel,
    ...srLabel,
    ...pfLabel,
    ...prLabel,
    ...threewaySupplyValve,
    ...threewayReturnValve,
    ...twoWaySupplyValve,
    ...twoWayReturnValve,
    ...sfPump,
    ...srPump,
    ...tOutdoor,
    tTop,
    ...meter,
  ];
};
