<gbc-modal [title]="data.PumpTitle" (dismiss)="dismiss()">
  <div class="container">
    <picture>
      <img [src]="data.PumpImage" />
    </picture>
    <div class="mr-8 ml-8">
      <dl>
        <dt>{{ 'mixit-pump-info-modal.product-type' | translate }}</dt>
        <dd>{{ data.PumpProductType }}</dd>

        <dt>{{ 'mixit-pump-info-modal.product-number' | translate }}</dt>
        <dd>{{ data.PumpProductNumber }}</dd>

        <dt>{{ 'mixit-pump-info-modal.serial-number' | translate }}</dt>
        <dd>{{ data.PumpSerialNumber }}</dd>

        <dt>{{ 'mixit-pump-info-modal.production-code' | translate }}</dt>
        <dd>{{ data.PumpProductionCode }}</dd>
      </dl>
      <div class="mb-8 mt-8" (click)="openProductCatalog()">
        <a gbc-button class="link" target="_blank" [href]="data.PumpLink">
          {{ 'mixit-pump-info-modal.link-to-gpc' | translate }}
        </a>
      </div>
    </div>
  </div>
</gbc-modal>
