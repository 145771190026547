import { getComponent } from '../../schematic/components/system-type-to-component';

const blankImage = 'data:@file/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAAXNSR0IArs4c6QAAAAlwSFlzAAALEwAACxMBAJqcGAAAA6hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IlhNUCBDb3JlIDUuNC4wIj4KICAgPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4KICAgICAgPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIKICAgICAgICAgICAgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIgogICAgICAgICAgICB4bWxuczp0aWZmPSJodHRwOi8vbnMuYWRvYmUuY29tL3RpZmYvMS4wLyIKICAgICAgICAgICAgeG1sbnM6ZXhpZj0iaHR0cDovL25zLmFkb2JlLmNvbS9leGlmLzEuMC8iPgogICAgICAgICA8eG1wOk1vZGlmeURhdGU+MjAxOS0xMC0wNFQxMDoxMDo4NDwveG1wOk1vZGlmeURhdGU+CiAgICAgICAgIDx4bXA6Q3JlYXRvclRvb2w+UGl4ZWxtYXRvciAzLjguNjwveG1wOkNyZWF0b3JUb29sPgogICAgICAgICA8dGlmZjpPcmllbnRhdGlvbj4xPC90aWZmOk9yaWVudGF0aW9uPgogICAgICAgICA8dGlmZjpDb21wcmVzc2lvbj4wPC90aWZmOkNvbXByZXNzaW9uPgogICAgICAgICA8dGlmZjpSZXNvbHV0aW9uVW5pdD4yPC90aWZmOlJlc29sdXRpb25Vbml0PgogICAgICAgICA8dGlmZjpZUmVzb2x1dGlvbj43MjwvdGlmZjpZUmVzb2x1dGlvbj4KICAgICAgICAgPHRpZmY6WFJlc29sdXRpb24+NzI8L3RpZmY6WFJlc29sdXRpb24+CiAgICAgICAgIDxleGlmOlBpeGVsWERpbWVuc2lvbj4xMDwvZXhpZjpQaXhlbFhEaW1lbnNpb24+CiAgICAgICAgIDxleGlmOkNvbG9yU3BhY2U+MTwvZXhpZjpDb2xvclNwYWNlPgogICAgICAgICA8ZXhpZjpQaXhlbFlEaW1lbnNpb24+MTA8L2V4aWY6UGl4ZWxZRGltZW5zaW9uPgogICAgICA8L3JkZjpEZXNjcmlwdGlvbj4KICAgPC9yZGY6UkRGPgo8L3g6eG1wbWV0YT4KQIb0AwAAAA5JREFUGBljYBgFgzMEAAGaAAF4NjQPAAAAAElFTkSuQmCC';
import { SystemState, SystemType } from '../../schematic/state';
import panelArrowSVG from '!svg-url-loader!../../../assets/icons/panel-arrow.svg';
import React, { DragEventHandler, useState } from 'react';
import { SystemMeta } from '../../schematic/components/types';
import { systemMetadataMap } from '../../schematic/components/system-metadata';
import styled from 'styled-components';
import classnames from 'classnames';
import ReactDOM from 'react-dom';
import { v4 } from 'uuid';

interface AccordionSectionModel {
  title: string;
  systems: string[];
}

const createSystemDragImage = (systemType: SystemType): Element | null => {
  // tslint:disable-next-line:no-shadowed-variable
  const Component = getComponent(systemType);
  if (!Component) {
    return null;
  }
  const svgElement = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  const meta = systemMetadataMap[systemType];
  svgElement.setAttribute('width', meta.width.toString());
  svgElement.setAttribute('height', meta.height.toString());
  const state: SystemState = {
    hasId: v4(),
    // @ts-ignore
    type: systemType, // This type is a systemType, but due to the way we have defined states, it expects a certain list of SystemTypes.
    top: 0,
    left: 0,
    configured: false,
    systemInfo: {}
  };
  ReactDOM.render(
      React.createElement(Component, { state, values: [], alarm: undefined, selected: false }),
      svgElement);

  // this is a chrome bug workaround. Chrome won't set the drag image unless the svg is part of the document.
  svgElement.style.position = 'fixed';
  document.body.appendChild(svgElement);
  setTimeout(() => document.body.removeChild(svgElement));
  return svgElement;
};

const sections = [
  {
    title: 'Supply',
    systems: [
      SystemType.DistrictHeatingSupply,
      SystemType.IndirectDistrictHeatingSupplySystem,
      SystemType.Boiler,
    ]
  },
  {
    title: 'Space Heating',
    systems: [
      SystemType.MixingLoop,
      SystemType.Mixit,
    ]
  },
  {
    title: 'Commercial hot water',
    systems: [
      SystemType.CommercialHotWater,
      SystemType.HotWaterTank,
      SystemType.TwoStrokeHeatExchangerHotWaterSystem,
      SystemType.CascadeTankSystem,
      SystemType.CascadeBufferTankChargingSystem,
    ]
  },
  {
    title: 'Other applications',
    systems: [
      SystemType.UnspecifiedLoad,
    ]
  },
  {
    title: 'Sensors and equipment',
    systems: [
      SystemType.OutdoorTemperature,
      SystemType.HotTemperature,
      SystemType.ColdTemperature,
      SystemType.PressureSensorSystem
    ],
  },
  {
    title: 'Other (will be deprecated)',
    systems: [
      SystemType.HeatExchanger,
      SystemType.CommercialHotWaterStandalone,
      SystemType.HotWaterTankStandAloneSystem,
      SystemType.BufferTank,
      SystemType.SimpleBufferTankSystem,
      SystemType.MixingloopUnspecifiedLoadSystem,
      // DEPRECATED: SystemType.HotWaterPump,
      // DEPRECATED: SystemType.ColdWaterPump,
      SystemType.PumpStandalone,
      SystemType.HeatmeterStandalone,
      SystemType.ColdWaterSupplySystem,
    ]
  }];

const StyledAccordionSectionEntry = styled.div`
  height: auto;
  width: 84px;
  display: flex;
  flex-direction: column;

  padding-top: 10px;

  &:nth-child(even) {
      padding-left: 6px;
  }
.component-title {
    opacity: 0.8;
    font-family: 'GrundfosTheSansV2';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: -0.3px;
    color: #000000;
    padding-left: 3px;
}
.component-image {
    width: 84px;
    height: 88px;
    object-fit: contain;
    overflow: hidden;
    cursor: pointer;
}
`;

const SectionEntry: React.FC<{ systemMeta: SystemMeta }> = ({ systemMeta }) => {
  const onDragStart: DragEventHandler = (e) => {
    const systemImage = createSystemDragImage(systemMeta.systemType as SystemType);
    if (systemImage) {
      e.dataTransfer.setDragImage(systemImage, systemMeta.width / 2, systemMeta.height / 2);
      e.dataTransfer.setData('text/plain', systemMeta.systemType);
    }
  };
  return (
      <StyledAccordionSectionEntry>
        {systemMeta.thumbnail ? <img
            className="component-image"
            onDragStart={onDragStart}
            src={systemMeta.thumbnail}
            draggable={true}>
        </img> : null}
        <p className="component-title">{systemMeta.name}</p>
      </StyledAccordionSectionEntry>
  );
};

const StyledSection = styled.div`
&:first-child .component-section-header {
    border-top: none;
}

&:last-child  {
    border-bottom: 1px solid lightgrey;
}

.component-section-header {
    user-select: none;
    cursor: pointer;
    padding: 18px 0px 18px 19px;
    border-top: 1px solid lightgrey;
    // border-top: 1px solid lightgrey;

    display: flex;
    justify-content: space-between;
}

.component-section-header-text {
    user-select: none;
    font-weight: 700;
    opacity: 0.8;
    font-size: 15px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.35px;
    color: rgb(0, 0, 0);
    margin: 0px;
}

.component-section-header-icon {
    margin-right: 22px;
}

.component-section-header-icon.opened {
    transform: rotate(180deg);
}

.component-section-content {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
    padding-left: 20px;
    padding-right: 20px;
}
`;

const Section: React.FC<{ section: AccordionSectionModel }> = ({ section }) => {
  const [open, setOpen] = useState(false);
  return (
      <StyledSection>
        <div className="component-section-header" onClick={() => setOpen(!open)}>
          <div className="component-section-header-text">
            {section.title}
          </div>
          <img className={classnames({ 'component-section-header-icon': true, opened: open })} src={panelArrowSVG}
               alt=""/>
        </div>
        {open ?
            <div className="component-section-content">
              {section.systems.map(systemType => {
                const meta = systemMetadataMap[systemType];
                return <SectionEntry key={meta.systemType} systemMeta={meta}/>;
              })}
              {
                // Insert blank image as filler, if the number of systems is uneven
                section.systems.length % 2 === 1 ? <div className="component"><img src={blankImage}/></div> : null
              }
            </div> : null}
      </StyledSection>);
};

export const StyledAccordion = styled.div`
    overflow: auto;
    background-color: #f3f5f8;
    border-right: 1px solid lightgrey;
    width: 230px;
    height: 100%;
`;

export const Accordion: React.FC = React.memo(() => {
  return (
      <StyledAccordion>
        {sections.map(section => <Section key={section.title} section={section}/>)}
      </StyledAccordion>);
});
