import React from 'react';
import { Labels } from '../../Labels';
import { Direction } from '../../Common';
import { PumpIcon } from '../../PumpIcon';
import { Pipe, PipeType } from '../../Pipe';
import { System, BaseSystemProps } from '../../System';
import { getHotWaterPumpLabels, hotWaterPumpMeta, PIPE_Y, WIDTH } from './hot-water-pump-metadata';
import { IHotWaterPumpSystemFormInterface } from '../../../../models/systems/hotWaterPumpSystem/hotWaterPumpSystemFormInterface';

export const HotWaterPump: React.FC<BaseSystemProps<IHotWaterPumpSystemFormInterface>> = React.memo(
  ({ state, values, alarm, selected }) => {
    return (
      <System state={state} meta={hotWaterPumpMeta} alarm={alarm} selected={selected}>
        <Pipe key="PIPE" x1={0} y1={PIPE_Y} x2={WIDTH} y2={PIPE_Y} type={PipeType.WarmSupply} />
        <PumpIcon direction={Direction.EAST} x={WIDTH / 2} y={PIPE_Y} />
        <Labels positions={getHotWaterPumpLabels()} values={values} />
      </System>
    );
  }
);
