import { Component, OnInit, OnDestroy } from '@angular/core';
import { PageInfoService } from 'projects/customerportal/src/app/services/page-info.service';
import { PageInfo, AppError, AppErrorService } from 'shared';
import { Observable, combineLatest } from 'rxjs';
import { Installation, Facility } from 'projects/customerportal/src/app/interfaces/facilty';
import { map, tap, first } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { InstallationService } from 'projects/customerportal/src/app/services/installation.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { navigateToInstallation } from '../../utils/navigate-utils';
import { FacilityService } from '../../services/facility.service';
import { formValues } from '../../../../../shared/src/lib/constants/form-values';

@Component({
  selector: 'app-edit-installation-page',
  templateUrl: './edit-installation-page.component.html'
})
export class EditInstallationPageComponent {
  public pageInfo$: Observable<PageInfo>;
  public pageError$: Observable<AppError>;
  private installation$: Observable<Installation>;
  private facility$: Observable<Facility>;

  public form = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, Validators.minLength(2),  Validators.maxLength(formValues.max_length.name)]),
  });

  constructor(
    private pageInfoService: PageInfoService,
    private router: Router,
    private errorService: AppErrorService,
    private installationService: InstallationService,
    private route: ActivatedRoute,
    private facilityService: FacilityService
  ) {
    this.facility$ = this.facilityService.getCurrentFacility(this.route);

    this.installation$ = this.facilityService.getCurrentInstallation(this.route);

    this.pageInfo$ = combineLatest([this.facility$, this.installation$]).pipe(
      map(([facility, installation]) => this.pageInfoService.editInstallation(facility, installation, ''))
    );

    this.installation$
      .pipe(
        first(),
        tap((installation) => this.form.patchValue({ name: installation.name }))
      )
      .subscribe();

    this.pageError$ = this.errorService.createPageErrorObservable([this.facility$, this.installation$]);
  }

  update() {
    combineLatest([this.facility$, this.installation$])
      .pipe(
        first(),
        tap(([facility, installation]) => {
          this.installationService.updateInstallationName(installation.id, this.form.value.name).subscribe(() => {
            navigateToInstallation(this.router, facility.id, installation.id);
          });
        })
      )
      .subscribe();
  }
}
