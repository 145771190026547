import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { Value } from '../multi-value-tile/multi-value-tile.component';
import { DataPointsResult, DataPointState, DeviceDataPoint, System, SystemDevice } from '../../interfaces/data-points';
import { formatDataPoint } from '../../utils/data-point-utils';
import { DataPointsService } from '../../services/data-points.service';
import { ModalService } from 'shared';
import { WarmWeatherShutdownSettingsModalComponent } from '../warm-weather-shutdown-settings-modal/warm-weather-shutdown-settings-modal.component';
import { InstallationConfigurationService } from '../../services/installation-configuration.service';
import { WarmWeatherShutdownSettingsDTO } from '../../interfaces/warm-weather-shutdown';
import { TranslateService } from '@ngx-translate/core';

/**
 * Event data is defined to be strings, and the backend forwards the content without interpreting it.
 * Embedded uses doubles for everything, even to represent booleans.
 * So it will send 1d/0d as event data
 * 1d = 1.000000
 * 0d = 0.000000
 */
export type FakeBooleanState = '0.000000' | '1.000000';

/**
 * The client ids associated with the data points used in warm weather shutdown.
 */
export enum WarmWeatherShutdown {
  EnableState = 'WarmWeatherShutdown_EnableState',
  ActiveState = 'WarmWeatherShutdown_ActiveState',
  TemperatureThreshold = 'WarmWeatherShutdown_TemperatureThreshold',
  AveragingPeriod = 'WarmWeatherShutdown_AveragingPeriod',
}

@Component({
  selector: 'app-warm-weather-shutdown-tile',
  templateUrl: './warm-weather-shutdown-tile.component.html',
  styleUrls: ['./warm-weather-shutdown-tile.component.scss'],
})
export class WarmWeatherShutdownTileComponent implements OnInit, OnDestroy {
  @Input() public installationId: string;
  @Input() public applicationId: string;
  @Input() public dataPoints$: Observable<DataPointsResult>;
  @Output() adjust = new EventEmitter<void>();

  private isShowingSettingsDialog: boolean;

  public values$: Observable<Value[]>;
  public state = DataPointState;

  public outdoorTemperature: DeviceDataPoint | undefined;
  private temperatureSubscription: Subscription;

  private fakeTrue: FakeBooleanState = '1.000000';
  private fakeFalse: FakeBooleanState = '0.000000';

  private currentSettings: WarmWeatherShutdownSettingsDTO;

  public isDataAvailable: boolean = false;

  constructor(
    private dataPointsService: DataPointsService,
    private modalService: ModalService,
    private installationConfigurationService: InstallationConfigurationService,
    private translateService: TranslateService) {
      // Set default setting values.
      this.currentSettings = {
        enable: false,
        temperatureThreshold: 18,
        averagingPeriod: 2
      }
    }

  ngOnInit(): void {
    this.temperatureSubscription = this.dataPointsService.dataPoints$
      .pipe(
        map((dataPoints: DataPointsResult[]) => {
          return dataPoints
            .flatMap((dataPointsResult: DataPointsResult) => dataPointsResult.data)
            .flatMap((system: System) => system.devices)
            .flatMap((systemDevice: SystemDevice) => systemDevice.dataPoints)
            .find((deviceDataPoint: DeviceDataPoint): boolean => deviceDataPoint.clientId === 'Sensor_OutdoorTemperature');
        })
      )
      .subscribe((deviceDataPoint: DeviceDataPoint | undefined) => (this.outdoorTemperature = deviceDataPoint));

    this.values$ = this.dataPoints$.pipe(
      map((dataPoints: DataPointsResult) => {
        const applicationDataPoints = this.getApplicationDataPoints(dataPoints.data);

        const enableState:  DeviceDataPoint | undefined = this.getValue(applicationDataPoints, WarmWeatherShutdown.EnableState);
        const fakeEnableState: FakeBooleanState = this.parseFakeBooleanValue(enableState);
        const visualEnabled: 'enabled' | 'disabled' = fakeEnableState === this.fakeTrue ? 'enabled' : 'disabled';

        const activeState:  DeviceDataPoint | undefined = this.getValue(applicationDataPoints, WarmWeatherShutdown.ActiveState);
        const fakeActiveState: FakeBooleanState = this.parseFakeBooleanValue(activeState);
        const visualState: 'active' | 'inactive' = fakeActiveState === this.fakeTrue ? 'active' : 'inactive';

        const temperatureThreshold: DeviceDataPoint | undefined = this.getValue(applicationDataPoints, WarmWeatherShutdown.TemperatureThreshold);
        const averagingPeriod: DeviceDataPoint | undefined = this.getValue(applicationDataPoints, WarmWeatherShutdown.AveragingPeriod);

        const isStatusAvailable = (enableState?.value !== null && activeState?.value !== null);

        const values: Value[] = [
          {
            titleKey: 'warm-weather-shutdown-tile.status',
            value: isStatusAvailable ? `warm-weather-shutdown-tile.${visualEnabled}-state` : "-",
            visualEnabled: enableState?.value !== null ? visualEnabled : undefined,
            visualState: activeState?.value !== null ? visualState : undefined,
          },
          {
            titleKey: 'warm-weather-shutdown-tile.outdoor-temperature-threshold',
            value: formatDataPoint(temperatureThreshold),
          },
          {
            titleKey: 'warm-weather-shutdown-tile.current-outdoor-temperature',
            value: formatDataPoint(this.outdoorTemperature),
          },
          {
            titleKey: 'warm-weather-shutdown-tile.averaging-period',
            value: formatDataPoint(averagingPeriod, { valueOnly: false, unitOnly: false }, this.translateService),    // HACK: translate service required for 'day' translation!
          },
        ];

        // Update available state.
        this.isDataAvailable = enableState?.value != null && temperatureThreshold?.value !== null && averagingPeriod?.value !== null;

        // Update current settings if values are receviced.
        if (temperatureThreshold && temperatureThreshold.value !== null && averagingPeriod && averagingPeriod.value !== null) {
          this.currentSettings = {
            enable: visualEnabled === "enabled",
            temperatureThreshold: +temperatureThreshold.value,
            averagingPeriod: +averagingPeriod.value
          };
        }

        return values;
      })
    );
  }
  ngOnDestroy(): void {
    this.temperatureSubscription.unsubscribe();
  }

  public onAdjust() {
    if (this.isShowingSettingsDialog) { return };
    this.isShowingSettingsDialog = true;

    this.modalService.openDialog<any>(WarmWeatherShutdownSettingsModalComponent, { data: this.currentSettings }).subscribe((response) => {
      this.isShowingSettingsDialog = false;
      if (response.dismissed) return;

      // Submit settings.
      const settings = response.result;
      this.installationConfigurationService.updateWarmWeatherShutdown(this.installationId, this.applicationId, settings);
    });
  }

  private getValue(dataPoints: DeviceDataPoint[], clientId: WarmWeatherShutdown): DeviceDataPoint | undefined {
    return dataPoints.find((deviceDataPoint: DeviceDataPoint): boolean => deviceDataPoint.clientId === clientId);
  }

  private parseFakeBooleanValue(dataPoint: DeviceDataPoint | undefined): FakeBooleanState {
    return (dataPoint?.value || this.fakeFalse) as FakeBooleanState;
  }

  private getApplicationDataPoints(systems: System[]): DeviceDataPoint[] {
    const system: System | undefined = systems.find((system: System) => system.systemId === this.applicationId);
    return system?.devices?.flatMap((systemDevice: SystemDevice) => systemDevice?.dataPoints) || [];
  }
}
