<app-multi-value-tile
  [applicationId]="applicationId"
  [installationId]="installationId"
  [tileTitle]="getTitle() | translate"
  [values]="values$ | async"
  [columns]="valuesToShow"
  [showAdjust]="true"
  [disableAdjust]="systemControlMode === SystemControlModeOptions.Off"
  (adjust)="adjustValve()">
</app-multi-value-tile>
