<gbc-page-error-handler [content]="content" [pageError$]="pageError$"></gbc-page-error-handler>
<ng-template #content>
  <gbc-page-header [pageInfo]="pageInfo$ | async">
    <ng-container gbc-page-header-actions>
      <button gbc-menu-item
        (click)="editFacility()"
        angulartics2On="click"
        angularticsAction="editFacilityShown"
        angularticsCategory="connectingCoreFacility">
        <img style="margin-right: 10px" src="./assets/svgs/edit.svg" />
        {{'mixit-facility-page.edit-facility' | translate}}
      </button>
    </ng-container>
  </gbc-page-header>
  <gbc-page-container>
    <app-mixit-x509-banner/>
    <div class="alarm-bar"  *ngIf="showAlarmBar$ | async">
      <img class="img" src="./assets/svgs/alarm.svg" />
      <div class="title">{{'mixit-facility-page.alarm' | translate}}</div>
    </div>

    <div class="warning-bar" *ngIf="showWarningBar$ | async">
      <img class="img" src="./assets/svgs/warning.svg" />
      <div class="title">{{'mixit-facility-page.warning' | translate}}</div>
    </div>

    <gbc-tile [title]="'connectingcore-facility-page.devices' | translate" class="schematics-tile">
      <div class="schematics-container">
        <div class="schematic-container" *ngFor="let installationAndSchematic of installationsAndSchematics$ | async">
          <div class="schematic-title-container">
            <h4 class="schematic-title">
              {{installationAndSchematic.installation.name}}
            </h4>
            <button class="schematic-edit-button" gbc-text-button type="button" (click)="editInstallation(installationAndSchematic.installation.id)">Edit</button>
          </div>
          <p class="schematic-being-commissioned" *ngIf="installationAndSchematic.installation.commissionStatus !== liveStatus">{{'mixit-facility-page.waiting-for-device-to-connect' | translate}}</p>
          <app-schematic
            (applicationClicked)="handleClick(installationAndSchematic.installation)"
            [installationId]="installationAndSchematic.installation.id"
            [schematic]="installationAndSchematic.appModel"
            [dataPoints]="installationAndSchematic.dataPoints"
            [alarms]="[installationAndSchematic.alarm]"
            class="schematic"
            angulartics2On="click"
            angularticsAction="applicationClicked"
            angularticsCategory="connectingCoreSchematic">
          </app-schematic>
        </div>
      </div>
    </gbc-tile>

  </gbc-page-container>
</ng-template>
