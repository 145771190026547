import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { DataPointsResult, DeviceDataPoint, EBackendUnit } from '../../../interfaces/data-points';
import { getDatapoint } from '../../../utils/mixit-utils';
import { MixitDataPointName } from '../../../interfaces/mixit';
import { first, map, switchMap } from 'rxjs/operators';
import { parseBoolean } from '../../../utils/data-point-utils';
import { Installation } from '../../../interfaces/facilty';
import { AppModel } from '@ams/dumbledore';
import { Application } from '../../../interfaces/alarm';
import { EquipmentMetaInfo } from '../../../services/installation-configuration.service';
import { MixitUnderfloorProtectionModalComponent, MixitUnderfloorProtectionResponse, MixitUnderfloorProtectionSettings } from '../mixit-underfloor-protection-modal/mixit-underfloor-protection-modal.component';
import { ICommand, MixitService } from '../../../services/mixit-service.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from 'shared';
import { SchematicsService } from '../../../services/schematics.service';
import { SystemDeviceType } from '../../../interfaces/systemDeviceType';

@Component({
  selector: 'app-underfloor-protection-tile',
  templateUrl: './underfloor-protection-tile.component.html',
  styleUrls: ['./underfloor-protection-tile.component.scss'],
})
export class UnderfloorProtectionTileComponent implements OnInit {
  @Input() installation$: Observable<Installation>;
  @Input() schematic$: Observable<AppModel>;
  @Input() dataPoints$: Observable<DataPointsResult>;
  @Input() application$: Observable<Application>;
  @Input() public overheatProtectionTemperature$: Observable<DeviceDataPoint | null>;
  @Input() public underFloorProtectionEnabled$: Observable<boolean>;

  private isAdjustmentInProgress = false;

  constructor(
    private translateService: TranslateService,
    private schematicService: SchematicsService,
    private modalService: ModalService,
    private mixitService: MixitService
  ) {}

  ngOnInit(): void {
    this.underFloorProtectionEnabled$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.FloorProtectInternal
    ).pipe(map((d) => parseBoolean(d?.value)));
    this.overheatProtectionTemperature$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.FloorMaxTemperature
    );
  }

  public adjustUnderfloorProtection() {
    if (this.isAdjustmentInProgress) { return; }
    this.isAdjustmentInProgress = true;

    const underfloorProtectionTemperatureMeta$ = this.application$.pipe(
      map((application) => application.id),
      switchMap((id) => this.schematicService.getEquipmentMetaInfo(id)),
      map((metas) => {
        return metas.find((m) => m.term === MixitDataPointName.FloorMaxTemperature);
      })
    );

    combineLatest([
      this.installation$,
      this.application$,
      this.underFloorProtectionEnabled$,
      this.overheatProtectionTemperature$,
      underfloorProtectionTemperatureMeta$,
    ])
      .pipe(first())
      .subscribe(
        ([installation, application, enabled, maxTemperature, underfloorProtectionTemperatureMeta]: [
          Installation,
          Application,
          boolean,
          DeviceDataPoint | null,
          EquipmentMetaInfo | undefined
        ]) => {
          const data: MixitUnderfloorProtectionSettings = {
            enabled,
            maxTemperature: parseFloat(maxTemperature?.value as string) || null,
            maxTemperatureMeta: underfloorProtectionTemperatureMeta as EquipmentMetaInfo,
          };

          this.modalService
            .openDialog<MixitUnderfloorProtectionResponse>(MixitUnderfloorProtectionModalComponent, {
              data,
            })
            .subscribe((response) => {
              if (response.dismissed) {
                this.isAdjustmentInProgress = false;
                return;
              }

              const res = response.result;

              const commands: ICommand[] = [
                {
                  term: MixitDataPointName.FloorProtectInternal,
                  value: res.enabled?.toString() || null,
                  unit: EBackendUnit.None,
                },
                {
                  term: MixitDataPointName.FloorMaxTemperature,
                  value: res.maxTemperature?.toString() || null,
                  unit: EBackendUnit.DegreeCelsius,
                },
              ];

              this.mixitService.sendCommand(installation, application.id, commands);
              this.isAdjustmentInProgress = false;
            });
        }
      );
  }
}
