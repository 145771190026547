import React from 'react';
import { SvgImage } from '../SvgImage';
import { Valve } from '../Valve';
import { HeatMeterOrSensors } from '../heat-meter-or-sensors/HeatMeterOrSensors';
import { TemperatureSensor } from '../TemperatureSensor';
import heatExchangerSvg from '!raw-loader!../../../../assets/systems/heatexchanger.svg';
import { DifferentialpressureSensor } from '../DifferentialPressureSensor';
import {
  EXCHANGER_ICON_TOP,
  EXCHANGER_ICON_X,
  FORWARD_Y,
  HEAT_METER_X,
  PRIMARY_DIFFERENTIAL_PRESSURE_X,
  RETURN_Y,
  SECONDARY_DIFFERENTIAL_PRESSURE_X,
  SECONDARY_TEMP_SENSOR_X,
  VALVE_X,
} from './heat-exchanger-common-metadata';
import { Direction } from '../Common';
import { ICommercialHotWaterSystemFormInterface } from '../../../models/systems/commercialHotWaterSystem/commercialHotWaterSystemFormInterface';
import { IHeatExchangingSystemFormInterface } from '../../../models/systems/heatExchangingSystem/heatExchangingSystemFormInterface';
import { Pipe, PipeType } from '../Pipe';
import { ValveEnergyIcon } from '../ValveEnergyIcon';
import { systemHasEnergyValve } from '../../util/system-properties-util';

interface CommercialHotWaterSystemProps {
  state: ICommercialHotWaterSystemFormInterface | IHeatExchangingSystemFormInterface;
  hideHeatMeterAndForwardTempSensors?: boolean;
  hidePrimaryDeltaPSensor?: boolean;
}

export const HeatExchangerCommon: React.FC<CommercialHotWaterSystemProps> = ({
  state,
  hideHeatMeterAndForwardTempSensors,
  hidePrimaryDeltaPSensor = false,
}) => {
  const systemInfo: ICommercialHotWaterSystemFormInterface | IHeatExchangingSystemFormInterface = state;
  const showHeatMeter = !!systemInfo.HM_present;
  const showForwardHeatMeter = systemInfo.HM_location === 'PrimaryForward';
  const showReturnHeatMeter = systemInfo.HM_location === 'PrimaryReturn';
  const showForwardSensor = !!(systemInfo.HM_present || systemInfo.T_PF_present);
  const showReturnSensor = !!(systemInfo.HM_present || systemInfo.T_PR_present);
  const hasEnergyValve = systemHasEnergyValve(systemInfo);

  return (
    <>
      <SvgImage key="heatExIcon" svgText={heatExchangerSvg} x={EXCHANGER_ICON_X} y={EXCHANGER_ICON_TOP} />
      {!hideHeatMeterAndForwardTempSensors &&
        <g key="heatMeterOrSensors" transform={`translate(${HEAT_METER_X},${FORWARD_Y})`}>
          <HeatMeterOrSensors
            direction={Direction.EAST}
            showHeatMeter={showHeatMeter}
            showForwardHeatMeter={showForwardHeatMeter}
            showReturnHeatMeter={showReturnHeatMeter}
            showForwardSensor={showForwardSensor}
            showReturnSensor={showReturnSensor}
          />
        </g>}
      {(systemInfo.Control_enabled || systemInfo.T_SF_present) &&
        <g key="T_SF" transform={`translate(${SECONDARY_TEMP_SENSOR_X},${FORWARD_Y})`}>
          <TemperatureSensor direction={Direction.EAST} />
        </g>
      }
      {!hidePrimaryDeltaPSensor && systemInfo.DP_P_present && <DifferentialpressureSensor key="primaryDifferentialPressure" x={PRIMARY_DIFFERENTIAL_PRESSURE_X} y={FORWARD_Y} />}
      {systemInfo.DP_S_present && <DifferentialpressureSensor key="secondaryDifferentialPressure" x={SECONDARY_DIFFERENTIAL_PRESSURE_X} y={FORWARD_Y} />}
      {systemInfo.T_SR_present &&
        <g key="T_SR" transform={`translate(${SECONDARY_TEMP_SENSOR_X},${RETURN_Y})`}>
          <TemperatureSensor direction={Direction.WEST} />
        </g>
      }
      {systemInfo.MV_Parallel_Valve_Enabled && systemInfo.MV_valveLocation === 'PrimaryForward' &&
        <React.Fragment>
          <Pipe
            key="parallelValvePipeLeft"
            x1={VALVE_X - 20}
            y1={FORWARD_Y}
            x2={VALVE_X - 20}
            y2={FORWARD_Y + 40}
            type={PipeType.WarmSupply}
          />
          <Pipe
            key="parallelValvePipeRight"
            x1={VALVE_X + 20}
            y1={FORWARD_Y}
            x2={VALVE_X + 20}
            y2={FORWARD_Y + 40}
            type={PipeType.WarmSupply}
          />
          <Pipe
            key="parallelValvePipeBottom"
            x1={VALVE_X - 20}
            y1={FORWARD_Y + 40}
            x2={VALVE_X + 20}
            y2={FORWARD_Y + 40}
            type={PipeType.WarmSupply}
          />
        </React.Fragment>
      }
      {systemInfo.MV_Parallel_Valve_Enabled && systemInfo.MV_valveLocation === 'PrimaryReturn' &&

        <React.Fragment>
          <Pipe
            key="parallelValvePipeLeft"
            x1={VALVE_X + 20}
            y1={RETURN_Y}
            x2={VALVE_X + 20}
            y2={RETURN_Y - 40}
            type={PipeType.ColdReturn}
          />
          <Pipe
            key="parallelValvePipeRight"
            x1={VALVE_X - 20}
            y1={RETURN_Y}
            x2={VALVE_X - 20}
            y2={RETURN_Y - 40}
            type={PipeType.ColdReturn}
          />
          <Pipe
            key="parallelValvePipeBottom"
            x1={VALVE_X + 20}
            y1={RETURN_Y - 40}
            x2={VALVE_X - 20}
            y2={RETURN_Y - 40}
            type={PipeType.ColdReturn}
          />
        </React.Fragment>
      }
      <>
        {systemInfo.MV_valveLocation === 'PrimaryForward' && (
          <>
            {hasEnergyValve && <ValveEnergyIcon x={VALVE_X - 25} y={FORWARD_Y - 30} />}
            <Valve key="valve" type="TwoWayValve" direction={Direction.WEST} x={VALVE_X} y={FORWARD_Y} />
            {systemInfo.MV_Parallel_Valve_Enabled && (
              <Valve key="valve2" type="TwoWayValve" direction={Direction.WEST} x={VALVE_X} y={FORWARD_Y + 40} />
            )}
          </>
        )}
        {systemInfo.MV_valveLocation === 'PrimaryReturn' && (
          <>
            {hasEnergyValve && <ValveEnergyIcon x={VALVE_X - 25} y={RETURN_Y - 30} />}
            <Valve key="valve" type="TwoWayValve" direction={Direction.WEST} x={VALVE_X} y={RETURN_Y} />;
            {systemInfo.MV_Parallel_Valve_Enabled && (
              <Valve key="valve2" type="TwoWayValve" direction={Direction.WEST} x={VALVE_X} y={RETURN_Y - 40} />
            )}
          </>
        )}
      </>
    </>
  );
};
