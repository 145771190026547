<section class="section">
  <ng-content select="[section-header]"></ng-content>
  <div class="kpi-container">
    <ng-container *ngFor="let kpi of sectionData.kpis">
      <gbc-tile [title]="'energy-management-dashboard.' + kpi.humanReadableId | translate">
        <span class="info" gbc-tile-left
          (click)="toggleInfoModal('energy-management-dashboard.' + healthCheckTargetKey + '.' + kpi.humanReadableId + '-tooltip', 'energy-management-dashboard.' + kpi.humanReadableId)"
          angulartics2On="click"
          [angularticsAction]="kpi.humanReadableId + 'IconClicked'"
          [angularticsCategory]="'EDB' + humanReadableId">
          <img src="./assets/svgs/info-circle_outline.svg" />
        </span>
        <article class="kpi">
          <span class="value">{{ kpi | formatDataPoint:{ valueOnly: true } }} <small class="unit">{{ kpi | formatDataPoint:{ unitOnly: true } }}</small></span>
        </article>
      </gbc-tile>
    </ng-container>
  </div>
  <div class="health-check-container" *ngIf="sectionData.healthChecks.length > 0">
    <gbc-collapsible toggled="true" (toggle)="toggleHealthCheck($event)">
      <div gbc-collapsible-title>
        <span class="collapsible-title">
          <img *ngIf="someHealthCheckWarning(sectionData.healthChecks); else thumbsUp" src="./assets/svgs/thumbs-down_outline.svg" />
          <ng-template #thumbsUp>
            <img src="./assets/svgs/thumbs-up_outline.svg" />
          </ng-template>
          <span>{{ 'energy-management-dashboard.health-check' | translate }}</span>
        </span>
      </div>
      <div [formGroup]="healthCheckFormGroup">
        <header class="health-check-header health-check-grid">
          <div></div><div>{{ 'energy-management-dashboard.current' | translate }}</div><div>{{ 'energy-management-dashboard.target' | translate }}</div><div>{{ 'energy-management-dashboard.potential-saving' | translate }}</div>
        </header>
        <ng-container *ngFor="let healthcheck of sectionData.healthChecks">
          <article class="health-check-item health-check-grid">
            <div>
              <img *ngIf="[healthCheckStatus.ErrorTargetOvershot, healthCheckStatus.ErrorTargetUndershot].includes(healthcheck.status)" src="./assets/svgs/thumbs-down_outline.svg" /><img *ngIf="[healthCheckStatus.OkTargetNotOvershot, healthCheckStatus.OkTargetNotUndershot].includes(healthcheck.status)" src="./assets/svgs/thumbs-up_outline.svg" /> {{ 'energy-management-dashboard.' + healthcheck.humanReadableId | translate }} <span class="info" (click)="toggleInfoModal('energy-management-dashboard.' + healthCheckTargetKey + '.' + healthcheck.humanReadableId + '-tooltip', 'energy-management-dashboard.' + healthcheck.humanReadableId)"><img src="./assets/svgs/info-circle_outline.svg" /></span>
            </div>
            <div data-current-display="test">{{ healthcheck | formatDataPoint }}</div>
            <div [ngClass]="['health-check-cell', pending ? 'pending' : ''].join(' ')">
              <a data-target-display="test" (click)="toggleEditMode(healthcheck)" *ngIf="healthcheck.name !== toggledHealthCheck?.name">
                {{ healthcheck.targets[0] | formatDataPoint }}
              </a>
              <ng-container *ngIf="healthcheck.name === toggledHealthCheck?.name">
                <div class="health-check-edit-target">
                  <gbc-input-field
                    [matchRelativeParentDimensions]="true"
                    [formControlName]="toggledHealthCheck?.name"
                    [unit]="healthcheck.targets[0] | formatDataPoint:{ unitOnly: true }"></gbc-input-field>
                </div>
                <div class="button-group">
                  <button class="confirm" (click)="onConfirm()"><img src="./assets/svgs/check-outline-white.svg" /></button>
                  <button (click)="onCancel()"><img src="./assets/svgs/close_outline.svg" /></button>
                </div>
              </ng-container>
            </div>
            <div><div data-saving-display="test" *ngIf="healthcheck.potentialSaving">{{ healthcheck.potentialSaving | formatDataPoint }}</div></div>
            <div>
              <div
                class="info healthcheck"
                (click)="showHealthCheckDetails(healthCheckTargetKey, healthcheck)"
                [title]="'energy-management-dashboard.details' | translate"
                angulartics2On="click"
                angularticsAction="potentialSavingsDetailsClicked"
                [angularticsCategory]="'EDB' + humanReadableId">
                <img src="./assets/svgs/chevron_forward.svg" /></div>
            </div>
          </article>
        </ng-container>
      </div>
    </gbc-collapsible>
  </div>
</section>
