import { EConnectionType } from '../schematic/state';

export const colors = {
  connections: {
    [EConnectionType.supply]: {
      transparent: 'rgba(228, 53, 53, 0.7)',
      regular: 'rgba(228, 53, 53, 1)',
    },
    [EConnectionType.return]: {
      transparent: 'rgba(0, 105, 196, 0.7)',
      regular: 'rgba(0, 105, 196, 1)',
    },
  },
  background: 'rgba(255, 255, 255, 1)',
  backgroundActive: 'rgba(0, 104, 203, 1)',
  backgroundStroke: 'rgba(217, 221, 223, 1)',
  textColor: '#333333',
  lineColor: '#979797',
  valueLineColor: '#d9dddf',
  textErrorColor: '#ff0000',
  setPointTextColor: '#8a8a8f',

  debug: '#ccc'
};
