<div>
  <span class="icon-area">
    <gbc-simple-spinner *ngIf="job.progressState === 'InProgress'" class="spinner progress-checker-in-progress"></gbc-simple-spinner>
    <span *ngIf="job.progressState === 'CompletedWithSuccess' && job?.result === true"
      class="progress-checker-result-success">
      <img class="icon" src="./assets/svgs/check-outline-green.svg" />
    </span>
    <span *ngIf="(job.progressState === 'CompletedWithSuccess' && job?.result === false) || (job.progressState === 'CompletedWithError')"
      class="progress-checker-result-error">
      <img class="icon" src="./assets/svgs/close_outline-red.svg" />
    </span>
  </span>
  <span>{{title}}</span>
  <span *ngIf="showErrorMessage && job?.progressState === 'CompletedWithError'">
    <img class="info-img" src="./assets/svgs/info-circle_outline.svg" [title]="job?.errorMessage" />
  </span>
</div>