import { Component, OnInit, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { ILicensePurchaseInformation, ITrialUpgradeInformation } from '../../interfaces/premium';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, combineLatest } from 'rxjs';
import { TERMS_AND_CONDITIONS_LINK, TERMS_OF_USE_LINK } from '../../utils/links';
import { formValues } from '../../../../../shared/src/lib/constants/form-values';

@Component({
  selector: 'app-mixit-premium-billing-details',
  templateUrl: './mixit-premium-billing-details.component.html',
  styleUrls: ['./mixit-premium-billing-details.component.scss'],
})
export class MixitPremiumBillingDetailsComponent implements OnInit, OnDestroy {
  @Input() public title: string;
  @Input() public licenseData: ILicensePurchaseInformation | ITrialUpgradeInformation;
  @Input() public extendedInfo = false;
  @Input() public readOnly = false;
  @Output() private licenseDataUpdate = new EventEmitter<ILicensePurchaseInformation | ITrialUpgradeInformation>();
  @Output() private formValidityUpdate = new EventEmitter<boolean>();

  public TERMS_AND_CONDITIONS_LINK = TERMS_AND_CONDITIONS_LINK;
  public TERMS_OF_USE_LINK = TERMS_OF_USE_LINK;
  public licenseInfo: UntypedFormGroup;
  private subscription = new Subscription();

  ngOnInit(): void {
    const premiumLicenseData = this.licenseData as ILicensePurchaseInformation;
    this.licenseInfo = new UntypedFormGroup({
      companyName: new UntypedFormControl({ value: this.licenseData?.companyName || '', disabled: this.readOnly }, [
        Validators.required,
        Validators.maxLength(formValues.max_length.name),
      ]),
      street: new UntypedFormControl({ value: this.licenseData?.street || '', disabled: this.readOnly }, [
        Validators.required,
        Validators.maxLength(formValues.max_length.address),
      ]),
      postalCode: new UntypedFormControl({ value: this.licenseData?.postalCode || '', disabled: this.readOnly }, [
        Validators.required,
        Validators.maxLength(formValues.max_length.postalCode),
      ]),
      city: new UntypedFormControl({ value: this.licenseData?.city || '', disabled: this.readOnly }, [
        Validators.required,
        Validators.maxLength(formValues.max_length.address),
      ]),
      country: new UntypedFormControl({ value: this.licenseData?.country || '', disabled: this.readOnly }, [
        Validators.required,
        Validators.maxLength(formValues.max_length.address),
      ]),
      region: new UntypedFormControl({ value: this.licenseData?.region || '', disabled: this.readOnly }, [
        Validators.maxLength(formValues.max_length.address),
      ]),
      state: new UntypedFormControl({ value: this.licenseData?.state || '', disabled: this.readOnly }, [
        Validators.maxLength(formValues.max_length.address),
      ]),
      termsOfUse: new UntypedFormControl({ value: this.licenseData?.termsOfUse || false, disabled: this.readOnly }, [
        Validators.required,
      ]),
      termsAndConditions: new UntypedFormControl({ value: this.licenseData?.termsAndConditions || false, disabled: this.readOnly }, [
        Validators.required,
      ]),
      other: new UntypedFormControl({ value: this.licenseData?.other || false, disabled: this.readOnly }),
      ...(this.extendedInfo
        ? {
            vatNumber: new UntypedFormControl({ value: premiumLicenseData?.vatNumber || '', disabled: this.readOnly }, [
              Validators.required,
              Validators.maxLength(formValues.max_length.default),
            ]),
            accountManagerName: new UntypedFormControl({ value: premiumLicenseData?.accountManagerName || '', disabled: this.readOnly }, [
              Validators.required,
              Validators.maxLength(formValues.max_length.name),
            ]),
            accountManagerEmail: new UntypedFormControl({ value: premiumLicenseData?.accountManagerEmail || '', disabled: this.readOnly }, [
              Validators.email,
              Validators.required,
              Validators.maxLength(formValues.max_length.email),
            ]),
            invoiceEmail: new UntypedFormControl({ value: premiumLicenseData?.invoiceEmail || '', disabled: this.readOnly }, [
              Validators.email,
              Validators.required,
              Validators.maxLength(formValues.max_length.email),
            ]),
            purchaseReference: new UntypedFormControl({ value: premiumLicenseData?.purchaseReference || '', disabled: this.readOnly }, [
              Validators.required,
              Validators.maxLength(formValues.max_length.default),
            ]),
          }
        : {}),
    });

    const sub = combineLatest([this.licenseInfo.valueChanges, this.licenseInfo.statusChanges]).subscribe(
      ([valueChanges, statusChanges]) => {
        const { termsOfUse, termsAndConditions } = valueChanges;
        this.licenseDataUpdate.emit(valueChanges);
        this.formValidityUpdate.emit(statusChanges === 'VALID' && termsOfUse && termsAndConditions);
      }
    );

    this.subscription.add(sub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
