import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InstallationPageComponent } from './pages/installation-page/installation-page.component';
import { FacilityPageComponent } from './pages/facility-page/facility-page.component';
import { FacilityMapPageComponent } from './pages/facility-map-page/facility-map-page.component';
import { RootPageComponent } from './pages/root-page/root-page.component';
import { ApplicationPageComponent } from './pages/application-page/application-page.component';
import { SystemStatusViewPageComponent } from './pages/system-status-view-page/system-status-view-page.component';
import { AlarmPageComponent } from './pages/alarm-page/alarm-page.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { AlarmMetadataComponent } from './pages/alarm-metadata/alarm-metadata.component';
import { AuthService, EmptyComponent } from 'shared';
import { MixitOnboardingComponent } from 'projects/customerportal/src/app/pages/mixit-onboarding/mixit-onboarding.component';
import { EditInstallationPageComponent } from 'projects/customerportal/src/app/pages/edit-installation-page/edit-installation-page.component';
import { EditFacilityPageComponent } from 'projects/customerportal/src/app/pages/edit-facility-page/edit-facility-page.component';
import { WelcomePageComponent } from './pages/welcome-page/welcome-page.component';
import { EditUserPageComponent } from './pages/edit-user-page/edit-user-page.component';
import { ViewUserPageComponent } from './pages/view-user-page/view-user-page.component';
import { UsersPageComponent } from './pages/users-page/users-page.component';
import { AccountPageComponent } from './pages/account-page/account-page.component';
import { AcquireLicensePageComponent } from './pages/acquire-license-page/acquire-license-page.component';
import { TrialFlowPageComponent } from './pages/trial-flow-page/trial-flow-page.component';
import { SubscribeFlowPageComponent } from './pages/subscribe-flow-page/subscribe-flow-page.component';
import { UsersContainerPageComponent } from './pages/users-container-page/users-container-page.component';
import { DeveloperDataPageComponent } from './pages/developer-data-page/developer-data-page.component';
import { LoggedInGuard } from './guards/logged-in-guard.service';
import { MixitSubscriptionOverviewComponent } from './pages/mixit-subscription-overview/mixit-subscription-overview.component';
import { HealthCheckPageComponent } from './pages/health-check-page/health-check-page.component';
import { ClaimGuard, ClaimRouteGuard } from './guards/claim.guard';
import { AccessClaim } from 'projects/shared/src/lib/interfaces/access';

const routes: Routes = [
  // AD fun stuff
  {
    path: 'callback',
    component: EmptyComponent,
  },
  {
    path: 'logout/callback',
    component: EmptyComponent,
  },
  {
    path: 'silent-renew.html',
    component: EmptyComponent,
  },
  {
    path: '',
    component: RootPageComponent,
    children: [
      // Front page
      {
        pathMatch: 'full',
        path: '',
        component: WelcomePageComponent,
      },

      // Profile, account stuff
      {
        path: 'profile',
        component: ProfilePageComponent,
        canActivate: [LoggedInGuard],
      },
      {
        path: 'account',
        component: AccountPageComponent,
        canActivate: [LoggedInGuard],
      },
      {
        path: 'account/mixit-subscriptions-overview',
        component: MixitSubscriptionOverviewComponent,
        canActivate: [LoggedInGuard],
      },
      {
        path: 'alarm-metadata',
        component: AlarmMetadataComponent,
        canActivate: [LoggedInGuard],
      },
      {
        path: 'acquire-license/:facilityId/:installationId',
        component: AcquireLicensePageComponent,
        canActivate: [LoggedInGuard],
      },

      // User management
      {
        path: 'create-user',
        component: EditUserPageComponent,
        canActivate: [LoggedInGuard],
      },
      {
        path: 'users',
        component: UsersContainerPageComponent,
        canActivate: [LoggedInGuard],
        children: [
          {
            path: '',
            component: UsersPageComponent,
          },
          {
            path: ':userId/edit',
            component: EditUserPageComponent,
            canActivate: [LoggedInGuard],
          },
          {
            path: ':userId/view',
            component: ViewUserPageComponent,
            canActivate: [LoggedInGuard],
          },
        ],
      },

      // Edit installation/facility
      {
        path: 'edit-installation/:facilityId/:installationId',
        component: EditInstallationPageComponent,
        canActivate: [LoggedInGuard],
      },
      {
        path: 'edit-facility/:facilityId',
        component: EditFacilityPageComponent,
        canActivate: [LoggedInGuard],
      },

      // Upgrade pages
      {
        path: 'upgrade/trial',
        component: TrialFlowPageComponent,
        canActivate: [LoggedInGuard],
      },
      {
        path: 'upgrade/subscribe',
        component: SubscribeFlowPageComponent,
        canActivate: [LoggedInGuard],
      },

      // Application pages
      {
        path: 'mixit/onboarding',
        component: MixitOnboardingComponent,
        canActivate: [AuthService], // This ensures you have an OIDC user
      },
      {
        path: 'facilities',
        component: FacilityMapPageComponent,
        canActivate: [LoggedInGuard],
      },
      {
        path: 'facility/:facilityId',
        component: FacilityPageComponent,
        canActivate: [LoggedInGuard],
        children: [
          {
            path: 'installation/:installationId',
            component: InstallationPageComponent,
            children: [
              {
                path: 'data-table',
                component: DeveloperDataPageComponent,
                canActivate: [ClaimGuard],
                data: {
                  [ClaimRouteGuard]: AccessClaim.CustomerPortalDataPointsTable,
                },
              },
              {
                path: 'application/:applicationId',
                component: ApplicationPageComponent,
              },
              {
                path: 'system-status',
                component: SystemStatusViewPageComponent,
              },
              {
                path: 'system-status/alarm/:alarmId',
                component: AlarmPageComponent,
              },
              {
                path: 'health-check/:healthCheckTargetKey/:healthcheckKey',
                component: HealthCheckPageComponent,
              },
            ],
          },
        ],
      },
    ],
  },
  // Catch all
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always', enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
