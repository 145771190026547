import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => {};
export const TEXT_TOGGLE_CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TextToggleComponent),
  multi: true
};

@Component({
  selector: 'gbc-text-toggle',
  templateUrl: './text-toggle.component.html',
  styleUrls: ['./text-toggle.component.css'],
  providers: [TEXT_TOGGLE_CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class TextToggleComponent {
  // Ng Model
  private innerValue: boolean;

  @Input()
  public disabled = false;
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  toggle() {
    this.value = !this.value;
  }

  //
  // Ng Model stuff
  //

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v === this.innerValue) {
      return;
    }

    this.innerValue = v;
    this.onChangeCallback(v);
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: any) {
    if (value === this.innerValue) {
      return;
    }

    this.innerValue = value;
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
