import { SystemMeta } from '../../types';
import { CONNECTION_SPACING } from '../../Common';
import { EConnectionType, SystemType } from '../../../state';
import { EConnectionPointPlacement } from '../../../types';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/simple-buffer-tank-system.svg';
import simpleBufferTankSystemForm from '../../../../models/systems/simpleBufferTankSystem/simpleBufferTankSystemForm.json';

export const HEIGHT = 280;
export const WIDTH = 196;
export const CENTER = 165;
export const FORWARD_Y = 120;
export const TANK_LEFT = 50;
export const TANK_TOP = 54;
export const TANK_TOP_TEMP_Y = TANK_TOP + 10;
export const TANK_WIDTH = 105;
export const TANK_HEIGHT = 172;
export const HEAT_METER_X = 90;
export const TANK_BOTTOM = TANK_TOP + TANK_HEIGHT;
export const TANK_BOTTOM_TEMP_Y = TANK_BOTTOM - 21;
export const RETURN_Y = FORWARD_Y + CONNECTION_SPACING;
export const TANK_RIGHT = TANK_LEFT + TANK_WIDTH;
export const PUMP_X = 110;

export const simpleBufferTankMeta: SystemMeta = {
  systemType: SystemType.SimpleBufferTankSystem,
  name: 'Simple Buffer Tank System',
  thumbnail,
  form: simpleBufferTankSystemForm,
  width: WIDTH,
  height: HEIGHT,
  calculateConnectionPoints: () => [
    {
      key: EConnectionPointPlacement.connectionPrimaryReturn,
      x: 0,
      y: RETURN_Y,
      type: EConnectionType.supply,
      dotted: false,
    },
    {
      key: EConnectionPointPlacement.connectionSecondaryForward,
      x: WIDTH,
      y: FORWARD_Y,
      type: EConnectionType.supply,
      dotted: false,
    },
  ],
};
