import { Component, Input, OnInit } from '@angular/core';
import { Installation } from '../../../interfaces/facilty';
import { Observable } from 'rxjs';
import { AppModel, MixingLoopSystemState, MixingLoopUnspecifiedLoadSystemState, SystemType } from '@ams/dumbledore';
import { filter, map, switchMap } from 'rxjs/operators';
import { AlarmOverview, Application } from '../../../interfaces/alarm';
import { DataPointsResult, DeviceDataPoint, SystemDevice } from '../../../interfaces/data-points';
import { getPumpOptions, getPumpType, systemsToPumpDevice, systemsToValveDevice } from '../../../utils/data-point-utils';
import { SchematicsService } from '../../../services/schematics.service';
import { PumpOption } from 'projects/customerportal/src/app/services/installation-configuration.service';
import { systemHasEnergyValve } from 'projects/dumbledore/src/schematic/util/system-properties-util';

@Component({
  selector: 'app-mixing-loop-dashboard',
  templateUrl: './mixing-loop-dashboard.component.html',
  styleUrls: ['./mixing-loop-dashboard.component.scss'],
})
export class MixingLoopDashboardComponent implements OnInit {
  @Input() public alarm$: Observable<AlarmOverview>;
  @Input() public application: Application;
  @Input() public installation$: Observable<Installation>;
  @Input() public schematic$: Observable<AppModel>;
  @Input() public dataPoints$: Observable<DataPointsResult>;

  public outdoorTemp$: Observable<number | null>;
  public weatherCompensationEnabled$: Observable<boolean>;
  public pumpType$: Observable<string>;
  public pumpDevice$: Observable<SystemDevice | undefined>;
  public pumpOptions$: Observable<PumpOption[] | undefined>;
  public valveDevice$: Observable<SystemDevice | undefined>;
  public hasEnergyValve$: Observable<boolean | undefined>;
  public isWarmWeatherShutdownAvailable$: Observable<boolean | undefined>;

  private system$: Observable<MixingLoopSystemState | MixingLoopUnspecifiedLoadSystemState>;

  constructor(private schematicsService: SchematicsService) {}

  ngOnInit(): void {
    this.system$ = this.schematic$.pipe(
      map((schematic) => {
        return schematic.systems.find((s) => s.hasId === this.application.id) as
          | MixingLoopSystemState
          | MixingLoopUnspecifiedLoadSystemState;
      })
    );

    this.outdoorTemp$ = this.dataPoints$.pipe(
      filter((d) => !!d?.data),
      map((dataPoints) => {
        const allDataPoints = dataPoints.data.flatMap((s) => s.devices).flatMap((d) => d.dataPoints);
        const outdoorTempDataPoint = allDataPoints.find((dataPoint) => dataPoint.dumbledoreId === 'T_OUTDOOR');
        return outdoorTempDataPoint?.value ? parseInt(outdoorTempDataPoint.value, 10) : null;
      })
    );

    this.weatherCompensationEnabled$ = this.system$.pipe(
      map((system) => {
        if (system.type === SystemType.MixingloopUnspecifiedLoadSystem) {
          return false;
        }
        return system?.systemInfo?.Weather_compensation_enabled || false;
      })
    );

    this.isWarmWeatherShutdownAvailable$ = this.schematicsService.getWarmWeatherShutdownAvailableState(this.application.id).pipe(
      map((warmWeatherShutdownAvailable) => {
        return warmWeatherShutdownAvailable;
      })
    );

    this.pumpDevice$ = this.dataPoints$.pipe(systemsToPumpDevice(this.application.id));

    this.pumpType$ = getPumpType(this.schematic$, this.application.id);

    this.pumpOptions$ = getPumpOptions(this.pumpDevice$, this.schematicsService, this.application.id);

    this.valveDevice$ = this.dataPoints$.pipe(systemsToValveDevice(this.application.id));

    this.hasEnergyValve$ = this.system$.pipe(
      map((system) => {
        return systemHasEnergyValve(system?.systemInfo);
      })
    );
  }
}
