<app-mixit-x509-banner/>
<app-alarm-bar *ngIf="alarm$ | async" [alarm]="alarm$"> </app-alarm-bar>

<app-schematics-tile
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [showShadow]="false"
  [dataPoints$]="dataPoints$">
</app-schematics-tile>

<app-pump-status-tile
  *ngIf="pumpDevice$ | async"
  [application]="application"
  [installation$]="installation$"
  [pumpType$]="pumpType$"
  [pumpDevice$]="pumpDevice$"
  [pumpOptions]="pumpOptions$ | async">
</app-pump-status-tile>

<app-valve-status-tile
  *ngIf="valveDevice$ | async"
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [dataPoints$]="dataPoints$"
  [valveDevice$]="valveDevice$"
  [energyValve]="hasEnergyValve$ | async">
</app-valve-status-tile>

<app-forward-return-chart-tile
  [title]="'two-stroke-heat-exchanger-dashboard.secondary-chart-title' | translate"
  [applicationId]="application.id"
  forwardDumbledoreId="T_SF"
  returnDumbledoreId="T_SR"
  [dataPoints$]="dataPoints$"
  [userTrackingPeriodOptions]="{ category: 'application/twoStrokeHeatExchanger', intervalPrefix: 'secondaryTemperature' }">
</app-forward-return-chart-tile>

<app-valve-chart
  [dataPoints$]="dataPoints$"
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [hasEnergyValve]="hasEnergyValve$ | async"
  [userTrackingPeriodOptions]="{ category: 'application/twoStrokeHeatExchanger', intervalPrefix: 'valvePosition' }">
</app-valve-chart>

<app-energy-valve-heat-and-flow-chart
  *ngIf="hasEnergyValve$ | async"
  [dataPoints$]="dataPoints$"
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id">
</app-energy-valve-heat-and-flow-chart>

<app-energy-valve-temperature-chart
  *ngIf="hasEnergyValve$ | async"
  [dataPoints$]="dataPoints$"
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id">
</app-energy-valve-temperature-chart>
