<gbc-page-error-handler [content]="content" [pageError$]="pageError$">
</gbc-page-error-handler>
<ng-template #content>
  <ng-container *ngIf="alarm$ | async as alarm">
    <gbc-page-header *ngIf="pageInfo$ | async as pageInfo" [pageInfo]="pageInfo"></gbc-page-header>
    <gbc-page-container>
      <gbc-tile [title]="alarm.type">
        <app-key-value>
          <dt app-key>{{'alarm-page.event-code' | translate}}</dt>
          <dd app-value><img class="alarm-icon" [src]="alarm.type | alarmIconLink">{{alarm.type}}
            {{alarm.metadata | formatAlarmMetadata }}: {{alarm.metadata.title}}</dd>
          <dt app-key>{{'alarm-page.occurred' | translate}}</dt>
          <dd app-value>{{alarm.currentActivation.activatedTimestampEpoch | timeWithAgo}}</dd>
          <dt app-key>{{'alarm-page.cleared' | translate}}</dt>
          <dd app-value id="cleared">
            {{alarm.currentActivation.clearedTimestampEpoch ? (alarm.currentActivation.clearedTimestampEpoch | timeWithAgo) : ('alarm-page.history-table.no' | translate)}}
          </dd>
          <ng-container *ngIf="alarm.metadata.conditions && alarm.metadata.conditions.length > 0">
            <dt app-key>{{'alarm-page.conditions' | translate}}</dt>
            <dd app-value>
              <ol>
                <li *ngFor="let condition of alarm.metadata.conditions">{{condition.value}}</li>
              </ol>
            </dd>
          </ng-container>
          <ng-container *ngIf="alarm.metadata.causes && alarm.metadata.causes.length > 0">
            <dt app-key>{{'alarm-page.root-cause' | translate}}</dt>
            <dd app-value>
              <ol>
                <li *ngFor="let cause of alarm.metadata.causes">{{cause.description}}</li>
              </ol>
            </dd>
          </ng-container>
          <ng-container *ngIf="alarm.metadata.causes?.length > 0 && alarm.metadata.causes[0]?.remedySteps?.length > 0">
            <dt app-key>{{'alarm-page.remedy-suggestions' | translate}}</dt>
            <dd app-value>
              <ol>
                <li *ngFor="let remedyStep of alarm.metadata.causes[0].remedySteps">{{remedyStep.command}}</li>
              </ol>
            </dd>
          </ng-container>
          <ng-container *ngIf="!alarm.currentActivation.clearedTimestampEpoch">
            <dt app-key>{{'alarm-page.override' | translate}}</dt>
            <dd app-value>
              <app-manual-override-box [overridden]="overriden$ | async" (override)="handleOverride($event)"></app-manual-override-box>
            </dd>
          </ng-container>
        </app-key-value>
      </gbc-tile>
      <gbc-tile *ngIf="alarm && alarm.comments && alarm.comments.length > 0" [title]="'alarm-page.comments-tile-header' | translate">
        <app-alarm-comments-table [comments]="alarm.comments"></app-alarm-comments-table>
      </gbc-tile>
      <app-add-alarm-comment-tile (commentSubmit)="handleComment($event)"></app-add-alarm-comment-tile>
      <gbc-tile [title]="'alarm-page.history-tile-header' | translate">
        <gbc-table>
          <th gbc-table-header>{{'alarm-page.history-table.header-activated' | translate}}</th>
          <th gbc-table-header>{{'alarm-page.history-table.header-cleared' | translate}}</th>
          <th gbc-table-header>{{'alarm-page.history-table.header-overridden' | translate}}</th>
          <tr gbc-table-row *ngFor="let entry of alarm.history">
            <td gbc-table-cell>{{entry.activatedTimestampEpoch | timeWithAgo}}</td>
            <td gbc-table-cell>
              {{entry.clearedTimestampEpoch ? (entry.clearedTimestampEpoch | timeWithAgo) : ('alarm-page.history-table.no' | translate)}}
            </td>
            <td gbc-table-cell>
              {{entry.overriddenTimestampEpoch ? (entry.overriddenTimestampEpoch | timeWithAgo) : ('alarm-page.history-table.no' | translate)}}
            </td>
          </tr>
        </gbc-table>
      </gbc-tile>
    </gbc-page-container>
  </ng-container>
</ng-template>
