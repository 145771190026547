import { SystemType } from '../../../state';
import { EEquipment } from '../../../types';
import { LabelPosition, SystemMeta } from '../../types';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/boiler.svg';
import boosterSystemForm from '../../../../models/systems/boosterSystem/boosterSystemForm.json';
import { IBoosterSystemFormInterface } from '../../../../models/systems/boosterSystem/boosterSystemFormInterface';

export const HEIGHT = 420;
export const WIDTH = 700;
export const PIPE_Y = 250 + 60 + 20 + 40;
export const START_X = 50;
export const END_X = 650;
export const ARROW_1_X = 5;
export const ARROW_Y = PIPE_Y - 13;
export const ARROW_2_X = WIDTH - 45;
export const TEMP_SENSOR_Y = PIPE_Y;
export const TEMP_SENSOR_1_X = 200;
export const TEMP_SENSOR_2_X = 500;
export const PUMP_X = WIDTH / 2;
export const FLOW_METER_X = 580;

export const boosterSystemMeta: SystemMeta = {
  systemType: SystemType.BoosterSystem,
  name: 'Booster',
  thumbnail,
  width: WIDTH,
  height: HEIGHT,
  form: boosterSystemForm,
  calculateConnectionPoints: () => [],
};

export const getBoosterLabels = (systemInfo: IBoosterSystemFormInterface): LabelPosition[] => {
  const pressureSensorLeft: LabelPosition = {
    id: EEquipment.H_PF,
    x: 200,
    y: 315,
    horizontalAlignment: 'middle',
  };

  const pressureSensorRight: LabelPosition = {
    id: EEquipment.H_SF,
    x: 500,
    y: 315,
    horizontalAlignment: 'middle',
  };

  const flowMeter: LabelPosition = {
    id: EEquipment.Q_SF,
    x: 580,
    y: 330,
    horizontalAlignment: 'middle',
  };

  const bottomPumpY = 400;
  const pump1: LabelPosition = {
    id: EEquipment.P_1,
    x: 350,
    y: bottomPumpY,
    horizontalAlignment: 'middle',
  };

  const numberOfPumps = systemInfo.NumberOfPumps as number;

  const pump2: LabelPosition[] =
    numberOfPumps >= 2 && numberOfPumps <= 6
      ? [
          {
            id: EEquipment.P_2,
            x: 350,
            y: bottomPumpY - 60,
            horizontalAlignment: 'middle',
          },
        ]
      : [];

  const pump3: LabelPosition[] =
    numberOfPumps >= 3 && numberOfPumps <= 6
      ? [
          {
            id: EEquipment.P_3,
            x: 350,
            y: bottomPumpY - 2 * 60,
            horizontalAlignment: 'middle',
          },
        ]
      : [];

  const pump4: LabelPosition[] =
    numberOfPumps >= 4 && numberOfPumps <= 6
      ? [
          {
            id: EEquipment.P_4,
            x: 350,
            y: bottomPumpY - 3 * 60,
            horizontalAlignment: 'middle',
          },
        ]
      : [];

  const pump5: LabelPosition[] =
    numberOfPumps >= 5 && numberOfPumps <= 6
      ? [
          {
            id: EEquipment.P_5,
            x: 350,
            y: bottomPumpY - 4 * 60,
            horizontalAlignment: 'middle',
          },
        ]
      : [];

  const pump6: LabelPosition[] =
    numberOfPumps >= 6 && numberOfPumps <= 6
      ? [
          {
            id: EEquipment.P_6,
            x: 350,
            y: bottomPumpY - 5 * 60,
            horizontalAlignment: 'middle',
          },
        ]
      : [];

  return [pressureSensorLeft, pressureSensorRight, flowMeter, pump1, ...pump2, ...pump3, ...pump4, ...pump5, ...pump6];
};
