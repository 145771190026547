import { createAction } from '@reduxjs/toolkit';
import { ConnectionPointIdentifier, SystemState } from './state';
import { AppModel, SchematicMode, SystemControlMode, TEquipmentValues } from './types';

export interface SystemSelect {
  systemId: string;
}

export const systemSelect = createAction<SystemSelect>('schematic/system-select');

export const systemUnselect = createAction<void>('schematic/system-unselect');

export interface ConfirmDeleteSystem {
  systemId: string;
}
export const confirmDeleteSystem = createAction<ConfirmDeleteSystem>('schematic/confirm-delete-system');

export interface SetMode {
  mode: SchematicMode;
}
export const setMode = createAction<SetMode>('schematic/set-mode');

export interface SetConnectionPointPosition {
  id: string;
  x: number;
  y: number;
}

export const setConnectionPointPosition = createAction<SetConnectionPointPosition>('schematic/set-connection-point-position');

export interface StartCreatingConnection {
  from: ConnectionPointIdentifier;
  toX: number;
  toY: number;
}

export const startCreatingConnection = createAction<StartCreatingConnection>('schematic/start-creating-connection');

export interface FinishCreatingConnection {
  to: ConnectionPointIdentifier;
}

export const finishCreatingConnection = createAction<FinishCreatingConnection>('schematic/finish-creating-connection');

export const cancelCreatingConnection = createAction<void>('schematic/cancel-creating-connection');

export interface UpdateConnectionBeingCreatedPosition {
  newX: number;
  newY: number;
}

export const updateConnectionBeingCreatedPosition = createAction<UpdateConnectionBeingCreatedPosition>(
  'schematic/update-connection-being-created-position'
);

export interface DeleteConnection {
  targetId: string;
}
export const deleteConnection = createAction<DeleteConnection>('schematic/delete-connection');

export interface StartDraggingConnectionPoint {
  targetId: string;
}
export const startDraggingConnectionPoint = createAction<StartDraggingConnectionPoint>('schematic/start-dragging-connection-point');

export const stopDraggingConnectionPoint = createAction<void>('schematic/stop-dragging-connection-point');

export interface DeleteConnectionPoint {
  connectionPointId: ConnectionPointIdentifier;
}
export const deleteConnectionPoint = createAction<DeleteConnectionPoint>('schematic/delete-connection-point');

export interface AddSystem {
  system: SystemState;
}
export const addSystem = createAction<AddSystem>('schematic/add-system');

export interface RemoveSystem {
  systemId: string;
}
export const removeSystem = createAction<RemoveSystem>('schematic/remove-system');

export interface CloneSystem {
  systemId: string;
}

export const cloneSystem = createAction<CloneSystem>('schematic/clone-system');

export interface SetSystemInfo {
  systemId: string;
  systemInfo: any;
  configured: boolean;
}
export const setSystemInfo = createAction<SetSystemInfo>('schematic/set-system-info');

export interface SetSystemPosition {
  systemId: string;
  x: number;
  y: number;
}
export const setSystemPosition = createAction<SetSystemPosition>('schematic/set-system-position');

export interface StartDraggingSystem {
  systemId: string;
  offsetX: number;
  offsetY: number;
}
export const startDraggingSystem = createAction<StartDraggingSystem>('schematic/start-dragging-system');

export const stopDraggingSystem = createAction<void>('schematic/stop-dragging-system');

export interface SplitConnection {
  connectionId: string;
  x: number;
  y: number;
}
export const splitConnection = createAction<SplitConnection>('schematic/split-connection');

export interface LoadSchematic {
  schematic: AppModel;
  systemId?: string;
}
export const loadSchematic = createAction<LoadSchematic>('schematic/load-schematic');

export interface SetValues {
  values: TEquipmentValues[];
}
export const setValues = createAction<SetValues>('schematic/set-values');

//-- Alarms ----------------------------------------------------------------------------

export type Alarm = 'Alarm';
export type Warning = 'Warning';

export interface AlarmsWarnings {
  systemId: string;
  type: Alarm | Warning;
}
export const setSystemAlarmsWarnings = createAction<AlarmsWarnings[]>('schematic/set-system-alarms-warning');

//-- Pasteurization --------------------------------------------------------------------

export interface InfoState {
  systemId: string;
  pasteurization: boolean;
}

export const setSystemInfoState = createAction<InfoState[]>('schematic/set-system-info-state');

//-- System Control Mode ---------------------------------------------------------------

export interface SystemControlModePayload {
  systemId: string;
  systemControlMode: SystemControlMode;
};

export const setSystemControlMode = createAction<SystemControlModePayload[]>('schematic/set-system-control-mode');

export interface WarmWeatherShutDownPayload {
  systemId: string;
  warmWeatherShutdown: boolean;
};

export const setWarmWeatherShutDown = createAction<WarmWeatherShutDownPayload[]>('schematic/set-warm-weather-shut-down');

export interface ShowOutdoorTemperatureSensorPayload {
  systemId: string;
  showOutdoorTemperatureSensor: boolean;
};

export const setShowOutdoorTemperatureSensor = createAction<ShowOutdoorTemperatureSensorPayload[]>('schematic/set-show-outdoor-temperature-sensor');



