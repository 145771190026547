import { Component, Input, OnInit } from '@angular/core';
import { AlarmOverview, Application } from '../../../interfaces/alarm';
import { combineLatest, Observable } from 'rxjs';
import { Installation } from '../../../interfaces/facilty';
import { AppModel, BufferTankSystemState, EEquipment } from '@ams/dumbledore';
import { DataPointsResult, SystemDevice } from '../../../interfaces/data-points';
import { PumpOption } from '../../../services/installation-configuration.service';
import { SchematicsService } from '../../../services/schematics.service';
import { getPumpOptions, getPumpType, systemsToPumpDevice } from '../../../utils/data-point-utils';
import { map } from 'rxjs/operators';
import { Serie, SerieType } from '../../charts/temperature-chart-tile/temperature-chart-tile.component';
import { TranslateService } from '@ngx-translate/core';
import { IBufferTankSystemFormInterface } from '../../../../../../dumbledore/src/models/systems/bufferTankSystem/bufferTankSystemFormInterface';

@Component({
  selector: 'app-buffer-tank-dashboard',
  templateUrl: './buffer-tank-dashboard.component.html',
  styleUrls: ['./buffer-tank-dashboard.component.scss'],
})
export class BufferTankDashboardComponent implements OnInit {
  @Input() public alarm$: Observable<AlarmOverview>;
  @Input() public application: Application;
  @Input() public installation$: Observable<Installation>;
  @Input() public schematic$: Observable<AppModel>;
  @Input() public dataPoints$: Observable<DataPointsResult>;

  public pumpType$: Observable<string>;
  public pumpDevice$: Observable<SystemDevice | undefined>;
  public pumpOptions$: Observable<PumpOption[] | undefined>;
  public temperatureSeries$: Observable<Serie[]>;
  public tankTemperatureSeries$: Observable<Serie[]>;
  public temperatureColors: string[] = ['#DD0028', '#0068B4', '#F5B2BF', '#B2D2E8'];
  public tankTemperatureColors: string[] = ['#9D0027', '#DD0028', '#F49500', '#FFD300'];

  private system$: Observable<BufferTankSystemState>;

  constructor(private schematicService: SchematicsService, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.system$ = (this.schematicService.schematic$ as Observable<AppModel>).pipe(
      map((schematic) => {
        return schematic.systems.find((s) => s.hasId === this.application.id) as BufferTankSystemState;
      })
    );

    this.pumpDevice$ = this.dataPoints$.pipe(systemsToPumpDevice(this.application.id));
    this.pumpType$ = getPumpType(this.schematic$, this.application.id);

    this.pumpOptions$ = this.pumpOptions$ = getPumpOptions(this.pumpDevice$, this.schematicService, this.application.id);

    const showTPF = (systemInfo: IBufferTankSystemFormInterface) => {
      return systemInfo.T_PF_present;
    };

    const showTPR = (systemInfo: IBufferTankSystemFormInterface) => {
      return systemInfo.T_PR_present;
    };

    const primaryForwardLegend$ = this.translateService.get('buffer-tank-dashboard.primary-forward');
    const secondaryForwardLegend$ = this.translateService.get('buffer-tank-dashboard.secondary-forward');
    const primaryReturnLegend$ = this.translateService.get('buffer-tank-dashboard.primary-return');
    const secondaryReturnLegend$ = this.translateService.get('buffer-tank-dashboard.secondary-return');
    this.temperatureSeries$ = combineLatest([
      this.system$,
      primaryForwardLegend$,
      secondaryForwardLegend$,
      primaryReturnLegend$,
      secondaryReturnLegend$,
    ]).pipe(
      map(([system, primaryForwardLegend, secondaryForwardLegend, primaryReturnLegend, secondaryReturnLegend]) => {
        if (!system?.systemInfo) {
          return [];
        }

        const series: Serie[] = [];

        if (showTPF(system.systemInfo)) {
          series.push({
            type: SerieType.DataPoint,
            title: primaryForwardLegend,
            dumbledoreId: EEquipment.T_PF,
          });
        }

        if (showTPR(system.systemInfo)) {
          series.push({
            type: SerieType.DataPoint,
            title: primaryReturnLegend,
            dumbledoreId: EEquipment.T_PR,
          });
        }

        if (system.systemInfo.T_SF_present) {
          series.push({
            type: SerieType.DataPoint,
            title: secondaryForwardLegend,
            dumbledoreId: EEquipment.T_SF,
          });
        }

        if (system.systemInfo.T_SR_present) {
          series.push({
            type: SerieType.DataPoint,
            title: secondaryReturnLegend,
            dumbledoreId: EEquipment.T_SR,
          });
        }

        return series;
      })
    );

    const tankTop$ = this.translateService.get('buffer-tank-dashboard.tank-top');
    const tankCenterTop$ = this.translateService.get('buffer-tank-dashboard.tank-center-top');
    const tankCenterBottom$ = this.translateService.get('buffer-tank-dashboard.tank-center-bottom');
    const tankBottom$ = this.translateService.get('buffer-tank-dashboard.tank-bottom');
    this.tankTemperatureSeries$ = combineLatest([this.system$, tankTop$, tankCenterTop$, tankCenterBottom$, tankBottom$]).pipe(
      map(([system, tankTop, tankCenterTop, tankCenterBottom, tankBottom]) => {
        if (!system?.systemInfo) {
          return [];
        }

        const series: Serie[] = [];

        series.push({
          type: SerieType.DataPoint,
          title: tankTop,
          dumbledoreId: EEquipment.T_T,
        });

        if (system.systemInfo.T_CENTERTOP_present) {
          series.push({
            type: SerieType.DataPoint,
            title: tankCenterTop,
            dumbledoreId: EEquipment.T_CENTERTOP,
          });
        }

        if (system.systemInfo.T_CENTERBOTTOM_present) {
          series.push({
            type: SerieType.DataPoint,
            title: tankCenterBottom,
            dumbledoreId: EEquipment.T_CENTERBOTTOM,
          });
        }

        series.push({
          type: SerieType.DataPoint,
          title: tankBottom,
          dumbledoreId: EEquipment.T_BOTTOM,
        });

        return series;
      })
    );
  }
}
