import { ErrorHandler, Injectable, Inject } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { sentryConfig } from '../injection-tokens';
import { ISentryErrorHandlerConfig } from '../interfaces/sentryErrorHandlerConfig';
import { AppError } from '../services/app-error.service';



@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  private configuration: ISentryErrorHandlerConfig;

  constructor(@Inject(sentryConfig) configuration: ISentryErrorHandlerConfig) {
    this.configuration = configuration;
    if (configuration.enabled) {
      Sentry.init({
        dsn: configuration.dsn,
        release: configuration.release
      });
    }
  }

  handleError(error: any) {
    if (this.configuration.enabled) {
      Sentry.captureException(error.originalError || error);
    } else {
      console.log('Error would have passed to sentry if enabled', error.originalError || error);
    }
  }
}
