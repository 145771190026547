import { CONNECTION_SPACING } from '../../Common';
import { LabelPosition, SystemMeta } from '../../types';
import { EConnectionType, SystemType } from '../../../state';
import { EConnectionPointPlacement, EEquipment } from '../../../types';
import { LargeLabelDeltas, SmallLabelDeltas } from '../../../util/constants';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/hot-water-tank.svg';
import hotWaterTankSystemForm from '../../../../models/systems/hotWaterTankSystem/hotWaterTankSystemForm.json';
import { getHorizontalHeatMeterLabels, getPrimaryTempLabels } from '../../heat-meter-or-sensors/heat-meter-or-sensors-metadata';
import { IHotWaterTankSystemFormInterface } from '../../../../models/systems/hotWaterTankSystem/hotWaterTankSystemFormInterface';

export const HEIGHT = 360;
export const WIDTH = 500;

export const FORWARD_Y = 160;
export const TANK_LEFT = 209;
export const TANK_TOP = 94;
export const TANK_TOP_TEMP_Y = TANK_TOP + 10;
export const TANK_WIDTH = 105;
export const TANK_HEIGHT = 172;
export const VALVE_X = 175;
export const HEAT_METER_X = 90;
export const SECONDARY_LOOP_TOP = 70;
export const SECONDARY_LOOP_RIGHT = 426;
export const PUMP_X = 378;
export const PUMP_Y = 101;
export const COLD_SUPPLY_BOTTOM = 290;
export const COLD_SUPPLY_RIGHT = 380;
export const COLD_SUPPLY_FLOW_X = 350;
export const COLD_SUPPLY_LEFT = TANK_LEFT + Math.ceil(TANK_WIDTH / 2);
export const TANK_BOTTOM = TANK_TOP + TANK_HEIGHT;
export const TANK_BOTTOM_TEMP_Y = TANK_BOTTOM - 6;
export const TANK_MIDDLE = (TANK_TOP + TANK_BOTTOM) / 2;
export const RETURN_Y = FORWARD_Y + CONNECTION_SPACING;
export const TANK_RIGHT = TANK_LEFT + TANK_WIDTH;
export const T_SR_X = TANK_RIGHT + 10;
export const T_SR_Y = TANK_TOP + 50;

export const hotWaterTankMeta: SystemMeta = {
  systemType: SystemType.HotWaterTank,
  name: 'Hot Water Tank',
  thumbnail,
  form: hotWaterTankSystemForm,
  width: WIDTH,
  height: HEIGHT,
  calculateConnectionPoints: (f: IHotWaterTankSystemFormInterface) => [
    {
      key: EConnectionPointPlacement.connectionPrimaryForward,
      x: 0,
      y: FORWARD_Y,
      type: EConnectionType.supply,
      dotted: false,
    },
    {
      key: EConnectionPointPlacement.connectionPrimaryReturn,
      x: 0,
      y: RETURN_Y,
      type: EConnectionType.return,
      dotted: true,
    },
  ],
};

export const getHotWaterTankLabels = (systemInfo: IHotWaterTankSystemFormInterface): LabelPosition[] => {
  const coldWaterSupplyFlowLabel: LabelPosition[] = systemInfo.Q_SC_present
    ? [
        {
          id: EEquipment.Q_SC,
          x: COLD_SUPPLY_FLOW_X,
          y: COLD_SUPPLY_BOTTOM + SmallLabelDeltas.WEST_Y,
          horizontalAlignment: 'middle',
        },
      ]
    : [];
  const pumpLabel: LabelPosition[] = systemInfo.P_connected
    ? [
        {
          id: EEquipment.P_SR,
          x: PUMP_X,
          y: PUMP_Y + SmallLabelDeltas.WEST_Y + 4, // we offset a little because pump labels are two lines
          horizontalAlignment: 'middle',
        },
      ]
    : [];
  const tankTopLabel: LabelPosition = {
    id: EEquipment.T_T,
    x: TANK_LEFT - 50,
    y: TANK_TOP_TEMP_Y - 20,
  };
  const tankMiddleLabel: LabelPosition[] = systemInfo.T_MIDDLE_present
    ? [
        {
          id: EEquipment.T_MIDDLE,
          x: TANK_RIGHT + LargeLabelDeltas.SOUTH_X,
          y: TANK_MIDDLE,
        },
      ]
    : [];
  const tankBottomLabel: LabelPosition[] = systemInfo.T_BOTTOM_present
    ? [
        {
          id: EEquipment.T_BOTTOM,
          x: TANK_RIGHT + LargeLabelDeltas.SOUTH_X,
          y: TANK_BOTTOM_TEMP_Y,
        },
      ]
    : [];
  const tsrLabel: LabelPosition[] = systemInfo.T_SR_present
    ? [
        {
          id: EEquipment.T_SR,
          x: T_SR_X + SmallLabelDeltas.TEMP_SENSOR_HORIZONTAL_DX,
          y: T_SR_Y + SmallLabelDeltas.WEST_Y,
        },
      ]
    : [];
  const valveLabel: LabelPosition[] =
    systemInfo.MV_valveLocation === 'PrimaryForward'
      ? [
          {
            id: EEquipment.MV_2W_PF,
            x: VALVE_X,
            y: FORWARD_Y + SmallLabelDeltas.EAST_Y,
            horizontalAlignment: 'middle',
          },
        ]
      : [
          {
            id: EEquipment.MV_2W_PR,
            x: VALVE_X,
            y: RETURN_Y + SmallLabelDeltas.WEST_Y,
            horizontalAlignment: 'middle',
          },
        ];

  return [
    tankTopLabel,
    ...tankMiddleLabel,
    ...tankBottomLabel,
    ...getHorizontalHeatMeterLabels(HEAT_METER_X, FORWARD_Y, systemInfo),
    ...getPrimaryTempLabels(HEAT_METER_X, FORWARD_Y, systemInfo),
    ...coldWaterSupplyFlowLabel,
    ...pumpLabel,
    ...tsrLabel,
    ...valveLabel,
  ];
};
