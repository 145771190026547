<gbc-table [clickableRows]="true">
    <th gbc-table-header>{{'facility-list.name' | translate}}</th>
    <th gbc-table-header>{{'facility-list.facility-type' | translate}}</th>
    <th gbc-table-header>{{'facility-list.address' | translate}}</th>
    <th gbc-table-header>{{'facility-list.city-and-postal-code' | translate}}</th>

    <tr gbc-table-row *ngFor="let facility of facilities" (rowClicked)="navigateToFacility(facility)">
        <td gbc-table-cell>{{facility.name}}</td>
        <td gbc-table-cell>{{facility.facilityType}}</td>
        <td gbc-table-cell>{{facility.addressRoad}}</td>
        <td gbc-table-cell>{{facility.addressCity}}, {{facility.addressPostal}}</td>
    </tr>
</gbc-table>
