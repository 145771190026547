import React from 'react';
import { SvgImage } from '../SvgImage';
import { Pipe, PipeType } from '../Pipe';
import { CONNECTION_SPACING } from '../Common';
import fanCoilIcon from '!raw-loader!../../../../assets/icons/fan-coil.svg';



const FAN_COIL_SVG_WIDTH = 54;
const FAN_COIL_SVG_HEIGHT = 33;
const ICON_LEFT = CONNECTION_SPACING / 2 - FAN_COIL_SVG_WIDTH / 2;



export const FORWARD_Y = 1;
export const RETURN_Y = FAN_COIL_SVG_HEIGHT - 1;

// The Y-value of the left hand pipe, when in right and left config
export const FAN_COIL_LEFT_PIPE_Y_LEFT = FAN_COIL_SVG_HEIGHT - FORWARD_Y;
export const FAN_COIL_LEFT_PIPE_Y_RIGHT = FORWARD_Y;

// The Y-value of the right hand pipe, when in right and left config
export const FAN_COIL_RIGHT_PIPE_Y_LEFT = FAN_COIL_SVG_HEIGHT - RETURN_Y;
export const FAN_COIL_RIGHT_PIPE_Y_RIGHT = RETURN_Y;

export const FanCoil: React.FC<{ x: number; y: number; reversedWarmCold?: boolean }> = ({ x, y, reversedWarmCold }) => {
  const leftPipeY = reversedWarmCold ? FAN_COIL_LEFT_PIPE_Y_LEFT : FAN_COIL_LEFT_PIPE_Y_RIGHT;
  const rightPipeY = reversedWarmCold ? FAN_COIL_RIGHT_PIPE_Y_LEFT : FAN_COIL_RIGHT_PIPE_Y_RIGHT;
  const fanCoilLeft = reversedWarmCold ? FAN_COIL_SVG_WIDTH + ICON_LEFT : ICON_LEFT;
  return (
    <g transform={`translate(${x} ${y})`}>
      <Pipe x1={0} y1={leftPipeY} x2={ICON_LEFT + 4} y2={leftPipeY} type={reversedWarmCold ? PipeType.ColdReturn : PipeType.WarmSupply} />
      <Pipe
        x1={CONNECTION_SPACING}
        y1={rightPipeY}
        x2={ICON_LEFT + FAN_COIL_SVG_WIDTH - 2}
        y2={rightPipeY}
        type={reversedWarmCold ? PipeType.WarmSupply : PipeType.ColdReturn}
      />
      <SvgImage svgText={fanCoilIcon} transforms={[`scale(${reversedWarmCold ? -1 : 1}, 1)`]} x={fanCoilLeft} y={0} />
    </g>
  );
};
