<ng-container>
  <gbc-breadcrumb [pageInfo]="pageInfo"></gbc-breadcrumb>
  <gbc-stepper #stepper linear (selectionChange)="stepperChange($event)" (done)="done()"
    [nextDisabled]="(canContinue$ | async) === false"
    [doneDisabled]="(canContinue$ | async) === false"
    [backLabel]="'app-back' | translate" [nextLabel]="'app-next' | translate"
    [doneLabel]="'app-done' | translate">

    <cdk-step [label]="'upgrade-flow.subscription-type' | translate">
      <gbc-tile [title]="'upgrade-flow.select-license-tile-title' | translate">
        <div class="category-picker">
          <button gbc-button *ngIf="subscriptionLicenseCategory$ | async as subCat" (click)="onSelectCategory(subCat.key)" [ngClass]="{ 'untoggled': selectedCategory !== subCat.key}" class="button primary">
            <span>{{ 'license-selector.subscription' | translate }}</span>
          </button>
          <button gbc-button *ngIf="oneTimeFeeLicenseCategory$ | async as otfCat" (click)="onSelectCategory(otfCat.key)" [ngClass]="{ 'untoggled': selectedCategory !== otfCat.key}" class="button primary">
            <span>{{ 'license-selector.one-time-fee' | translate }}</span>
          </button>
        </div>
        <div class="license-grid">
          <ng-container *ngIf="selectedCategory === 'one-time-fee'">
            <ng-container *ngIf="oneTimeFeeLicenseCategory$ | async as otfCategory">
              <ng-container *ngFor="let license of otfCategory.licenses">
                <app-content-tile [title]="'upgrade-flow.' + license.translationKey + '-title' | translate" class="license">
                  <figure>
                    <img *ngIf="license.translationKey === 'building-connect'" src="./assets/svgs/bc-professional.svg" />
                    <img *ngIf="license.translationKey === 'mixit-dynamic'" src="./assets/svgs/mixit.svg" />
                  </figure>
                  <p>{{ 'upgrade-flow.' + license.translationKey + '-one-time-fee-paragraph' | translate }}</p>
                  <ul class="benefits">
                    <li><img src="./assets/svgs/check-mark-outline.svg" /><span>{{ license.translationKey + '.benefit-1' | translate }}</span></li>
                    <li><img src="./assets/svgs/check-mark-outline.svg" /><span>{{ license.translationKey + '.benefit-2' | translate }}</span></li>
                    <li><img src="./assets/svgs/check-mark-outline.svg" /><span>{{ license.translationKey + '.benefit-3' | translate }}</span></li>
                  </ul>
                  <footer class="footer">
                    <div class="price">
                      <span>€{{ license.priceEuro }}</span>
                    </div>
                    <button gbc-button (click)="onSelectLicense(license)" class="button">
                      <ng-container *ngIf="(selectedLicenseType$ | async) !== license.licenseType">
                        <span>{{ 'license-selector.choose' | translate }}</span>
                      </ng-container>
                      <ng-container *ngIf="(selectedLicenseType$ | async) === license.licenseType">
                        <img src="./assets/svgs/check-mark.svg" /> <span>{{ 'license-selector.selected' | translate }}</span>
                      </ng-container>
                    </button>
                  </footer>
                </app-content-tile>
              </ng-container>
            </ng-container>
          </ng-container>
          <ng-container *ngIf="selectedCategory === 'subscription'">
            <ng-container *ngIf="subscriptionLicenseCategory$ | async as subCategory">
              <ng-container *ngFor="let license of subCategory.licenses">
                <app-content-tile [title]="'upgrade-flow.' + license.translationKey + '-title' | translate" class="license">
                  <figure>
                    <img *ngIf="license.translationKey === 'building-connect'" src="./assets/svgs/bc-professional.svg" />
                    <img *ngIf="license.translationKey === 'mixit-dynamic'" src="./assets/svgs/mixit.svg" />
                  </figure>
                  <p>{{ 'upgrade-flow.' + license.translationKey + '-subscription-paragraph' | translate }}</p>
                  <ul class="benefits">
                    <li><img src="./assets/svgs/check-mark-outline.svg" /><span>{{ license.translationKey + '.benefit-1' | translate }}</span></li>
                    <li><img src="./assets/svgs/check-mark-outline.svg" /><span>{{ license.translationKey + '.benefit-2' | translate }}</span></li>
                    <li><img src="./assets/svgs/check-mark-outline.svg" /><span>{{ license.translationKey + '.benefit-3' | translate }}</span></li>
                  </ul>
                  <footer class="footer">
                    <div class="price">
                      <span>€{{ license.priceEuro }}</span>
                      <span class="price-appendix"> {{ 'license-selector.subscription-price-appendix' | translate }}</span>
                    </div>
                    <button gbc-button (click)="onSelectLicense(license)" class="button">
                      <ng-container *ngIf="(selectedLicenseType$ | async) !== license.licenseType">
                        <span>{{ 'license-selector.choose' | translate }}</span>
                      </ng-container>
                      <ng-container *ngIf="(selectedLicenseType$ | async) === license.licenseType">
                        <img src="./assets/svgs/check-mark.svg" /> <span>{{ 'license-selector.selected' | translate }}</span>
                      </ng-container>
                    </button>
                  </footer>
                </app-content-tile>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </gbc-tile>
    </cdk-step>
    <cdk-step [label]="'upgrade-flow.unit-selection' | translate">
      <gbc-tile>
        <app-content-tile [title]="'upgrade-flow.' + (selectedLicense$ | async)?.translationKey + '-title' | translate">
        <figure>
          <img *ngIf="(selectedLicense$ | async)?.translationKey === 'building-connect'" src="./assets/svgs/bc-professional.svg" />
          <img *ngIf="(selectedLicense$ | async)?.translationKey === 'mixit-dynamic'" src="./assets/svgs/mixit.svg" />
        </figure>
        <div>
          <p>{{ 'upgrade-flow.' + (selectedLicense$ | async)?.translationKey + '-installation-selection-paragraph' | translate }}</p>
        </div>
        <gbc-accordion [toggleTitle]="'license-upgrade.see-benefits' | translate" class="mt-4">
          <ul>
            <li>{{ (selectedLicense$ | async)?.translationKey + '.benefit-1' | translate }}</li>
            <li>{{ (selectedLicense$ | async)?.translationKey + '.benefit-2' | translate }}</li>
            <li>{{ (selectedLicense$ | async)?.translationKey + '.benefit-3' | translate }}</li>
          </ul>
        </gbc-accordion>
      </app-content-tile>
      </gbc-tile>
      <gbc-tile [title]="'trial-flow-page.units-upgrade-tile-title' | translate">
        <app-mixit-installation-selector [preSelectedInstallation]="preSelectedInstallation" [facilities$]="facilities$" (installationsSelect)="onSelectInstallations($event)"></app-mixit-installation-selector>
      </gbc-tile>
    </cdk-step>
    <cdk-step [label]="'upgrade-flow.customer-information' | translate" >
      <app-mixit-premium-billing-details
        [title]="'upgrade-flow.billing-details' | translate"
        [extendedInfo]="true"
        (licenseDataUpdate)="setLicenseInfo($event)"
        (formValidityUpdate)="setFormValidity($event)">
      </app-mixit-premium-billing-details>
    </cdk-step>
    <cdk-step [label]="'upgrade-flow.summary' | translate">
      <gbc-tile [title]="'upgrade-flow.summary-selected-installations' | translate">
          <gbc-table>
            <th gbc-table-header>{{'trial-flow-page.location' | translate}}</th>
            <th gbc-table-header>{{'trial-flow-page.mixit-unit' | translate}}</th>
            <th gbc-table-header>{{'trial-flow-page.serial-number' | translate}}</th>
            <th gbc-table-header>{{'trial-flow-page.installation-date' | translate}}</th>
            <tr gbc-table-row *ngFor="let installation of selectedInstallationsByFacility$ | async">
                <td gbc-table-cell>{{installation.location}}</td>
                <td gbc-table-cell><span class="unit"><app-connection-status [status]="installation.state.type" *ngIf="installation.state !== null"></app-connection-status>{{installation.name}}</span></td>
                <td gbc-table-cell>{{installation.serialNumber}}</td>
                <td gbc-table-cell>{{installation.createdTimestampEpoch | date:'dd/MM/yyyy' }}</td>
            </tr>
          </gbc-table>
      </gbc-tile>
      <gbc-tile>
        <div class="summary" *ngIf="summary$ | async as summary">
          <gbc-table>
            <tr class="header">
              <td>Selected units</td>
              <td colspan="2">Renewal</td>
              <td>Total amount</td>
            </tr>
            <tr>
              <td gbc-table-cell [ngPlural]="summary.qty">
                <ng-template ngPluralCase="=0">
                  <h2>{{ 'upgrade-flow.units-in-total' | translate: { count: summary.qty } }}</h2>
                </ng-template>
                <ng-template ngPluralCase="=1">
                  <h2>{{ 'upgrade-flow.units-in-total-singular' | translate: { count: summary.qty } }}</h2>
                </ng-template>
                <ng-template ngPluralCase="other">
                  <h2>{{ 'upgrade-flow.units-in-total' | translate: { count: summary.qty } }}</h2>
                </ng-template>
              </td>
              <td gbc-table-cell colspan="2"><h2>{{ 'license.' + summary.selectedLicense.licenseType | translate }}</h2></td>
              <td gbc-table-cell>
                <ng-container *ngIf="selectedCategory === 'subscription'"><h2>€{{ (summary.price ) }} <span class="price-appendix">{{ 'license-selector.subscription-price-appendix' | translate }}</span></h2></ng-container>
                <ng-container *ngIf="selectedCategory === 'one-time-fee'"><h2>€{{ (summary.price ) }}</h2></ng-container>
              </td>
            </tr>
            <tr>
              <td colspan="4">
                <p>{{ 'upgrade-flow.terms-and-conditions' | translate }}</p>
              </td>
            </tr>
          </gbc-table>
        </div>
      </gbc-tile>
      <app-mixit-premium-billing-details
        *ngIf="(currentStep$ | async) === 3"
        [title]="'upgrade-flow.billing-details' | translate"
        [extendedInfo]="true"
        [licenseData]="licenseInfo$ | async"
        [readOnly]="true">
      </app-mixit-premium-billing-details>
    </cdk-step>
  </gbc-stepper>
</ng-container>
