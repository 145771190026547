import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-manual-override-box',
  templateUrl: './manual-override-box.component.html',
  styleUrls: ['./manual-override-box.component.scss']
})
export class ManualOverrideBoxComponent {
  @Input()
  overridden: boolean;

  @Output() override = new EventEmitter<boolean>();

  public toggleManualOverride() {
    this.override.emit(this.overridden);
  }

}
