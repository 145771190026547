<gbc-modal (dismiss)="dismiss()" title="{{ 'scheduling-modal.pasteurization' | translate }}" class="gbc-large gbc-with-dividers" data-test-id="scheduling-modal">
  <div class="modal-content">
    <div class="content">
      <p class="description">{{ 'scheduling-modal.pasteurization-description' | translate }}</p>
      <div class="modal-banner-container">
        <gbc-banner type="warning" class="modal-banner" data-test-id="scheduling-modal-warning-banner">
          <div>{{ 'scheduling-modal.advice-occupants-warning' | translate }}</div>
        </gbc-banner>
      </div>
      <form [formGroup]="form" class="form">

        <gbc-modal-section data-test-id="temperature-container">
          <gbc-modal-section-column>
            <gbc-input-label class="label" multiline>
              <div>
                <gbc-input-label>{{ 'scheduling-modal.temperature' | translate }}</gbc-input-label>
                <img pTooltip="{{ 'scheduling-modal.temperature-tooltip' | translate }}" class="question-mark" src="./assets/svgs/question-mark.svg" escape />
              </div>
              <div class="light">{{ 'scheduling-modal.min-max-temp' | translate : { min: temperatureMin, max: temperatureMax } }}</div>
            </gbc-input-label>
          </gbc-modal-section-column>
          <gbc-modal-section-column>
            <gbc-input-field
              unit="°C"
              inputType="number"
              formControlName="temperature"
              min="{{ temperatureMin }}"
              max="{{ temperatureMax }}"
              errorDescriptionTranslationString="app-enter-value-between"
              [errorDescriptionData]="{ min: temperatureMin, max: temperatureMax }"
              data-test-id="temperature-input">
            </gbc-input-field>
          </gbc-modal-section-column>
        </gbc-modal-section>

        <gbc-modal-section data-test-id="retention-time-container">
          <gbc-modal-section-column>
            <gbc-input-label class="label" multiline>
              <div>
                <gbc-input-label>{{ 'scheduling-modal.retention-time' | translate }}</gbc-input-label>
                <img pTooltip="{{ 'scheduling-modal.retention-time-tooltip' | translate: { min: retentionTimeMin } }}" class="question-mark" src="./assets/svgs/question-mark.svg" escape />
              </div>
              <div class="light">{{ 'scheduling-modal.retention-time-description' | translate }}</div>
            </gbc-input-label>
          </gbc-modal-section-column>
          <gbc-modal-section-column>
            <gbc-input-field
              class="fullWidth"
              unit="min"
              inputType="number"
              formControlName="retentionTime"
              min="{{ retentionTimeMin }}"
              errorDescriptionTranslationString="scheduling-modal.retention-time-minimum"
              [errorDescriptionData]="{ min: retentionTimeMin }"
              [ngClass]="{'error': form.errors?.['retiontionTimeExceedTimePeriod'] }"
              data-test-id="retention-time-input">
            </gbc-input-field>
          </gbc-modal-section-column>
        </gbc-modal-section>

        <div class="modal-banner-container" *ngIf="form.errors?.['retiontionTimeExceedTimePeriod'] && (form.touched || form.dirty)" data-test-id="retention-exceed-error">
          <gbc-banner type="alarm" class="modal-banner">
            <div>{{ 'scheduling-modal.retention-time-exceed-time-period' | translate }}</div>
          </gbc-banner>
        </div>

        <gbc-modal-section data-test-id="duration-container">
          <gbc-modal-section-column>
            <gbc-input-label multiline class="label">
              <div>
                <gbc-input-label>{{ 'scheduling-modal.session-duration' | translate }}</gbc-input-label>
                <img pTooltip="{{ 'scheduling-modal.session-duration-tooltip' | translate }}" escape class="question-mark" src="./assets/svgs/question-mark.svg" />
              </div>
              <div class="light">{{ 'scheduling-modal.start-and-stop' | translate }}</div>
            </gbc-input-label>
          </gbc-modal-section-column>
          <gbc-modal-section-column>
            <div class="gbc-inline-container fullWidth">
              <gbc-input-time-field
                formControlName="startTime"
                [placeholder]="'scheduling-modal.startTime-placeholder' | translate"
                errorDescriptionTranslationString="Hello"
                data-test-id="duration-start-input">
              </gbc-input-time-field>
              <gbc-input-time-field
                formControlName="stopTime"
                [placeholder]="'scheduling-modal.stopTime-placeholder' | translate"
                data-test-id="duration-stop-input">
              </gbc-input-time-field>
            </div>
          </gbc-modal-section-column>
        </gbc-modal-section>

        <div class="modal-banner-container" *ngIf="(form.errors === null) && (showWarningBanner$ | async)" data-test-id="duration-warning">
          <gbc-banner type="warning" class="modal-banner">
            <div>{{ 'scheduling-modal.outside-periode-warning' | translate }}</div>
          </gbc-banner>
        </div>

        <div class="modal-banner-container" *ngIf="(form.controls.startTime.touched && !form.controls.startTime.valid) || (form.controls.stopTime.touched && !form.controls.stopTime.valid)" data-test-id="duration-missing-value-error">
          <gbc-banner type="alarm" class="modal-banner">
            <div>{{ 'scheduling-modal.missing-duration-error' | translate }}</div>
          </gbc-banner>
        </div>

        <gbc-modal-section data-test-id="schedule-days-container">
          <gbc-modal-section-column>
            <div class="full-width">
              <gbc-input-label>{{ 'scheduling-modal.schedule-days' | translate }}</gbc-input-label>
              <gbc-weekday-selector formControlName="days" data-test-id="schedule-days-picker"></gbc-weekday-selector>
            </div>
          </gbc-modal-section-column>
        </gbc-modal-section>

      </form>
    </div>
  </div>
  <div class="modal-footer">
    <gbc-modal-buttons data-test-id="form-buttons">
      <button gbc-button ghost (click)="close()" data-test-id="cancel-scheduling" angulartics2On="click" angularticsAction="cancelClicked" angularticsCategory="pasteurizationSchedule">
        {{ 'app-cancel' | translate }}
      </button>
      <button gbc-button [disabled]="!form.valid" (click)="apply()" data-test-id="apply-scheduling" angulartics2On="click" angularticsAction="saveClicked" angularticsCategory="pasteurizationSchedule">
        {{ 'app-apply' | translate }}
      </button>
    </gbc-modal-buttons>
  </div>
</gbc-modal>
