import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ModalController, MODAL_CONTROLLER, MODAL_DATA } from 'shared';
import { EnergyDashboardSettingsDto } from '../../interfaces/installation';
import { UserTrackingHelperService } from '../../services/user-tracking-helper.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-energy-data-export-modal',
  templateUrl: './energy-data-export-modal.component.html',
  styleUrls: ['./energy-data-export-modal.component.scss']
})
export class EnergyDataExportModalComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();
  public form: UntypedFormGroup;
  public isValid$: Observable<boolean>;
  public title: string;
  public years: { name: string, id: string}[];
  public months: { name: string, id: string}[];

  constructor(
    @Inject(MODAL_DATA) public data: any,
    @Inject(MODAL_CONTROLLER) private controller: ModalController<any>,
    private translateService: TranslateService,
    private userTrackingHelperService: UserTrackingHelperService
  ) {}

  ngOnInit(): void {

    // Populate year and month selectors.
    this.years = this.getAvailableYears();
    this.months = this.getAvailableMonths();

    this.form = new UntypedFormGroup({
      year: new UntypedFormControl(this.years[0], [Validators.required]),
      month: new UntypedFormControl(null),
    });

    this.isValid$ = this.form.valueChanges.pipe(
      startWith(this.form.valid),
      map(value => {
        return this.form.valid;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  close = () => {
    this.controller.dismiss();
  }

  export = () => {

    console.log(this.form);

    let result: any = {
      year: this.form.controls.year.value ? this.form.controls.year.value.id : null,
      month: this.form.controls.month.value ? this.form.controls.month.value.id : null,
    };

    // Remove empty (null) attributes.
    Object.keys(result).forEach((k) => result[k] === null && delete result[k]);

    this.controller.complete({ ...result });
  }

  dismiss = () => {
    this.userTrackingHelperService.trackUserAction('energyDataExportModal', 'closeClicked');
  }

  private getAvailableYears(): { name: string, id: string}[] {
    const availableYears: { name: string, id: string}[] = [];
    const max = new Date().getFullYear();
    const min = 2022;

    for( let year = max; year >= min; year--) {
      availableYears.push({ name: year.toString(), id: year.toString() });
    }

    return availableYears;
  }

  private getAvailableMonths(): { name: string, id: string}[] {
    return [
      { name: this.translateService.instant("months.january"), id: "1" },
      { name: this.translateService.instant("months.february"), id: "2" },
      { name: this.translateService.instant("months.march"), id: "3" },
      { name: this.translateService.instant("months.april"), id: "4" },
      { name: this.translateService.instant("months.may"), id: "5" },
      { name: this.translateService.instant("months.june"), id: "6" },
      { name: this.translateService.instant("months.july"), id: "7" },
      { name: this.translateService.instant("months.august"), id: "8" },
      { name: this.translateService.instant("months.september"), id: "9" },
      { name: this.translateService.instant("months.october"), id: "10" },
      { name: this.translateService.instant("months.november"), id: "11" },
      { name: this.translateService.instant("months.december"), id: "12" },
    ];
  }
}
