import { Component, Input, EventEmitter, Output, Inject } from '@angular/core';
import { versionToken } from '../../injection-tokens';
import { LanguageService } from '../../services/language.service';
import { Observable } from 'rxjs';
import { ModalService } from '../../services/modal.service';
import { LanguagePickerComponent } from '../../components/language-picker/language-picker.component';
import { SupportModalComponent } from '../support-modal/support-modal.component';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'gbc-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent {
  @Input() title: string;
  @Output() headerClick: EventEmitter<void> = new EventEmitter();

  public currentLanguage$: Observable<string>;
  
  constructor(@Inject(versionToken) public version: string, private languageService: LanguageService, private modalService: ModalService) {
    this.currentLanguage$ = this.languageService.currentLanguage.asObservable().pipe(
      filter((l) => !!l),
      map((l) => l as string)
    );
  }

  openLanguagePicker() {
    this.modalService.openDialog(LanguagePickerComponent, {});
  }

  openSupport() {
    this.modalService.openDialog(SupportModalComponent, {});
  }
}
