import { Component, OnDestroy, OnInit } from '@angular/core';
import { GroupType, SocketService } from '../../services/socket.service';
import { UserService } from '../../services/user.service';
import { catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { AppError, AppErrorService, FORBIDDEN_ERROR_CODE } from 'shared';
import { AccessClaim } from 'projects/shared/src/lib/interfaces/access';

@Component({
  selector: 'app-users-container-page',
  templateUrl: './users-container-page.component.html',
  styleUrls: ['./users-container-page.component.scss']
})
export class UsersContainerPageComponent implements OnInit {
  public pageError$: Observable<AppError>;

  constructor(
    private socketService: SocketService,
    private userService: UserService,
    private errorService: AppErrorService,
  ) { }

  ngOnInit(): void {
    // We do not unsubscribe to users, as a navigation to this page again would cause all users to be sent once more
    if (!this.socketService.subscribedToUsers) {
      this.socketService.subscribeMultiple([], [GroupType.AllUsers]);
      this.socketService.subscribedToUsers = true;
    }

    const hasUserManagementClaim$ = this.userService.hasClaim(AccessClaim.CustomerPortalUserManagement).pipe(
      map(hasClaim => {
        if (!hasClaim) {
          throw Error();
        }
        return true;
      }),
      catchError((e) => {
        return throwError(this.errorService.createError({
          ...FORBIDDEN_ERROR_CODE[403],
          report: false
        }));
      })
    );

    this.pageError$ = this.errorService.createPageErrorObservable([hasUserManagementClaim$]);
  }
}
