import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DataPointsResult, DeviceDataPoint } from '../../interfaces/data-points';
import { getDatapoint } from '../../utils/mixit-utils';
import { BoosterDataPoints } from '../../interfaces/booster';
import { SystemDeviceType } from '../../interfaces/systemDeviceType';

@Component({
  selector: 'app-booster-input-source-tile',
  templateUrl: './booster-input-source-tile.component.html',
  styleUrls: ['./booster-input-source-tile.component.scss'],
})
export class BoosterInputSourceTileComponent implements OnInit {
  @Input() dataPoints$: Observable<DataPointsResult>;

  public outletPressure$: Observable<DeviceDataPoint | null>;
  public inletPressure$: Observable<DeviceDataPoint | null>;

  ngOnInit(): void {
    this.outletPressure$ = getDatapoint(this.dataPoints$, SystemDeviceType.BoosterSystem, BoosterDataPoints.SystemOutDiffPress);
    this.inletPressure$ = getDatapoint(this.dataPoints$, SystemDeviceType.BoosterSystem, BoosterDataPoints.SystemInDiffPress);
    // this.flow$ = getDatapoint(this.dataPoints$, SystemDeviceType.BoosterSystem, BoosterDataPoints.Flow);
    // this.specificEnergy$ = getDatapoint(this.dataPoints$, SystemDeviceType.BoosterSystem, BoosterDataPoints.SystemSpecEnergy);
  }
}
