import React from 'react';
import { Labels } from '../../Labels';
import { Direction } from '../../Common';
import { FlowMeter } from '../../FlowMeter';
import { Pipe, PipeType } from '../../Pipe';
import { SupplyTriangle } from '../../SupplyTriangle';
import { BaseSystemProps, System } from '../../System';
import { coldWaterSupplyMeta, getColdWaterSupplyLabels, PIPE_Y, WIDTH } from './cold-water-supply-metadata';
import { IColdWaterSupplySystemFormInterface } from '../../../../models/systems/coldWaterSupplySystem/coldWaterSupplySystemFormInterface';

export const ColdWaterSupply: React.FC<BaseSystemProps<IColdWaterSupplySystemFormInterface>> = React.memo(
  ({ state, values, alarm, selected }) => {
    const systemInfo = state.systemInfo as IColdWaterSupplySystemFormInterface;
    const X = systemInfo.LeftToRight ? 0 : WIDTH;
    const direction = systemInfo.LeftToRight ? Direction.EAST : Direction.WEST;

    const coldWaterFlow = () => {
      if (systemInfo.Q_SC_present) {
        return <FlowMeter x={WIDTH / 2} y={PIPE_Y} />;
      }
    };

    return (
      <System state={state} meta={coldWaterSupplyMeta} alarm={alarm} selected={selected}>
        <Pipe key="PIPE" x1={0} y1={PIPE_Y} x2={WIDTH} y2={PIPE_Y} type={PipeType.ColdSupply} />
        <SupplyTriangle warm={false} x={X} y={PIPE_Y} direction={direction} />
        {coldWaterFlow()}
        <Labels positions={getColdWaterSupplyLabels(state.systemInfo)} values={values} />
      </System>
    );
  }
);
