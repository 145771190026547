import { CONNECTION_SPACING } from '../../Common';
import { LabelPosition, SystemMeta } from '../../types';
import { EConnectionType, SystemType } from '../../../state';
import { EConnectionPointPlacement, EEquipment } from '../../../types';
import { LargeLabelDeltas, SmallLabelDeltas } from '../../../util/constants';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/hot-water-tank.svg';
import { getHorizontalHeatMeterLabels, getPrimaryTempLabels } from '../../heat-meter-or-sensors/heat-meter-or-sensors-metadata';
import hotWaterTankSystemForm from '../../../../models/systems/hotWaterTankStandAloneSystem/hotWaterTankStandAloneSystemForm.json';
import { IHotWaterTankStandAloneSystemFormInterface } from '../../../../models/systems/hotWaterTankStandAloneSystem/hotWaterTankStandAloneSystemFormInterface';

export const HEIGHT = 360;
export const WIDTH = 430;
export const FORWARD_Y = 160;
export const TANK_LEFT = 209;
export const TANK_TOP = 94;
export const TANK_TOP_TEMP_Y = TANK_TOP + 10;
export const TANK_WIDTH = 105;
export const TANK_HEIGHT = 172;
export const VALVE_X = 175;
export const HEAT_METER_X = 90;
export const SECONDARY_LOOP_TOP = 60;
export const SECONDARY_LOOP_RIGHT = 400;
export const PUMP_X = 378;
export const PUMP_Y = 101;
export const COLD_SUPPLY_BOTTOM = 300;
export const COLD_SUPPLY_RIGHT = 380;
export const COLD_SUPPLY_FLOW_X = 350;
export const COLD_SUPPLY_LEFT = TANK_LEFT + Math.ceil(TANK_WIDTH / 2);
export const TANK_BOTTOM = TANK_TOP + TANK_HEIGHT;
export const TANK_BOTTOM_TEMP_Y = TANK_BOTTOM - 6;
export const TANK_MIDDLE = (TANK_TOP + TANK_BOTTOM) / 2;
export const RETURN_Y = FORWARD_Y + CONNECTION_SPACING;
export const TANK_RIGHT = TANK_LEFT + TANK_WIDTH;
export const T_SR_X = TANK_RIGHT + 10;
export const T_SR_Y = TANK_TOP + 50;

export const hotWaterTankStandaloneMeta: SystemMeta = {
  systemType: SystemType.HotWaterTankStandAloneSystem,
  name: 'Hot Water Tank Standalone',
  thumbnail,
  form: hotWaterTankSystemForm,
  width: WIDTH,
  height: HEIGHT,
  calculateConnectionPoints: (systemInfo: IHotWaterTankStandAloneSystemFormInterface) => [
    { key: EConnectionPointPlacement.connectionPrimaryForward, x: 0, y: FORWARD_Y, type: EConnectionType.supply, dotted: false },
    { key: EConnectionPointPlacement.connectionPrimaryReturn, x: 0, y: RETURN_Y, type: EConnectionType.return, dotted: true },
    { key: EConnectionPointPlacement.connectionSecondaryForward, x: SECONDARY_LOOP_RIGHT, y: SECONDARY_LOOP_TOP, type: EConnectionType.supply, dotted: false },
    { key: EConnectionPointPlacement.connectionSecondaryReturn, x: SECONDARY_LOOP_RIGHT, y: FORWARD_Y, type: EConnectionType.supply, dotted: true },
    ...(systemInfo.Has_External_Cold_Water_Supply
      ? [
          { key: EConnectionPointPlacement.connectionBottomForward, x: COLD_SUPPLY_LEFT, y: COLD_SUPPLY_BOTTOM, type: EConnectionType.return, dotted: false }
        ]
      : []),
  ],
};

export const getHotWaterTankStandaloneLabels = (systemInfo: IHotWaterTankStandAloneSystemFormInterface): LabelPosition[] => {
  const coldWaterSupplyFlowLabel: LabelPosition[] = systemInfo.Q_SC_present
    ? [
        { id: EEquipment.Q_SC, x: COLD_SUPPLY_FLOW_X, y: COLD_SUPPLY_BOTTOM + SmallLabelDeltas.WEST_Y, horizontalAlignment: 'middle', },
      ]
    : [];
  const tankTopLabel: LabelPosition = { id: EEquipment.T_T, x: TANK_LEFT - 50, y: TANK_TOP_TEMP_Y - 20 };
  const tankMiddleLabel: LabelPosition[] = systemInfo.T_MIDDLE_present
    ? [
        { id: EEquipment.T_MIDDLE, x: TANK_RIGHT + LargeLabelDeltas.SOUTH_X, y: TANK_MIDDLE },
      ]
    : [];
  const tankBottomLabel: LabelPosition[] = systemInfo.T_BOTTOM_present
    ? [
        { id: EEquipment.T_BOTTOM, x: TANK_RIGHT + LargeLabelDeltas.SOUTH_X, y: TANK_BOTTOM_TEMP_Y },
      ]
    : [];
  const tsrLabel: LabelPosition[] = systemInfo.T_SR_present
    ? [
        { id: EEquipment.T_SR, x: T_SR_X + SmallLabelDeltas.TEMP_SENSOR_HORIZONTAL_DX, y: FORWARD_Y + SmallLabelDeltas.EAST_Y },
      ]
    : [];
  const valveLabel: LabelPosition[] =
    systemInfo.MV_valveLocation === 'PrimaryForward'
      ? [
          { id: EEquipment.MV_2W_PF, x: VALVE_X, y: FORWARD_Y + SmallLabelDeltas.EAST_Y, horizontalAlignment: 'middle' },
        ]
      : [
          { id: EEquipment.MV_2W_PR, x: VALVE_X, y: RETURN_Y + SmallLabelDeltas.WEST_Y, horizontalAlignment: 'middle' },
        ];

  return [
    tankTopLabel,
    ...tankMiddleLabel,
    ...tankBottomLabel,
    ...getHorizontalHeatMeterLabels(HEAT_METER_X, FORWARD_Y, systemInfo),
    ...getPrimaryTempLabels(HEAT_METER_X, FORWARD_Y, systemInfo),
    ...coldWaterSupplyFlowLabel,
    ...tsrLabel,
    ...valveLabel,
  ];
};
