import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Facility, Installation } from '../../interfaces/facilty';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { withLatestFrom, tap, first } from 'rxjs/operators';
import { ConnectionState } from '../../interfaces/connectionState';

@Component({
  selector: 'app-mixit-installation-selector',
  templateUrl: './mixit-installation-selector.component.html',
  styleUrls: ['./mixit-installation-selector.component.scss']
})
export class MixitInstallationSelectorComponent implements OnInit, OnDestroy {

  @Input() facilities$: Observable<Facility[]>;
  @Input() preSelectedInstallation: string;

  @Output() installationsSelect = new EventEmitter<Facility[]>();

  public selectedInstallations = new UntypedFormGroup({});

  public ConnectionState = ConnectionState;

  private subscription = new Subscription();

  ngOnInit(): void {
    const sub = this.facilities$.pipe(
      first(),
      tap((facilities) => {
        facilities.forEach((f) => {
          f.installations.forEach((installation) => {
            const disabled = installation.connectionState !== ConnectionState.Connected;
            const preselected = this.preSelectedInstallation === installation.id;
            if (this.preSelectedInstallation === installation.id) {
              const filteredInstallations = f.installations.filter(i => i.id === this.preSelectedInstallation);
              const mutatedFacility = {
                ...f,
                installations: filteredInstallations,
              };
              this.installationsSelect.emit([mutatedFacility]);
            }
            if (this.selectedInstallations.contains(installation.id)) {
              this.selectedInstallations.setControl(installation.id, new UntypedFormControl({ value: preselected, disabled }));
            } else {
              this.selectedInstallations.addControl(installation.id, new UntypedFormControl({ value: preselected, disabled }));
            }
          });
        });
      })
    ).subscribe();
    this.subscription.add(sub);

    const secondSub = this.selectedInstallations.valueChanges.pipe(
      withLatestFrom(this.facilities$),
    ).subscribe(([form, facilities]) => {
      const filteredFacilities = facilities
        .map(f => {
          const installations = f.installations.filter(i => form[i.id]);
          return {
            ...f,
            installations
          };
        })
        .filter(f => f.installations.length);
      this.installationsSelect.emit(filteredFacilities);

    });
    this.subscription.add(secondSub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
