<gbc-tile [title]="'weather-curve-tile.title' | translate">
  <div
    *ngIf="editingEnabled$ | async"
    gbc-tile-right
    class="adjust-curve"
    [ngClass]="{ editing: editing }"
    (click)="adjustCurveClicked()"
    role="button"
  >
    <button gbc-icon-button gbc-tile-right data-test-id="adjust-curve">
      <img src="./assets/svgs/adjust-outline-black.svg" />
    </button>
  </div>
  <div class="container">
    <!-- Highcharts does not support automatic resizing, so we just render a new one when toggling editing -->
    <app-weather-curve-chart *ngIf="!editing" [outdoorTemp$]="outdoorTemp$" [data$]="data$"> </app-weather-curve-chart>
    <app-weather-curve-chart class="editing" *ngIf="editing" [outdoorTemp$]="outdoorTemp$" [data$]="data$"> </app-weather-curve-chart>
    <app-weather-curve-form
      *ngIf="editing"
      [initialCurve]="curve$ | async"
      (currentCurve)="updateCurve($event)"
      (cancel)="cancelEditing()"
      (submitWeatherCurve)="formSubmitted($event)"
    >
    </app-weather-curve-form>
  </div>
</gbc-tile>
