import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AppError, AppErrorService, AuthService } from 'shared';
import { UserType } from '../../interfaces/user';
import { Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { first, flatMap, map, shareReplay, filter, tap } from 'rxjs/operators';
import { FacilityService } from '../../services/facility.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss']
})
export class WelcomePageComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  public form = new UntypedFormGroup({
    claimCode: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(12),
      Validators.maxLength(12),
      Validators.pattern(/^[A-Z2-7]+$/)
    ])
  });
  public pageError$: Observable<AppError>;
  public isLoggedIn$: Observable<boolean>;

  public submitMixitClaimCode() {
    const claimCode = this.form.value.claimCode;
    this.router.navigate(['/mixit/onboarding'], {queryParams: {claimCode}});
  }

  constructor(
    public userService: UserService,
    public authService: AuthService,
    private errorService: AppErrorService,
    private router: Router,
    private facilityService: FacilityService
  ) {}

  ngOnInit(): void {

    this.isLoggedIn$ = this.authService.currentUser.pipe(
      map(u => !!(u))
    );

    const hasFacilities$ = this.authService.authenticated.pipe(
      flatMap(isAuthenticated => {
        if (isAuthenticated) {
          return this.userService.currentUser$.pipe(
            first(),
            flatMap(currentUser => {
              if (currentUser?.type === UserType.BuildingConnect) {
                return this.facilityService.facilities$.pipe(
                  filter(f => !!f),
                  map(facilities => facilities && facilities.length > 0)
                );
              } else {
                return of(false);
              }
            })
          );
        } else {
          return of(false);
        }
      }),
      shareReplay());

    this.pageError$ = this.errorService.createPageErrorObservable([hasFacilities$]);
    const sub = hasFacilities$.subscribe(hasFacilities => {
      if (hasFacilities) {
        this.router.navigate(['/facilities']);
      }
    });

    this.subscription.add(sub);
  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
