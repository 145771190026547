import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, forkJoin, Observable, of, Subscription } from 'rxjs';
import { first, flatMap, map, shareReplay } from 'rxjs/operators';
import { DeviceDataPoint, SystemDevice } from '../../interfaces/data-points';
import { Application, PumpControlMode, PumpData, OperatingMode } from '../../interfaces/alarm';
import { formatDataPoint, getDataOutdatedState } from '../../utils/data-point-utils';
import { TranslateService } from '@ngx-translate/core';
import { Value } from '../multi-value-tile/multi-value-tile.component';
import { ModalService } from 'shared';
import { EBackendUnit } from '../../interfaces/data-points';
import { defaultPumpSettings, PumpModalComponent, PumpSettings } from 'projects/customerportal/src/app/components/pump-modal/pump-modal.component';
import { InstallationConfigurationService, PumpOption, UpdateSystemModeAndPumpModeDTO } from 'projects/customerportal/src/app/services/installation-configuration.service';
import { Installation } from '../../interfaces/facilty';
import { sortBy } from 'lodash';
import { UserTrackingHelperService } from '../../services/user-tracking-helper.service';
import { DataPointsService } from '../../services/data-points.service';
import { SystemControlMode } from '../../interfaces/system-control-mode';

const extractDataPoint = (device: SystemDevice, name: string): DeviceDataPoint | undefined => {
  return device.dataPoints.find((p) => p.humanReadableId?.indexOf(name) !== -1);
};

const extractLastUpdated = (device: SystemDevice): number => {
  const timeStamps = sortBy(device.dataPoints, (d) => d.timestampEpoch);
  return timeStamps[timeStamps.length - 1]?.timestampEpoch;
};

const createpumpData = (device: SystemDevice): PumpData => {
  const controlMode = extractDataPoint(device, 'ControlMode_');
  const operatingMode = extractDataPoint(device, 'ActualMode_');
  const power = extractDataPoint(device, 'Power_');
  const differentialPressure = extractDataPoint(device, 'H_');
  const impellerSpeed = extractDataPoint(device, 'ImpellerSpeed_');
  const estimatedFlow = extractDataPoint(device, 'Q_');
  const setPoint = extractDataPoint(device, 'ActualControlReference_');
  return {
    controlMode,
    operatingMode,
    setPoint,
    power,
    differentialPressure,
    impellerSpeed,
    estimatedFlow,
  };
};

const controlModeTranslationKeys = new Map<PumpControlMode, string>([
  [PumpControlMode.ConstantPressure, 'mode-constant-pressure'],
  [PumpControlMode.ProportionalPressure, 'mode-proportional-pressure'],
  [PumpControlMode.ConstantFrequency, 'mode-constant-frequency'],
  [PumpControlMode.ConstantFlow, 'mode-constant-flow'],
  [PumpControlMode.ConstantLevel, 'mode-constant-level'],
  [PumpControlMode.ConstantDifferentialPressure, 'mode-constant-differential-pressure'],
  [PumpControlMode.FlowAdapt, 'mode-flow-adapt'],
  [PumpControlMode.AutoAdapt, 'mode-auto-adapt'],
  [PumpControlMode.ConstantTemperature, 'mode-constant-temperature'],
  [PumpControlMode.ConstantDifferentialTemperature, 'mode-constant-diff-temperature'],
  [PumpControlMode.Other, 'mode-other'],
]);

const operatingModeTranslationKeys = new Map<OperatingMode, string>([
  [OperatingMode.Other, 'mode-other'],
  [OperatingMode.Stop, 'opmode-stop'],
  [OperatingMode.Normal, 'opmode-normal'],
  [OperatingMode.Min, 'opmode-min'],
  [OperatingMode.Max, 'opmode-max'],
  [OperatingMode.Hand, 'opmode-hand'],
]);

const formatPumpData = (translateService: TranslateService, pumpData: PumpData): Observable<Value[]> => {
  const controlMode = pumpData.controlMode;
  const operatingMode = pumpData.operatingMode;
  const setPointHasValue = pumpData.setPoint?.value;

  const controlMode$: Observable<string> =
    controlMode && controlMode.value
      ? translateService.get(`pump-status-tile.${controlModeTranslationKeys.get(controlMode.value as PumpControlMode)}`)
      : of('-');
  const operatingMode$: Observable<string> =
    operatingMode && operatingMode.value
      ? translateService.get(`pump-status-tile.${operatingModeTranslationKeys.get(operatingMode.value as OperatingMode)}`)
      : of('-');

  return forkJoin([controlMode$, operatingMode$]).pipe(
    map(([controlModeString, operatingModeString]) => [
      { titleKey: 'pump-status-tile.control-mode', value: controlModeString, state: controlMode ? controlMode.state : undefined },
      { titleKey: 'pump-status-tile.operating-mode', value: operatingModeString, state: operatingMode ? operatingMode.state : undefined },
      {
        titleKey: 'pump-status-tile.setpoint',
        value: formatDataPoint(pumpData.setPoint),
        state: setPointHasValue ? pumpData.setPoint?.state : undefined,
      },
      {
        titleKey: 'pump-status-tile.power',
        value: formatDataPoint(pumpData.power),
        state: getDataOutdatedState(pumpData.power)
      },
      {
        titleKey: 'pump-status-tile.head',
        value: formatDataPoint(pumpData.differentialPressure),
        state: getDataOutdatedState(pumpData.differentialPressure)
      },
      {
        titleKey: 'pump-status-tile.estimated-flow',
        value: formatDataPoint(pumpData.estimatedFlow),
        state: getDataOutdatedState(pumpData.estimatedFlow)
      },
      {
        titleKey: 'pump-status-tile.impeller-speed',
        value: formatDataPoint(pumpData.impellerSpeed),
        state: getDataOutdatedState(pumpData.impellerSpeed)
      },
    ])
  );
};

@Component({
  selector: 'app-pump-status-tile',
  templateUrl: './pump-status-tile.component.html',
  styleUrls: ['./pump-status-tile.component.scss'],
})
export class PumpStatusTileComponent implements OnInit, OnDestroy {
  @Input() public application: Application;
  @Input() public pumpType$: Observable<string>;
  @Input() public pumpDevice$: Observable<SystemDevice>;
  @Input() public pumpOptions: PumpOption[] | null;
  @Input() public installation$: Observable<Installation>;
  @Input() public title: string;

  public pumpTypeName$: Observable<string>;
  public formattedPumpData$: Observable<Value[]>;
  public lastUpdated$: Observable<number>;

  public systemControlMode: SystemControlMode;
  public SystemControlModeOptions = SystemControlMode;
  private systemControlModeSubscription: Subscription;

  constructor(
    private translateService: TranslateService,
    private modalService: ModalService,
    private installationConfigurationService: InstallationConfigurationService,
    private userTrackingHelperService: UserTrackingHelperService,
    private dataPointsService: DataPointsService
  ) {}

  ngOnInit(): void {
    this.lastUpdated$ = this.pumpDevice$.pipe(map(extractLastUpdated));

    this.formattedPumpData$ = this.pumpDevice$.pipe(
      map(createpumpData),
      flatMap((pumpData) => formatPumpData(this.translateService, pumpData)),
      shareReplay(1)
    );

    this.pumpTypeName$ = this.pumpType$.pipe(
      flatMap((pumpType) => {
        return this.translateService.get(`pump-name.${pumpType}`);
      })
    );

    // Subscribe to 'System Control Mode updated.
    this.systemControlModeSubscription = this.dataPointsService.getSystemControlMode(this.application.id).subscribe((controlMode) => {
      this.systemControlMode = controlMode;
    });
  }

  ngOnDestroy(): void {
    this.systemControlModeSubscription.unsubscribe();
  }

  adjustPump() {
    const pumpOptions = this.pumpOptions;
    if (!this.hasPumpOptions(pumpOptions)) {
      return;
    }

    this.userTrackingHelperService.trackUserAction('application', 'adjustPumpStatusShown');

    this.pumpDevice$.pipe(first()).subscribe((device) => {
      const controlModeDataPoint = extractDataPoint(device, 'ControlMode_');
      const setpoint = extractDataPoint(device, 'ActualControlReference_');
      const operatingMode = extractDataPoint(device, 'ActualMode_');

      const data: PumpSettings = {
        ...defaultPumpSettings,
        pumpOptions,
        stopped: operatingMode?.value === OperatingMode.Stop,
        title: 'Pump',
        controlMode: controlModeDataPoint?.value,
        setpoint: setpoint?.value,
        systemId: this.application.id,
        systemControlMode: this.systemControlMode,
      };
      this.modalService
        .openDialog<PumpSettings>(PumpModalComponent, {
          data,
        })
        .subscribe((response) => {
          if (response.dismissed) {
            return;
          }
          const { systemControlMode, controlMode, setpoint, unitType, operationMode } = response.result;

          const data: UpdateSystemModeAndPumpModeDTO = {
            systemControlMode: systemControlMode,
            pumpControlMode: {
              controlMode: controlMode as string,
              value: setpoint as string,
              unitType: unitType as EBackendUnit,
            },
            pumpId: device.deviceId,
            operationMode,
          };

          this.setSystemModeAndPumpMode(data);
        });
    });
  }

  private setSystemModeAndPumpMode(data: UpdateSystemModeAndPumpModeDTO) {
    combineLatest([this.installation$, this.pumpDevice$])
      .pipe(first())
      .subscribe(([installation, pumpDevice]) => {
        this.installationConfigurationService.setSystemModeAndPumpMode(installation.id, this.application.id, data);
        this.userTrackingHelperService.trackSetPumpControlMode('pumpSetToAutoMode', pumpDevice.deviceId);
      });
  }

  hasPumpOptions(pumpOptions: PumpOption[] | null): pumpOptions is PumpOption[] {
    return pumpOptions !== null;
  }
}
