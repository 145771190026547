import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppError, AppErrorService } from 'shared';
import { Observable, of } from 'rxjs';
import { Application } from '../../interfaces/alarm';
import { AppModel } from '@ams/dumbledore';
import { ActivatedRoute } from '@angular/router';
import { catchError, filter, first, map, take, tap } from 'rxjs/operators';
import { EFacilityType } from '../../../../../serviceportal/src/app/interfaces/facility';
import { FacilityService } from '../../services/facility.service';
import { Facility, Installation } from '../../interfaces/facilty';
import { SchematicsService } from '../../services/schematics.service';
import { GroupType, SocketService } from '../../services/socket.service';
import { AlarmService } from '../../services/alarm.service';


@Component({
  selector: 'app-installation-page',
  templateUrl: './installation-page.component.html',
  styleUrls: ['./installation-page.component.scss']
})
export class InstallationPageComponent implements OnDestroy, OnInit {
  public pageError$: Observable<AppError>;
  public schematic$: Observable<AppModel>;
  public applications$: Observable<Application[]>;
  public facility$: Observable<Facility>;
  public installation$: Observable<Installation>;

  public isMixit$: Observable<boolean>;
  public isBuildingConnect$: Observable<boolean>;
  public isConnectingCore$: Observable<boolean>;

  constructor(
    public route: ActivatedRoute,
    private errorService: AppErrorService,
    private schematicService: SchematicsService,
    private alarmService: AlarmService,
    private facilityService: FacilityService,
    private socketService: SocketService,
  ) { }

  ngOnInit(): void {
    this.facility$ = this.facilityService.getCurrentFacility(this.route);
    this.installation$ = this.facilityService.getCurrentInstallation(this.route).pipe(
      catchError(e => {
        // The installation might not be there at all, so we handle that here
        return of(null);
      }),
      // Stop the stream, if no installation
      filter(i => !!i),
      map(i => i as Installation)
    );

    this.schematicService.setup(this.installation$);

    this.schematic$ = this.schematicService.schematic$ as Observable<AppModel>;
    this.applications$ = this.schematicService.applications$;

    this.pageError$ = this.errorService.createPageErrorObservable([
      // We call the functions directly here as they return an error that we need for page error handler
      this.facilityService.getCurrentFacility(this.route),
      this.facilityService.getCurrentInstallation(this.route),

      this.applications$.pipe(first()),
      this.schematic$.pipe(first())
    ]);

    this.isMixit$ = this.facility$.pipe(
      map(f => {
        return f.facilityType === EFacilityType.MIXIT;
      })
    );

    this.isBuildingConnect$ = this.facility$.pipe(
      map(f => {
        return f.facilityType === EFacilityType.BuildingConnect;
      })
    );

    this.isConnectingCore$ = this.facility$.pipe(
      map(f => {
        return f.facilityType === EFacilityType.ConnectingCore;
      })
    );

    const installations$ = this.installation$.pipe(
      map(i => [i])
    );

    this.alarmService.setup(this.facility$, installations$, this.schematicService.applications$);

    this.installation$.pipe(
      take(1),
    ).subscribe(installation => {
      this.socketService.subscribeMultiple([installation.id], [GroupType.Schematic, GroupType.Installation]);
    });
  }

  ngOnDestroy(): void {
    this.schematicService.teardown();

    this.installation$.pipe(
      first(),
    ).subscribe(installation => {
      this.socketService.unsubscribe([installation.id], GroupType.Installation);
      this.socketService.unsubscribe([installation.id], GroupType.Schematic);
    });
  }
}
