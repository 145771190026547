import { SystemMeta } from './types';
import { mixitMetadata } from './systems/mixit/mixit-metadata';
import { hotTemperatureMeta } from './systems/HotTemperatureSystem';
import { boilerSystemMeta } from './systems/boiler/boiler-metadata';
import { corePumpMeta } from './systems/core-pump/core-pump-metadata';
import { coldTemperatureMeta } from './systems/ColdTemperatureSystem';
import { boosterSystemMeta } from './systems/booster/booster-metadata';
import { bufferTankMeta } from './systems/buffer-tank/buffer-tank-metadata';
import { mixingLoopMeta } from './systems/mixing-loop/mixing-loop-metadata';
import { cascadeTankMeta } from './systems/cascade-tank/cascade-tank-metadata';
import { hotWaterTankMeta } from './systems/hot-water-tank/hot-water-tank-metadata';
import { hotWaterPumpMeta } from './systems/hot-water-pump/hot-water-pump-metadata';
import { heatExchangerMeta } from './systems/heat-exchanger/heat-exchanger-metadata';
import { coldWaterPumpMeta } from './systems/cold-water-pump/cold-water-pump-metadata';
import { pumpStandaloneMeta } from './systems/pump-standalone/pump-standalone-metadata';
import { pressureSensorMetadata } from './systems/pressure-sensor/pressure-sensor-metadata';
import { coldWaterSupplyMeta } from './systems/cold-water-supply/cold-water-supply-metadata';
import { unspecifiedLoadMetadata } from './systems/unspecified-load/unspecified-load-metadata';
import { simpleBufferTankMeta } from './systems/simple-buffer-tank/simple-buffer-tank-metadata';
import { districtHeatingSupplyMeta } from './systems/district-heating/district-heating-metadata';
import { outdoorTemperatureMeta } from './systems/outdoor-temperature/outdoor-temperature-metadata';
import { commercialHotWaterMeta } from './systems/commercial-hot-water/commercial-hot-water-metadata';
import { heatmeterStandaloneMeta } from './systems/heatmeter-standalone/heat-meter-standalone-metadata';
import { hotWaterTankStandaloneMeta } from './systems/hot-water-tank-standalone/hot-water-tank-standalone-metadata';
import { mixingLoopUnspecifiedLoadMeta } from './systems/mixing-loop-unspecified-load/mixing-loop-unspecified-load-metadata';
import { cascadeBufferTankChargingMeta } from './systems/cascade-buffer-tank-charging/cascade-buffer-tank-charging-metadata';
import { commercialHotWaterStandaloneMeta } from './systems/commercial-hot-water-standalone/commercial-hot-water-standalone-metadata';
import { indirectDistrictHeatingSupplyMeta } from './systems/indirect-district-heating-supply/indirect-district-heating-supply-metadata';
import { twoStrokeHeatExchangerHotWaterSystemMeta } from './systems/two-stroke-heat-exchanger-hot-water/two-stroke-heat-exchanger-hot-water-metadata';

export const systemMetadataMap: Record<string, SystemMeta> = [
  districtHeatingSupplyMeta,
  mixingLoopMeta,
  mixingLoopUnspecifiedLoadMeta,
  commercialHotWaterMeta,
  commercialHotWaterStandaloneMeta,
  heatExchangerMeta,
  boilerSystemMeta,
  outdoorTemperatureMeta,
  hotWaterPumpMeta,
  hotWaterTankMeta,
  coldWaterPumpMeta,
  mixitMetadata,
  twoStrokeHeatExchangerHotWaterSystemMeta,
  unspecifiedLoadMetadata,
  corePumpMeta,
  hotTemperatureMeta,
  coldTemperatureMeta,
  bufferTankMeta,
  pumpStandaloneMeta,
  heatmeterStandaloneMeta,
  simpleBufferTankMeta,
  hotWaterTankStandaloneMeta,
  coldWaterSupplyMeta,
  boosterSystemMeta,
  pressureSensorMetadata,
  cascadeTankMeta,
  indirectDistrictHeatingSupplyMeta,
  cascadeBufferTankChargingMeta,
].reduce((acc: { [k: string]: SystemMeta }, meta) => {
  acc[meta.systemType] = meta;
  return acc;
}, {});
