export  const formValues = {
  max_length: {
    address: 100,
    default: 100,
    email: 100,
    name: 100,
    phone: 100,
    postalCode: 20,
  }
}
