import { Component, OnInit } from '@angular/core';
import { CommandService } from '../../services/command.service';

@Component({
  selector: 'gbc-command-spinner',
  templateUrl: './command-spinner.component.html',
  styleUrls: ['./command-spinner.component.scss']
})
export class CommandSpinnerComponent {
  constructor(public commandService: CommandService) {
  }
}
