import { Component, Input, QueryList, ContentChildren, OnInit } from '@angular/core';
import { PageInfo } from '../../interfaces/pageInfo';
import { MenuItemComponent } from '../menu-item/menu-item.component';

// Component for displaying the page header. Accepts a PageInfo input, which is created by he PageInfoService.
@Component({
  selector: 'gbc-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent {
  @Input() public pageInfo: PageInfo;
  @Input() public backRoute?: string;

  @ContentChildren(MenuItemComponent) public menuItems: QueryList<MenuItemComponent>;
}
