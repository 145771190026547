import { Pipe, PipeType } from '../../Pipe';
import React, { FunctionComponent } from 'react';
import * as metaData from './indirect-district-heating-supply-metadata';

type SecondPump = {
  outtakeBeforeHeatExchanger?: boolean;
};

export const ConnectionPipesComponent: FunctionComponent<SecondPump> = ({ outtakeBeforeHeatExchanger }) => {
  return (
    <>
      {/* The two horizontal pipes */}
      <Pipe
        key="supplyPipe"
        x1={metaData.SUPPLY_X - 1}
        y1={metaData.SUPPLY_Y}
        x2={metaData.indirectDistrictHeatingSupplyMeta.width}
        y2={metaData.SUPPLY_Y}
        type={PipeType.WarmSupply}
      />
      <Pipe
        key="returnPipe"
        x1={metaData.SUPPLY_X - 1}
        y1={metaData.RETURN_Y}
        x2={metaData.indirectDistrictHeatingSupplyMeta.width}
        y2={metaData.RETURN_Y}
        type={PipeType.ColdReturn}
      />

      {/* The two vertical pipes before the heat exchanger */}
      {outtakeBeforeHeatExchanger && (
        <>
          <Pipe
            key="supplyPipeBeforeHeatEx"
            x1={metaData.CONNECTION_POINT_PRIMARY_FORWARD_X}
            y1={metaData.SUPPLY_Y}
            x2={metaData.CONNECTION_POINT_PRIMARY_FORWARD_X}
            y2={metaData.CONNECTION_POINT_FORWARD_Y}
            type={PipeType.WarmSupply}
          />
          <Pipe
            key="returnPipeBeforeHeatEx"
            x1={metaData.CONNECTION_POINT_PRIMARY_RETURN_X}
            y1={metaData.RETURN_Y}
            x2={metaData.CONNECTION_POINT_PRIMARY_RETURN_X}
            y2={metaData.CONNECTION_POINT_FORWARD_Y}
            type={PipeType.ColdReturn}
          />
        </>
      )}
    </>
  );
};
