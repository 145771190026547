<app-mixit-x509-banner/>
<app-alarm-bar *ngIf="alarm$ | async" [alarm]="alarm$"> </app-alarm-bar>

<app-schematics-tile
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [showShadow]="false"
  [dataPoints$]="dataPoints$"
>
</app-schematics-tile>

<ng-container *ngIf="installation$ | async as installation">
  <app-building-connect-weather-curve-tile
    *ngIf="weatherCompensationEnabled$ | async"
    [installation]="installation"
    [application]="application"
    [outdoorTemp$]="outdoorTemp$"
  >
  </app-building-connect-weather-curve-tile>
</ng-container>

<app-warm-weather-shutdown-tile
  *ngIf="isWarmWeatherShutdownAvailable$ | async"
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [dataPoints$]="dataPoints$"
></app-warm-weather-shutdown-tile>

<app-pump-status-tile
  *ngIf="pumpDevice$ | async"
  [installation$]="installation$"
  [application]="application"
  [pumpType$]="pumpType$"
  [pumpDevice$]="pumpDevice$"
  [pumpOptions]="pumpOptions$ | async"
>
</app-pump-status-tile>

<app-valve-status-tile
  *ngIf="valveDevice$ | async"
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [dataPoints$]="dataPoints$"
  [valveDevice$]="valveDevice$"
  [energyValve]="hasEnergyValve$ | async"
>
</app-valve-status-tile>

<app-heat-meter-status-tile
  [installation$]="installation$"
  [application]="application"
  [dataPoints$]="dataPoints$"
  [schematic$]="schematic$"
>
</app-heat-meter-status-tile>

<app-forward-return-chart-tile
  [title]="'mixing-loop-dashboard.primary-temp-chart-title' | translate"
  [applicationId]="application.id"
  forwardDumbledoreId="T_PF"
  returnDumbledoreId="T_PR"
  [dataPoints$]="dataPoints$"
  [userTrackingPeriodOptions]="{ category: 'application/mixingLoop', intervalPrefix: 'primaryTemperature' }"
>
</app-forward-return-chart-tile>

<app-forward-return-chart-tile
  [title]="'mixing-loop-dashboard.secondary-temp-chart-title' | translate"
  [applicationId]="application.id"
  forwardDumbledoreId="T_SF"
  returnDumbledoreId="T_SR"
  [dataPoints$]="dataPoints$"
  [userTrackingPeriodOptions]="{ category: 'application/mixingLoop', intervalPrefix: 'secondaryTemperature' }"
>
</app-forward-return-chart-tile>

<app-valve-chart
  [dataPoints$]="dataPoints$"
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [hasEnergyValve]="hasEnergyValve$ | async"
  [userTrackingPeriodOptions]="{ category: 'application/mixingLoop', intervalPrefix: 'valvePosition' }"
>
</app-valve-chart>

<app-energy-valve-heat-and-flow-chart
  *ngIf="hasEnergyValve$ | async"
  [dataPoints$]="dataPoints$"
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
>
</app-energy-valve-heat-and-flow-chart>

<app-energy-valve-temperature-chart
  *ngIf="hasEnergyValve$ | async"
  [dataPoints$]="dataPoints$"
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
>
</app-energy-valve-temperature-chart>
