import { TabState } from './state';
import { createAction } from '@reduxjs/toolkit';
import { IBuildingFormInterface } from '../models/building/buildingFormInterface';

export interface SetTabState {
  tabState: TabState;
}

export interface SetBuildingInfo {
  buildingInfo: IBuildingFormInterface;
}

export const setTabState = createAction<SetTabState>('editor/set-tab-state');
export const setBuildingInfo = createAction<SetBuildingInfo>('editor/set-building-info');
export const enterFullscreen = createAction<void>('editor/enter-fullscreen');
export const leaveFullscreen = createAction<void>('editor/leave-fullscreen');
export const showConfirmDeleteSchematic = createAction<void>('editor/show-confirm-delete');
export const cancelDeleteSchematic = createAction<void>('editor/cancel-delete-schematic');
export const deleteSchematic = createAction<void>('editor/delete-schematic');
export const cancelRemoveSystem = createAction<void>('editor/cancel-remove-system');
export const showHelp = createAction<void>('editor/show-help');
export const hideHelp = createAction<void>('editor/hide-help');
export const showDevTools = createAction<void>('editor/show-dev-tools');
