import React, { ReactElement } from 'react';
import styled from 'styled-components';

export interface ModalProps {
  header: ReactElement;
  body: ReactElement;
  actions: ReactElement;
  onBackdropClick: () => any;
}

const modalWidth = '800px';
const StyledModal = styled.div`
.backdrop {
  position: fixed;
  top: 0;
  bottom: auto;
  right: 0;
  left: 0;
  background: rgba(13, 51, 84, 0.4);
  height: 100%;
  z-index: 2;
}

.dumbledore-modal {
  overflow: auto;
  max-height: 80vh;
  color: #313131;
  width: ${modalWidth};
  position: fixed;
  z-index: 4;
  background-color: white;
  top: 100px;
  left: calc(50% - ${modalWidth} / 2);

  .header {
    font-size: 20px;
    padding: 1em 2em;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 1px solid #d9dddf;
  }

  .body {
    // overflow: scroll;
    padding: 2em;
  }

  .actions {
    display: flex;

    button {
      cursor: pointer !important;
      text-transform: uppercase !important;
      flex: 1 !important;
      text-align: center !important;
      font-size: 20px !important;
      border: none !important;
      border-top: 1px solid #d9dddf !important;
      padding: 1em 2em !important;
      font-weight: 600 !important;
      font-family: 'GrundfosTheSansV2', sans-serif !important;

      &:nth-child(1) {
        border-right: 1px solid #d9dddf !important;
      }

    }
  }
`;

export const Modal: React.FC<ModalProps> = ({ onBackdropClick, header, body, actions }) => {
  return (
    <StyledModal>
      <div className="backdrop" onClick={onBackdropClick} />

      <div className="dumbledore-modal">
        <div className="header">{header}</div>

        <div className="body">{body}</div>

        <div className="actions">{actions}</div>
      </div>
    </StyledModal>
  );
};
