<div class="wrapper" cdkOverlayOrigin #trigger="cdkOverlayOrigin" #wrapper>
  <div class="container" [ngClass]="{ invalid: !valid && ngControl.control?.touched, disabled: ngControl.control?.disabled }">
    <div class="icon-container left">
      <gbc-icon iconName="clock_outline" class="clock-icon_outline"></gbc-icon>
    </div>
    <input
      autocomplete="off"
      #timeInputField
      class="input"
      [disabled]="ngControl.control?.disabled"
      [min]="min"
      [max]="max"
      [maxlength]="maxLength"
      [type]="inputType"
      [step]="step"
      [placeholder]="placeholder ? placeholder : ''"
      [(ngModel)]="value"
      [attr.name]="formControlName"
      (keyup)="onKey(timeInputField.value)"
      (blur)="onBlur()"
      (focus)="timeInputField.select()"
      mask="Hh:m0"
      [leadZeroDateTime]="true" />

    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="showMenu" (detach)="toggleMenu(false)" (overlayOutsideClick)="toggleMenu(false)">
      <ul class="menu" [ngClass]="{ 'hide': !showMenu }" [ngStyle]="{ 'width': menuWidth }">
        <li *ngFor="let option of timeMenuOptions" (click)="useMenuOption(option.value)">{{ option.text }}</li>
      </ul>
    </ng-template>

    <div class="icon-container right">
      <gbc-icon *ngIf="!showMenu" iconName="arrow-indicator-down_outline" class="selector-icon" (click)="toggleMenu()"></gbc-icon>
      <gbc-icon *ngIf="showMenu" iconName="arrow-indicator-up_outline" class="selector-icon" (click)="toggleMenu()"></gbc-icon>
    </div>
  </div>
  <!-- We show it as a description, rather than an error, when the user has not touched the input -->
  <!--
  <div class="label" [ngClass]="{ error: ngControl.control?.touched }" *ngIf="!valid">
    {{ errorDescriptionTranslationString | translate: errorDescriptionData }}
  </div>
  -->
</div>
