import { systemSelect } from '../actions';
import { InputEventType, InputStrategy } from './input-strategy-service';

export const viewerInputStrategy: InputStrategy = {
  name: 'Viewer',
  handleEvent: (dispatch, state, event) => {
    switch (event.type) {
      case InputEventType.SystemClick: {
        dispatch(systemSelect({ systemId: event.systemId }));
        return;
      }
    }
  },
  render: () => [],
};
