<ng-container *ngIf="facility$ | async as facility">
  <ng-container *ngIf="installation$ | async as installation">
    <gbc-table [clickableRows]="clickable" [ngStyle]="{'min-height': minHeight}" *ngIf="visible | async; else noalarms">

      <th gbc-table-header>{{'alarm-table.header-type' | translate}}</th>
      <th gbc-table-header>{{'alarm-table.header-application' | translate}}</th>
      <th gbc-table-header>{{'alarm-table.header-date' | translate}}</th>
      <th gbc-table-header>{{'alarm-table.header-override' | translate}}</th>

      <tr gbc-table-row *ngFor="let alarm of alarms$ | async; trackBy: trackByAlarm" (rowClicked)="alarmClick.emit(alarm)">
        <td gbc-table-cell><img class="alarm-icon" [src]="alarm.type | alarmIconLink"> {{alarm.metadata | formatAlarmMetadata }}:
          {{alarm.metadata.title}}</td>
        <td gbc-table-cell>{{alarm.application ? (alarm.application.title | async) : ''}}</td>
        <td gbc-table-cell>{{alarm.currentActivation.activatedTimestampEpoch | timeWithAgo}}</td>
        <td gbc-table-cell>
          {{manuallyOverriden(alarm) ? ('alarm-table.override-yes' | translate) : ('alarm-table.override-no' | translate)}}
        </td>
      </tr>
    </gbc-table>
    <ng-template #noalarms>
      <div class="no-alarms-container" [ngStyle]="{'min-height': minHeight}">
        <div>{{noAlarmsMessage}}
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
