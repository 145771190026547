import { Component, OnInit } from '@angular/core';
import { FeatureToggleService } from '../../../../../shared/src/lib/services/feature-toggle.service';
import { combineLatest, Observable } from 'rxjs';
import { SchematicsService } from '../../services/schematics.service';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-mixit-x509-banner',
  templateUrl: './mixit-x509-banner.component.html',
  styleUrls: ['./mixit-x509-banner.component.scss'],
})
export class MixitX509BannerComponent implements OnInit {
  mixitX509Warning$: Observable<boolean>;
  showMixitX509WarningBanner$: Observable<boolean>;
  message: string;

  constructor(
    private featureToggleService: FeatureToggleService,
    private schematicsService: SchematicsService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.mixitX509Warning$ = this.featureToggleService.getFeatureFlag('Mixit-X509Warning');
    this.message = this.createLink();

    this.showMixitX509WarningBanner$ = combineLatest([this.schematicsService.schematicAndMeta$, this.mixitX509Warning$]).pipe(
      map(([schematicAndMeta, mixitX509Warning]) => {
        return !!schematicAndMeta.installationMetaInfo.showMixitX509Warning && mixitX509Warning;
      })
    );
  }

  private createLink(): string {
    const link: string = `<a href="${this.translateService.instant('mixit-x509-warning.link.href')}">${this.translateService.instant('mixit-x509-warning.link.text')}</a>`;
    const date: string = `<span class="mixit-x509-warning">${this.translateService.instant('mixit-x509-warning.date')}</span>`
    return this.translateService.instant('mixit-x509-warning.message', { date, link });
  }
}
