<gbc-page-error-handler [content]='content' [pageError$]='pageError$'>
</gbc-page-error-handler>
<ng-template #content>
  <gbc-page-container data-test-id='welcome-page'>
    <div class='container'>
      <div class='top'>
        <div class='left'>
          <h2>{{'welcome-page.welcome-header' | translate}}</h2>
        </div>
      </div>
      <div class='bottom'>
        <div class='left'>
          <p>{{'welcome-page.welcome-text' | translate}}</p>
          <div *ngIf='(isLoggedIn$ | async) === false'>
            <p>{{'welcome-page.sign-in-text' | translate}}</p>
            <button class='sign-in-button'
                    gbc-button
                    (click)="authService.signinRedirect('/')">
              {{'app-sign-in' | translate}}
            </button>
            <p>{{'welcome-page.dont-have-account' | translate}}<br>
              <a (click)="authService.signinRedirect('/', true)"
                 href='#'>{{'welcome-page.create-one-link' | translate}}</a>
            </p>
          </div>
        </div>

        <div class='right' *ngIf='isLoggedIn$ | async'>
          <p>{{'welcome-page.get-started' | translate}}</p>
          <gbc-tile>
            <form [formGroup]='form'>
              <gbc-input-container>
                <gbc-input-label>{{'mixit-onboarding.device-identification-number' | translate}}</gbc-input-label>
                <gbc-input-field 
                  formControlName='claimCode' maxlength='12'
                  required
                  errorDescriptionTranslationString='mixit-onboarding.invalid-claim-code'
                  (input)="form.controls.claimCode.setValue(form.controls.claimCode.value.toUpperCase())"
                ></gbc-input-field>
              </gbc-input-container>
              <button gbc-button 
                      class='mixit-button'
                      data-test-id='claim-device'
                      [disabled]='!form.valid'
                      (click)='submitMixitClaimCode()'>{{'welcome-page.claim-device' | translate}}</button>
            </form>
          </gbc-tile>
        </div>
      </div>
    </div>
  </gbc-page-container>
</ng-template>

