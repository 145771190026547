import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { EventTypes, ScheduledEvent, PasteurizationScheduledEvent, WeekDays } from '../../interfaces/scheduling';
import { ModalService } from 'shared';
import { SchedulingModalComponent } from '../scheduling-modal/scheduling-modal.component';
import { Installation } from '../../interfaces/facilty';
import { finalize, first, map, switchMap } from 'rxjs/operators';
import { InstallationService } from '../../services/installation.service';
import { TranslateService } from '@ngx-translate/core';
import { SchematicsService } from '../../services/schematics.service';
import { UserTrackingHelperService } from '../../services/user-tracking-helper.service';
import { IMenuOption } from 'projects/shared/src/lib/interfaces/menuOption';
import { without } from 'lodash';

@Component({
  selector: 'app-scheduling-tile',
  templateUrl: './scheduling-tile.component.html',
  styleUrls: ['./scheduling-tile.component.scss'],
})
export class SchedulingTileComponent implements OnInit {
  constructor(
    private modalService: ModalService,
    private installationService: InstallationService,
    private schematicsService: SchematicsService,
    private translateService: TranslateService,
    private userTrackingHelperService: UserTrackingHelperService
  ) {}

  public hasItems: Observable<boolean>;
  public EventTypes = EventTypes;
  public scheduledEvents$: Observable<ScheduledEvent[]>;
  public currentlyEditingScheduledEvents: string[] = [];
  public schedulingMenuOptions: IMenuOption[] = [];

  @Input() installation$: Observable<Installation>;

  ngOnInit(): void {
    // Initialize scheduling menu options.
    this.schedulingMenuOptions = [
      {
        title: 'app-edit',
        value: 'edit',
        onClick: this.openSchedulingModal,
        userTrackingCategory: 'plantroom',
        userTrackingAction: 'editSchedule',
        testId: 'gbc-scheduling-action-edit',
      },
      {
        title: 'app-delete',
        value: 'delete',
        onClick: this.delete,
        userTrackingCategory: 'plantroom',
        userTrackingAction: 'deleteSchedule',
        testId: 'gbc-scheduling-action-delete',
      },
    ];

    this.scheduledEvents$ = this.schematicsService.schematicAndMeta$.pipe(map((schematic) => schematic.scheduledEvents));

    this.hasItems = this.scheduledEvents$.pipe(map((events) => events?.length > 0));
  }

  openSchedulingModal = (model?: ScheduledEvent) => {
    // Init and open relevant dialog depending on the type of scheduled event. For now we only support Pasteurization.
    if (model && model.eventType !== EventTypes.Pasteurization) {
      return;
    }

    this.modalService.openDialog<PasteurizationScheduledEvent>(SchedulingModalComponent, { data: model }).subscribe((res) => {
      if (res.dismissed) {
        return;
      }

      const scheduledEvent = res.result as PasteurizationScheduledEvent;

      if (scheduledEvent.id) {
        this.currentlyEditingScheduledEvents.push(scheduledEvent.id);

        this.installation$
          .pipe(
            switchMap((i) => {
              return this.installationService.updatePasteurizationScheduledEvent(i.id, scheduledEvent);
            }),
            first(),
            finalize(() => without(this.currentlyEditingScheduledEvents, scheduledEvent.id as string))
          )
          .subscribe();
      } else {
        this.installation$
          .pipe(
            first(),
            switchMap((i) => this.installationService.createPasteurizationScheduledEvent(i.id, scheduledEvent))
          )
          .subscribe();
      }
    });
  };

  delete = (model?: ScheduledEvent) => {
    if (model === undefined) {
      return;
    }

    this.modalService.showTextModal({
      title: this.translateService.instant('scheduling-tile.confirm-delete'),
      content: this.translateService.instant('scheduling-tile.confirm-delete-description'),
      actions: [
        {
          text: this.translateService.instant('app-cancel'),
          cancel: true,
          handler: () => {
            this.userTrackingHelperService.trackUserAction('pasteurizationSchedule', 'deleteCancelClicked');
          },
        },
        {
          text: this.translateService.instant('app-delete'),
          type: 'danger',
          handler: () => {
            this.currentlyEditingScheduledEvents.push(model.id as string);
            this.installation$
              .pipe(
                first(),
                switchMap((i) => this.installationService.deleteScheduledEvent(i.id, model))
              )
              .subscribe({
                complete: () => {
                  without(this.currentlyEditingScheduledEvents, model.id as string);
                },
              });
            this.userTrackingHelperService.trackUserAction('pasteurizationSchedule', 'deleteConfirmClicked');
          },
        },
      ],
    });
  };

  getPeriodText(event: ScheduledEvent): string {
    const weekdays = event.weekDays.sort((a, b) => Object.values(WeekDays).indexOf(a) - Object.values(WeekDays).indexOf(b)).join(', ');
    return this.translateService.instant('scheduling-tile.weekly-schedule', { weekdays });
  }
}
