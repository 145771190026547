import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { DataPointsResult, DeviceDataPoint } from '../../../interfaces/data-points';
import { first, map, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Application, PumpControlMode } from '../../../interfaces/alarm';
import { controlMode, getDatapoint, pumpType, showPumpInfo } from '../../../utils/mixit-utils';
import { MixitDataPointName, UnitType } from '../../../interfaces/mixit';
import { Installation } from '../../../interfaces/facilty';
import { AppModel } from '@ams/dumbledore';
import { MixitEquipmentMetaInfo, PumpOption } from '../../../services/installation-configuration.service';
import { defaultPumpSettings, PumpModalComponent, PumpSettings } from '../../pump-modal/pump-modal.component';
import { SchematicsService } from '../../../services/schematics.service';
import { ModalService } from 'shared';
import { MixitService } from '../../../services/mixit-service.service';
import { SystemDeviceType } from '../../../interfaces/systemDeviceType';

@Component({
  selector: 'app-pump-tile',
  templateUrl: './pump-tile.component.html',
  styleUrls: ['./pump-tile.component.scss']
})
export class PumpTileComponent implements OnInit {
  @Input() installation$: Observable<Installation>;
  @Input() schematic$: Observable<AppModel>;
  @Input() dataPoints$: Observable<DataPointsResult>;
  @Input() application$: Observable<Application>;

  public pumpType$: Observable<string>;
  private pumpMetaInfo$: Observable<MixitEquipmentMetaInfo | null>;
  public controlMode$: Observable<DeviceDataPoint | null>;
  public setpointHead$: Observable<DeviceDataPoint | null>;
  public setpointFlow$: Observable<DeviceDataPoint | null>;
  public setpointSpeed$: Observable<DeviceDataPoint | null>;
  public energy$: Observable<DeviceDataPoint | null>;
  public pumpHeadDutyPoint$: Observable<DeviceDataPoint | null>;
  public pumpFlowDutyPoint$: Observable<DeviceDataPoint | null>;
  public formattedControlMode$: Observable<string>;

  public PumpControlModes = PumpControlMode;

  private isAdjustmentsInProgress: boolean = false;

  constructor(
    private translateService: TranslateService,
    private schematicService: SchematicsService,
    private modalService: ModalService,
    private mixitService: MixitService) {}

  ngOnInit(): void {
    this.pumpMetaInfo$ = this.application$.pipe(
      map((application) => application.id),
      switchMap((id) => this.schematicService.getEquipmentMetaInfo(id)),
      map((metas) => {
        // @ts-ignore
        return metas.find((m) => m.term === 'MixitPumpControlOptions');
      }),
      map((meta) => {
        return meta as MixitEquipmentMetaInfo;
      })
    );

    this.pumpType$ = pumpType(this.pumpMetaInfo$);
    this.controlMode$ = controlMode(this.translateService, this.dataPoints$);
    this.setpointHead$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.PumpHeadReference
    );
    this.setpointFlow$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.PumpFlowReference
    );
    this.setpointSpeed$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.PumpSpeedReference
    );
    this.pumpFlowDutyPoint$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.PumpFlowDutyPoint
    );
    this.pumpHeadDutyPoint$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.PumpHeadDutyPoint
    );
    this.energy$ = of(null);

    this.formattedControlMode$ = this.controlMode$.pipe(
      switchMap((mode) => {
        return mode && mode.value
          ? this.translateService.get(`mixit-control-mode.${mode.value}`)
          : of('-');
      })
    );
  }

  public adjustPump() {
    if (this.isAdjustmentsInProgress) { return; }
    this.isAdjustmentsInProgress = true;

    combineLatest([
      this.installation$,
      this.application$,
      this.pumpMetaInfo$,
      this.controlMode$,
      this.setpointHead$,
      this.setpointFlow$,
      this.setpointSpeed$,
      this.pumpHeadDutyPoint$,
      this.pumpFlowDutyPoint$
    ])
      .pipe(first())
      .subscribe(
        ([
          installation,
          application,
          pumpMetaInfo,
          controlModeDataPoint,
          setPointHead,
          setPointFlow,
          setpointSpeed,
          pumpHeadDutyPoint,
          pumpFlowDutyPoint
        ]) => {
          // TODO: Fix this.
          const isPumpStopped = false;

          let setPoint: string | null | undefined;

          switch (
            (controlModeDataPoint as DeviceDataPoint).value as PumpControlMode
          ) {
            case PumpControlMode.ConstantPressure:
              setPoint = (setPointHead as DeviceDataPoint).value;
              break;
            case PumpControlMode.ConstantFlow:
              setPoint = (setPointFlow as DeviceDataPoint).value;
              break;
            case PumpControlMode.ProportionalPressure:
              setPoint = (pumpHeadDutyPoint as DeviceDataPoint).value;
              break;
            case PumpControlMode.AutoAdapt:
            case PumpControlMode.ConstantFrequency:
              setPoint = (setpointSpeed as DeviceDataPoint).value;
              break;
          }

          // TODO: Replace title, with the pump model
          const data: PumpSettings = {
            ...defaultPumpSettings,
            stopped: isPumpStopped,
            mixit: true,
            pumpOptions: (pumpMetaInfo as MixitEquipmentMetaInfo)?.pumpControlOptions as PumpOption[],
            title: 'Pump',
            controlMode: (controlModeDataPoint as DeviceDataPoint).value,
            // The flow setpoint is only used for proportionalPressure, so it's always this value
            flowSetpoint: (pumpFlowDutyPoint as DeviceDataPoint).value,
            setpoint: setPoint,
            systemId: (application as Application).id
          };

          this.modalService
            .openDialog<PumpSettings>(PumpModalComponent, {
              data
            })
            .subscribe((response) => {
              if (response.dismissed) {
                this.isAdjustmentsInProgress = false;
                return;
              }

              const res = response.result;

              let head = null;
              let speed = null;
              let flow = null;

              switch (res.controlMode) {
                case PumpControlMode.ConstantPressure:
                  head = res.setpoint;
                  break;
                case PumpControlMode.ConstantFlow:
                  flow = res.setpoint;
                  break;
                case PumpControlMode.ProportionalPressure:
                  head = res.setpoint;
                  flow = res.flowSetpoint;
                  break;
                case PumpControlMode.AutoAdapt:
                  break;
                case PumpControlMode.ConstantFrequency:
                  speed = res.setpoint;
                  break;
              }

              this.mixitService.setPumpMode(
                installation as Installation,
                (application as Application).id,
                {
                  controlMode: res.controlMode as string,
                  headUnit: UnitType.MeterHead,
                  head: parseFloat(head as string),
                  speedUnit: UnitType.Percentage,
                  speed: parseFloat(speed as string),
                  flowUnit: UnitType.CubicMeterPerHour,
                  flow: parseFloat(flow as string)
                }
              );

              this.isAdjustmentsInProgress = false;
            });
        }
      );
  }

  public showPumpInfo() {
    showPumpInfo({
      translateService: this.translateService,
      modalService: this.modalService,
      schematic$: this.schematic$
    });
  }
}
