<gbc-modal (dismiss)='dismiss()' [title]='title' class="gbc-large gbc-with-dividers" data-test-id='mixit-device-modal'>
  <gbc-modal-content>
    <p class='text'>{{ 'mixit-device-modal.description' | translate }}</p>
    <form [formGroup]='form'>
      <ng-container *ngIf='!data.hideKvs'>
        <gbc-modal-section>
          <gbc-modal-section-column>
            <gbc-input-label>{{ 'mixit-device-modal.kvs' | translate }}</gbc-input-label>
            <img class='question-mark' src='./assets/svgs/question-mark.svg' pTooltip="{{ 'mixit-device-modal.kvs-tooltip' | translate }}" tooltipPosition="right" escape />
            <gbc-toggle formControlName='kvsEnabled' class="align-right"></gbc-toggle>
          </gbc-modal-section-column>
          <gbc-modal-section-column>
            <gbc-input-field
              [errorDescriptionData]='kvsErrorData'
              [max]='data.kvsMax'
              [min]='data.kvsMin'
              [showErrorTranslationString]='true'
              errorDescriptionTranslationString='app-enter-value-between'
              formControlName='kvs'
              inputType='number'
              unit='m3/h'>
            </gbc-input-field>
          </gbc-modal-section-column>
        </gbc-modal-section>
      </ng-container>

      <gbc-modal-section>
        <gbc-modal-section-column>
          <gbc-input-label>
            {{ 'mixit-device-modal.manual-valve-opening' | translate }} (%)
          </gbc-input-label>
          <img class='question-mark' src='./assets/svgs/question-mark.svg' pTooltip="{{ 'mixit-device-modal.manual-opening-tooltip' | translate }}" tooltipPosition="right" escape />
          <gbc-toggle formControlName='manualOpeningEnabled' class="align-right" data-test-id='manual-opening-toggle'></gbc-toggle>
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-field
            [errorDescriptionData]='valveErrorData'
            [max]='data.valveMax'
            [min]='data.valveMin'
            [showErrorTranslationString]='true'
            errorDescriptionTranslationString='app-enter-value-between'
            formControlName='manualOpening'
            inputType='number'
            unit='%'>
          </gbc-input-field>
        </gbc-modal-section-column>
      </gbc-modal-section>

      <!--  Disabled for now   -->
      <gbc-modal-section *ngIf='false'>
        <gbc-modal-section-column>
          <gbc-input-label class='disabled-text'>
            {{ 'mixit-device-modal.eco-scheduling' | translate }}
          </gbc-input-label>
          <img class='question-mark' src='./assets/svgs/question-mark.svg' pTooltip="{{ 'mixit-device-modal.eco-scheduling-tooltip' | translate }}" tooltipPosition="right" escape />
        </gbc-modal-section-column>
        <gbc-modal-section-column>
          <gbc-input-label class='ecoscheduling disabled-text'>
            <ng-container *ngIf='ecoScheduling$ | async'>
              {{ 'mixit-device-modal.eco-scheduling-active' | translate }}
            </ng-container>
            <ng-container *ngIf='(ecoScheduling$ | async) === false'>
              {{ 'mixit-device-modal.eco-scheduling-inactive' | translate }}
            </ng-container>
          </gbc-input-label>
        </gbc-modal-section-column>
      </gbc-modal-section>
    </form>
  </gbc-modal-content>

  <gbc-modal-buttons>
    <button gbc-modal-button (click)='close()' angulartics2On='click' angularticsAction='cancelClicked' angularticsCategory='adjustMixitConfigurationsShown'>
      {{ 'app-cancel' | translate }}
    </button>
    <button gbc-modal-button (click)='save()' [disabled]='(valid$ | async) === false' class='primary' angulartics2On='click' angularticsAction='saveClicked' angularticsCategory='adjustMixitConfigurationsShown'>
      {{ 'app-save' | translate }}
    </button>
  </gbc-modal-buttons>
</gbc-modal>
