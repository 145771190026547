import { CONNECTION_SPACING } from '../../Common';
import { LabelPosition, SystemMeta } from '../../types';
import { EConnectionType, SystemType } from '../../../state';
import { EConnectionPointPlacement, EEquipment } from '../../../types';
import { LargeLabelDeltas, SmallLabelDeltas } from '../../../util/constants';
import { getPrimaryTempLabels } from '../../heat-meter-or-sensors/heat-meter-or-sensors-metadata';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/buffer-tank-system.svg';
import bufferTankSystemForm from '../../../../models/systems/bufferTankSystem/bufferTankSystemForm.json';
import { IBufferTankSystemFormInterface } from '../../../../models/systems/bufferTankSystem/bufferTankSystemFormInterface';

export const HEIGHT = 280;
export const WIDTH = 310;
export const CENTER = 165;
export const FORWARD_Y = 120;
export const TANK_LEFT = 154;
export const TANK_TOP = 54;
export const TANK_TOP_TEMP_Y = TANK_TOP + 10;
export const TANK_WIDTH = 105;
export const TANK_HEIGHT = 172;
export const HEAT_METER_X = 90;
export const TANK_BOTTOM = TANK_TOP + TANK_HEIGHT;
export const TANK_BOTTOM_TEMP_Y = TANK_BOTTOM - 21;
export const RETURN_Y = FORWARD_Y + CONNECTION_SPACING;
export const TANK_RIGHT = TANK_LEFT + TANK_WIDTH;
export const PUMP_X = 110;
export const TANK_CENTER_TOP_TEMP_Y = TANK_TOP_TEMP_Y + 40;
export const TANK_CENTER_BOTTOM_TEMP_Y = TANK_BOTTOM_TEMP_Y - 40;
export const FORWARD_TEMP_X = 30;
export const RETURN_TEMP_X = 270;

export const bufferTankMeta: SystemMeta = {
  systemType: SystemType.BufferTank,
  name: 'Buffer Tank System',
  thumbnail,
  form: bufferTankSystemForm,
  width: WIDTH,
  height: HEIGHT,
  calculateConnectionPoints: () => [
    {
      key: EConnectionPointPlacement.connectionPrimaryForward,
      x: 0,
      y: FORWARD_Y,
      type: EConnectionType.supply,
      dotted: false,
    },
    {
      key: EConnectionPointPlacement.connectionPrimaryReturn,
      x: 0,
      y: RETURN_Y,
      type: EConnectionType.return,
      dotted: true,
    },
    {
      key: EConnectionPointPlacement.connectionSecondaryForward,
      x: WIDTH,
      y: FORWARD_Y,
      type: EConnectionType.supply,
      dotted: false,
    },
    {
      key: EConnectionPointPlacement.connectionSecondaryReturn,
      x: WIDTH,
      y: RETURN_Y,
      type: EConnectionType.return,
      dotted: true,
    },
  ],
};

export const getBufferTankLabels = (systemInfo: IBufferTankSystemFormInterface): LabelPosition[] => {
  const secondaryForwardLabel: LabelPosition[] = systemInfo.T_SF_present
    ? [
        {
          id: EEquipment.T_SF,
          x: RETURN_TEMP_X + SmallLabelDeltas.TEMP_SENSOR_HORIZONTAL_DX,
          y: FORWARD_Y - SmallLabelDeltas.WEST_Y,
        },
      ]
    : [];

  const secondaryReturnLabel: LabelPosition[] = systemInfo.T_SR_present
    ? [
        {
          id: EEquipment.T_SR,
          x: RETURN_TEMP_X + SmallLabelDeltas.TEMP_SENSOR_HORIZONTAL_DX,
          y: RETURN_Y + SmallLabelDeltas.SOUTH_X,
        },
      ]
    : [];

  const TANK_LABEL_X = TANK_LEFT - LargeLabelDeltas.SOUTH_X;

  const tankBottomLabel: LabelPosition = {
    id: EEquipment.T_BOTTOM,
    x: TANK_LABEL_X,
    y: TANK_BOTTOM_TEMP_Y,
    horizontalAlignment: 'end',
  };

  const tankTopLabel: LabelPosition = {
    id: EEquipment.T_T,
    x: TANK_LABEL_X,
    y: TANK_TOP_TEMP_Y,
    horizontalAlignment: 'end',
  };

  const tankCenterTop: LabelPosition[] = systemInfo.T_CENTERTOP_present
    ? [
        {
          id: EEquipment.T_CENTERTOP,
          x: TANK_LABEL_X,
          y: TANK_CENTER_TOP_TEMP_Y,
          horizontalAlignment: 'end',
        },
      ]
    : [];

  const tankCenterBottom: LabelPosition[] = systemInfo.T_CENTERTOP_present
    ? [
        {
          id: EEquipment.T_CENTERBOTTOM,
          x: TANK_LABEL_X,
          y: TANK_CENTER_BOTTOM_TEMP_Y,
          horizontalAlignment: 'end',
        },
      ]
    : [];

  return [
    tankBottomLabel,
    tankTopLabel,
    ...tankCenterTop,
    ...tankCenterBottom,

    ...getPrimaryTempLabels(FORWARD_TEMP_X, FORWARD_Y, systemInfo),
    ...secondaryForwardLabel,
    ...secondaryReturnLabel,
  ];
};
