import { Component, Input, OnInit } from '@angular/core';
import { AlarmOverview, Application } from '../../../interfaces/alarm';
import { Observable } from 'rxjs';
import { AppModel, EEquipment, SystemState } from '@ams/dumbledore';
import { DataPointsResult } from '../../../interfaces/data-points';
import { Installation } from '../../../interfaces/facilty';
import {filter, map, switchMap} from 'rxjs/operators';
import { IUnspecifiedLoadSystemFormInterface } from '../../../../../../dumbledore/src/models/systems/unspecifiedLoadSystem/unspecifiedLoadSystemFormInterface';
import {SchematicsService} from "../../../services/schematics.service";
import {MixitDataPointName} from "../../../interfaces/mixit";

@Component({
  selector: 'app-unspecified-load-dashboard',
  templateUrl: './unspecified-load-dashboard.component.html',
  styleUrls: ['./unspecified-load-dashboard.component.scss'],
})
export class UnspecifiedLoadDashboardComponent implements OnInit {
  @Input() public alarm$: Observable<AlarmOverview>;
  @Input() public application: Application;
  @Input() public installation$: Observable<Installation>;
  @Input() public schematic$: Observable<AppModel>;
  @Input() public dataPoints$: Observable<DataPointsResult>;

  public hasWeatherCurve$: Observable<boolean>;
  public outdoorTemp$: Observable<number | null>;
  public EEquipment = EEquipment;
  public isWarmWeatherShutdownAvailable$: Observable<boolean | undefined>;

  constructor(private schematicService: SchematicsService) {}

  ngOnInit(): void {
    const application$ = this.schematic$.pipe(
      map((schematic) => {
        return schematic.systems.find((s) => s.hasId === this.application.id);
      })
    );

    this.hasWeatherCurve$ = application$.pipe(
      map((a) => (a?.systemInfo as IUnspecifiedLoadSystemFormInterface).Weather_compensation_enabled || false)
    );

    this.outdoorTemp$ = this.dataPoints$.pipe(
      filter((d) => !!d?.data),
      map((dataPoints) => {
        const allDataPoints = dataPoints.data.flatMap((s) => s.devices).flatMap((d) => d.dataPoints);
        const outdoorTempDataPoint = allDataPoints.find((dataPoint) => dataPoint.dumbledoreId === 'T_OUTDOOR');
        return outdoorTempDataPoint?.value ? parseInt(outdoorTempDataPoint.value, 10) : null;
      })
    );

    this.isWarmWeatherShutdownAvailable$ = this.schematicService.getWarmWeatherShutdownAvailableState(this.application.id).pipe(
      map((warmWeatherShutdownAvailable) => {
        return warmWeatherShutdownAvailable;
      })
    );

  }
}
