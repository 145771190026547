import { Component, Input, OnInit } from '@angular/core';
import {
  DataPointsService,
  ReportPeriod,
  Resolution,
  TimeSerie,
  TimeSeriesDetails
} from '../../../services/data-points.service';
import { combineLatest, Observable } from 'rxjs';
import { Options, SeriesOptionsType } from 'highcharts';
import { PeriodOption } from '../../../interfaces/chart';
import moment from 'moment';
import { DataPointsResult } from '../../../interfaces/data-points';
import { Application } from '../../../interfaces/alarm';
import { TranslateService } from '@ngx-translate/core';
import { first, flatMap, map, shareReplay } from 'rxjs/operators';
import { getDatapoint } from '../../../utils/mixit-utils';
import { MixitDataPointName } from '../../../interfaces/mixit';
import { convertCubicMeterPerSecondToCubicMeterPerHour, convertPascalToMeter } from '../../../utils/data-point-utils';
import { UserTrackingPeriodOptions } from '../../../services/user-tracking-helper.service';
import { SystemDeviceType } from '../../../interfaces/systemDeviceType';

@Component({
  selector: 'app-mixit-pump-chart',
  templateUrl: './mixit-pump-chart.component.html',
  styleUrls: ['./mixit-pump-chart.component.scss'],
})
export class MixitPumpChartComponent implements OnInit {
  @Input() public dataPoints$: Observable<DataPointsResult>;
  @Input() public application: Application;
  @Input() userTrackingPeriodOptions: UserTrackingPeriodOptions;

  public getSeries: (period: ReportPeriod) => Observable<SeriesOptionsType[]>;
  public options: Options;
  public dataPointsForChart$: Observable<any>;
  public periodOptions: PeriodOption[] = [
    {
      isDefault: true,
      name: this.translateService.get('chart-tile.4-hours'),
      period: {
        duration: moment.duration(4, 'hour').asMilliseconds(),
        resolution: Resolution.Minutes,
      },
    },
    {
      name: this.translateService.get('chart-tile.48-hours'),
      period: {
        duration: moment.duration(48, 'hour').asMilliseconds(),
        resolution: Resolution.Minutes,
      },
    },
    {
      name: this.translateService.get('chart-tile.week'),
      period: {
        duration: moment.duration(7, 'days').asMilliseconds(),
        resolution: Resolution.Hours,
      },
    },
    {
      name: this.translateService.get('chart-tile.month'),
      period: {
        duration: moment.duration(30, 'days').asMilliseconds(),
        resolution: Resolution.Hours,
      },
    },
    {
      name: this.translateService.get('chart-tile.year'),
      period: {
        duration: moment.duration(1, 'year').asMilliseconds(),
        resolution: Resolution.Days,
      },
    },
  ];

  constructor(private translateService: TranslateService, private dataPointsService: DataPointsService) {
    this.options = {
      colors: ['#33A3DC', '#D6D357'],
      yAxis: [
        {
          title: {
            text: null,
          },
          labels: {
            format: '{value} m3/h',
          },
          min: 0,
        },
        {
          title: {
            text: null,
          },
          labels: {
            format: '{value} m',
          },
          min: 0,
          opposite: true,
        },
      ],
    };
  }

  ngOnInit(): void {
    const flowLegend$ = this.translateService.get('mixit-pump-chart.flow-legend');
    const pressureLegend$ = this.translateService.get('mixit-pump-chart.pressure-legend');

    const flowDataPoint$ = getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.PumpFlowAverage).pipe(first());
    const pressureDataPoint$ = getDatapoint(this.dataPoints$, SystemDeviceType.MixitSystem, MixitDataPointName.PumpHead).pipe(first());

    this.dataPointsForChart$ = combineLatest([flowDataPoint$, pressureDataPoint$]);

    const installationId$ = this.dataPoints$.pipe(
      first(),
      map((d) => d.installationId)
    );

    this.getSeries = (period) =>
      combineLatest([flowDataPoint$, pressureDataPoint$, pressureLegend$, flowLegend$, installationId$]).pipe(
        flatMap(([flowDataPoint, pressureDataPoint, pressureLegend, flowLegend, installationId]) => {
          const timeSeriesDetails: TimeSeriesDetails = {
            dataPoints: [flowDataPoint, pressureDataPoint],
            period,
            installationId,
            systemId: this.application.id,
            requestId: 'pump_trend_data',
          };
          return this.dataPointsService.getTimeSeries(timeSeriesDetails).pipe(
            map((seriesData: TimeSerie[]) => {
              const series: SeriesOptionsType[] = [];
              if (seriesData[0]) {
                series.push({
                  name: flowLegend,
                  data: convertCubicMeterPerSecondToCubicMeterPerHour(seriesData[0]),
                  type: 'line',
                  tooltip: { valueSuffix: ' m3/h' },
                } as SeriesOptionsType);
              }

              if (seriesData[1]) {
                series.push({
                  name: pressureLegend,
                  data: convertPascalToMeter(seriesData[1]),
                  type: 'line',
                  tooltip: { valueSuffix: ' m' },
                  yAxis: 1,
                } as SeriesOptionsType);
              }

              return series;
            })
          );
        }),
        shareReplay(1)
      );
  }
}
