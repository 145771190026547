import {Component, OnInit} from '@angular/core';
import {Facility} from '../../interfaces/facilty';
import {PageInfoService} from '../../services/page-info.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FacilityService} from '../../services/facility.service';
import {debounceTime, distinctUntilChanged, filter, first, map, shareReplay, startWith, tap} from 'rxjs/operators';
import {combineLatest, Observable} from 'rxjs';
import {AppError, AppErrorService, ModalService, PageInfo, Result, TabComponent} from 'shared';
import {UserService} from '../../services/user.service';
import {navigateToFacility} from '../../utils/navigate-utils';
import {UserTrackingHelperService} from '../../services/user-tracking-helper.service';
import {EnergyDataExportModalComponent} from '../../components/energy-data-export-modal/energy-data-export-modal.component';
import {DataPointsService} from '../../services/data-points.service';
import {TranslateService} from '@ngx-translate/core';
import {IUser} from '../../interfaces/user';
import {MapService, MarkerState} from '../../services/map.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import { FacilityData } from '../../components/map/map.component';

@Component({
  selector: 'app-facility-map-page',
  templateUrl: './facility-map-page.component.html',
  styleUrls: ['./facility-map-page.component.scss'],
})
export class FacilityMapPageComponent implements OnInit {
  public pageInfo: PageInfo;
  public facilities$: Observable<Facility[]>;
  public filteredFacilities$: Observable<FacilityData | null>;
  public pageError$: Observable<AppError>;
  public currentTab$: Observable<string>;
  public user$: Observable<IUser | null>;
  public isEnergyDataExportDialogShown: boolean = false;
  public searchForm: FormGroup;
  public showFilterToggle: boolean = false;

  private searchQuery$: Observable<string>;
  private searchFilters$: Observable<string[]>;
  private facilitiesTrigger: string = "";

  public filterOptions: any[] = [
    { name: MarkerState.Alarm, value: MarkerState.Alarm },
    { name: MarkerState.Warning, value: MarkerState.Warning },
    { name: MarkerState.Offline, value: MarkerState.Offline },
    { name: MarkerState.Commissioning, value: MarkerState.Commissioning },
    { name: MarkerState.AllGood, value: MarkerState.AllGood },
    //{ name: MarkerState.Empty, value: MarkerState.Empty }
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private pageInfoService: PageInfoService,
    private facilityService: FacilityService,
    private errorService: AppErrorService,
    private modalService: ModalService,
    private dataPointService: DataPointsService,
    private translateService: TranslateService,
    private userService: UserService,
    private userTrackingHelperService: UserTrackingHelperService,
    private mapService: MapService,
    private form: FormBuilder
  ) {}

  ngOnInit() {

    this.searchForm = this.form.group({
      searchQuery: new FormControl(''),
      searchFilters: new FormControl([])
    });

    this.facilities$ = this.facilityService.facilities$.pipe(
      tap(() => {
        this.facilitiesTrigger = "service"
      }),
      filter((facility) => !!facility),
      map((facilities) => {
        const facilitiesWithStatus = this.updateFacilitiesWithStatus([...(facilities || [])]);
        const facilitiesSorted = facilitiesWithStatus.sort((a, b) => (a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1));
        return facilitiesSorted;
      }),
      shareReplay()
    );

    this.searchQuery$ = this.searchForm.controls['searchQuery'].valueChanges.pipe(
      startWith(''),
      debounceTime(200),
      map((searchQuery) => searchQuery.trim()),
      distinctUntilChanged(),
      tap(() => {
        this.facilitiesTrigger = "search"
      }),
      shareReplay()
    );

    this.searchFilters$ = this.searchForm.controls['searchFilters'].valueChanges.pipe(
      startWith([]),
      distinctUntilChanged(),
      tap(() => {
        this.facilitiesTrigger = "filters"
      }),
      shareReplay()
    );

    this.filteredFacilities$ = combineLatest([this.facilities$, this.searchQuery$, this.searchFilters$]).pipe(
      map(([facilities, query, filters]) => {
        if (!facilities) return { data: [], trigger: this.facilitiesTrigger };

        // Filter facilities by search query.
        let outputFacilities =  facilities.filter((facility) => facility.name.toLowerCase().includes(query.toLowerCase()));

        // Filter facilities by state (if applicable).
        if (filters.length > 0) {
          outputFacilities = outputFacilities.filter((item) => {
            return (item.status && filters.includes(item.status));
          });
        }

        // Group facilities by state (sort by state, then alphabetically).
        if (filters.length > 0) {
          const order: MarkerState[]  = [MarkerState.Alarm, MarkerState.Warning, MarkerState.Offline, MarkerState.Commissioning, MarkerState.AllGood, MarkerState.Empty];
          outputFacilities = outputFacilities.sort((a, b) => {
            const statusA = order.indexOf(a.status || MarkerState.Empty);
            const statusB = order.indexOf(b.status || MarkerState.Empty);

            // Compare the positions of statusA and statusB in the order array
            return statusA - statusB;
          });
        }

        // Return facilities with applied filters and sorting.
        return { data: outputFacilities, trigger: this.facilitiesTrigger };
      }),
      shareReplay()
    );

    const facilitiesLoading$ = this.facilities$.pipe(
      // We only take the first, as we need the loading to stop
      first(),
      // If we don't have any facilities, we navigate to the welcome page
      tap((facilities) => {
        if (!facilities.length) {
          this.router.navigate(['']);
        }
      })
    );

    this.pageError$ = this.errorService.createPageErrorObservable([this.userService.initialUser$, facilitiesLoading$]);
    this.pageInfo = this.pageInfoService.facilityMap();
    this.currentTab$ = this.route.params.pipe(map((p) => p.tab));
    this.user$ = this.userService.currentUser$;
  }

  public navigateToFacility(facility: Facility) {
    navigateToFacility(this.router, facility);
    this.userTrackingHelperService.trackUserAction('Facilities', 'LocationClicked');
  }

  public tabSelected(selectedTab: TabComponent) {
    this.userTrackingHelperService.trackTabSelected('Facilities', selectedTab.name);

    if (selectedTab.name == 'energyData') {
      this.showExportDialog();
    }
  }

  private showExportDialog = () => {
    // Ensure button state.
    if (this.isEnergyDataExportDialogShown) {
      return;
    }
    this.isEnergyDataExportDialogShown = true;

    // Show dialog.
    this.modalService.openDialog<any>(EnergyDataExportModalComponent, {}).subscribe((response: Result<any>) => {
      this.isEnergyDataExportDialogShown = false;

      if (response.dismissed) {
        this.router.navigate(['']);
        return;
      }

      this.dataPointService.exportEnergyData(response.result).subscribe((exportData) => {
        const blob = new Blob(['\ufeff', exportData], { type: 'text/csv' });
        const downloadUrl = URL.createObjectURL(blob);
        let fileName = this.translateService.instant('facility-map-page.export-energy-data-file-name') + '.csv';
        fileName = response.result.month ? response.result.month.padStart(2, '0').slice(-2) + '-' + fileName : fileName;
        fileName = response.result.year ? response.result.year + '-' + fileName : fileName;

        const link = document.createElement('a');
        link.setAttribute('id', 'gbc-download-link');
        link.setAttribute('href', downloadUrl);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Return to home.
        this.router.navigate(['']);
      });
    });
  };

  getFacilityStatus = (facilities: Facility[]) => {
    return this.mapService.getMarkerState(facilities);
  };

  getFacilityStatusIcon = (status: MarkerState) => {
    // There are no icons on the map, but we need some for the list.
    if (status === MarkerState.AllGood) {
      return this.mapService.getMarkerIcon(MarkerState.AllGoodList);
    }
    if (status === MarkerState.Empty) {
      return this.mapService.getMarkerIcon(MarkerState.EmptyList);
    }
    return this.mapService.getMarkerIcon(status);
  };

  updateFacilitiesWithStatus = (facilities: Facility[]): Facility[] => {
    return facilities.map((facility) => {
      facility.status = this.getFacilityStatus([facility]);
      return facility;
    });
  }
}
