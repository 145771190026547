import React from 'react';
import { Labels } from '../../Labels';
import { Direction } from '../../Common';
import { Pipe, PipeType } from '../../Pipe';
import { System, BaseSystemProps } from '../../System';
import { HeatMeterOrSensors } from '../../heat-meter-or-sensors/HeatMeterOrSensors';
import { ApplicationType, getForwardTop, getReturnTop } from '../../mixing-loop-common/ApplicationType';
import { IUnspecifiedLoadSystemFormInterface } from 'projects/dumbledore/src/models/systems/unspecifiedLoadSystem/unspecifiedLoadSystemFormInterface';
import {
  unspecifiedLoadMetadata,
  getLabels,
  heatMeterPosition,
  primaryForwardPosition,
  primaryReturnPosition,
  returnPipeEndPosition,
  supplyPipeEndPosition,
} from './unspecified-load-metadata';

export const UnspecifiedLoad: React.FC<BaseSystemProps<IUnspecifiedLoadSystemFormInterface>> = React.memo(
  ({ state, values, alarm, selected }) => {
    const systemInfo = state.systemInfo;
    const showHeatMeter = !!systemInfo.HM_present;
    const applicationType = systemInfo.Application;
    const showForwardHeatMeter = systemInfo.HM_location === 'PrimaryForward';
    const showReturnHeatMeter = systemInfo.HM_location === 'PrimaryReturn';
    const showForwardSensor = !!(systemInfo.HM_present || systemInfo.T_F_present);
    const showReturnSensor = !!(systemInfo.HM_present || systemInfo.T_R_present);

    return (
      <System state={state} meta={unspecifiedLoadMetadata} alarm={alarm} selected={selected}>
        <Pipe
          key="supplyPipe"
          x1={primaryForwardPosition.x}
          y1={primaryForwardPosition.y}
          x2={supplyPipeEndPosition.x}
          y2={getForwardTop(applicationType)}
          type={PipeType.WarmSupply}
        />
        <Pipe
          key="returnPipe"
          x1={primaryReturnPosition.x}
          y1={primaryReturnPosition.y}
          x2={returnPipeEndPosition.x}
          y2={getReturnTop(applicationType)}
          type={PipeType.ColdReturn}
        />

        <g transform={`translate(${heatMeterPosition.x}, ${heatMeterPosition.y})`}>
          <HeatMeterOrSensors
            direction={Direction.NORTH}
            showHeatMeter={showHeatMeter}
            showForwardHeatMeter={showForwardHeatMeter}
            showReturnHeatMeter={showReturnHeatMeter}
            showForwardSensor={showForwardSensor}
            showReturnSensor={showReturnSensor}
          />
        </g>
        <ApplicationType applicationType={applicationType} />
        <Labels positions={getLabels(systemInfo)} values={values} />
      </System>
    );
  }
);
