<gbc-tile data-test-id="energy-monitoring-tile" [title]="'energy-monitoring-tile.energy-monitoring' | translate">
  <div class="tile-content">

    <div class="tile-row" data-test-id="thermal-power">
      <div class="
      tile-row-name">{{'energy-monitoring-tile.thermal-power' | translate}}:</div>
      <div class="tile-row-value">{{thermalPowerCalibrated$ | async | formatDataPoint}}</div>
    </div>

    <div class="tile-row" data-test-id="thermal-energy">
      <div class="tile-row-name">{{'energy-monitoring-tile.thermal-energy' | translate}}:</div>
      <div class="tile-row-value">{{thermalEnergy$ | async | formatDataPoint }}</div>
    </div>

    <div class="tile-row" data-test-id="volume">
      <div class="tile-row-name">{{'energy-monitoring-tile.volume' | translate}}:</div>
      <div class="tile-row-value">{{volume$ | async | formatDataPoint }}</div>
    </div>

    <div class="tile-row" data-test-id="weighted-delta-t">
      <div class="tile-row-name">{{'energy-monitoring-tile.weighted-delta-t' | translate}}:</div>
      <div class="tile-row-value">{{weightedDeltaT$ | async | formatDataPoint }}</div>
    </div>

  </div>
</gbc-tile>

