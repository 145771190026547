<app-mixit-x509-banner/>
<app-alarm-bar *ngIf="alarm$ | async" [alarm]="alarm$"> </app-alarm-bar>

<app-schematics-tile
  [installationId]="(installation$ | async)?.id"
  [applicationId]="application.id"
  [showShadow]="false"
  [dataPoints$]="dataPoints$"
>
</app-schematics-tile>

<app-temperature-chart-tile
  [applicationId]="application.id"
  [title]="'outdoor-temp-dashboard.temperature-chart-title' | translate"
  [series]="series$ | async"
  [chartOptions]="chartOptions"
  [dataPoints$]="dataPoints$"
  [userTrackingPeriodOptions]="{ category: 'application/outdoorTemperature', intervalPrefix: 'outdoorTemperature' }"
>
</app-temperature-chart-tile>
