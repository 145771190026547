import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { EnergyManagementDashboardSection, EBackendHealthCheckStatus, HealthCheck, EnergyDashboardTargetsDto, EHealthCheckTargetKey } from '../../interfaces/installation';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { InstallationService } from '../../services/installation.service';
import { SnackBarService, ModalService } from 'shared';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserTrackingHelperService } from '../../services/user-tracking-helper.service';

@Component({
  selector: 'app-energy-management-dashboard-section',
  templateUrl: './energy-management-dashboard-section.component.html',
  styleUrls: ['./energy-management-dashboard-section.component.scss'],
})
export class EnergyManagementDashboardSectionComponent implements OnInit, OnDestroy {
  @Input() public sectionData: EnergyManagementDashboardSection;
  @Input() public humanReadableId: string;
  @Input() public healthCheckTargetKey: EHealthCheckTargetKey;
  @Input() public installationId: string;

  public healthCheckStatus = EBackendHealthCheckStatus;
  public toggledHealthCheckKey$: BehaviorSubject<HealthCheck | null> = new BehaviorSubject<HealthCheck | null>(null);
  public toggledHealthCheck: HealthCheck | null;
  public healthCheckFormGroup: UntypedFormGroup;
  public pending = false;

  private healthCheckTargetSubscription: Subscription;
  private updateRequest: Subscription;
  private userTrackingValueChangeSubscription: Subscription;

  constructor(
    private installationService: InstallationService,
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private userTrackingHelperService: UserTrackingHelperService
  ) { }
  ngOnInit(): void {
    this.healthCheckFormGroup = new UntypedFormGroup({});

    this.healthCheckTargetSubscription = combineLatest([this.toggledHealthCheckKey$])
      .pipe(
        map(([hCheck]) => {

          if (hCheck) {
            if (!this.healthCheckFormGroup.controls[hCheck.name]) {
              this.healthCheckFormGroup.addControl(hCheck.name, new UntypedFormControl(hCheck.targets[0].value));
            } else {
              this.healthCheckFormGroup.controls[hCheck.name].enable({ emitEvent: false });
              // Force validation since we used emitEvent=false above, which means validation is not automatically triggered
              this.healthCheckFormGroup.controls[hCheck.name].updateValueAndValidity();
            }
            this.toggledHealthCheck = hCheck;
          } else {
            this.toggledHealthCheck = null;
          }

          this.userTrackingValueChangeSubscription?.unsubscribe();
          this.userTrackingValueChangeSubscription = this.userTrackingHelperService.startFormValueChangeTracking(`EDB${this.humanReadableId}`, this.healthCheckFormGroup);
      })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.healthCheckTargetSubscription.unsubscribe();
    this.updateRequest?.unsubscribe();
    this.userTrackingValueChangeSubscription?.unsubscribe();
  }

  toggleEditMode(hcheck: HealthCheck) {
    this.toggledHealthCheckKey$.next(hcheck);
    this.userTrackingHelperService.trackUserAction(`EDB${this.humanReadableId}`, `${hcheck.name}StartEdit`)
  }

  toggleHealthCheck(contentVisible: boolean) {
    this.userTrackingHelperService.trackUserAction(`EDB${this.humanReadableId}`, contentVisible ? 'healthCheckExpanded' : 'healthCheckCollapsed')
  }

  onCancel() {
    this.userTrackingHelperService.trackUserAction(`EDB${this.humanReadableId}`, `${this.toggledHealthCheck?.name}CancelEdit`)
    this.toggledHealthCheckKey$.next(null);
  }

  onConfirm() {
    const healthCheck = this.toggledHealthCheck;
    if (!this.isHealthCheck(healthCheck)) {
      return;
    }

    this.userTrackingHelperService.trackUserAction(`EDB${this.humanReadableId}`, `${healthCheck.name}Save`)

    this.pending = true;
    this.healthCheckFormGroup.controls[healthCheck.name].disable();
    const newTargetValue = +this.healthCheckFormGroup.controls[healthCheck.name].value;
    const payload: EnergyDashboardTargetsDto = [
      {
        name: `${this.healthCheckTargetKey}${healthCheck.name}`,
        unit: healthCheck.unitType,
        value: newTargetValue,
      },
    ];

    this.updateRequest = this.installationService
      .updateInstallationEnergyDashboardTarget(this.installationId, payload)
      .subscribe(
        () => {
          this.pending = false;
          this.toggledHealthCheckKey$.next(null);
          this.snackBarService.show(this.translateService.instant(`energy-management-dashboard.health-check-target-update-success`));
        },
        error => {
          this.pending = false;
          this.healthCheckFormGroup.controls[healthCheck.name].enable();
          this.snackBarService.show(
            `${this.translateService.instant('energy-management-dashboard.health-check-target-update-error')} ${healthCheck.name}`
          );
        }
      );
  }

  healthCheckValueChange($event: any) {
    this.userTrackingHelperService.trackUserAction(`EDB${this.humanReadableId}`, `${this.toggledHealthCheck?.name}ValueChange`)
  }

  someHealthCheckWarning(healthChecks: HealthCheck[]) {
    return healthChecks.map((hc) => hc.status).some((value) => [EBackendHealthCheckStatus.ErrorTargetOvershot, EBackendHealthCheckStatus.ErrorTargetUndershot].includes(value));
  }

  toggleInfoModal(translateString: string, title: string) {
    const translatedTitle = this.translateService.instant(title);
    const translatedContentString = this.translateService.instant(translateString);
    this.modalService.showTextModal({
      title: translatedTitle,
      content: translatedContentString,
      actions: [{ text: this.translateService.instant('app-ok'), type: 'primary' }],
    });
  }

  isHealthCheck(h: HealthCheck | null): h is HealthCheck {
    return h !== null;
  }

  showHealthCheckDetails(healthCheckTargetKey: EHealthCheckTargetKey, healthCheck: HealthCheck) {
    this.router.navigate([
      'health-check',
      healthCheckTargetKey,
      healthCheck.name
    ], { relativeTo: this.route });
  }
}
