<gbc-breadcrumb [pageInfo]="pageInfo$ | async"></gbc-breadcrumb>
<gbc-page-error-handler [content]="content" [pageError$]="pageError$"></gbc-page-error-handler>
<ng-template #content>
  <gbc-command-spinner>
    <gbc-page-container data-test-id="edit-user">
      <form [formGroup]="form" (submit)="submit()">
        <gbc-tile [title]="(id ? 'edit-user-page.edit-user' : 'edit-user-page.create-user') | translate">
          <gbc-table>
            <tr gbc-table-row>
              <td>
                <gbc-input-container>
                  <gbc-input-label>{{ 'edit-user-page.name' | translate }}*</gbc-input-label>
                  <gbc-input-field formControlName="name"></gbc-input-field>
                </gbc-input-container>
              </td>
            </tr>
            <tr gbc-table-row>
              <td>
                <gbc-input-container>
                  <gbc-input-label>{{ 'edit-user-page.email' | translate }}*</gbc-input-label>
                  <gbc-input-field
                    inputType="email"
                    formControlName="email"
                    errorDescriptionTranslationString="edit-user-page.invalid-email"
                  ></gbc-input-field>
                </gbc-input-container>
              </td>
            </tr>
            <tr gbc-table-row>
              <td>
                <gbc-input-container>
                  <gbc-input-label>{{ 'edit-user-page.role' | translate }}*</gbc-input-label>
                  <gbc-dropdown formControlName="role" [dropDownItems]="roles$ | async"></gbc-dropdown>
                  <app-user-role-explainer-toggle-link></app-user-role-explainer-toggle-link>
                </gbc-input-container>
              </td>
            </tr>
            <tr>
              <td>
                <gbc-form-hint> * {{ 'app-must-fill-in' | translate }} </gbc-form-hint>
              </td>
            </tr>
          </gbc-table>
        </gbc-tile>

        <gbc-tile *ngIf="showFacilitySelector$ | async" [title]="'edit-user-page.assigned-facilities' | translate">
          <ng-container *ngIf="facilitiesForCompany$ | async as facilities">
            <gbc-item-list-selector
              formControlName="facilities"
              [header]="[{ key: 'name', title: 'edit-user-page.facility' | translate }]"
              [fallbackMessage]="'edit-user-page.no-facilities-for-company' | translate"
              [items]="facilities"
            >
            </gbc-item-list-selector>
          </ng-container>
        </gbc-tile>

        <button gbc-button large type="submit" [disabled]="!form.valid" class="submit-button">
          {{ (id ? 'edit-user-page.update-user' : 'edit-user-page.create-user') | translate }}
        </button>
      </form>
    </gbc-page-container>
  </gbc-command-spinner>
</ng-template>
