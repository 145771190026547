import React from 'react';
import { Labels } from '../../Labels';
import { Direction } from '../../Common';
import { PumpIcon } from '../../PumpIcon';
import { SvgImage } from '../../SvgImage';
import { FlowMeter } from '../../FlowMeter';
import { Pipe, PipeType } from '../../Pipe';
import * as metaData from './booster-metadata';
import { BaseSystemProps, System } from '../../System';
import { TemperatureSensor } from '../../TemperatureSensor';
import arrowRight from '!raw-loader!../../../../../assets/icons/arrow-right_outline.svg';
import { IBoosterSystemFormInterface } from '../../../../models/systems/boosterSystem/boosterSystemFormInterface';

export const BoosterSystem: React.FC<BaseSystemProps<IBoosterSystemFormInterface>> = React.memo(({ state, values, alarm, selected }) => {
  const systemInfo = state.systemInfo;

  const pumpWithConnections = (key: number, x: number, y: number) => {
    return (
      <g transform={`translate(${x || 0},${y || 0})`} key={key}>
        <Pipe type={PipeType.ColdSupply} key="left" x1={0} x2={0} y1={0} y2={60} />
        <Pipe type={PipeType.ColdSupply} key="center" x1={-1} x2={161} y1={-1} y2={-1} />
        <PumpIcon direction={Direction.EAST} x={80} y={0} />;
        <Pipe type={PipeType.ColdSupply} key="right" x1={160} x2={160} y1={0} y2={60} />
      </g>
    );
  };

  const renderAdditionalPumps = () => {
    const numberOfPumps = systemInfo.NumberOfPumps as number;
    if (numberOfPumps <= 0 || numberOfPumps > 6) {
      return null;
    }

    return new Array(numberOfPumps - 1).fill(true).map((_, i) => {
      const y = metaData.PIPE_Y - (i + 1) * 60;
      return pumpWithConnections(y, 270, y);
    });
  };

  return (
    <System state={state} meta={metaData.boosterSystemMeta} alarm={alarm} selected={selected}>
      <SvgImage key="left-arrow" svgText={arrowRight} x={metaData.ARROW_1_X} y={metaData.ARROW_Y} transforms={['scale(0.055)']} />
      <TemperatureSensor key="left-temp" direction={Direction.EAST} x={metaData.TEMP_SENSOR_1_X} y={metaData.TEMP_SENSOR_Y} />
      <Pipe
        key="supplyPipe"
        type={PipeType.ColdSupply}
        x1={metaData.START_X}
        x2={metaData.END_X}
        y1={metaData.PIPE_Y}
        y2={metaData.PIPE_Y}
      />
      <TemperatureSensor key="right-temp" direction={Direction.EAST} x={metaData.TEMP_SENSOR_2_X} y={metaData.TEMP_SENSOR_Y} />
      <SvgImage key="right-arrow" svgText={arrowRight} x={metaData.ARROW_2_X} y={metaData.ARROW_Y} transforms={['scale(0.055)']} />

      <PumpIcon key="first-pump" direction={Direction.EAST} x={metaData.PUMP_X} y={metaData.PIPE_Y} />

      {renderAdditionalPumps()}

      <FlowMeter key="flowMeter" x={metaData.FLOW_METER_X} y={metaData.PIPE_Y} />

      <Labels positions={metaData.getBoosterLabels(systemInfo)} values={values} />
    </System>
  );
});
