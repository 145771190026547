import React from 'react';
import ReactDOM from 'react-dom';
import { SchematicState } from './state';
import { View } from './components/View';
import { createStore, Action } from 'redux';
import { schematicReducer } from './reducer';
import { ModeContext } from './components/mode-provider';
import { PayloadAction, Reducer } from '@reduxjs/toolkit';
import { AppModel, SchematicMode, TEquipmentValues } from './types';
import { viewerInputStrategy } from './input-strategies/viewer-input-strategy';
import { defaultInputStrategy } from './input-strategies/default-input-strategy';
import { DispatchInputEventProvider } from './input-strategies/input-strategy-service';
import { confirmDeleteSystem, loadSchematic, setMode, setValues, systemSelect } from './actions';
import { creatingConnectionInputStrategy } from './input-strategies/creating-connection-input-strategy';
import { draggingConnectionPointStrategy } from './input-strategies/dragging-connection-point-strategy';
import { draggingSystemInputStrategy } from './input-strategies/dragging-system-strategy';

export enum SchematicEventType {
  SystemDelete = 'SystemDelete',
  StateChange = 'StateChange',
  SystemClick = 'SystemClick',
}

export interface DeleteSystemEvent {
  type: SchematicEventType.SystemDelete;
  systemId: string;
}

export interface SystemClickEvent {
  type: SchematicEventType.SystemClick;
  systemId: string;
}

export interface StateChangeEvent {
  type: SchematicEventType.StateChange;
  state: SchematicState;
}

export type SchematicEvent = DeleteSystemEvent | SystemClickEvent | StateChangeEvent;

export type SchematicEventCallback = (event: SchematicEvent) => unknown;

interface SchematicProps {
  getState: () => SchematicState;
  dispatch: (action: PayloadAction<any>) => void;
}

export const Schematic: React.FC<SchematicProps> = ({ getState, dispatch }) => {
  return (
    <React.StrictMode>
      <ModeContext.Provider value={getState().mode}>
        <DispatchInputEventProvider
          strategies={[
            defaultInputStrategy,
            creatingConnectionInputStrategy,
            draggingConnectionPointStrategy,
            draggingSystemInputStrategy,
            viewerInputStrategy,
          ]}
          getState={getState}
          dispatch={dispatch}>
          <View state={getState()} />
        </DispatchInputEventProvider>
      </ModeContext.Provider>
    </React.StrictMode>
  );
};

interface RenderSchematicResult {
  getState: () => SchematicState;
  dispatch: (action: PayloadAction<any>) => unknown;
}

export const renderSchematic = (container: HTMLElement, onEvent: SchematicEventCallback, mode: SchematicMode): RenderSchematicResult => {
  const schematicViewerReducer = (state: SchematicState, action: Action): SchematicState => {
    if (systemSelect.match(action)) {
      const event: SystemClickEvent = {
        type: SchematicEventType.SystemClick,
        systemId: action.payload.systemId,
      };
      onEvent(event);
    }
    if (confirmDeleteSystem.match(action)) {
      const event: DeleteSystemEvent = {
        type: SchematicEventType.SystemDelete,
        systemId: action.payload.systemId,
      };
      onEvent(event);
    }
    return state;
  };

  const combinedReducer: Reducer<SchematicState, Action> = (state: SchematicState | undefined, action: Action): SchematicState => {
    let newState = schematicReducer(state, action);
    newState = schematicViewerReducer(newState, action);
    return newState;
  };

  const store = createStore(combinedReducer);

  store.dispatch(setMode({ mode }));

  const getState = () => {
    return store.getState();
  };

  const dispatch = (action: PayloadAction<any>): void => {
    store.dispatch(action);
    ReactDOM.render(React.createElement(Schematic, { getState, dispatch }), container);
    onEvent({
      type: SchematicEventType.StateChange,
      state: store.getState(),
    });
  };

  ReactDOM.render(React.createElement(Schematic, { getState, dispatch }), container);

  return {
    getState,
    dispatch,
  };
};

export const getSchematicSvg = (schematic: AppModel): string | undefined => {
  return getIOManualSVG(schematic, []);
};

export const getIOManualSVG = (schematic: AppModel, ioMappings: TEquipmentValues[]): string | undefined => {
  const container = document.createElement('div');

  try {
    const { dispatch } = renderSchematic(container, () => undefined, SchematicMode.Present);
    dispatch(loadSchematic({ schematic }));
    dispatch(setValues({ values: ioMappings }));
    const serializer = new XMLSerializer();
    if (container.firstElementChild) {
      return serializer.serializeToString(container.firstElementChild);
    }
  } finally {
    container.remove();
  }
};
