import { LabelPosition, SystemMeta } from '../../types';
import { SmallLabelDeltas } from '../../../util/constants';
import { EConnectionType, SystemType } from '../../../state';
import { EConnectionPointPlacement, EEquipment } from '../../../types';
import thumbnail from '!svg-url-loader!../../../../../assets/system_thumbnails/cold-water-pump.svg';
import coldWaterPumpSystemForm from '../../../../models/systems/coldWaterPumpSystem/coldWaterPumpSystemForm.json';

export const HEIGHT = 140;
export const WIDTH = 140;

export const PIPE_Y = 80;

export const PUMP_X = WIDTH / 2;

export const coldWaterPumpMeta: SystemMeta = {
  systemType: SystemType.ColdWaterPump,
  name: 'Pump',
  thumbnail,
  form: coldWaterPumpSystemForm,
  width: WIDTH,
  height: HEIGHT,
  calculateConnectionPoints: () => [
    {
      key: EConnectionPointPlacement.connectionPrimaryReturn,
      x: 0,
      y: PIPE_Y,
      type: EConnectionType.return,
      dotted: true,
    },
    {
      key: EConnectionPointPlacement.connectionSecondaryReturn,
      x: WIDTH,
      y: PIPE_Y,
      type: EConnectionType.return,
      dotted: true,
    },
  ],
};

export const getColdWaterPumpLabels = (): LabelPosition[] => {
  const rPump: LabelPosition = {
    id: EEquipment.P_R,
    x: PUMP_X,
    y: PIPE_Y + SmallLabelDeltas.WEST_Y + 4,
    horizontalAlignment: 'middle',
  };

  return [rPump];
};
