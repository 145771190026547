import { AccessClaim, EAccessLevel } from 'projects/shared/src/lib/interfaces/access';
import { INotificationSettings } from '../../../../shared/src';

// It is possible for users to log into the customer portal without being created on the backend
// In this case, we only have the name property from OpenId Connect.
export enum UserType {
  OIDC = 'oidc',
  BuildingConnect = 'BuildingConnect',
}

export interface IOidcUser {
  type: UserType.OIDC;
  name: string;
}

export interface IBackendUser {
  id: string;
  active: boolean;

  name: string;
  companyId?: number;
  companyName?: string;
  phone: string;
  email: string;
  notificationSettings: INotificationSettings;
  accessLevel: EAccessLevel;
  accessClaimsNames: AccessClaim[]; 
  facilityIDs?: number[];
  hasInstallationsWithEnergyData?: boolean;
}

export interface IBuildingConnectUser extends IBackendUser {
  type: UserType.BuildingConnect;
}

export type IUser = IOidcUser | IBuildingConnectUser;
