import { SystemType, DistrictHeatingSystemState } from '../state';
import { EEquipment, EValueType } from '../types';
import { SampleEquipmentValue } from './sample-equipment-values';
import { v4 } from 'uuid';

export const SAMPLE_DISTRICT_HEATING_SUPPLY: DistrictHeatingSystemState = {
  hasId: v4(),
  type: SystemType.DistrictHeatingSupply,
  left: 10,
  top: 10,
  configured: false,
  systemInfo: {
    HM_present: true,
    HM_location: 'PrimaryForward',
    T_F_present: true,
    T_F_sensortype: "SensorPT500"
  }
};

export const SAMPLE_VALUES_DISTRICT_HEATING_SUPPLY: SampleEquipmentValue[] = [
  { systemType: SystemType.DistrictHeatingSupply, type: EValueType.value, equipment: EEquipment.T_F, value: '72.9\u2103' },
  { systemType: SystemType.DistrictHeatingSupply, type: EValueType.value, equipment: EEquipment.T_R, value: '33.7\u2103' },
  { systemType: SystemType.DistrictHeatingSupply, type: EValueType.value, equipment: EEquipment.Q_PF, value: '10.3\u33a5/s' },
  { systemType: SystemType.DistrictHeatingSupply, type: EValueType.value, equipment: EEquipment.Q_PR, value: '10.3\u33a5/s' }
];
